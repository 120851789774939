import { FEEDBACK } from "../../../constants/feedback";

import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";


// import { IAssessProductValues } from "../../../ts/interfaces/assessProduct";

// import { SendNotification } from '../notification/sendNotificationAction';

import {CreativeJoBNumber} from '../../../utils'

export const AssessProduct = (
    values:any
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: any,
    { getFirebase }: any
): Promise<void> => {
        const firebase = getFirebase();
        try {
            //it's all good

            if (
                values.isProductNameClear.status &&
                values.isProductShortDescriptionClear.status &&
                values.isProductFullDescriptionClear.status &&
                values.isProductGeneralDataClear.status &&
                values.isProductImagesDataClear.status &&
                values.isProductAttributesDataClear.status
            ) {
                const updateProductPromise = 
                firebase
                    .firestore()
                    .collection("Products")
                    .doc(values.product_id)
                    .set({
                        ...values,
                        to_be_reviewed:false,
                        to_be_resolved:false,
                        // visible_on_market: true,
                        under_creatives_process: true,
                        
                    },{ merge: true });
                const addNewCreativeJob = 
                firebase.firestore().collection('CreativeJobs').doc().set({
                    store_id: values.product_data.store_id,
											store_name: values.product_data.store_name,
											store_admin_data: {
												first_name: values.product_data.store_admin_data.first_name,
												last_name: values.product_data.store_admin_data.last_name,
												email: values.product_data.store_admin_data.email,
												contact_phone_number: values.product_data.store_admin_data.contact_phone_number,
											},

											reference: CreativeJoBNumber(),
											job: ["MARKET_PRODUCT"],
											product_data: {
												id: values.product_id,
												image_1: values.product_data.image_1,
												image_1_url:
                                                values.product_data.image_1_url,
												image_2: values.product_data.image_2,
												image_2_url:
                                                values.product_data.image_2_url,
												image_3: values.product_data.image_3,
												image_3_url: values.product_data.image_3_url,
												image_4: values.product_data.image_4,
												image_4_url: values.product_data.image_4_url,
											},
											commission: 100,
											pending_review: true,
											type: "MARKET_PRODUCT",
                    added_on:firebase.firestore.FieldValue.serverTimestamp()
                });

                const allPromise = Promise.all([updateProductPromise,addNewCreativeJob])

                 allPromise.then(async () => {
                        dispatch({
                            type: FEEDBACK,
                            payload: {
                                severity: "success",
                                title: "Product will go to the creatives for editing!",
                                body: `${values.product_name} is now being rebranded! Thanks for the assessment.`,
                            },
                        });

                      
                    });
            }

            if (
                !values.isProductNameClear.status ||
                !values.isProductShortDescriptionClear.status ||
                !values.isProductFullDescriptionClear.status ||
                !values.isProductGeneralDataClear.status ||
                !values.isProductImagesDataClear.status ||
                !values.isProductAttributesDataClear.status
            ) {
              return  firebase
                    .firestore()
                    .collection("Products")
                    .doc(values.product_id)
                    .set(
                        {
                            ...values,
                            to_be_reviewed:false,
                            to_be_resolved:true,
                            visible_on_market:false,
                            
                        },
                        { merge: true }
                    )
                    .then(async () => {
                        dispatch({
                            type: FEEDBACK,
                            payload: {
                                severity: "success",
                                title: "Product under review  email sent!",
                                body: `${values.product_name} will be edited by the vendor! Thanks for the assessment.`,
                            },
                        });

                       
                    });
            }

            if (values.enable_automated_add_product) {
                firebase
                    .firestore()
                    .collection("Stores")
                    .doc(values.entity)
                    .set(
                        {
                            ekioja_approved_add_product: values.enable_automated_add_product
                        },
                        { merge: true })

            }

           

        } catch (error) {
            

            dispatch({
                type: FEEDBACK,
                payload: { severity: "error", title: "Error!!!", body: error.code },
            });
        }
    };
