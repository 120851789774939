import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//@ts-ignore
import AsyncSelect from "react-select/async";

interface Tag {
	value: string;
	label: string;
}

interface Props {
	loadOptions: any;
	onChange: any;
}

const useStyles = makeStyles({
	root: { display: "100%", justifyContent: "center", alignItems: "center" },
});

const SearchVendorChatView = (props: Props) => {
	const styles = useStyles();
	return (
		<div className={styles.root}>
			<AsyncSelect loadOptions={props.loadOptions} onChange={props.onChange} />
		</div>
	);
};

export default SearchVendorChatView;
