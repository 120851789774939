
import {IAddress} from '../../../../ts/interfaces/addresses';

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';


export const Addaddress = (values:IAddress): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();
        try {


            firebase.firestore().collection('CustomerAddresses').doc().set({
                ...values,
                added_on: firebase.firestore.FieldValue.serverTimestamp(),
            });

        } catch (error) {
            console.log(error.code);
            
        }

    }