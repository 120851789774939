import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { TransitionProps } from "@material-ui/core/transitions";
import { Invoice } from "../../../../../../ConservationList/ConservationItem";
import _ from "lodash";
import NumberFormat from "react-number-format";
import useCancelOrder from "../../../../../../../../../hooks/chat/invoice/useCancelOrder";
import { truncate } from "../../../../../../../../../utils";
import { useSelector } from "react-redux";

import { Formik, Form, Field } from "formik";
import { Select } from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { ChatContext } from "../../../../../../../ChatController";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
	invoice: Partial<Invoice[]>;
}

const CancelOrder = (props: Props) => {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const auth = useSelector((state: any) => state.firebase.auth);
	const profile = useSelector((state: any) => state.firebase.profile);

	const [CancelOrderMutate, CancelOrderStatus] = useCancelOrder();
	const { chat_id } = useContext(ChatContext);
	return (
		<div>
			{" "}
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginTop: 10,
				}}
			>
				<Button
					size="small"
					variant="contained"
					color="primary"
					onClick={handleClickOpen}
				>
					Cancel Order
				</Button>
			</div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="cancel-order-dialog-slide-title"
				aria-describedby="cancel-order-dialog-slide-description"
			>
				<DialogTitle id="cancel-order-dialog-slide-title">
					{"Cancel Order?"}
				</DialogTitle>
				<DialogContent>
					{props.invoice.map((item: any) => (
						<Box marginTop={2} borderRadius={3} key={item.product_id}>
							<Grid container>
								<Grid item xs={3}>
									<Box
										width="100%"
										height="100%"
										display="flex"
										justifyContent="center"
										alignItems="center"
									>
										<Avatar
											src={item.product_image}
											alt={item.product_name}
											style={{ width: "3em", height: "3em" }}
											variant="square"
										/>
									</Box>
								</Grid>
								<Grid item xs={5}>
									<Box
										width="100%"
										height="100%"
										display="flex"
										justifyContent="flex-start"
										alignItems="center"
									>
										<Typography variant="subtitle1" color="textPrimary">
											{truncate(item.product_name, 30)}
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={1}>
									<Box
										width="100%"
										height="100%"
										display="flex"
										justifyContent="center"
										alignItems="center"
									>
										{" "}
										<Typography variant="subtitle1" color="textPrimary">
											X {item.product_quantity}
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={3}>
									<Box
										width="100%"
										height="100%"
										display="flex"
										justifyContent="center"
										alignItems="center"
									>
										<Typography variant="subtitle1" color="textPrimary">
											<NumberFormat
												value={item.product_quantity * item.product_price}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"₦"}
											/>
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Box>
					))}
					<Formik
						initialValues={{
							chat_id: chat_id,
							reason: "",
							persona: "ekioja",
							persona_id: auth.uid,
							persona_name: `${profile.first_name} ${profile.last_name}`,
						}}
						onSubmit={(values) => {
							CancelOrderMutate(values, {
								onSuccess: () => {
									handleClose();
								},
							});
						}}
					>
						{({ isSubmitting, submitForm }) => (
							<Form>
								<FormControl fullWidth>
									<InputLabel htmlFor="cancel-item-reason">
										What is the reason?
									</InputLabel>
									<Field
										component={Select}
										required
										name="reason"
										inputProps={{
											id: "cancel-item-reason",
										}}
										fullWidth
									>
										<MenuItem value="This is a duplicate order">
											This is a duplicate order
										</MenuItem>
										<MenuItem value="Found a better offer in Ekioja">
											Found a better offer in Ekioja
										</MenuItem>
										<MenuItem value="Found a better offer outside Ekioja">
											Found a better offer outside Ekioja
										</MenuItem>
										<MenuItem value="I was testing Ekioja">
											I was testing Ekioja
										</MenuItem>
										<MenuItem value="Not interested in order anymore">
											Not interested in order anymore
										</MenuItem>
										<MenuItem value="Shipping Fee is High">
											Shipping Fee is High
										</MenuItem>
										<MenuItem value=" Change delivery address/method">
											Change delivery address/method
										</MenuItem>
										<MenuItem value=" Change payment method">
											Change payment method
										</MenuItem>
										{/* <MenuItem value="Want to change voucher code">
											Want to change voucher code
										</MenuItem> */}
									</Field>
								</FormControl>
								<br />
								<br />
								<Button
									variant="contained"
									color="primary"
									disabled={isSubmitting}
									onClick={() => {
										if (window.confirm("Are you sure?")) {
											submitForm();
										}
									}}
								>
									{CancelOrderStatus.isLoading
										? "Processing..."
										: CancelOrderStatus.isError
										? "Error!"
										: CancelOrderStatus.isSuccess
										? "Done!"
										: "Cancel Order"}
								</Button>
							</Form>
						)}
					</Formik>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CancelOrder;
