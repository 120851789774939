import React from "react";
// import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
//logo
import Logo from "../../assets/images/svg/logo.svg";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		loading: {
			position: "fixed",
			left: 0,
			right: 0,
			top: "calc(50% - 20px)",
			margin: "auto",
			height: "80px",
			width: "80px",
			"& img": {
				position: "absolute",
				height: "40px",
				width: "auto",
				top: -10,
				bottom: 0,
				left: 0,
				right: 0,
				margin: "auto",
			},
		},
	})
);

const LoadingScreen = () => {
	const styles = useStyles();

	return (
		<div className={styles.loading}>
			<img src={Logo} alt="Ekioja " />
			<CircularProgress style={{ color: "green" }} size={68} />
		</div>
	);
};

export default LoadingScreen;
