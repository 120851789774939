import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const acceptAssignBooking = (values: any) => {
	return firebase
		.firestore()
		.collection("OrdersTimeline")
		.doc(values.orderTimelineId)
		.set(
			{
				booked: true,
				logistics: {
					uuid: values.logistics_uuid,
					name: values.name,
					entity: values.entity,
					manager_uuid: values.manager_uuid, 
					dispatcher_email: values.dispatcher_email,
					dispatcher_phonenumber: values.dispatcher_phonenumber,
					on: firebase.firestore.FieldValue.serverTimestamp(),
				},
			},
			{ merge: true }
		);
};

const useAcceptAssignBooking = () => {
	return useMutation(acceptAssignBooking);
};

export default useAcceptAssignBooking;
