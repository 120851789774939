import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const setTopPickProducts = (values: any) => {
	//empty cart
	const topPickProductsPromises: any = [];

	for (const item of values) {
		const p = firebase
			.firestore()
			.collection(`Products`)
			.doc(item.id)
			.set({ is_top_product: true }, { merge: true });

		topPickProductsPromises.push(p);
	}

	return Promise.all([...topPickProductsPromises]);
};

const useSetTopPickProducts = () => {
	return useMutation(setTopPickProducts);
};

export default useSetTopPickProducts;
