

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const OrderOutForDelivery = (id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

       try {
        firebase.firestore().collection('OrdersTimeline').doc(id).set({
            order_placed:{
               
                current:false,
            },
            pending_confirmation:{
               
                current:false,
            },
            waiting_to_be_shipped:{
                
                current:false,
            },
            out_for_delivery:{
                status:true,
                current:true,
                date: firebase.firestore.FieldValue.serverTimestamp(),
            },

        },{merge:true});
       } catch (error) {
           console.log(error);
           
       }
    }