import { useMutation, queryCache } from "react-query";
import firebase from "../../../config/firebaseConfig";

interface Props {
	chat_id: string;
	persona: string;
	reason: string;
	persona_name: string;
	persona_id: string;
}

const cancelOrder = async (values: Props) => {
	return firebase
		.firestore()
		.collection("Conversations")
		.doc(values.chat_id)
		.set(
			{
				orderTimeline: {
					order_placed: {
						current: false,
					},
					pending: {
						current: false,
					},
					waiting_to_be_shipped: {
						current: false,
					},
					out_for_delivery: {
						current: false,
					},
					delivered: {
						current: false,
					},
					cancelled: {
						status: true,
						current: true,
						reason: values.reason,
						persona: values.persona,
						persona_id: values.persona_id,
						persona_name: values.persona_name,
						date: firebase.firestore.FieldValue.serverTimestamp(),
					},
				},
			},
			{ merge: true }
		);
};

const useCancelOrder = () => {
	return useMutation(cancelOrder, {
		onSettled: (returnedData, error, sentVaribles: any) => {
			queryCache.invalidateQueries([
				"chatCurrentConversation",
				sentVaribles.chat_id,
			]);
			queryCache.refetchQueries(
				["chatCurrentConversation", sentVaribles.chat_id],
				{ active: true, exact: true }
			);
		},
	});
};

export default useCancelOrder;
