import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import Divider from "@material-ui/core/Divider";
import CloudUploadTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";

import UploadImage from "../../../UploadImage/UploadImage";
import useDeleteImage from "../../../../hooks/images/useDeleteImage";
import useAddImageDataToVendor from "../../../../hooks/creative/brandcreatives/useAddImageDataToVendor";
import useRemoveImageDataToVendor from "../../../../hooks/creative/brandcreatives/useRemoveImageDataToVendor";
import useCompleteJob from "../../../../hooks/creative/brandcreatives/useCompleteJob";
import _ from "lodash";
// import EkiojaBag from "../../../../assets/images/ekioja-logo.jpg";
// import EkiojaBG from "../../../../assets/images/categories/african_fashion.png";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";

const ProcessBrandCreativesJob = ({ data }: { data: any }) => {
	const [DeleteImageMutate] = useDeleteImage();

	const [AddImageDataToVendorMutate] = useAddImageDataToVendor();
	const [
		RemoveImageDataToVendorMutate,
		RemoveImageDataToVendorInfo,
	] = useRemoveImageDataToVendor();
	const [CompleteJobMutate, CompleteJobInfo] = useCompleteJob();

	const [logoURL, setLogoURL] = useState("");
	const [bannerURL, setBannerURL] = useState("");

	const [deleteLogoDialog, setDeleteLogoDialog] = useState(false);
	const [deleteBannerDialog, setDeleteBannerDialog] = useState(false);

	const [completedDialog, setCompletedDialog] = useState(false);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={1}></Grid>
				<Grid item xs={12} lg={4}>
					<List>
						<ListItem>
							<ListItemAvatar>
								<Avatar
									style={{ width: 100, height: 100 }}
									alt={data.store_name}
									src={`https://firebasestorage.googleapis.com/v0/b/ekioja-7bb9f.appspot.com/o/images%2Flogo_${data.store_id}?alt=media`}
								/>
							</ListItemAvatar>
							<ListItemText primary="Vendor" secondary={data.store_name} />
						</ListItem>
						<Divider variant="inset" component="li" />
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<AccountCircleTwoToneIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Representative"
								secondary={
									<div>
										<h4>{`${data.store_admin_data.first_name} ${data.store_admin_data.last_name}`}</h4>
										<h4>{`${data.store_admin_data.email}`}</h4>
										<h4>{`${data.store_admin_data.contact_phone_number}`}</h4>
									</div>
								}
							/>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} lg={7}>
					{logoURL !== "" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								minHeight: 200,
								width: "70%",
								border: "1px gray solid",
								borderRadius: 20,
								position: "relative",
								marginBottom: 30,
								paddingTop: 30,
							}}
						>
							<img width={150} height={150} src={logoURL} alt="logo" />
							<br />

							<Button
								color="secondary"
								onClick={() => {
									setDeleteLogoDialog(true);
								}}
							>
								Do you want to remove this logo?
							</Button>
							<br />
							<Collapse in={deleteLogoDialog} timeout="auto" unmountOnExit>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 40,
									}}
								>
									<div>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												RemoveImageDataToVendorMutate(
													{
														id: data.store_id,
														jobid:
															data.toBeProcessedBrandCreativeCreativeJobs_data_uuid,
														type: "logo",
													},
													{
														onSuccess: () => {
															DeleteImageMutate(
																{
																	folder: "images",
																	source: `logo_${data.store_id}`,
																},
																{
																	onSuccess: () => {
																		setLogoURL("");
																		setDeleteLogoDialog(false);
																	},
																	onError: (e: any) => {
																		window.alert(e.message);
																	},
																}
															);
														},
														onError: (e: any) => {
															window.alert(e.message);
														},
													}
												);
											}}
										>
											{RemoveImageDataToVendorInfo.isLoading
												? "removing..."
												: "Yes"}
										</Button>
										<Button
											color="secondary"
											onClick={() => {
												setDeleteLogoDialog(false);
											}}
										>
											No
										</Button>
									</div>
								</div>
							</Collapse>
						</div>
					)}

					{_.includes(data.job, "Logo") && logoURL === "" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: 200,
								width: "70%",
								border: "1px gray solid",
								borderRadius: 20,
								position: "relative",
								marginBottom: 30,
							}}
						>
							<CloudUploadTwoToneIcon style={{ fontSize: 40 }} />
							<h3>Upload processed logo design</h3>
							<div
								style={{
									position: "absolute",
									bottom: -10,
									right: "44%",
									width: 50,
									zIndex: 500,
								}}
							>
								<UploadImage
									title="logo design"
									existingImageId={`logo_${data.store_id}`}
									folderToSave="images"
									onRequestSaveURL={(url: any) => {
										AddImageDataToVendorMutate(
											{
												id: data.store_id,
												url,
												jobid:
													data.toBeProcessedBrandCreativeCreativeJobs_data_uuid,
												type: "logo",
											},
											{
												onSuccess: () => {
													setLogoURL(url);
												},
												onError: (e: any) => {
													window.alert(e.message);
												},
											}
										);
									}}
									onRequestSave={(id: any) => {}}
									onRequestClear={() => {
										DeleteImageMutate(
											{
												folder: "images",
												source: `logo_${data.store_id}`,
											},
											{
												onSuccess: () => {
													setLogoURL("");
												},
												onError: (e: any) => {
													window.alert(e.message);
												},
											}
										);
									}}
								/>
							</div>
						</div>
					)}

					{bannerURL !== "" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								minHeight: 200,
								width: "70%",
								border: "1px gray solid",
								borderRadius: 20,
								position: "relative",
								marginBottom: 30,
								paddingTop: 30,
							}}
						>
							<img width={250} height={150} src={bannerURL} alt="banner" />
							<br />

							<Button
								color="secondary"
								onClick={() => {
									setDeleteBannerDialog(true);
								}}
							>
								Do you want to remove this banner?
							</Button>
							<br />
							<Collapse in={deleteBannerDialog} timeout="auto" unmountOnExit>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 40,
									}}
								>
									<div>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												RemoveImageDataToVendorMutate(
													{
														id: data.store_id,
														jobid:
															data.toBeProcessedBrandCreativeCreativeJobs_data_uuid,
														type: "banner",
													},
													{
														onSuccess: () => {
															DeleteImageMutate(
																{
																	folder: "vendorAdminBannerImages",
																	source: `banner_${data.store_id}`,
																},
																{
																	onSuccess: () => {
																		setBannerURL("");
																		setDeleteBannerDialog(false);
																	},
																	onError: (e: any) => {
																		window.alert(e.message);
																	},
																}
															);
														},
														onError: (e: any) => {
															window.alert(e.message);
														},
													}
												);
											}}
										>
											{RemoveImageDataToVendorInfo.isLoading
												? "removing..."
												: "Yes"}
										</Button>
										<Button
											color="secondary"
											onClick={() => {
												setDeleteBannerDialog(false);
											}}
										>
											No
										</Button>
									</div>
								</div>
							</Collapse>
						</div>
					)}

					{_.includes(data.job, "Banner") && bannerURL === "" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: 200,
								width: "70%",
								border: "1px gray solid",
								borderRadius: 20,
								position: "relative",
								marginBottom: 30,
								marginTop: 30,
							}}
						>
							<CloudUploadTwoToneIcon style={{ fontSize: 40 }} />
							<h3>Upload processed banner design</h3>
							<div
								style={{
									position: "absolute",
									bottom: -10,
									right: "44%",
									width: 50,
									zIndex: 500,
								}}
							>
								<UploadImage
									title="banner design"
									existingImageId={`banner_${data.store_id}`}
									folderToSave="vendorAdminBannerImages"
									onRequestSaveURL={(url: any) => {
										AddImageDataToVendorMutate(
											{
												id: data.store_id,
												url,
												jobid:
													data.toBeProcessedBrandCreativeCreativeJobs_data_uuid,
												type: "banner",
											},
											{
												onSuccess: () => {
													setBannerURL(url);
												},
												onError: (e: any) => {
													window.alert(e.message);
												},
											}
										);
									}}
									onRequestSave={(id: any) => {}}
									onRequestClear={() => {
										DeleteImageMutate(
											{
												folder: "vendorAdminBannerImages",
												source: `banner_${data.store_id}`,
											},
											{
												onSuccess: () => {
													setBannerURL("");
												},
												onError: (e: any) => {
													window.alert(e.message);
												},
											}
										);
									}}
								/>
							</div>
						</div>
					)}

					{(_.includes(data.job, "Logo") && logoURL !== "") ||
					(_.includes(data.job, "Banner") && bannerURL !== "") ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								minHeight: 200,
								width: "70%",
							}}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={() => setCompletedDialog(true)}
							>
								Have you uploaded the design ?
							</Button>
							<br />
							<Collapse in={completedDialog} timeout="auto" unmountOnExit>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 40,
									}}
								>
									<div>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												CompleteJobMutate(
													{
														id:
															data.toBeProcessedBrandCreativeCreativeJobs_data_uuid,
														name: `${data.job_processed_by.name}`,
														amount: data.commission,
														walletid: data.job_processed_by.uid,
														purpose: "Brand creatives logos and banner",
													},
													{
														onSuccess: () => {
															setDeleteLogoDialog(false);
														},
														onError: (e: any) => {
															window.alert(e.message);
														},
													}
												);
											}}
										>
											{!CompleteJobInfo.isLoading &&
												!CompleteJobInfo.isSuccess &&
												"yes"}
											{CompleteJobInfo.isLoading && "Please wait..."}
											{CompleteJobInfo.isSuccess && " Completed!"}
										</Button>
										<Button
											color="secondary"
											onClick={() => {
												setCompletedDialog(false);
											}}
										>
											No
										</Button>
									</div>
								</div>
							</Collapse>
						</div>
					) : null}
				</Grid>
			</Grid>
		</>
	);
};

export default ProcessBrandCreativesJob;
