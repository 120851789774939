import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SenderMsg from "./components/SenderMsg";
import RecieverMsg from "./components/RecieverMsg";
import Info from "./components/Info";
import { Conversation } from "../../../ConservationList/ConservationItem";
import moment from "moment";
import _ from "lodash";
// import { animated, useTransition } from "react-spring";

const useStyles = makeStyles({
	root: {
		width: "100%",
		height: 350,
		marginBottom: 2,
		marginTop: 2,
		overflowX: "hidden",
		overflowY: "auto",
		"&::-webkit-scrollbar": {
			width: "10px",
		},
		"&::-webkit-scrollbar-track": {
			background: "#f1f1f1",
		},
		"&::-webkit-scrollbar-thumb": {
			background: "rgba(18, 175, 10, 0.1)",
			borderRadius: 10,
		},
		"&::-webkit-scrollbar-thumb:hover": {
			background: "rgba(18, 175, 10, 0.3)",
			borderRadius: 10,
			cursor: "pointer",
		},
	},
});

interface IInfoMsg {
	type: "info";
	mode: "default" | "error" | "warning" | "info" | "success";
	content: string;
	timestamp: any;
}

interface Props {
	data: Conversation;
}

const ChatBody = (props: Props) => {
	const styles = useStyles();
	const bodyRef = useRef<any>(null);
	const [chats, setChats] = useState<any>({});
	useEffect(() => {
		if (bodyRef) {
			bodyRef.current.addEventListener("DOMNodeInserted", (event: any) => {
				const { currentTarget: target } = event;
				target.scroll({ top: target.scrollHeight, behavior: "smooth" });
			});
		}
		return () => {
			if (bodyRef) {
				bodyRef.current.removeEventListener("DOMNodeInserted", (event: any) => {
					const { currentTarget: target } = event;
					target.scroll({ top: target.scrollHeight, behavior: "smooth" });
				});
			}
		};
	}, []);

	const sizeOfObject = function (obj: object) {
		var size = 0,
			key;
		for (key in obj) {
			if (obj.hasOwnProperty(key)) size++;
		}
		return size;
	};

	useEffect(() => {
		let now = moment();

		if (sizeOfObject(props.data.chats) > 0) {
			//convert date to MMM-ddd-YYYY
			const convertedTimestamp = _.map(props.data.chats, (item: any) => {
				return {
					...item,
					convertedTimestsamp: moment(item.timestamp.toDate()).format(
						"YYYY-M-D"
						// "MMM_ddd_YYYY"
					),
					formated: moment(now).isSame(item.timestamp.toDate(), "day"),
				};
			});
			const grouped = _.mapValues(
				_.groupBy(convertedTimestamp, "convertedTimestsamp"),
				(clist) => clist.map((chats) => _.omit(chats, "convertedTimestsamp"))
			);

			setChats({ ...grouped });
		}
	}, [props.data.chats]);

	return (
		<div className={styles.root} ref={bodyRef}>
			{sizeOfObject(props.data.chats) > 0 &&
				Object.keys(chats).map((key: any, index: number) => {
					const chatItem = chats[key];
					return (
						<>
							<Info
								content={
									moment().isSame(moment(key).format(), "day")
										? "Today"
										: moment(key).format("dddd, MMMM Do YYYY")
								}
								default
							/>
							{chatItem.map((item: any, index: number) => {
								switch (item.type) {
									case "info":
										const { mode } = item;
										if (mode === "default") {
											return <Info content={item.content} default />;
										} else if (mode === "error") {
											return <Info content={item.content} error />;
										} else if (mode === "warning") {
											return <Info content={item.content} warning />;
										} else if (mode === "success") {
											return <Info content={item.content} success />;
										} else {
											return <Info content={item.content} warning />;
										}

									case "send":
										const { sender } = item;
										if (sender === "buyer") {
											return (
												<RecieverMsg
													content={item.content}
													time={moment(item.timestamp.toDate()).format(
														"h:mm a"
													)}
													buyer
												/>
											);
										} else if (sender === "brand") {
											return (
												<RecieverMsg
													content={item.content}
													time={moment(item.timestamp.toDate()).format(
														"h:mm a"
													)}
													brand
												/>
											);
										} else if (sender === "ekioja") {
											return (
												<SenderMsg
													content={item.content}
													time={moment(item.timestamp.toDate()).format(
														"h:mm a"
													)}
													ekioja
												/>
											);
										}
										break;

									default:
										break;
								}
							})}
						</>
					);
				})}
			{/* {props.data.chats?.map((item: any, index: number) => {
				switch (item.type) {
					case "info":
						const { mode } = item;
						if (mode === "default") {
							return <Info content={item.content} default />;
						} else if (mode === "error") {
							return <Info content={item.content} error />;
						} else if (mode === "warning") {
							return <Info content={item.content} warning />;
						} else if (mode === "success") {
							return <Info content={item.content} success />;
						} else {
							return <Info content={item.content} warning />;
						}

					case "send":
						const { sender } = item;
						if (sender === "buyer") {
							return (
								<SenderMsg
									content={item.content}
									time={moment(item.timestamp.toDate()).format("h:mm a")}
								/>
							);
						} else if (sender === "brand") {
							return (
								<RecieverMsg
									content={item.content}
									time={moment(item.timestamp.toDate()).format("h:mm a")}
								/>
							);
						} else if (sender === "ekioja") {
							return (
								<RecieverMsg
									content={item.content}
									time={moment(item.timestamp.toDate()).format("h:mm a")}
								/>
							);
						}
						break;

					default:
						break;
				}
			})} */}
		</div>
	);
};

export default ChatBody;
