import React from "react";
import { makeStyles } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles({
	root: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		alignItem: "center",
	},
	msg_wrapper: {
		backgroundColor: (props: any) =>
			props.ekioja ? grey[600] : props.brand ? grey[400] : grey[100],
		padding: 10,
		borderRadius: 20,
		width: "fit-content",
		position: "relative",
		maxWidth: 350,
		margin: 20,
	},
	triangle: {
		borderStyle: "solid",
		borderWidth: " 0 11px 22px 11px",
		borderColor: (props: any) =>
			`transparent transparent ${
				props.ekioja ? grey[600] : props.brand ? grey[400] : grey[100]
			}  transparent`,
		position: "absolute",
		bottom: -10,
		left: 0,
		transform: "rotate(213deg)",
	},
	time: {
		fontSize: "0.8em",
		color: "#ccc",
		position: "absolute",
		bottom: -20,
		right: 0,
	},
	admin: {
		fontSize: "0.8em",
		color: "#ccc",
		position: "absolute",
		top: -20,
		left: 0,
	},
});

interface Props {
	content: string;
	time: string;
	ekioja?: boolean;
	brand?: boolean;
	buyer?: boolean;
}
const RecieverMsg = (props: Props) => {
	const styles = useStyles(props);
	return (
		<div className={styles.root}>
			<div className={styles.msg_wrapper}>
				{props.ekioja && <div className={styles.admin}>Admin</div>}
				{props.content}
				<div className={styles.triangle}></div>
				<div className={styles.time}>{props.time}</div>
			</div>
		</div>
	);
};

export default RecieverMsg;
