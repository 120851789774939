import React, { Fragment, useState, useEffect } from "react";
//material ui components
import Box from "@material-ui/core/Box";
// import Typography from '@material-ui/core/Typography';
import LocalMallTwoToneIcon from "@material-ui/icons/LocalMallTwoTone";
import ThumbUpTwoToneIcon from "@material-ui/icons/ThumbUpTwoTone";
import RedeemTwoToneIcon from "@material-ui/icons/RedeemTwoTone";
import LocalShippingTwoToneIcon from "@material-ui/icons/LocalShippingTwoTone";
import PanToolTwoToneIcon from "@material-ui/icons/PanToolTwoTone";
import SentimentVerySatisfiedTwoToneIcon from "@material-ui/icons/SentimentVerySatisfiedTwoTone";
import ReplayTwoToneIcon from "@material-ui/icons/ReplayTwoTone";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//material table
import MaterialTable from "material-table";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";

import moment from "moment";

import { truncate } from "../../../utils";

//number format
import NumberFormat from "react-number-format";

interface Props {
	entity: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			borderRadius: 20,
			width: "100%",
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		vendor_logo: {
			width: 50,
			height: 50,
			borderRadius: 50,
			border: "1px white solid",
		},
		add_new_user_button: {
			marginTop: "5%",
		},
		queries_wrapper: {
			width: "100%",
			padding: 10,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
		product_image_wrapper: {
			width: 40,
			height: 40,
			border: "1px white solid",
		},
		appBar: {
			position: "relative",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		card_wrapper: {
			marginBottom: 10,
			marginTop: 10,
		},
		product_image: {
			width: "100%",
			minHeight: 120,
			"& img": {
				width: "100%",
				height: 120,
			},
		},
		product_info_wrapper: {
			width: "100%",
			minHeight: 120,
			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-start",
			flexDirection: "column",
		},
		waiting_state: {
			backgroundColor: "#4287f5",
			padding: 5,
			textTransform: "uppercase",
			color: "white",
			borderRadius: 5,
			fontSize: 12,
		},
		done_state: {
			backgroundColor: "#8bf50a",
			padding: 5,
			textTransform: "uppercase",
			color: "white",
			borderRadius: 5,
			fontSize: 12,
		},
		wrapper: {
			width: "100%",
			height: 300,
		},
		order_list_wrapper: {
			maxWidth: "100%",
			height: 260,
			whiteSpace: "nowrap",
			WebkitOverflowScrolling: "touch",
			msOverflowStyle: "-ms-autohiding-scrollbar",
			overflowY: "hidden",
			overflowX: "auto",
			display: "inline-block",
			verticalAlign: "middle",
			padding: 10,
		},
		order_list_item_wrapper: {
			width: 250,
			height: 250,
			marginRight: 10,
			display: "inline-block",
		},

		order_list_item_info: {
			width: "100%",
			minHeight: 120,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			alignContent: "flex-end",
			flexDirection: "column",
		},
		media: {
			height: 140,
		},
		group_order_wrapper: {
			backgroundColor: "#cbffc2",
			marginTop: 10,
			marginBottom: 10,
		},
		paper: {
			padding: "6px 16px",
		},
		secondaryTail: {
			backgroundColor: "green",
		},
		spacing: {
			marginBottom: 0,
		},
		connect_default: {
			height: 50,
		},
		connector_success: {
			height: 70,
			backgroundColor: "green",
		},
		connector_error: {
			height: 70,
			backgroundColor: "red",
		},
		dot_success: {
			backgroundColor: "green",
			color: "black",
		},
		dot_error: {
			backgroundColor: "red",
			color: "black",
		},
		action_wrapper: {
			width: "100%",
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center",
			marginTop: 10,
		},
		track_order_image: {
			width: "100%",
			height: 160,
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const Orders = ({ entity }: Props) => {
	const styles = useStyles();

	const [pageloading, setpageloading] = useState(true);

	const [orderData, setOrderData] = useState<any>([]);

	useFirestoreConnect([
		{
			collection: "Orders",
			where: [["store_ids", "array-contains", entity]],
			storeAs: "Orders",
		},
		{
			collection: "OrdersTimeline",
			where: [["order_store_id", "==", entity]],
			storeAs: "OrdersTimeline",
		},
	]);

	const orders = useSelector((state: any) => state.firestore.data["Orders"]);

	const ordersTimeline = useSelector(
		(state: any) => state.firestore.data["OrdersTimeline"]
	);

	useEffect(() => {
		if (isLoaded(orders) && isLoaded(ordersTimeline)) {
			setpageloading(false);

			let order_data: any[] = [];
			if (orders !== null) {
				for (var orderkey in orders) {
					order_data.push({ ...orders[orderkey], order_uuid: orderkey });
				}
			}

			const mapDeliveryAddressToitems = _.map(order_data, (item) => {
				return {
					...item,
					items: _.map(item.items, (elems) => {
						return {
							...elems,
							order_id: item.order_id,
						};
					}),
				};
			});

			let orderTimeline_data: any[] = [];
			if (ordersTimeline !== null) {
				for (var orderTimelinekey in ordersTimeline) {
					orderTimeline_data.push({
						...ordersTimeline[orderTimelinekey],
						orderTimelinekey_uuid: orderTimelinekey,
					});
				}
			}

			const all_items_in_orders_and_ordertimeline = _.map(
				mapDeliveryAddressToitems,
				(item) => {
					return {
						...item,

						items: _.intersectionWith(
							_.cloneDeep(item.items),
							orderTimeline_data,
							function (x: any, y: any) {
								return (
									x.order_id === y.the_order_id &&
									x.entity === entity &&
									_.assign(x, y)
								);
							}
						),
					};
				}
			);

			const get_store_order_items = _.flatMapDeep(
				_.map(all_items_in_orders_and_ordertimeline, (order_item) =>
					_.map(order_item.items, (item) => [
						{
							...item,
							order_id: order_item.order_id,
							added_on: order_item.added_on,
							full_order_info: order_item,
						},
					])
				)
			);
			const order_items = _.filter(
				get_store_order_items,
				(item) => item.entity === entity
			);

			const order_filtered_by_date_added = _.orderBy(
				order_items,
				["added_on"],
				["desc"]
			);

			const result_filtered_orders = _.map(
				order_filtered_by_date_added,
				(item) => {
					return {
						image: item.product_image,
						order_id: item.order_id,
						product_name: item.product_name,
						quantity: item.quantity,
						price: item.subtotal,
						data: item,
						status: "status",
						added: moment(item.added_on.toDate()).fromNow(),
						createdon: moment(item.added_on.toDate()).format("MM/DD/YYYY"),
					};
				}
			);

			setOrderData([...result_filtered_orders]);
		}
	}, [orders, ordersTimeline, entity]);

	// const toggleorderDetalModal = () => {
	//     setOrderDetalModalState(!orderDetalModalState);
	// }

	return (
		<Fragment>
			<Box className={styles.root} boxShadow={5}>
				<MaterialTable
					style={{
						width: "100%",
					}}
					isLoading={pageloading}
					title="All Order"
					columns={[
						{
							title: "Order SN",
							field: "order_id",
							render: (rowData) => (
								<Typography variant="caption">{rowData.order_id}</Typography>
							),
							align: "center",
						},
						{
							title: "Image",
							field: "image",
							render: (rowData) => (
								<img
									className={styles.product_image_wrapper}
									src={rowData.image}
									alt={`${rowData.product_name}`}
								/>
							),
							align: "center",
						},
						{
							title: "Item",
							field: "product_name",
							render: (rowData) => (
								<Typography variant="caption">
									{truncate(rowData.product_name, 30)}
								</Typography>
							),
							align: "center",
						},
						{
							title: "Quantity",
							field: "quantity",
							type: "numeric",
							render: (rowData) => (
								<Typography variant="caption">X {rowData.quantity}</Typography>
							),
							align: "center",
						},
						{
							title: "Total",
							field: "price",
							type: "numeric",
							render: (rowData) => (
								<NumberFormat
									value={rowData.price}
									displayType={"text"}
									thousandSeparator={true}
									prefix={"₦"}
								/>
							),
							align: "center",
						},
						{
							title: "Status",
							field: "status",
							render: (rowData) => (
								<Fragment>
									{!_.isUndefined(rowData.data.order_placed) &&
										rowData.data.order_placed.current === true && (
											<Chip
												icon={
													<LocalMallTwoToneIcon
														style={{
															color: "orange",
														}}
													/>
												}
												label="ORDER PLACED"
												variant="outlined"
												style={{
													color: "orange",
													border: "1px orange solid",
												}}
											/>
										)}
									{!_.isUndefined(rowData.data.pending_confirmation) &&
										rowData.data.pending_confirmation.current === true && (
											<Chip
												icon={
													<ThumbUpTwoToneIcon
														style={{
															color: "orange",
														}}
													/>
												}
												label="PROCESSED"
												variant="outlined"
												style={{
													color: "orange",
													border: "1px orange solid",
												}}
											/>
										)}
									{!_.isUndefined(rowData.data.waiting_to_be_shipped) &&
										rowData.data.waiting_to_be_shipped.current === true && (
											<Chip
												icon={
													<RedeemTwoToneIcon
														style={{
															color: "orange",
														}}
													/>
												}
												label="PENDING DELIVERY"
												variant="outlined"
												style={{
													color: "orange",
													border: "1px orange solid",
												}}
											/>
										)}
									{!_.isUndefined(rowData.data.shipped) &&
										rowData.data.shipped.current === true && (
											<Chip
												icon={
													<LocalShippingTwoToneIcon
														style={{
															color: "orange",
														}}
													/>
												}
												label="SHIPPED"
												variant="outlined"
												style={{
													color: "orange",
													border: "1px orange solid",
												}}
											/>
										)}
									{!_.isUndefined(rowData.data.out_for_delivery) &&
										rowData.data.out_for_delivery.current === true && (
											<Chip
												icon={
													<LocalShippingTwoToneIcon
														style={{
															color: "orange",
														}}
													/>
												}
												label="OUT FOR DELIVERY"
												variant="outlined"
												style={{
													color: "orange",
													border: "1px orange solid",
												}}
											/>
										)}
									{!_.isUndefined(rowData.data.delivered) &&
										rowData.data.delivered.current === true && (
											<Chip
												icon={
													<SentimentVerySatisfiedTwoToneIcon
														style={{
															color: "green",
														}}
													/>
												}
												label="DELIVERED"
												variant="outlined"
												style={{
													color: "green",
													border: "1px green solid",
												}}
											/>
										)}
									{!_.isUndefined(rowData.data.canceled) &&
										rowData.data.canceled.current === true && (
											<Chip
												icon={<PanToolTwoToneIcon style={{ color: "red" }} />}
												label="CANCELLED"
												variant="outlined"
												style={{ color: "red", border: "1px red solid" }}
											/>
										)}
									{!_.isUndefined(rowData.data.returned) &&
										rowData.data.returned.current === true && (
											<Chip
												icon={<ReplayTwoToneIcon style={{ color: "orange" }} />}
												label="RETURNED"
												variant="outlined"
												style={{
													color: "orange",
													border: "1px orange solid",
												}}
											/>
										)}
								</Fragment>
							),
							align: "center",
						},

						{
							title: "Added on",
							field: "added_on",
							render: (rowData) => (
								<Fragment>
									<Typography variant="caption">{rowData.added_on}</Typography>
									<Typography variant="caption">{rowData.createdon}</Typography>
								</Fragment>
							),
						},
						{ title: "createdon", field: "createdon", hidden: true },
						{ title: "Data", field: "data", hidden: true },
					]}
					data={orderData}
					options={{
						search: true,
						actionsColumnIndex: -1,
					}}
					// actions={[
					//   {
					//     icon: "save",
					//     tooltip: "Save User",
					//     onClick: (event, rowData: any) => {
					//       setOrderDataDetails({ ...rowData.data });
					//       handleOrderDetailDialogOpen();
					//     },
					//   },
					// ]}
					// components={{
					//   Action: (props) => (
					//     <Button
					//       aria-label="view-vendor-details"
					//       size="medium"
					//       onClick={(event) => props.action.onClick(event, props.data)}
					//     >
					//       View
					//     </Button>
					//   ),
					// }}
				/>
			</Box>
		</Fragment>
	);
};

export default Orders;
