import React, { useEffect, useState } from "react";

// //react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

// //react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//number format
import NumberFormat from "react-number-format";

//material table
import MaterialTable, { MTableToolbar } from "material-table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
  })
);

const WalletsWidget = () => {
  const styles = useStyles();

  const [persona, setPersona] = useState("Vendor");
  const [tableData, setTableData] = useState<any>([]);
  const [pageloading, setpageloading] = useState(true);

  useFirestoreConnect([
    {
      collection: "Admins",
      where: [["entity", "==", "ekioja-super"]],
      storeAs: "EkiojaAdmins",
    },
    {
      collection: "Stores",
    },
    {
      collection: "Wallets",
    },
  ]);

  const stores = useSelector((state: any) => state.firestore.ordered.Stores);
  const wallets = useSelector((state: any) => state.firestore.ordered.Wallets);

  const ekiojaAdmins = useSelector(
    (state: any) => state.firestore.data["EkiojaAdmins"]
  );

  useEffect(() => {
    if (isLoaded(stores) && isLoaded(ekiojaAdmins) && isLoaded(wallets)) {
      let ekiojaAdmins_data: any[] = [];
      if (ekiojaAdmins !== null) {
        for (var adminkey in ekiojaAdmins) {
          ekiojaAdmins_data.push({
            ...ekiojaAdmins[adminkey],
            admin_uuid: adminkey,
          });
        }
      }

      //intersect story with wallet

      var result_intersect_story_with_wallet = _.intersectionWith(
        _.cloneDeep(stores),
        _.cloneDeep(wallets),
        function (x: any, y: any) {
          return x.unique_id === y.owner_id && _.assign(x, y);
        }
      );

      //intersect ekioja admin with wallet

      var result_intersect_ekiojaAdmins_with_wallet = _.intersectionWith(
        _.cloneDeep(ekiojaAdmins_data),
        _.cloneDeep(wallets),
        function (x: any, y: any) {
          return x.admin_uuid === y.owner_id && _.assign(x, y);
        }
      );

      let data: any = [];

      if (persona === "Vendor") {
        data = _.map(result_intersect_story_with_wallet, (item) => {
          return {
            account_name: item.business_name,
            persona: "Vendor",
            balance: item.income,
          };
        });

        setTableData([...data]);
        setpageloading(false);
      }
      if (persona === "Ekioja") {
        data = _.map(result_intersect_ekiojaAdmins_with_wallet, (item) => {
          return {
            account_name: `${item.first_name} ${item.last_name}`,
            persona: item.role,
            balance: item.income,
          };
        });

        setTableData([...data]);
        setpageloading(false);
      }
      if (persona === "Customer") {
      }
    }
  }, [stores, ekiojaAdmins, wallets, persona]);

  return (
    <div>
      <MaterialTable
        style={{ width: "100%" }}
        isLoading={pageloading}
        title={`${persona} Wallets`}
        columns={[
          {
            title: "Account Name",
            field: "account_name",
            render: (rowData) => (
              <Typography variant="caption" color="primary">
                {rowData.account_name}
              </Typography>
            ),
          },
          { title: "Persona", field: "persona" },

          {
            title: "Balance",
            field: "balance",
            render: (rowData) => (
              <Typography variant="h6" color="primary">
                <NumberFormat
                  value={rowData.balance}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </Typography>
            ),
          },
        ]}
        data={tableData}
        options={{
          search: true,
          actionsColumnIndex: -1,
          exportAllData: true,
          exportButton: true,
        }}
        // actions={[
        //   {
        //     icon: "save",
        //     tooltip: "Save User",
        //     onClick: (event, rowData: any) => {},
        //   },
        // ]}
        components={{
          //   Action: (props) => (
          //     <Button
          //       aria-label="view-vendor-details"
          //       size="medium"
          //       onClick={(event) => props.action.onClick(event, props.data)}
          //     >
          //       View
          //     </Button>
          //   ),
          Toolbar: (props) => (
            <div>
              <Grid container>
                <Grid item lg={3}>
                  <FormControl className={styles.formControl}>
                    <InputLabel id="persona-select-label">
                      {" "}
                      Select persona
                    </InputLabel>
                    <Select
                      labelId="persona-select-label"
                      id="persona-select"
                      value={persona}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setPersona(event.target.value as string);
                      }}
                    >
                      <MenuItem value="Vendor">Vendors</MenuItem>
                      <MenuItem value="Ekioja">Ekioja</MenuItem>
                      <MenuItem value="Customers">Customers</MenuItem>
                    </Select>
                    <FormHelperText>
                      {persona === "Vendor" && "Vendors wallets"}
                      {persona === "Ekioja" && "Ekioja Admins wallets"}
                      {persona === "Customers" && "Customers wallets"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={9}>
                  <MTableToolbar {...props} />
                </Grid>
              </Grid>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default WalletsWidget;
