import React from "react";
import ChatListQueryController from "./ChatListQueryController";

const ChatListQuery = () => {
	return (
		<div>
			<ChatListQueryController />
		</div>
	);
};

export default ChatListQuery;
