import React, { useMemo } from "react";
import firebase from "../../../config/firebaseConfig";
import { useDropzone } from "react-dropzone";
import { Grid, Typography, Button } from "@material-ui/core";

export default function ImagesDropzone({
	setImageList,
	folderToSave,
	id,
}: any) {
	const onDrop = (acceptedFiles: any) => {
		if (acceptedFiles.length > 0) {
			const newImages = Array.from(acceptedFiles).map((file, index) => {
				return {
					file: file,
					fileName: `${id}_${index + 1}`,
					status: "CREATED",
					storageRef: firebase
						.storage()
						.ref()
						.child(`${folderToSave}/${id}_${index + 1}`),
					downloadURL: "",
					description: "",
				};
			});

			setImageList((prevState: any) => [...prevState, ...newImages]);
		}
	};

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		open,
	} = useDropzone({
		onDrop,
		accept: "image/png, image/jpeg",
		noClick: true,
		noKeyboard: true,
		maxFiles: 4,
	});

	const baseStyle = {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "20px",
		borderWidth: 2,
		borderRadius: 2,
		borderColor: "#eeeeee",
		borderStyle: "dashed",
		backgroundColor: "#fafafa",
		color: "#bdbdbd",
		outline: "none",
		transition: "border .24s ease-in-out",
	};

	const activeStyle = {
		borderColor: "#2196f3",
	};

	const acceptStyle = {
		borderColor: "#00e676",
	};

	const rejectStyle = {
		borderColor: "#ff1744",
	};

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject, isDragAccept]
	);

	return (
		// @ts-ignore
		<div {...getRootProps({ style })}>
			<input {...getInputProps()} />
			<Grid container direction="column" spacing={2}>
				<Grid item container direction="column" alignItems="center" spacing={1}>
					<Grid item>
						<Typography align="center">
							{isDragActive
								? "Drop Images here ..."
								: "Drag 'n' drop Images here, or:"}
						</Typography>
						<br />
						<em>(4 files are the maximum number of files you can drop here)</em>
					</Grid>
					<Grid item>
						<Button
							onClick={open}
							variant="contained"
							color="primary"
							style={{ color: "#fff" }}
						>
							Select Images
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
