import { AnyAction } from "redux";

export const initialState = {
	customer_id: "",
	chat_id: "",
	brand_id: "",
	activeIndex: 0,
	openChatActivity: true,
	conversation_list_query: "all",
};

export type InitialState = typeof initialState;

export const chatReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case "SET_CURRENT_CHAT":
			const { customer_id, chat_id } = action.payload;
			return {
				...state,
				customer_id,
				chat_id,
			};
		case "SET_BRAND_CONVERSATION_LIST":
			const { brand_id } = action.payload;
			return {
				...state,
				brand_id,
			};
		case "ACTIVE_CHAT":
			const { activeIndex } = action.payload;
			return {
				...state,
				activeIndex,
			};
		case "OPEN_CHAT_ACTIVITY":
			const { openChatActivity } = action.payload;
			return {
				...state,
				openChatActivity,
			};
		case "SET_CONVERSATION_LIST_QUERY":
			const { conversation_list_query } = action.payload;
			return {
				...state,
				conversation_list_query,
			};

		default: {
			return { ...state };
		}
	}
};
