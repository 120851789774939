import React from "react";

import Typography from "@material-ui/core/Typography";
//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const CreativesMaster = () => {
	const styles = useStyles();
	return (
		<>
			<DashBoardLayout menuIndex={1}>
				<div className={styles.page_header}>
					<Typography variant="h4" color="primary">
						{" "}
						Creatives Management
					</Typography>
				</div>
				<br />
			</DashBoardLayout>
		</>
	);
};

export default CreativesMaster;
