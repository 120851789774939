import { useMutation, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

interface Props {
	store_id_arr: string[];
	package: "starter" | "professional" | "advanced" | "premium";
	picked_package?: boolean;
	ekioja_admin_picked?: boolean;
	ekioja_admin_id?: string;
	picked_package_by_vendor_name?: string;
	picked_package_by_vendor_id?: string;
	is_starter_package?: boolean;
	is_professional_package?: boolean;
	is_advanced_package?: boolean;
	is_premium_package?: boolean;
}

const pickPackageMultiple = (values: Props) => {
	const pickPackagePromises: any = [];
	//starter package
	if (values.package === "starter") {
		for (const store_id of values.store_id_arr) {
			const sp = firebase.firestore().collection("Stores").doc(store_id).set(
				{
					picked_package: values.picked_package,
					ekioja_admin_picked: values.ekioja_admin_picked,
					ekioja_admin_id: values.ekioja_admin_id,
					is_starter_package: values.is_starter_package,
					is_professional_package: false,
					is_advanced_package: false,
					is_premium_package: false,
					picked_package_date: firebase.firestore.FieldValue.serverTimestamp(),
				},
				{ merge: true }
			);
			pickPackagePromises.push(sp);
		}
	}
	//professional package
	if (values.package === "professional") {
		for (const store_id of values.store_id_arr) {
			const prp = firebase.firestore().collection("Stores").doc(store_id).set(
				{
					picked_package: values.picked_package,
					ekioja_admin_picked: values.ekioja_admin_picked,
					ekioja_admin_id: values.ekioja_admin_id,
					is_professional_package: values.is_professional_package,
					is_starter_package: false,
					is_advanced_package: false,
					is_premium_package: false,
					picked_package_date: firebase.firestore.FieldValue.serverTimestamp(),
				},
				{ merge: true }
			);
			pickPackagePromises.push(prp);
		}
	}
	//advanced package
	if (values.package === "advanced") {
		for (const store_id of values.store_id_arr) {
			const ap = firebase.firestore().collection("Stores").doc(store_id).set(
				{
					picked_package: values.picked_package,
					ekioja_admin_picked: values.ekioja_admin_picked,
					ekioja_admin_id: values.ekioja_admin_id,
					is_advanced_package: values.is_advanced_package,
					is_starter_package: false,
					is_professional_package: false,
					is_premium_package: false,
					picked_package_date: firebase.firestore.FieldValue.serverTimestamp(),
				},
				{ merge: true }
			);
			pickPackagePromises.push(ap);
		}
	}
	//premium package
	if (values.package === "premium") {
		for (const store_id of values.store_id_arr) {
			const pp = firebase.firestore().collection("Stores").doc(store_id).set(
				{
					picked_package: values.picked_package,
					ekioja_admin_picked: values.ekioja_admin_picked,
					ekioja_admin_id: values.ekioja_admin_id,
					is_premium_package: values.is_premium_package,
					is_starter_package: false,
					is_professional_package: false,
					is_advanced_package: false,
					picked_package_date: firebase.firestore.FieldValue.serverTimestamp(),
				},
				{ merge: true }
			);
			pickPackagePromises.push(pp);
		}
	}

	return Promise.all([...pickPackagePromises]);
};

const usePickPackageMultiple = () => {
	return useMutation(pickPackageMultiple, {
		onSuccess: () => {
			queryCache.invalidateQueries("allStores");
		},
	});
};

export default usePickPackageMultiple;
