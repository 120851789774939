import React from "react";

//loading screen
import LoadingScreen from "../../components/LoadingScreen";

//link
import { Redirect } from "react-router-dom";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//routes
import * as ROUTES from "../../constants/routes";
import {
	SUPER_ADMINSTRATOR,
	ADMINSTRATOR,
	MANAGER,
	LEGAL,
	LOGISTICS,
	LOGISTICS_DISPATCHER,
	LOGISTICS_MANAGER,
	FINANCE,
	CREATIVE,
	ACREATIVE,
	FCREATIVE,
	MEDIA,
} from "../../constants/roles";

const RedirectBridge = () => {
	const authid = useSelector((state: any) => state.firebase.auth.uid);

	useFirestoreConnect([
		{
			collection: "Admins",
			doc: isLoaded(authid) ? authid : null,
		},
	]);

	const currentUser = useSelector(
		(state: any) => state.firestore.ordered.Admins
	);

	if (!isLoaded(currentUser)) return <LoadingScreen />;
	return (
		<div>
			{currentUser[0].blocked === true && (
				<Redirect to={ROUTES.REVIEW_ADMIN_ACCOUNT} />
			)}
			{currentUser[0].blocked === false &&
				currentUser[0].role === SUPER_ADMINSTRATOR && (
					<Redirect to={ROUTES.DASHBOARD} />
				)}
			{currentUser[0].blocked === false &&
				currentUser[0].role === ADMINSTRATOR && (
					<Redirect to={ROUTES.DASHBOARD} />
				)}
			{currentUser[0].blocked === false && currentUser[0].role === MANAGER && (
				<Redirect to={ROUTES.DASHBOARD} />
			)}
			{currentUser[0].blocked === false && currentUser[0].role === LEGAL && (
				<Redirect to={ROUTES.VET_DASHBOARD} />
			)}
			{currentUser[0].blocked === false &&
				currentUser[0].role === LOGISTICS &&
				currentUser[0].logistics_role === LOGISTICS_MANAGER && (
					<Redirect to={ROUTES.LOGISTICS_DASHBOARD} />
				)}
			{currentUser[0].blocked === false &&
				currentUser[0].role === LOGISTICS &&
				currentUser[0].logistics_role === LOGISTICS_DISPATCHER && (
					<Redirect
						to={`/logistics-dispatcher-bookings/${ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS}`}
					/>
				)}
			{currentUser[0].blocked === false && currentUser[0].role === FINANCE && (
				<Redirect to={ROUTES.FINANCE_DASHBOARD} />
			)}
			{currentUser[0].blocked === false && currentUser[0].role === CREATIVE && (
				<Redirect to={ROUTES.CREATIVE_DASHBOARD} />
			)}
			{currentUser[0].blocked === false &&
				currentUser[0].role === ACREATIVE && (
					<Redirect to={ROUTES.CREATIVE_DASHBOARD} />
				)}
			{currentUser[0].blocked === false &&
				currentUser[0].role === FCREATIVE && (
					<Redirect to={ROUTES.CREATIVE_DASHBOARD} />
				)}
			{currentUser[0].blocked === false && currentUser[0].role === MEDIA && (
				<Redirect to={ROUTES.ADDONS} />
			)}
		</div>
	);
};

export default RedirectBridge;
