import React, { useEffect, useState } from "react";
//react - redux
import { useSelector } from "react-redux";
//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";
//react redux firebase
import { isLoaded, isEmpty } from "react-redux-firebase";
import _ from "lodash";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import PickHotProduct from "./PickHotProduct";
import { truncate } from "../../../utils";
import useRemoveHotPickProduct from "../../../hooks/Product/useRemoveHotPickProduct";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			height: 400,
			position: "relative",
		},
		media: {
			height: 240,
		},
		appBar: {
			position: "relative",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const HotProducts = () => {
	const classes = useStyles();

	const [hotProductsList, setHotProductsList] = useState<any>([]);

	const [removeHotProductId, setRemoveHotProductId] = useState("");

	const [
		RemoveHotPickProductMutate,
		RemoveHotPickProductinfo,
	] = useRemoveHotPickProduct();

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [openConfirmRemove, setOpenConfirmRemove] = React.useState(false);

	const handleClickOpenConfirmRemove = () => {
		setOpenConfirmRemove(true);
	};

	const handleCloseConfirmRemove = () => {
		setOpenConfirmRemove(false);
	};

	useFirestoreConnect([
		{
			collection: "Products",
			where: [
				["is_hot_product", "==", true],
				["visible_on_market", "==", true],
			],
			storeAs: "HotProducts",
		},
	]);

	// const hotProductsStores = useSelector(
	// 	(state: any) => state.firestore.data["HotProductsStores"]
	// );
	const hotProducts = useSelector(
		(state: any) => state.firestore.data["HotProducts"]
	);

	useEffect(() => {
		if (isLoaded(hotProducts) && typeof hotProducts !== "undefined") {
			// let hotProductsStores_data: any[] = [];
			// if (hotProductsStores !== null) {
			// 	for (let hotProductsStoreskey in hotProductsStores) {
			// 		hotProductsStores_data.push({
			// 			...hotProductsStores[hotProductsStoreskey],
			// 			hotProductsStores_uuid: hotProductsStoreskey,
			// 		});
			// 	}
			// }

			let hotProducts_data: any[] = [];
			if (hotProducts !== null) {
				for (let hotProductskey in hotProducts) {
					hotProducts_data.push({
						...hotProducts[hotProductskey],
						hotProductskey_uuid: hotProductskey,
					});
				}
			}

			// let result_products_of_stores = _.intersectionWith(
			// 	_.cloneDeep(hotProductsStores_data),
			// 	hotProducts_data,
			// 	function (x: any, y: any) {
			// 		return x.unique_id === y.entity && _.assign(x, y);
			// 	}
			// );

			// let result_products_of_store =
			// for(let hpicks in hotProductsStores_data){

			// }

			setHotProductsList([...hotProducts_data]);
		}
	}, [hotProducts]);

	return (
		<div>
			<ToastContainer />
			<Dialog
				open={openConfirmRemove}
				onClose={handleCloseConfirmRemove}
				aria-labelledby="remove-hotproduct-confirmation-dialog-title"
				aria-describedby="remove-hotproduct-confirmation-dialog-description"
			>
				<DialogTitle id="remove-hotproduct-confirmation-dialog-title">
					{"Remove Product Confirmation"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="remove-hotproduct-confirmation-dialog-description">
						Are you sure this product should be removed?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseConfirmRemove} color="primary">
						Cancel
					</Button>
					<Button
						onClick={() => {
							RemoveHotPickProductMutate(removeHotProductId, {
								onSuccess: () => {
									toast.success("product removed successfully!", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									handleCloseConfirmRemove();
								},
								onError: () => {
									toast.error("Oops! An error occured!  Try agin", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									handleCloseConfirmRemove();
								},
							});
						}}
						color="primary"
						autoFocus
					>
						{RemoveHotPickProductinfo.isLoading
							? "Removing..."
							: RemoveHotPickProductinfo.isError
							? "Error!"
							: RemoveHotPickProductinfo.isSuccess
							? "Removed!"
							: "Remove"}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar} elevation={0}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							Add Hot Pick Product
						</Typography>
					</Toolbar>
				</AppBar>
				<PickHotProduct />
			</Dialog>
			<div
				style={{
					width: "100%",
					height: 80,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					color="primary"
					startIcon={<Icon>add</Icon>}
					onClick={handleClickOpen}
				>
					ADD HOTPICK PRODUCTS
				</Button>
			</div>

			<br />
			<Divider />
			<br />
			{isLoaded(hotProducts) && _.isEmpty(hotProductsList) ? (
				<div
					style={{
						width: "100%",
						height: 400,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					No Hot products available
				</div>
			) : (
				<></>
			)}
			{!isLoaded(hotProducts) && isEmpty(hotProducts) ? (
				<div
					style={{
						width: "100%",
						height: 400,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress />
				</div>
			) : (
				<></>
			)}
			<Grid container spacing={3}>
				{!_.isEmpty(hotProductsList) &&
					typeof hotProductsList !== "undefined" &&
					hotProductsList.map((item: any, index: any) => (
						<Grid item xs={12} lg={3} key={item.product_id}>
							<Card className={classes.root}>
								<CardActionArea>
									<CardMedia
										className={classes.media}
										image={item.image_1_url}
										title={item.product_name}
									/>
									<CardContent>
										<Typography gutterBottom variant="h5" component="h2">
											{item.product_name}
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											component="p"
										>
											{truncate(item.product_short_description, 60)}
										</Typography>
									</CardContent>
								</CardActionArea>
								<CardActions
									style={{ position: "absolute", bottom: 0, right: 0 }}
								>
									{/* <Button size="small" color="primary">
										View
									</Button> */}
									<Button
										size="small"
										color="primary"
										onClick={() => {
											handleClickOpenConfirmRemove();
											setRemoveHotProductId(item.hotProductskey_uuid);
										}}
									>
										Remove
									</Button>
								</CardActions>
							</Card>
						</Grid>
					))}
			</Grid>
		</div>
	);
};

export default HotProducts;
