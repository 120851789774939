import React, { useEffect, useState } from "react";

// //react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

// //react redux firebase
import { isLoaded } from "react-redux-firebase";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

//material table
import MaterialTable from "material-table";

// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import _ from "lodash";
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAcceptCreativeJob from "../../hooks/creative/brandcreatives/useAcceptCreativeJob";

// const useStyles = makeStyles((theme: Theme) =>
// 	createStyles({
// 		formControl: {
// 			margin: theme.spacing(1),
// 			width: "100%",
// 		},
// 	})
// );

const BrandCreatives = () => {
	const [tableData, setTableData] = useState<any>([]);
	const [pageloading, setpageloading] = useState(true);

	const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
	const [
		AcceptCreativeJobMutate,
		AcceptCreativeJobInfo,
	] = useAcceptCreativeJob();

	const authuid = useSelector((state: any) => state.firebase.auth.uid);
	const profile = useSelector((state: any) => state.firebase.profile);

	const [brandCreativesUID, setBrandCreativesUID] = useState("");

	useFirestoreConnect([
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "BRAND_CREATIVE"],
				["pending_review", "==", true],
			],
			storeAs: "CreativeJobs",
		},
	]);

	const creativeJobs = useSelector(
		(state: any) => state.firestore.data["CreativeJobs"]
	);

	useEffect(() => {
		if (isLoaded(creativeJobs) && typeof creativeJobs !== "undefined") {
			let creative_data: any[] = [];
			if (creativeJobs !== null) {
				for (var item in creativeJobs) {
					creative_data.push({
						...creativeJobs[item],
						creative_job_uuid: item,
					});
				}
			}
			const sorted = _.orderBy(creative_data, ["added_on"], ["desc"]);

			let data: any = [];

			data = _.map(sorted, (item) => {
				return {
					ref: item.reference,
					vendor: item.store_name,
					job: item.job?.join(" & "),
					date: moment(item.added_on?.toDate()).format("MM-DD-YYYY").toString(),
					data: item,
				};
			});

			setTableData([...data]);
			setpageloading(false);
		}
	}, [creativeJobs]);

	return (
		<div>
			<ToastContainer />
			<Dialog
				open={toggleConfirmationModal}
				onClose={() => setToggleConfirmationModal(false)}
				aria-labelledby="confirm-brand-creatives-dialog-title"
				aria-describedby="confirm-brand-creatives-dialog-description"
			>
				<DialogTitle id="confirm-brand-creatives-dialog-title">
					{"Are you  sure?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="confirm-brand-creatives-dialog-description">
						By confirming it means you will handle this job.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setToggleConfirmationModal(false)}
						color="primary"
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							AcceptCreativeJobMutate(
								{
									id: brandCreativesUID,
									authuid: authuid,
									first_name: profile.first_name,
									last_name: profile.last_name,
								},
								{
									onSuccess: () => {
										toast.success("Job confirmed successfully!", {
											position: "top-right",
											autoClose: 5000,
											hideProgressBar: false,
											closeOnClick: true,
										});
									},
									onError: () => {
										toast.error("Oops! An error occured!  Try agin", {
											position: "top-right",
											autoClose: 5000,
											hideProgressBar: false,
											closeOnClick: true,
										});
									},
								}
							);
							setToggleConfirmationModal(false);
						}}
						color="primary"
						autoFocus
						variant="outlined"
					>
						{AcceptCreativeJobInfo.isLoading ? "Processing..." : "Confirm"}
					</Button>
				</DialogActions>
			</Dialog>
			<MaterialTable
				style={{ width: "100%" }}
				isLoading={pageloading}
				title="Brand Creative : Logo and Banner Jobs"
				columns={[
					{
						title: "REF",
						field: "ref",
						render: (rowData) => (
							<Typography variant="caption" color="primary">
								{rowData.ref}
							</Typography>
						),
					},
					{ title: "Vendor", field: "vendor" },
					{ title: "Job", field: "job" },
					{ title: "date", field: "date" },
				]}
				data={tableData}
				options={{
					search: true,
					actionsColumnIndex: -1,
					//   exportAllData: true,
					//   exportButton: true,
				}}
				actions={[
					{
						icon: "save",
						tooltip: "Save User",
						onClick: (event, rowData: any) => {
							setBrandCreativesUID(rowData.data.creative_job_uuid);
							setToggleConfirmationModal(true);
						},
					},
				]}
				components={{
					Action: (props) => (
						<Button
							aria-label="view-vendor-details"
							size="medium"
							variant="contained"
							color="primary"
							onClick={(event) => props.action.onClick(event, props.data)}
						>
							Accept
						</Button>
					),
				}}
			/>
		</div>
	);
};

export default BrandCreatives;
