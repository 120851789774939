import React, { useContext } from "react";
import ChatInputView from "./ChatInputView";
import useSendChatMessage from "../../../../../../hooks/chat/useSendChatMessage";
import { ChatContext } from "../../../../ChatController";

const ChatInputController = () => {
	const [mutation, status] = useSendChatMessage();
	const { chat_id } = useContext(ChatContext);

	return (
		<div>
			<ChatInputView
				handleSendMessage={mutation}
				status={status}
				chatId={chat_id}
			/>
		</div>
	);
};

export default ChatInputController;
