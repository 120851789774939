  
import { useMutation} from "react-query";
import firebase from '../config/firebaseConfig';


const updateStoreDataActive = (values:{id:string,status:boolean})=>{

    return firebase.firestore().collection('Stores').doc(values.id).update({
        avaliable_on_market: values.status
    })
}


 const useUpdateStoreActive = ()=>{
    return useMutation(updateStoreDataActive);
}


export default useUpdateStoreActive;