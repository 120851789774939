import React, { Fragment, useState } from "react";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//material table
import MaterialTable from "material-table";

//material ui components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Container from "@material-ui/core/Container";
import MoneyIcon from "@material-ui/icons/Money";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PaymentIcon from "@material-ui/icons/Payment";

import Avatar from "@material-ui/core/Avatar";

//number format
import NumberFormat from "react-number-format";

//get image
import GetImageFromFirebase from "../components/GetImageFromFirebase";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//formik
import { Field, Form, Formik } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      borderRadius: 20,
      width: "100%",
    },
    toolbar_wrapper: {
      // backgroundColor: '#303030',
      backgroundColor: theme.palette.background.default,
    },
    gutter: {
      height: 30,
      width: "100%",
    },
    business_logo_wrapper: {
      width: 70,
      height: 70,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: 40,
        height: 40,
        borderRadius: 40,
      },
    },
    add_new_product_button: {
      marginTop: "5%",
    },
    queries_wrapper: {
      width: "100%",
      padding: 10,
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    td: {
      padding: 10,
      border: "2px black solid",
    },
    chart_wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: 200,
      borderRadius: 10,
    },
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: 120,
      width: "100%",
      borderRadius: 20,
    },
    money_title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VetLegalDetails = () => {
  const styles = useStyles();

  // const [pageloading, setpageloading] = useState(false);
  // const [tableTitle, setTableTitle] = useState("All vet");
  const [openDetailDialog, setOpenDetailDialog] = useState(false);

  const handleClickDetailDialogOpen = () => {
    setOpenDetailDialog(true);
  };

  const handlDetailDialogeClose = () => {
    setOpenDetailDialog(false);
  };

  return (
    <Fragment>
      <DashBoardLayout menuIndex={11}>
        <Dialog
          fullScreen
          open={openDetailDialog}
          onClose={handlDetailDialogeClose}
          TransitionComponent={Transition}
        >
          <AppBar className={styles.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handlDetailDialogeClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={styles.title}>
                VN-1234-123-12345
              </Typography>
              <Button>Save as Draft</Button>
            </Toolbar>
          </AppBar>
          <Container>
            <Grid container>
              <Grid item xs={12} lg={4}>
                <Typography variant="subtitle2" color="secondary">
                  Actions
                </Typography>
                <Divider />
                <Formik
                  initialValues={{
                    video_call: "",
                    voice_call: "",
                    email: "",
                    aproval: "",
                    comment: "",
                    reason: "",
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      setSubmitting(false);
                    }, 1000);
                  }}
                >
                  {({ submitForm, isSubmitting, errors, touched }) => (
                    <Form>
                      {isSubmitting && <LinearProgress />}
                      <List>
                        <ListItem>
                          <ListItemText
                            primary="Did you video call?"
                            secondary={
                              <Fragment>
                                <Field
                                  component={RadioGroup}
                                  name="video_call"
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio disabled={isSubmitting} />}
                                    label="Yes"
                                    disabled={isSubmitting}
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio disabled={isSubmitting} />}
                                    label="No"
                                    disabled={isSubmitting}
                                  />
                                </Field>
                              </Fragment>
                            }
                          />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary="Did you voice call?"
                            secondary={
                              <Fragment>
                                <Field
                                  component={RadioGroup}
                                  name="voice_call"
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio disabled={isSubmitting} />}
                                    label="Yes"
                                    disabled={isSubmitting}
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio disabled={isSubmitting} />}
                                    label="No"
                                    disabled={isSubmitting}
                                  />
                                </Field>
                              </Fragment>
                            }
                          />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary="Did you Email (not compulsory)?"
                            secondary={
                              <Fragment>
                                <Field component={RadioGroup} name="email" row>
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio disabled={isSubmitting} />}
                                    label="Yes"
                                    disabled={isSubmitting}
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio disabled={isSubmitting} />}
                                    label="No"
                                    disabled={isSubmitting}
                                  />
                                </Field>
                              </Fragment>
                            }
                          />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemText
                            primary="Are you satisfied with this store's information and acknowledge that the store should be approved to sell on Ekioja?"
                            secondary={
                              <Fragment>
                                <Field
                                  component={RadioGroup}
                                  name="approval"
                                  row
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio disabled={isSubmitting} />}
                                    label="Yes"
                                    disabled={isSubmitting}
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio disabled={isSubmitting} />}
                                    label="No"
                                    disabled={isSubmitting}
                                  />
                                </Field>
                              </Fragment>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </List>
                      <Field
                        component={TextField}
                        name="reason"
                        type="text"
                        label="Reason for failing this store?"
                        helperText={"aa"}
                        multiline
                        rows={6}
                        fullWidth
                      />
                      <Divider />
                      <Field
                        component={TextField}
                        name="comment"
                        type="text"
                        label="Comment (optional)"
                        helperText={"aa"}
                        multiline
                        rows={6}
                        fullWidth
                      />
                      <Divider />
                    </Form>
                  )}
                </Formik>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Typography variant="h6" color="secondary">
                  Data from the assessed store
                </Typography>
                <Divider />
                <br />
                <br />
                <table style={{ border: "2px black solid" }}>
                  <tbody>
                    <tr>
                      <td className={styles.td}>&nbsp;Business logo</td>
                      <td className={styles.td}>&nbsp;lllllls</td>
                    </tr>
                    <tr>
                      <td className={styles.td}>&nbsp;Business name</td>
                      <td className={styles.td}>&nbsp;lllllls</td>
                    </tr>
                    <tr>
                      <td className={styles.td}>&nbsp;Business email</td>
                      <td className={styles.td}>&nbsp;lllllls</td>
                    </tr>
                    <tr>
                      <td className={styles.td}>
                        &nbsp;Business website (Optional)
                      </td>
                      <td className={styles.td}>&nbsp;lllllls</td>
                    </tr>
                    <tr>
                      <td className={styles.td}>
                        &nbsp;Corporate Affairs Commission (CAC) number(if
                        available)
                      </td>
                      <td className={styles.td}>&nbsp;lllllls</td>
                    </tr>
                    <tr>
                      <td className={styles.td}>&nbsp;Address</td>
                      <td className={styles.td}>&nbsp;aqsaznjanzak</td>
                    </tr>
                    <tr>
                      <td className={styles.td}>&nbsp;Business description</td>
                      <td className={styles.td}>&nbsp;lllllls</td>
                    </tr>
                    <tr>
                      <td className={styles.td}>&nbsp;Business niche&nbsp;</td>
                      <td className={styles.td}>&nbsp;jznanzkanzka</td>
                    </tr>
                    <tr>
                      <td className={styles.td}>&nbsp;Vendor&nbsp;</td>
                      <td className={styles.td}>&nbsp;jznanzkanzka</td>
                    </tr>

                    <tr>
                      <td className={styles.td}>
                        &nbsp;Our goal is for Ekioja to be a trusted haven for
                        made in Africa products and businesses. To this end,
                        each seller confirms that their business and their
                        products are genuine, authentic, and are trustworthy.
                      </td>
                      <td className={styles.td}>
                        &nbsp;I confirm that my products are authentic and
                        genuine. I confirm I will never upload any product of
                        poor quality to the Ekioja Marketplace. I hereby
                        covenant that all the products I will promote on the
                        Ekioja marketplace are mine, or designed by my business,
                        and are genuine and verifiable
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.td}>
                        &nbsp;Ekioja is a platform to find genuine businesses,
                        and authentic African brands and creators. Our sellers
                        and actual owners of their brands and creators of their
                        products. To maintain the standards that we demand of
                        ourselves, we only deal with actual business owners and
                        product creators. To this end, you guarantee that you
                        are the owner of or person solely in charge of the brand
                        under which this business is created.
                      </td>
                      <td className={styles.td}>
                        &nbsp;I confirm and guarantee that I am the owner,
                        founder, and direct producer, and manager of my named
                        brand and her products. I guarantee that I am not a
                        third party, a phony seller, or a phony contractor.
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.td}>
                        &nbsp;To keep the community and marketplace safe, Ekioja
                        maintains some terms and conditions. To protect both the
                        buyer and seller in all transactions, Ekioja operates an
                        escrow service.
                      </td>
                      <td className={styles.td}>
                        &nbsp;I agree to be bound by the escrow service policy,
                        in protecting all transactions.
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.td}>&nbsp;</td>
                      <td className={styles.td}>
                        &nbsp;I agree to use Ekioja safely and securely, and to
                        do my due diligence in preventing fraud or fraudulent
                        activities.
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.td}>&nbsp;</td>
                      <td className={styles.td}>
                        I affirm that the information I have submitted is to the
                        best of my knowledge accurate and true representation of
                        my business.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Container>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Box className={styles.wrapper} boxShadow={5}>
              <Avatar>HY</Avatar>
              <Typography variant="caption" color="textSecondary">
                username
              </Typography>
              <Typography variant="caption" color="textSecondary">
                full name
              </Typography>
              <Typography variant="caption" color="textSecondary">
                email
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Box className={styles.wrapper} boxShadow={5}>
              <div className={styles.money_title}>
                <MoneyIcon style={{ fontSize: 40 }} />
                <Typography variant="h6" color="textSecondary">
                  Upcoming earnings
                </Typography>
              </div>
              <Typography variant="h6" color="textPrimary" display="block">
                <NumberFormat
                  value={1000000}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Box className={styles.wrapper} boxShadow={5}>
              <div className={styles.money_title}>
                <AccountBalanceIcon style={{ fontSize: 40 }} />
                <Typography variant="h6" color="textSecondary">
                  Pending Clearance
                </Typography>
              </div>
              <Typography variant="h6" color="textPrimary" display="block">
                <NumberFormat
                  value={1000000}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Box className={styles.wrapper} boxShadow={5}>
              <div className={styles.money_title}>
                <LocalAtmIcon style={{ fontSize: 40 }} />
                <Typography variant="h6" color="textSecondary">
                  Earned
                </Typography>
              </div>
              <Typography variant="h6" color="textPrimary" display="block">
                <NumberFormat
                  value={1000000}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Box className={styles.wrapper} boxShadow={5}>
              <div className={styles.money_title}>
                <PaymentIcon style={{ fontSize: 40 }} />
                <Typography variant="h6" color="textSecondary">
                  Withdrawal
                </Typography>
              </div>
              <Typography variant="h6" color="textPrimary" display="block">
                <NumberFormat
                  value={1000000}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <br />
        <br />
        <Box boxShadow={5} className={styles.chart_wrapper}>
          <Typography variant="subtitle1" color="textSecondary">
            chart
          </Typography>
        </Box>
        <br />
        <br />
        <div className={styles.queries_wrapper}>
          <Chip label={`All Vet`} variant="default" />
          <Chip label={`All To be vetted`} variant="default" />
          <Chip label={`All Undervetting`} variant="default" />
          <Chip label={`All Draft`} variant="default" />
          <Chip label={`All Passed`} variant="default" />
          <Chip label={`All Failed `} variant="default" />
        </div>
        <Box className={styles.root} boxShadow={5}>
          <MaterialTable
            style={{ width: "100%" }}
            isLoading={false}
            title={'All Vet'}
            columns={[
              {
                title: "Vet ID",
                field: "vid",
                render: (rowData) => (
                  <Typography variant="caption">{rowData.vid}</Typography>
                ),
              },
              {
                title: "Logo",
                field: "business_logo_id",
                render: (rowData) => (
                  <div className={styles.business_logo_wrapper}>
                    <GetImageFromFirebase source={rowData.business_logo_id} />
                  </div>
                ),
              },
              {
                title: "Store name",
                field: "business_name",
                render: (rowData) => (
                  <Typography variant="caption">
                    {rowData.business_name}
                  </Typography>
                ),
              },
              {
                title: "Address",
                field: "business_location",
                render: (rowData) => (
                  <Typography variant="caption">
                    {rowData.business_location}
                  </Typography>
                ),
              },
              {
                title: "Niche",
                field: "business_niche",
                render: (rowData) => (
                  <Typography variant="caption">
                    {rowData.business_niche}
                  </Typography>
                ),
              },
              {
                title: "Status",
                field: "status",
                render: (rowData) => (
                  <Typography variant="caption">{rowData.status}</Typography>
                ),
              },
              {
                title: "vetted by",
                field: "vetted_by",
                render: (rowData) => (
                  <Typography variant="caption">{rowData.vetted_by}</Typography>
                ),
              },
              {
                title: "Action",
                field: "action",
                render: (rowData) => (
                  <Button onClick={handleClickDetailDialogOpen}>VIEW</Button>
                ),
              },
              //   {
              //     title: "Added",
              //     field: "added",
              //     render: (rowData) => (
              //       <Typography variant="caption">
              //         {rowData.added} <br /> {rowData.createdon}{" "}
              //       </Typography>
              //     ),
              //   },
              //   { title: "Created on", field: "createdon", hidden: true },
            ]}
            data={[
              {
                vid: "VN-1234-123-12345",
                business_logo_id: "4ciGugO6y",
                business_name: "Top signature",
                business_location: "23 msmmms snskjns smos,kns sm",
                business_niche: "xkmxkmxkmxmxsx",
                status: "to_be_vetted",
                vetted_by: "Kayoide ",
              },
            ]}
            options={{
              search: true,
              actionsColumnIndex: -1,
            }}

            // actions={[
            //     {
            //         icon: 'save',
            //         tooltip: 'Save User',
            //         onClick: (event, rowData: any) => { navigateToLink.push(ROUTES.VIEW_PRODUCT_DETAILS, { id: rowData.id }) }
            //     },

            // ]}
          />
        </Box>
      </DashBoardLayout>
    </Fragment>
  );
};

export default VetLegalDetails;
