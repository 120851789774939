import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DashBoardLayout from "../pages/Layouts/DashBoardLayout";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import FBTBList from "../components/DashBoardWidgets/FBTBList";
import MomentList from "../components/DashBoardWidgets/MomentList";
import BrandStory from "../components/DashBoardWidgets/BrandStory";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const Addons = () => {
	const styles = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};
	return (
		<DashBoardLayout>
			<div className={styles.page_header}>
				<Typography variant="h4" color="primary">
					{" "}
					Addons
				</Typography>
			</div>
			<br />
			<div style={{ padding: 15 }}>
				<AppBar position="static">
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="simple tabs example"
					>
						<Tab label="FBTB" {...a11yProps(0)} />
						<Tab label="MOMENT" {...a11yProps(1)} />
						<Tab label="BRAND STORY" {...a11yProps(2)} />
					</Tabs>
				</AppBar>
				<TabPanel value={value} index={0}>
					<FBTBList />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<MomentList />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<BrandStory />
				</TabPanel>
			</div>
		</DashBoardLayout>
	);
};

export default Addons;
