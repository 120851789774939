import { useMutation } from "react-query";
import firebase from "../../../config/firebaseConfig";
// import {referenceNumber} from '../../../utils'

const completeJob = async (values: {
	id: string;
	name: string;
	amount: number;
	walletid: string;
	purpose: string;
	product_id?: string;
}) => {
	const updateCreativeJobPromise = await firebase
		.firestore()
		.collection("CreativeJobs")
		.doc(values.id)
		.set(
			{
				pending_review: false,
				to_be_processed: {
					status: false,
				},
				processing: {
					status: false,
				},
				completed: {
					status: true,
					on: firebase.firestore.FieldValue.serverTimestamp(),
				},
			},
			{ merge: true }
		);

	if (values.product_id) {
		firebase.firestore().collection("Products").doc(values.product_id).set(
			{
				to_be_reviewed: false,
				to_be_resolved: false,
				visible_on_market: true,
				under_creatives_process: false,
			},
			{ merge: true }
		);
	}
	//    const recordTransactionPromise = await firebase.firestore().collection('Transactions').doc().set({
	//     mode: 'CREATIVE_COMMISSION',
	//     payer:'ekioja-super',
	//     payee:values.id,
	//     amount:values.amount,
	//     payer_name:values.name,
	//     metaData:{
	//         purpose:values.purpose
	//     },
	//     reference:referenceNumber(),
	//     type: ['DEBIT_EKIOJA', 'CREDIT_CREATIVE'],
	//     class:'CREATIVE',
	//     status:'SUCCESS',
	//      date: firebase.firestore.FieldValue.serverTimestamp(),
	//  });

	//  const ekiojaWalletDocRef = firebase.firestore().collection('Wallets').doc(process.env.REACT_APP_EKIOJA_WALLET);
	//  const creativeWalletDocRef = firebase.firestore().collection('Wallets').doc(values.walletid);

	//  const addToWallettransactionpromise = await firebase.firestore().runTransaction((transaction) => {
	//      return transaction.get(creativeWalletDocRef).then((transactionDoc) => {
	//          if (!transactionDoc.exists) {

	//          }

	//          const newIncome = transactionDoc.data()?.brand_creatives_balance + values.amount;

	//          transaction.update(creativeWalletDocRef, {
	//             brand_creatives_balance: newIncome,
	//          });

	//      })
	//  })
	//  const deductFromWallettransactionpromise = await firebase.firestore().runTransaction((transaction) => {
	//      return transaction.get(ekiojaWalletDocRef).then((transactionDoc) => {
	//          if (!transactionDoc.exists) {

	//          }

	//          const newIncome = transactionDoc.data()?.income - values.amount;

	//          transaction.update(ekiojaWalletDocRef, {
	//             income: newIncome,
	//          });

	//      })
	//  })

	const allPromise = Promise.all([updateCreativeJobPromise]);
	//  const allPromise  = Promise.all([updateCreativeJobPromise,addToWallettransactionpromise,deductFromWallettransactionpromise,recordTransactionPromise,])

	return allPromise;
};

const useCompleteJob = () => {
	return useMutation(completeJob);
};

export default useCompleteJob;
