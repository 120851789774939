import React from "react";
import ChatListQueryView from "./ChatListQueryView";

const ChatListQueryController = () => {
	return (
		<div>
			<ChatListQueryView />
		</div>
	);
};

export default ChatListQueryController;
