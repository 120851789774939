import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
	makeStyles,
	withStyles,
	Theme,
	createStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//link
import { useParams } from "react-router-dom";

import NumberFormat from "react-number-format";

import {
	Magnifier,
	//   GlassMagnifier,
	//   SideBySideMagnifier,
	//   PictureInPictureMagnifier,
	// MOUSE_ACTIVATION,
	// TOUCH_ACTIVATION,
} from "react-image-magnifiers";

// import StarBorderIcon from "@material-ui/icons/StarBorder";
// import Rating from "@material-ui/lab/Rating";
// import {
// 	EmailShareButton,
// 	FacebookShareButton,
// 	LinkedinShareButton,
// 	PinterestShareButton,
// 	TwitterShareButton,
// 	WhatsappShareButton,
// 	FacebookIcon,
// 	WhatsappIcon,
// 	TwitterIcon,
// 	LinkedinIcon,
// 	EmailIcon,
// 	PinterestIcon,
// } from "react-share";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//interface
//loading screen
import LoadingScreen from "../components/LoadingScreen";

import moment from "moment";

import { EditorState, convertFromRaw } from "draft-js";

import { Editor } from "react-draft-wysiwyg";

import * as ROUTES from "../constants/routes";

//navigation hook
import { useNavigate } from "../hooks/navigate";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`product-detail-tabpanel-${index}`}
			aria-labelledby={`product-detail-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `product-detail-tab-${index}`,
		"aria-controls": `product-detail-tabpanel-${index}`,
	};
}

interface StyledTabProps {
	label: string;
}

const AntTabs = withStyles({
	root: {
		borderBottom: "1px  transparent solid",
	},
	indicator: {
		backgroundColor: "#08C551",
	},
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
	createStyles({
		root: {
			textTransform: "none",
			minWidth: 72,
			fontWeight: theme.typography.fontWeightRegular,
			marginRight: theme.spacing(4),
			fontFamily: [
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
			"&:hover": {
				color: "#08C551ff",
				opacity: 1,
			},
			"&$selected": {
				color: "#08C551",
				fontWeight: theme.typography.fontWeightMedium,
			},
			"&:focus": {
				color: "#08C551",
			},
		},
		selected: {},
	})
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
	carousel_wrapper: {
		width: "100%",
		height: 350,
		borderRadius: 10,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	carousel_options_wrapper: {
		width: "100%",
		height: 163,
		borderRadius: 10,
		backgroundColor: "rgba(0, 0, 0, 0.8)",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		position: "relative",
	},
	carousel_options_overlay: {
		position: "absolute",
		bottom: 0,
		background: "rgba(0, 0, 0, 0.5)",
		color: "#f1f1f1",
		borderRadius: 10,
		width: "100%",
		height: "100%",
		textAlign: "center",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	featured_categories_wrapper: {
		width: "100%",
		height: 160,
		borderRadius: 10,
		backgroundColor: "rgba(0, 0, 0, 0.8)",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundOrigin: "center",
		position: "relative",
		padding: 0,
	},
	featured_categories_option_overlay: {
		position: "absolute",
		bottom: 0,
		background: "rgba(0, 0, 0, 0.5)",
		color: "#f1f1f1",
		borderRadius: 10,
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		paddingRight: 40,
		fontWeight: "bolder",
		fontSize: 22,
		fontFamily: "Lato",
	},
	image_thumbs: {
		cursor: "pointer",
		"&>img": {
			width: 50,
			height: 50,
			borderRadius: 10,
			border: "1px  #000 solid",
		},
	},
	price: {
		color: "green",
		marginRight: 10,
	},
	discount_price: {
		textDecoration: "line-through",
		color: "rgba(194, 188, 188, 0.7)",
	},
	discount_percent: {
		backgroundColor: "green",
		width: 40,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "#fff",
		marginLeft: 10,
		padding: 1,
	},
	tabpanel: {
		minHeight: 300,
	},
	cart_quantity_wrapper: {
		width: 150,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	specificationTdWrapper: {
		border: "1px solid black",
		padding: 20,
	},
}));

const ViewProductDetails = () => {
	const styles = useStyles();

	const { id } = useParams<any>();

	const { navigateToLink } = useNavigate();

	const [data, setData] = useState<any>({});

	const [showImage1, setShowImage1] = useState(true);
	const [showImage2, setShowImage2] = useState(false);
	const [showImage3, setShowImage3] = useState(false);
	const [showImage4, setShowImage4] = useState(false);

	const [detailTabValue, setDetailTabValue] = useState(0);

	const handleDetailTabChange = (
		event: React.ChangeEvent<{}>,
		newValue: number
	) => {
		setDetailTabValue(newValue);
	};

	const content = {
		entityMap: {},
		blocks: [
			{
				key: "637gr",
				text: "Product full description.",
				type: "unstyled",
				depth: 0,
				inlineStyleRanges: [],
				entityRanges: [],
				data: {},
			},
		],
	};
	const contentState = convertFromRaw(content);
	const [productDescription, setProductDescription] = useState({
		editorState: EditorState.createWithContent(contentState),
	});

	useFirestoreConnect([
		{
			collection: "Products",
			doc: id,
		},
	]);

	const productFirestore = useSelector(
		(state: any) => state.firestore.ordered.Products
	);

	useEffect(() => {
		const products = [];
		if (productFirestore !== null) {
			for (const productkey in productFirestore) {
				products.push({
					...productFirestore[productkey],
					product_uuid: productFirestore[productkey].id,
				});
			}
		}
		setData({ ...products[0] });
	}, [productFirestore]);

	useEffect(() => {
		if (isLoaded(productFirestore)) {
			const contentState2 = convertFromRaw(
				JSON.parse(productFirestore[0].product_full_description)
			);

			setProductDescription({
				editorState: EditorState.createWithContent(contentState2),
			});
		}
	}, [productFirestore]);

	if (!isLoaded(productFirestore)) return <LoadingScreen />;

	return (
		<DashBoardLayout menuIndex={0}>
			<Container>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						paddingTop: 10,
					}}
				>
					<Breadcrumbs
						separator={<NavigateNextIcon fontSize="small" />}
						aria-label="breadcrumb"
					>
						<div
							style={{ cursor: "pointer" }}
							onClick={() => {
								navigateToLink.push(ROUTES.VIEWPRODUCTS, {
									query: ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS,
								});
							}}
						>
							All products
						</div>
						{/* <Link component={GatsbyLink} to={ROUTES.SHOP} replace>
            Shop
          </Link> */}
						<Typography color="textPrimary">{data.product_name}</Typography>
					</Breadcrumbs>
					<Button
						variant="contained"
						color="primary"
						style={{ color: "#fff" }}
						aria-label="edit-vendor-details"
						size="large"
						onClick={() => {
							navigateToLink.push(ROUTES.EDIT_PRODUCT, {
								id: data.product_id,
							});
						}}
					>
						Edit
					</Button>
				</div>
				<br />
				<Grid container spacing={4}>
					<Grid item xs={12} lg={8}>
						<div
							style={{
								//backgroundColor: muitheme.palette.background.paper,
								padding: 10,
								borderRadius: 10,
							}}
						>
							<Grid container spacing={4}>
								<Grid item xs={12} lg={5}>
									<div>
										<div style={{ borderRadius: 10, width: 300, height: 250 }}>
											{showImage1 && data.image_1_url !== "" && (
												<Magnifier
													imageSrc={data.image_1_url}
													imageAlt={data.product_permalink}
													style={{
														width: "100%",
														height: 300,
														borderRadius: 20,
													}}
												/>
											)}
											{showImage2 && data.image_2_url !== "" && (
												<Magnifier
													imageSrc={data.image_2_url}
													imageAlt={data.product_permalink}
													style={{
														width: "100%",
														height: 300,
														borderRadius: 20,
													}}
												/>
											)}
											{showImage3 && data.image_3_url !== "" && (
												<Magnifier
													imageSrc={data.image_3_url}
													imageAlt={data.product_permalink}
													style={{
														width: "100%",
														height: 300,
														borderRadius: 20,
													}}
												/>
											)}
											{showImage4 && data.image_4_url !== "" && (
												<Magnifier
													imageSrc={data.image_4_url}
													imageAlt={data.product_permalink}
													style={{
														width: "100%",
														height: 300,
														borderRadius: 20,
													}}
												/>
											)}
										</div>
										<br />
										<br />
										<br />
										<div
											style={{
												display: "flex",
												justifyContent: "space-evenly",
												alignItems: "center",
												marginTop: 10,
											}}
										>
											{data.image_1_url !== "" && (
												<div
													className={styles.image_thumbs}
													onClick={() => {
														setShowImage1(true);
														setShowImage2(false);
														setShowImage3(false);
														setShowImage4(false);
													}}
												>
													<img
														src={data.image_1_url}
														alt={data.product_permalink}
													/>
												</div>
											)}
											{data.image_2_url !== "" && (
												<div
													className={styles.image_thumbs}
													onClick={() => {
														setShowImage1(false);
														setShowImage2(true);
														setShowImage3(false);
														setShowImage4(false);
													}}
												>
													<img
														src={data.image_2_url}
														alt={data.product_permalink}
													/>
												</div>
											)}
											{data.image_3_url !== "" && (
												<div
													className={styles.image_thumbs}
													onClick={() => {
														setShowImage1(false);
														setShowImage2(false);
														setShowImage3(true);
														setShowImage4(false);
													}}
												>
													<img
														src={data.image_3_url}
														alt={data.product_permalink}
													/>
												</div>
											)}
											{data.image_4_url !== "" && (
												<div
													className={styles.image_thumbs}
													onClick={() => {
														setShowImage1(false);
														setShowImage2(false);
														setShowImage3(false);
														setShowImage4(true);
													}}
												>
													<img
														src={data.image_4_url}
														alt={data.product_permalink}
													/>
												</div>
											)}
										</div>
										<br />
										<Divider />
										<br />
										{/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Typography variant="subtitle1" color="inherit">
                        Share :
                      </Typography>
                      <FacebookShareButton
                        quote={data.product_name}
                        hashtag="#ekioja"
                        url={`https://ekioja.com/${data.product_permalink}`}
                      >
                        <FacebookIcon size={24} round />
                      </FacebookShareButton>
                      <WhatsappShareButton
                        url={`https://ekioja.com/${data.product_permalink}`}
                        title={data.product_name}
                        separator=":: "
                      >
                        <WhatsappIcon size={24} round />
                      </WhatsappShareButton>
                      <TwitterShareButton
                        url={`https://ekioja.com/${data.product_permalink}`}
                        title={data.product_name}
                      >
                        <TwitterIcon size={24} round />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={`https://ekioja.com/${data.product_permalink}`}
                        title={data.product_name}
                      >
                        <LinkedinIcon size={24} round />
                      </LinkedinShareButton>
                      <EmailShareButton
                        url={`https://ekioja.com/${data.product_permalink}`}
                        subject={data.product_name}
                        body={`Buy ${data.product_name} from Ekioja`}
                      >
                        <EmailIcon size={24} round />
                      </EmailShareButton>
                      <PinterestShareButton
                        url={`https://ekioja.com/${data.product_permalink}`}
                        media={`https://firebasestorage.googleapis.com/v0/b/ekioja-7bb9f.appspot.com/o/images%2F${data.image_1}?alt=media`}
                      >
                        <PinterestIcon size={24} round />
                      </PinterestShareButton>
                    </div> */}
									</div>
								</Grid>
								<Grid item xs={12} lg={7}>
									<div>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Typography variant="caption" color="inherit">
												Category : {data.product_category}
											</Typography>
											<div style={{ width: 120 }}></div>
										</div>

										<Typography
											variant="caption"
											color="inherit"
											style={{ fontWeight: "bold", fontSize: 21 }}
										>
											{data.product_name}
										</Typography>
										<br />

										<span>{data.product_short_description}</span>
										<br />
										{/* <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Rating
                        name="customized-empty"
                        defaultValue={2}
                        precision={0.5}
                        emptyIcon={
                          <StarBorderIcon
                            fontSize="inherit"
                            style={{ color: "green" }}
                          />
                        }
                        readOnly
                        size="small"
                        style={{ color: "green" }}
                      />
                      <Typography variant="caption" color="inherit">
                        (<Link to="/">501 Ratings</Link>)
                      </Typography>
                    </div> */}
										<br />
										<Divider />

										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												width: "100%",
												flexDirection: "row",
											}}
										>
											<div>
												{data.enable_discount === false && (
													<Typography variant="h6" className={styles.price}>
														<NumberFormat
															value={data.regular_price}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"₦"}
														/>
													</Typography>
												)}
												{data.enable_discount === true && (
													<Typography variant="h6" className={styles.price}>
														<NumberFormat
															value={data.discount_price}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"₦"}
														/>
													</Typography>
												)}

												<div
													style={{
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "center",
													}}
												>
													{data.enable_discount &&
														data.enable_discount_schedule === true &&
														data.discount_price_to_date !== null &&
														moment(
															data.discount_price_to_date.toDate()
														).isBefore(moment()) && (
															<>
																<div
																	style={{
																		display: "flex",
																		justifyContent: "flex-start",
																		alignItems: "center",
																	}}
																>
																	<Typography
																		variant="subtitle2"
																		className={styles.discount_price}
																	>
																		<NumberFormat
																			value={data.regular_price}
																			displayType={"text"}
																			thousandSeparator={true}
																			prefix={"₦"}
																		/>
																	</Typography>
																	<div className={styles.discount_percent}>
																		{`-${data.discount_percent}`}
																	</div>
																</div>
															</>
														)}

													{data.enable_discount &&
														data.enable_discount_schedule === false && (
															<>
																<div
																	style={{
																		display: "flex",
																		justifyContent: "flex-start",
																		alignItems: "center",
																	}}
																>
																	<Typography
																		variant="subtitle2"
																		className={styles.discount_price}
																	>
																		<NumberFormat
																			value={data.regular_price}
																			displayType={"text"}
																			thousandSeparator={true}
																			prefix={"₦"}
																		/>
																	</Typography>
																	<div className={styles.discount_percent}>
																		{`-${data.discount_percent}`}
																	</div>
																</div>
															</>
														)}
												</div>
											</div>
											<div></div>
										</div>
										<Divider />
									</div>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid item xs={12} lg={4}>
						<div
							style={{
								//backgroundColor: muitheme.palette.background.paper,
								padding: 10,
								borderRadius: 10,
							}}
						></div>
					</Grid>
				</Grid>
				<br />
				<br />
				<Grid container>
					<Grid item xs={12} lg={12}>
						<AntTabs
							value={detailTabValue}
							onChange={handleDetailTabChange}
							aria-label="product detail tab"
						>
							<AntTab label="Description" {...a11yProps(0)} />
							<AntTab label="Specifications" {...a11yProps(1)} />
							<AntTab label="Reviews" {...a11yProps(3)} />
						</AntTabs>
						<div
							style={{
								//backgroundColor: muitheme.palette.background.paper,
								padding: 10,
								borderRadius: 10,
								marginTop: 5,
							}}
						>
							<TabPanel value={detailTabValue} index={0}>
								<div className={styles.tabpanel}>
									{/* {!isSSR && (
                    <Editor
                      editorState={productDescription.editorState}
                      readOnly={true}
                      toolbarHidden
                    />
                  )} */}

									<Editor
										editorState={productDescription.editorState}
										readOnly={true}
										toolbarHidden
									/>
								</div>
							</TabPanel>
							<TabPanel value={detailTabValue} index={1}>
								<div className={styles.tabpanel}>
									<div style={{ width: 600, paddingTop: 50 }}>
										<table
											style={{
												border: "1px solid black",
												borderCollapse: "collapse",
												width: "100%",
											}}
										>
											{data.product_attribute_1_title === "" && (
												<p>No specification</p>
											)}
											{data.product_attribute_1_title !== "" && (
												<tr>
													<td className={styles.specificationTdWrapper}>
														{data.product_attribute_1_title}
													</td>
													<td className={styles.specificationTdWrapper}>
														{data.product_attribute_1_value}
													</td>
												</tr>
											)}
											{data.product_attribute_2_title !== "" && (
												<tr>
													<td className={styles.specificationTdWrapper}>
														{data.product_attribute_2_title}
													</td>
													<td className={styles.specificationTdWrapper}>
														{data.product_attribute_2_value}
													</td>
												</tr>
											)}
											{data.product_attribute_3_title !== "" && (
												<tr>
													<td className={styles.specificationTdWrapper}>
														{data.product_attribute_3_title}
													</td>
													<td className={styles.specificationTdWrapper}>
														{data.product_attribute_3_value}
													</td>
												</tr>
											)}
											{data.product_attribute_4_title !== "" && (
												<tr>
													<td className={styles.specificationTdWrapper}>
														{data.product_attribute_4_title}
													</td>
													<td className={styles.specificationTdWrapper}>
														{data.product_attribute_4_value}
													</td>
												</tr>
											)}
										</table>
									</div>
								</div>
							</TabPanel>
							<TabPanel value={detailTabValue} index={2}>
								<div className={styles.tabpanel}>Coming soon...</div>
							</TabPanel>
						</div>
					</Grid>
				</Grid>
			</Container>
		</DashBoardLayout>
	);
};

export default ViewProductDetails;
