import React from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
//styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		form_wrapper: {
			padding: 5,
			borderRadius: 20,
			width: "100%",
			height: "auto",
			marginBottom: 10,
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		step: {
			backgroundColor: "#F5F9FA",
			padding: 10,
		},

		checkbox: {
			color: "#08c551",
		},
		appBar: {
			position: "relative",
			backgroundColor: "transparent",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		photo_wrapper: {
			width: "100%",
			height: 300,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& > img": {
				width: 250,
				height: 250,
			},
		},
		logo_wrapper: {
			width: "100%",
			height: 300,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderRadius: 100,
			"& > img": {
				width: 100,
				height: 100,
				borderRadius: 100,
			},
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const MomentDetail = ({
	data,
	open,
	handleClose,
}: {
	data: any;
	open: boolean;
	handleClose: () => void;
}) => {
	const styles = useStyles();

	return (
		<div>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar className={styles.appBar} elevation={0}>
					<Toolbar>
						<IconButton
							edge="start"
							color="primary"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography
							variant="h6"
							color="textPrimary"
							className={styles.title}
						>
							{data.business_name} - Moment
						</Typography>
					</Toolbar>
				</AppBar>

				<Grid container spacing={4}>
					<Grid item xs={12} lg={3}></Grid>
					<Grid item xs={12} lg={6}>
						<Typography
							variant="body1"
							style={{ color: "#000", marginBottom: 10, marginTop: 10 }}
							align="center"
						>
							<b>Details</b>
						</Typography>
						<br />
						<Grid container spacing={2}>
							<Grid item xs={12} lg={6}>
								<div className={styles.logo_wrapper}>
									<img src={data.store_logo_url} alt={data.business_name} />
								</div>
							</Grid>
							<Grid item xs={12} lg={6}>
								<div className={styles.photo_wrapper}>
									<img src={data.photo_of_founder_url} alt={data.full_name} />
								</div>
							</Grid>
						</Grid>
						<br />
						<List>
							<ListItem>
								<ListItemText primary="Full Name" secondary={data.full_name} />
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Business Phone Number"
									secondary={data.business_phone_number}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Brand/Business Name"
									secondary={data.business_name}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Business email"
									secondary={data.business_email}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Can we meet you? Tell us about your brand."
									secondary={data.meet_you}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Tell us about your journey (How old is your brand, starting from when you first had the idea)"
									secondary={data.about_journey}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What are the key hurdles you had to face to make this a reality?"
									secondary={data.problem_faced}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What tradeoffs, I mean those things, habits, life, etc. you had to give up your business?"
									secondary={data.tradeoffs}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What was your strongest pillar(s) all through those formative days/years?"
									secondary={data.strongest_pillar}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What one basic thing you would do differently, if you were starting again today, and this thing slowed you down a bit or could have ruined your business?"
									secondary={data.do_differently}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What one message do you have for those entrepreneur and vendors in the dugout, still in the obscure phase, trying to still make sense of their idea?"
									secondary={data.take_away}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What key steps did you take to begin seeing some of the results you have today?"
									secondary={data.key_steps}
								/>
							</ListItem>
						</List>
					</Grid>
					<Grid item xs={12} lg={3}></Grid>
				</Grid>
			</Dialog>
		</div>
	);
};

export default MomentDetail;
