import { useQuery, queryCache } from "react-query";
import firebase from "../../../config/firebaseConfig";

const getAllMomentList = async () => {
	const response = await firebase.firestore().collection("Moments").get();
	let data: any = [];
	response.forEach((doc: any) => {
		data.push({ id: doc.id, ...doc.data() });
	});
	return data;
};

const useGetAllMomentList = () => {
	return useQuery("allMomentList", getAllMomentList, {
		initialData: () => {
			if (queryCache.getQueryData("allMomentList")) {
				return queryCache.getQueryData("allMomentList");
			}
		},
		refetchOnWindowFocus: false,
	});
};

export default useGetAllMomentList;
