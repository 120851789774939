import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
// //react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

// //react redux firebase
import { isLoaded } from "react-redux-firebase";
//styles
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

//number format
import NumberFormat from "react-number-format";

//@ts-ignore
import AsyncSelect from "react-select/async";

import firebase from "../../config/firebaseConfig";

//material table
import MaterialTable, { MTableToolbar } from "material-table";

import _ from "lodash";

import { getTransactionsTimeFrame } from "../../utils";

import { JsonToTable } from "react-json-to-table";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    width: "fit-content",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const loadShopNameOptions = async (inputValue: string) => {
  inputValue = inputValue.toLowerCase().replace(/\W/g, "");
  return new Promise((resolve) => {
    firebase
      .firestore()
      .collection("Stores")
      // .where('type_of_story', '==', 'Shop')
      .orderBy("shop_plainName")
      .startAt(inputValue)
      .endAt(inputValue + "\uf8ff")
      .get()
      .then((docs) => {
        if (!docs.empty) {
          const shopNames: any[] = [];
          docs.forEach(function (doc) {
            const tag = {
              value: doc.data().unique_id,
              label: doc.data().business_name,
            };
            shopNames.push(tag);
          });
          return resolve(shopNames);
        } else {
          return resolve([]);
        }
      });
  });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
  })
);

const TransactionWidget = () => {
  const styles = useStyles();

  const [classquery, setClassquery] = useState("VENDOR");
  const [mode, setMode] = useState("SIGNUP_FEE");
  const [type, setType] = useState(["CREDIT_EKIOJA"]);
  const [vendorId, setVendorId] = useState("");

  const [pageloading, setpageloading] = useState(true);
  const [tableData, setTableData] = useState<any>([]);
  const [transactionData, setTransactionData] = useState<any>([]);

  const [timeFrameValue, setTimeFrameValue] = useState("today");

  const handleClassChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setClassquery(event.target.value as string);
    if ((event.target.value as string) === "LEGAL") {
      setMode("LEGAL_COMMISSION");
      setType(["CREDIT_LEGAL"]);
      setVendorId("");
      setTimeFrameValue("today");
    }

    if ((event.target.value as string) === "CREATIVE") {
      setMode("CREATIVE_COMMISSION");
      setType(["CREDIT_CREATIVE"]);
      setVendorId("");
      setTimeFrameValue("today");
    }
    if ((event.target.value as string) === "LOGISTICS") {
      setMode("LOGISTICS_COMMISSION");
      setType(["CREDIT_LOGISTICS"]);
      setVendorId("");
      setTimeFrameValue("today");
    }
  };
  const handleModeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMode(event.target.value as string);
  };
  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType([event.target.value as string]);
  };
  //   const handleTimeFrameChange = (
  //     event: React.ChangeEvent<{ value: unknown }>
  //   ) => {
  //     setTimeFrameValue(event.target.value as string);
  //   };

  const handleOnShopSearchChange = (tag: any) => {
    setVendorId(tag.value);
  };

  useFirestoreConnect([
    {
      collection: "Transactions",
      where: [
        ["class", "==", classquery],
        ["mode", "==", mode],
        ["type", "array-contains-any", type],
      ],
      storeAs: "Transactions",
    },
  ]);

  const Transactions = useSelector(
    (state: any) => state.firestore.data["Transactions"]
  );

  useEffect(() => {
    if (isLoaded(Transactions)) {
      const transactions: any = [];
      for (let transactionItem in Transactions) {
        transactions.push({
          ...Transactions[transactionItem],
          transactionItem_uuid: transactionItem,
        });
      }

      let vendorSpecificTransactions: any = [];
      if (vendorId === "") {
        vendorSpecificTransactions = _.map(transactions, (item) => item);
      } else {
        vendorSpecificTransactions = _.filter(
          transactions,
          (item) => item.payer === vendorId
        );
      }

      const data = _.map(vendorSpecificTransactions, (item) => {
        return {
          mode: item.mode,
          payer: item.payer,
          payee: item.payee,
          amount: item.amount,
          payer_name: item.payer_name,
          reference: item.reference,
          type: _.map(item.type, (item) => item + ","),
          class: item.class,
          date: item.date,
          metaData: item.metaData,
        };
      });
      setpageloading(false);
      setTransactionData([...data]);
      setTableData([...data]);
    }
  }, [Transactions, vendorId]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <FormControl className={styles.formControl}>
            <InputLabel id="class-select-label">Class</InputLabel>
            <Select
              labelId="class-select-label"
              id="class-select"
              value={classquery}
              onChange={handleClassChange}
            >
              <MenuItem value="EKIOJA">EKIOJA</MenuItem>
              <MenuItem value="VENDOR">VENDOR</MenuItem>
              <MenuItem value="CUSTOMER">CUSTOMER</MenuItem>
              <MenuItem value="LEGAL">LEGAL</MenuItem>
              <MenuItem value="LOGISTICS">LOGISTICS</MenuItem>
              <MenuItem value="CREATIVE">CREATIVE</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/** vendor queries */}
        {classquery === "VENDOR" && (
          <Grid item xs={12} lg={3}>
            <FormControl className={styles.formControl}>
              <InputLabel id="vendor-mode-select-label">Mode</InputLabel>
              <Select
                labelId="vendor-mode-select-label"
                id="vendor-mode-select"
                value={mode}
                onChange={handleModeChange}
              >
                <MenuItem value="SIGNUP_FEE">SIGNUP FEE</MenuItem>
                <MenuItem value="PROMOTE_PRODUCT">PROMOTE PRODUCT</MenuItem>
                <MenuItem value="SALE_REVENUE">SALE REVENUE</MenuItem>
                <MenuItem value="REFUND">REFUND</MenuItem>
                <MenuItem value="VENDOR_PAYOUT">VENDOR PAYOUT</MenuItem>
                <MenuItem value="PRODUCT_STORY">PRODUCT STORY</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {classquery === "VENDOR" && (
          <Grid item xs={12} lg={3}>
            <FormControl className={styles.formControl}>
              <InputLabel id="vendor-type-select-label">Type</InputLabel>
              <Select
                labelId="vendor-type-select-label"
                id="vendor-type-select"
                value={type}
                onChange={handleTypeChange}
              >
                <MenuItem value="CREDIT_EKIOJA">CREDIT EKIOJA</MenuItem>
                <MenuItem value="DEBIT_EKIOJA">DEBIT EKIOJA</MenuItem>
                <MenuItem value="CREDIT_VENDOR">CREDIT VENDOR</MenuItem>
                <MenuItem value="DEBIT_VENDOR">DEBIT VENDOR</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {classquery === "VENDOR" && (
          <Grid item xs={12} lg={3}>
            <div style={{ marginTop: 20 }} />
            <AsyncSelect
              loadOptions={loadShopNameOptions}
              onChange={handleOnShopSearchChange}
            />
          </Grid>
        )}
        {/** end vendor queries */}

        {/** legal queries */}
        {classquery === "LEGAL" && (
          <Grid item xs={12} lg={3}>
            <FormControl className={styles.formControl}>
              <InputLabel id="legal-mode-select-label">Mode</InputLabel>
              <Select
                labelId="legal-mode-select-label"
                id="legal-mode-select"
                value={mode}
                onChange={handleModeChange}
              >
                <MenuItem value="LEGAL_COMMISSION">LEGAL COMMISSION</MenuItem>
                <MenuItem value="LEGAL_SALARY">LEGAL SALARY</MenuItem>
                <MenuItem value="LEGAL_PAYOUT">LEGAL PAYOUT</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {classquery === "LEGAL" && (
          <Grid item xs={12} lg={3}>
            <FormControl className={styles.formControl}>
              <InputLabel id="legal-type-select-label">Type</InputLabel>
              <Select
                labelId="legal-type-select-label"
                id="legal-type-select"
                value={type}
                onChange={handleTypeChange}
              >
                <MenuItem value="CREDIT_EKIOJA">CREDIT EKIOJA</MenuItem>
                <MenuItem value="DEBIT_EKIOJA">DEBIT EKIOJA</MenuItem>
                <MenuItem value="CREDIT_LEGAL">CREDIT LEGAL</MenuItem>
                <MenuItem value="DEBIT_LEGAL">DEBIT LEGAL</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {/** end  legal queries */}

        {/** creatives queries */}
        {classquery === "CREATIVE" && (
          <Grid item xs={12} lg={3}>
            <FormControl className={styles.formControl}>
              <InputLabel id="creatives-mode-select-label">Mode</InputLabel>
              <Select
                labelId="creatives-mode-select-label"
                id="creatives-mode-select"
                value={mode}
                onChange={handleModeChange}
              >
                <MenuItem value="CREATIVE_COMMISSION">
                  CREATIVE COMMISSION
                </MenuItem>
                <MenuItem value="CREATIVE_SALARY">CREATIVE SALARY</MenuItem>
                <MenuItem value="CREATIVE_PAYOUT">CREATIVE PAYOUT</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {classquery === "CREATIVE" && (
          <Grid item xs={12} lg={3}>
            <FormControl className={styles.formControl}>
              <InputLabel id="creatives-type-select-label">Type</InputLabel>
              <Select
                labelId="creatives-type-select-label"
                id="creatives-type-select"
                value={type}
                onChange={handleTypeChange}
              >
                <MenuItem value="CREDIT_EKIOJA">CREDIT EKIOJA</MenuItem>
                <MenuItem value="DEBIT_EKIOJA">DEBIT EKIOJA</MenuItem>
                <MenuItem value="CREDIT_CREATIVE">CREDIT CREATIVE</MenuItem>
                <MenuItem value="DEBIT_CREATIVE">DEBIT CREATIVE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {/** end  creatives queries */}

        {/** logistics queries */}
        {classquery === "LOGISTICS" && (
          <Grid item xs={12} lg={3}>
            <FormControl className={styles.formControl}>
              <InputLabel id="logistics-mode-select-label">Mode</InputLabel>
              <Select
                labelId="logistics-mode-select-label"
                id="logistics-mode-select"
                value={mode}
                onChange={handleModeChange}
              >
                <MenuItem value="LOGISTICS_COMMISSION">
                  LOGISTICS COMMISSION
                </MenuItem>
                <MenuItem value="LOGISTICS_SALARY">LOGISTICS SALARY</MenuItem>
                <MenuItem value="LOGISTICS_PAYOUT">LOGISTICS PAYOUT</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {classquery === "LOGISTICS" && (
          <Grid item xs={12} lg={3}>
            <FormControl className={styles.formControl}>
              <InputLabel id="logistics-type-select-label">Type</InputLabel>
              <Select
                labelId="logistics-type-select-label"
                id="logistics-type-select"
                value={type}
                onChange={handleTypeChange}
              >
                <MenuItem value="CREDIT_EKIOJA">CREDIT EKIOJA</MenuItem>
                <MenuItem value="DEBIT_EKIOJA">DEBIT EKIOJA</MenuItem>
                <MenuItem value="CREDIT_LOGISTICS">CREDIT LOGISTICS</MenuItem>
                <MenuItem value="DEBIT_LOGISTICS">DEBIT LOGISTICS</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {/** end  logistics queries */}
      </Grid>
      <br />
      <MaterialTable
        style={{ width: "100%" }}
        isLoading={pageloading}
        title={`${classquery} Transactions`}
        columns={[
          {
            title: "Reference Number",
            field: "reference",
            render: (rowData) => (
              <Typography variant="caption" color="primary">
                {rowData.reference}
              </Typography>
            ),
          },
          { title: "Payer", field: "payer" },
          { title: "Payee", field: "payee" },
          {
            title: "Amount",
            field: "amount",
            render: (rowData) => (
              <Typography variant="h6" color="primary">
                <NumberFormat
                  value={rowData.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </Typography>
            ),
          },
          { title: "Class", field: "class" },
          { title: "Mode", field: "mode" },
          { title: "Type", field: "type" },
          {
            title: "Meta Data",
            field: "metaData",
            render: (rowData) => (
              <HtmlTooltip
                arrow
                placement="left-start"
                title={
                  <React.Fragment>
                    <JsonToTable json={rowData.metaData} />
                  </React.Fragment>
                }
              >
                <Button size="small">view meta data</Button>
              </HtmlTooltip>
            ),
          },
        ]}
        data={tableData}
        options={{
          search: true,
          actionsColumnIndex: -1,
          exportAllData: true,
          exportButton: true,
        }}
        // actions={[
        //   {
        //     icon: "save",
        //     tooltip: "Save User",
        //     onClick: (event, rowData: any) => {},
        //   },
        // ]}
        components={{
          //   Action: (props) => (
          //     <Button
          //       aria-label="view-vendor-details"
          //       size="medium"
          //       onClick={(event) => props.action.onClick(event, props.data)}
          //     >
          //       View
          //     </Button>
          //   ),
          Toolbar: (props) => (
            <div>
              <Grid container>
                <Grid item lg={3}>
                  <FormControl className={styles.formControl}>
                    <InputLabel id="timeframe-select-label">
                      Time Frame
                    </InputLabel>
                    <Select
                      labelId="timeframe-select-label"
                      id="timeframe-select"
                      value={timeFrameValue}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setTimeFrameValue(event.target.value as string);
                        setTableData(
                          getTransactionsTimeFrame(
                            transactionData,
                            event.target.value as string
                          )
                        );
                      }}
                    >
                      <MenuItem value="today">Today</MenuItem>
                      <MenuItem value="within-a-week">Within a week</MenuItem>
                      <MenuItem value="within-a-month">Within a month</MenuItem>
                      <MenuItem value="over-two-weeks">
                        Over two weeks ago
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {timeFrameValue === "today" && "Within today"}
                      {timeFrameValue === "within-a-week" &&
                        "Within the past 7 days"}
                      {timeFrameValue === "within-a-month" &&
                        "Within the past 30 days"}
                      {timeFrameValue === "over-two-weeks" &&
                        "Over two weeks ago or more"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={9}>
                  <MTableToolbar {...props} />
                </Grid>
              </Grid>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default TransactionWidget;
