import { INVALID_EMAIL, USER_NOT_FOUND } from '../../../constants/forgotpassworderrors';
import { FEEDBACK } from '../../../constants/feedback';


export const SendPasswordReset = (credential: { email: string }) => (dispatch: any, getState: any, { getFirebase }: any) => {
    const firebase = getFirebase();

    return firebase.auth().sendPasswordResetEmail(credential.email)
        .then(() => {
            dispatch({ type: FEEDBACK, payload: { severity: 'success', title: 'Link sent!', body: 'Password reset link has been sent to your email, go click on it' } })
        })
        .catch((error: any) => {

            var errorCode = error.code;

            switch (errorCode) {
                case USER_NOT_FOUND:
                    dispatch({ type: FEEDBACK, payload: { severity: 'error', title: 'User not found', body: "Oops! for some reasons this user is not found" } })
                    break;
                case INVALID_EMAIL:
                    dispatch({ type: FEEDBACK, payload: { severity: 'error', title: 'Invalid email', body: "Oops! This email is invalid! Check the format and try again" } })
                    break;
                default:
                    break;
            }

        })
}