import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const addKYCReportEntry = (values:any) => {
	return firebase
		.firestore()
		.collection("KYCReports")
		.doc()
		.set({
			...values,
			added_on: firebase.firestore.FieldValue.serverTimestamp(),
		});
};

const useAddKYCReportEntry = () => {
	return useMutation(addKYCReportEntry);
};

export default useAddKYCReportEntry;
