import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
import { queryCache } from "react-query";

const approveAdmin = (id: string) => {
	return firebase.firestore().collection("Admins").doc(id).update({
		approved: true,
	});
};

const useApproveAdmin = () => {
	return useMutation(approveAdmin, {
		// // Always refetch after error or success:
		onSettled: () => {
			queryCache.invalidateQueries("allEkiojaAdmins");
		},
	});
};

export default useApproveAdmin;
