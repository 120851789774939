import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import SearchVendorChat from "./components/SearchVendorChat";
import VendorList from "./components/VendorList";

const useStyles = makeStyles({
	root: { width: 250 },
});
const ChatListQueryView = () => {
	const styles = useStyles();
	return (
		<div className={styles.root}>
			<SearchVendorChat />
			<Divider />
			<VendorList />
		</div>
	);
};

export default ChatListQueryView;
