import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
import { queryCache } from "react-query";

const unBlockAdmin = (id: string) => {
	return firebase.firestore().collection("Admins").doc(id).update({
		blocked: false,
	});
};

const useUnBlockLogisticsAdmin = () => {
	return useMutation(unBlockAdmin, {
		// // Always refetch after error or success:
		onSuccess: () => {
			queryCache.invalidateQueries("allLogisticsAdmins");
		},
	});
};

export default useUnBlockLogisticsAdmin;
