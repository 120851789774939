import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
import { queryCache } from "react-query";
const blockAdmin = (id: string) => {
	return firebase.firestore().collection("Admins").doc(id).update({
		blocked: true,
	});
};

const useBlockAdmin = () => {
	return useMutation(blockAdmin, {
		// // Always refetch after error or success:
		onSuccess: () => {
			queryCache.invalidateQueries("allEkiojaAdmins");
		},
	});
};

export default useBlockAdmin;
