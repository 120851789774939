import React, { ReactNode, useState, Fragment } from "react";

//material ui components
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";

import FormControlLabel from "@material-ui/core/FormControlLabel";

//material icons
import IconButton from "@material-ui/core/IconButton";
// import MailIcon from '@material-ui/icons/Mail';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone";
import StoreMallDirectoryTwoToneIcon from "@material-ui/icons/StoreMallDirectoryTwoTone";
import ListAltTwoToneIcon from "@material-ui/icons/ListAltTwoTone";
import SupervisedUserCircleTwoToneIcon from "@material-ui/icons/SupervisedUserCircleTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import AccountBalanceWalletTwoToneIcon from "@material-ui/icons/AccountBalanceWalletTwoTone";
import ToysTwoToneIcon from "@material-ui/icons/ToysTwoTone";
import PersonOutlineTwoToneIcon from "@material-ui/icons/PersonOutlineTwoTone";
import PowerIcon from '@material-ui/icons/Power';
import GavelIcon from "@material-ui/icons/Gavel";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import AccountBalanceTwoToneIcon from "@material-ui/icons/AccountBalanceTwoTone";
import BarChartIcon from "@material-ui/icons/BarChart";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CategoryIcon from "@material-ui/icons/Category";
import ChatIcon from "@material-ui/icons/Chat";
//images
import Gold from "../../assets/images/gold.svg";
import Silver from "../../assets/images/silver.svg";
import Logo from "../../assets/images/svg/ekioja-logo.svg";

//material ui styles
import {
	createStyles,
	makeStyles,
	Theme,
	fade,
	createMuiTheme,
	ThemeProvider,
} from "@material-ui/core/styles";

import { Navigation } from "react-minimal-side-navigation";

import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

//link
import { Link, useLocation } from "react-router-dom";

//react - redux
import { useSelector } from "react-redux";

//routes
import * as ROUTES from "../../constants/routes";
import {
	SUPER_ADMINSTRATOR,
	ADMINSTRATOR,
	MANAGER,
	LEGAL,
	LOGISTICS,
	LOGISTICS_MANAGER,
	LOGISTICS_DISPATCHER,
	FINANCE,
	CREATIVE,
	ACREATIVE,
	FCREATIVE,
	MEDIA,
} from "../../constants/roles";

//firebase
import firebase from "../../config/firebaseConfig";

//navigation hook
import { useNavigate } from "../../hooks/navigate";

import LoadingBar from "react-redux-loading-bar";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			background: "rgba(225,225,225,0.2)",
			// background:
			// 	"linear-gradient(45deg, rgba(105, 255, 135, 1) 80%, rgba(105, 255, 135, 0.1) 10%)",
			// boxShadow: '0 3px 5px 2px rgba(105, 255, 135, .3)',
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		hide: {
			display: "none",
		},
		drawer: {
			width: drawerWidth,
			// height: "100vh",
			// flexShrink: 0,
			whiteSpace: "nowrap",
		},
		drawerOpen: {
			width: drawerWidth,
			overflowY: "hidden",
			overflowX: "hidden",
			height: 1000,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowY: "hidden",
			overflowx: "hidden",
			height: 1000,
			width: 100,
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9) + 1,
			},
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		content: {
			width: "100vw",
		},
		grow: {
			flexGrow: 1,
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		title: {
			display: "none",
			[theme.breakpoints.up("sm")]: {
				display: "block",
				marginRight: 40,
			},
		},
		search: {
			position: "relative",
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.15),
			"&:hover": {
				backgroundColor: fade(theme.palette.common.white, 0.25),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.spacing(3),
				width: "auto",
			},
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: "100%",
			position: "absolute",
			pointerEvents: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		inputRoot: {
			color: "inherit",
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create("width"),
			width: "400px",
			[theme.breakpoints.up("md")]: {
				width: "20ch",
			},
		},
		sectionDesktop: {
			display: "none",
			[theme.breakpoints.up("md")]: {
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			},
		},
		sectionMobile: {
			display: "flex",
			[theme.breakpoints.up("md")]: {
				display: "none",
			},
		},
		drawer_wrapper: {
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			width: "auto",
			// height: "100vh",
		},
		role_icon_wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			width: 60,
			height: 60,
			marginRight: 10,
			"& img": {
				width: 30,
				height: "auto",
			},
		},
		logo_wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			width: 100,
			height: 60,
			marginRight: 10,
			"& img": {
				width: 90,
				height: "auto",
			},
		},
		store_small_logo: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			width: 60,
			height: 60,
			marginRight: 2,
			"& img": {
				width: 50,
				height: "auto",
			},
		},
		yet_to_pay_msg_wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
			height: "auto",
			padding: 10,
			backgroundColor: "#00fff2",
			marginBottom: 20,
			borderRadius: 10,
		},
		nested_menu_item: {
			paddingLeft: theme.spacing(4),
		},
		notifiction_appBar: {
			position: "relative",
		},
	})
);

interface Props {
	children: ReactNode;
	menuIndex?: number;
}

const DashBoardLayout = ({ children, menuIndex }: Props) => {
	// @ts-ignore
	const styles = useStyles();
	const { navigateToLink } = useNavigate();

	const location = useLocation();
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [
		mobileMoreAnchorEl,
		setMobileMoreAnchorEl,
	] = useState<null | HTMLElement>(null);

	const profile = useSelector((state: any) => state.firebase.profile);

	const [darkState, setDarkState] = useState(false);
	const palletType = darkState ? "dark" : "light";
	const mainPrimaryColor = darkState ? "#4caf50" : "#388e3c";
	const mainSecondaryColor = darkState ? "#ffc400" : "#00e676";
	const darkTheme = createMuiTheme({
		palette: {
			type: palletType,
			primary: {
				main: mainPrimaryColor,
			},
			secondary: {
				main: mainSecondaryColor,
			},
		},
		typography: {
			fontFamily: `"Work Sans", Arial, sans-serif`,
		},
	});

	const handleThemeChange = () => {
		setDarkState(!darkState);
		if (darkState) localStorage.setItem("isDarkTheme", "no");
		if (!darkState) localStorage.setItem("isDarkTheme", "yes");
	};

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem>{profile.username}</MenuItem>
			<MenuItem component={Link} to={ROUTES.ACCOUNT}>
				Profile
			</MenuItem>
			<MenuItem onClick={() => firebase.auth().signOut()}>Sign Out</MenuItem>
		</Menu>
	);

	const mobileMenuId = "primary-search-account-menu-mobile";
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem component={Link} to={ROUTES.ACCOUNT}>
				<IconButton
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<p>Profile</p>
			</MenuItem>
			<MenuItem>{profile.username}</MenuItem>
			<MenuItem onClick={() => firebase.auth().signOut()}>Sign Out</MenuItem>
		</Menu>
	);

	return (
		<ThemeProvider theme={darkTheme}>
			<div>
				{/* <CssBaseline /> */}
				<div className="relative min-h-screen">
					<main className="w-full min-h-screen">
						<div className=" relative flex h-screen">
							<div
								onClick={() => setIsSidebarOpen(false)}
								className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
									isSidebarOpen ? "block" : "hidden"
								}`}
							/>
							<AppBar position="fixed">
								<Toolbar>
									<IconButton
										color="inherit"
										aria-label="open drawer"
										onClick={() => setIsSidebarOpen(true)}
										edge="start"
									>
										<MenuIcon />
									</IconButton>

									<div className={styles.logo_wrapper}>
										<img src={Logo} alt="ekioja" />
									</div>

									<div className={styles.grow} />

									<div className={styles.sectionDesktop}>
										<Typography variant="h6" color="textPrimary">
											<span role="img">👋</span>Hey,
											{profile.first_name}
										</Typography>

										<IconButton
											edge="end"
											aria-label="account of current user"
											aria-controls={menuId}
											aria-haspopup="true"
											onClick={handleProfileMenuOpen}
											color="inherit"
										>
											<AccountCircle />
										</IconButton>
									</div>
									<div className={styles.sectionMobile}>
										<IconButton
											aria-label="show more"
											aria-controls={mobileMenuId}
											aria-haspopup="true"
											onClick={handleMobileMenuOpen}
											color="inherit"
										>
											<MoreIcon />
										</IconButton>
									</div>
									<LoadingBar />
								</Toolbar>
							</AppBar>
							{renderMobileMenu}
							{renderMenu}

							<div
								className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
									isSidebarOpen
										? "ease-out translate-x-0"
										: "ease-in -translate-x-full"
								}`}
							>
								<div className="flex items-center mt-10  justify-center pt-6">
									<FormControlLabel
										control={
											<Switch
												checked={darkState}
												onChange={handleThemeChange}
											/>
										}
										label={
											darkState ? "Turn off Dark mode" : "Turn on Dark mode"
										}
									/>
								</div>
								<div className={styles.sectionMobile}>
									<Typography variant="h6" color="textPrimary" align="center">
										<span role="img">👋</span> Hey,
										{profile.first_name}
									</Typography>
								</div>
								<div className="flex items-center justify-center bg-gray-200 p-4">
									<div className={styles.role_icon_wrapper}>
										{profile.role === SUPER_ADMINSTRATOR && (
											<img src={Gold} alt="ekioja super administrator" />
										)}
										{profile.role === SUPER_ADMINSTRATOR && (
											<>
												{" "}
												<Typography variant="caption" align="center">
													Super Administrator
												</Typography>
											</>
										)}
										{profile.role === ADMINSTRATOR && (
											<img src={Gold} alt="ekioja administrator" />
										)}
										{profile.role === ADMINSTRATOR && (
											<Typography variant="caption">Administrator</Typography>
										)}
										{profile.role === MANAGER && (
											<img src={Silver} alt="ekioja manager" />
										)}
										{profile.role === MANAGER && (
											<Typography variant="caption">Manager</Typography>
										)}
										{profile.role === LEGAL && (
											<GavelIcon style={{ fontSize: 30 }} />
										)}
										{profile.role === LEGAL && (
											<Typography variant="caption">Legal</Typography>
										)}
										{profile.role === LOGISTICS && (
											<LocalShippingIcon style={{ fontSize: 30 }} />
										)}
										{profile.role === LOGISTICS &&
											profile.logistics_role === LOGISTICS_MANAGER && (
												<Typography variant="caption">Manager</Typography>
											)}
										{profile.role === LOGISTICS &&
											profile.logistics_role === LOGISTICS_DISPATCHER && (
												<Typography variant="caption">Dispatcher</Typography>
											)}
										{profile.role === FINANCE && (
											<BarChartIcon style={{ fontSize: 30 }} />
										)}
										{profile.role === FINANCE && (
											<Typography variant="caption">Finance</Typography>
										)}
										{profile.role === CREATIVE && (
											<BubbleChartIcon style={{ fontSize: 30 }} />
										)}
										{profile.role === CREATIVE && (
											<Typography variant="caption">Creative</Typography>
										)}
										{profile.role === ACREATIVE && (
											<BubbleChartIcon style={{ fontSize: 30 }} />
										)}
										{profile.role === ACREATIVE && (
											<Typography variant="caption">Creative</Typography>
										)}
										{profile.role === FCREATIVE && (
											<BubbleChartIcon style={{ fontSize: 30 }} />
										)}
										{profile.role === FCREATIVE && (
											<Typography variant="caption">Creative</Typography>
										)}
										{profile.role === MEDIA && (
											<BubbleChartIcon style={{ fontSize: 30 }} />
										)}
										{profile.role === MEDIA && (
											<Typography variant="caption">Media</Typography>
										)}
									</div>
								</div>
								<div
									style={{
										width: "100%",
										height: "65vh",
										overflowY: "auto",
									}}
								>
									{profile.role === SUPER_ADMINSTRATOR ? (
										<Navigation
											activeItemId={location.pathname}
											items={[
												{
													title: "Dashboard",
													itemId: ROUTES.DASHBOARD,
													elemBefore: () => <DashboardTwoToneIcon />,
												},
												{
													title: "Stores",
													itemId: `/stores/${ROUTES.VIEWALLSTORESQUERIES.ALLSTORES}`,
													elemBefore: () => <StoreMallDirectoryTwoToneIcon />,
												},
												{
													title: "Products",
													itemId: `/products/${ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS}`,
													elemBefore: () => <ToysTwoToneIcon />,
												},
												{
													title: "Orders",
													itemId: `/orders/${ROUTES.VIEWORDERSQUERIES.ALL_ORDERS}`,
													elemBefore: () => <ListAltTwoToneIcon />,
												},
												{
													title: "Vetting",
													itemId: `/legal/${ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS}`,
													elemBefore: () => <VerifiedUserIcon />,
												},
												{
													title: "Chats",
													itemId: ROUTES.CHATS,
													elemBefore: () => <ChatIcon />,
												},
												{
													title: "Reports",
													itemId: ROUTES.KYCREPORTS,
													elemBefore: () => <AssessmentIcon />,
												},
												{
													title: "Addons",
													itemId: ROUTES.ADDONS,
													elemBefore: () => <CategoryIcon />,
												},
												{
													title: "Logistics",
													itemId: `/master-logistics/${ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS}`,
													elemBefore: () => <LocalShippingIcon />,
												},
												{
													title: "Creatives Jobs",
													itemId: `${ROUTES.CREATIVES_MASTER}/all/brand-creative`,
													elemBefore: () => <BubbleChartIcon />,
												},
												{
													title: "Interested Vendors",
													itemId: ROUTES.INTERESTED_VENDORS,
													elemBefore: () => <PersonOutlineTwoToneIcon />,
												},
												{
													title: "Integrations",
													itemId: ROUTES.INTEGRATIONS,
													elemBefore: () => <PowerIcon />,
												},
												{
													title: "Admins",
													itemId: ROUTES.ADMINS_MANAGEMENT,
													elemBefore: () => <SupervisedUserCircleTwoToneIcon />,
												},
												{
													title: "Wallet",
													itemId: ROUTES.WALLET,
													elemBefore: () => <AccountBalanceWalletTwoToneIcon />,
												},
												{
													title: "Finance",
													itemId: ROUTES.PAYOUT_REQUESTS,
													elemBefore: () => <AccountBalanceTwoToneIcon />,
												},
												{
													title: "Ekioja Settings",
													itemId: "#",
													elemBefore: () => <SettingsTwoToneIcon />,
													subNav: [
														{
															title: "Categories / Niches",
															itemId: ROUTES.CATEGORIES,
														},
													],
												},
											]}
											onSelect={({ itemId }) => {
												navigateToLink.push(itemId);
											}}
										/>
									) : null}
									{profile.role === ADMINSTRATOR ? (
										<Navigation
											activeItemId={location.pathname}
											items={[
												{
													title: "Dashboard",
													itemId: ROUTES.DASHBOARD,
													elemBefore: () => <DashboardTwoToneIcon />,
												},
												{
													title: "Stores",
													itemId: `/stores/${ROUTES.VIEWALLSTORESQUERIES.ALLSTORES}`,
													elemBefore: () => <StoreMallDirectoryTwoToneIcon />,
												},
												{
													title: "Products",
													itemId: `/products/${ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS}`,
													elemBefore: () => <ToysTwoToneIcon />,
												},
												{
													title: "Orders",
													itemId: `/orders/${ROUTES.VIEWORDERSQUERIES.ALL_ORDERS}`,
													elemBefore: () => <ListAltTwoToneIcon />,
												},
												{
													title: "Vetting",
													itemId: `/legal/${ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS}`,
													elemBefore: () => <VerifiedUserIcon />,
												},
												{
													title: "KYCReports",
													itemId: ROUTES.KYCREPORTS,
													elemBefore: () => <AssessmentIcon />,
												},
												{
													title: "Addons",
													itemId: ROUTES.ADDONS,
													elemBefore: () => <CategoryIcon />,
												},
												{
													title: "Logistics",
													itemId: `/master-logistics/${ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS}`,
													elemBefore: () => <LocalShippingIcon />,
												},
												{
													title: "Creatives Jobs",
													itemId: `${ROUTES.CREATIVES_MASTER}/all/brand-creative`,
													elemBefore: () => <BubbleChartIcon />,
												},
												{
													title: "Interested Vendors",
													itemId: ROUTES.INTERESTED_VENDORS,
													elemBefore: () => <PersonOutlineTwoToneIcon />,
												},

												{
													title: "Ekioja Settings",
													itemId: "#",
													elemBefore: () => <SettingsTwoToneIcon />,
													subNav: [
														{
															title: "Categories / Niches",
															itemId: ROUTES.CATEGORIES,
														},
													],
												},
											]}
											onSelect={({ itemId }) => {
												navigateToLink.push(itemId);
											}}
										/>
									) : null}
									{profile.role === MEDIA ? (
										<Navigation
											activeItemId={location.pathname}
											items={[
												{
													title: "Addons",
													itemId: ROUTES.ADDONS,
													elemBefore: () => <CategoryIcon />,
												},
											]}
											onSelect={({ itemId }) => {
												navigateToLink.push(itemId);
											}}
										/>
									) : null}
									{profile.role === LEGAL ? (
										<Navigation
											activeItemId={location.pathname}
											items={[
												{
													title: "Dashboard",
													itemId: ROUTES.VET_DASHBOARD,
													elemBefore: () => <DashboardTwoToneIcon />,
												},
												{
													title: "Vet",
													itemId: `/vet/${ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS}`,
													elemBefore: () => <VerifiedUserIcon />,
												},
												{
													title: "Wallet",
													itemId: ROUTES.VET_WALLET,
													elemBefore: () => <AccountBalanceWalletTwoToneIcon />,
												},
											]}
											onSelect={({ itemId }) => {
												navigateToLink.push(itemId);
											}}
										/>
									) : null}
									{profile.role === LOGISTICS &&
									profile.logistics_role === LOGISTICS_MANAGER ? (
										<Navigation
											activeItemId={location.pathname}
											items={[
												{
													title: "Dashboard",
													itemId: ROUTES.LOGISTICS_DASHBOARD,
													elemBefore: () => <DashboardTwoToneIcon />,
												},
												{
													title: "Bookings",
													itemId: `/logistics/${ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS}`,
													elemBefore: () => <LocalShippingIcon />,
												},
												{
													title: "Wallet",
													itemId: ROUTES.LOGISTICS_WALLET,
													elemBefore: () => <AccountBalanceWalletTwoToneIcon />,
												},
												{
													title: "Team",
													itemId: ROUTES.LOGISTICS_ADMINS_MANAGEMENT,
													elemBefore: () => <SupervisedUserCircleTwoToneIcon />,
												},
											]}
											onSelect={({ itemId }) => {
												navigateToLink.push(itemId);
											}}
										/>
									) : null}
									{profile.role === LOGISTICS &&
									profile.logistics_role === LOGISTICS_DISPATCHER ? (
										<Navigation
											activeItemId={location.pathname}
											items={[
												{
													title: "Dashboard",
													itemId: `/logistics-dispatcher-bookings/${ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS}`,
													elemBefore: () => <LocalShippingIcon />,
												},
											]}
											onSelect={({ itemId }) => {
												navigateToLink.push(itemId);
											}}
										/>
									) : null}

									{profile.role === CREATIVE ||
									profile.role === ACREATIVE ||
									profile.role === FCREATIVE ? (
										<Navigation
											activeItemId={location.pathname}
											items={[
												{
													title: "Dashboard",
													itemId: ROUTES.CREATIVE_DASHBOARD,
													elemBefore: () => <DashboardTwoToneIcon />,
												},
												{
													title: "Jobs",
													itemId: `/creatives/brand-creative/all`,
													elemBefore: () => <BubbleChartIcon />,
												},
												{
													title: "Wallet",
													itemId: ROUTES.CREATIVE_WALLET,
													elemBefore: () => <AccountBalanceWalletTwoToneIcon />,
												},
											]}
											onSelect={({ itemId }) => {
												navigateToLink.push(itemId);
											}}
										/>
									) : null}

									<Navigation
										activeItemId={location.pathname}
										items={[
											{
												title: "Account",
												itemId: ROUTES.ACCOUNT,
												elemBefore: () => <AccountCircleTwoToneIcon />,
											},
										]}
										onSelect={({ itemId }) => {
											navigateToLink.push(itemId);
										}}
									/>
								</div>
							</div>

							<div className="flex flex-col flex-1 overflow-y-auto">
								<main>
									<section className="sm:flex-row flex flex-col flex-1">
										<div style={{ marginTop: 60, width: "100%" }}>
											{profile.isLoaded && children}
										</div>
									</section>
								</main>
							</div>
						</div>
					</main>
				</div>
			</div>
		</ThemeProvider>
	);
};

export default DashBoardLayout;
