import React, { Fragment } from "react";
import ChatActivityController from "./ChatActivityController";
import { Conversation } from "../../../ConservationList/ConservationItem";

interface Props {
	data: Conversation;
}

const ChatActivity = (props: Props) => {
	return (
		<Fragment>
			<ChatActivityController data={props.data} />
		</Fragment>
	);
};

export default ChatActivity;
