  
import { useQuery } from "react-query";
import firebase from '../../config/firebaseConfig';


const checkBrandCreatedJob = async (key:any,id:string) =>{
  return firebase.firestore().collection('CreativeJobs').where('store_id','==',id).where('type','==','BRAND_CREATIVE').get().then(function(querySnapshot) {
        let data:any = []
        querySnapshot.forEach(function(doc) {
            
            data.push({uuid:id,...doc.data()})
        });

        return data
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
}


 const useCheckBrandCreatedJob = (id:string)=>{
    return useQuery(['checkCreativeJobs',id],checkBrandCreatedJob)
}


export default useCheckBrandCreatedJob;