
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import { FEEDBACK } from '../../../constants/feedback'

export const ConfirmForVetting = (id: string, by:string,status: true | false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Stores').doc(id).set({
            paid_for_review: status,
            paid_for_review_status_by:by,
        },{merge:true})
            .then(() => {
                if (status) {
                    dispatch({ type: FEEDBACK, payload: { severity: 'success', title: 'The Store is Confirmed for Vetting', body: ' The store will now be vetted by the legal admin.' } })
                }
                
            });
           

    }