import React, { useReducer, createContext } from "react";
import ChatView from "./ChatView";
import {
	chatReducer,
	initialState,
	InitialState,
} from "./chatStore/chatReducer";

export const ChatContext = createContext({
	customer_id: "",
	chat_id: "",
	brand_id: "",
	activeIndex: 0,
	openChatActivity: false,
	conversation_list_query: "all",
	setCurrentChat: (payload: Partial<InitialState>): any => {},
	setCurrentBrandConversation: (payload: Partial<InitialState>): any => {},
	setActiveChat: (payload: Pick<InitialState, "activeIndex">): any => {},
	handleOpenChatActivity: (
		payload: Pick<InitialState, "openChatActivity">
	): any => {},
	setConversationListQuery: (
		payload: Pick<InitialState, "conversation_list_query">
	): any => {},
});

const ChatController = () => {
	const [state, dispatch] = useReducer(chatReducer, initialState);

	const setCurrentChat = (payload: Partial<InitialState>) => {
		return dispatch({ type: "SET_CURRENT_CHAT", payload });
	};

	const setCurrentBrandConversation = (payload: Partial<InitialState>) => {
		return dispatch({ type: "SET_BRAND_CONVERSATION_LIST", payload });
	};

	const setActiveChat = (payload: Pick<InitialState, "activeIndex">) => {
		return dispatch({ type: "ACTIVE_CHAT", payload });
	};
	const handleOpenChatActivity = (
		payload: Pick<InitialState, "openChatActivity">
	) => {
		return dispatch({ type: "OPEN_CHAT_ACTIVITY", payload });
	};

	const setConversationListQuery = (
		payload: Pick<InitialState, "conversation_list_query">
	) => {
		return dispatch({ type: "SET_CONVERSATION_LIST_QUERY", payload });
	};
	return (
		<div>
			<ChatContext.Provider
				value={{
					...state,
					setCurrentChat,
					setCurrentBrandConversation,
					setActiveChat,
					handleOpenChatActivity,
					setConversationListQuery,
				}}
			>
				<ChatView />
			</ChatContext.Provider>
		</div>
	);
};

export default ChatController;
