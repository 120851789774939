import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DashBoardLayout from "./Layouts/DashBoardLayout";
import LinearProgress from "@material-ui/core/LinearProgress";
import useGetAllLogisticsCompanyList from "../hooks/logistics/useGetAllLogisticsCompanyList";
import ViewLogisticsData from "../components/Logistics/ViewLogisticsData";
import Icon from "@material-ui/core/Icon";
import moment from "moment";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialTable, { MTableToolbar } from "material-table";
import Chip from "@material-ui/core/Chip";
import BeenhereTwoToneIcon from "@material-ui/icons/BeenhereTwoTone";
import BlockTwoToneIcon from "@material-ui/icons/BlockTwoTone";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//formik
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";

import Grid from "@material-ui/core/Grid";

import { LOGISTICS_MANAGER } from "../constants/roles";

//react - redux
import { useSelector } from "react-redux";
//yup
import * as yup from "yup";

import useInviteNewLogisticsPartner from "../hooks/logistics/useInviteNewLogisticsPartner";

//admin validation schema
const AdminValidationSchema = yup.object().shape({
	potential_user_email: yup.string().email().required("Email is Required"),
	role: yup.string().required("The Role is Required"),
	logistics_company_name: yup
		.string()
		.required("The Logistics company name is Required"),
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
		add_new_user_button: {
			marginTop: "5%",
			marginLeft: 50,
		},
	})
);

const LogisticsEkiojaMaster = () => {
	const styles = useStyles();

	const [open, setOpen] = React.useState(false);
	const [unique_id, setUnique_id] = React.useState("");
	const [addNewUserModalState, setAddNewUserModalState] = useState(false);
	const toggleAddNewUserModal = () => {
		setAddNewUserModalState(!addNewUserModalState);
	};
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const currentUser = useSelector((state: any) => state.firebase.profile);

	const GetAllLogisticsCompanyListQuery = useGetAllLogisticsCompanyList();

	const [
		InviteNewLogisticsPartnerMutate,
		InviteNewLogisticsPartnerInfo,
	] = useInviteNewLogisticsPartner();

	const [tableData, setTableData] = useState<any>([]);

	useEffect(() => {
		if (GetAllLogisticsCompanyListQuery.isSuccess) {
			const list = GetAllLogisticsCompanyListQuery.data;

			const sortedList = _.orderBy(list, ["joined_on"], ["desc"]);

			const result = _.map(sortedList, (item) => {
				return {
					logistics_company_name: item.logistics_company_name,
					managers: _.has(item, "managers") ? item.managers.length : 1,
					dispatchers: _.has(item, "dispatchers") ? item.dispatchers.length : 0,
					active: item.active,
					approved: item.approved,
					blocked: item.blocked,
					data: item,
					added_on: moment(item.joined_on.toDate()).fromNow(),
					createdon: moment(item.joined_on.toDate()).format("MM/DD/YYYY"),
					id: item.id,
				};
			});

			setTableData([...result]);
		}
	}, [
		GetAllLogisticsCompanyListQuery.isSuccess,
		GetAllLogisticsCompanyListQuery.data,
	]);

	return (
		<div>
			<DashBoardLayout menuIndex={11}>
				<div className={styles.page_header}>
					<Typography variant="h4" color="primary">
						{" "}
						Logistics Management
					</Typography>
				</div>
				<Dialog
					onClose={toggleAddNewUserModal}
					aria-labelledby="add-new-user-dialog"
					open={addNewUserModalState}
					maxWidth="sm"
					fullWidth={true}
				>
					<DialogTitle>Invite Logistics partner</DialogTitle>
					<DialogContent>
						<Formik
							initialValues={{
								logistics_company_name: "",
								potential_user_email: "",
								role: LOGISTICS_MANAGER,
								invited_by: currentUser.first_name,
							}}
							validationSchema={AdminValidationSchema}
							onSubmit={(values, { setSubmitting, resetForm }) => {
								setTimeout(() => {
									setSubmitting(false);
									InviteNewLogisticsPartnerMutate(values, {
										onSuccess: () => {
											resetForm();
											InviteNewLogisticsPartnerInfo.reset();
											// toggleAddNewUserModal();
										},
									});
								}, 3000);
							}}
						>
							{({ submitForm, isSubmitting, errors, touched }) => (
								<Form>
									<Grid container>
										<Grid item xs={12} lg={2}></Grid>
										<Grid item xs={12} lg={8}>
											{isSubmitting && <LinearProgress />}
											<Field
												component={TextField}
												name="potential_user_email"
												style={{ width: "100%" }}
												placeholder="Email of the person you want to add"
												type="email"
												required
											/>

											<br />
											<br />
											<Field
												component={TextField}
												name="logistics_company_name"
												style={{ width: "100%" }}
												placeholder="Logistics Company Name"
												type="text"
											/>
											<br />
											<br />
											{!errors.potential_user_email &&
											!errors.role &&
											touched.potential_user_email &&
											touched.logistics_company_name &&
											!errors.logistics_company_name ? (
												<Button
													variant="contained"
													color="primary"
													disabled={
														isSubmitting ||
														InviteNewLogisticsPartnerInfo.isSuccess
													}
													onClick={submitForm}
												>
													{InviteNewLogisticsPartnerInfo.isLoading
														? "Processing..."
														: InviteNewLogisticsPartnerInfo.isError
														? "Error!"
														: InviteNewLogisticsPartnerInfo.isSuccess
														? "Done!"
														: "	Send Invite"}
												</Button>
											) : (
												""
											)}
										</Grid>
										<Grid item xs={12} lg={2}></Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</DialogContent>
					<DialogActions>
						<Button
							variant="text"
							color="secondary"
							onClick={() => setAddNewUserModalState(false)}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>

				<ViewLogisticsData
					open={open}
					handleClose={handleClose}
					unique_id={unique_id}
				/>
				<div
					style={{
						padding: 15,
					}}
				>
					<MaterialTable
						style={{
							width: "100%",
						}}
						isLoading={
							GetAllLogisticsCompanyListQuery.isLoading ||
							GetAllLogisticsCompanyListQuery.isFetching
						}
						title="All Logistics companies"
						columns={[
							{
								title: "Name",
								field: "logistics_company_name",
								cellStyle: {
									backgroundColor: "rgba(34, 179, 88, 1)",
									color: "#FFF",
									padding: 10,
								},
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 1)",
									color: "#FFF",
								},
							},
							{
								title: "Managers",
								field: "managers",
								type: "numeric",
								cellStyle: {
									width: 200,
								},

								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
							},
							{
								title: "Dispatchers",
								field: "dispatchers",
								type: "numeric",
								cellStyle: {
									width: 200,
								},

								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
							},

							{
								title: "Approved",
								field: "approved",
								type: "boolean",
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
								render: (rowData) =>
									rowData.approved ? (
										<Chip
											icon={<BeenhereTwoToneIcon />}
											label="Approved"
											variant="outlined"
											color="secondary"
										/>
									) : (
										<Chip
											icon={<BlockTwoToneIcon />}
											label="No"
											variant="outlined"
											color="secondary"
											style={{ color: "red", border: "1px red solid" }}
										/>
									),
							},
							{
								title: "Blocked",
								field: "blocked",
								type: "boolean",
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
								render: (rowData) =>
									rowData.blocked ? (
										<Chip
											icon={<BeenhereTwoToneIcon />}
											label="Blocked"
											variant="outlined"
											color="secondary"
										/>
									) : (
										<Chip
											icon={<BlockTwoToneIcon />}
											label="No"
											variant="outlined"
											color="secondary"
											style={{ color: "red", border: "1px red solid" }}
										/>
									),
							},
							{
								title: "active",
								field: "active",
								type: "boolean",
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
								render: (rowData) =>
									rowData.active ? (
										<Chip
											icon={<BeenhereTwoToneIcon />}
											label="Active"
											variant="outlined"
											color="secondary"
										/>
									) : (
										<Chip
											icon={<BlockTwoToneIcon />}
											label="No"
											variant="outlined"
											color="secondary"
											style={{ color: "red", border: "1px red solid" }}
										/>
									),
							},
							{
								title: "Joined",
								field: "added_on",
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
							},
							{
								title: ".",
								field: "action",
								render: (rowData) => (
									<Button
										onClick={() => {
											setUnique_id(rowData.id);
											handleClickOpen();
										}}
										color="primary"
									>
										View
									</Button>
								),
							},
							{ title: "id", field: "id", hidden: true },
							{ title: "data", field: "data", hidden: true },
						]}
						data={tableData}
						options={{
							search: true,
							actionsColumnIndex: -1,

							rowStyle: {
								borderLeft: "6px solid green",
							},
						}}
						components={{
							Toolbar: (props) => (
								<div>
									<Grid container>
										<Grid item lg={4}>
											<Tooltip title="Add a new admin" aria-label="add-admin">
												<Button
													variant="outlined"
													color="primary"
													className={styles.add_new_user_button}
													startIcon={<Icon>add</Icon>}
													onClick={toggleAddNewUserModal}
												>
													Add New Logistics partner
												</Button>
											</Tooltip>
										</Grid>
										<Grid item lg={8}>
											<MTableToolbar {...props} />
										</Grid>
									</Grid>
								</div>
							),
						}}
					/>
				</div>
			</DashBoardLayout>
		</div>
	);
};

export default LogisticsEkiojaMaster;
