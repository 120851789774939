import React, { Fragment, useState, useEffect } from "react";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//material table
import MaterialTable from "material-table";

//material ui components
// import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import EditIcon from "@material-ui/icons/Edit";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LineStyleIcon from "@material-ui/icons/LineStyle";
//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//navigation hook
import { useNavigate } from "../../../hooks/navigate";

//routes
import * as ROUTES from "../../../constants/routes";

import _ from "lodash";

// import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

import ProcessBrandCreativesJob from "./dialogs/ProcessBrandCreativesJob";
import CompletedBrandCreativesJob from "./dialogs/CompletedBrandCreativesJob";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			position: "relative",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		root: {
			display: "flex",

			width: "100%",
		},

		queries_wrapper: {
			width: "100%",
			padding: 10,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
	})
);

const BrandCreativesJobs = ({ query }: { query?: string }) => {
	const styles = useStyles();
	const { navigateToLink } = useNavigate();
	const [tableTitle, setTableTitle] = useState("");
	const [pageloading, setpageloading] = useState(true);
	const [tableData, setTableData] = useState<any>([]);
	const [openProcess, setOpenProcess] = React.useState(false);
	const [processJobData, setProcessJobData] = useState<any>({});
	const [typeofJob, setTypeofJob] = useState("");
	const handleClickOpenProcess = () => {
		setOpenProcess(true);
	};

	const handleCloseProcess = () => {
		setOpenProcess(false);
	};

	// const [viewStoriesData, setViewStoriesData] = useState<any>({});
	// const [openViewVideoModal, setOpenViewVideoModal] = useState(false);

	useFirestoreConnect([
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "BRAND_CREATIVE"],
				["to_be_processed.status", "==", true],
			],
			storeAs: "ToBeProcessedBrandCreativeCreativeJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "BRAND_CREATIVE"],
				["processing.status", "==", true],
			],
			storeAs: "ProcessedBrandCreativeCreativeJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "BRAND_CREATIVE"],
				["completed.status", "==", true],
			],
			storeAs: "CompletedBrandCreativeCreativeJobs",
		},
	]);

	const toBeProcessedBrandCreativeCreativeJobs = useSelector(
		(state: any) =>
			state.firestore.data["ToBeProcessedBrandCreativeCreativeJobs"]
	);

	const processedBrandCreativeCreativeJobs = useSelector(
		(state: any) => state.firestore.data["ProcessedBrandCreativeCreativeJobs"]
	);
	const completedBrandCreativeCreativeJobs = useSelector(
		(state: any) => state.firestore.data["CompletedBrandCreativeCreativeJobs"]
	);

	const [
		ToBeProcessedBrandCreativeCreativeJobs,
		setToBeProcessedBrandCreativeCreativeJobs,
	] = useState<any>([]);
	const [
		ProcessedBrandCreativeCreativeJobs,
		setProcessedBrandCreativeCreativeJobs,
	] = useState<any>([]);
	const [
		CompletedBrandCreativeCreativeJobs,
		setCompletedBrandCreativeCreativeJobs,
	] = useState<any>([]);

	useEffect(() => {
		if (
			isLoaded(toBeProcessedBrandCreativeCreativeJobs) &&
			typeof toBeProcessedBrandCreativeCreativeJobs !== "undefined" &&
			isLoaded(processedBrandCreativeCreativeJobs) &&
			typeof processedBrandCreativeCreativeJobs !== "undefined"
		) {
			let toBeProcessedBrandCreativeCreativeJobs_data: any[] = [];
			if (toBeProcessedBrandCreativeCreativeJobs !== null) {
				for (var toBeProcessedBrandCreativeCreativeJobs_data_item in toBeProcessedBrandCreativeCreativeJobs) {
					toBeProcessedBrandCreativeCreativeJobs_data.push({
						...toBeProcessedBrandCreativeCreativeJobs[
							toBeProcessedBrandCreativeCreativeJobs_data_item
						],
						toBeProcessedBrandCreativeCreativeJobs_data_uuid: toBeProcessedBrandCreativeCreativeJobs_data_item,
					});
				}
			}

			setToBeProcessedBrandCreativeCreativeJobs([
				...toBeProcessedBrandCreativeCreativeJobs_data,
			]);

			let processedBrandCreativeCreativeJobs_data: any[] = [];
			if (processedBrandCreativeCreativeJobs !== null) {
				for (var processedBrandCreativeCreativeJobs_data_item in processedBrandCreativeCreativeJobs) {
					processedBrandCreativeCreativeJobs_data.push({
						...processedBrandCreativeCreativeJobs[
							processedBrandCreativeCreativeJobs_data_item
						],
						processedBrandCreativeCreativeJobs_data_uuid: processedBrandCreativeCreativeJobs_data_item,
					});
				}
			}

			setProcessedBrandCreativeCreativeJobs([
				...processedBrandCreativeCreativeJobs_data,
			]);

			let completedBrandCreativeCreativeJobs_data: any[] = [];
			if (completedBrandCreativeCreativeJobs !== null) {
				for (var completedBrandCreativeCreativeJobs_data_item in completedBrandCreativeCreativeJobs) {
					completedBrandCreativeCreativeJobs_data.push({
						...completedBrandCreativeCreativeJobs[
							completedBrandCreativeCreativeJobs_data_item
						],
						completedBrandCreativeCreativeJobs_data_uuid: completedBrandCreativeCreativeJobs_data_item,
					});
				}
			}

			setCompletedBrandCreativeCreativeJobs([
				...completedBrandCreativeCreativeJobs_data,
			]);

			let data: any = [];

			if (query === "all") {
				const combineData = [
					...toBeProcessedBrandCreativeCreativeJobs_data,
					...processedBrandCreativeCreativeJobs_data,
					...completedBrandCreativeCreativeJobs_data,
				];
				data = _.map(combineData, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All Brand Creatives");
			} else if (query === "to-be-processed") {
				data = _.map(toBeProcessedBrandCreativeCreativeJobs_data, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All To Be Processed");
			} else if (query === "processing") {
				data = _.map(processedBrandCreativeCreativeJobs_data, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All Under Process");
			} else if (query === "completed") {
				data = _.map(completedBrandCreativeCreativeJobs_data, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All Completed");
			}
		}
	}, [
		toBeProcessedBrandCreativeCreativeJobs,
		processedBrandCreativeCreativeJobs,
		completedBrandCreativeCreativeJobs,
		query,
	]);

	return (
		<Fragment>
			<Dialog
				fullScreen
				open={openProcess}
				onClose={handleCloseProcess}
				TransitionComponent={Transition}
			>
				<AppBar className={styles.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseProcess}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={styles.title}>
							{typeofJob === "to_be_processed" && "Process brand creative"}
							{typeofJob === "processing" && "Process brand creative"}
							{typeofJob === "completed" && "Completed brand creative"}
						</Typography>
					</Toolbar>
				</AppBar>

				{typeofJob === "to_be_processed" && (
					<ProcessBrandCreativesJob data={processJobData} />
				)}
				{typeofJob === "processing" && "Process brand creative"}
				{typeofJob === "completed" && (
					<CompletedBrandCreativesJob data={processJobData} />
				)}
			</Dialog>
			<br />
			<br />
			<div className={styles.queries_wrapper}>
				<Chip
					label={`All Brand Creatives (${
						ToBeProcessedBrandCreativeCreativeJobs.length +
						ProcessedBrandCreativeCreativeJobs.length +
						CompletedBrandCreativeCreativeJobs.length
					})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "all",
							type: "brand-creative",
						})
					}
				/>
				<Chip
					label={`All To Be Processed (${ToBeProcessedBrandCreativeCreativeJobs.length})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "to-be-processed",
							type: "brand-creative",
						})
					}
				/>
				<Chip
					label={`All Under Process (${ProcessedBrandCreativeCreativeJobs.length})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "processing",
							type: "brand-creative",
						})
					}
				/>
				<Chip
					label={`All Completed (${CompletedBrandCreativeCreativeJobs.length})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "completed",
							type: "brand-creative",
						})
					}
				/>
			</div>
			<Box className={styles.root} boxShadow={5}>
				<MaterialTable
					style={{ width: "100%" }}
					isLoading={pageloading}
					title={tableTitle}
					columns={[
						{
							title: "Reference",
							field: "ref",
							render: (rowData) => (
								<Typography variant="caption">{rowData.ref}</Typography>
							),
						},
						{
							title: "Vendor ",
							field: "vendor",
							render: (rowData) => (
								<Typography variant="caption">{rowData.vendor}</Typography>
							),
						},
						{
							title: "Task ",
							field: "job",
							render: (rowData) => (
								<Typography variant="caption">{rowData.job}</Typography>
							),
						},
						{
							title: "Status ",
							field: "status",
							render: (rowData) => (
								<Fragment>
									{_.has(rowData.data, "to_be_processed") &&
										rowData.data.to_be_processed.status && (
											<Chip
												icon={<EditIcon />}
												label="To be processed"
												variant="default"
											/>
										)}
									{_.has(rowData.data, "processing") &&
										rowData.data.processing.status && (
											<Chip
												icon={<LineStyleIcon />}
												label="Under Process"
												variant="default"
											/>
										)}
									{_.has(rowData.data, "completed") &&
										rowData.data.completed.status && (
											<Chip
												icon={<AssignmentTurnedInIcon />}
												label="Completed"
												variant="default"
											/>
										)}
								</Fragment>
							),
						},
						{ title: "data", field: "data", hidden: true },
						{
							title: "Action ",
							field: "action",
							render: (rowData) => (
								<Fragment>
									{_.has(rowData.data, "to_be_processed") &&
										rowData.data.to_be_processed.status && (
											<Button
												variant="contained"
												color="secondary"
												style={{ color: "#fff" }}
												onClick={() => {
													handleClickOpenProcess();
													setProcessJobData({ ...rowData.data });
													setTypeofJob("to_be_processed");
												}}
											>
												process
											</Button>
										)}
									{_.has(rowData.data, "processing") &&
										rowData.data.processing.status && (
											<Button
												variant="contained"
												color="secondary"
												style={{ color: "#fff" }}
												onClick={() => {
													setTypeofJob("processing");
												}}
											>
												process
											</Button>
										)}
									{_.has(rowData.data, "completed") &&
										rowData.data.completed.status && (
											<Button
												variant="contained"
												color="secondary"
												style={{ color: "#fff" }}
												onClick={() => {
													handleClickOpenProcess();
													setProcessJobData({ ...rowData.data });
													setTypeofJob("completed");
												}}
											>
												View
											</Button>
										)}
								</Fragment>
							),
						},
					]}
					data={tableData}
					options={{
						search: true,
						actionsColumnIndex: -1,
					}}
				/>
			</Box>
		</Fragment>
	);
};

export default BrandCreativesJobs;
