
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import { FEEDBACK } from '../../../constants/feedback'

export const Approve = (id: string,by:string, status: true | false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Stores').doc(id).set({
            approved: status,
            approved_by:by,
        },{merge:true})
            .then(() => {
                if (status) {
                    dispatch({ type: FEEDBACK, payload: { severity: 'success', title: 'The Store is Approved', body: 'The store is now open to customers on Ekioja marketplace' } })
                }
                
            });
           

    }