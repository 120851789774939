import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import Divider from "@material-ui/core/Divider";
import CloudUploadTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";

import UploadImage from "../../../UploadImage/UploadImage";
import useDeleteImage from "../../../../hooks/images/useDeleteImage";
import useAddImageDataToVendor from "../../../../hooks/creative/brandcreatives/useAddImageDataToVendor";
import useRemoveImageDataToVendor from "../../../../hooks/creative/brandcreatives/useRemoveImageDataToVendor";
import useCompleteJob from "../../../../hooks/creative/brandcreatives/useCompleteJob";
import _ from "lodash";
// import EkiojaBag from "../../../../assets/images/ekioja-logo.jpg";
// import EkiojaBG from "../../../../assets/images/categories/african_fashion.png";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";

const ProcessMarketplaceJob = ({ data }: { data: any }) => {
	const [DeleteImageMutate] = useDeleteImage();

	const [AddImageDataToVendorMutate] = useAddImageDataToVendor();
	const [
		RemoveImageDataToVendorMutate,
		RemoveImageDataToVendorInfo,
	] = useRemoveImageDataToVendor();

	const [CompleteJobMutate, CompleteJobInfo] = useCompleteJob();

	const [completedDialog, setCompletedDialog] = useState(false);

	const [image1URL, setImage1URL] = useState(data.product_data.image_1_url);
	const [image2URL, setImage2URL] = useState(data.product_data.image_2_url);
	const [image3URL, setImage3URL] = useState(data.product_data.image_3_url);
	const [image4URL, setImage4URL] = useState(data.product_data.image_4_url);

	const [deleteImage1URL, setDeleteImage1URL] = useState(false);
	const [deleteImage2URL, setDeleteImage2URL] = useState(false);
	const [deleteImage3URL, setDeleteImage3URL] = useState(false);
	const [deleteImage4URL, setDeleteImage4URL] = useState(false);
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={1}></Grid>
				<Grid item xs={12} lg={4}>
					<List>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<StorefrontIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Vendor" secondary={data.store_name} />
						</ListItem>
						<Divider variant="inset" component="li" />
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<AccountCircleTwoToneIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Representative"
								secondary={
									<React.Fragment>
										<h4>{`${data.store_admin_data.first_name} ${data.store_admin_data.last_name}`}</h4>
										<h4>{`${data.store_admin_data.email}`}</h4>
										<h4>{`${data.store_admin_data.contact_phone_number}`}</h4>
									</React.Fragment>
								}
							/>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} lg={7}>
					{image1URL !== "" && data.product_data.image_1 !== "" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								minHeight: 200,
								width: "70%",
								border: "1px gray solid",
								borderRadius: 20,
								position: "relative",
								marginBottom: 30,
								paddingTop: 30,
							}}
						>
							<img width={150} height={150} src={image1URL} alt="logo" />
							<br />

							<Button
								color="secondary"
								onClick={() => {
									setDeleteImage1URL(true);
								}}
							>
								Do you want to remove this design?
							</Button>
							<br />
							<Collapse in={deleteImage1URL} timeout="auto" unmountOnExit>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 40,
									}}
								>
									<div>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												RemoveImageDataToVendorMutate(
													{
														id: data.product_data.id,
														jobid:
															data.toBeProcessedMarketProductJobs_data_uuid,
														type: "marketplaceproduct",
														image_num: 1,
													},
													{
														onSuccess: () => {
															DeleteImageMutate(
																{
																	folder: "productImages",
																	source: `product_${data.product_data.id}_image_1`,
																},
																{
																	onSuccess: () => {
																		setImage1URL("");
																		setDeleteImage1URL(false);
																	},
																	onError: (e: any) => {
																		window.alert(e.message);
																	},
																}
															);
														},
														onError: (e: any) => {
															window.alert(e.message);
														},
													}
												);
											}}
										>
											{RemoveImageDataToVendorInfo.isLoading
												? "removing..."
												: "Yes"}
										</Button>
										<Button
											color="secondary"
											onClick={() => {
												setDeleteImage1URL(false);
											}}
										>
											No
										</Button>
									</div>
								</div>
							</Collapse>
						</div>
					)}

					{_.includes(data.job, "MARKET_PRODUCT") &&
						image1URL === "" &&
						data.product_data.image_1 !== "" && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: 200,
									width: "70%",
									border: "1px gray solid",
									borderRadius: 20,
									position: "relative",
									marginBottom: 30,
								}}
							>
								<CloudUploadTwoToneIcon style={{ fontSize: 40 }} />
								<h3>Upload processed design</h3>
								<div
									style={{
										position: "absolute",
										bottom: -10,
										right: "44%",
										width: 50,
										zIndex: 500,
									}}
								>
									<UploadImage
										title="product image design"
										existingImageId={`product_${data.product_data.id}_image_1`}
										folderToSave="productImages"
										onRequestSaveURL={(url: any) => {
											AddImageDataToVendorMutate(
												{
													id: data.product_data.id,
													url,
													jobid: data.toBeProcessedMarketProductJobs_data_uuid,
													type: "marketplaceproduct",
													image_num: 1,
												},
												{
													onSuccess: () => {
														setImage1URL(url);
													},
													onError: (e: any) => {
														window.alert(e.message);
													},
												}
											);
										}}
										onRequestSave={(id: any) => {}}
										onRequestClear={() => {
											DeleteImageMutate(
												{
													folder: "productImages",
													source: `product_${data.product_data.id}_image_1`,
												},
												{
													onSuccess: () => {
														setImage1URL("");
													},
													onError: (e: any) => {
														window.alert(e.message);
													},
												}
											);
										}}
									/>
								</div>
							</div>
						)}

					{image2URL !== "" && data.product_data.image_2 !== "" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								minHeight: 200,
								width: "70%",
								border: "1px gray solid",
								borderRadius: 20,
								position: "relative",
								marginBottom: 30,
								paddingTop: 30,
							}}
						>
							<img width={150} height={150} src={image2URL} alt="logo2" />
							<br />

							<Button
								color="secondary"
								onClick={() => {
									setDeleteImage2URL(true);
								}}
							>
								Do you want to remove this design?
							</Button>
							<br />
							<Collapse in={deleteImage2URL} timeout="auto" unmountOnExit>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 40,
									}}
								>
									<div>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												RemoveImageDataToVendorMutate(
													{
														id: data.product_data.id,
														jobid:
															data.toBeProcessedMarketProductJobs_data_uuid,
														type: "marketplaceproduct",
														image_num: 2,
													},
													{
														onSuccess: () => {
															DeleteImageMutate(
																{
																	folder: "productImages",
																	source: `product_${data.product_data.id}_image_2`,
																},
																{
																	onSuccess: () => {
																		setImage2URL("");
																		setDeleteImage2URL(false);
																	},
																	onError: (e: any) => {
																		window.alert(e.message);
																	},
																}
															);
														},
														onError: (e: any) => {
															window.alert(e.message);
														},
													}
												);
											}}
										>
											{RemoveImageDataToVendorInfo.isLoading
												? "removing..."
												: "Yes"}
										</Button>
										<Button
											color="secondary"
											onClick={() => {
												setDeleteImage2URL(false);
											}}
										>
											No
										</Button>
									</div>
								</div>
							</Collapse>
						</div>
					)}

					{_.includes(data.job, "MARKET_PRODUCT") &&
						image2URL === "" &&
						data.product_data.image_2 !== "" && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: 200,
									width: "70%",
									border: "1px gray solid",
									borderRadius: 20,
									position: "relative",
									marginBottom: 30,
								}}
							>
								<CloudUploadTwoToneIcon style={{ fontSize: 40 }} />
								<h3>Upload processed design</h3>
								<div
									style={{
										position: "absolute",
										bottom: -10,
										right: "44%",
										width: 50,
										zIndex: 500,
									}}
								>
									<UploadImage
										title="product image design"
										existingImageId={`product_${data.product_data.id}_image_2`}
										folderToSave="productImages"
										onRequestSaveURL={(url: any) => {
											AddImageDataToVendorMutate(
												{
													id: data.product_data.id,
													url,
													jobid: data.toBeProcessedMarketProductJobs_data_uuid,
													type: "marketplaceproduct",
													image_num: 2,
												},
												{
													onSuccess: () => {
														setImage2URL(url);
													},
													onError: (e: any) => {
														window.alert(e.message);
													},
												}
											);
										}}
										onRequestSave={(id: any) => {}}
										onRequestClear={() => {
											DeleteImageMutate(
												{
													folder: "productImages",
													source: `product_${data.product_data.id}_image_2`,
												},
												{
													onSuccess: () => {
														setImage2URL("");
													},
													onError: (e: any) => {
														window.alert(e.message);
													},
												}
											);
										}}
									/>
								</div>
							</div>
						)}
					{image3URL !== "" && data.product_data.image_3 !== "" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								minHeight: 200,
								width: "70%",
								border: "1px gray solid",
								borderRadius: 20,
								position: "relative",
								marginBottom: 30,
								paddingTop: 30,
							}}
						>
							<img width={150} height={150} src={image3URL} alt="logo3" />
							<br />

							<Button
								color="secondary"
								onClick={() => {
									setDeleteImage3URL(true);
								}}
							>
								Do you want to remove this design?
							</Button>
							<br />
							<Collapse in={deleteImage3URL} timeout="auto" unmountOnExit>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 40,
									}}
								>
									<div>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												RemoveImageDataToVendorMutate(
													{
														id: data.product_data.id,
														jobid:
															data.toBeProcessedMarketProductJobs_data_uuid,
														type: "marketplaceproduct",
														image_num: 3,
													},
													{
														onSuccess: () => {
															DeleteImageMutate(
																{
																	folder: "productImages",
																	source: `product_${data.product_data.id}_image_3`,
																},
																{
																	onSuccess: () => {
																		setImage3URL("");
																		setDeleteImage3URL(false);
																	},
																	onError: (e: any) => {
																		window.alert(e.message);
																	},
																}
															);
														},
														onError: (e: any) => {
															window.alert(e.message);
														},
													}
												);
											}}
										>
											{RemoveImageDataToVendorInfo.isLoading
												? "removing..."
												: "Yes"}
										</Button>
										<Button
											color="secondary"
											onClick={() => {
												setDeleteImage3URL(false);
											}}
										>
											No
										</Button>
									</div>
								</div>
							</Collapse>
						</div>
					)}

					{_.includes(data.job, "MARKET_PRODUCT") &&
						image3URL === "" &&
						data.product_data.image_3 !== "" && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: 200,
									width: "70%",
									border: "1px gray solid",
									borderRadius: 20,
									position: "relative",
									marginBottom: 30,
								}}
							>
								<CloudUploadTwoToneIcon style={{ fontSize: 40 }} />
								<h3>Upload processed design</h3>
								<div
									style={{
										position: "absolute",
										bottom: -10,
										right: "44%",
										width: 50,
										zIndex: 500,
									}}
								>
									<UploadImage
										title="product image design"
										existingImageId={`product_${data.product_data.id}_image_3`}
										folderToSave="productImages"
										onRequestSaveURL={(url: any) => {
											AddImageDataToVendorMutate(
												{
													id: data.product_data.id,
													url,
													jobid: data.toBeProcessedMarketProductJobs_data_uuid,
													type: "marketplaceproduct",
													image_num: 3,
												},
												{
													onSuccess: () => {
														setImage3URL(url);
													},
													onError: (e: any) => {
														window.alert(e.message);
													},
												}
											);
										}}
										onRequestSave={(id: any) => {}}
										onRequestClear={() => {
											DeleteImageMutate(
												{
													folder: "productImages",
													source: `product_${data.product_data.id}_image_3`,
												},
												{
													onSuccess: () => {
														setImage3URL("");
													},
													onError: (e: any) => {
														window.alert(e.message);
													},
												}
											);
										}}
									/>
								</div>
							</div>
						)}
					{image4URL !== "" && data.product_data.image_4 !== "" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								minHeight: 200,
								width: "70%",
								border: "1px gray solid",
								borderRadius: 20,
								position: "relative",
								marginBottom: 30,
								paddingTop: 30,
							}}
						>
							<img width={150} height={150} src={image4URL} alt="logo3" />
							<br />

							<Button
								color="secondary"
								onClick={() => {
									setDeleteImage4URL(true);
								}}
							>
								Do you want to remove this design?
							</Button>
							<br />
							<Collapse in={deleteImage4URL} timeout="auto" unmountOnExit>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 40,
									}}
								>
									<div>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												RemoveImageDataToVendorMutate(
													{
														id: data.product_data.id,
														jobid:
															data.toBeProcessedMarketProductJobs_data_uuid,
														type: "marketplaceproduct",
														image_num: 4,
													},
													{
														onSuccess: () => {
															DeleteImageMutate(
																{
																	folder: "productImages",
																	source: `product_${data.product_data.id}_image_4`,
																},
																{
																	onSuccess: () => {
																		setImage4URL("");
																		setDeleteImage4URL(false);
																	},
																	onError: (e: any) => {
																		window.alert(e.message);
																	},
																}
															);
														},
														onError: (e: any) => {
															window.alert(e.message);
														},
													}
												);
											}}
										>
											{RemoveImageDataToVendorInfo.isLoading
												? "removing..."
												: "Yes"}
										</Button>
										<Button
											color="secondary"
											onClick={() => {
												setDeleteImage4URL(false);
											}}
										>
											No
										</Button>
									</div>
								</div>
							</Collapse>
						</div>
					)}

					{_.includes(data.job, "MARKET_PRODUCT") &&
						image4URL === "" &&
						data.product_data.image_4 !== "" && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: 200,
									width: "70%",
									border: "1px gray solid",
									borderRadius: 20,
									position: "relative",
									marginBottom: 30,
								}}
							>
								<CloudUploadTwoToneIcon style={{ fontSize: 40 }} />
								<h3>Upload processed design</h3>
								<div
									style={{
										position: "absolute",
										bottom: -10,
										right: "44%",
										width: 50,
										zIndex: 500,
									}}
								>
									<UploadImage
										title="product image design"
										existingImageId={`product_${data.product_data.id}_image_4`}
										folderToSave="productImages"
										onRequestSaveURL={(url: any) => {
											AddImageDataToVendorMutate(
												{
													id: data.product_data.id,
													url,
													jobid: data.toBeProcessedMarketProductJobs_data_uuid,
													type: "marketplaceproduct",
													image_num: 4,
												},
												{
													onSuccess: () => {
														setImage4URL(url);
													},
													onError: (e: any) => {
														window.alert(e.message);
													},
												}
											);
										}}
										onRequestSave={(id: any) => {}}
										onRequestClear={() => {
											DeleteImageMutate(
												{
													folder: "productImages",
													source: `product_${data.product_data.id}_image_4`,
												},
												{
													onSuccess: () => {
														setImage4URL("");
													},
													onError: (e: any) => {
														window.alert(e.message);
													},
												}
											);
										}}
									/>
								</div>
							</div>
						)}

					{_.includes(data.job, "MARKET_PRODUCT") && image1URL !== "" ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								minHeight: 200,
								width: "70%",
							}}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={() => setCompletedDialog(true)}
							>
								Have you uploaded the design ?
							</Button>
							<br />
							<Collapse in={completedDialog} timeout="auto" unmountOnExit>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 40,
									}}
								>
									<div>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												CompleteJobMutate(
													{
														id: data.toBeProcessedMarketProductJobs_data_uuid,
														name: `${data.job_processed_by.name}`,
														amount: data.commission,
														walletid: data.job_processed_by.uid,
														purpose: "Marketplace product image re-design",
														product_id: data.product_data.id,
													},
													{
														onSuccess: () => {
															setCompletedDialog(false);
														},
														onError: (e: any) => {
															window.alert(e.message);
														},
													}
												);
											}}
										>
											{!CompleteJobInfo.isLoading &&
												!CompleteJobInfo.isSuccess &&
												"yes"}
											{CompleteJobInfo.isLoading && "Please wait..."}
											{CompleteJobInfo.isSuccess && " Completed!"}
										</Button>
										<Button
											color="secondary"
											onClick={() => {
												setCompletedDialog(false);
											}}
										>
											No
										</Button>
									</div>
								</div>
							</Collapse>
						</div>
					) : null}
				</Grid>
			</Grid>
		</>
	);
};

export default ProcessMarketplaceJob;
