
import { FEEDBACK } from '../../../constants/feedback';

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const MoveFromNotLiveToLiveCategory = (value: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();
        try {

            var batch = firebase.firestore().batch();

            const notLiveNicheRef = firebase.firestore().collection('Ekioja').doc('notLiveNiches');
            batch.update(notLiveNicheRef, { "values": firebase.firestore.FieldValue.arrayRemove(value) });

            const liveNicheRef = firebase.firestore().collection('Ekioja').doc('liveNiches');
            batch.update(liveNicheRef, { "values": firebase.firestore.FieldValue.arrayUnion(value) });

            // const removeFromNotLivePromise = firebase.firestore().collection('Ekioja').doc('notLiveNiches').update({
            //     values: firebase.firestore.FieldValue.arrayRemove(value)
            // });

            // const addToLivePromise = firebase.firestore().collection('Ekioja').doc('liveNiches').update({
            //     values: firebase.firestore.FieldValue.arrayUnion(value)
            // });

            // const allPromise = Promise.all([removeFromNotLivePromise, addToLivePromise]);

            // allPromise.then(() => {
            //     dispatch({ type: FEEDBACK, payload: { severity: 'success', title: 'Moved!', body: `${value} has been sucessfully moved!` } })
            // })

            // Commit the batch
            batch.commit().then(function () {
                dispatch({ type: FEEDBACK, payload: { severity: 'success', title: 'Moved!', body: `${value} has been sucessfully moved!` } })
            });



        } catch (error) {
            dispatch({ type: FEEDBACK, payload: { severity: 'error', title: 'Error!!!', body: error.code } })
        }

    }