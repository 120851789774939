import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types"; // ES6
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import firebase from "../../config/firebaseConfig";
import shortid from "shortid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
// And import the necessary css
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// register the filepond plugins for additional functionality
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

// make a reference to our firebase storage
const storage = firebase.storage().ref();

const UploadImage = ({
	onRequestSave,
	onRequestSaveURL,
	onRequestClear,
	defaultFiles = [],
	existingImageId,
	title,
	folderToSave,
}: {
	onRequestSave: any;
	onRequestSaveURL: any;
	onRequestClear: any;
	defaultFiles?: any;
	existingImageId: any;
	title: any;
	folderToSave: any;
}) => {
	const [open, setOpen] = React.useState(false);
	const [files, setFiles] = React.useState(defaultFiles);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const server = {
		// this uploads the image using firebase
		process: (
			fieldName: any,
			file: any,
			metadata: any,
			load: any,
			error: any,
			progress: any,
			abort: any
		) => {
			// create a unique id for the file
			let id: any;
			if (existingImageId) {
				id = existingImageId;
			} else {
				id = shortid.generate();
			}

			// upload the image to firebase
			const task = storage.child(`${folderToSave}/${id}`).put(file, {
				contentType: "image/jpeg",
			});

			// monitor the task to provide updates to FilePond
			task.on(
				firebase.storage.TaskEvent.STATE_CHANGED,
				(snap: any) => {
					// provide progress updates
					progress(true, snap.bytesTransferred, snap.totalBytes);
					// switch (snap.state) {
					// 	case firebase.storage.TaskState.PAUSED: // or 'paused'
					// 		console.log("Upload is paused");
					// 		break;
					// 	case firebase.storage.TaskState.RUNNING: // or 'running'
					// 		console.log("Upload is running");
					// 		break;
					// }
				},
				(err: any) => {
					// provide errors
					error(err.message);
					console.log(err.message);
					// switch (err.code) {
					// 	case "storage/unauthorized":
					// 		// User doesn't have permission to access the object
					// 		break;
					// 	case "storage/canceled":
					// 		// User canceled the upload
					// 		break;

					// 	// ...

					// 	case "storage/unknown":
					// 		// Unknown error occurred, inspect error.serverResponse
					// 		break;
					// }
				},
				() => {
					// the file has been uploaded
					load(id);
					onRequestSave(id);
					task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
						onRequestSaveURL(downloadURL);
					});
				}
			);
		},

		// this loads an already uploaded image to firebase
		load: (source: any, load: any, error: any, progress: any, abort: any) => {
			// reset our progress
			progress(true, 0, 1024);

			// fetch the download URL from firebase
			storage
				.child(`${folderToSave}/${source}`)
				.getDownloadURL()
				.then((url: any) => {
					// fetch the actual image using the download URL
					// and provide the blob to FilePond using the load callback
					let xhr = new XMLHttpRequest();
					xhr.responseType = "blob";
					xhr.onload = function (event) {
						let blob = xhr.response;
						load(blob);
					};
					xhr.open("GET", url);
					xhr.send();
				})
				.catch((err: any) => {
					error(err.message);
					abort();
				});
		},
	};
	return (
		<>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="upload-image-dialog-slide-title"
				aria-describedby="upload-image-dialog-slide-description"
				fullScreen={fullScreen}
			>
				<DialogTitle id="upload-image-dialog-slide-title">
					{`Upload ${title}`}
				</DialogTitle>
				<DialogContent style={{ minWidth: 400 }}>
					<FilePond
						files={files}
						allowMultiple={false}
						maxFiles={1}
						onupdatefiles={(fileItems) => {
							if (fileItems.length === 0) {
								onRequestClear();
							}

							setFiles(fileItems.map((fileItem) => fileItem.file));
						}}
						name="files"
						labelIdle='Drag & Drop your image or <span class="filepond--label-action">Browse</span>'
						server={server} // todo: add custom server functionality using firebase
						// acceptedFileTypes={["image/jpeg","images/png"]}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						close
					</Button>
				</DialogActions>
			</Dialog>
			<Tooltip title="upload an image">
				<IconButton
					color="primary"
					aria-label="upload picture"
					component="span"
					onClick={handleClickOpen}
					style={{ backgroundColor: "#fff" }}
					size="small"
				>
					<PhotoCamera style={{ color: "green" }} />
				</IconButton>
			</Tooltip>
		</>
	);
};

export default UploadImage;

UploadImage.propTypes = {
	title: PropTypes.string,
};
