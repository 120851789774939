import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// //react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

// //react redux firebase
import { isLoaded } from "react-redux-firebase";
//number format
// import NumberFormat from "react-number-format";

import CreativesJobWidget from "../DashBoardWidgets/CreativesJobWidget";

import _ from "lodash";
const FaceBehindTheBrandQuickLinks = () => {
	useFirestoreConnect([
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "FACE_BEHIND_THE_BRAND"],
				["to_be_processed.status", "==", true],
			],
			storeAs: "ToBeProcessedFaceBehindTheBrandCreativeJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "FACE_BEHIND_THE_BRAND"],
				["processing.status", "==", true],
			],
			storeAs: "ProcessedFaceBehindTheBrandCreativeJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "FACE_BEHIND_THE_BRAND"],
				["completed.status", "==", true],
			],
			storeAs: "CompletedFaceBehindTheBrandCreativeJobs",
		},
	]);

	const toBeProcessedFaceBehindTheBrandCreativeJobs = useSelector(
		(state: any) =>
			state.firestore.data["ToBeProcessedFaceBehindTheBrandCreativeJobs"]
	);

	const processedFaceBehindTheBrandCreativeJobs = useSelector(
		(state: any) =>
			state.firestore.data["ProcessedFaceBehindTheBrandCreativeJobs"]
	);
	const completedFaceBehindTheBrandCreativeJobs = useSelector(
		(state: any) =>
			state.firestore.data["CompletedFaceBehindTheBrandCreativeJobs"]
	);

	const [
		ToBeProcessedFaceBehindTheBrandCreativeJobs,
		setToBeProcessedFaceBehindTheBrandCreativeJobs,
	] = useState<any>([]);
	const [
		ProcessedFaceBehindTheBrandCreativeJobs,
		setProcessedFaceBehindTheBrandCreativeJobs,
	] = useState<any>([]);
	const [
		CompletedFaceBehindTheBrandCreativeJobs,
		setCompletedFaceBehindTheBrandCreativeJobs,
	] = useState<any>([]);

	useEffect(() => {
		if (
			isLoaded(toBeProcessedFaceBehindTheBrandCreativeJobs) &&
			typeof toBeProcessedFaceBehindTheBrandCreativeJobs !== "undefined" &&
			isLoaded(processedFaceBehindTheBrandCreativeJobs) &&
			typeof processedFaceBehindTheBrandCreativeJobs !== "undefined"
		) {
			let toBeProcessedFaceBehindTheBrandCreativeJobs_data: any[] = [];
			if (toBeProcessedFaceBehindTheBrandCreativeJobs !== null) {
				for (var toBeProcessedFaceBehindTheBrandCreativeJobs_data_item in toBeProcessedFaceBehindTheBrandCreativeJobs) {
					toBeProcessedFaceBehindTheBrandCreativeJobs_data.push({
						...toBeProcessedFaceBehindTheBrandCreativeJobs[
							toBeProcessedFaceBehindTheBrandCreativeJobs_data_item
						],
						toBeProcessedFaceBehindTheBrandCreativeJobs_data_uuid: toBeProcessedFaceBehindTheBrandCreativeJobs_data_item,
					});
				}
			}

			setToBeProcessedFaceBehindTheBrandCreativeJobs([
				...toBeProcessedFaceBehindTheBrandCreativeJobs_data,
			]);

			let processedFaceBehindTheBrandCreativeJobs_data: any[] = [];
			if (processedFaceBehindTheBrandCreativeJobs !== null) {
				for (var processedFaceBehindTheBrandCreativeJobs_data_item in processedFaceBehindTheBrandCreativeJobs) {
					processedFaceBehindTheBrandCreativeJobs_data.push({
						...processedFaceBehindTheBrandCreativeJobs[
							processedFaceBehindTheBrandCreativeJobs_data_item
						],
						processedFaceBehindTheBrandCreativeJobs_data_uuid: processedFaceBehindTheBrandCreativeJobs_data_item,
					});
				}
			}

			setProcessedFaceBehindTheBrandCreativeJobs([
				...processedFaceBehindTheBrandCreativeJobs_data,
			]);

			let completedFaceBehindTheBrandCreativeJobs_data: any[] = [];
			if (completedFaceBehindTheBrandCreativeJobs !== null) {
				for (var completedFaceBehindTheBrandCreativeJobs_data_item in completedFaceBehindTheBrandCreativeJobs) {
					completedFaceBehindTheBrandCreativeJobs_data.push({
						...completedFaceBehindTheBrandCreativeJobs[
							completedFaceBehindTheBrandCreativeJobs_data_item
						],
						completedFaceBehindTheBrandCreativeJobs_data_uuid: completedFaceBehindTheBrandCreativeJobs_data_item,
					});
				}
			}

			setCompletedFaceBehindTheBrandCreativeJobs([
				...completedFaceBehindTheBrandCreativeJobs_data,
			]);
		}
	}, [
		toBeProcessedFaceBehindTheBrandCreativeJobs,
		processedFaceBehindTheBrandCreativeJobs,
		completedFaceBehindTheBrandCreativeJobs,
	]);

	return (
		<div>
			<br />
			<br />
			<br />
			<Typography variant="h6">Face Behind the brand quick links</Typography>
			<Divider />
			<br />
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(ToBeProcessedFaceBehindTheBrandCreativeJobs) &&
									isLoaded(ProcessedFaceBehindTheBrandCreativeJobs) &&
									isLoaded(CompletedFaceBehindTheBrandCreativeJobs)
										? ToBeProcessedFaceBehindTheBrandCreativeJobs.length +
										  ProcessedFaceBehindTheBrandCreativeJobs.length +
										  CompletedFaceBehindTheBrandCreativeJobs.length
										: 0
								}
								title="All Face behind the brand"
								routeQuery={"all"}
								type="face-behind-the-brand"
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(ToBeProcessedFaceBehindTheBrandCreativeJobs) &&
									!_.isEmpty(ToBeProcessedFaceBehindTheBrandCreativeJobs)
										? ToBeProcessedFaceBehindTheBrandCreativeJobs.length
										: 0
								}
								title="All To be Processed"
								routeQuery={"to-be-processed"}
								type="face-behind-the-brand"
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(ProcessedFaceBehindTheBrandCreativeJobs) &&
									!_.isEmpty(ProcessedFaceBehindTheBrandCreativeJobs)
										? ProcessedFaceBehindTheBrandCreativeJobs.length
										: 0
								}
								title="All Under Process"
								routeQuery={"processing"}
								type="face-behind-the-brand"
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(CompletedFaceBehindTheBrandCreativeJobs) &&
									!_.isEmpty(CompletedFaceBehindTheBrandCreativeJobs)
										? CompletedFaceBehindTheBrandCreativeJobs.length
										: 0
								}
								title="All Completed"
								routeQuery={"completed"}
								type="face-behind-the-brand"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} lg={6}>
					{/* <List
					// subheader={
					// 	<ListSubheader component="div">
					// 		Today's Brand Creatives Earning data
					// 	</ListSubheader>
					// }
					>
						<ListItem>
							<ListItemText
								primary="Upcoming earnings"
								secondary={
									<Fragment>
										<NumberFormat
											value={
												isLoaded(ToBeProcessedFaceBehindTheBrandCreativeJobs) &&
												!_.isEmpty(ToBeProcessedFaceBehindTheBrandCreativeJobs)
													? ToBeProcessedFaceBehindTheBrandCreativeJobs.length *
													  100
													: 0
											}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"₦"}
										/>
									</Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								primary="Pending Clearance"
								secondary={
									<Fragment>
										<NumberFormat
											value={
												isLoaded(ProcessedFaceBehindTheBrandCreativeJobs) &&
												!_.isEmpty(ProcessedFaceBehindTheBrandCreativeJobs)
													? ProcessedFaceBehindTheBrandCreativeJobs.length * 100
													: 0
											}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"₦"}
										/>
									</Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								primary="Earned"
								secondary={
									<Fragment>
										<NumberFormat
											value={
												isLoaded(CompletedFaceBehindTheBrandCreativeJobs) &&
												!_.isEmpty(CompletedFaceBehindTheBrandCreativeJobs)
													? CompletedFaceBehindTheBrandCreativeJobs.length * 100
													: 0
											}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"₦"}
										/>
									</Fragment>
								}
							/>
						</ListItem>
						<Divider />
					</List> */}
				</Grid>
			</Grid>
		</div>
	);
};

export default FaceBehindTheBrandQuickLinks;
