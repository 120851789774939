import React, { useEffect, useState } from "react";

// //react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

// //react redux firebase
import { isLoaded } from "react-redux-firebase";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

//material table
import MaterialTable from "material-table";

const BrandStories = () => {
	const [tableData, setTableData] = useState<any>([]);
	const [pageloading, setpageloading] = useState(true);

	useFirestoreConnect([
		{
			collection: "CreativeJobs",
			where: [["type", "==", "BRAND_CREATIVE"]],
			storeAs: "CreativeJobs",
		},
	]);

	const creativeJobs = useSelector(
		(state: any) => state.firestore.data["CreativeJobs"]
	);

	useEffect(() => {
		if (isLoaded(creativeJobs)) {
			console.log(creativeJobs);
		}
	}, [creativeJobs]);

	return (
		<div>
			<MaterialTable
				style={{ width: "100%" }}
				isLoading={pageloading}
				title="Brand Stories Jobs"
				columns={[
					{
						title: "REF",
						field: "ref",
						render: (rowData) => (
							<Typography variant="caption" color="primary">
								{rowData.ref}
							</Typography>
						),
					},
					{ title: "Vendor", field: "vendor" },
					{ title: "date", field: "date" },
				]}
				data={tableData}
				options={{
					search: true,
					actionsColumnIndex: -1,
					//   exportAllData: true,
					//   exportButton: true,
				}}
				actions={[
					{
						icon: "save",
						tooltip: "Save User",
						onClick: (event, rowData: any) => {},
					},
				]}
				components={{
					Action: (props) => (
						<Button
							aria-label="view-vendor-details"
							size="medium"
							onClick={(event) => props.action.onClick(event, props.data)}
						>
							Accept
						</Button>
					),
				}}
			/>
		</div>
	);
};

export default BrandStories;
