import { FEEDBACK } from '../../constants/feedback';

interface initialStateValue {
    info: {
        severity: string;
        title:string;
        body:string;
    }
}

interface actionValues {
    type: string;
    payload: {
        severity: string;
        title:string;
        body:string;
    }
}

let initialState: initialStateValue = {
    info: {
        severity: '',
        title:'',
        body:'',
    }
};

const feedBackReducer = (state = initialState, action: actionValues) => {
    switch (action.type) {
        case FEEDBACK:
            return {
                ...state,
                info: {
                    severity: action.payload.severity,
                    title: action.payload.title,
                    body: action.payload.body,
                }
            }
        default:
            return state
    }
}

export default feedBackReducer;