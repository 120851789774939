import React, { Fragment, useState, useEffect } from "react";

import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import BeenhereTwoToneIcon from "@material-ui/icons/BeenhereTwoTone";

//params
import { useParams } from "react-router-dom";
//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//navigation hook
import { useNavigate } from "../hooks/navigate";

//routes
import * as ROUTES from "../constants/routes";

//react - redux
import { useSelector, useDispatch } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//images
// import BG from "../assets/images/bg.jpg";
import BG from "../assets/images/ekioja-bg.jpeg";

//loading screen
import LoadingScreen from "../components/LoadingScreen";

///widgets
import ProductsWidget from "../components/StoreWidgets/ProductsWidget";
import OrdersWidget from "../components/StoreWidgets/OrdersWidget";

import moment from "moment";
import _ from "lodash";
//get image
import GetImageFromFirebase from "../components/GetImageFromFirebase";

// import { IStore } from "../ts/interfaces/store";

import { Approve } from "../store/actions/stores/approve";
import { Block } from "../store/actions/stores/block";
import { Activate } from "../store/actions/stores/activate";
import { ConfirmForVetting } from "../store/actions/stores/confirmforvetting";

import { truncate } from "../utils";

import EkiojaBag from "../assets/images/ekioja-logo.jpg";

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) => ({
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	logo: {
		width: 100,
		height: 100,
		borderRadius: 100,
		position: "absolute",
		bottom: 10,
		left: 50,
		"& img": {
			width: 100,
			height: 100,
			borderRadius: 100,
			border: `3px ${theme.palette.success.dark} solid`,
			backgroundColor: "white",
		},
	},
	tab_panel_wrapper: {
		// width: 1000,
		// height: 800,
		// marginLeft: 40,
		// border: '1px solid red',
		padding: 10,
	},
	store_image_wrapper: {
		width: "100%",
		height: 300,
		backgroundImage: `url(${BG})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		position: "relative",
	},
	card_title: {
		fontSize: 14,
	},
	product_card_wrapper: {
		padding: 3,
	},

	media: {
		height: 140,
	},
}));

const StoreDetails = () => {
	const { id } = useParams<any>();

	const styles = useStyles();
	const theme = useTheme();

	const [tabIndexValue, setTabIndexValueValue] = useState(0);

	const [store, setStore] = useState<any>({});
	const [
		openConfirmForVettingDialog,
		setOpenConfirmForVettingDialog,
	] = useState(false);

	const { navigateToLink } = useNavigate();

	const dispatch = useDispatch();
	useFirestoreConnect([
		{
			collection: "Stores",
			doc: id,
		},
		{
			collection: "Admins",
		},
	]);

	const StoreData = useSelector((state: any) => state.firestore.ordered.Stores);
	const authuid = useSelector((state: any) => state.firebase.auth.uid);
	const admins = useSelector((state: any) => state.firestore.ordered.Admins);

	useEffect(() => {
		if (isLoaded(StoreData) && isLoaded(admins)) {
			const all_same_docs_in_stores_and_admins = _.intersectionWith(
				_.cloneDeep(admins),
				StoreData,
				function (x: any, y: any) {
					return (
						x.entity === y.unique_id &&
						x.role === "Administrator" &&
						_.assign(x, y)
					);
				}
			);

			setStore({ ...all_same_docs_in_stores_and_admins[0] });
		}
	}, [admins, StoreData]);
	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabIndexValueValue(newValue);
	};

	const handleTabChangeIndex = (index: number) => {
		setTabIndexValueValue(index);
	};

	//   const handleClickConfirmForVettingOpen = () => {
	//     setOpenConfirmForVettingDialog(true);
	//   };

	const handleConfirmForVettingClose = () => {
		setOpenConfirmForVettingDialog(false);
	};

	if (
		isLoaded(StoreData) &&
		isLoaded(authuid) &&
		isLoaded(admins) &&
		_.isEmpty(store)
	)
		return <LoadingScreen />;

	return (
		<Fragment>
			<DashBoardLayout menuIndex={1}>
				<Dialog
					open={openConfirmForVettingDialog}
					onClose={handleConfirmForVettingClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{"Are you  sure?"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							By confirming this store for vetting it means you have verfied
							that they had paid.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleConfirmForVettingClose} color="primary">
							Cancel
						</Button>
						<Button
							onClick={() =>
								dispatch(ConfirmForVetting(store.unique_id, authuid, true))
							}
							color="primary"
							autoFocus
							variant="outlined"
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>

				{_.isEmpty(store) && (
					<Skeleton variant="rect" width="100%" height={400} animation="wave" />
				)}

				{!_.isEmpty(store) && (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12} lg={3}>
								<Card>
									<CardContent>
										<Breadcrumbs aria-label="breadcrumb">
											<Button
												onClick={() => {
													navigateToLink.push(ROUTES.VIEWALLSTORES, {
														query: ROUTES.VIEWALLSTORESQUERIES.ALLSTORES,
													});
												}}
											>
												All stores
											</Button>
											<Typography color="textPrimary">
												{typeof store.business_name !== "undefined" &&
													truncate(store.business_name, 15)}
											</Typography>
										</Breadcrumbs>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} lg={7}>
								<Card>
									<CardContent>
										<FormGroup row>
											{_.has(store, "vetted") && store.vetted === true && (
												<FormControlLabel
													control={
														<Switch
															checked={store.approved}
															disabled={store.approved}
															onChange={(
																event: React.ChangeEvent<HTMLInputElement>
															) =>
																dispatch(
																	Approve(
																		store.unique_id,
																		authuid,
																		event.target.checked
																	)
																)
															}
															color={store.approved ? "primary" : "secondary"}
														/>
													}
													label={store.approved ? "Approved" : "Approve"}
												/>
											)}

											{_.has(store, "vetted") && store.vetted === true && (
												<FormControlLabel
													control={
														<Switch
															checked={store.blocked}
															onChange={(
																event: React.ChangeEvent<HTMLInputElement>
															) =>
																dispatch(
																	Block(
																		store.unique_id,
																		authuid,
																		event.target.checked
																	)
																)
															}
															color={store.blocked ? "primary" : "secondary"}
														/>
													}
													label={store.blocked ? "UnBlock" : "Block"}
												/>
											)}
											{_.has(store, "vetted") && store.vetted === true && (
												<FormControlLabel
													control={
														<Switch
															checked={store.active}
															onChange={(
																event: React.ChangeEvent<HTMLInputElement>
															) =>
																dispatch(
																	Activate(
																		store.unique_id,
																		authuid,
																		event.target.checked
																	)
																)
															}
															color={store.active ? "primary" : "secondary"}
														/>
													}
													label={
														store.active ? "Hide from market" : "Activate Store"
													}
												/>
											)}

											{!store.paid_for_review && (
												<FormControlLabel
													control={
														<Switch
															checked={store.paid_for_review}
															onChange={(
																event: React.ChangeEvent<HTMLInputElement>
															) =>
																setOpenConfirmForVettingDialog(
																	event.target.checked
																)
															}
															color={
																store.paid_for_review ? "primary" : "secondary"
															}
														/>
													}
													label={
														store.paid_for_review
															? "Paid for vetting"
															: "Confirm for Vetting (Bye-pass payment)"
													}
												/>
											)}
										</FormGroup>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} lg={2}>
								<Card>
									<CardContent>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "flex-end",
												width: "100%",
											}}
										>
											{store.pending_vet && (
												<Chip
													icon={<BeenhereTwoToneIcon />}
													label="Pending Vet"
													variant="outlined"
													color="secondary"
													style={{
														color: "yellow",
														border: "1px yellow solid",
													}}
												/>
											)}
											{store.under_vetting_by_legal && (
												<Chip
													icon={<BeenhereTwoToneIcon />}
													label="Under Vetting"
													variant="outlined"
													color="secondary"
													style={{
														color: "orange",
														border: "1px orange solid",
													}}
												/>
											)}
											{store.vetted && (
												<Chip
													icon={<BeenhereTwoToneIcon />}
													label="Vetted"
													variant="outlined"
													color="secondary"
												/>
											)}

											{store.failed_vet && (
												<Chip
													icon={<BeenhereTwoToneIcon />}
													label=" Failed Vetting"
													variant="outlined"
													color="secondary"
													style={{ color: "red", border: "1px red solid" }}
												/>
											)}
											{/* {store.pending_vet ? (
                    <Chip
                      icon={<BeenhereTwoToneIcon />}
                      label="Vetted"
                      variant="outlined"
                      color="secondary"
                    />
                  ) : (
                    <Chip
                      icon={<BeenhereTwoToneIcon />}
                      label="Not Vetted"
                      variant="outlined"
                      color="secondary"
                      style={{ color: "red", border: "1px red solid" }}
                    />
                  )} */}
										</div>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
						<br />
						<Divider />
						<br />

						<Grid container direction="row" spacing={2}>
							<Grid item xs={12} lg={2}>
								<Tabs
									value={tabIndexValue}
									onChange={handleTabChange}
									indicatorColor="primary"
									textColor="primary"
									aria-label="store details"
									orientation="vertical"
									variant="scrollable"
									className={styles.tabs}
								>
									<Tab label="General" {...a11yProps(0)} />
									<Tab label="Products" {...a11yProps(1)} />
									<Tab label="Orders" {...a11yProps(2)} />
									<Tab label="Statistics" {...a11yProps(3)} />
									<Tab label="Wallet" {...a11yProps(3)} />
									<Tab label="Reviews" {...a11yProps(4)} />
									<Tab label="Reports" {...a11yProps(5)} />
								</Tabs>
								<br />
								<Button
									color="primary"
									size="large"
									variant="contained"
									style={{ color: "white", marginLeft: "10%" }}
									onClick={() => {
										navigateToLink.push(ROUTES.EDIT_STORE, {
											id: id,
										});
									}}
								>
									Edit Vendor
								</Button>
							</Grid>
							<Grid item xs={12} lg={10}>
								<SwipeableViews
									axis={theme.direction === "rtl" ? "x-reverse" : "x"}
									index={tabIndexValue}
									onChangeIndex={handleTabChangeIndex}
								>
									<TabPanel
										value={tabIndexValue}
										index={0}
										dir={theme.direction}
									>
										<div className={styles.tab_panel_wrapper}>
											<div
												className={styles.store_image_wrapper}
												style={{
													backgroundImage:
														store.business_banner_id !== ""
															? `url(https://firebasestorage.googleapis.com/v0/b/ekioja-7bb9f.appspot.com/o/images%2F${store.business_banner_id}?alt=media)`
															: `url(${BG})`,
												}}
											>
												{store.business_logo_id === "" && (
													<div className={styles.logo}>
														<img src={EkiojaBag} alt="ekioja logo" />
													</div>
												)}
												{store.business_logo_id !== "" && (
													<div className={styles.logo}>
														<GetImageFromFirebase
															source={store.business_logo_id}
														/>
													</div>
												)}
											</div>

											<Typography variant="h5">
												{" "}
												{store.business_name}
											</Typography>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={4}>
													<Card>
														<CardContent>
															<Typography
																className={styles.card_title}
																color="textSecondary"
																gutterBottom
															>
																Business email
															</Typography>
															<Typography variant="body2" component="p">
																{store.business_email}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item xs={12} lg={4}>
													<Card>
														<CardContent>
															<Typography
																className={styles.card_title}
																color="textSecondary"
																gutterBottom
															>
																Website
															</Typography>
															<Typography variant="body2" component="p">
																{store.business_website !== ""
																	? store.business_website
																	: "Nil"}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item xs={12} lg={4}>
													<Card>
														<CardContent>
															<Typography
																className={styles.card_title}
																color="textSecondary"
																gutterBottom
															>
																Representative
															</Typography>
															<Typography variant="body2" component="p">
																{`${store.first_name} ${store.last_name}`}
															</Typography>
															<Typography variant="body2" component="p">
																{`Phone -  ${store.contact_phone_number}`}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item xs={12} lg={4}>
													<Card>
														<CardContent>
															<Typography
																className={styles.card_title}
																color="textSecondary"
																gutterBottom
															>
																Business Slogan
															</Typography>
															<Typography variant="body2" component="p">
																{store.business_slogan !== ""
																	? store.business_slogan
																	: "Nil"}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item xs={12} lg={4}>
													<Card>
														<CardContent>
															<Typography
																className={styles.card_title}
																color="textSecondary"
																gutterBottom
															>
																Niches
															</Typography>
															<Typography variant="body2" component="p">
																{store.business_niche}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item xs={12} lg={4}>
													<Card>
														<CardContent>
															<Typography
																className={styles.card_title}
																color="textSecondary"
																gutterBottom
															>
																Joined
															</Typography>
															<Typography variant="body2" component="p">
																{typeof store.date_added !== "undefined" &&
																	moment(store.date_added.toDate())
																		.fromNow()
																		.toString()}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
												<Grid item xs={12} lg={4}>
													<Card>
														<CardContent>
															<Typography
																className={styles.card_title}
																color="textSecondary"
																gutterBottom
															>
																Location
															</Typography>
															<Typography variant="body2" component="p">
																{store.business_location?.street_address_1} ,
																{store.business_location?.postal_code_1}
															</Typography>
														</CardContent>
													</Card>
													{/* <Card>
                                            <CardContent>
                                                <Typography className={styles.card_title} color="textSecondary" gutterBottom>
                                                Social media
                                            </Typography>
                                                <Typography variant="body2" component="p">
                                                Facebook page link
                                                <br />
                                                {'"facebook"'}
                                                <br />
                                                Instagram Account link
                                                <br />
                                                {'"instagram"'}
                                                <br />
                                                WhatsApp Business Account link
                                                <br />
                                                {'"whatsapp"'}
                                                <br />
                                                </Typography>
                                            </CardContent>
                                        </Card> */}
												</Grid>
											</Grid>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={12}>
													<Card>
														<CardContent>
															<Typography
																className={styles.card_title}
																color="textSecondary"
																gutterBottom
															>
																Business description
															</Typography>
															<Typography variant="body2" component="p">
																{store.business_description}
															</Typography>
														</CardContent>
													</Card>
												</Grid>
											</Grid>
										</div>
									</TabPanel>
									<TabPanel
										value={tabIndexValue}
										index={1}
										dir={theme.direction}
									>
										<div className={styles.tab_panel_wrapper}>
											<ProductsWidget entity={id} />
										</div>
									</TabPanel>
									<TabPanel
										value={tabIndexValue}
										index={2}
										dir={theme.direction}
									>
										<div className={styles.tab_panel_wrapper}>
											<OrdersWidget entity={id} />
										</div>
									</TabPanel>
									<TabPanel
										value={tabIndexValue}
										index={3}
										dir={theme.direction}
									>
										<div className={styles.tab_panel_wrapper}>
											<h5>Coming soon...</h5>
										</div>
									</TabPanel>
									<TabPanel
										value={tabIndexValue}
										index={4}
										dir={theme.direction}
									>
										<div className={styles.tab_panel_wrapper}>
											<h5>Coming soon...</h5>
										</div>
									</TabPanel>
									<TabPanel
										value={tabIndexValue}
										index={5}
										dir={theme.direction}
									>
										<div className={styles.tab_panel_wrapper}>
											<h5>Coming soon...</h5>
										</div>
									</TabPanel>
									<TabPanel
										value={tabIndexValue}
										index={6}
										dir={theme.direction}
									>
										<div className={styles.tab_panel_wrapper}>
											<h5>Coming soon...</h5>
										</div>
									</TabPanel>
								</SwipeableViews>
							</Grid>
						</Grid>
					</>
				)}
			</DashBoardLayout>
		</Fragment>
	);
};

export default StoreDetails;
