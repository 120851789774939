import { useQuery } from "react-query";
import firebase from "../../config/firebaseConfig";

import _ from "lodash";

const getLogisticsComStatus = async (key: any, id: string) => {
	const response = await firebase
		.firestore()
		.collection("LogisticsCompanies")
		.doc(id)
		.get();
	const data: any = response.data();

	//A means not approved yet
	//B means not active yet
	//C means not blocked
	//D means all good
	let status = "";
	if (_.has(data, "approved") && data.approved === false) {
		status = "A";
	} else if (_.has(data, "active") && data.active === false) {
		status = "B";
	} else if (_.has(data, "blocked") && data.blocked === true) {
		status = "C";
	} else {
		status = "D";
	}

	return status;
};

const useGetLogisticsComStatus = (id: string) => {
	return useQuery(["logisticsStatus", id], getLogisticsComStatus);
};

export default useGetLogisticsComStatus;
