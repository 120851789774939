import React, { Fragment, useEffect, useState } from "react";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PaymentIcon from "@material-ui/icons/Payment";
//number format
import NumberFormat from "react-number-format";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { default as MUSelect } from "@material-ui/core/Select";
//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";
//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";

import LoadingScreen from "../components/LoadingScreen";

import {
	getEkiojaVendorsUpcomingRevenueTimeFrame,
	getEkiojaVendorsPendingClearanceRevenueTimeFrame,
	getEkiojaVendorsEarnedRevenueTimeFrame,
	getEkiojaVendorsRefundsTimeFrame,
} from "../utils";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			height: 200,
			width: "100%",
			borderRadius: 20,
		},
		money_title: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			flexDirection: "column",
		},
		available_wrapper: {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const Wallet = () => {
	const styles = useStyles();

	const [signUpBalance, setSignUpBalance] = useState(0);
	const [productPromotionBalance, setProductPromotionBalance] = useState(0);
	const [orderBalance, setOrderBalance] = useState(0);
	const [profit, setProfit] = useState(0);

	const [upComingRevenue, setUpComingRevenue] = useState(0);
	const [pendingClearanceRevenue, setPendingClearanceRevenue] = useState(0);
	const [earnedRevenue, setEarnedRevenue] = useState(0);
	const [refunds, setRefunds] = useState(0);

	const [orderPlaced, setOrderPlaced] = useState<any>([]);
	const [outForDelivery, setOutForDelivery] = useState<any>([]);
	const [delivered, setDelivered] = useState<any>([]);
	const [refunded, setRefunded] = useState<any>([]);

	const [open, setOpen] = React.useState(false);
	const [timeframeValue, setTimeframeValue] = useState("today");

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	useFirestoreConnect([
		{
			collection: "Wallets",
			where: [["owner_id", "==", "ekioja-super"]],
			storeAs: "MyWallet",
		},
		{
			collection: "Orders",
		},
		{
			collection: "OrdersTimeline",
		},
		// {
		// 	collection: "Wallets",
		// },
	]);

	// const allWallets = useSelector(
	// 	(state: any) => state.firestore.ordered.Wallets
	// );
	const wallet = useSelector((state: any) => state.firestore.data["MyWallet"]);

	const orders = useSelector((state: any) => state.firestore.ordered.Orders);

	const ordersTimeline = useSelector(
		(state: any) => state.firestore.ordered.OrdersTimeline
	);

	useEffect(() => {
		if (isLoaded(orders) && isLoaded(ordersTimeline)) {
			let orderTimeline_data: any = [];
			if (ordersTimeline !== null) {
				for (var orderTimelinekey in ordersTimeline) {
					orderTimeline_data.push({
						...ordersTimeline[orderTimelinekey],
						orderTimelinekey_uuid: orderTimelinekey,
					});
				}
			}

			let order_data = [];
			if (orders !== null) {
				for (var orderkey in orders) {
					order_data.push({ ...orders[orderkey], order_uuid: orderkey });
				}
			}

			const mapDeliveryAddressToitems = _.map(order_data, (item) => {
				return {
					...item,
					items: _.map(item.items, (elems) => {
						return {
							...elems,
							order_id: item.order_id,
							added_on: item.added_on,
						};
					}),
				};
			});

			const all_items_in_orders_and_ordertimeline = _.map(
				mapDeliveryAddressToitems,
				(item) => {
					return {
						...item,

						items: _.intersectionWith(
							_.cloneDeep(item.items),
							orderTimeline_data,
							function (x: any, y: any) {
								return (
									x.order_id === y.the_order_id &&
									x.product_id === y.order_product_id &&
									// x.entity === profile.entity &&
									_.assign(x, y)
								);
							}
						),
					};
				}
			);

			//get order items
			const order_items = _.flatMapDeep(
				_.map(all_items_in_orders_and_ordertimeline, (item) => item.items)
			);

			//get order items belonging to store
			const store_order_item = _.map(order_items, _.cloneDeep);

			//upcoming revenue

			const uprevenue = _.filter(
				store_order_item,
				(item) =>
					_.has(item, "order_placed") &&
					typeof item.order_placed.current !== "undefined" &&
					item.order_placed.current === true
			);
			setOrderPlaced([...uprevenue]);

			//pending clearance revenue
			const pcrevenue = _.filter(
				store_order_item,
				(item) =>
					_.has(item, "out_for_delivery") &&
					typeof item.out_for_delivery.current !== "undefined" &&
					item.out_for_delivery.current === true
			);
			setOutForDelivery([...pcrevenue]);

			//earned revenue
			const erevenue = _.filter(
				store_order_item,
				(item) =>
					_.has(item, "delivered") &&
					typeof item.delivered.current !== "undefined" &&
					item.delivered.current === true
			);
			setDelivered([...erevenue]);

			//revenue
			const rrevenue = _.filter(
				store_order_item,
				(item) =>
					_.has(item, "refunded") &&
					typeof item.refunded.current !== "undefined" &&
					item.refunded.current === true
			);
			setRefunded([...rrevenue]);

			setTimeframeValue("today");
			setUpComingRevenue(
				getEkiojaVendorsUpcomingRevenueTimeFrame(uprevenue, "today")
			);
			setPendingClearanceRevenue(
				getEkiojaVendorsPendingClearanceRevenueTimeFrame(pcrevenue, "today")
			);
			setEarnedRevenue(
				getEkiojaVendorsEarnedRevenueTimeFrame(erevenue, "today")
			);
			setRefunds(getEkiojaVendorsRefundsTimeFrame(rrevenue, "today"));
		}
	}, [orders, ordersTimeline]);

	// useEffect(() => {
	// 	if (isLoaded(allWallets)) {
	// 		const deleteandAddWallets = () => {
	// 			const allAffected = _.filter(
	// 				allWallets,
	// 				(item) =>
	// 					item.id !== item.owner_id && item.owner_id !== "ekioja-super"
	// 			);

	// 			const allAffectIds: any = [];

	// 			for (let allAffectedKey in allAffected) {
	// 				allAffectIds.push(allAffected[allAffectedKey]);
	// 			}
	// 			const allAffectUUID: any = [];

	// 			for (let allAffectedUUIDKey in allAffected) {
	// 				allAffectUUID.push(allAffected[allAffectedUUIDKey].id);
	// 			}

	// 			// const deleteWalletsPromises: any = [];
	// 			// for (var uuid in allAffectUUID) {
	// 			// 	const p = firebase
	// 			// 		.firestore()
	// 			// 		.collection("Wallets")
	// 			// 		.doc(allAffectUUID[uuid])
	// 			// 		.delete();
	// 			// 	deleteWalletsPromises.push(p);
	// 			// }

	// 			// const creatWalletsPromises: any = [];
	// 			// for (var id in allAffectIds) {
	// 			// 	const pid = firebase
	// 			// 		.firestore()
	// 			// 		.collection("Wallets")
	// 			// 		.doc(allAffectIds[id].owner_id)
	// 			// 		.set({
	// 			// 			...allAffectIds[id],
	// 			// 		});
	// 			// 	creatWalletsPromises.push(pid);
	// 			// }

	// 			// return Promise.all([...deleteWalletsPromises])
	// 			// 	.then(() => {
	// 			// 		console.log("deleted!");
	// 			// 	})
	// 			// 	.catch((error: any) => {
	// 			// 		console.log(error);
	// 			// 	});
	// 		};

	// 		deleteandAddWallets();
	// 	}
	// }, [allWallets]);

	useEffect(() => {
		if (isLoaded(wallet)) {
			let wallet_data: any[] = [];
			if (wallet !== null) {
				for (var walletkey in wallet) {
					wallet_data.push(wallet[walletkey]);
				}
			}

			setSignUpBalance(wallet_data[0].sign_up_fee);
			setProductPromotionBalance(wallet_data[0].product_promotion);
			setOrderBalance(wallet_data[0].order);
			setProfit(wallet_data[0].profit);
		}
	}, [wallet]);

	if (!isLoaded(wallet) && !isLoaded(orders) && !isLoaded(ordersTimeline))
		return <LoadingScreen />;
	return (
		<Fragment>
			<DashBoardLayout menuIndex={7}>
				<div className={styles.page_header}>
					<Typography variant="h4" color="primary">
						{" "}
						Wallet
					</Typography>
				</div>
				<br />
				<Container>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={3}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<LocalAtmIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Signup fee
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={signUpBalance}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<PaymentIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Product Promotion
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={productPromotionBalance}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<PaymentIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Orders
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={orderBalance}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<PaymentIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Profit
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={profit}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<PaymentIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Total Revenue
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={signUpBalance + productPromotionBalance + profit}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<br />
					<br />
					<Typography variant="h5" color="textPrimary" align="center">
						Overview of vendor's orders and sales
					</Typography>
					<Divider />
					<br />
					<Grid container>
						<Grid item lg={2}>
							<FormControl fullWidth>
								<InputLabel id="open-select-label">
									Select a Time Frame
								</InputLabel>
								<MUSelect
									labelId="open-select-label"
									id="open-select"
									open={open}
									onClose={handleClose}
									onOpen={handleOpen}
									value={timeframeValue}
									onChange={(event: any) => {
										setTimeframeValue(event.target.value);
										setUpComingRevenue(
											getEkiojaVendorsUpcomingRevenueTimeFrame(
												orderPlaced,
												event.target.value
											)
										);
										setPendingClearanceRevenue(
											getEkiojaVendorsPendingClearanceRevenueTimeFrame(
												outForDelivery,
												event.target.value
											)
										);
										setEarnedRevenue(
											getEkiojaVendorsEarnedRevenueTimeFrame(
												delivered,
												event.target.value
											)
										);
										setRefunds(
											getEkiojaVendorsRefundsTimeFrame(
												refunded,
												event.target.value
											)
										);
									}}
								>
									<MenuItem value="today">Today</MenuItem>
									<MenuItem value="within-a-week">Within a week</MenuItem>
									<MenuItem value="within-a-month">Within a month</MenuItem>
									<MenuItem value="over-two-weeks">Over two weeks ago</MenuItem>
								</MUSelect>
							</FormControl>
						</Grid>
					</Grid>
					<br />
					<br />
					<Grid container spacing={3}>
						<Grid item xs={12} lg={3}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<LocalAtmIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Upcoming Revenue
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={upComingRevenue}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									(Order placed)
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									{timeframeValue === "today" && "Within today"}
									{timeframeValue === "within-a-week" &&
										"Within the past 7 days"}
									{timeframeValue === "within-a-month" &&
										"Within the past 30 days"}
									{timeframeValue === "over-two-weeks" &&
										"Over two weeks ago or more"}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<LocalAtmIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary" align="center">
										Pending Clearance Revenue
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={pendingClearanceRevenue}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									(Out for delivery)
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									{timeframeValue === "today" && "Within today"}
									{timeframeValue === "within-a-week" &&
										"Within the past 7 days"}
									{timeframeValue === "within-a-month" &&
										"Within the past 30 days"}
									{timeframeValue === "over-two-weeks" &&
										"Over two weeks ago or more"}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<LocalAtmIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Earned Revenue
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={earnedRevenue}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									(Delivered)
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									{timeframeValue === "today" && "Within today"}
									{timeframeValue === "within-a-week" &&
										"Within the past 7 days"}
									{timeframeValue === "within-a-month" &&
										"Within the past 30 days"}
									{timeframeValue === "over-two-weeks" &&
										"Over two weeks ago or more"}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<PaymentIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Refunds
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={refunds}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									(Refunds)
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									{timeframeValue === "today" && "Within today"}
									{timeframeValue === "within-a-week" &&
										"Within the past 7 days"}
									{timeframeValue === "within-a-month" &&
										"Within the past 30 days"}
									{timeframeValue === "over-two-weeks" &&
										"Over two weeks ago or more"}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</DashBoardLayout>
		</Fragment>
	);
};

export default Wallet;
