import { useMutation } from "react-query";

import firebase from "../../../config/firebaseConfig";
// const queryClient = new QueryClient();
const removeBankAccountData = async (id: string) => {
	return firebase
		.firestore()
		.collection("LogisticsCompanies")
		.doc(id)
		.set(
			{
				psreciept_code: "",
				psreciept_data: {
					account_name: "",
					account_number: "",
					bank_code: "",
				},
			},
			{ merge: true }
		);
};

const useRemoveBankAccountData = () => {
	return useMutation(removeBankAccountData, {
		onSuccess: () => {
			// queryClient.refetchQueries();
		},
	});
};

export default useRemoveBankAccountData;
