//auth pages
export const LOGIN = "/";
export const FORGOT_PASSWORD = "/forgot-password";
export const EKIOJA_SUPER_ADMIN_SIGNUP = "/ekioja-admin-index-account";
export const CREATE_NEW_ADMIN =
	"/ekioja-admin-create-new-account/:entity/:role/:email/:classtype/:logistics_company_name";
export const REDIRECT_BRIDGE_PAGE = "/checking-accoount-status";
export const BLOCKED_ADMIN = "/you-have-been-blocked";
export const REVIEW_ADMIN_ACCOUNT = "/review-in-progress";
export const HOME = "/home";

//pages
export const DASHBOARD = "/dashboard";
export const VET_DASHBOARD = "/legal-dashboard";
export const LOGISTICS_DASHBOARD = "/logistics-dashboard";
export const FREELANCER_DASHBOARD = "/freelancer-dashboard";
export const ACCOUNT = "/account-settings";
export const STORESETTINGS = "/store-settings";
export const ADMINS_MANAGEMENT = "/store-admins-management";
export const PRODUCTS_MANAGEMENT = "/products-management";
export const ORDERS_MANAGEMENT = "/orders-management";
export const WALLET = "/wallet";
export const PAYOUT_REQUESTS = "/finance";
//sub pages

export const VIEWALLSTORES = "/stores/:query";
export const VIEWALLSTORESQUERIES = {
	ALLSTORES: "all-stores",
	ALL_ACTIVE_STORES: "all-active-stores",
	ALL_IN_ACTIVE_STORES: "all-in-active-stores",
	ALL_APPROVED_STORES: "all-approved-stores",
	ALL_NOT_APPROVED_STORES: "all-not-approved-stores",
	ALL_BLOCKED_STORES: "all-blocked-stores",
	ALL_PAID_PENDING_STORES: "all-paid-pending-stores",
	ALL_NOT_PAID_PENDING_STORES: "all-not-paid-pending-stores",
	ALL_UNDER_VETTING_STORES: "all-under-vetting-stores",
	All_VETTED_STORE: "all-vetted-stores",
	ALL_FAILED_STORE: "all-failed-stores",
};

export const VIEW_STORE_DETAILS = "/store-details/:id";

export const CATEGORIES = "/categories";

//product page queries
export const VIEWPRODUCTS = "/products/:query";
export const VIEWPRODUCTSQUERIES = {
	ALL_PRODUCTS: "all-products",
	ALL_ACTIVE_PRODUCTS: "all-active-products",
	ALL_IN_ACTIVE_PRODUCTS: "all-in-active-products",
	ALL_FEATURED_PRODUCTS: "all-featured-products",
	ALL_PROMOTED_PRODUCTS: "all-promoted-products",
	ALL_LOW_PRICE_RANGE_PRODUCTS: "all-low-price-range-products",
	ALL_HIGH_PRICE_RANGE_PRODUCTS: "all-high-price-range-products",
	ALL_UNDER_ASSESSMENT: "all-under-assessment",
	ALL_TO_BE_RESOLVED: "all-to-be-resolved",
	ALL_UNDER_CREATIVE_PROCESS: "all-under-creative-process",
};

export const VIEW_PRODUCT_DETAILS = "/product-details/:id";
export const EDIT_PRODUCT = "/edit-product-details/:id";
export const REVIEW_PRODUCT = "/review-product/:id";
export const RESOLVE_PRODUCT = "/resolve-product/:id";

export const INTERESTED_VENDORS = "/interested-vendors";

export const INTERESTED_VENDORS_QUERIES = {
	CHECKED_INTERESTED_VENDORS: "checked-interested-vendors",
	UNCHECKED_INTERESTED_VENDORS: "unchecked-interested-vendors",
	TODAYS_INTERESTED_VENDORS: "todays-interested-vendors",
	PREVIOUS_DAYS_INTERESTED_VENDORS: "previous-days-interested-vendors",
};

//marketplace
export const MARKETPLACE_CATEGORIES = "/market-categories";
export const VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST =
	"/market-categories-listing/:query";

export const MARKETPLACE_CATEGORIES_QUERIES = {
	ALL_HOME_AND_DECORS: "all-home-and-decor-products",
	ALL_ART_AND_CRAFT: "all-art-and-craft-products",
	ALL_BEADS_AND_WIREWORKS: "all-beads-and-wireworks",
	ALL_BEAUTY_PRODUCTS: "all-beauty-products",
	ALL_ClOTH_AND_FASHION: "all-cloth-and-fashion",
};

export const VIEW_MARKETPLACE_WISHLIST = "/customer-wishlist";

export const VIEW_MARKETPLACE_CART = "/customer-cart";

export const VIEW_MARKETPLACE_CHECKOUT = "/customer-checkout";

export const VIEW_MARKETPLACE_ORDERS = "/customer-orders";

export const VIEW_MARKETPLACE_ORDERS_DETAILS =
	"/customer-order-details/:order_uuid";

//vetting
export const VET = "/vet/:query";

export const VET_DOC_QUERIES = {
	ALL_VET_DOCS: "all-vet-docs",
	ALL_TO_BE_VETTED: "all-to-be-vetted",
	ALL_UNDER_VETTING: "all-under-vetted",
	ALL_DRAFT: "all-draft",
	ALL_PASSED: "all-passed",
	ALL_FAILED: "all-failed",
};

export const VET_WALLET = "/vet-wallet";

export const VET_MASTER = "/legal/:query";

export const VET_LEGAL_LIST = "/vetting-legals";

//logistics
export const LOGISTICS = "/logistics/:query";

export const LOGISTICS_EKIOJA_MASTER = "/master-logistics/:query";

export const LOGISTICS_BOOKINGS_QUERIES = {
	ALL_BOOKINGS: "all-bookings",
	ALL_WAITING_TO_BE_SHIPPED: "all-waiting-to-be-shipped-bookings",
	ALL_OUT_FOR_DELIVERY: "all-out-for-delivery-bookings",
	ALL_DELIVERED: "all-delivered-bookings",
	ALL_CANCELLED: "all-cancelled-bookings",
	ALL_RETURN: "all-return-bookings",
};

export const LOGISTICS_WALLET = "/logistics-wallet";

export const LOGISTICS_MASTER = "/all-logistics";

export const LOGISTICS_LEGAL_LIST = "/logistics-legals";

export const LOGISTICS_ADMINS_MANAGEMENT = "/logistics-admins-management";

export const LOGISTICS_ADMINS_SIGN_UP =
	"/logistics-admins-register/:entity/:email/:role";

export const LOGISTICS_DISPATCHER_DASHBOARD =
	"/logistics-dispatcher-bookings/:query";

export const INVITE_LOGISTICS_PARTNER =
	"/logistics-partner-create-new-account/:email/:logistics_company_name/:role";
export const INVITE_LOGISTICS_PARTNER_ADMIN =
	"/logistics-partner-admin-create-new-account/:email/:logistics_company_name/:role/:entity";

//brand stories
export const BRAND_STORIES = "/brand-story/:query";

export const BRAND_STORIES_QUERIES = {
	ALL_BRAND_STORIES: "all-brand-stories",
	ALL_LIVE_STORIES: "all-live-stories",
	ALL_PENDING_REVIEW: "all-pending-review",
	ALL_TO_BE_REVIEWED: "all-to-be-reviewed",
	ALL_UNDER_REVIEW: "all-under-review",
	ALL_TO_BE_RESOLVED: "all-to-be-resolved",
	ALL_PASSED: "all-passed",
};

export const ASSESS_BRAND_STORY = "/assess-brand-story/:id";
export const REVIEW_BRAND_STORY = "/review-brand-story/:id";

export const CREATIVES_STORY_JOB = "/creatives/:type/:query";

export const VIEWORDERSQUERIES = {
	ALL_ORDERS: "all-orders",
	ALL_ORDER_PLACED: "all-order-placed",
	ALL_PENDING_CONFIRMATION: "all-pending-confirmation",
	ALL_WAITING_TO_BE_SHIPPED: "all-waiting-to-be-shipped",
	ALL_SHIPPED: "all-shipped",
	ALL_OUT_FOR_DELIVERY: "all-out-for-delivery",
	ALL_DELIVERED: "all-delivered",
	ALL_RETURNED: "all-returned",
	ALL_CANCELLED: "all-cancelled",
};

export const VIEWORDERS = "/orders/:query";

export const CREATIVE_DASHBOARD = "/creatives-dashboard";
export const CREATIVE_JOBS = "/creatives-jobs";
export const CREATIVE_WALLET = "/creatives-wallet";

export const CREATIVES_MASTER = "/creatives-management";

export const FINANCE_DASHBOARD = "/finance-dashboard";
export const FINANCE_WALLET = "/finance-wallet";

//Edit store

export const EDIT_STORE = "/edit-vendor-details/:id";

export const KYCREPORTS = "/reports";

export const ADDONS = "/ekioja-products";

export const CHATS = '/ekioja-chat'

export const INTEGRATIONS = '/integrations'
