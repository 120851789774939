import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
// import ListSubheader from "@material-ui/core/ListSubheader";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// //react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

// //react redux firebase
import { isLoaded } from "react-redux-firebase";
//number format
// import NumberFormat from "react-number-format";

import CreativesJobWidget from "../DashBoardWidgets/CreativesJobWidget";

import _ from "lodash";
const BrandCreativesQuickLinks = () => {
	useFirestoreConnect([
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "BRAND_CREATIVE"],
				["to_be_processed.status", "==", true],
			],
			storeAs: "ToBeProcessedBrandCreativeCreativeJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "BRAND_CREATIVE"],
				["processing.status", "==", true],
			],
			storeAs: "ProcessedBrandCreativeCreativeJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "BRAND_CREATIVE"],
				["completed.status", "==", true],
			],
			storeAs: "CompletedBrandCreativeCreativeJobs",
		},
	]);

	const toBeProcessedBrandCreativeCreativeJobs = useSelector(
		(state: any) =>
			state.firestore.data["ToBeProcessedBrandCreativeCreativeJobs"]
	);

	const processedBrandCreativeCreativeJobs = useSelector(
		(state: any) => state.firestore.data["ProcessedBrandCreativeCreativeJobs"]
	);
	const completedBrandCreativeCreativeJobs = useSelector(
		(state: any) => state.firestore.data["CompletedBrandCreativeCreativeJobs"]
	);

	const [
		ToBeProcessedBrandCreativeCreativeJobs,
		setToBeProcessedBrandCreativeCreativeJobs,
	] = useState<any>([]);
	const [
		ProcessedBrandCreativeCreativeJobs,
		setProcessedBrandCreativeCreativeJobs,
	] = useState<any>([]);
	const [
		CompletedBrandCreativeCreativeJobs,
		setCompletedBrandCreativeCreativeJobs,
	] = useState<any>([]);

	useEffect(() => {
		if (
			isLoaded(toBeProcessedBrandCreativeCreativeJobs) &&
			typeof toBeProcessedBrandCreativeCreativeJobs !== "undefined" &&
			isLoaded(processedBrandCreativeCreativeJobs) &&
			typeof processedBrandCreativeCreativeJobs !== "undefined"
		) {
			let toBeProcessedBrandCreativeCreativeJobs_data: any[] = [];
			if (toBeProcessedBrandCreativeCreativeJobs !== null) {
				for (var toBeProcessedBrandCreativeCreativeJobs_data_item in toBeProcessedBrandCreativeCreativeJobs) {
					toBeProcessedBrandCreativeCreativeJobs_data.push({
						...toBeProcessedBrandCreativeCreativeJobs[
							toBeProcessedBrandCreativeCreativeJobs_data_item
						],
						toBeProcessedBrandCreativeCreativeJobs_data_uuid: toBeProcessedBrandCreativeCreativeJobs_data_item,
					});
				}
			}

			setToBeProcessedBrandCreativeCreativeJobs([
				...toBeProcessedBrandCreativeCreativeJobs_data,
			]);

			let processedBrandCreativeCreativeJobs_data: any[] = [];
			if (processedBrandCreativeCreativeJobs !== null) {
				for (var processedBrandCreativeCreativeJobs_data_item in processedBrandCreativeCreativeJobs) {
					processedBrandCreativeCreativeJobs_data.push({
						...processedBrandCreativeCreativeJobs[
							processedBrandCreativeCreativeJobs_data_item
						],
						processedBrandCreativeCreativeJobs_data_uuid: processedBrandCreativeCreativeJobs_data_item,
					});
				}
			}

			setProcessedBrandCreativeCreativeJobs([
				...processedBrandCreativeCreativeJobs_data,
			]);

			let completedBrandCreativeCreativeJobs_data: any[] = [];
			if (completedBrandCreativeCreativeJobs !== null) {
				for (var completedBrandCreativeCreativeJobs_data_item in completedBrandCreativeCreativeJobs) {
					completedBrandCreativeCreativeJobs_data.push({
						...completedBrandCreativeCreativeJobs[
							completedBrandCreativeCreativeJobs_data_item
						],
						completedBrandCreativeCreativeJobs_data_uuid: completedBrandCreativeCreativeJobs_data_item,
					});
				}
			}

			setCompletedBrandCreativeCreativeJobs([
				...completedBrandCreativeCreativeJobs_data,
			]);
		}
	}, [
		toBeProcessedBrandCreativeCreativeJobs,
		processedBrandCreativeCreativeJobs,
		completedBrandCreativeCreativeJobs,
	]);

	return (
		<div>
			<br />
			<br />
			<br />
			<Typography variant="h6">Brand Creatives quick links</Typography>
			<Divider />
			<br />
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(ToBeProcessedBrandCreativeCreativeJobs) &&
									isLoaded(ProcessedBrandCreativeCreativeJobs) &&
									isLoaded(CompletedBrandCreativeCreativeJobs)
										? ToBeProcessedBrandCreativeCreativeJobs.length +
										  ProcessedBrandCreativeCreativeJobs.length +
										  CompletedBrandCreativeCreativeJobs.length
										: 0
								}
								title="All Brand Creatives"
								routeQuery={"all"}
								type="brand-creative"
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(ToBeProcessedBrandCreativeCreativeJobs) &&
									!_.isEmpty(ToBeProcessedBrandCreativeCreativeJobs)
										? ToBeProcessedBrandCreativeCreativeJobs.length
										: 0
								}
								title="All To be Processed"
								routeQuery={"to-be-processed"}
								type="brand-creative"
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(ProcessedBrandCreativeCreativeJobs) &&
									!_.isEmpty(ProcessedBrandCreativeCreativeJobs)
										? ProcessedBrandCreativeCreativeJobs.length
										: 0
								}
								title="All Under Process"
								routeQuery={"processing"}
								type="brand-creative"
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(CompletedBrandCreativeCreativeJobs) &&
									!_.isEmpty(CompletedBrandCreativeCreativeJobs)
										? CompletedBrandCreativeCreativeJobs.length
										: 0
								}
								title="All Completed"
								routeQuery={"completed"}
								type="brand-creative"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} lg={6}>
					{/* <List
					// subheader={
					// 	<ListSubheader component="div">
					// 		Today's Brand Creatives Earning data
					// 	</ListSubheader>
					// }
					>
						<ListItem>
							<ListItemText
								primary="Upcoming earnings"
								secondary={
									<Fragment>
										<NumberFormat
											value={
												isLoaded(ToBeProcessedBrandCreativeCreativeJobs) &&
												!_.isEmpty(ToBeProcessedBrandCreativeCreativeJobs)
													? ToBeProcessedBrandCreativeCreativeJobs.length * 100
													: 0
											}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"₦"}
										/>
									</Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								primary="Pending Clearance"
								secondary={
									<Fragment>
										<NumberFormat
											value={
												isLoaded(ProcessedBrandCreativeCreativeJobs) &&
												!_.isEmpty(ProcessedBrandCreativeCreativeJobs)
													? ProcessedBrandCreativeCreativeJobs.length * 100
													: 0
											}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"₦"}
										/>
									</Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								primary="Earned"
								secondary={
									<Fragment>
										<NumberFormat
											value={
												isLoaded(CompletedBrandCreativeCreativeJobs) &&
												!_.isEmpty(CompletedBrandCreativeCreativeJobs)
													? CompletedBrandCreativeCreativeJobs.length * 100
													: 0
											}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"₦"}
										/>
									</Fragment>
								}
							/>
						</ListItem>
						<Divider />
					</List> */}
				</Grid>
			</Grid>
		</div>
	);
};

export default BrandCreativesQuickLinks;
