import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";


import HomeandDecor from "../assets/images/categories/home_and_decor.png";
import ArtandCraft from "../assets/images/categories/arts_and_craft.png";
import Beads from "../assets/images/categories/beads.png";
import Beauty from "../assets/images/categories/beauty.png";
import AfricanFashion from "../assets/images/categories/african_fashion.png";

//navigation hook
import { useNavigate } from "../hooks/navigate";

//routes
import * as ROUTES from "../constants/routes";

import MarketPlaceLayout from "../pages/Layouts/MarketPlaceLayout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        marginRight: 40,
      },
    },

    logo_wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: 100,
      height: 60,
      marginRight: 10,
      "& img": {
        width: 90,
        height: "auto",
      },
    },
    section_gutter: {
      marginTop: 100,
    },
    card_media: {
      height: 140,
    },
  })
);

const MarketProductCategory = () => {
  const styles = useStyles();

  const { navigateToLink } = useNavigate();

  return (
   
      <MarketPlaceLayout>
        <Container>
          <div className={styles.section_gutter} />
          <Grid container spacing={5}>
            <Grid item xs={12} lg={3}>
              <Card>
                <CardActionArea
                  onClick={() => {
                    navigateToLink.push(
                      ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST,
                      {
                        query:
                          ROUTES.MARKETPLACE_CATEGORIES_QUERIES
                            .ALL_HOME_AND_DECORS,
                      }
                    );
                  }}
                >
                  <CardMedia
                    className={styles.card_media}
                    image={HomeandDecor}
                    title="Home and decor cover"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Home and Decor
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Get the best of Home and Decor from Africa
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Card>
                <CardActionArea
                  onClick={() => {
                    navigateToLink.push(
                      ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST,
                      {
                        query:
                          ROUTES.MARKETPLACE_CATEGORIES_QUERIES
                            .ALL_ART_AND_CRAFT,
                      }
                    );
                  }}
                >
                  <CardMedia
                    className={styles.card_media}
                    image={ArtandCraft}
                    title="Art and craft cover"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Art and Craft
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Get the best of Art and Craft from Africa
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Card>
                <CardActionArea
                  onClick={() => {
                    navigateToLink.push(
                      ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST,
                      {
                        query:
                          ROUTES.MARKETPLACE_CATEGORIES_QUERIES
                            .ALL_BEADS_AND_WIREWORKS,
                      }
                    );
                  }}
                >
                  <CardMedia
                    className={styles.card_media}
                    image={Beads}
                    title=" Beads and Wireworks cover"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Beads and Wireworks
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Get the best of Beads and Wireworks from Africa
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Card>
                <CardActionArea
                  onClick={() => {
                    navigateToLink.push(
                      ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST,
                      {
                        query:
                          ROUTES.MARKETPLACE_CATEGORIES_QUERIES
                            .ALL_BEAUTY_PRODUCTS,
                      }
                    );
                  }}
                >
                  <CardMedia
                    className={styles.card_media}
                    image={Beauty}
                    title="Beauty Products cover"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Beauty Products
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Get the best of Beauty Products from Africa
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Card>
                <CardActionArea
                  onClick={() => {
                    navigateToLink.push(
                      ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST,
                      {
                        query:
                          ROUTES.MARKETPLACE_CATEGORIES_QUERIES
                            .ALL_ClOTH_AND_FASHION,
                      }
                    );
                  }}
                >
                  <CardMedia
                    className={styles.card_media}
                    image={AfricanFashion}
                    title="Cloth and  Fashion cover"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Cloth and Fashion
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Get the best of Cloth and Fashion from Africa
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MarketPlaceLayout>
   
  );
};

export default MarketProductCategory;
