import React, { useEffect, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { Link } from "react-router-dom";

import * as ROUTES from "../../../constants/routes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      borderRadius: 20,
      width: "100%",
    },
    gutter: {
      height: 30,
      width: "100%",
    },
    toolbar_wrapper: {
      // backgroundColor: '#303030',
      backgroundColor: theme.palette.background.default,
    },
    add_new_product_button: {
      marginTop: "5%",
    },
  })
);

interface Props {
  data: any[];
  title: string;
  show_action_link?: boolean;
}

const InterestedVendors = ({ data, title, show_action_link }: Props) => {
  const styles = useStyles();
  const [pageloading, setpageloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setpageloading(false);
    }, 1000);
  }, []);

  return (
    <Box className={styles.root} boxShadow={5}>
      <MaterialTable
        style={{ width: "100%" }}
        isLoading={pageloading}
        title={title}
        columns={[
          { title: "Fullname", field: "fullname" },
          { title: "Contact Number", field: "contact_number" },
          { title: "Email", field: "email" },
          { title: "Website", field: "website" },
          {
            title: "Added on",
            field: "added_on",
            render: (rowData) => (
              <Typography variant="caption">
                {rowData.added_on} <br /> {rowData.createdon}{" "}
              </Typography>
            ),
          },
          { title: "createdon", field: "createdon", hidden: true },
        ]}
        data={data}
        options={{
          search: true,
          exportAllData: true,
          exportButton: true,
          exportFileName: "interested_vendors",
        }}
        components={{
          Toolbar: (props) => (
            <div className={styles.toolbar_wrapper}>
              <Grid container>
                <Grid item lg={9}>
                  <MTableToolbar {...props} />
                </Grid>
                <Grid item lg={3}>
                  {show_action_link && (
                    <Link to={ROUTES.INTERESTED_VENDORS}>
                      <Button
                        color="primary"
                        className={styles.add_new_product_button}
                      >
                        VIEW ALL INTERESTED VENDORS
                      </Button>
                    </Link>
                  )}
                </Grid>
              </Grid>
            </div>
          ),
        }}
      />
    </Box>
  );
};

export default InterestedVendors;
