import React from "react";

//styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button, { ButtonProps } from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "max-content",
      position: "relative",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    buttonWrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
  })
);

interface Props extends ButtonProps {
  pending: boolean;
}

const LoadingButton = (props: Props) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Button
        {...props}
        style={{ backgroundColor: props.pending ? "gray" : props.color }}
      >
        {props.children}
      </Button>
      {props.pending && (
        <CircularProgress
          className={styles.buttonProgress}
          size={30}
          thickness={4}
        />
      )}
    </div>
  );
};

export default LoadingButton;
