  
import { useMutation } from "react-query";
import firebase from '../config/firebaseConfig';


const deleteImage = (id:string)=>{
   return firebase.storage().ref().child("images/" + id).delete()
}

 const useDeleteImage = ()=>{
    return useMutation(deleteImage);
}


export default useDeleteImage;