import React, { lazy, Suspense, useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import MarketPlaceLayout from "./Layouts/MarketPlaceLayout";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";




const WishlistList = lazy(
  () => import("../components/MarketPlace/WishlistList")
);



const MarketPlaceWishlist = () => {
 
  const [productsData, setProductsData] = useState<any>([]);

  useFirestoreConnect([
    {
      collection: "Products",
    },
    {
      collection: "Wishlist",
      where: [["wishlist_customer_id", "==", "demo-customer"]],
      storeAs: "CustomerWishlist",
    },
  ]);

  const products = useSelector(
    (state: any) => state.firestore.ordered.Products
  );

  const wishList = useSelector(
    (state: any) => state.firestore.data["CustomerWishlist"]
  );

  useEffect(() => {
    if (isLoaded(products) && isLoaded(wishList)) {
      let wishlist_data = [];
      if (wishList !== null) {
        for (var key in wishList) {
          wishlist_data.push({ ...wishList[key], wishlist_uuid: key });
        }
      }

      var result_products_in_wishlist = _.intersectionWith(
        _.cloneDeep(products),
        wishlist_data,
        function (x: any, y: any) {
          return x.product_id === y.wishlist_product_id && _.assign(x, y);
        }
      );

      const sorted = _.orderBy(
        result_products_in_wishlist,
        ["wishlist_added_on"],
        ["desc"]
      );

      setProductsData([...sorted]);
    }
  }, [products, wishList]);

  return (
    <MarketPlaceLayout>
      <Container>
        <div style={{ marginTop: 100 }} />
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Typography variant="h6">
              Saved items ({productsData.length})
            </Typography>
            <Divider />
            <Box boxShadow={0} style={{ padding: 20 }}>
              <Suspense fallback={<div>Loading...</div>}>
                <WishlistList data={productsData} />
              </Suspense>
            </Box>

 
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Container>
    </MarketPlaceLayout>
  );
};

export default MarketPlaceWishlist;
