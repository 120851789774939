import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// //react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

// //react redux firebase
import { isLoaded } from "react-redux-firebase";
//number format
// import NumberFormat from "react-number-format";

import CreativesJobWidget from "../DashBoardWidgets/CreativesJobWidget";

import _ from "lodash";
const MarketPlaceProductQuickLink = () => {
	useFirestoreConnect([
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "MARKET_PRODUCT"],
				["to_be_processed.status", "==", true],
			],
			storeAs: "ToBeProcessedMarketProductJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "MARKET_PRODUCT"],
				["processing.status", "==", true],
			],
			storeAs: "ProcessedMarketProductJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "MARKET_PRODUCT"],
				["completed.status", "==", true],
			],
			storeAs: "CompletedMarketProductJobs",
		},
	]);

	const toBeProcessedMarketProductJobs = useSelector(
		(state: any) => state.firestore.data["ToBeProcessedMarketProductJobs"]
	);

	const processedMarketProductJobs = useSelector(
		(state: any) => state.firestore.data["ProcessedMarketProductJobs"]
	);
	const completedMarketProductJobs = useSelector(
		(state: any) => state.firestore.data["CompletedMarketProductJobs"]
	);

	const [
		ToBeProcessedMarketProductJobs,
		setToBeProcessedMarketProductJobs,
	] = useState<any>([]);
	const [ProcessedMarketProductJobs, setProcessedMarketProductJobs] = useState<
		any
	>([]);
	const [CompletedMarketProductJobs, setCompletedMarketProductJobs] = useState<
		any
	>([]);

	useEffect(() => {
		if (
			isLoaded(toBeProcessedMarketProductJobs) &&
			typeof toBeProcessedMarketProductJobs !== "undefined" &&
			isLoaded(processedMarketProductJobs) &&
			typeof processedMarketProductJobs !== "undefined"
		) {
			let toBeProcessedMarketProductJobs_data: any[] = [];
			if (toBeProcessedMarketProductJobs !== null) {
				for (var toBeProcessedMarketProductJobs_data_item in toBeProcessedMarketProductJobs) {
					toBeProcessedMarketProductJobs_data.push({
						...toBeProcessedMarketProductJobs[
							toBeProcessedMarketProductJobs_data_item
						],
						toBeProcessedMarketProductJobs_data_uuid: toBeProcessedMarketProductJobs_data_item,
					});
				}
			}

			setToBeProcessedMarketProductJobs([
				...toBeProcessedMarketProductJobs_data,
			]);

			let processedMarketProductJobs_data: any[] = [];
			if (processedMarketProductJobs !== null) {
				for (var processedMarketProductJobs_data_item in processedMarketProductJobs) {
					processedMarketProductJobs_data.push({
						...processedMarketProductJobs[processedMarketProductJobs_data_item],
						processedMarketProductJobs_data_uuid: processedMarketProductJobs_data_item,
					});
				}
			}

			setProcessedMarketProductJobs([...processedMarketProductJobs_data]);

			let completedMarketProductJobs_data: any[] = [];
			if (completedMarketProductJobs !== null) {
				for (var completedMarketProductJobs_data_item in completedMarketProductJobs) {
					completedMarketProductJobs_data.push({
						...completedMarketProductJobs[completedMarketProductJobs_data_item],
						completedMarketProductJobs_data_uuid: completedMarketProductJobs_data_item,
					});
				}
			}

			setCompletedMarketProductJobs([...completedMarketProductJobs_data]);
		}
	}, [
		toBeProcessedMarketProductJobs,
		processedMarketProductJobs,
		completedMarketProductJobs,
	]);

	return (
		<div>
			<br />
			<br />
			<br />
			<Typography variant="h6">
				Marketplace product Images quick links
			</Typography>
			<Divider />
			<br />
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(ToBeProcessedMarketProductJobs) &&
									isLoaded(ProcessedMarketProductJobs) &&
									isLoaded(CompletedMarketProductJobs)
										? ToBeProcessedMarketProductJobs.length +
										  ProcessedMarketProductJobs.length +
										  CompletedMarketProductJobs.length
										: 0
								}
								title="All Product Images"
								routeQuery={"all"}
								type="market-product"
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(ToBeProcessedMarketProductJobs) &&
									!_.isEmpty(ToBeProcessedMarketProductJobs)
										? ToBeProcessedMarketProductJobs.length
										: 0
								}
								title="All To be Processed"
								routeQuery={"to-be-processed"}
								type="market-product"
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(ProcessedMarketProductJobs) &&
									!_.isEmpty(ProcessedMarketProductJobs)
										? ProcessedMarketProductJobs.length
										: 0
								}
								title="All Under Process"
								routeQuery={"processing"}
								type="market-product"
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<CreativesJobWidget
								count={
									isLoaded(CompletedMarketProductJobs) &&
									!_.isEmpty(CompletedMarketProductJobs)
										? CompletedMarketProductJobs.length
										: 0
								}
								title="All Completed"
								routeQuery={"completed"}
								type="market-product"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} lg={6}>
					{/* <List
					// subheader={
					// 	<ListSubheader component="div">
					// 		Today's Brand Creatives Earning data
					// 	</ListSubheader>
					// }
					>
						<ListItem>
							<ListItemText
								primary="Upcoming earnings"
								secondary={
									<Fragment>
										<NumberFormat
											value={
												isLoaded(ToBeProcessedMarketProductJobs) &&
												!_.isEmpty(ToBeProcessedMarketProductJobs)
													? ToBeProcessedMarketProductJobs.length * 100
													: 0
											}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"₦"}
										/>
									</Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								primary="Pending Clearance"
								secondary={
									<Fragment>
										<NumberFormat
											value={
												isLoaded(ProcessedMarketProductJobs) &&
												!_.isEmpty(ProcessedMarketProductJobs)
													? ProcessedMarketProductJobs.length * 100
													: 0
											}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"₦"}
										/>
									</Fragment>
								}
							/>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemText
								primary="Earned"
								secondary={
									<Fragment>
										<NumberFormat
											value={
												isLoaded(CompletedMarketProductJobs) &&
												!_.isEmpty(CompletedMarketProductJobs)
													? CompletedMarketProductJobs.length * 100
													: 0
											}
											displayType={"text"}
											thousandSeparator={true}
											prefix={"₦"}
										/>
									</Fragment>
								}
							/>
						</ListItem>
						<Divider />
					</List> */}
				</Grid>
			</Grid>
		</div>
	);
};

export default MarketPlaceProductQuickLink;
