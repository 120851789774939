import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const removeTopPickProduct = (id: string) => {
	return firebase
		.firestore()
		.collection("Products")
		.doc(id)
		.set({ is_top_product: false }, { merge: true });
};

const useRemoveTopPickProduct = () => {
	return useMutation(removeTopPickProduct);
};

export default useRemoveTopPickProduct;
