import {IAddress} from '../../../../ts/interfaces/addresses';
import { FEEDBACK } from '../../../../constants/feedback';

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const Editaddress = (values:IAddress): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();
        try {


            //create new doc in Admin collection
            firebase.firestore().collection('CustomerAddresses').doc(values.address_uuid).update({
               ...values
            }).then(() => {
                dispatch({ type: FEEDBACK, payload: { severity: 'success', title: 'Address edited! ', body: `` } })
            })


        } catch (error) {
            dispatch({ type: FEEDBACK, payload: { severity: 'error', title: 'Error!!!', body: error.code } })
        }

    }