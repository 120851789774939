import React, { useState } from 'react';
//components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';

//styles
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

//formik
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';

//yup
import * as yup from 'yup';

//icons
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//images
import Logo from '../../../assets/images/svg/ekioja-logo.svg';

//create account action
import { CreaNewEkiojaAdminstratorAccount } from '../../../store/actions/auth/adminstrator/createNewAccount'

//react - redux
import { useDispatch, useSelector } from 'react-redux';

//react redux firebase
import { isLoaded } from 'react-redux-firebase';

//custom Alert
import MKVAlert from '../../../components/FeedBack/MKVAlert';

//feedback type 
import { FEEDBACK } from '../../../constants/feedback';


//react router dom
import { Link, Redirect } from 'react-router-dom'

//signup yup schema
const SignupValidationSchema = yup.object().shape({
    first_name: yup.string().max(35, 'its too long').min(2, 'its too short').required('Your first name is Required'),
    last_name: yup.string().max(35, 'its too long').min(2, 'its too short').required('Your last name is Required'),
    username: yup.string().max(35, 'its too long').min(2, 'its too short').required('Your user name is Required'),
    email: yup.string().email().required('Your email is Required'),
    password: yup.string().label('Password').required('Your password is Required')
        .min(8, 'Your password must not be less than 8 characters')
    ,
    confirmpassword: yup.string().required()
        .test('passwords-match', 'Oops! Passwords do not match!', function (value) {
            return this.parent.password === value
        })
    ,
})


//styles functions
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100vw',
        },
        form_wrapper: {
            padding: 20,
            borderRadius: 20,
            width: '100%',
            height: 'auto',
        }
    })
);



const Signup = () => {

    const styles = useStyles();

    const [showPassword, setshowPassword] = useState(false);
    const dispatch = useDispatch();
    const feedback = useSelector((state: any) => state.feedBack.info);
    const auth = useSelector((state: any) => state.firebase.auth);
    const clearInfo = () => dispatch({ type: FEEDBACK, payload: { severity: '', title: '', body: '' } });


    if (isLoaded(auth.uid)) {
       
        return <Redirect to="/dashboard" />

    }
    return (
        <div className={styles.root}>
            {
                feedback.severity === 'error' || feedback.severity === 'warning' || feedback.severity === 'info' || feedback.severity === 'success' ? <MKVAlert severity={feedback.severity} title={feedback.title} body={feedback.body} show={true} hide={clearInfo} /> : null
            }
            <Container>

                <Grid container>
                    <Grid item xs={12} lg={4}></Grid>
                    <Grid item xs={12} lg={4}>
                        <img src={Logo} alt="ekioja" />

                        <Box boxShadow={5} className={styles.form_wrapper}>
                            <Formik
                                validationSchema={SignupValidationSchema}
                                initialValues={{
                                    first_name: '',
                                    last_name: '',
                                    username: '',
                                    email: '',
                                    password: '',
                                    role: 'Administrator',
                                    entity: 'ekioja-super',
                                    class: 'ekioja'
                                }}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setTimeout(() => {
                                        setSubmitting(false);
                                        dispatch(CreaNewEkiojaAdminstratorAccount(values))

                                    }, 500);
                                }}
                            >
                                {({ submitForm, isSubmitting, resetForm }) => (
                                    <Form>
                                        {isSubmitting && <LinearProgress />}
                                        <Field
                                            component={TextField}
                                            name="first_name"
                                            type="text"
                                            label="First Name"
                                            fullWidth
                                        />
                                        <br />
                                        <br />
                                        <Field
                                            component={TextField}
                                            name="last_name"
                                            type="text"
                                            label="Last Name"
                                            fullWidth
                                        />
                                        <br />
                                        <br />
                                        <Field
                                            component={TextField}
                                            name="username"
                                            type="text"
                                            label="Username"
                                            fullWidth
                                        />
                                        <br />
                                        <br />
                                        <Field
                                            component={TextField}
                                            name="email"
                                            type="email"
                                            label="Email"
                                            fullWidth
                                        />
                                        <br />
                                        <br />
                                        <Field
                                            component={TextField}
                                            type={showPassword ? 'text' : 'password'}
                                            label="Password (for your dashboard access)"
                                            name="password"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="start" onClick={() => setshowPassword(!showPassword)}>
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth

                                        />
                                        <br />
                                        <br />
                                        <Field
                                            component={TextField}
                                            type={showPassword ? 'text' : 'password'}
                                            label="Confirm Password"
                                            name="confirmpassword"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="start" onClick={() => setshowPassword(!showPassword)}>
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth

                                        />
                                        <br />
                                        <br />
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#4caf50', color: 'white' }}
                                            disabled={isSubmitting}
                                            onClick={submitForm}
                                        >
                                            Create Account
                                        </Button>
                                        <Link to="/">
                                            <Button>
                                                or Login
                                            </Button>
                                        </Link>
                                    </Form>
                                )}

                            </Formik>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={4}></Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Signup
