import React, { Fragment, useState, useEffect } from "react";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//material table
import MaterialTable from "material-table";

//material ui components
// import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import EditIcon from "@material-ui/icons/Edit";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LineStyleIcon from "@material-ui/icons/LineStyle";
//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//navigation hook
import { useNavigate } from "../../../hooks/navigate";

//routes
import * as ROUTES from "../../../constants/routes";

import _ from "lodash";

// import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

import ProcessMarketplaceJob from "./dialogs/ProcessMarketplaceJob";
import CompletedMarketplaceJob from "./dialogs/CompletedMarketplaceJob";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			position: "relative",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		root: {
			display: "flex",

			width: "100%",
		},

		queries_wrapper: {
			width: "100%",
			padding: 10,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
	})
);

const MarketProductJobs = ({ query }: { query?: string }) => {
	const styles = useStyles();
	const { navigateToLink } = useNavigate();
	const [tableTitle, setTableTitle] = useState("");
	const [pageloading, setpageloading] = useState(true);
	const [tableData, setTableData] = useState<any>([]);
	const [openProcess, setOpenProcess] = React.useState(false);
	const [processJobData, setProcessJobData] = useState<any>({});
	const [typeofJob, setTypeofJob] = useState("");
	const handleClickOpenProcess = () => {
		setOpenProcess(true);
	};

	const handleCloseProcess = () => {
		setOpenProcess(false);
	};

	// const [viewStoriesData, setViewStoriesData] = useState<any>({});
	// const [openViewVideoModal, setOpenViewVideoModal] = useState(false);

	useFirestoreConnect([
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "MARKET_PRODUCT"],
				["to_be_processed.status", "==", true],
			],
			storeAs: "ToBeProcessedMarketProductJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "MARKET_PRODUCT"],
				["processing.status", "==", true],
			],
			storeAs: "ProcessedMarketProductJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "MARKET_PRODUCT"],
				["completed.status", "==", true],
			],
			storeAs: "CompletedMarketProductJobs",
		},
	]);

	const toBeProcessedMarketProductJobs = useSelector(
		(state: any) => state.firestore.data["ToBeProcessedMarketProductJobs"]
	);

	const processedMarketProductJobs = useSelector(
		(state: any) => state.firestore.data["ProcessedMarketProductJobs"]
	);
	const completedMarketProductJobs = useSelector(
		(state: any) => state.firestore.data["CompletedMarketProductJobs"]
	);

	const [
		ToBeProcessedMarketProductJobs,
		setToBeProcessedMarketProductJobs,
	] = useState<any>([]);
	const [ProcessedMarketProductJobs, setProcessedMarketProductJobs] = useState<
		any
	>([]);
	const [CompletedMarketProductJobs, setCompletedMarketProductJobs] = useState<
		any
	>([]);

	useEffect(() => {
		if (
			isLoaded(toBeProcessedMarketProductJobs) &&
			typeof toBeProcessedMarketProductJobs !== "undefined" &&
			isLoaded(processedMarketProductJobs) &&
			typeof processedMarketProductJobs !== "undefined"
		) {
			let toBeProcessedMarketProductJobs_data: any[] = [];
			if (toBeProcessedMarketProductJobs !== null) {
				for (var toBeProcessedMarketProductJobs_data_item in toBeProcessedMarketProductJobs) {
					toBeProcessedMarketProductJobs_data.push({
						...toBeProcessedMarketProductJobs[
							toBeProcessedMarketProductJobs_data_item
						],
						toBeProcessedMarketProductJobs_data_uuid: toBeProcessedMarketProductJobs_data_item,
					});
				}
			}

			setToBeProcessedMarketProductJobs([
				...toBeProcessedMarketProductJobs_data,
			]);

			let processedMarketProductJobs_data: any[] = [];
			if (processedMarketProductJobs !== null) {
				for (var processedMarketProductJobs_data_item in processedMarketProductJobs) {
					processedMarketProductJobs_data.push({
						...processedMarketProductJobs[processedMarketProductJobs_data_item],
						processedMarketProductJobs_data_uuid: processedMarketProductJobs_data_item,
					});
				}
			}

			setProcessedMarketProductJobs([...processedMarketProductJobs_data]);

			let completedMarketProductJobs_data: any[] = [];
			if (completedMarketProductJobs !== null) {
				for (var completedMarketProductJobs_data_item in completedMarketProductJobs) {
					completedMarketProductJobs_data.push({
						...completedMarketProductJobs[completedMarketProductJobs_data_item],
						completedMarketProductJobs_data_uuid: completedMarketProductJobs_data_item,
					});
				}
			}

			setCompletedMarketProductJobs([...completedMarketProductJobs_data]);

			let data: any = [];

			if (query === "all") {
				const combineData = [
					...toBeProcessedMarketProductJobs_data,
					...processedMarketProductJobs_data,
					...completedMarketProductJobs_data,
				];
				data = _.map(combineData, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All marketplace images");
			} else if (query === "to-be-processed") {
				data = _.map(toBeProcessedMarketProductJobs_data, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All To Be Processed");
			} else if (query === "processing") {
				data = _.map(processedMarketProductJobs_data, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All Under Process");
			} else if (query === "completed") {
				data = _.map(completedMarketProductJobs_data, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All Completed");
			}
		}
	}, [
		toBeProcessedMarketProductJobs,
		processedMarketProductJobs,
		completedMarketProductJobs,
		query,
	]);

	return (
		<Fragment>
			<Dialog
				fullScreen
				open={openProcess}
				onClose={handleCloseProcess}
				TransitionComponent={Transition}
			>
				<AppBar className={styles.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseProcess}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={styles.title}>
							{typeofJob === "to-be-processed" && "Process marketplace images"}
							{typeofJob === "processing" && "Process marketplace images"}
							{typeofJob === "completed" && "Process marketplace images"}
						</Typography>
					</Toolbar>
				</AppBar>

				{typeofJob === "to-be-processed" && (
					<ProcessMarketplaceJob data={processJobData} />
				)}
				{typeofJob === "processing" && "Process marketplace images"}
				{typeofJob === "completed" && (
					<CompletedMarketplaceJob data={processJobData} />
				)}
			</Dialog>
			<br />
			<br />
			<div className={styles.queries_wrapper}>
				<Chip
					label={`All marketplace images (${
						ToBeProcessedMarketProductJobs.length +
						ProcessedMarketProductJobs.length +
						CompletedMarketProductJobs.length
					})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "all",
							type: "market-product",
						})
					}
				/>
				<Chip
					label={`All To Be Processed (${ToBeProcessedMarketProductJobs.length})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "to-be-processed",
							type: "market-product",
						})
					}
				/>
				<Chip
					label={`All Under Process (${ProcessedMarketProductJobs.length})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "processing",
							type: "market-product",
						})
					}
				/>
				<Chip
					label={`All Completed (${CompletedMarketProductJobs.length})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "completed",
							type: "market-product",
						})
					}
				/>
			</div>
			<Box className={styles.root} boxShadow={5}>
				<MaterialTable
					style={{ width: "100%" }}
					isLoading={pageloading}
					title={tableTitle}
					columns={[
						{
							title: "Reference",
							field: "ref",
							render: (rowData) => (
								<Typography variant="caption">{rowData.ref}</Typography>
							),
						},
						{
							title: "Vendor ",
							field: "vendor",
							render: (rowData) => (
								<Typography variant="caption">{rowData.vendor}</Typography>
							),
						},
						{
							title: "Task ",
							field: "job",
							render: (rowData) => (
								<Typography variant="caption">{rowData.job}</Typography>
							),
						},
						{
							title: "Status ",
							field: "status",
							render: (rowData) => (
								<Fragment>
									{_.has(rowData.data, "to_be_processed") &&
										rowData.data.to_be_processed.status && (
											<Chip
												icon={<EditIcon />}
												label="To be processed"
												variant="default"
											/>
										)}
									{_.has(rowData.data, "processing") &&
										rowData.data.processing.status && (
											<Chip
												icon={<LineStyleIcon />}
												label="Under Process"
												variant="default"
											/>
										)}
									{_.has(rowData.data, "completed") &&
										rowData.data.completed.status && (
											<Chip
												icon={<AssignmentTurnedInIcon />}
												label="Completed"
												variant="default"
											/>
										)}
								</Fragment>
							),
						},
						{ title: "data", field: "data", hidden: true },
						{
							title: "Action ",
							field: "action",
							render: (rowData) => (
								<Fragment>
									{_.has(rowData.data, "to_be_processed") &&
										rowData.data.to_be_processed.status && (
											<Button
												variant="contained"
												color="secondary"
												style={{ color: "#fff" }}
												onClick={() => {
													handleClickOpenProcess();
													setProcessJobData({ ...rowData.data });
													setTypeofJob("to-be-processed");
												}}
											>
												process
											</Button>
										)}
									{_.has(rowData.data, "processing") &&
										rowData.data.processing.status && (
											<Button
												variant="contained"
												color="secondary"
												style={{ color: "#fff" }}
												onClick={() => {
													setTypeofJob("processing");
												}}
											>
												process
											</Button>
										)}
									{_.has(rowData.data, "completed") &&
										rowData.data.completed.status && (
											<Button
												variant="contained"
												color="secondary"
												style={{ color: "#fff" }}
												onClick={() => {
													handleClickOpenProcess();
													setProcessJobData({ ...rowData.data });
													setTypeofJob("completed");
												}}
											>
												View
											</Button>
										)}
								</Fragment>
							),
						},
					]}
					data={tableData}
					options={{
						search: true,
						actionsColumnIndex: -1,
					}}
				/>
			</Box>
		</Fragment>
	);
};

export default MarketProductJobs;
