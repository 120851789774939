import React, { Fragment, useState, useEffect } from "react";
//material ui components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";

//icons
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Icon from "@material-ui/core/Icon";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//formik
import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-material-ui";

//types
import {
	ADMINSTRATOR,
	MANAGER,
	LEGAL,
	LOGISTICS,
	SUPER_ADMINSTRATOR,
	FINANCE,
	ACREATIVE,
	FCREATIVE,
	MEDIA,
} from "../constants/roles";

//material table
import MaterialTable, { MTableToolbar } from "material-table";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//react - redux
import { useSelector } from "react-redux";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//firebase
// import firebase from '../config/firebaseConfig'

//lodash
import _ from "lodash";

//moment
import moment from "moment";

//current admin
import { ICurrentAdmin } from "../ts/interfaces/currentAdmin";

import useApproveAdmin from "../hooks/admins/useApproveAdmin";
import useBlockAdmin from "../hooks/admins/useBlockAdmin";
import useUnBlockAdmin from "../hooks/admins/useUnBlockAdmin";
import useChangeRole from "../hooks/admins/useChangeRole";
import useInviteNewAdmin from "../hooks/admins/useInviteNewAdmin";
import useRemoveAdmin from "../hooks/admins/useRemoveAdmin";
import useGetAllEkiojaAdmins from "../hooks/admins/useGetAllEkiojaAdmins";

//yup
import * as yup from "yup";

//admin validation schema
const AdminValidationSchema = yup.object().shape({
	potential_user_email: yup.string().email().required("Email is Required"),
	role: yup.string().required("The Role is Required"),
});

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			padding: 20,
			overflowX: "auto",
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		vendor_logo: {
			width: 50,
			height: 50,
			borderRadius: 50,
			border: "1px white solid",
		},
		add_new_user_button: {
			marginTop: "5%",
			marginLeft: 50,
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const Users = () => {
	const styles = useStyles();

	const [userActionDialogState, setUserActionDialogState] = useState(false);
	// const [approvedState, setApprovedState] = useState(true);
	// const [blockedState, setBlockedState] = useState(false);
	const [deleteState, setDeleteState] = useState(false);
	const [changeRoleState, setChangeRoleState] = useState(false);
	const [addNewUserModalState, setAddNewUserModalState] = useState(false);
	const [viewedAdminData, setViewedAdminData] = useState<ICurrentAdmin | any>(
		{}
	);
	const [storeAdminsArr, setStoreAdminsArr] = useState<any>([]);

	const [ApproveAdminMutate, ApproveAdminInfo] = useApproveAdmin();
	const [BlockAdminMutate, BlockAdminInfo] = useBlockAdmin();
	const [UnBlockAdminMutate, UnBlockAdminInfo] = useUnBlockAdmin();
	const [ChangeRoleMutate, ChangeRoleInfo] = useChangeRole();
	const [InviteNewAdminMutate, InviteNewAdminInfo] = useInviteNewAdmin();
	const [RemoveAdminMutate, RemoveAdminInfo] = useRemoveAdmin();
	const GetAllEkiojaAdminsQuery = useGetAllEkiojaAdmins();

	const EkiojaAdmins = GetAllEkiojaAdminsQuery.isSuccess
		? GetAllEkiojaAdminsQuery.data
		: [];
	const currentUser = useSelector((state: any) => state.firebase.profile);

	useEffect(() => {
		if (GetAllEkiojaAdminsQuery.isSuccess) {
			let ekioja_admin_data: any[] = [];

			for (const EkiojaAdminkey in EkiojaAdmins) {
				ekioja_admin_data.push({
					...EkiojaAdmins[EkiojaAdminkey],
					uuid: EkiojaAdmins[EkiojaAdminkey].id,
				});
			}
			let mapArrToData = _.map(ekioja_admin_data, (item) => {
				return {
					avatar:
						_.upperCase(item.first_name.charAt(0)) +
						_.upperCase(item.last_name.charAt(0)),
					username: item.username,
					fullname:
						_.capitalize(item.first_name) + " " + _.capitalize(item.last_name),
					role: item.role,
					// joined: item.joined_on,
					joined: moment(item.joined_on.toDate()).fromNow(),
					approved: item.approved,
					blocked: item.blocked,
					email: item.email,
					data: item,
				};
			});

			setStoreAdminsArr([...mapArrToData]);
		}
	}, [GetAllEkiojaAdminsQuery.isSuccess, EkiojaAdmins]);

	const toggleUserActionModal = () => {
		setUserActionDialogState(!userActionDialogState);
	};

	// const toggleApproved = () => {
	//     setApprovedState(!approvedState);
	// }

	// const toggleBlocked = () => {
	//     setBlockedState(!blockedState);
	// }

	const toggleDelete = () => {
		setDeleteState(!deleteState);
	};

	const toggleChangeRole = () => {
		setChangeRoleState(!changeRoleState);
	};

	const toggleAddNewUserModal = () => {
		setAddNewUserModalState(!addNewUserModalState);
	};

	if (!isLoaded(currentUser)) return null;

	return (
		<Fragment>
			<DashBoardLayout menuIndex={4}>
				<div className={styles.page_header}>
					<Typography variant="h4" color="primary">
						{" "}
						Admins Management
					</Typography>
				</div>
				<br />
				<Dialog
					onClose={toggleAddNewUserModal}
					aria-labelledby="add-new-user-dialog"
					open={addNewUserModalState}
					maxWidth="sm"
					fullWidth={true}
				>
					<DialogTitle>Invite someone</DialogTitle>
					<DialogContent>
						<Formik
							initialValues={{
								logistics_company_name: "",
								potential_user_email: "",
								role: "",
								entity: "ekioja-super",
								classtype: "ekioja",
								invited_by: currentUser.first_name,
							}}
							validationSchema={AdminValidationSchema}
							onSubmit={(values, { setSubmitting, resetForm }) => {
								setTimeout(() => {
									setSubmitting(false);

									InviteNewAdminMutate(values, {
										onSuccess: () => {
											resetForm();
											InviteNewAdminInfo.reset();
											toggleAddNewUserModal();
										},
									});
								}, 1000);
							}}
						>
							{({ submitForm, isSubmitting, errors, touched, values }) => (
								<Form>
									<Grid container>
										<Grid item xs={12} lg={2}></Grid>
										<Grid item xs={12} lg={8}>
											{isSubmitting && <LinearProgress />}
											<Field
												component={TextField}
												name="potential_user_email"
												style={{ width: "100%" }}
												placeholder="Email of the person you want to add"
												type="email"
												required
											/>
											<br />
											<br />
											<FormControl>
												<InputLabel htmlFor="potential-user-role-field">
													Choose a role for them
												</InputLabel>
												<Field
													component={Select}
													required
													name="role"
													inputProps={{
														id: "potential-user-role-field",
													}}
													style={{ width: 370, paddingLeft: 20 }}
												>
													<MenuItem value={SUPER_ADMINSTRATOR}>
														Super Adminstration
													</MenuItem>
													<MenuItem value={ADMINSTRATOR}>Adminstrator</MenuItem>
													<MenuItem value={MANAGER}>Manager</MenuItem>
													<MenuItem value={LEGAL}>Legal</MenuItem>
													{/* <MenuItem value={LOGISTICS}>Logistics</MenuItem> */}
													<MenuItem value={ACREATIVE}>A-Creative</MenuItem>
													<MenuItem value={FCREATIVE}>F-Creative</MenuItem>
													<MenuItem value={FINANCE}>Finance</MenuItem>
													<MenuItem value={MEDIA}>Media</MenuItem>
												</Field>
											</FormControl>
											<br />
											<br />
											{values.role === LOGISTICS && (
												<>
													<Field
														component={TextField}
														name="logistics_company_name"
														style={{ width: "100%" }}
														placeholder="Logistics Company Name"
														type="text"
													/>
													<br />
													<br />
												</>
											)}
											{!errors.potential_user_email &&
											!errors.role &&
											touched.potential_user_email &&
											touched.role ? (
												<Button
													variant="contained"
													color="primary"
													disabled={isSubmitting}
													onClick={submitForm}
												>
													{InviteNewAdminInfo.isLoading
														? "Processing..."
														: InviteNewAdminInfo.isError
														? "Error!"
														: InviteNewAdminInfo.isSuccess
														? "Done!"
														: "	Send Invite"}
												</Button>
											) : (
												""
											)}
										</Grid>
										<Grid item xs={12} lg={2}></Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</DialogContent>
					<DialogActions>
						<Button
							variant="text"
							color="secondary"
							onClick={() => setAddNewUserModalState(false)}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					onClose={toggleUserActionModal}
					aria-labelledby="user-action-dialog"
					open={userActionDialogState}
					maxWidth="sm"
					fullWidth={true}
				>
					<DialogTitle id="simple-dialog-title">Role</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{viewedAdminData.first_name} {viewedAdminData.last_name} (
							{viewedAdminData.username}) : {viewedAdminData.role}
						</DialogContentText>
						<List>
							<ListItem>
								<ListItemText
									id="user-approved-state"
									primary={
										ApproveAdminInfo.isLoading
											? "Approving..."
											: ApproveAdminInfo.isError
											? "Error!"
											: ApproveAdminInfo.isSuccess
											? "Approved!"
											: viewedAdminData.approved
											? "Approved"
											: "Approve"
									}
								/>
								<ListItemSecondaryAction>
									<Switch
										edge="end"
										onChange={() =>
											ApproveAdminMutate(viewedAdminData.uuid, {
												onSuccess: () => ApproveAdminInfo.reset(),
											})
										}
										checked={viewedAdminData.approved}
										inputProps={{ "aria-labelledby": "user-approved-state" }}
										disabled={viewedAdminData.approved}
									/>
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem>
								<ListItemText
									id="user-blocked-state"
									primary={
										BlockAdminInfo.isLoading || UnBlockAdminInfo.isLoading
											? "Processing..."
											: BlockAdminInfo.isError || UnBlockAdminInfo.isError
											? "Error!"
											: BlockAdminInfo.isSuccess || UnBlockAdminInfo.isSuccess
											? "Done!"
											: viewedAdminData.blocked
											? "Unblock"
											: "Block"
									}
								/>
								<ListItemSecondaryAction>
									<Switch
										edge="end"
										onChange={() => {
											if (viewedAdminData.blocked) {
												UnBlockAdminMutate(viewedAdminData.uuid, {
													onSuccess: () => {
														UnBlockAdminInfo.reset();
													},
												});
											}
											if (viewedAdminData.blocked === false) {
												BlockAdminMutate(viewedAdminData.uuid, {
													onSuccess: () => {
														BlockAdminInfo.reset();
													},
												});
											}
										}}
										checked={viewedAdminData.blocked}
										inputProps={{ "aria-labelledby": "user-blocked-state" }}
									/>
								</ListItemSecondaryAction>
							</ListItem>
							<ListItem>
								<ListItemText
									id="user-delete-state"
									primary={
										RemoveAdminInfo.isLoading
											? "Removing user..."
											: RemoveAdminInfo.isError
											? "Error!"
											: RemoveAdminInfo.isSuccess
											? "Removed!"
											: "Remove"
									}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="user-delete-state"
										onClick={toggleDelete}
									>
										<DeleteIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
							<Collapse in={deleteState}>
								<Alert
									severity="warning"
									action={
										<Fragment>
											<Button
												onClick={() =>
													RemoveAdminMutate(viewedAdminData.uuid, {
														onSuccess: () => {
															RemoveAdminInfo.reset();
														},
													})
												}
											>
												Confirm
											</Button>
											<Button onClick={toggleDelete}>Cancel</Button>
										</Fragment>
									}
								>
									<AlertTitle>Remove User Confirmation</AlertTitle>
									You are about to remove this admin{" "}
									<strong> permanently</strong> from the list of roles in your
									store!
								</Alert>
							</Collapse>
							<ListItem>
								<ListItemText
									id="user-change-role-state"
									primary={
										ChangeRoleInfo.isLoading
											? "Changing role..."
											: ChangeRoleInfo.isError
											? "Error!"
											: ChangeRoleInfo.isSuccess
											? "Done!"
											: "Change role"
									}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="user-change-role-state"
										onClick={toggleChangeRole}
									>
										<EditIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
							<Collapse in={changeRoleState}>
								<Formik
									initialValues={{
										role: viewedAdminData.role,
									}}
									onSubmit={(values, { setSubmitting }) => {
										setTimeout(() => {
											setSubmitting(false);
											ChangeRoleMutate(
												{ id: viewedAdminData.uuid, role: values.role },
												{
													onSuccess: () => {
														ChangeRoleInfo.reset();
													},
												}
											);
										}, 500);
									}}
								>
									{({ submitForm, isSubmitting }) => (
										<Form>
											<Grid container>
												<Grid item xs={12} lg={6}>
													{isSubmitting && <LinearProgress />}
													<FormControl>
														<Field
															component={Select}
															name="role"
															inputProps={{
																id: "change-role-field",
															}}
															variant="outlined"
															style={{ width: "100%" }}
														>
															<MenuItem value={SUPER_ADMINSTRATOR}>
																Super Adminstration
															</MenuItem>
															<MenuItem value={ADMINSTRATOR}>
																Adminstrator
															</MenuItem>
															<MenuItem value={MANAGER}>Manager</MenuItem>
															<MenuItem value={LEGAL}>Legal</MenuItem>
															<MenuItem value={LOGISTICS}>Logistics</MenuItem>
															<MenuItem value={ACREATIVE}>A-Creative</MenuItem>
															<MenuItem value={FCREATIVE}>F-Creative</MenuItem>
															<MenuItem value={FINANCE}>Finance</MenuItem>
														</Field>
													</FormControl>
												</Grid>
												<Grid item xs={12} lg={6}>
													<br />
													<Button
														variant="contained"
														color="primary"
														disabled={isSubmitting}
														onClick={submitForm}
													>
														Change
													</Button>
												</Grid>
											</Grid>
										</Form>
									)}
								</Formik>
							</Collapse>
						</List>
					</DialogContent>
					<DialogActions>
						<Button onClick={toggleUserActionModal}>Close</Button>
					</DialogActions>
				</Dialog>

				<Box className={styles.root} boxShadow={0}>
					<MaterialTable
						style={{
							width: 1230,
						}}
						isLoading={
							GetAllEkiojaAdminsQuery.isLoading ||
							GetAllEkiojaAdminsQuery.isFetching
						}
						title="Admins"
						columns={[
							{
								title: "Avatar",
								field: "avatar",
								render: (rowData) => <Avatar>{rowData.avatar}</Avatar>,
							},
							{
								title: "Username",
								field: "username",
								render: (rowData) => (
									<Typography variant="subtitle1">
										{_.capitalize(rowData.username)}
										{currentUser.email === rowData.email ? "(you)" : ""}
									</Typography>
								),
							},
							{ title: "Full Name", field: "fullname" },
							{ title: "Email", field: "email" },
							{ title: "Role", field: "role" },
							{ title: "Joined", field: "joined" },
							{ title: "Approved", field: "approved", type: "boolean" },
							{ title: "Blocked", field: "blocked", type: "boolean" },
							{ title: "data", field: "data", hidden: true },
						]}
						data={storeAdminsArr}
						options={{
							search: true,
							actionsColumnIndex: -1,
						}}
						actions={[
							{
								icon: "save",
								tooltip: "Save User",
								onClick: (event, rowData: any) => {
									setViewedAdminData({ ...rowData.data });
									toggleUserActionModal();
								},
							},
						]}
						components={{
							Action: (props) => (
								<Button
									aria-label="view-vendor-details"
									size="medium"
									onClick={(event) => props.action.onClick(event, props.data)}
								>
									View
								</Button>
							),
							Toolbar: (props) => (
								<div>
									<Grid container>
										<Grid item lg={3}>
											<Tooltip title="Add a new admin" aria-label="add-admin">
												<Button
													variant="outlined"
													color="primary"
													className={styles.add_new_user_button}
													startIcon={<Icon>add</Icon>}
													onClick={toggleAddNewUserModal}
												>
													Add New Admin
												</Button>
											</Tooltip>
										</Grid>
										<Grid item lg={9}>
											<MTableToolbar {...props} />
										</Grid>
									</Grid>
								</div>
							),
						}}
					/>
				</Box>
			</DashBoardLayout>
		</Fragment>
	);
};

export default Users;
