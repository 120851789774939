import React, { Fragment, useState } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputLabel from "@material-ui/core/InputLabel";
// import FormControlLabel from "@material-ui/core/FormControlLabel";

//formik
import { Field, Form, Formik } from "formik";

import { Select } from "formik-material-ui";
// import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { green } from "@material-ui/core/colors";

//number format
import NumberFormat from "react-number-format";

//react - redux
import { useSelector, useDispatch } from "react-redux";

//react redux firebase
// import { isLoaded } from "react-redux-firebase";

//interface
// import { IProducts } from "../ts/interfaces/product";

//get image
// import GetImageFromFirebase from "../components/GetImageFromFirebase";

import moment from "moment";

import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { FEEDBACK } from "../../../constants/feedback";

import { AssessProduct } from "../../../store/actions/products/assessProduct";

// const GreenCheckbox = withStyles({
// 	root: {
// 		color: green[100],
// 		"&$checked": {
// 			color: green[600],
// 		},
// 	},
// 	checked: {},
// })((props: CheckboxProps) => (
// 	<Field component={Checkbox} type="checbox" color="default" {...props} />
// ));
interface Props {
	data: any;
	handleCloseModal: any;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		product_image_preview: {
			width: 300,
			height: 300,
			border: "2px gray solid",
			marginBottom: 20,
			"& img": {
				width: 294,
				height: 294,
			},
		},
		empty_product_image_preview: {
			width: 300,
			height: 300,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "2px gray solid",
			marginBottom: 20,
		},
	})
);

const ReviewProductModal = ({ data, handleCloseModal }: Props) => {
	const styles = useStyles();
	const dispatch = useDispatch();

	const [openProductNameNo, setOpenProductNameNo] = useState(false);
	const [openProductShortNo, setOpenProductShortNo] = useState(false);
	const [openProductFullNo, setOpenProductFullNo] = useState(false);
	const [openProductGeneralDataNo, setOpenProductGeneralDataNo] = useState(
		false
	);

	const [
		openProductAttributesDataNo,
		setOpenProductAttributesDataNo,
	] = useState(false);
	const [openProductImagesDataNo, setOpenProductImagesDataNo] = useState(false);

	// const content = {
	// 	entityMap: {},
	// 	blocks: [
	// 		{
	// 			key: "637gr",
	// 			text: "Product full description.",
	// 			type: "unstyled",
	// 			depth: 0,
	// 			inlineStyleRanges: [],
	// 			entityRanges: [],
	// 			data: {},
	// 		},
	// 	],
	// };
	// let contentState = convertFromRaw(content);
	// const [productDescription, setProductDescription] = useState({
	// 	editorState: EditorState.createWithContent(contentState),
	// });

	let contentState2 = convertFromRaw(JSON.parse(data.product_full_description));

	// const [productDescription, setProductDescription] = useState({
	// 	editorState: EditorState.createWithContent(contentState2),
	// });

	const profile = useSelector((state: any) => state.firebase.profile);
	const authuid = useSelector((state: any) => state.firebase.auth.uid);
	const product: any = data;

	// useEffect(() => {
	// 	if (data !== {}) {
	// 		let contentState2 = convertFromRaw(
	// 			JSON.parse(data.product_full_description)
	// 		);

	// 		setProductDescription({
	// 			editorState: EditorState.createWithContent(contentState2),
	// 		});
	// 	}
	// }, [data]);

	// if (!isLoaded(profile)) return <LoadingScreen />;
	// if (!isLoaded(authuid)) return <LoadingScreen />;
	// if (!isLoaded(productFirestore)) return <LoadingScreen />;

	return (
		<Fragment>
			<br />
			<Container>
				<Formik
					initialValues={{
						isProductNameClear: {
							status: false,
							reason: "",
							touched: false,
						},
						isProductShortDescriptionClear: {
							status: false,
							reason: "",
							touched: false,
						},
						isProductFullDescriptionClear: {
							status: false,
							reason: "",
							touched: false,
						},
						isProductGeneralDataClear: {
							status: false,
							reason: "",
							touched: false,
						},
						isProductAttributesDataClear: {
							status: false,
							reason: "",
							touched: false,
						},
						isProductImagesDataClear: {
							status: false,
							reason: "",
							touched: false,
						},
						enable_automated_add_product: false,
						entity: product.entity,
						added_by: product.added_by,
						product_name: product.product_name,
						product_id: product.product_id,
						examined_by_username: profile.username,
						examined_by_id: authuid,
						product_data: product,
					}}
					onSubmit={(values, { setSubmitting }) => {
						setTimeout(() => {
							setSubmitting(false);
							if (!values.isProductNameClear.touched) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Product name not examined yet!",
										body: "You have not assessed the product name",
									},
								});
							} else if (
								values.isProductNameClear.touched &&
								!values.isProductNameClear.status &&
								values.isProductNameClear.reason === ""
							) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Reason required for product name",
										body:
											"You have assessed the product name but did not choose a reason!",
									},
								});
							} else if (!values.isProductShortDescriptionClear.touched) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Product short description not examined yet!",
										body: "You have not assessed the short description",
									},
								});
							} else if (
								values.isProductShortDescriptionClear.touched &&
								!values.isProductShortDescriptionClear.status &&
								values.isProductShortDescriptionClear.reason === ""
							) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Reason required for product short description",
										body:
											"You have assessed the product short description but did not choose a reason!",
									},
								});
							} else if (!values.isProductFullDescriptionClear.touched) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Product full description not examined yet!",
										body: "You have not assessed the full description",
									},
								});
							} else if (
								values.isProductFullDescriptionClear.touched &&
								!values.isProductFullDescriptionClear.status &&
								values.isProductFullDescriptionClear.reason === ""
							) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Reason required for product full description",
										body:
											"You have assessed the product full description but did not choose a reason!",
									},
								});
							} else if (!values.isProductGeneralDataClear.touched) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Product general data not examined yet!",
										body: "You have not assessed the product general data",
									},
								});
							} else if (
								values.isProductGeneralDataClear.touched &&
								!values.isProductGeneralDataClear.status &&
								values.isProductGeneralDataClear.reason === ""
							) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Reason required for product general data",
										body:
											"You have assessed the product general data but did not choose a reason!",
									},
								});
							} else if (!values.isProductGeneralDataClear.touched) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Product general data not examined yet!",
										body: "You have not assessed the product general data",
									},
								});
							} else if (
								values.isProductGeneralDataClear.touched &&
								!values.isProductGeneralDataClear.status &&
								values.isProductGeneralDataClear.reason === ""
							) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Reason required for product general data",
										body:
											"You have assessed the product general data but did not choose a reason!",
									},
								});
							} else if (!values.isProductAttributesDataClear.touched) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Product attributes data not examined yet!",
										body: "You have not assessed the product attributes data",
									},
								});
							} else if (
								values.isProductAttributesDataClear.touched &&
								!values.isProductAttributesDataClear.status &&
								values.isProductAttributesDataClear.reason === ""
							) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Reason required for product attribute data",
										body:
											"You have assessed the product attribute data but did not choose a reason!",
									},
								});
							} else if (!values.isProductImagesDataClear.touched) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Product images data not examined yet!",
										body: "You have not assessed the product images data",
									},
								});
							} else if (
								values.isProductImagesDataClear.touched &&
								!values.isProductImagesDataClear.status &&
								values.isProductImagesDataClear.reason === ""
							) {
								dispatch({
									type: FEEDBACK,
									payload: {
										severity: "warning",
										title: "Reason required for product image data",
										body:
											"You have assessed the product image data but did not choose a reason!",
									},
								});
							} else {
								dispatch(AssessProduct(values));
								handleCloseModal();
							}

							// alert(JSON.stringify(values));
						}, 1000);
					}}
				>
					{({ submitForm, isSubmitting, values, setFieldValue, touched }) => (
						<Form>
							<Grid container direction="column" spacing={2}>
								<Grid item xs={12} lg={12}>
									<Card>
										<CardContent>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={8}>
													<Typography variant="caption">
														Are you satisfied with the Product name?{" "}
														<span style={{ color: "red" }}>
															{!values.isProductNameClear.status &&
																values.isProductNameClear.reason !== "" &&
																"  No," + values.isProductNameClear.reason}
														</span>{" "}
														<span style={{ color: "green" }}>
															{values.isProductNameClear.status &&
																"  Yes , all good!"}
														</span>{" "}
														<span style={{ color: "orange" }}>
															{!values.isProductNameClear.touched &&
																"   Waiting for your assement!"}
														</span>
													</Typography>
													<Typography variant="body2">
														{product.product_name}
													</Typography>
												</Grid>
												<Grid item xs={12} lg={2}></Grid>
												<Grid item xs={12} lg={2}>
													<Grid container justify="center" alignItems="center">
														<Grid item xs={6} lg={6}>
															<Button
																variant={
																	values.isProductNameClear.status
																		? "contained"
																		: "text"
																}
																onClick={() => {
																	setOpenProductNameNo(false);
																	setFieldValue(
																		"isProductNameClear.status",
																		true,
																		false
																	);
																	setFieldValue(
																		"isProductNameClear.reason",
																		"",
																		false
																	);
																	setFieldValue(
																		"isProductNameClear.touched",
																		true,
																		false
																	);
																}}
															>
																Yes
															</Button>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Button
																onClick={() => {
																	setOpenProductNameNo(!openProductNameNo);
																	setFieldValue(
																		"isProductNameClear.status",
																		false,
																		false
																	);
																	setFieldValue(
																		"isProductNameClear.touched",
																		true,
																		false
																	);
																}}
																variant={
																	!values.isProductNameClear.status &&
																	touched.isProductNameClear?.reason
																		? "contained"
																		: "text"
																}
															>
																No
															</Button>
														</Grid>
													</Grid>
													<Grid container>
														<Grid item xs={12} lg={12}>
															<Collapse
																in={openProductNameNo}
																timeout="auto"
																unmountOnExit
															>
																<FormControl fullWidth>
																	<InputLabel htmlFor="product-name-denied-reason">
																		Reason?
																	</InputLabel>
																	<Field
																		component={Select}
																		name="isProductNameClear.reason"
																		inputProps={{
																			id: "product-name-denied-reason",
																		}}
																		style={{ width: "100%", paddingLeft: 20 }}
																	>
																		<MenuItem value="Product name too short">
																			Product name too short
																		</MenuItem>
																		<MenuItem value="Product name too long">
																			Product name too long
																		</MenuItem>
																		<MenuItem value="Product name contains inappropriate words">
																			Product name contains inappropriate words
																		</MenuItem>
																		<MenuItem value="Product name is not clear enough">
																			Product name is not clear enough
																		</MenuItem>
																	</Field>
																</FormControl>
															</Collapse>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} lg={12}>
									<Card>
										<CardContent>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={8}>
													<Typography variant="caption">
														Are you satisfied with the Product short
														description?{" "}
														<span style={{ color: "red" }}>
															{!values.isProductShortDescriptionClear.status &&
																values.isProductShortDescriptionClear.reason !==
																	"" &&
																"  No," +
																	values.isProductShortDescriptionClear.reason}
														</span>{" "}
														<span style={{ color: "green" }}>
															{values.isProductShortDescriptionClear.status &&
																"  Yes , all good!"}
														</span>{" "}
														<span style={{ color: "orange" }}>
															{!values.isProductShortDescriptionClear.touched &&
																"   Waiting for your assement!"}
														</span>
													</Typography>
													<Typography variant="body2">
														{product.product_short_description}
													</Typography>
												</Grid>
												<Grid item xs={12} lg={2}></Grid>
												<Grid item xs={12} lg={2}>
													<Grid container justify="center" alignItems="center">
														<Grid item xs={6} lg={6}>
															<Button
																variant={
																	values.isProductShortDescriptionClear.status
																		? "contained"
																		: "text"
																}
																onClick={() => {
																	setOpenProductShortNo(false);
																	setFieldValue(
																		"isProductShortDescriptionClear.status",
																		true,
																		false
																	);
																	setFieldValue(
																		"isProductShortDescriptionClear.reason",
																		"",
																		false
																	);
																	setFieldValue(
																		"isProductShortDescriptionClear.touched",
																		true,
																		false
																	);
																}}
															>
																Yes
															</Button>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Button
																onClick={() => {
																	setOpenProductShortNo(!openProductShortNo);
																	setFieldValue(
																		"isProductShortDescriptionClear.status",
																		false,
																		false
																	);
																	setFieldValue(
																		"isProductShortDescriptionClear.touched",
																		true,
																		false
																	);
																}}
																variant={
																	!values.isProductShortDescriptionClear
																		.status &&
																	touched.isProductShortDescriptionClear?.reason
																		? "contained"
																		: "text"
																}
															>
																No
															</Button>
														</Grid>
													</Grid>
													<Grid container>
														<Grid item xs={12} lg={12}>
															<Collapse
																in={openProductShortNo}
																timeout="auto"
																unmountOnExit
															>
																<FormControl fullWidth>
																	<InputLabel htmlFor="product-short-description-denied-reason">
																		Reason?
																	</InputLabel>
																	<Field
																		component={Select}
																		name="isProductShortDescriptionClear.reason"
																		inputProps={{
																			id:
																				"product-short-description-denied-reason",
																		}}
																		style={{ width: "100%", paddingLeft: 20 }}
																	>
																		<MenuItem value="Product short description too short">
																			Product short description too short
																		</MenuItem>
																		<MenuItem value="Product short description too long">
																			Product short description too long
																		</MenuItem>
																		<MenuItem value="Product short description contains inappropriate words">
																			Product short description contains
																			inappropriate words
																		</MenuItem>
																		<MenuItem value="Product short description is not clear enough">
																			Product short description is not clear
																			enough
																		</MenuItem>
																	</Field>
																</FormControl>
															</Collapse>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} lg={12}>
									<Card>
										<CardContent>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={8}>
													<Typography variant="caption">
														Are you satisfied with the Product full description?{" "}
														<span style={{ color: "red" }}>
															{!values.isProductFullDescriptionClear.status &&
																values.isProductFullDescriptionClear.reason !==
																	"" &&
																"  No," +
																	values.isProductFullDescriptionClear.reason}
														</span>{" "}
														<span style={{ color: "green" }}>
															{values.isProductFullDescriptionClear.status &&
																"  Yes , all good!"}
														</span>{" "}
														<span style={{ color: "orange" }}>
															{!values.isProductFullDescriptionClear.touched &&
																"   Waiting for your assement!"}
														</span>
													</Typography>
													<Editor
														editorState={EditorState.createWithContent(
															contentState2
														)}
														readOnly={true}
														toolbarHidden
													/>
												</Grid>
												<Grid item xs={12} lg={2}></Grid>
												<Grid item xs={12} lg={2}>
													<Grid container justify="center" alignItems="center">
														<Grid item xs={6} lg={6}>
															<Button
																variant={
																	values.isProductFullDescriptionClear.status
																		? "contained"
																		: "text"
																}
																onClick={() => {
																	setOpenProductFullNo(false);
																	setFieldValue(
																		"isProductFullDescriptionClear.status",
																		true,
																		false
																	);
																	setFieldValue(
																		"isProductFullDescriptionClear.reason",
																		"",
																		false
																	);
																	setFieldValue(
																		"isProductFullDescriptionClear.touched",
																		true,
																		false
																	);
																}}
															>
																Yes
															</Button>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Button
																onClick={() => {
																	setOpenProductFullNo(!openProductFullNo);
																	setFieldValue(
																		"isProductFullDescriptionClear.status",
																		false,
																		false
																	);
																	setFieldValue(
																		"isProductFullDescriptionClear.touched",
																		true,
																		false
																	);
																}}
																variant={
																	!values.isProductFullDescriptionClear
																		.status &&
																	touched.isProductFullDescriptionClear?.reason
																		? "contained"
																		: "text"
																}
															>
																No
															</Button>
														</Grid>
													</Grid>
													<Grid container>
														<Grid item xs={12} lg={12}>
															<Collapse
																in={openProductFullNo}
																timeout="auto"
																unmountOnExit
															>
																<FormControl fullWidth>
																	<InputLabel htmlFor="product-full-description-denied-reason">
																		Reason?
																	</InputLabel>
																	<Field
																		component={Select}
																		name="isProductFullDescriptionClear.reason"
																		inputProps={{
																			id:
																				"product-full-description-denied-reason",
																		}}
																		style={{ width: "100%", paddingLeft: 20 }}
																	>
																		<MenuItem value="Product full description too short">
																			Product full description too short
																		</MenuItem>
																		<MenuItem value="Product full description too long">
																			Product full description too long
																		</MenuItem>
																		<MenuItem value="Product full description contains inappropriate words">
																			Product full description contains
																			inappropriate words
																		</MenuItem>
																		<MenuItem value="Product full description is not clear enough">
																			Product full description is not clear
																			enough
																		</MenuItem>
																	</Field>
																</FormControl>
															</Collapse>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} lg={12}>
									<Card>
										<CardContent>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={8}>
													<Typography variant="caption">
														Are you satisfied with the Product general data?{" "}
														<span style={{ color: "red" }}>
															{!values.isProductGeneralDataClear.status &&
																values.isProductGeneralDataClear.reason !==
																	"" &&
																"  No," +
																	values.isProductGeneralDataClear.reason}
														</span>{" "}
														<span style={{ color: "green" }}>
															{values.isProductGeneralDataClear.status &&
																"  Yes , all good!"}
														</span>{" "}
														<span style={{ color: "orange" }}>
															{!values.isProductGeneralDataClear.touched &&
																"   Waiting for your assement!"}
														</span>
													</Typography>
													<Typography variant="subtitle1">
														{" "}
														Asking price
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														<NumberFormat
															value={product.asking_price}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"₦"}
														/>
													</Typography>
													<Divider />
													<Typography variant="subtitle1">
														{" "}
														Regulated price
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														<NumberFormat
															value={product.regular_price}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"₦"}
														/>
													</Typography>
													<Divider />
													<Typography variant="subtitle1">
														{" "}
														Enable discount price ?
													</Typography>
													{product.enable_discount === false && (
														<>
															<Typography variant="body2" gutterBottom>
																no
															</Typography>
															<Divider />
														</>
													)}
													{product.enable_discount === true && (
														<>
															<Typography variant="subtitle1">
																{" "}
																Disount price
															</Typography>
															<Typography variant="body2" gutterBottom>
																{" "}
																<NumberFormat
																	value={product.discount_price}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"₦"}
																/>
															</Typography>

															<Typography variant="subtitle1">
																{" "}
																Disount percent
															</Typography>
															<Typography variant="body2" gutterBottom>
																{" "}
																- {product.discount_percent}
															</Typography>
															<Divider />
														</>
													)}

													<Typography variant="subtitle1">
														{" "}
														Enable discount price schedule?
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{!product.enable_discount_schedule && "No"}{" "}
														{product.enable_discount_schedule &&
															`Yes ,From - ${moment(
																product.discount_price_from_date
															).format("MM/DD/YYYY")} to - ${moment(
																product.discount_price_to_date
															).format("MM/DD/YYYY")}`}
													</Typography>
													<Divider />
													<Typography variant="subtitle1"> Category</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_category}{" "}
													</Typography>
												</Grid>
												<Grid item xs={12} lg={2}></Grid>
												<Grid item xs={12} lg={2}>
													<Grid container justify="center" alignItems="center">
														<Grid item xs={6} lg={6}>
															<Button
																variant={
																	values.isProductGeneralDataClear.status
																		? "contained"
																		: "text"
																}
																onClick={() => {
																	setOpenProductGeneralDataNo(false);
																	setFieldValue(
																		"isProductGeneralDataClear.status",
																		true,
																		false
																	);
																	setFieldValue(
																		"isProductGeneralDataClear.reason",
																		"",
																		false
																	);
																	setFieldValue(
																		"isProductGeneralDataClear.touched",
																		true,
																		false
																	);
																}}
															>
																Yes
															</Button>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Button
																onClick={() => {
																	setOpenProductGeneralDataNo(
																		!openProductGeneralDataNo
																	);
																	setFieldValue(
																		"isProductGeneralDataClear.status",
																		false,
																		false
																	);
																	setFieldValue(
																		"isProductGeneralDataClear.touched",
																		true,
																		false
																	);
																}}
																variant={
																	!values.isProductGeneralDataClear.status &&
																	touched.isProductGeneralDataClear?.reason
																		? "contained"
																		: "text"
																}
															>
																No
															</Button>
														</Grid>
													</Grid>
													<Grid container>
														<Grid item xs={12} lg={12}>
															<Collapse
																in={openProductGeneralDataNo}
																timeout="auto"
																unmountOnExit
															>
																<FormControl fullWidth>
																	<InputLabel htmlFor="product-general-data-denied-reason">
																		Reason?
																	</InputLabel>
																	<Field
																		component={Select}
																		name="isProductGeneralDataClear.reason"
																		inputProps={{
																			id: "product-general-data-denied-reason",
																		}}
																		style={{ width: "100%", paddingLeft: 20 }}
																	>
																		<MenuItem value="Product price is overinflated">
																			Product price is overinflated
																		</MenuItem>
																		<MenuItem value="Product discount price is not reasonable">
																			Product discount price is not reasonable
																		</MenuItem>
																		<MenuItem value="Product category does not match the product data">
																			Product category does not match the
																			product data
																		</MenuItem>
																	</Field>
																</FormControl>
															</Collapse>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} lg={12}>
									<Card>
										<CardContent>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={8}>
													<Typography variant="caption">
														Are you satisfied with the Product attributes data?{" "}
														<span style={{ color: "red" }}>
															{!values.isProductAttributesDataClear.status &&
																values.isProductAttributesDataClear.reason !==
																	"" &&
																"  No," +
																	values.isProductAttributesDataClear.reason}
														</span>{" "}
														<span style={{ color: "green" }}>
															{values.isProductAttributesDataClear.status &&
																"  Yes , all good!"}
														</span>{" "}
														<span style={{ color: "orange" }}>
															{!values.isProductAttributesDataClear.touched &&
																"   Waiting for your assement!"}
														</span>
													</Typography>
													<Typography variant="h6">
														{" "}
														Specification 1{" "}
													</Typography>
													<Typography variant="subtitle1">
														{" "}
														Specification 1 title
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_attribute_1_title !== ""
															? product.product_attribute_1_title
															: "empty"}
													</Typography>
													<Divider />
													<Typography variant="subtitle1">
														{" "}
														Specification 1 value
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_attribute_1_value !== ""
															? product.product_attribute_1_value
															: "empty"}
													</Typography>
													<Divider />
													<Typography variant="h6">
														{" "}
														Specification 2{" "}
													</Typography>
													<Typography variant="subtitle1">
														{" "}
														Specification 2 title
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_attribute_2_title !== ""
															? product.product_attribute_2_title
															: "empty"}
													</Typography>
													<Divider />
													<Typography variant="subtitle1">
														{" "}
														Specification 2 value
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_attribute_2_value !== ""
															? product.product_attribute_2_value
															: "empty"}
													</Typography>
													<Divider />
													<Typography variant="h6">
														{" "}
														Specification 3{" "}
													</Typography>
													<Typography variant="subtitle1">
														{" "}
														Specification 3 title
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_attribute_3_title !== ""
															? product.product_attribute_3_title
															: "empty"}
													</Typography>
													<Divider />
													<Typography variant="subtitle1">
														{" "}
														Specification 3 value
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_attribute_3_value !== ""
															? product.product_attribute_3_value
															: "empty"}
													</Typography>
													<Divider />
													<Typography variant="h6">
														{" "}
														Specification 4{" "}
													</Typography>
													<Typography variant="subtitle1">
														{" "}
														Specification 4 title
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_attribute_4_title !== ""
															? product.product_attribute_4_title
															: "empty"}
													</Typography>
													<Divider />
													<Typography variant="subtitle1">
														{" "}
														Specification 4 value
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_attribute_4_value !== ""
															? product.product_attribute_4_value
															: "empty"}
													</Typography>
													<Divider />
													<Typography variant="h6">
														{" "}
														Specification 5{" "}
													</Typography>
													<Typography variant="subtitle1">
														{" "}
														Specification 5 title
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_attribute_5_title !== ""
															? product.product_attribute_5_title
															: "empty"}
													</Typography>
													<Divider />
													<Typography variant="subtitle1">
														{" "}
														Specification 5 value
													</Typography>
													<Typography variant="body2" gutterBottom>
														{" "}
														{product.product_attribute_5_value !== ""
															? product.product_attribute_5_value
															: "empty"}
													</Typography>
													<Divider />
												</Grid>
												<Grid item xs={12} lg={2}></Grid>
												<Grid item xs={12} lg={2}>
													<Grid container justify="center" alignItems="center">
														<Grid item xs={6} lg={6}>
															<Button
																variant={
																	values.isProductAttributesDataClear.status
																		? "contained"
																		: "text"
																}
																onClick={() => {
																	setOpenProductAttributesDataNo(false);
																	setFieldValue(
																		"isProductAttributesDataClear.status",
																		true,
																		false
																	);
																	setFieldValue(
																		"isProductAttributesDataClear.reason",
																		"",
																		false
																	);
																	setFieldValue(
																		"isProductAttributesDataClear.touched",
																		true,
																		false
																	);
																}}
															>
																Yes
															</Button>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Button
																onClick={() => {
																	setOpenProductAttributesDataNo(
																		!openProductAttributesDataNo
																	);
																	setFieldValue(
																		"isProductAttributesDataClear.status",
																		false,
																		false
																	);
																	setFieldValue(
																		"isProductAttributesDataClear.touched",
																		true,
																		false
																	);
																}}
																variant={
																	!values.isProductAttributesDataClear.status &&
																	touched.isProductAttributesDataClear?.reason
																		? "contained"
																		: "text"
																}
															>
																No
															</Button>
														</Grid>
													</Grid>
													<Grid container>
														<Grid item xs={12} lg={12}>
															<Collapse
																in={openProductAttributesDataNo}
																timeout="auto"
																unmountOnExit
															>
																<FormControl fullWidth>
																	<InputLabel htmlFor="product-attribute-data-denied-reason">
																		Reason?
																	</InputLabel>
																	<Field
																		component={Select}
																		name="isProductAttributesDataClear.reason"
																		inputProps={{
																			id:
																				"product-attribute-data-denied-reason",
																		}}
																		style={{ width: "100%", paddingLeft: 20 }}
																	>
																		<ListSubheader>
																			Product specification 1
																		</ListSubheader>
																		<MenuItem value="Product specification 1 title is meant to be a single option ">
																			Product specification 1 title is meant to
																			be a single option{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 1 title is too long ">
																			Product specification 1 title is too long{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 1 title contains inappropriate words ">
																			Product specification 1 title contains
																			inappropriate words{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 1 title is not clear enough ">
																			Product specification 1 title is not clear
																			enough{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 1 value is not clear enough ">
																			Product specification 1 title is not clear
																			enough{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 1 value contains inappropriate words ">
																			Product specification 1 value contains
																			inappropriate words{" "}
																		</MenuItem>
																		<ListSubheader>
																			Product specification 2
																		</ListSubheader>
																		<MenuItem value="Product specification 2 title is meant to be a single option ">
																			Product specification 2 title is meant to
																			be a single option{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 2 title is too long ">
																			Product specification 2 title is too long{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 2 title contains inappropriate words ">
																			Product specification 2 title contains
																			inappropriate words{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 2 title is not clear enough ">
																			Product specification 2 title is not clear
																			enough{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 2 value is not clear enough ">
																			Product specification 2 title is not clear
																			enough{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 2 value contains inappropriate words ">
																			Product specification 2 value contains
																			inappropriate words{" "}
																		</MenuItem>
																		<ListSubheader>
																			Product specification 3
																		</ListSubheader>
																		<MenuItem value="Product specification 3 title is meant to be a single option ">
																			Product specification 3 title is meant to
																			be a single option{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 3 title is too long ">
																			Product specification 3 title is too long{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 3 title contains inappropriate words ">
																			Product specification 3 title contains
																			inappropriate words{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 3 title is not clear enough ">
																			Product specification 3 title is not clear
																			enough{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 3 value is not clear enough ">
																			Product specification 3 title is not clear
																			enough{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 3 value contains inappropriate words ">
																			Product specification 3 value contains
																			inappropriate words{" "}
																		</MenuItem>
																		<ListSubheader>
																			Product specification 4
																		</ListSubheader>
																		<MenuItem value="Product specification 4 title is meant to be a single option ">
																			Product specification 4 title is meant to
																			be a single option{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 4 title is too long ">
																			Product specification 4 title is too long{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 4 title contains inappropriate words ">
																			Product specification 4 title contains
																			inappropriate words{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 4 title is not clear enough ">
																			Product specification 4 title is not clear
																			enough{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 4 value is not clear enough ">
																			Product specification 4 title is not clear
																			enough{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 4 value contains inappropriate words ">
																			Product specification 4 value contains
																			inappropriate words{" "}
																		</MenuItem>
																		<ListSubheader>
																			Product specification 5
																		</ListSubheader>
																		<MenuItem value="Product specification 5 title is meant to be a single option ">
																			Product specification 5 title is meant to
																			be a single option{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 5 title is too long ">
																			Product specification 5 title is too long{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 5 title contains inappropriate words ">
																			Product specification 5 title contains
																			inappropriate words{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 5 title is not clear enough ">
																			Product specification 5 title is not clear
																			enough{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 5 value is not clear enough ">
																			Product specification 5 title is not clear
																			enough{" "}
																		</MenuItem>
																		<MenuItem value="Product specification 5 value contains inappropriate words ">
																			Product specification 5 value contains
																			inappropriate words{" "}
																		</MenuItem>
																	</Field>
																</FormControl>
															</Collapse>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} lg={12}>
									<Card>
										<CardContent>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={8}>
													<Typography variant="caption">
														Are you satisfied with the Product images?{" "}
														<span style={{ color: "red" }}>
															{!values.isProductImagesDataClear.status &&
																values.isProductImagesDataClear.reason !== "" &&
																"  No," +
																	values.isProductImagesDataClear.reason}
														</span>{" "}
														<span style={{ color: "green" }}>
															{values.isProductImagesDataClear.status &&
																"  Yes , all good!"}
														</span>{" "}
														<span style={{ color: "orange" }}>
															{!values.isProductImagesDataClear.touched &&
																"   Waiting for your assement!"}
														</span>
													</Typography>
													<Grid container>
														<Grid item xs={12} lg={6}>
															{product.image_1_url !== "" ? (
																<div className={styles.product_image_preview}>
																	<img
																		src={product.image_1_url}
																		alt="product  one"
																	/>
																</div>
															) : (
																<div
																	className={styles.empty_product_image_preview}
																>
																	<Typography variant="caption">
																		Image 1 empty
																	</Typography>
																</div>
															)}
														</Grid>
														<Grid item xs={12} lg={6}>
															{product.image_2_url !== "" ? (
																<div className={styles.product_image_preview}>
																	<img
																		src={product.image_2_url}
																		alt="prodcut  two"
																	/>
																</div>
															) : (
																<div
																	className={styles.empty_product_image_preview}
																>
																	<Typography variant="caption">
																		Image 2 empty
																	</Typography>
																</div>
															)}
														</Grid>
													</Grid>
													<Grid container>
														<Grid item xs={12} lg={6}>
															{product.image_3_url !== "" ? (
																<div className={styles.product_image_preview}>
																	<img
																		src={product.image_3_url}
																		alt="product  3"
																	/>
																</div>
															) : (
																<div
																	className={styles.empty_product_image_preview}
																>
																	<Typography variant="caption">
																		Image 3 empty
																	</Typography>
																</div>
															)}
														</Grid>
														<Grid item xs={12} lg={6}>
															{product.image_4_url !== "" ? (
																<div className={styles.product_image_preview}>
																	<img
																		src={product.image_1_url}
																		alt="product  4"
																	/>
																</div>
															) : (
																<div
																	className={styles.empty_product_image_preview}
																>
																	<Typography variant="caption">
																		Image 4 empty
																	</Typography>
																</div>
															)}
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={12} lg={2}></Grid>
												<Grid item xs={12} lg={2}>
													<Grid container justify="center" alignItems="center">
														<Grid item xs={6} lg={6}>
															<Button
																variant={
																	values.isProductImagesDataClear.status
																		? "contained"
																		: "text"
																}
																onClick={() => {
																	setOpenProductImagesDataNo(false);
																	setFieldValue(
																		"isProductImagesDataClear.status",
																		true,
																		false
																	);
																	setFieldValue(
																		"isProductImagesDataClear.reason",
																		"",
																		false
																	);
																	setFieldValue(
																		"isProductImagesDataClear.touched",
																		true,
																		false
																	);
																}}
															>
																Yes
															</Button>
														</Grid>
														<Grid item xs={6} lg={6}>
															<Button
																onClick={() => {
																	setOpenProductImagesDataNo(
																		!openProductImagesDataNo
																	);
																	setFieldValue(
																		"isProductImagesDataClear.status",
																		false,
																		false
																	);
																	setFieldValue(
																		"isProductImagesDataClear.touched",
																		true,
																		false
																	);
																}}
																variant={
																	!values.isProductImagesDataClear.status &&
																	touched.isProductImagesDataClear?.reason
																		? "contained"
																		: "text"
																}
															>
																No
															</Button>
														</Grid>
													</Grid>
													<Grid container>
														<Grid item xs={12} lg={12}>
															<Collapse
																in={openProductImagesDataNo}
																timeout="auto"
																unmountOnExit
															>
																<FormControl fullWidth>
																	<InputLabel htmlFor="product-image-data-denied-reason">
																		Reason?
																	</InputLabel>
																	<Field
																		component={Select}
																		name="isProductImagesDataClear.reason"
																		inputProps={{
																			id: "product-image-data-denied-reason",
																		}}
																		style={{ width: "100%", paddingLeft: 20 }}
																	>
																		<MenuItem value="Image is water marked">
																			Image is water marked
																		</MenuItem>
																		<MenuItem value="Image is blurred">
																			Image is blurred
																		</MenuItem>
																		<MenuItem value="Image was copied and not original">
																			Image was copied and not original
																		</MenuItem>
																		<MenuItem value="Image is not appropriate">
																			Image is not appropriate
																		</MenuItem>
																	</Field>
																</FormControl>
															</Collapse>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={12} lg={12}>
									{isSubmitting && <LinearProgress />}
									<Card>
										<CardContent>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={12}>
													{/* <FormControlLabel
														control={
															<GreenCheckbox
																checked={values.enable_automated_add_product}
																onChange={({ target: { checked } }) =>
																	setFieldValue(
																		"enable_automated_add_product",
																		checked,
																		false
																	)
																}
															/>
														}
														label="Allow products from this store to automatically show on the marketplace.?"
													/> */}
													<Typography variant="caption">
														{" "}
														Confirm that you have properly assessed this
														product.
													</Typography>
													<Button
														variant="contained"
														color="primary"
														disabled={isSubmitting}
														onClick={() => {
															submitForm();
														}}
													>
														Done
													</Button>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</Container>
		</Fragment>
	);
};

export default ReviewProductModal;
