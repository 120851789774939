// import {referenceNumber} from '../../../utils';
// import {LegalVetPayment , AddToWallet ,DeductFromWallet ,EkiojaLegalTransfer} from '../transactions';
    
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const FailedVetting = (id: string,store_id:string, values:any,legal_id:string,amount:number,wallet:string,vet_ref_id:string,ekioja_wallet:any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();


        const updateStoreVetStatus =  firebase.firestore().collection('Stores').doc(store_id).set({
            under_vetting_by_legal: false,
            pending_vet:false,
            vetted:false,
            failed_vet:true,
        },{merge:true});

        const updateVetDocument =firebase.firestore().collection('VetDocuments').doc(id).set({
            
            to_be_vetted:{
                status:false,
            },
            under_vetting:{
                status:false,
            },
            passed:{
                status:false,
            },
            failed:{
                status:true,
                on:firebase.firestore.FieldValue.serverTimestamp(),
                data:values
            },
            commission:amount
         },{merge:true})

        
// //record transaction

// const legalVetTransactionPromise = dispatch(LegalVetPayment(legal_id,referenceNumber(),amount,vet_ref_id));

// const ekiojalegaltransferTransactionPromise = dispatch(EkiojaLegalTransfer(legal_id,referenceNumber(),amount,vet_ref_id));

// //run transaction on Legal balance
// const addtolegalwalletpromise = dispatch(AddToWallet(wallet,amount));

// //run transaction on Ekioja balance
// const withdrawfromekiojanpromise = dispatch(DeductFromWallet(ekioja_wallet,amount));


// const allPromise = Promise.all([updateStoreVetStatus,updateVetDocument,legalVetTransactionPromise,addtolegalwalletpromise,withdrawfromekiojanpromise,ekiojalegaltransferTransactionPromise]);
const allPromise = Promise.all([updateStoreVetStatus,updateVetDocument]);

return allPromise.then(() => {
   // dispatch({ type: 'FEEDBACK', payload: { severity: 'success', title: 'Your have sucessfully passed this store', body: '' } })
       
   });
  




           

    }