import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const orderOutForDelivery = (values: any) => {
	return firebase
		.firestore()
		.collection("OrdersTimeline")
		.doc(values.id)
		.set(
			{
				order_placed: {
					current: false,
				},
				pending_confirmation: {
					current: false,
				},
				waiting_to_be_shipped: {
					current: false,
				},
				out_for_delivery: {
					status: true,
					current: true,
					date: firebase.firestore.FieldValue.serverTimestamp(),
				},
			},
			{ merge: true }
		);
};

const useOrderOutForDelivery = () => {
	return useMutation(orderOutForDelivery);
};

export default useOrderOutForDelivery;
