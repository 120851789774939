import React, { Fragment, useState, useEffect } from "react";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//material table
import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import PanToolOutlinedIcon from "@material-ui/icons/PanToolOutlined";
import ReactPlayer from "react-player";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

//material ui components
// import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import LiveTvIcon from "@material-ui/icons/LiveTv";
import RateReviewIcon from "@material-ui/icons/RateReview";
import EditIcon from "@material-ui/icons/Edit";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//utils
import { getStoriesCounts, getStories } from "../utils/index";

//navigation hook
import { useNavigate } from "../hooks/navigate";

//routes
import * as ROUTES from "../constants/routes";

//link
import { useParams } from "react-router-dom";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

import LoadingScreen from "../components/LoadingScreen";

import _ from "lodash";

import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      borderRadius: 20,
      width: "100%",
    },
    toolbar_wrapper: {
      // backgroundColor: '#303030',
      // backgroundColor: theme.palette.background.default,
    },
    gutter: {
      height: 30,
      width: "100%",
    },
    product_image_wrapper: {
      "& img": {
        width: 40,
        height: 40,
        border: "1px white solid",
      },
    },
    add_new_product_button: {
      marginTop: "5%",
      marginLeft: 30,
    },
    queries_wrapper: {
      width: "100%",
      padding: 10,
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    detail_panel_wrapper: {
      padding: 10,
    },
    story_title: {
      overflowWrap: "break-word",
    },
    brand_story_action_action: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    card_top_section: {
      width: "100%",
      height: 50,
      marginBottom: 10,
    },
    card_middle_section: {
      width: "100%",
      height: 80,
    },
    card_bottom_section: {
      width: "100%",
      height: 30,
    },
    video_loading_wrapper: {
      width: "100%",
      height: 300,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    added_high5_wrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    high5_wrapper: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "baseline",
    },
    card_wrapper: {
      height: 270,
    },
    page_header: {
      width: "100%",
      height: 100,
      backgroundColor: "#99ff99",
      paddingTop: 20,
      paddingLeft: 20,
    },
  })
);

const BrandStories = () => {
  const styles = useStyles();
  const { query } = useParams<any>();
  const { navigateToLink } = useNavigate();
  const [tableTitle, setTableTitle] = useState("");
  const [pageloading, setpageloading] = useState(true);
  const [brandStoryData, setBrandStoryData] = useState<any>([]);

  const [viewStoriesData, setViewStoriesData] = useState<any>({});
  const [openViewVideoModal, setOpenViewVideoModal] = useState(false);

  useFirestoreConnect([
    {
      collection: "BrandStories",
    },
  ]);

  const brandStories = useSelector(
    (state: any) => state.firestore.ordered.BrandStories
  );

  useEffect(() => {
    if (isLoaded(brandStories)) {
      const result = getStories(brandStories, query);

      switch (query) {
        case ROUTES.BRAND_STORIES_QUERIES.ALL_BRAND_STORIES:
          setBrandStoryData([...result]);
          setpageloading(false);
          setTableTitle("All Brand Stories");
          break;
        case ROUTES.BRAND_STORIES_QUERIES.ALL_LIVE_STORIES:
          setBrandStoryData([...result]);
          setpageloading(false);
          setTableTitle("All Live Stories");
          break;
        case ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_REVIEWED:
          setBrandStoryData([...result]);
          setpageloading(false);
          setTableTitle("All To Be Assess Stories");
          break;
        case ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_RESOLVED:
          setBrandStoryData([...result]);
          setpageloading(false);
          setTableTitle("All To Be Resolved Stories");
          break;

        default:
          break;
      }
    }
  }, [brandStories, query]);

  const handleViewStoryOpen = () => {
    setOpenViewVideoModal(true);
  };

  const handlesetViewStoryClose = () => {
    setOpenViewVideoModal(false);
  };

  if (!isLoaded(brandStories)) return <LoadingScreen />;

  return (
    <Fragment>
      <DashBoardLayout menuIndex={3}>
        <div className={styles.page_header}>
          <Typography variant="h4" color="primary">
            {" "}
            Brand Stories Management
          </Typography>
        </div>
        <br />
        <Dialog
          open={openViewVideoModal}
          onClose={handlesetViewStoryClose}
          aria-labelledby="view-story--dialog-title"
          aria-describedby="view-story-dialog-description"
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <Typography gutterBottom variant="subtitle2" align="center">
                  {viewStoriesData.title !== "" && viewStoriesData.title}
                </Typography>
                <Fragment>
                  <ReactPlayer
                    url={viewStoriesData.video}
                    playing={false}
                    controls={true}
                    width="100%"
                    height={400}
                    pip={true}
                  />
                </Fragment>
              </Grid>
              <Grid item xs={3}>
                <Card className={styles.card_wrapper}>
                  <CardContent>
                    <div className={styles.card_middle_section}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        display="inline"
                        className={styles.story_title}
                      >
                        {viewStoriesData.description !== "" &&
                          viewStoriesData.description}
                      </Typography>
                    </div>

                    <div className={styles.card_bottom_section}>
                      <div className={styles.added_high5_wrapper}>
                        <div className={styles.high5_wrapper}>
                          {" "}
                          <Typography variant="caption" color="textSecondary">
                            20.7K
                          </Typography>
                          <PanToolOutlinedIcon style={{ fontSize: 16 }} />
                        </div>
                        <Typography variant="caption" color="textSecondary">
                          uploaded{" "}
                          {typeof viewStoriesData.added_on !== "undefined" &&
                            moment(viewStoriesData.added_on.toDate())
                              .fromNow()
                              .toString()}
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlesetViewStoryClose} color="primary" autoFocus>
              close
            </Button>
          </DialogActions>
        </Dialog>
        <div className={styles.queries_wrapper}>
          <Chip
            label={`All Stories (${getStoriesCounts(
              brandStories,
              ROUTES.BRAND_STORIES_QUERIES.ALL_BRAND_STORIES
            )})`}
            variant="default"
            onClick={() =>
              navigateToLink.push(ROUTES.BRAND_STORIES, {
                query: ROUTES.BRAND_STORIES_QUERIES.ALL_BRAND_STORIES,
              })
            }
          />
          <Chip
            label={`All Live Stories (${getStoriesCounts(
              brandStories,
              ROUTES.BRAND_STORIES_QUERIES.ALL_LIVE_STORIES
            )})`}
            variant="default"
            onClick={() =>
              navigateToLink.push(ROUTES.BRAND_STORIES, {
                query: ROUTES.BRAND_STORIES_QUERIES.ALL_LIVE_STORIES,
              })
            }
          />
          <Chip
            label={`All To Be Assessed Stories (${getStoriesCounts(
              brandStories,
              ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_REVIEWED
            )})`}
            variant="default"
            onClick={() =>
              navigateToLink.push(ROUTES.BRAND_STORIES, {
                query: ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_REVIEWED,
              })
            }
          />
          <Chip
            label={`All To Be Resolved Stories (${getStoriesCounts(
              brandStories,
              ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_RESOLVED
            )})`}
            variant="default"
            onClick={() =>
              navigateToLink.push(ROUTES.BRAND_STORIES, {
                query: ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_RESOLVED,
              })
            }
          />
        </div>
        <Box className={styles.root} boxShadow={5}>
          <MaterialTable
            style={{ width: "100%" }}
            isLoading={pageloading}
            title={tableTitle}
            columns={[
              {
                title: "Title",
                field: "title",
                render: (rowData) => (
                  <Typography variant="caption">{rowData.title}</Typography>
                ),
              },
              {
                title: "Type ",
                field: "type",
                render: (rowData) => (
                  <Typography variant="caption">{rowData.type}</Typography>
                ),
              },
              {
                title: "Category",
                field: "category",
                render: (rowData) => (
                  <Typography variant="caption">{rowData.category}</Typography>
                ),
              },
              {
                title: "Shop ",
                field: "shop_name",
                render: (rowData) => (
                  <Typography variant="caption">{rowData.shop_name}</Typography>
                ),
              },
              {
                title: "Status ",
                field: "status",
                render: (rowData) => (
                  <Fragment>
                    {_.has(rowData.data, "live") && rowData.data.live && (
                      <Chip
                        icon={<LiveTvIcon />}
                        label="Live"
                        variant="default"
                      />
                    )}
                    {_.has(rowData.data, "to_be_reviewed") &&
                      rowData.data.to_be_reviewed && (
                        <Chip
                          icon={<RateReviewIcon />}
                          label="To be Assessed"
                          variant="default"
                        />
                      )}
                    {_.has(rowData.data, "to_be_resolved") &&
                      rowData.data.to_be_resolved && (
                        <Chip
                          icon={<EditIcon />}
                          label="To be resolved"
                          variant="default"
                        />
                      )}
                  </Fragment>
                ),
              },
              {
                title: "Added",
                field: "added",
                render: (rowData) => (
                  <Typography variant="caption">
                    {rowData.added} <br /> {rowData.createdon}{" "}
                  </Typography>
                ),
              },
              { title: "Created on", field: "createdon", hidden: true },
              { title: "Id", field: "id", hidden: true },
              { title: "data", field: "data", hidden: true },
              {
                title: "Action ",
                field: "action",
                render: (rowData) => (
                  <Fragment>
                    {_.has(rowData.data, "live") && rowData.data.live && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setViewStoriesData({ ...rowData.data });
                          handleViewStoryOpen();
                        }}
                      >
                        View
                      </Button>
                    )}
                    {_.has(rowData.data, "to_be_reviewed") &&
                      rowData.data.to_be_reviewed && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            navigateToLink.push(ROUTES.ASSESS_BRAND_STORY, {
                              id: rowData.data.id,
                            });
                          }}
                        >
                          Assess
                        </Button>
                      )}
                    {_.has(rowData.data, "to_be_resolved") &&
                      rowData.data.to_be_resolved && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            navigateToLink.push(ROUTES.REVIEW_BRAND_STORY, {
                              id: rowData.data.id,
                            });
                          }}
                        >
                          Review
                        </Button>
                      )}
                  </Fragment>
                ),
              },
            ]}
            data={brandStoryData}
            options={{
              search: true,
              actionsColumnIndex: -1,
            }}
          />
        </Box>
      </DashBoardLayout>
    </Fragment>
  );
};

export default BrandStories;
