import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
// import IconButton from "@material-ui/core/IconButton";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
import { Conversation } from "../../../ConservationList/ConservationItem";

const useStyles = makeStyles({
	root: {
		width: "100%",
		height: 70,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 10,
	},
	brand_info_wrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	brand_logo: { width: "2em", height: "2em" },
	brand_name: {
		fontSize: "1em",
		color: "#ccc",
		textTransform: "capitalize",
	},
	product_info_wrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	product_image: {
		width: "2em",
		height: "2em",
		borderRadius: 3,
	},
	product_name: {
		fontSize: "0.7em",
		fontWeight: "bold",
		textTransform: "capitalize",
	},
	action_wrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		width: "3em",
	},
});

interface Props {
	data: Partial<Conversation>;
}

const ChatHead = (props: Props) => {
	const styles = useStyles();

	// const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	// const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	// 	setAnchorEl(event.currentTarget);
	// };

	// const handleClose = () => {
	// 	setAnchorEl(null);
	// };

	return (
		<Paper className={styles.root}>
			<div className={styles.brand_info_wrapper}>
				{props.data.brand_logo ? (
					<Avatar
						className={styles.brand_logo}
						alt={props.data.brand_name}
						src={props.data.brand_logo}
					/>
				) : (
					<Avatar className={styles.brand_logo}>H</Avatar>
				)}

				<Typography className={styles.brand_name}>
					{props.data.brand_name}
				</Typography>
			</div>
			<div className={styles.product_info_wrapper}>
				<img
					className={styles.product_image}
					src={props.data.product_image}
					alt={props.data.product_name}
				/>

				<Typography className={styles.product_name}>
					{props.data.product_name}
				</Typography>
			</div>
			{/* <div className={styles.action_wrapper}>
				<IconButton
					aria-controls="chat-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id="chat-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={handleClose}>Report</MenuItem>
					<MenuItem onClick={handleClose}>My account</MenuItem>
					<MenuItem onClick={handleClose}>Logout</MenuItem>
				</Menu>
			</div> */}
		</Paper>
	);
};

export default ChatHead;
