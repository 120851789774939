

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const CancelOrder = (id:string,values:any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

    
        const cancelOrderPromise =   firebase.firestore().collection('OrdersTimeline').doc(id).set({
            order_placed:{
                
                current:false,
            },
            pending_confirmation:{
                
                current:false,
            },
            waiting_to_be_shipped:{
               
                current:false,
            },
            out_for_delivery:{
                
                current:false,
            },
            delivered:{
                current:false,
            },
            canceled:{
                status:true,
                current:true,
                reason:values.reason,
                persona:values.persona,
                persona_id:values.persona_id,
                persona_name: values.persona_name,
                customer_id: values.customer_id,
                date: firebase.firestore.FieldValue.serverTimestamp(),
            },

        },{merge:true});



        
    const walletDocRef = firebase
    .firestore()
    .collection('Wallets')
    .doc(values.customer_id)
    //run transaction on Ekioja balance
const transactionpromise = firebase
    .firestore()
    .runTransaction((transaction:any) => {
        return transaction.get(walletDocRef).then((transactionDoc:any) => {
            if (!transactionDoc.exists) {}

            let newIncome = transactionDoc.data().income + values.amount

            transaction.update(walletDocRef, {
                income: newIncome,
            })
        })
    })

const allPromise = Promise.all([cancelOrderPromise, transactionpromise])

return allPromise.then()

       
    }