import { AddToWallet ,DeductFromWallet  ,AdminProductStoryPayment } from '../transactions';
import {referenceNumber} from '../../../utils';

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const Assessbrandstory = (values:any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

            if( values.isBrandStoryNameClear.status &&
                values.isBrandStoryDescriptionClear.status &&
                values.isBrandStoryThumbnailClear.status &&
                values.isBrandStoryVideoClear.status ){

                   const assessbrandstoryPromise =   firebase.firestore().collection('BrandStories').doc(values.id).set({
                    ...values,
                    under_review:{
                        status:false
                    },
                    to_be_reviewed:{
                        status:false
                    },
                    to_be_resolved:{
                        status:false
                    },
                    pass:{
                        status:true
                    },
                    live:true,
                        added_on: firebase.firestore.FieldValue.serverTimestamp(),

                    },{merge:true});

                    //record transaction

                    const admintopupforproductstoryPromise  = dispatch(AdminProductStoryPayment(values.examined_by_id,referenceNumber(),values.admincut,values.id))
                    // const ekiojatransfertoadminPromise = dispatch(EkiojaProductStoryTransfer(values.examined_by_id,referenceNumber(),values.admincut,values.id))
                   
                    //run transaction on Legal balance
                    const addtoadminwalletpromise = dispatch(AddToWallet(values.adminwallet,values.admincut));

                     //run transaction on Ekioja balance
                        const withdrawfromekiojanpromise = dispatch(DeductFromWallet(values.ekiojawallet,values.admincut));

                    const allPromise = Promise.all([assessbrandstoryPromise,admintopupforproductstoryPromise,addtoadminwalletpromise,withdrawfromekiojanpromise]);

                    allPromise.then(()=>{
                        dispatch({ type: 'FEEDBACK', payload: { severity: 'success', title: 'Story assessement was successful', body: "" } })
                    }).catch((error:any)=>{
                        dispatch({ type: 'FEEDBACK', payload: { severity: 'error', title: 'An error occured', body: "Oops! Your something went wrong! try again " } })

                        console.log(error);
                        
                    })
            }

            if( !values.isBrandStoryNameClear.status ||
                !values.isBrandStoryDescriptionClear.status ||
                !values.isBrandStoryThumbnailClear.status ||
                !values.isBrandStoryVideoClear.status ){

                    return firebase.firestore().collection('BrandStories').doc(values.id).set({
                    ...values,
                    under_review:{
                        status:false
                    },
                    to_be_reviewed:{
                        status:false
                    },
                    to_be_resolved:{
                        status:true
                    },
                    pass:{
                        status:false
                    },
                    live:false,
                        added_on: firebase.firestore.FieldValue.serverTimestamp(),

                    },{merge:true}).then(()=>{
                        dispatch({ type: 'FEEDBACK', payload: { severity: 'success', title: 'Story assessement was successful', body: "" } });
                        
                    }).catch((error:any)=>{
                        dispatch({ type: 'FEEDBACK', payload: { severity: 'error', title: 'An error occured', body: "Oops! Your something went wrong! try again " } })
                    })
            }

       

            
    }