

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';


export const TRANSACTION_TYPE = {
INCOME:'income',
ORDER:'order',
LOGISTICS:'logistics',
VENDOR_TOP_UP:'vendor-top-up',
VENDOR_HOLD:'vendor-hold',
VENDOR_REFUND_HOLD:'vendor-refund-hold',
LOGISTICS_TOP_UP:'logistics-top-up',
LEGAL_TOP_UP:'legal-top-up',
CUSTOMER_REFUND:'customer-refund',
CUSTOMER_CANCELLED_ORDER:'cancelled-order',
VENDOR_CANCELLED_ORDER:'vendor-cancelled-order',
LOGISTICS_CANCELLED_ORDER:'logistics-cancelled-order',
TRANSFER:'transfer',
ADMIN_PRODUCT_STORY_TOP_UP:'admin-product-story-top-up',
//withdraw
LEGAL_WITHDRAW:'legal-withdraw',
ADMIN_WITHDRAW:'admin-withdraw',
}

//(Vendor) Payment for signup and vetting
export const VendorSignupPayment = (store_id:string,reference:string,amount:number,admin_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Transactions').doc().set({
            by:store_id,
            to:'Ekioja',
            on:firebase.firestore.FieldValue.serverTimestamp(),
            reference,
            amount,
            withdraw:false,
            type:TRANSACTION_TYPE.INCOME,
            class:'paystack',
            admin_id,
        }).then(() => {
               
                
            });
    }

//(Vendor) Payment for Product story
export const VendorProductStoryPayment = (store_id:string,reference:string,amount:number,story_id:string,admin_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Transactions').doc().set({
            by:store_id,
            to:'Ekioja',
            on:firebase.firestore.FieldValue.serverTimestamp(),
            reference,
            amount,
            withdraw:false,
            type:TRANSACTION_TYPE.INCOME,
            class:'paystack',
            story_id,
            admin_id
        }).then(() => {
               
                
            });
    }


//(Vendor) Payment for Promote Product 
export const VendorPromoteProductPayment = (store_id:string,reference:string,amount:number,product_id:string,admin_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Transactions').doc().set({
            by:store_id,
            to:'Ekioja',
            on:firebase.firestore.FieldValue.serverTimestamp(),
            reference,
            amount,
            withdraw:false,
            type:TRANSACTION_TYPE.INCOME,
            class:'paystack',
            product_id,
            admin_id
        }).then(() => {
               
                
            });
    }


//(customer) Payment for order
export const CustomerOrderPayment = (customer_id:string,reference:string,amount:number,order_id:string,customer_email:string,): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Transactions').doc().set({
            by:customer_id,
            to:'Ekioja',
            on:firebase.firestore.FieldValue.serverTimestamp(),
            reference,
            amount,
            withdraw:false,
            type:TRANSACTION_TYPE.ORDER,
            class:'paystack',
            order_id,
            customer_email
        }).then(() => {
               
                
            });
    }


//(logistics) Pay logistics for delivery
export const LogisticsDeliveryPayment = (logistics_id:string,reference:string,amount:number,order_id:string,orderTimeline_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Transactions').doc().set({
            by:'Ekioja',
            to:logistics_id,
            on:firebase.firestore.FieldValue.serverTimestamp(),
            reference,
            amount,
            withdraw:false,
            type:TRANSACTION_TYPE.LOGISTICS_TOP_UP,
            class:'ekioja',
            order_id,
            orderTimeline_id
        }).then(() => {
               
                
            });
    }


//(vendor) Pay vendor after delivery
export const VendorSalePayment = (store_id:string,reference:string,amount:number,order_id:string,orderTimeline_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Transactions').doc().set({
            by:'Ekioja',
            to:store_id,
            on:firebase.firestore.FieldValue.serverTimestamp(),
            reference,
            amount,
            withdraw:false,
            type:TRANSACTION_TYPE.VENDOR_TOP_UP,
            class:'ekioja',
            order_id,
            orderTimeline_id
        }).then(() => {
               
                
            });
    }


//(vendor) hold vendor money 
export const VendorHoldFundPayment = (store_id:string,reference:string,amount:number,order_id:string,orderTimeline_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Transactions').doc().set({
            by:'Ekioja',
            to:store_id,
            on:firebase.firestore.FieldValue.serverTimestamp(),
            reference,
            amount,
            withdraw:false,
            type:TRANSACTION_TYPE.VENDOR_HOLD,
            class:'ekioja',
            order_id,
            orderTimeline_id
        }).then(() => {
               
                
            });
    }


    

//(customer) refund to customer 
export const CustomerRefundPayment = (customer_id:string,reference:string,amount:number,order_id:string,orderTimeline_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Transactions').doc().set({
            by:'Ekioja',
            to:customer_id,
            on:firebase.firestore.FieldValue.serverTimestamp(),
            reference,
            amount,
            withdraw:false,
            type:TRANSACTION_TYPE.CUSTOMER_REFUND,
            class:'ekioja',
            order_id,
            orderTimeline_id
        }).then(() => {
               
                
            });
    }


//(vendor) refund to vendor after verfication 
export const VendorRefundPayment = (vendor_id:string,reference:string,amount:number,order_id:string,orderTimeline_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Transactions').doc().set({
            by:'Ekioja',
            to:vendor_id,
            on:firebase.firestore.FieldValue.serverTimestamp(),
            reference,
            amount,
            withdraw:false,
            type:TRANSACTION_TYPE.VENDOR_REFUND_HOLD,
            class:'ekioja',
            order_id,
            orderTimeline_id
        }).then(() => {
               
                
            });
    }


    //(customer) customer canceled order
export const CustomerCancelOrder = (customer_id:string,reference:string,amount:number,order_id:string,orderTimeline_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
    const firebase = getFirebase();

    return firebase.firestore().collection('Transactions').doc().set({
        by:'Ekioja',
        to:customer_id,
        on:firebase.firestore.FieldValue.serverTimestamp(),
        reference,
        amount,
        withdraw:false,
        type:TRANSACTION_TYPE.CUSTOMER_CANCELLED_ORDER,
        class:'ekioja',
        order_id,
        orderTimeline_id
    }).then(() => {
           
            
        });
}


    //(vendor) customer canceled order
export const VendorCancelOrder = (vendor_id:string,reference:string,amount:number,order_id:string,orderTimeline_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
    const firebase = getFirebase();

    return firebase.firestore().collection('Transactions').doc().set({
        by:'Ekioja',
        to:vendor_id,
        on:firebase.firestore.FieldValue.serverTimestamp(),
        reference,
        amount,
        withdraw:false,
        type:TRANSACTION_TYPE.VENDOR_REFUND_HOLD,
        class:'ekioja',
        order_id,
        orderTimeline_id
    }).then(() => {
           
            
        });
}


    //(logistics) logistics canceled order
export const LogisticsCancelOrder = (logistics_id:string,reference:string,amount:number,order_id:string,orderTimeline_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
    const firebase = getFirebase();

    return firebase.firestore().collection('Transactions').doc().set({
        by:'Ekioja',
        to:logistics_id,
        on:firebase.firestore.FieldValue.serverTimestamp(),
        reference,
        amount,
        withdraw:false,
        type:TRANSACTION_TYPE.LOGISTICS_CANCELLED_ORDER,
        class:'ekioja',
        order_id,
        orderTimeline_id
    }).then(() => {
           
            
        });
}

    //(legal) legal pass or fail vett
export const LegalVetPayment = (legal_id:string,reference:string,amount:number,vet_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
    const firebase = getFirebase();

    return firebase.firestore().collection('Transactions').doc().set({
        by:'Ekioja',
        to:legal_id,
        on:firebase.firestore.FieldValue.serverTimestamp(),
        reference,
        amount,
        withdraw:false,
        type:TRANSACTION_TYPE.LEGAL_TOP_UP,
        class:'ekioja',
        vet_id,
    }).then(() => {
           
            
        });
}


    //(Admin) Admin asses product story
export const AdminProductStoryPayment = (admin_id:string,reference:string,amount:number,story_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
    const firebase = getFirebase();

    return firebase.firestore().collection('Transactions').doc().set({
        by:'Ekioja',
        to:admin_id,
        on:firebase.firestore.FieldValue.serverTimestamp(),
        reference,
        amount,
        withdraw:false,
        type:TRANSACTION_TYPE.ADMIN_PRODUCT_STORY_TOP_UP,
        class:'ekioja',
        story_id,
    }).then(() => {
           
            
        });
}


    //(Ekioja) ekioja transfer to  legal
export const EkiojaLegalTransfer = (legal_id:string,reference:string,amount:number,vet_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
    const firebase = getFirebase();

    return firebase.firestore().collection('Transactions').doc().set({
        by:'Ekioja',
        to:legal_id,
        on:firebase.firestore.FieldValue.serverTimestamp(),
        reference,
        amount,
        withdraw:false,
        type:TRANSACTION_TYPE.LEGAL_TOP_UP,
        class:'ekioja',
        vet_id,
    }).then(() => {
           
            
        });
}

//(Ekioja) ekioja transfet to admin for product story
export const EkiojaProductStoryTransfer = (admin_id:string,reference:string,amount:number,story_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Transactions').doc().set({
            by:'Ekioja',
            to:admin_id,
            on:firebase.firestore.FieldValue.serverTimestamp(),
            reference,
            amount,
            withdraw:false,
            type:TRANSACTION_TYPE.ADMIN_PRODUCT_STORY_TOP_UP,
            class:'ekioja',
            story_id,
        }).then(() => {
               
                
            });
    }


    //(Ekioja) ekioja transfet to admin for logistics
    export const EkiojaLogisticsTransfer = (admin_id:string,reference:string,amount:number,order_id:string,orderTimeline_id:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
        async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
            const firebase = getFirebase();
    
            return firebase.firestore().collection('Transactions').doc().set({
                by:'Ekioja',
                to:admin_id,
                on:firebase.firestore.FieldValue.serverTimestamp(),
                reference,
                amount,
                withdraw:false,
                type:TRANSACTION_TYPE.LOGISTICS_TOP_UP,
                class:'ekioja',
                order_id,
                orderTimeline_id
            }).then(() => {
                   
                    
                });
        }


    //Transaction to add to wallet
export const AddToWallet = (id:any,amount:number,): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
    const firebase = getFirebase();

    const walletDocRef = firebase.firestore().collection('Wallets').doc(id);

  return firebase.firestore().runTransaction((transaction:any)=>{
        return transaction.get(walletDocRef).then((transactionDoc:any)=>{
                if(!transactionDoc.exists){
                    
                }

                let newIncome = transactionDoc.data().income + amount;
                
                transaction.update(walletDocRef, { 
                   income:newIncome,
                 });

        })
   }).then(() => {
           
            
        });
}


    //Transaction to deduct from wallet
export const DeductFromWallet = (id:any,amount:number,): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
    const firebase = getFirebase();

    const walletDocRef = firebase.firestore().collection('Wallets').doc(id);

  return firebase.firestore().runTransaction((transaction:any)=>{
        return transaction.get(walletDocRef).then((transactionDoc:any)=>{
                if(!transactionDoc.exists){
                    
                }

                let newIncome = transactionDoc.data().income - amount;
                
                transaction.update(walletDocRef, { 
                   income:newIncome,
                 });

        })
   }).then(() => {
           
            
        });
}




