

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const RemoveAddress = (id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();
        try {


            firebase.firestore().collection('CustomerAddresses').doc(id).delete().then(() => {
                dispatch({ type: 'FEEDBACK', payload: { severity: 'success', title: 'Address Removed!', body: '' } })
            })

        } catch (error) {
            console.log(error.code);
            
        }

    }