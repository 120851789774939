import { useQuery, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";
import { LOGISTICS_DISPATCHER } from "../../constants/roles";
import { isToday } from "../../utils";
import moment from "moment";
import _ from "lodash";

const getLogisticsDispatcherList = async (key: any, entity: string) => {
	const response = await firebase
		.firestore()
		.collection("Admins")
		.where("entity", "==", entity)
		.where("logistics_role", "==", LOGISTICS_DISPATCHER)
		.get();
	let data: any = [];
	response.forEach((doc: any) => {
		data.push({ id: doc.id, ...doc.data() });
	});

	var start = new Date();
	start.setHours(0, 0, 0, 0);

	var end = new Date();
	end.setHours(23, 59, 59, 999);

	const orderTimeLineResponse = await firebase
		.firestore()
		.collection("OrdersTimeline")
		.where("logistics.entity", "==", entity)
		.get();

	let orderTimeLineData: any = [];
	orderTimeLineResponse.forEach((doc: any) => {
		orderTimeLineData.push({ id: doc.id, ...doc.data() });
	});

	// compare and assign dispatcher and order
	const result_dispatcher_and_ordertimeline = data.map((item: any) => {
		return {
			...item,
			orders: orderTimeLineData.filter(
				(orderitem: any) =>
					orderitem.logistics.uuid === item.id &&
					isToday(moment(orderitem.logistics.on.toDate()))
			),
			waiting_to_be_shipped_count: orderTimeLineData.filter(
				(orderitem: any) =>
					orderitem.logistics.uuid === item.id &&
					isToday(moment(orderitem.logistics.on.toDate())) &&
					_.has(orderitem, "waiting_to_be_shipped") &&
					orderitem.waiting_to_be_shipped.current === true
			).length,
			out_for_delivery_count: orderTimeLineData.filter(
				(orderitem: any) =>
					orderitem.logistics.uuid === item.id &&
					isToday(moment(orderitem.logistics.on.toDate())) &&
					_.has(orderitem, "out_for_delivery") &&
					orderitem.out_for_delivery.current === true
			).length,
			delivered_count: orderTimeLineData.filter(
				(orderitem: any) =>
					orderitem.logistics.uuid === item.id &&
					isToday(moment(orderitem.logistics.on.toDate())) &&
					_.has(orderitem, "delivered") &&
					orderitem.delivered.current === true
			).length,
			returned_count: orderTimeLineData.filter(
				(orderitem: any) =>
					orderitem.logistics.uuid === item.id &&
					isToday(moment(orderitem.logistics.on.toDate())) &&
					_.has(orderitem, "returned") &&
					orderitem.returned.current === true
			).length,
			canceled_count: orderTimeLineData.filter(
				(orderitem: any) =>
					orderitem.logistics.uuid === item.id &&
					isToday(moment(orderitem.logistics.on.toDate())) &&
					_.has(orderitem, "canceled") &&
					orderitem.canceled.current === true
			).length,
		};
	});
	return result_dispatcher_and_ordertimeline;
};

const useGetLogisticsDispatcherList = (entity: string) => {
	return useQuery(
		["allLogisticsDispatchers", entity],
		getLogisticsDispatcherList,
		{
			initialData: () => {
				if (queryCache.getQueryData("allLogisticsDispatchers")) {
					return queryCache.getQueryData("allLogisticsDispatchers");
				}
			},
			refetchOnWindowFocus: false,
		}
	);
};

export default useGetLogisticsDispatcherList;
