import React, { Fragment } from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LocalMallTwoToneIcon from "@material-ui/icons/LocalMallTwoTone";
import ThumbUpTwoToneIcon from "@material-ui/icons/ThumbUpTwoTone";
import RedeemTwoToneIcon from "@material-ui/icons/RedeemTwoTone";
import LocalShippingTwoToneIcon from "@material-ui/icons/LocalShippingTwoTone";
import PanToolTwoToneIcon from "@material-ui/icons/PanToolTwoTone";
import SentimentVerySatisfiedTwoToneIcon from "@material-ui/icons/SentimentVerySatisfiedTwoTone";
import moment from "moment";
import {
	OrderTimeline,
	Invoice,
	ShippingOption,
} from "../../../../../ConservationList/ConservationItem";

import useOrderWaitingToBeShipped from "../../../../../../../../hooks/chat/invoice/useOrderWaitingToBeShipped";
import useOutForDelivery from "../../../../../../../../hooks/chat/invoice/useOutForDelivery";
import useDelivered from "../../../../../../../../hooks/chat/invoice/useDelivered";
import useTransactionComplete from "../../../../../../../../hooks/chat/invoice/useTransactionComplete";

import { makeStyles, Theme } from "@material-ui/core/styles";
import CancelOrder from "./widgets/CancelOrder";

import Alert from "@material-ui/lab/Alert";

import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(2),
	},
	card_wrapper: {
		marginBottom: 10,
		marginTop: 10,
	},
	product_image: {
		width: "100%",
		minHeight: 120,
		"& img": {
			width: "100%",
			height: 120,
		},
		[theme.breakpoints.down("sm")]: {
			height: 240,
			"& img": {
				width: "100%",
				height: 240,
			},
		},
	},
	product_info_wrapper: {
		width: "100%",
		minHeight: 120,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-start",
		flexDirection: "column",
	},
	waiting_state: {
		backgroundColor: "#4287f5",
		padding: 5,
		textTransform: "uppercase",
		color: "white",
		borderRadius: 5,
		fontSize: 12,
	},
	done_state: {
		backgroundColor: "#8bf50a",
		padding: 5,
		textTransform: "uppercase",
		color: "white",
		borderRadius: 5,
		fontSize: 12,
	},
	wrapper: {
		width: "100%",
		height: 300,
	},
	order_list_wrapper: {
		maxWidth: "100%",
		height: 260,
		whiteSpace: "nowrap",
		WebkitOverflowScrolling: "touch",
		msOverflowStyle: "-ms-autohiding-scrollbar",
		overflowY: "hidden",
		overflowX: "auto",
		display: "inline-block",
		verticalAlign: "middle",
		padding: 10,
	},
	order_list_item_wrapper: {
		width: 250,
		height: 250,
		marginRight: 10,
		display: "inline-block",
	},

	order_list_item_info: {
		width: "100%",
		minHeight: 120,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		alignContent: "flex-end",
		flexDirection: "column",
	},
	media: {
		height: 140,
	},
	group_order_wrapper: {
		backgroundColor: "#cbffc2",
		marginTop: 10,
		marginBottom: 10,
	},
	appBar: {
		position: "relative",
		backgroundColor: "#fff",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	paper: {
		padding: "6px 16px",
	},
	secondaryTail: {
		backgroundColor: "green",
	},
	spacing: {
		marginBottom: 0,
	},
	connect_default: {
		height: 50,
	},
	connector_success: {
		height: 70,
		backgroundColor: "green",
	},
	connector_error: {
		height: 70,
		backgroundColor: "red",
	},
	dot_success: {
		backgroundColor: "green",
		color: "black",
	},
	dot_error: {
		backgroundColor: "red",
		color: "black",
	},
	action_wrapper: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		marginTop: 10,
	},
	timelineTitle: {
		fontSize: "0.7em",
		fontWeight: "bolder",
		textTransform: "capitalize",
	},
	timelineDate: {
		fontSize: "0.7em",
	},
}));

interface Props {
	data: Partial<OrderTimeline>;
	invoice: Partial<Invoice[]>;
	chatId: any;
	shippingOption: Partial<ShippingOption>;
	transactionCompleteData: {
		status: boolean;
		brand_id: string;
		amount: any;
	};
}
const OrderView = (props: Props) => {
	const styles = useStyles();
	const [
		OrderWaitingToBeShippedMutation,
		OrderWaitingToBeShippedInfo,
	] = useOrderWaitingToBeShipped();
	const [OutForDeliveryMutation, OutForDeliveryInfo] = useOutForDelivery();
	const [DeliveredMutation, DeliveredInfo] = useDelivered();
	const [
		TransactionCompleteMutate,
		TransactionCompleteStatus,
	] = useTransactionComplete();

	return (
		<div>
			{/* {(props.data.cancelled?.status === false ||
				props.data.out_for_delivery?.status === false ||
				props.data.delivered?.status === false) && (
				<CancelOrder invoice={props.invoice} />
			)} */}
			{(_.isUndefined(props.data.cancelled) ||
				_.isUndefined(props.data.out_for_delivery) ||
				_.isUndefined(props.data.delivered)) && (
				<CancelOrder invoice={props.invoice} />
			)}
			<Timeline align="alternate">
				{props.data.order_placed?.status && (
					<Fragment>
						<TimelineItem className={styles.spacing}>
							<TimelineOppositeContent>
								<Typography
									className={styles.timelineDate}
									color="textSecondary"
								>
									{moment(props.data.order_placed.date?.toDate()).format(
										"YYYY-MM-DD"
									)}
								</Typography>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineDot className={styles.dot_success}>
									<LocalMallTwoToneIcon />
								</TimelineDot>
								<TimelineConnector className={styles.connector_success} />
							</TimelineSeparator>
							<TimelineContent>
								<Paper elevation={3} className={styles.paper}>
									<Typography className={styles.timelineTitle} align="center">
										Order Placed
									</Typography>
								</Paper>
							</TimelineContent>
						</TimelineItem>
					</Fragment>
				)}
				{props.shippingOption.shipping_option === "door_delivery" &&
					props.data.pending?.status && (
						<Fragment>
							<TimelineItem className={styles.spacing}>
								<TimelineOppositeContent>
									<Typography
										className={styles.timelineDate}
										color="textSecondary"
									>
										{moment(props.data.pending.date?.toDate()).format(
											"YYYY-MM-DD"
										)}
									</Typography>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className={styles.dot_success}>
										<ThumbUpTwoToneIcon />
									</TimelineDot>
									<TimelineConnector className={styles.connector_success} />
								</TimelineSeparator>
								<TimelineContent>
									<Paper elevation={3} className={styles.paper}>
										<Typography className={styles.timelineTitle} align="center">
											Pending Confirmation
										</Typography>
										<br />
										{props.data.pending?.current && (
											<Button
												color="primary"
												size="small"
												variant="outlined"
												onClick={() => {
													if (window.confirm("Are you sure?")) {
														OrderWaitingToBeShippedMutation({
															chat_id: props.chatId,
														});
													}
												}}
												disabled={
													OrderWaitingToBeShippedInfo.isLoading ||
													OrderWaitingToBeShippedInfo.isSuccess
												}
											>
												{OrderWaitingToBeShippedInfo.isLoading
													? "Processing..."
													: OrderWaitingToBeShippedInfo.isError
													? "Error!"
													: OrderWaitingToBeShippedInfo.isSuccess
													? "Done!"
													: "confirm order processed"}
											</Button>
										)}
									</Paper>
								</TimelineContent>
							</TimelineItem>
						</Fragment>
					)}
				{props.shippingOption.shipping_option === "pickup_by_customer" &&
					props.data.pending?.status && (
						<Fragment>
							<TimelineItem className={styles.spacing}>
								<TimelineOppositeContent>
									<Typography
										className={styles.timelineDate}
										color="textSecondary"
									>
										{moment(props.data.pending.date?.toDate()).format(
											"YYYY-MM-DD"
										)}
									</Typography>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className={styles.dot_success}>
										<ThumbUpTwoToneIcon />
									</TimelineDot>
									<TimelineConnector className={styles.connector_success} />
								</TimelineSeparator>
								<TimelineContent>
									<Paper elevation={3} className={styles.paper}>
										<Typography className={styles.timelineTitle} align="center">
											Pending Pick up
										</Typography>
										<br />
										{props.data.pending?.current && (
											<Button
												variant="text"
												color="primary"
												onClick={() => {
													if (window.confirm("Are you sure?")) {
														DeliveredMutation({
															chat_id: props.chatId,
														});
													}
												}}
												disabled={
													DeliveredInfo.isLoading || DeliveredInfo.isSuccess
												}
											>
												{DeliveredInfo.isLoading
													? "Processing..."
													: DeliveredInfo.isError
													? "Error!"
													: DeliveredInfo.isSuccess
													? "Done!"
													: "confirm Item Pick up"}
											</Button>
										)}
									</Paper>
								</TimelineContent>
							</TimelineItem>
						</Fragment>
					)}
				{props.shippingOption.shipping_option === "door_delivery" &&
					props.data.waiting_to_be_shipped?.status && (
						<Fragment>
							<TimelineItem className={styles.spacing}>
								<TimelineOppositeContent>
									<Typography
										className={styles.timelineDate}
										color="textSecondary"
									>
										{moment(
											props.data.waiting_to_be_shipped.date?.toDate()
										).format("YYYY-MM-DD")}
									</Typography>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className={styles.dot_success}>
										<RedeemTwoToneIcon />
									</TimelineDot>
									<TimelineConnector className={styles.connector_success} />
								</TimelineSeparator>
								<TimelineContent>
									<Paper elevation={3} className={styles.paper}>
										<Typography className={styles.timelineTitle} align="center">
											Pending delivery
										</Typography>
										<Typography variant="caption">
											Item is processed and vendor is waiting for the delivery
											man.
										</Typography>
										<br />
										{props.data.waiting_to_be_shipped?.current && (
											<Button
												color="primary"
												size="small"
												variant="outlined"
												onClick={() => {
													if (window.confirm("Are you sure?")) {
														OutForDeliveryMutation({
															chat_id: props.chatId,
														});
													}
												}}
												disabled={
													OutForDeliveryInfo.isLoading ||
													OutForDeliveryInfo.isSuccess
												}
											>
												{OutForDeliveryInfo.isLoading
													? "Processing..."
													: OutForDeliveryInfo.isError
													? "Error!"
													: OutForDeliveryInfo.isSuccess
													? "Done!"
													: "confirm out for delivery"}
											</Button>
										)}
									</Paper>
								</TimelineContent>
							</TimelineItem>
						</Fragment>
					)}

				{props.shippingOption.shipping_option === "door_delivery" &&
					props.data.out_for_delivery?.status && (
						<Fragment>
							<TimelineItem className={styles.spacing}>
								<TimelineOppositeContent>
									<Typography
										className={styles.timelineDate}
										color="textSecondary"
									>
										{moment(props.data.out_for_delivery.date?.toDate()).format(
											"YYYY-MM-DD"
										)}
									</Typography>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className={styles.dot_success}>
										<LocalShippingTwoToneIcon />
									</TimelineDot>
									<TimelineConnector className={styles.connector_success} />
								</TimelineSeparator>
								<TimelineContent>
									<Paper elevation={3} className={styles.paper}>
										<Typography className={styles.timelineTitle} align="center">
											Out for delivery
										</Typography>
										<Typography variant="caption">
											The item is being delivered you
										</Typography>
										{props.data.out_for_delivery?.current && (
											<Button
												variant="text"
												color="primary"
												onClick={() => {
													if (window.confirm("Are you sure?")) {
														DeliveredMutation({
															chat_id: props.chatId,
														});
													}
												}}
												disabled={
													DeliveredInfo.isLoading || DeliveredInfo.isSuccess
												}
											>
												{DeliveredInfo.isLoading
													? "Processing..."
													: DeliveredInfo.isError
													? "Error!"
													: DeliveredInfo.isSuccess
													? "Done!"
													: "confirm order delivered"}
											</Button>
										)}
									</Paper>
								</TimelineContent>
							</TimelineItem>
						</Fragment>
					)}
				{props.shippingOption.shipping_option === "door_delivery" &&
					props.data.delivered?.status && (
						<Fragment>
							<TimelineItem className={styles.spacing}>
								<TimelineOppositeContent>
									<Typography
										className={styles.timelineDate}
										color="textSecondary"
									>
										{moment(props.data.delivered.date?.toDate()).format(
											"YYYY-MM-DD"
										)}
									</Typography>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className={styles.dot_success}>
										<SentimentVerySatisfiedTwoToneIcon />
									</TimelineDot>
								</TimelineSeparator>
								<TimelineContent>
									<Paper elevation={3} className={styles.paper}>
										<Typography className={styles.timelineTitle} align="center">
											Delivered
										</Typography>
										<Typography variant="caption">
											The items were delivered to you successfully
										</Typography>
									</Paper>
								</TimelineContent>
							</TimelineItem>
						</Fragment>
					)}
				{props.shippingOption.shipping_option === "pickup_by_customer" &&
					props.data.delivered?.status && (
						<Fragment>
							<TimelineItem className={styles.spacing}>
								<TimelineOppositeContent>
									<Typography
										className={styles.timelineDate}
										color="textSecondary"
									>
										{moment(props.data.delivered.date?.toDate()).format(
											"YYYY-MM-DD"
										)}
									</Typography>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className={styles.dot_success}>
										<SentimentVerySatisfiedTwoToneIcon />
									</TimelineDot>
								</TimelineSeparator>
								<TimelineContent>
									<Paper elevation={3} className={styles.paper}>
										<Typography className={styles.timelineTitle} align="center">
											Item Picked by customer
										</Typography>
										<Typography variant="caption">
											The item(s) has been picked
										</Typography>
									</Paper>
								</TimelineContent>
							</TimelineItem>
						</Fragment>
					)}
				{props.data.returned?.status && (
					<Fragment>
						<div className={styles.waiting_state}>RETURNED</div>
						<Typography variant="subtitle2" color="textPrimary">
							{moment(props.data.returned.date?.toDate()).format("YYYY-MM-DD")}
						</Typography>
					</Fragment>
				)}
				{props.data.cancelled?.status && (
					<Fragment>
						<Typography variant="subtitle2" color="textPrimary"></Typography>
						<TimelineItem className={styles.spacing}>
							<TimelineOppositeContent>
								<Typography
									className={styles.timelineDate}
									color="textSecondary"
								>
									{moment(props.data.cancelled.date?.toDate()).format(
										"YYYY-MM-DD"
									)}
								</Typography>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineDot className={styles.dot_error}>
									<PanToolTwoToneIcon />
								</TimelineDot>
							</TimelineSeparator>
							<TimelineContent>
								<Paper elevation={3} className={styles.paper}>
									<Typography className={styles.timelineTitle} align="center">
										Order was cancelled
									</Typography>
									<Typography variant="caption" color="textSecondary">
										{props.data.cancelled.persona === "customer" &&
											"Cancelled by You"}
										{props.data.cancelled.persona === "vendor" &&
											"Cancelled by Vendor"}
										{props.data.cancelled.persona === "logistics" &&
											"Cancelled by Logistics"}
									</Typography>
									<br />
									<Typography variant="caption" color="textSecondary">
										Reason :{props.data.cancelled.reason}
									</Typography>
								</Paper>
							</TimelineContent>
						</TimelineItem>
					</Fragment>
				)}
			</Timeline>

			{props.transactionCompleteData?.status && (
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Alert severity="success">Transaction Complete</Alert>
				</div>
			)}

			{props.data.delivered?.status &&
				props.transactionCompleteData?.status === false && (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							style={{ color: "#fff" }}
							onClick={() => {
								if (window.confirm("Are you sure?")) {
									TransactionCompleteMutate({
										chat_id: props.chatId,
										brand_id: props.transactionCompleteData?.brand_id,
										amount: props.transactionCompleteData?.amount,
									});
								}
							}}
						>
							{TransactionCompleteStatus.isLoading
								? "Processing..."
								: TransactionCompleteStatus.isError
								? "Error!"
								: TransactionCompleteStatus.isSuccess
								? "Done!"
								: "Transaction Complete?"}
						</Button>
					</div>
				)}
		</div>
	);
};

export default OrderView;
