import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const rejectAssignBooking = (id: string) => {
	return firebase
		.firestore()
		.collection("OrdersTimeline")
		.doc(id)
		.set(
			{
				booked: false,
				logistics: {
					uuid: "",
					name: "",
					entity: "",
					manager_uuid: "",
					dispatcher_email: "",
					dispatcher_phonenumber: "",
				},
			},
			{ merge: true }
		);
};

const useRejectAssignBooking = () => {
	return useMutation(rejectAssignBooking);
};

export default useRejectAssignBooking;
