import React from "react";
//route
import { Switch, Route } from "react-router-dom";
//import idleTimer
// import SessionIdleTimer from './components/SessionIdleTimer';

//routes
import * as ROUTES from "./constants/routes";

//pages
import SignUp from "./pages/Auth/Signup";
import AdminSignup from "./pages/Auth/AdminSignup";
import LogisticsAdminSignup from "./pages/Auth/Logistics/LogisticsAdminSignup";
import LogisticsPartnerNewAccount from "./pages/Auth/Logistics/LogisticsPartnerNewAccount";
import LogisticsPartnerAdminNewAccount from "./pages/Auth/Logistics/LogisticsPartnerAdminNewAccount";
import Login from "./pages/Auth/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Home from "./pages/Auth/Home";

import Dashboard from "./pages/Dashboard";
import VetDashboard from "./pages/VetDashboard";
import LogisticsDashboard from "./pages/LogisticsDashboard";
import Account from "./pages/Account";
import Users from "./pages/Users";
import Wallet from "./pages/Wallet";
import Stores from "./pages/Stores";
import StoreDetails from "./pages/StoreDetails";
import Categories from "./pages/Categories";
import Products from "./pages/Products";
import ReviewProduct from "./pages/ReviewProduct";
import ResolveProduct from "./pages/ResolveProduct";
import Orders from "./pages/Orders";
import InterestedVendorsPage from "./pages/InterestedVendorsPage";
import BrandStories from "./pages/BrandStories";
import AssessBrandStory from "./pages/AssessBrandStory";
import ReviewBrandStory from "./pages/ReviewBrandStory";
import PayoutRequests from "./pages/PayoutRequests";
import Chats from "./pages/Chats";

import ReviewingAccount from "./pages/Auth/ReviewingAccount";
import RedirectBridge from "./pages/Auth/RedirectBridge";

import MarketProductCategory from "./pages/MarketProductCategory";
import MarketProductCategoryList from "./pages/MarketProductCategoryList";
import MarketPlaceWishlist from "./pages/MarketPlaceWishlist";
import MarketPlaceCart from "./pages/MarketPlaceCart";
import MarketPLaceCheckout from "./pages/MarketPLaceCheckout";
import MarketPLaceOrders from "./pages/MarketPlaceOrders";
import MarketPlaceOrdersDetail from "./pages/MarketPlaceOrdersDetail";

import Vet from "./pages/Vet";
import VetWallet from "./pages/VetWallet";
import VetMaster from "./pages/VetMaster";
import VetLegalDetails from "./pages/VetLegalDetails";

import Logistics from "./pages/Logistics";
import LogisticsWallet from "./pages/LogisticsWallet";
import LogisticsAdminMgt from "./pages/LogisticsAdminMgt";
import LogisticsDispatcherDashboard from "./pages/LogisticsDispatcherDashboard";
import LogisticsEkiojaMaster from "./pages/LogisticsEkiojaMaster";

import CreativeDashboard from "./pages/CreativeDashboard";
import CreativeWallet from "./pages/CreativeWallet";
// import CreativeJob from "./pages/CreativeJob";
import CreateJobs from "./pages/CreateJobs";

import FinanceDashBoard from "./pages/FinanceDashBoard";
import FinanceWallet from "./pages/FinanceWallet";

import SearchBarTest from "./pages/SearchBarTest";

import ViewProductDetails from "./pages/ViewProductDetails";
import EditProduct from "./pages/EditProduct";

import EditStore from "./pages/EditStore";

import CreativesMaster from "./pages/CreativesMaster";

import KYCReports from "./pages/KYCReports";
import Addons from "./pages/Addons";
import Integrations from "./pages/Integration";

//components
import {
	UserIsAuthenticated,
	// UserIsNotAuthenticated,
	UserIsAuthenticatedAndIsAdmin,
} from "./components/Authentication";

const App = () => {
	return (
		<div>
			{/* <SessionIdleTimer /> */}

			<Switch>
				<Route exact path={ROUTES.LOGIN} component={Login} />

				<Route path={ROUTES.HOME} component={Home} />

				<Route path={ROUTES.EKIOJA_SUPER_ADMIN_SIGNUP} component={SignUp} />
				<Route
					path={ROUTES.CREATE_NEW_ADMIN}
					component={AdminSignup}
					// component={UserIsNotAuthenticated(AdminSignup)}
				/>
				<Route
					path={ROUTES.LOGISTICS_ADMINS_SIGN_UP}
					component={LogisticsAdminSignup}
					// component={UserIsNotAuthenticated(LogisticsAdminSignup)}
				/>
				<Route
					path={ROUTES.INVITE_LOGISTICS_PARTNER}
					component={LogisticsPartnerNewAccount}
					// component={UserIsNotAuthenticated(LogisticsAdminSignup)}
				/>
				<Route
					path={ROUTES.INVITE_LOGISTICS_PARTNER_ADMIN}
					component={LogisticsPartnerAdminNewAccount}
					// component={UserIsNotAuthenticated(LogisticsAdminSignup)}
				/>
				<Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
				<Route
					path={ROUTES.REVIEW_ADMIN_ACCOUNT}
					component={ReviewingAccount}
				/>
				<Route
					path={ROUTES.REDIRECT_BRIDGE_PAGE}
					component={UserIsAuthenticated(RedirectBridge)}
				/>

				<Route
					path={ROUTES.DASHBOARD}
					component={UserIsAuthenticated(Dashboard)}
				/>

				<Route
					path={ROUTES.KYCREPORTS}
					component={UserIsAuthenticated(KYCReports)}
				/>
				<Route path={ROUTES.ADDONS} component={UserIsAuthenticated(Addons)} />
				<Route
					path={ROUTES.VET_DASHBOARD}
					component={UserIsAuthenticated(VetDashboard)}
				/>
				<Route
					path={ROUTES.LOGISTICS_DASHBOARD}
					component={UserIsAuthenticated(LogisticsDashboard)}
				/>
				<Route
					path={ROUTES.FINANCE_DASHBOARD}
					component={UserIsAuthenticated(FinanceDashBoard)}
				/>
				<Route
					path={ROUTES.FINANCE_WALLET}
					component={UserIsAuthenticated(FinanceWallet)}
				/>
				<Route path={ROUTES.ACCOUNT} component={UserIsAuthenticated(Account)} />
				<Route
					path={ROUTES.ADMINS_MANAGEMENT}
					component={UserIsAuthenticatedAndIsAdmin(Users)}
				/>
				<Route
					path={ROUTES.LOGISTICS_EKIOJA_MASTER}
					component={UserIsAuthenticatedAndIsAdmin(LogisticsEkiojaMaster)}
				/>
				<Route
					path={ROUTES.WALLET}
					component={UserIsAuthenticatedAndIsAdmin(Wallet)}
				/>
				<Route
					path={ROUTES.PAYOUT_REQUESTS}
					component={UserIsAuthenticatedAndIsAdmin(PayoutRequests)}
				/>

				<Route
					path={ROUTES.VIEWALLSTORES}
					component={UserIsAuthenticated(Stores)}
				/>
				<Route
					path={ROUTES.VIEW_STORE_DETAILS}
					component={UserIsAuthenticated(StoreDetails)}
				/>

				<Route
					path={ROUTES.CATEGORIES}
					component={UserIsAuthenticated(Categories)}
				/>
				<Route
					path={ROUTES.VIEWPRODUCTS}
					component={UserIsAuthenticated(Products)}
				/>
				<Route
					path={ROUTES.REVIEW_PRODUCT}
					component={UserIsAuthenticated(ReviewProduct)}
				/>

				<Route
					path={ROUTES.VIEW_PRODUCT_DETAILS}
					component={UserIsAuthenticated(ViewProductDetails)}
				/>
				<Route
					path={ROUTES.EDIT_PRODUCT}
					component={UserIsAuthenticated(EditProduct)}
				/>

				<Route
					path={ROUTES.RESOLVE_PRODUCT}
					component={UserIsAuthenticated(ResolveProduct)}
				/>

				<Route
					path={ROUTES.VIEWORDERS}
					component={UserIsAuthenticated(Orders)}
				/>

				<Route
					path={ROUTES.INTERESTED_VENDORS}
					component={UserIsAuthenticated(InterestedVendorsPage)}
				/>

				<Route path={ROUTES.CHATS} component={UserIsAuthenticated(Chats)} />

				<Route
					path={ROUTES.MARKETPLACE_CATEGORIES}
					component={MarketProductCategory}
				/>
				<Route
					path={ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST}
					component={MarketProductCategoryList}
				/>
				<Route
					path={ROUTES.VIEW_MARKETPLACE_WISHLIST}
					component={MarketPlaceWishlist}
				/>
				<Route
					path={ROUTES.VIEW_MARKETPLACE_CART}
					component={MarketPlaceCart}
				/>
				<Route
					path={ROUTES.VIEW_MARKETPLACE_CHECKOUT}
					component={MarketPLaceCheckout}
				/>
				<Route
					path={ROUTES.VIEW_MARKETPLACE_ORDERS}
					component={MarketPLaceOrders}
				/>
				<Route
					path={ROUTES.VIEW_MARKETPLACE_ORDERS_DETAILS}
					component={MarketPlaceOrdersDetail}
				/>

				<Route path={ROUTES.VET} component={UserIsAuthenticated(Vet)} />

				<Route
					path={ROUTES.VET_WALLET}
					component={UserIsAuthenticated(VetWallet)}
				/>

				<Route
					path={ROUTES.VET_MASTER}
					component={UserIsAuthenticated(VetMaster)}
				/>

				<Route
					path={ROUTES.VET_LEGAL_LIST}
					component={UserIsAuthenticated(VetLegalDetails)}
				/>

				<Route
					path={ROUTES.LOGISTICS}
					component={UserIsAuthenticated(Logistics)}
				/>

				<Route
					path={ROUTES.LOGISTICS_WALLET}
					component={UserIsAuthenticated(LogisticsWallet)}
				/>
				<Route
					path={ROUTES.LOGISTICS_ADMINS_MANAGEMENT}
					component={UserIsAuthenticated(LogisticsAdminMgt)}
				/>
				<Route
					path={ROUTES.LOGISTICS_DISPATCHER_DASHBOARD}
					component={UserIsAuthenticated(LogisticsDispatcherDashboard)}
				/>

				<Route
					path={ROUTES.BRAND_STORIES}
					component={UserIsAuthenticated(BrandStories)}
				/>

				<Route
					path={ROUTES.ASSESS_BRAND_STORY}
					component={UserIsAuthenticated(AssessBrandStory)}
				/>

				<Route
					path={ROUTES.REVIEW_BRAND_STORY}
					component={UserIsAuthenticated(ReviewBrandStory)}
				/>
				<Route
					path={ROUTES.CREATIVES_MASTER}
					component={UserIsAuthenticated(CreativesMaster)}
				/>
				<Route
					path={ROUTES.CREATIVE_DASHBOARD}
					component={UserIsAuthenticated(CreativeDashboard)}
				/>
				<Route
					path={ROUTES.CREATIVE_WALLET}
					component={UserIsAuthenticated(CreativeWallet)}
				/>
				<Route
					path={ROUTES.CREATIVES_STORY_JOB}
					component={UserIsAuthenticated(CreateJobs)}
				/>
				<Route
					path={ROUTES.EDIT_STORE}
					component={UserIsAuthenticated(EditStore)}
				/>
				<Route
					path={ROUTES.INTEGRATIONS}
					component={UserIsAuthenticated(Integrations)}
				/>

				<Route path="/search-bar-test" component={SearchBarTest} />
			</Switch>
		</div>
	);
};

export default App;
