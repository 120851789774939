import React, { useState } from "react";
//components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Logo from "../../../assets/images/svg/ekioja-logo.svg";

import useCreateLogisticsAdminAccount from "../../../hooks/logistics/useCreateLogisticsAdminAccount";

//react router dom
import { useParams, Redirect } from "react-router-dom";

import { getBrowserProps } from "../../../utils/getBrowerProps";

import { LOGISTICS } from "../../../constants/roles";

import { useSelector } from "react-redux";

import * as ROUTES from "../../../constants/routes";

import { isLoaded } from "react-redux-firebase";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

//signup yup schema
const SignupValidationSchema = yup.object().shape({
	first_name: yup
		.string()
		.max(35, "its too long")
		.min(2, "its too short")
		.required("Your first name is Required"),
	last_name: yup
		.string()
		.max(35, "its too long")
		.min(2, "its too short")
		.required("Your last name is Required"),
	username: yup
		.string()
		.max(35, "its too long")
		.min(2, "its too short")
		.required("Your user name is Required"),
	email: yup.string().email().required("Your email is Required"),
	phone_number: yup.string().required("Your phone number is Required"),
	password: yup
		.string()
		.label("Password")
		.required("Your password is Required")
		.min(8, "Your password must not be less than 8 characters"),
	confirmpassword: yup
		.string()
		.required()
		.test("passwords-match", "Oops! Passwords do not match!", function (value) {
			return this.parent.password === value;
		}),
});

//styles functions
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "99vw",
			backgroundColor: "green",
			paddingTop: 20,
			paddingBottom: 60,
		},
		form_wrapper: {
			padding: 20,
			borderRadius: 20,
			width: "100%",
			height: "auto",
			backgroundColor: "white",
		},
	})
);

const LogisticsAdminSignup = () => {
	const styles = useStyles();

	const [showPassword, setshowPassword] = useState(false);
	const [
		CreateLogisticsAdminAccountMutate,
		CreateLogisticsAdminAccountInfo,
	] = useCreateLogisticsAdminAccount();

	const { entity, role, email } = useParams<any>();

	const [clientDevicedata, setclientDevicedata] = useState({});

	React.useEffect(() => {
		getBrowserProps().then((data: any) => {
			setclientDevicedata({ ...data });
		});
	}, []);

	const auth = useSelector((state: any) => state.firebase.auth);

	if (isLoaded(auth.uid)) {
		return <Redirect to={ROUTES.REDIRECT_BRIDGE_PAGE} />;
	}

	return (
		<div className={styles.root}>
			<Container>
				<Grid container>
					<Grid item xs={12} lg={4}></Grid>
					<Grid item xs={12} lg={4}>
						<img src={Logo} alt="ekioja" />

						<Box boxShadow={5} className={styles.form_wrapper}>
							<Formik
								validationSchema={SignupValidationSchema}
								initialValues={{
									first_name: "",
									last_name: "",
									username: "",
									email: email,
									password: "",
									confirmpassword: "",
									phone_number: "",
									role: LOGISTICS,
									entity: entity,
									logistics_role: role,
									deviceData: [],
								}}
								onSubmit={(values, { setSubmitting }) => {
									// @ts-ignore
									values.deviceData.push({ ...clientDevicedata });
									setTimeout(() => {
										setSubmitting(false);
										CreateLogisticsAdminAccountMutate(values, {
											onError: (err: any) => console.log(JSON.stringify(err)),
										});
									}, 500);
								}}
							>
								{({
									submitForm,
									isSubmitting,
									setFieldValue,
									values,
									isValid,
									errors,
								}) => (
									<Form>
										{isSubmitting && <LinearProgress />}
										<Field
											component={TextField}
											name="first_name"
											type="text"
											label="First Name"
											fullWidth
										/>
										<br />
										<br />
										<Field
											component={TextField}
											name="last_name"
											type="text"
											label="Last Name"
											fullWidth
										/>
										<br />
										<br />
										<Field
											component={TextField}
											name="username"
											type="text"
											label="Username"
											fullWidth
										/>
										<br />
										<br />
										<Field
											component={TextField}
											name="email"
											type="email"
											label="Email"
											fullWidth
											disabled
										/>
										<br />
										<br />
										<FormControl fullWidth>
											<Field
												component={PhoneInput}
												inputProps={{
													name: "phone_number",
												}}
												country={"ng"}
												enableSearch={true}
												value={values.phone_number}
												onChange={(phone: string) =>
													setFieldValue("contact_phone_number", phone, true)
												}
												inputStyle={{
													width: "100%",
													border: 0,
													borderBottom: `1px solid  ${
														errors.phone_number ? "red" : "gray"
													}`,
												}}
											/>
										</FormControl>
										<ErrorMessage
											name="phone_number"
											render={(msg) => (
												<span style={{ color: "red", fontSize: "13px" }}>
													{msg}
												</span>
											)}
										/>
										<br />
										<br />
										<Field
											component={TextField}
											type={showPassword ? "text" : "password"}
											label="Password (for your dashboard access)"
											name="password"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<LockIcon />
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment
														position="start"
														onClick={() => setshowPassword(!showPassword)}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</InputAdornment>
												),
											}}
											fullWidth
										/>
										<br />
										<br />
										<Field
											component={TextField}
											type={showPassword ? "text" : "password"}
											label="Confirm Password"
											name="confirmpassword"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<LockIcon />
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment
														position="start"
														onClick={() => setshowPassword(!showPassword)}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</InputAdornment>
												),
											}}
											fullWidth
										/>
										<br />
										<br />
										{isValid && (
											<Button
												variant="contained"
												style={{ backgroundColor: "#4caf50", color: "white" }}
												disabled={isSubmitting}
												onClick={submitForm}
											>
												{CreateLogisticsAdminAccountInfo.isLoading
													? "Processing..."
													: CreateLogisticsAdminAccountInfo.isError
													? "Error!"
													: CreateLogisticsAdminAccountInfo.isSuccess
													? "Done!"
													: "Create Account"}
											</Button>
										)}
									</Form>
								)}
							</Formik>
						</Box>
					</Grid>
					<Grid item xs={12} lg={4}></Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default LogisticsAdminSignup;
