
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import { FEEDBACK } from '../../../constants/feedback'

export const Block = (id: string,by:string, status: true | false): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

        return firebase.firestore().collection('Stores').doc(id).set({
            blocked: status,
            blocked_status_by:by,
        },{merge:true})
            .then(() => {
                if (status) {
                    dispatch({ type: FEEDBACK, payload: { severity: 'success', title: 'The Store is Blocked', body: 'The store is temporary closed to customers on Ekioja marketplace.' } })
                }

                if (!status) {
                    dispatch({ type: FEEDBACK, payload: { severity: 'success', title: 'Your Store is Unblocked', body: 'The store is  open to customers on Ekioja marketplace.' } })
                }
                
            });
           

    }