import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
import { queryCache } from "react-query";

import axios from "axios";

const removeAdmin = (id: string) => {
	const deleteDocPromise = firebase
		.firestore()
		.collection("Admins")
		.doc(id)
		.delete();

	const deleteAuthPromise = axios(
		`https://us-central1-ekioja-7bb9f.cloudfunctions.net/ekiojaVendorExpressWidgets/deleteUser/${id}`
	);

	const allPromises = Promise.all([deleteDocPromise, deleteAuthPromise]);

	return allPromises;
};

const useRemoveLogisticsAdmin = () => {
	return useMutation(removeAdmin, {
		// // Always refetch after error or success:
		onSettled: () => {
			queryCache.invalidateQueries("allLogisticsAdmins");
		},
	});
};

export default useRemoveLogisticsAdmin;
