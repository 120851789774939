import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const createLogisticsPartnerAdminAccount = async (values: any) => {
	const response = await firebase
		.auth()
		.createUserWithEmailAndPassword(values.email, values.password);

	//add displayName
	const updateProfilePromise = firebase.auth().currentUser?.updateProfile({
		displayName: values.first_name + " " + values.last_name,
	});

	//create logistics company account if exist

	//create new doc in Admin collection
	const createCollectionPromise = firebase
		.firestore()
		.collection("Admins")
		.doc(response.user?.uid)
		.set({
			first_name: values.first_name,
			last_name: values.last_name,
			username: values.username,
			role: values.role,
			entity: values.entity,
			contact_phone_number: values.contact_phone_number,
			logistics_role: values.logistics_role,
			check_terms_and_condition: values.check_terms_and_condition,
			email: values.email,
			deviceData: values.deviceData,
			approved: true,
			blocked: false,
			isNew: true,
			blocked_history: [],
			removed: false,
			removed_data: [],
			joined_on: firebase.firestore.FieldValue.serverTimestamp(),
		});

	//create wallet

	//send email verification
	const sendEmailPromise = firebase.auth().currentUser?.sendEmailVerification();

	//allpromise
	const allPromise = Promise.all([
		updateProfilePromise,
		createCollectionPromise,
		sendEmailPromise,
	]);

	return allPromise;
};

const useCreateLogisticsPartnerAdminAccount = () => {
	return useMutation(createLogisticsPartnerAdminAccount);
};

export default useCreateLogisticsPartnerAdminAccount;
