import { useMutation, queryCache } from "react-query";
import firebase from "../../../config/firebaseConfig";

const outForDelivery = async (values: any) => {
	return firebase
		.firestore()
		.collection("Conversations")
		.doc(values.chat_id)
		.set(
			{
				orderTimeline: {
					order_placed: {
						current: false,
					},
					pending: {
						current: false,
					},
					waiting_to_be_shipped: {
						current: false,
					},
					out_for_delivery: {
						status: true,
						current: true,
						date: firebase.firestore.FieldValue.serverTimestamp(),
					},
				},
			},
			{ merge: true }
		);
};

const useOutForDelivery = () => {
	return useMutation(outForDelivery, {
		onSettled: (returnedData, error, sentVaribles) => {
			queryCache.invalidateQueries([
				"chatCurrentConversation",
				sentVaribles.chat_id,
			]);
			queryCache.refetchQueries(
				["chatCurrentConversation", sentVaribles.chat_id],
				{ active: true, exact: true }
			);
		},
	});
};

export default useOutForDelivery;
