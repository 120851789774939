import React, { useEffect, useState } from "react";

// //react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

// //react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";

import moment from "moment";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import DoneTwoToneIcon from "@material-ui/icons/DoneTwoTone";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//number format
import NumberFormat from "react-number-format";

//material table
import MaterialTable, { MTableToolbar } from "material-table";

import usePSInitiateTransfer from "../../hooks/finance/usePSInitiateTransfer";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(1),
			width: "100%",
		},
	})
);

const PayoutRequestsWidget = () => {
	const styles = useStyles();

	const [persona, setPersona] = useState("Vendor");
	const [tableData, setTableData] = useState<any>([]);
	const [pageloading, setpageloading] = useState(true);

	const [toggleAprroveTransfer, setToggleAprroveTransfer] = useState(false);
	const [recipientsData, setRecipientsData] = useState<any>([]);

	useFirestoreConnect([
		{
			collection: "RequestedPayouts",
			where: [["persona", "==", persona]],
			storeAs: "RequestedPayouts",
		},
	]);

	const profile = useSelector((state: any) => state.firebase.profile);

	const authuid = useSelector((state: any) => state.firebase.auth.uid);

	const requestedPayouts = useSelector(
		(state: any) => state.firestore.data["RequestedPayouts"]
	);

	const [
		PSInitiateTransferMutate,
		PSInitiateTransferInfo,
	] = usePSInitiateTransfer();

	useEffect(() => {
		if (isLoaded(requestedPayouts) && typeof requestedPayouts !== "undefined") {
			let requestedPayouts_data: any[] = [];
			if (requestedPayouts !== null) {
				for (var requestedPayoutskey in requestedPayouts) {
					requestedPayouts_data.push({
						...requestedPayouts[requestedPayoutskey],
						requestedPayouts_uuid: requestedPayoutskey,
					});
				}
			}

			let data: any = [];
			if (
				persona === "Vendor" &&
				typeof requestedPayouts_data !== "undefined"
			) {
				data = _.map(requestedPayouts_data, (item) => {
					return {
						entity: item.store_name,
						requested_by: item.request_by_name,
						persona: item.persona,
						fulfilled: item.fulfilled,
						date_added: moment(item.date_added.toDate()).format("MM/DD/YYYY"),
						date_fulfilled: _.has(item, "date_fulfilled")
							? moment(item.date_fulfilled.toDate()).format("MM/DD/YYYY")
							: "-",
						amount: item.amount,
						data: item,
						authorized_by_id: authuid,
						authorized_by_name: `${profile.first_name} ${profile.last_name}`,
					};
				});

				setTableData([...data]);
				setpageloading(false);
			}
			if (persona === "Ekioja") {
				data = _.map(requestedPayouts_data, (item) => {
					return {
						entity: item.store_name,
						requested_by: item.request_by_name,
						persona: item.persona,
						fulfilled: item.fulfilled,
						date_added: moment(item.date_added.toDate()).format("MM/DD/YYYY"),
						date_fulfilled: _.has(item, "date_fulfilled")
							? moment(item.date_fulfilled.toDate()).format("MM/DD/YYYY")
							: "-",
						amount: item.amount,
						data: item,
						authorized_by_id: authuid,
						authorized_by_name: `${profile.first_name} ${profile.last_name}`,
					};
				});

				setTableData([...data]);
				setpageloading(false);
			}
			if (persona === "Customer") {
				data = _.map(requestedPayouts_data, (item) => {
					return {
						entity: item.customer_name,
						requested_by: item.request_by_name,
						persona: item.persona,
						fulfilled: item.fulfilled,
						date_added: moment(item.date_added.toDate()).format("MM/DD/YYYY"),
						date_fulfilled: _.has(item, "date_fulfilled")
							? moment(item.date_fulfilled.toDate()).format("MM/DD/YYYY")
							: "-",
						amount: item.amount,
						data: item,
						authorized_by_id: authuid,
						authorized_by_name: `${profile.first_name} ${profile.last_name}`,
					};
				});

				setTableData([...data]);
				setpageloading(false);
			}
		}
	}, [
		requestedPayouts,
		persona,
		authuid,
		profile.first_name,
		profile.last_name,
	]);

	return (
		<div>
			<ToastContainer />
			<Dialog
				open={toggleAprroveTransfer}
				onClose={() => setToggleAprroveTransfer(false)}
				aria-labelledby="approve-transfer-dialog-title"
				aria-describedby="approve-transfer-dialog-description"
			>
				<DialogTitle id="approve-transfer-dialog-title">
					{"Are you  sure?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="approve-transfer-dialog-description">
						By confirming ,it means you authorize this transfer to these
						recipients!.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setToggleAprroveTransfer(false)}
						color="primary"
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							PSInitiateTransferMutate([...recipientsData], {
								onSuccess: () => {
									toast.success("Transfer Initiated successfully!", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									setToggleAprroveTransfer(false);
								},
								onError: () => {
									toast.error("Oops! An error occured!  Try agin", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									setToggleAprroveTransfer(false);
								},
							});
						}}
						color="primary"
						autoFocus
						variant="outlined"
					>
						{PSInitiateTransferInfo.isLoading ? "Processing..." : "Confirm"}
					</Button>
				</DialogActions>
			</Dialog>
			<MaterialTable
				style={{ width: "100%" }}
				isLoading={pageloading}
				title={`${persona} Payout Requests`}
				columns={[
					{
						title: "Entity",
						field: "entity",
						render: (rowData) => (
							<Typography variant="caption" color="primary">
								{rowData.entity}
							</Typography>
						),
					},
					{ title: "Requested by", field: "requested_by" },
					{ title: "Persona", field: "persona" },
					{
						title: "Amount",
						field: "amount",
						render: (rowData) => (
							<Typography variant="h6" color="primary">
								<NumberFormat
									value={rowData.amount}
									displayType={"text"}
									thousandSeparator={true}
									prefix={"₦"}
								/>
							</Typography>
						),
					},
					{
						title: "Fulfilled",
						field: "fulfilled",
						type: "boolean",
						align: "center",
						render: (rowData) => (
							<>
								{rowData.fulfilled && <DoneTwoToneIcon color="primary" />}
								{!rowData.fulfilled && (
									<CloseTwoToneIcon style={{ color: "red" }} />
								)}
							</>
						),
					},
					{ title: "Date Added", field: "date_added" },
					{ title: "Date Fulfilled", field: "date_fulfilled", align: "center" },
				]}
				data={tableData}
				options={{
					search: true,
					actionsColumnIndex: -1,
					exportAllData: true,
					exportButton: true,
					selection: true,
				}}
				actions={[
					{
						icon: "save",
						tooltip: "Save User",
						onClick: (event, rowData: any) => {
							setRecipientsData([...rowData]);
							setToggleAprroveTransfer(true);
						},
					},
				]}
				components={{
					Action: (props) => (
						<Button
							aria-label="fulfill payout"
							size="medium"
							color="primary"
							style={{ color: "#fff", marginLeft: 40 }}
							variant="contained"
							onClick={(event) => props.action.onClick(event, props.data)}
						>
							Approve
						</Button>
					),
					Toolbar: (props) => (
						<div>
							<Grid container spacing={2}>
								<Grid item lg={3}>
									<FormControl className={styles.formControl}>
										<InputLabel id="persona-select-label">
											{" "}
											Select persona
										</InputLabel>
										<Select
											labelId="persona-select-label"
											id="persona-select"
											value={persona}
											onChange={(
												event: React.ChangeEvent<{ value: unknown }>
											) => {
												setPersona(event.target.value as string);
												setpageloading(true);
											}}
										>
											<MenuItem value="Vendor">Vendors</MenuItem>
											<MenuItem value="Ekioja">Ekioja</MenuItem>
											<MenuItem value="Customer">Customers</MenuItem>
										</Select>
										<FormHelperText>
											{persona === "Vendor" && "Vendors payout requests"}
											{persona === "Ekioja" && "Ekioja Admins payout requests"}
											{persona === "Customers" && "Customers payout requests"}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item lg={9}>
									<MTableToolbar {...props} />
								</Grid>
							</Grid>
						</div>
					),
				}}
			/>
		</div>
	);
};

export default PayoutRequestsWidget;
