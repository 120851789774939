import React, { useState, useContext } from "react";
import VendorListView from "./VendorListView";
import useGetEkiojaBrandsWithProducts from "../../../../../../hooks/chat/useGetEkiojaBrandsWithProducts";
import { ChatContext } from "../../../../ChatController";
const VendorListController = () => {
	const ekiojaBrandsInfo = useGetEkiojaBrandsWithProducts();
	const data = ekiojaBrandsInfo.isSuccess ? ekiojaBrandsInfo.data : [];
	const [selectedStoreId, setSelectedStoreId] = useState("");
	const { setCurrentBrandConversation, setConversationListQuery } = useContext(
		ChatContext
	);
	const handleSelectBrandChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSelectedStoreId((event.target as HTMLInputElement).value);
		setCurrentBrandConversation({
			brand_id: (event.target as HTMLInputElement).value,
		});
		setConversationListQuery({ conversation_list_query: "brand" });
	};
	return (
		<div>
			<VendorListView
				data={data}
				status={ekiojaBrandsInfo}
				value={selectedStoreId}
				onChange={handleSelectBrandChange}
			/>
		</div>
	);
};

export default VendorListController;
