import React, { Fragment, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

//material table
import MaterialTable from "material-table";

//material ui components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import EventIcon from "@material-ui/icons/Event";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import PublicIcon from "@material-ui/icons/Public";
import StoreMallDirectoryTwoToneIcon from "@material-ui/icons/StoreMallDirectoryTwoTone";
import CallIcon from "@material-ui/icons/Call";
import RedeemTwoToneIcon from "@material-ui/icons/RedeemTwoTone";
import LocalShippingTwoToneIcon from "@material-ui/icons/LocalShippingTwoTone";
import PanToolTwoToneIcon from "@material-ui/icons/PanToolTwoTone";
import SentimentVerySatisfiedTwoToneIcon from "@material-ui/icons/SentimentVerySatisfiedTwoTone";
import ReplayTwoToneIcon from "@material-ui/icons/ReplayTwoTone";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

import { isLoaded } from "react-redux-firebase";

import { useParams } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import _ from "lodash";
import moment from "moment";

import { getBooking, getBookingCounts } from "../../utils";

import useGetLogisticsCompany from "../../hooks/logistics/useGetLogisticsCompany";
import useApproveLogisticsCompany from "../../hooks/logistics/master/useApproveLogisticsCompany";
import useActiveLogisticsCompany from "../../hooks/logistics/master/useActiveLogisticsCompany";
import useBlockedLogisticsCompany from "../../hooks/logistics/master/useBlockedLogisticsCompany";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			borderRadius: 20,
			width: "100%",
		},
		toolbar_wrapper: {
			// backgroundColor: '#303030',
			backgroundColor: theme.palette.background.default,
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		business_logo_wrapper: {
			width: 60,
			height: 60,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& img": {
				width: 40,
				height: 40,
				borderRadius: 80,
			},
		},
		add_new_product_button: {
			marginTop: "5%",
		},
		queries_wrapper: {
			width: "100%",
			padding: 10,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
		appBar: {
			position: "relative",
			backgroundColor: "#fff",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		td: {
			padding: 10,
			border: "2px black solid",
		},
		widget_wrapper: {
			width: "90%",
			borderRadius: 20,
			padding: 10,
			marginBottom: 8,
			// backgroundColor: theme.palette.background.default,
		},
		widget_info_wrapper: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		widget_info_title_wrapper: {
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
			flexDirection: "row",
		},
		tab_wrapper: {
			// backgroundColor: theme.palette.background.paper,
		},
		product_image: {
			width: 70,
			backgroundColor: "white",
		},
		product_wrapper: {
			padding: 20,
			borderRadius: 10,
			marginBottom: 5,
		},
		product_data_wrapper: {
			display: "flex",
			justifyContent: "space-between",
			// alignItems: "center",
			alignContent: "flex-start",
			flexDirection: "column",
			width: "100%",
		},
		total_amount_summary_wrapper: {
			padding: 20,
			borderRadius: 10,
			width: "80%",
			marginLeft: 20,
			"& hr": {
				border: "1px gray solid",
				width: "80%",
			},
		},
		options_wrapper: {
			width: "90%",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		paper: {
			padding: "6px 16px",
		},
		secondaryTail: {
			backgroundColor: "green",
		},
		spacing: {
			marginBottom: 0,
		},
		connect_default: {
			height: 50,
		},
		connector_success: {
			height: 70,
			backgroundColor: "green",
		},
		connector_error: {
			height: 70,
			backgroundColor: "red",
		},
		dot_success: {
			backgroundColor: "green",
			color: "black",
		},
		dot_error: {
			backgroundColor: "red",
			color: "black",
		},
		action_wrapper: {
			width: "100%",
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center",
			marginTop: 10,
		},
		waiting_state: {
			backgroundColor: "#4287f5",
			padding: 5,
			textTransform: "uppercase",
			color: "white",
			borderRadius: 5,
			fontSize: 12,
		},
		card_wrapper: {
			marginBottom: 10,
			marginTop: 10,
		},
		product_image_2: {
			width: "100%",
			minHeight: 120,
			"& img": {
				width: "100%",
				height: 120,
			},
		},
		product_info_wrapper: {
			width: "100%",
			minHeight: 120,
			display: "flex",
			justifyContent: "space-between",
			alignItems: "flex-start",
			flexDirection: "column",
		},
		actions_wrapper: {
			width: 400,
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			position: "absolute",
			right: 200,
			top: 10,
		},
	})
);

interface Props {
	open: boolean;
	handleClose: () => void;
	unique_id: string;
}

const ViewLogisticsData = ({ open, handleClose, unique_id }: Props) => {
	const styles = useStyles();

	const [pageloading, setpageloading] = useState(false);
	const [tableTitle, setTableTitle] = useState("");
	const [openDetailDialog, setOpenDetailDialog] = useState(false);
	const [bookingData, setBookingData] = useState<any>([]);
	const [bookingDataForCount, setBookingDataForCount] = useState<any>([]);
	const [bookingDataDetail, setBookingDataDetail] = useState<any>({});

	const GetLogisticsCompanyQuery = useGetLogisticsCompany(unique_id);

	const LogisticsCompanyInfo = GetLogisticsCompanyQuery.isSuccess
		? GetLogisticsCompanyQuery.data[0]
		: {};

	const [
		ApproveLogisticsCompanyMutate,
		ApproveLogisticsCompanyInfo,
	] = useApproveLogisticsCompany();
	const [
		ActiveLogisticsCompanyMutate,
		ActiveLogisticsCompanyInfo,
	] = useActiveLogisticsCompany();
	const [
		BlockedLogisticsCompanyMutate,
		BlockedLogisticsCompanyInfo,
	] = useBlockedLogisticsCompany();

	const [
		confirmIsTransportedModalState,
		setConfirmIsTransportedModalState,
	] = useState(false);
	const [
		confirmOrderCompletedModalState,
		setConfirmOrderCompletedModalState,
	] = useState(false);

	const { query } = useParams<any>();

	useFirestoreConnect([
		{
			collection: "Stores",
		},
		{
			collection: "Products",
		},
		{
			collection: "Orders",
		},
		{
			collection: "OrdersTimeline",
		},
	]);

	const stores = useSelector((state: any) => state.firestore.ordered.Stores);
	const products = useSelector(
		(state: any) => state.firestore.ordered.Products
	);

	const orders = useSelector((state: any) => state.firestore.ordered.Orders);
	const ordersTimelime = useSelector(
		(state: any) => state.firestore.ordered.OrdersTimeline
	);

	useEffect(() => {
		if (
			isLoaded(orders) &&
			isLoaded(ordersTimelime) &&
			!_.isUndefined(ordersTimelime) &&
			!_.isUndefined(orders)
		) {
			//re arrange order data
			let order_data: any[] = [];
			if (orders !== null) {
				for (var orderkey in orders) {
					order_data.push({
						...orders[orderkey],
						order_uuid: orders[orderkey].id,
					});
				}
			}

			const mapOrderIdToitems = _.map(order_data, (item) => {
				return {
					...item,
					items: _.map(item.items, (elems) => {
						return {
							...elems,
							order_id: item.order_id,
							delivery_address: item.delivery_address,
							delivery_lga: item.delivery_lga,
							delivery_ward: item.delivery_ward,
							delivery_state: item.delivery_state,
							deliver_from_date: item.deliver_from_date,
							deliver_to_date: item.deliver_to_date,
							deliver_method: item.deliver_method,
							customer_email: item.customer_email,
							recipient: item.recipient,
							item_subtotal: item.item_subtotal,
						};
					}),
				};
			});

			//re arrange order timeline data
			let orderTimeline_data: any[] = [];
			if (ordersTimelime !== null) {
				for (var orderTimelinekey in ordersTimelime) {
					orderTimeline_data.push({
						...ordersTimelime[orderTimelinekey],
						orderTimelinekey_uuid: ordersTimelime[orderTimelinekey].id,
					});
				}
			}

			//merge order and ordertimeline data
			const all_items_in_orders_and_ordertimeline = _.flattenDeep(
				_.map(mapOrderIdToitems, (item) => {
					return [
						..._.intersectionWith(
							_.cloneDeep(item.items),
							orderTimeline_data,
							function (x: any, y: any) {
								return (
									x.order_id === y.the_order_id &&
									x.product_id === y.order_product_id &&
									_.assign(x, y)
								);
							}
						),
					];
				})
			);

			//get waiting to be shipped data
			// const get_confirmed_items_for_shipping = _.filter(
			//   all_items_in_orders_and_ordertimeline,
			//   (item) =>
			//     _.has(item.waiting_to_be_shipped, "current") &&
			//     item.waiting_to_be_shipped.current === true
			// );

			//get essential data from store
			const storeData = _.map(stores, (item) => {
				return {
					store_name: item.business_name,
					pick_address: item.business_location.street_address_1,
					store_id: item.id,
				};
			});

			//merge store to items
			// const store_items_data = _.intersectionWith(
			//   _.cloneDeep(storeData),
			//   all_items_in_orders_and_ordertimeline,
			//   function (x: any, y: any) {
			//     return x.store_id === y.entity && _.assign(x, y);
			//   }
			// );

			const store_items_data = [];
			for (var storekey in storeData) {
				for (var ookey in all_items_in_orders_and_ordertimeline) {
					if (
						storeData[storekey].store_id ===
						all_items_in_orders_and_ordertimeline[ookey].entity
					) {
						store_items_data.push({
							...storeData[storekey],
							...all_items_in_orders_and_ordertimeline[ookey],
						});
					}
				}
			}

			//get essential data from products
			const productData = _.map(products, (item) => {
				return {
					dimension_unit: item.dimension_unit,
					dimension_length: item.dimension_length,
					dimension_height: item.dimension_height,
					dimension_breadth: item.dimension_breadth,
					weight_unit: item.weight_unit,
					weight: item.weight,
					product_id: item.product_id,
					product_name: item.product_name,
					image_1_url: item.image_1_url,
					store_admin_data: item.store_admin_data,
					store_location: item.store_location,
				};
			});

			//merge products to items and store
			// const products_store_items_data = _.intersectionWith(
			//   _.cloneDeep(productData),
			//   store_items_data,
			//   function (x: any, y: any) {
			//     return x.product_id === y.product_id && _.assign(x, y);
			//   }
			// );

			const products_store_items_data = [];
			for (var productkey in productData) {
				for (var stkey in store_items_data) {
					if (
						productData[productkey].product_id ===
						store_items_data[stkey].product_id
					) {
						products_store_items_data.push({
							...productData[productkey],
							...store_items_data[stkey],
						});
					}
				}
			}

			//get logistics bookings
			const logistics_bookings = _.filter(
				products_store_items_data,
				(item) =>
					_.has(item, "logistics") &&
					item.logistics.entity === unique_id &&
					item.booked === true
			);

			setBookingDataForCount([...logistics_bookings]);

			const result_filtered = getBooking(logistics_bookings, query);

			switch (query) {
				case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS:
					setpageloading(false);
					setTableTitle("All Bookings ");
					setBookingData([...result_filtered]);
					break;
				case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_WAITING_TO_BE_SHIPPED:
					setpageloading(false);
					setTableTitle("All Waiting to be shipped ");
					setBookingData([...result_filtered]);
					break;
				case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_OUT_FOR_DELIVERY:
					setpageloading(false);
					setTableTitle("All Out for delivery ");
					setBookingData([...result_filtered]);
					break;
				case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_DELIVERED:
					setpageloading(false);
					setTableTitle("All Delivered ");
					setBookingData([...result_filtered]);
					break;
				case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_CANCELLED:
					setpageloading(false);
					setTableTitle("All Cancelled ");
					setBookingData([...result_filtered]);
					break;
				case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_RETURN:
					setpageloading(false);
					setTableTitle("All Return ");
					setBookingData([...result_filtered]);
					break;

				default:
					break;
			}
		}
	}, [orders, ordersTimelime, stores, products, query, unique_id]);

	const handleClickDetailDialogOpen = () => {
		setOpenDetailDialog(true);
	};

	const handlDetailDialogeClose = () => {
		setOpenDetailDialog(false);
	};

	const handleCloseConfirmIsTransportedModalState = () => {
		setConfirmIsTransportedModalState(false);
	};

	const handleCloseConfirmOpenCompletedModalState = () => {
		setConfirmOrderCompletedModalState(false);
	};

	return (
		<div>
			<Dialog
				fullScreen
				open={open}
				onClose={() => {
					handleClose();
					BlockedLogisticsCompanyInfo.reset();
					ApproveLogisticsCompanyInfo.reset();
					ActiveLogisticsCompanyInfo.reset();
				}}
				TransitionComponent={Transition}
			>
				{GetLogisticsCompanyQuery.isLoading && (
					<div
						style={{
							width: "100vw",
							height: "90vh",
							display: "flex",
							placeContent: "center",
							placeItems: "center",
							fontSize: 50,
						}}
					>
						{" "}
						Loading...
					</div>
				)}
				{GetLogisticsCompanyQuery.isSuccess && (
					<>
						<AppBar className={styles.appBar} elevation={0}>
							<Toolbar>
								<IconButton
									edge="start"
									color="primary"
									onClick={handleClose}
									aria-label="close"
								>
									<CloseIcon />
								</IconButton>
								<Typography variant="h6" color="primary">
									{LogisticsCompanyInfo?.logistics_company_name}
								</Typography>
								<div className={styles.actions_wrapper}>
									<Button
										variant="contained"
										color="primary"
										style={{ color: "#fff" }}
										disabled={LogisticsCompanyInfo?.approved}
										onClick={() => {
											if (
												window.confirm(
													"Are you sure you wanna approve this logistics partner"
												)
											) {
												ApproveLogisticsCompanyMutate({
													id: unique_id,
													status: !LogisticsCompanyInfo.approved,
												});
											}
										}}
									>
										{" "}
										{ApproveLogisticsCompanyInfo.isLoading
											? "Processing..."
											: ApproveLogisticsCompanyInfo.isError
											? "Error!"
											: ApproveLogisticsCompanyInfo.isSuccess
											? "Approved"
											: "Approve"}
									</Button>
									<Button
										variant="contained"
										color="primary"
										style={{ color: "#fff" }}
										onClick={() => {
											if (
												window.confirm(
													`Are you sure you wanna ${
														LogisticsCompanyInfo?.blocked ? "Unblock" : "Block"
													} this logistics partner`
												)
											) {
												BlockedLogisticsCompanyMutate(
													{
														id: unique_id,
														status: !LogisticsCompanyInfo?.blocked,
													},
													{
														onSuccess: () =>
															BlockedLogisticsCompanyInfo.reset(),
													}
												);
											}
										}}
									>
										{BlockedLogisticsCompanyInfo.isLoading
											? "Processing..."
											: BlockedLogisticsCompanyInfo.isError
											? "Error!"
											: BlockedLogisticsCompanyInfo.isSuccess
											? "done!"
											: `${
													LogisticsCompanyInfo?.blocked ? "Unblock" : "Block"
											  }`}
									</Button>
									<Button
										variant="contained"
										color="primary"
										style={{ color: "#fff" }}
										onClick={() => {
											if (
												window.confirm(
													`Are you sure you wanna ${
														LogisticsCompanyInfo?.active
															? "Deactivate"
															: "Activate"
													} this logistics partner`
												)
											) {
												ActiveLogisticsCompanyMutate(
													{
														id: unique_id,
														status: !LogisticsCompanyInfo?.active,
													},
													{
														onSuccess: () => ActiveLogisticsCompanyInfo.reset(),
													}
												);
											}
										}}
									>
										{ActiveLogisticsCompanyInfo.isLoading
											? "Processing..."
											: ActiveLogisticsCompanyInfo.isError
											? "Error!"
											: ActiveLogisticsCompanyInfo.isSuccess
											? "done!"
											: `${
													LogisticsCompanyInfo?.active
														? "Deactivate"
														: "Activate"
											  }`}
									</Button>
								</div>
							</Toolbar>
						</AppBar>
						<Grid container>
							<Grid item xs={12} lg={1}></Grid>
							<Grid item xs={12} lg={10}>
								<Dialog
									open={confirmIsTransportedModalState}
									onClose={handleCloseConfirmIsTransportedModalState}
								>
									<DialogTitle id="confirm-order-is-transported-dialog">
										Confirm Order is transported by dispatch riders
									</DialogTitle>
									<DialogContent>
										<DialogContentText id="confirm-order-is-transported-dialog-description">
											By confirming this order it means the parcel is being
											transported to the delivery address
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button
											color="primary"
											onClick={() => {
												// dispatch(
												// 	OrderOutForDelivery(
												// 		bookingDataDetail.orderTimelinekey_uuid
												// 	)
												// );
												handleCloseConfirmIsTransportedModalState();
												handlDetailDialogeClose();
											}}
										>
											Ok , I confirm
										</Button>
										<Button color="primary" autoFocus>
											Cancel
										</Button>
									</DialogActions>
								</Dialog>

								<Dialog
									open={confirmOrderCompletedModalState}
									onClose={handleCloseConfirmOpenCompletedModalState}
								>
									<DialogTitle id="confirm-order-is-transported-dialog">
										Confirm parcel was delivered to the recipient
									</DialogTitle>
									<DialogContent>
										<DialogContentText id="confirm-order-is-transported-dialog-description">
											By confirming this booking it means the parcel was
											delivered to the recipient
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button
											color="primary"
											onClick={() => {
												// dispatch(
												// 	OrderDelivered(bookingDataDetail.orderTimelinekey_uuid, {
												// 		logistics_id: bookingDataDetail.logistics.entity,
												// 		vendor_id: bookingDataDetail.order_store_id,
												// 		logistics_pay: bookingDataDetail.delivery_fee,
												// 		vendor_pay: bookingDataDetail.item_vendor_price,
												// 		ekioja_pay:
												// 			bookingDataDetail.item_subtotal -
												// 			bookingDataDetail.item_vendor_price,
												// 	})
												// );
												handlDetailDialogeClose();
												handleCloseConfirmOpenCompletedModalState();
											}}
										>
											Ok , I confirm
										</Button>
										<Button color="primary" autoFocus>
											Cancel
										</Button>
									</DialogActions>
								</Dialog>

								{!_.isEmpty(bookingDataDetail) && (
									<Dialog
										fullScreen
										open={openDetailDialog}
										onClose={handlDetailDialogeClose}
										TransitionComponent={Transition}
									>
										<AppBar className={styles.appBar} elevation={0}>
											<Toolbar>
												<IconButton
													edge="start"
													color="primary"
													onClick={handlDetailDialogeClose}
													aria-label="close"
												>
													<CloseIcon />
												</IconButton>
												<Typography
													variant="h6"
													className={styles.title}
													color="primary"
												>
													{bookingDataDetail.order_id}
												</Typography>
												{/* {!bookingDataDetail.delivered?.status === true ||
								!bookingDataDetail.canceled?.status === true ? (
									<Button onClick={handleCancelItemOpen}>
										Cancel delivery
									</Button>
								) : (
									<></>
								)} */}
											</Toolbar>
										</AppBar>
										<Container>
											<Grid container>
												<Grid item xs={12} lg={6}>
													<Box boxShadow={6} className={styles.widget_wrapper}>
														<Typography variant="h6" align="center">
															Order summary
														</Typography>
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<EventIcon color="primary" />
																<Typography variant="caption">
																	Date Booked
																</Typography>
															</div>
															<Typography variant="h6">
																{moment(
																	bookingDataDetail.logistics.on.toDate()
																).format("MM/DD/YYYY")}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<EventIcon color="primary" />
																<Typography variant="caption">
																	Estimated delivery deadline
																</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.deliver_to_date}
															</Typography>
														</div>

														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<LocalShippingIcon color="primary" />
																<Typography variant="caption">
																	Shipping method
																</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.deliver_method}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<StoreMallDirectoryTwoToneIcon color="primary" />
																<Typography variant="caption">
																	Store Name
																</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.store_name}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<CallIcon color="primary" />
																<Typography variant="caption">
																	Phone Number
																</Typography>
															</div>
															<Typography variant="h6">
																+
																{
																	bookingDataDetail.store_admin_data
																		.contact_phone_number
																}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<LocationOnIcon color="primary" />
																<Typography variant="caption">
																	Store Address
																</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.pick_address}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<LocationOnIcon color="primary" />
																<Typography variant="caption">State</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.store_location.state}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<LocationOnIcon color="primary" />
																<Typography variant="caption">LGA</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.store_location.lga}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<LocationOnIcon color="primary" />
																<Typography variant="caption">WARD</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.store_location.ward}
															</Typography>
														</div>
													</Box>

													<Box boxShadow={6} className={styles.widget_wrapper}>
														<Typography variant="h6" align="center">
															Delivery Address
														</Typography>
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<AccountBoxIcon color="primary" />
																<Typography variant="caption">
																	Recipient's Name
																</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.recipient.customer}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<CallIcon color="primary" />
																<Typography variant="caption">
																	Phone Number
																</Typography>
															</div>
															<Typography variant="h6">
																+{bookingDataDetail.recipient.phonenumber}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<PublicIcon color="primary" />
																<Typography variant="caption">Email</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.customer_email}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<LocationOnIcon color="primary" />
																<Typography variant="caption">
																	Address
																</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.delivery_address}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<LocationOnIcon color="primary" />
																<Typography variant="caption">State</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.delivery_state}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<LocationOnIcon color="primary" />
																<Typography variant="caption">LGA</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.delivery_lga}
															</Typography>
														</div>
														<Divider light />
														<div className={styles.widget_info_wrapper}>
															<div className={styles.widget_info_title_wrapper}>
																<LocationOnIcon color="primary" />
																<Typography variant="caption">WARD</Typography>
															</div>
															<Typography variant="h6">
																{bookingDataDetail.delivery_ward}
															</Typography>
														</div>
													</Box>

													<Box boxShadow={6} className={styles.widget_wrapper}>
														<Typography variant="h6" align="center">
															Dispatcher Details
														</Typography>
														{_.has(bookingDataDetail, "logistics") ? (
															<>
																<div className={styles.widget_info_wrapper}>
																	<div
																		className={styles.widget_info_title_wrapper}
																	>
																		<AccountBoxIcon color="primary" />
																		<Typography variant="caption">
																			Dispatcher name
																		</Typography>
																	</div>
																	<Typography variant="h6">
																		{bookingDataDetail.logistics.name}
																	</Typography>
																</div>
																<Divider light />
																<div className={styles.widget_info_wrapper}>
																	<div
																		className={styles.widget_info_title_wrapper}
																	>
																		<CallIcon color="primary" />
																		<Typography variant="caption">
																			Phone Number
																		</Typography>
																	</div>
																	<Typography variant="h6">
																		+
																		{
																			bookingDataDetail.logistics
																				.dispatcher_phonenumber
																		}
																	</Typography>
																</div>
																<Divider light />
																<div className={styles.widget_info_wrapper}>
																	<div
																		className={styles.widget_info_title_wrapper}
																	>
																		<PublicIcon color="primary" />
																		<Typography variant="caption">
																			Email
																		</Typography>
																	</div>
																	<Typography variant="h6">
																		{
																			bookingDataDetail.logistics
																				.dispatcher_email
																		}
																	</Typography>
																</div>
															</>
														) : (
															<div className={styles.widget_info_wrapper}>
																<div
																	className={styles.widget_info_title_wrapper}
																>
																	<Typography variant="caption">
																		Status:
																	</Typography>
																</div>
																<Typography variant="h6">
																	No dispatcher assigned yet
																</Typography>
															</div>
														)}
													</Box>
												</Grid>
												<Grid item xs={12} lg={6}>
													<br />
													<Box className={styles.product_wrapper} boxShadow={6}>
														<Grid container spacing={1}>
															<Grid item xs={12} lg={2}>
																<img
																	className={styles.product_image}
																	src={bookingDataDetail.image_1_url}
																	alt={`${bookingDataDetail.product_name}`}
																/>
															</Grid>
															<Grid item xs={12} lg={9}>
																<div className={styles.product_data_wrapper}>
																	<Typography variant="h6">
																		{" "}
																		{bookingDataDetail.product_name}
																	</Typography>
																	<div>
																		<Divider light />
																		<div className={styles.widget_info_wrapper}>
																			<div
																				className={
																					styles.widget_info_title_wrapper
																				}
																			>
																				<Typography variant="caption">
																					Dimensions(LXBXH)
																				</Typography>
																			</div>
																			<Typography variant="caption">
																				{`${bookingDataDetail.dimension_length} ${bookingDataDetail.dimension_unit} X ${bookingDataDetail.dimension_breadth} ${bookingDataDetail.dimension_unit}  X ${bookingDataDetail.dimension_height} ${bookingDataDetail.dimension_unit} `}
																			</Typography>
																		</div>
																		<Divider light />
																		<div className={styles.widget_info_wrapper}>
																			<div
																				className={
																					styles.widget_info_title_wrapper
																				}
																			>
																				<Typography variant="caption">
																					Weight
																				</Typography>
																			</div>
																			<Typography variant="caption">
																				{`${bookingDataDetail.weight} ${bookingDataDetail.weight_unit} `}
																			</Typography>
																		</div>
																		<Divider light />
																	</div>
																</div>
															</Grid>
															<Grid item xs={12} lg={1}>
																<Typography variant="caption">
																	Quantity
																</Typography>
																<Typography variant="h6">
																	x {bookingDataDetail.quantity}
																</Typography>
															</Grid>
														</Grid>
													</Box>

													<Box
														boxShadow={6}
														style={{ minHeight: 310, borderRadius: 10 }}
													>
														<Timeline align="alternate">
															{bookingDataDetail.waiting_to_be_shipped
																?.status && (
																<Fragment>
																	<TimelineItem className={styles.spacing}>
																		<TimelineOppositeContent>
																			<Typography
																				variant="body2"
																				color="textSecondary"
																			>
																				{moment(
																					bookingDataDetail.waiting_to_be_shipped.date.toDate()
																				).format("YYYY-MM-DD")}
																			</Typography>
																		</TimelineOppositeContent>
																		<TimelineSeparator>
																			<TimelineDot
																				className={styles.dot_success}
																			>
																				<RedeemTwoToneIcon />
																			</TimelineDot>
																			<TimelineConnector
																				className={styles.connector_success}
																			/>
																		</TimelineSeparator>
																		<TimelineContent>
																			<Paper
																				elevation={3}
																				className={styles.paper}
																			>
																				<Typography
																					variant="subtitle2"
																					component="h1"
																				>
																					Waiting to be shipped
																				</Typography>
																				<Typography variant="caption">
																					Item is processed and vendor is
																					waiting for the delivery man
																				</Typography>
																			</Paper>
																			{/* {_.has(
																bookingDataDetail.out_for_delivery,
																"current"
															) === false && (
																<div className={styles.action_wrapper}>
																	<Button
																		variant="contained"
																		color="primary"
																		onClick={
																			handleOpenConfirmIsTransportedModalState
																		}
																	>
																		{" "}
																		Confirm Parcel is on delivery
																	</Button>
																
																</div>
															)} */}
																		</TimelineContent>
																	</TimelineItem>
																</Fragment>
															)}

															{bookingDataDetail.out_for_delivery?.status && (
																<Fragment>
																	<TimelineItem className={styles.spacing}>
																		<TimelineOppositeContent>
																			<Typography
																				variant="body2"
																				color="textSecondary"
																			>
																				{moment(
																					bookingDataDetail.out_for_delivery.date.toDate()
																				).format("YYYY-MM-DD")}
																			</Typography>
																		</TimelineOppositeContent>
																		<TimelineSeparator>
																			<TimelineDot
																				className={styles.dot_success}
																			>
																				<LocalShippingTwoToneIcon />
																			</TimelineDot>
																			<TimelineConnector
																				className={styles.connector_success}
																			/>
																		</TimelineSeparator>
																		<TimelineContent>
																			<Paper
																				elevation={3}
																				className={styles.paper}
																			>
																				<Typography
																					variant="subtitle2"
																					component="h1"
																				>
																					Out for delivery
																				</Typography>
																				<Typography variant="caption">
																					The item is being delivered by
																					dispatcher
																				</Typography>
																			</Paper>
																			{/* {_.has(bookingDataDetail.delivered, "current") ===
																false && (
																<div className={styles.action_wrapper}>
																	<Button
																		variant="contained"
																		color="primary"
																		onClick={handleOpenOrderCompletedModalState}
																	>
																		{" "}
																		Confirm Parcel delivered
																	</Button>
																	
																</div>
															)} */}
																		</TimelineContent>
																	</TimelineItem>
																</Fragment>
															)}

															{bookingDataDetail.delivered?.status && (
																<Fragment>
																	<TimelineItem className={styles.spacing}>
																		<TimelineOppositeContent>
																			<Typography
																				variant="body2"
																				color="textSecondary"
																			>
																				{moment(
																					bookingDataDetail.delivered.date.toDate()
																				).format("YYYY-MM-DD")}
																			</Typography>
																		</TimelineOppositeContent>
																		<TimelineSeparator>
																			<TimelineDot
																				className={styles.dot_success}
																			>
																				<SentimentVerySatisfiedTwoToneIcon />
																			</TimelineDot>
																		</TimelineSeparator>
																		<TimelineContent>
																			<Paper
																				elevation={3}
																				className={styles.paper}
																			>
																				<Typography
																					variant="subtitle2"
																					component="h1"
																				>
																					Delivered
																				</Typography>
																				<Typography variant="caption">
																					The items were delivered to customer
																					successfully
																				</Typography>
																			</Paper>
																		</TimelineContent>
																	</TimelineItem>
																</Fragment>
															)}

															{bookingDataDetail.canceled?.status && (
																<Fragment>
																	<Typography
																		variant="subtitle2"
																		color="textPrimary"
																	></Typography>
																	<TimelineItem className={styles.spacing}>
																		<TimelineOppositeContent>
																			<Typography
																				variant="body2"
																				color="textSecondary"
																			>
																				{moment(
																					bookingDataDetail.canceled.date.toDate()
																				).format("YYYY-MM-DD")}
																			</Typography>
																		</TimelineOppositeContent>
																		<TimelineSeparator>
																			<TimelineDot className={styles.dot_error}>
																				<PanToolTwoToneIcon />
																			</TimelineDot>
																		</TimelineSeparator>
																		<TimelineContent>
																			<Paper
																				elevation={3}
																				className={styles.paper}
																			>
																				<Typography
																					variant="subtitle2"
																					component="h1"
																				>
																					Order was cancelled
																				</Typography>
																				<Typography
																					variant="caption"
																					color="textSecondary"
																				>
																					{bookingDataDetail.canceled
																						.persona === "customer" &&
																						"Cancelled by Customer"}
																					{bookingDataDetail.canceled
																						.persona === "vendor" &&
																						"Cancelled by Vendor"}
																					{bookingDataDetail.canceled
																						.persona === "logistics" &&
																						"Cancelled by Dispatcher"}
																				</Typography>
																				<br />
																				<Typography
																					variant="caption"
																					color="textSecondary"
																				>
																					Reason :
																					{bookingDataDetail.canceled.reason}
																				</Typography>
																			</Paper>
																		</TimelineContent>
																	</TimelineItem>
																</Fragment>
															)}
														</Timeline>
													</Box>
												</Grid>
											</Grid>
										</Container>
									</Dialog>
								)}

								<div className={styles.queries_wrapper}>
									<Chip
										label={`All Bookings ${getBookingCounts(
											bookingDataForCount,
											ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS
										)}`}
										variant="default"
										onClick={() => {
											setBookingData([
												...getBooking(
													bookingDataForCount,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS
												),
											]);
											setpageloading(false);
											setTableTitle("All Bookings");
										}}
									/>
									<Chip
										label={`All Waiting to be shipped  ${getBookingCounts(
											bookingDataForCount,
											ROUTES.LOGISTICS_BOOKINGS_QUERIES
												.ALL_WAITING_TO_BE_SHIPPED
										)}`}
										variant="default"
										onClick={() => {
											setBookingData([
												...getBooking(
													bookingDataForCount,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES
														.ALL_WAITING_TO_BE_SHIPPED
												),
											]);
											setpageloading(false);
											setTableTitle("All Waiting to be shipped");
										}}
									/>
									<Chip
										label={`All Out for delivery  ${getBookingCounts(
											bookingDataForCount,
											ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_OUT_FOR_DELIVERY
										)}`}
										variant="default"
										onClick={() => {
											setBookingData([
												...getBooking(
													bookingDataForCount,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_OUT_FOR_DELIVERY
												),
											]);
											setpageloading(false);
											setTableTitle("All Out for delivery");
										}}
									/>
									<Chip
										label={`All Delivered  ${getBookingCounts(
											bookingDataForCount,
											ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_DELIVERED
										)}`}
										variant="default"
										onClick={() => {
											setBookingData([
												...getBooking(
													bookingDataForCount,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_DELIVERED
												),
											]);
											setpageloading(false);
											setTableTitle("All Delivered");
										}}
									/>
									<Chip
										label={`All Returned  ${getBookingCounts(
											bookingDataForCount,
											ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_RETURN
										)}`}
										variant="default"
										onClick={() => {
											setBookingData([
												...getBooking(
													bookingDataForCount,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_RETURN
												),
											]);
											setpageloading(false);
											setTableTitle("All Return");
										}}
									/>
									<Chip
										label={`All Cancelled  ${getBookingCounts(
											bookingDataForCount,
											ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_CANCELLED
										)}`}
										variant="default"
										onClick={() => {
											setBookingData([
												...getBooking(
													bookingDataForCount,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_CANCELLED
												),
											]);
											setpageloading(false);
											setTableTitle("All Cancelled");
										}}
									/>
								</div>
								<Box className={styles.root} boxShadow={5}>
									<MaterialTable
										style={{ width: "100%" }}
										isLoading={pageloading}
										title={tableTitle}
										columns={[
											{
												title: "Order ID",
												field: "order_id",
												render: (rowData) => (
													<Typography variant="caption">
														{rowData.order_id}
													</Typography>
												),
											},
											{
												title: "Dispatcher",
												field: "dispatcher",
												render: (rowData) => (
													<Typography variant="caption">
														{rowData.dispatcher}
													</Typography>
												),
											},
											{
												title: "Store name",
												field: "store_name",
												render: (rowData) => (
													<Typography variant="caption">
														{rowData.store_name}
													</Typography>
												),
											},
											// {
											//   title: "Product Dimension (L X B X H)",
											//   field: "dimensions",
											//   render: (rowData) => (
											//     <Typography variant="caption">
											//       {rowData.dimensions}
											//     </Typography>
											//   ),
											// },
											// {
											//   title: "Product Weight",
											//   field: "weight",
											//   render: (rowData) => (
											//     <Typography variant="caption">{rowData.weight}</Typography>
											//   ),
											// },
											// {
											//   title: "Quantity",
											//   field: "quantity",
											//   type: "numeric",
											//   render: (rowData) => (
											//     <Typography variant="caption">
											//       X {rowData.quantity}
											//     </Typography>
											//   ),
											// },
											// {
											//   title: "Pick Up address",
											//   field: "pick_address",
											//   render: (rowData) => (
											//     <Typography variant="caption">
											//       {rowData.pick_address}
											//     </Typography>
											//   ),
											// },
											{
												title: "Delivery Address",
												field: "delivery_address",
												render: (rowData) => (
													<Typography variant="caption">
														{rowData.delivery_address}
													</Typography>
												),
											},
											{
												title: "orderTimeline_uuid",
												field: "orderTimeline_uuid",
												hidden: true,
											},

											{
												title: "Status",
												field: "status",
												render: (rowData) => (
													<Fragment>
														{!_.isUndefined(
															rowData.data.waiting_to_be_shipped
														) &&
															rowData.data.waiting_to_be_shipped.current ===
																true && (
																<Chip
																	icon={<RedeemTwoToneIcon />}
																	label="WAITING TO BE SHIPPED"
																	variant="outlined"
																	color="secondary"
																/>
															)}
														{!_.isUndefined(rowData.data.shipped) &&
															rowData.data.shipped.current === true && (
																<Chip
																	icon={<LocalShippingTwoToneIcon />}
																	label="SHIPPED"
																	variant="outlined"
																	color="secondary"
																/>
															)}
														{!_.isUndefined(rowData.data.out_for_delivery) &&
															rowData.data.out_for_delivery.current ===
																true && (
																<Chip
																	icon={<LocalShippingTwoToneIcon />}
																	label="OUT FOR DELIVERY"
																	variant="outlined"
																	color="secondary"
																/>
															)}
														{!_.isUndefined(rowData.data.delivered) &&
															rowData.data.delivered.current === true && (
																<Chip
																	icon={<SentimentVerySatisfiedTwoToneIcon />}
																	label="DELIVERED"
																	variant="outlined"
																	color="secondary"
																/>
															)}
														{!_.isUndefined(rowData.data.canceled) &&
															rowData.data.canceled.current === true && (
																<Chip
																	icon={
																		<PanToolTwoToneIcon
																			style={{
																				color: "red",
																			}}
																		/>
																	}
																	label="CANCELLED"
																	variant="outlined"
																	style={{
																		color: "red",
																		border: "1px red solid",
																	}}
																/>
															)}
														{!_.isUndefined(rowData.data.returned) &&
															rowData.data.returned.current === true && (
																<Chip
																	icon={
																		<ReplayTwoToneIcon
																			style={{
																				color: "orange",
																			}}
																		/>
																	}
																	label="RETURNED"
																	variant="outlined"
																	style={{
																		color: "orange",
																		border: "1px orange solid",
																	}}
																/>
															)}
													</Fragment>
												),
											},
											{
												title: "Booking date",
												field: "added",
												render: (rowData) => (
													<Typography variant="caption">
														{rowData.added} <br /> {rowData.createdon}{" "}
													</Typography>
												),
											},
											{ title: "Created on", field: "createdon", hidden: true },
											{ title: "data", field: "data", hidden: true },
											{
												title: "Action",
												field: "action",
												render: (rowData) => (
													<Button
														onClick={() => {
															handleClickDetailDialogOpen();
															setBookingDataDetail({ ...rowData.data });

															// setCancelItemData({ ...rowData.data });
														}}
													>
														VIEW
													</Button>
												),
											},
										]}
										data={bookingData}
										options={{
											search: true,
											actionsColumnIndex: -1,
										}}

										// actions={[
										//     {
										//         icon: 'save',
										//         tooltip: 'Save User',
										//         onClick: (event, rowData: any) => { navigateToLink.push(ROUTES.VIEW_PRODUCT_DETAILS, { id: rowData.id }) }
										//     },

										// ]}
									/>
								</Box>
							</Grid>
							<Grid item xs={12} lg={1}></Grid>
						</Grid>
					</>
				)}
			</Dialog>
		</div>
	);
};

export default ViewLogisticsData;
