
import { FEEDBACK } from '../../../constants/feedback';

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const CheckedInterestedVendor = (id: string,authuid:string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();
        try {


            //create new doc in Admin collection
            firebase.firestore().collection('InterestedVendors').doc(id).update({
                checked: true,
                checked_data:{
                    by:authuid,
                    on:firebase.firestore.FieldValue.serverTimestamp(),
                }
            }).then(() => {
                dispatch({ type: FEEDBACK, payload: { severity: 'success', title: 'Thank you! ', body: `Fingers crossed we hope the vendor joins our platform` } })
            })


        } catch (error) {
            dispatch({ type: FEEDBACK, payload: { severity: 'error', title: 'Error!!!', body: error.code } })
        }

    }