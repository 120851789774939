import React, { useState, useEffect, Fragment } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//formik
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Autocomplete } from "formik-material-ui-lab";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { default as MyTextField } from "@material-ui/core/TextField";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { LinearProgress } from "@material-ui/core";
//phone number import
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

//images
import Logo from "../assets/images/svg/ekioja-logo.svg";

import moment from "moment";

import _ from "lodash";

//number format
import NumberFormat from "react-number-format";

//react - redux
import { useSelector, useDispatch } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";
import LoadingScreen from "../components/LoadingScreen";

import { IAddress } from "../ts/interfaces/addresses";
import { ICart } from "../ts/interfaces/cart";
import { IProducts } from "../ts/interfaces/product";
import { IOrderItems } from "../ts/interfaces/order";

import { RemoveAddress } from "../store/actions/customer/address/removeaddressAction";
import { Addaddress } from "../store/actions/customer/address/addaddressAction";
import { Editaddress } from "../store/actions/customer/address/editaddressAction";

import { NewOrder } from "../store/actions/customer/order/newOrder";

import * as ROUTES from "../constants/routes";

import { Link } from "react-router-dom";

import {orderNumber , referenceNumber} from '../utils/index';

//paystack
import { PaystackConsumer } from "react-paystack";

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const autocompleteService_2 = { current: null };

interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}

interface IProductAndCart extends IProducts, ICart {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },

    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        marginRight: 40,
      },
    },

    logo_wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: 100,
      height: 60,
      marginRight: 10,
      "& img": {
        width: 90,
        height: "auto",
      },
    },
    section_gutter: {
      marginTop: 100,
    },
    card_wrappers: {
      marginBottom: 20,
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    shipment_payment_details_field: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    total_price: {
      color: "green",
    },
    empty_cart: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  })
);

interface Props {}

const MarketPLaceCheckout = (props: Props) => {
  const styles = useStyles();

  const dispatch = useDispatch();

  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<PlaceType[]>([]);

  const [value_2, setValue_2] = React.useState<PlaceType | null>(null);
  const [inputValue_2, setInputValue_2] = React.useState("");
  const [options_2, setOptions_2] = React.useState<PlaceType[]>([]);

  const loaded = React.useRef(false);

  const [customeraddress, setCustomeraddress] = useState<IAddress>({});

  const [openAddressDialog, setOpenAddressDialog] = React.useState(false);

  const [addressDialogType, setAddressDialogType] = useState<"add" | "edit">(
    "add"
  );

  const [productsData, setProductsData] = useState<IProductAndCart[]>([]);

  const [totalSubTotal, setTotalSubTotal] = useState(0);

  const [storeIds, setStoreIds] = useState<string[]>([]);

  const [storeEmails, setStoreEmails] = useState<string[]>([])

  const [orderData, setOrderData] = useState<IOrderItems[]>([]);

  const [refNum, setRefNum] = useState("");


  const handleClickOpen = () => {
    setOpenAddressDialog(true);
  };

  const handleClose = () => {
    setOpenAddressDialog(false);
  };

  useFirestoreConnect([
    {
      collection: "CustomerAddresses",
      where: [["customer_id", "==", "demo-customer"]],
      storeAs: "CustomerAddress",
    },
    {
      collection: "Products",
    },
    {
      collection: "Cart",
      where: [
        ["cart_customer_id", "==", "demo-customer"],
        ["cart_closed", "==", false],
      ],
      storeAs: "CustomerCart",
    },
  ]);

  const products = useSelector(
    (state: any) => state.firestore.ordered.Products
  );

  const Customeraddress = useSelector(
    (state: any) => state.firestore.data["CustomerAddress"]
  );

  const cart = useSelector(
    (state: any) => state.firestore.data["CustomerCart"]
  );

  useEffect(() => {
    setRefNum(`${referenceNumber()}`);
  }, []);

  useEffect(() => {
    if (isLoaded(Customeraddress) && isLoaded(cart) && isLoaded(products)) {
      
      let address = [];
      if (Customeraddress !== null) {
        for (var addresskey in Customeraddress) {
          address.push({
            ...Customeraddress[addresskey],
            address_uuid: addresskey,
          });
        }
        setInputValue_2(address[0].address);
        setCustomeraddress({ ...address[0] });
      }

      let cart_data: any[] = [];
      if (cart !== null) {
        for (var cartkey in cart) {
          cart_data.push({ ...cart[cartkey], cart_uuid: cartkey });
        }
      }

      var result_products_in_cart = _.intersectionWith(
        _.cloneDeep(products),
        cart_data,
        function (x: any, y: any) {
          return x.product_id === y.cart_product_id && _.assign(x, y);
        }
      );

      const sorted = _.orderBy(
        result_products_in_cart,
        ["cart_added_on"],
        ["desc"]
      );

      const total_cart_item_subtotal_and_quantity = _.map(
        sorted,
        (item) => item.cart_subtotal
        // (item) => item.cart_subtotal * item.cart_quantity
      );

      const result_total_cart_item_subtotal = _.sum(
        total_cart_item_subtotal_and_quantity
      );

     
      

      const order_items = _.map(sorted, (item) => {
        return {
          product_id: item.product_id,
          product_image: item.image_1,
          product_name: item.product_name,
          quantity: item.cart_quantity,
          subtotal: item.cart_subtotal,
          entity: item.entity,
          cart_uuid: item.cart_uuid,
        };
      });

      setOrderData([...order_items]);

      const all_product_store_ids = _.map(sorted, (item) => item.entity);
      const all_product_store_vendor_email = _.map(sorted, (item) => item.added_by);

      setStoreIds([...all_product_store_ids]);

      setStoreEmails([...all_product_store_vendor_email]);

      setTotalSubTotal(result_total_cart_item_subtotal);

      setProductsData([...sorted]);
    }
  }, [Customeraddress, cart, products]);

  useEffect(() => {
    if (typeof window !== "undefined" && !loaded.current) {
      if (!document.querySelector("#addaddress")) {
        loadScript(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyAYVMr5WMxDnEeCTyhT55qW_XDiAJt9Qds&libraries=places",
          document.querySelector("head"),
          "addaddress"
        );
      }

      loaded.current = true;
    }
  }, []);

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  const fetch_2 = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          (autocompleteService_2.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService_2.current && (window as any).google) {
      autocompleteService_2.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService_2.current) {
      return undefined;
    }

    if (inputValue_2 === "") {
      setOptions_2(value_2 ? [value_2] : []);
      return undefined;
    }

    fetch_2({ input: inputValue_2 }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value_2) {
          newOptions = [value_2];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions_2(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value_2, inputValue_2, fetch_2]);

  if (!isLoaded(Customeraddress)) return <LoadingScreen />;

  if (isLoaded(cart) && cart === null)
    return (
      <div className={styles.empty_cart}>
        {" "}
        <Typography variant="h2" color="textPrimary">
          Oops! Cart is empty
        </Typography>{" "}
        <Link to={ROUTES.MARKETPLACE_CATEGORIES}>
          <Button variant="contained" size="large">
            continue shopping
          </Button>
        </Link>
      </div>
    );

  return (
    <div>
      <Dialog
        open={openAddressDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {addressDialogType === "add" && "Add address"}{" "}
          {addressDialogType === "edit" && "Edit address"}{" "}
        </DialogTitle>
        <DialogContent>
          {addressDialogType === "add" && (
            <Formik
              initialValues={{
                address: "",
                customer_id: "demo-customer",
                first_name: "",
                surname: "",
                phone_number_1: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  if (values.address === "") {
                    dispatch({
                      type: "FEEDBACK",
                      payload: {
                        severity: "error",
                        title: "Address is required! ",
                        body: ``,
                      },
                    });
                  } else if (values.first_name === "") {
                    dispatch({
                      type: "FEEDBACK",
                      payload: {
                        severity: "error",
                        title: "First name is required! ",
                        body: ``,
                      },
                    });
                  } else if (values.surname === "") {
                    dispatch({
                      type: "FEEDBACK",
                      payload: {
                        severity: "error",
                        title: "Surname is required! ",
                        body: ``,
                      },
                    });
                  } else if (values.phone_number_1 === "") {
                    dispatch({
                      type: "FEEDBACK",
                      payload: {
                        severity: "error",
                        title: "Phone number is required! ",
                        body: ``,
                      },
                    });
                  } else {
                    dispatch(Addaddress(values));
                  }
                }, 1000);
              }}
            >
              {({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Form>
                  {isSubmitting && <LinearProgress />}
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <Field
                        component={TextField}
                        name="first_name"
                        fullWidth
                        placeholder="Enter first name"
                        type="text"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Field
                        component={TextField}
                        name="surname"
                        fullWidth
                        placeholder="Enter Surname"
                        type="text"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                      <Field
                        name="address"
                        component={Autocomplete}
                        id="addaddress"
                        required
                        getOptionLabel={(option: any) =>
                          typeof option === "string"
                            ? option
                            : option.description
                        }
                        filterOptions={(x: any) => x}
                        options={options}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={value}
                        getOptionSelected={(option: any, value: any) =>
                          option === value
                        }
                        onChange={(event: any, newValue: PlaceType | null) => {
                          setOptions(
                            newValue ? [newValue, ...options] : options
                          );
                          setValue(newValue);
                        }}
                        onInputChange={(event: any, newInputValue: any) => {
                          setInputValue(newInputValue);
                          setFieldValue("address", newInputValue, false);
                        }}
                        renderInput={(params: any) => (
                          <MyTextField
                            {...params}
                            label="Enter the address here"
                            fullWidth
                          />
                        )}
                        renderOption={(option: any) => {
                          const matches =
                            option.structured_formatting
                              .main_text_matched_substrings;
                          const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match: any) => [
                              match.offset,
                              match.offset + match.length,
                            ])
                          );

                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon className={styles.icon} />
                              </Grid>
                              <Grid item xs>
                                {parts.map((part: any, index: number) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {option.structured_formatting.secondary_text}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                      <Field
                        component={PhoneInput}
                        inputProps={{
                          name: "phone_number_1",
                        }}
                        country={"ng"}
                        enableSearch={true}
                        value={values.phone_number_1}
                        onChange={(phone: string) =>
                          setFieldValue("phone_number_1", phone, false)
                        }
                        required
                      />
                    </Grid>
                  </Grid>
                  <div style={{ marginTop: 50 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      Add
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {addressDialogType === "edit" && (
            <Formik
              initialValues={{
                address: customeraddress.address,
                customer_id: customeraddress.customer_id,
                first_name: customeraddress.first_name,
                surname: customeraddress.surname,
                phone_number_1: customeraddress.phone_number_1,
                address_uuid: customeraddress.address_uuid,
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  if (values.address === "") {
                    dispatch({
                      type: "FEEDBACK",
                      payload: {
                        severity: "error",
                        title: "Address is required! ",
                        body: ``,
                      },
                    });
                  } else if (values.first_name === "") {
                    dispatch({
                      type: "FEEDBACK",
                      payload: {
                        severity: "error",
                        title: "First name is required! ",
                        body: ``,
                      },
                    });
                  } else if (values.surname === "") {
                    dispatch({
                      type: "FEEDBACK",
                      payload: {
                        severity: "error",
                        title: "Surname is required! ",
                        body: ``,
                      },
                    });
                  } else if (values.phone_number_1 === "") {
                    dispatch({
                      type: "FEEDBACK",
                      payload: {
                        severity: "error",
                        title: "Phone number is required! ",
                        body: ``,
                      },
                    });
                  } else {
                    dispatch(Editaddress(values));
                  }
                }, 1000);
              }}
            >
              {({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Form>
                  {isSubmitting && <LinearProgress />}
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <Field
                        component={TextField}
                        name="first_name"
                        fullWidth
                        placeholder="Enter first name"
                        type="text"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Field
                        component={TextField}
                        name="surname"
                        fullWidth
                        placeholder="Enter Surname"
                        type="text"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                      <Field
                        name="address"
                        component={Autocomplete}
                        id="editaddress"
                        required
                        getOptionLabel={(option: any) =>
                          typeof option === "string"
                            ? option
                            : option.description
                        }
                        filterOptions={(x: any) => x}
                        options={options_2}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={value_2}
                        getOptionSelected={(option: any, value: any) =>
                          option === value
                        }
                        onChange={(event: any, newValue: PlaceType | null) => {
                          setOptions_2(
                            newValue ? [newValue, ...options] : options
                          );
                          setValue_2(newValue);
                        }}
                        onInputChange={(event: any, newInputValue: any) => {
                          setInputValue_2(newInputValue);
                          setFieldValue("address", newInputValue, false);
                        }}
                        renderInput={(params: any) => (
                          <MyTextField
                            {...params}
                            label={values.address}
                            fullWidth
                            value={values.address}
                          />
                        )}
                        renderOption={(option: any) => {
                          const matches =
                            option.structured_formatting
                              .main_text_matched_substrings;
                          const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match: any) => [
                              match.offset,
                              match.offset + match.length,
                            ])
                          );

                          return (
                            <Grid container alignItems="center">
                              <Grid item>
                                <LocationOnIcon className={styles.icon} />
                              </Grid>
                              <Grid item xs>
                                {parts.map((part: any, index: number) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {option.structured_formatting.secondary_text}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                      <Field
                        component={PhoneInput}
                        inputProps={{
                          name: "phone_number_1",
                        }}
                        country={"ng"}
                        enableSearch={true}
                        value={values.phone_number_1}
                        onChange={(phone: string) =>
                          setFieldValue("phone_number_1", phone, false)
                        }
                        required
                      />
                    </Grid>
                  </Grid>

                  <div style={{ marginTop: 50 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
      <AppBar position="fixed" style={{ backgroundColor: "#00e676" }}>
        <Toolbar>
          <div className={styles.logo_wrapper}>
            <img src={Logo} alt="ekioja" />
          </div>

          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={styles.title}
          >
            Checkout
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={styles.section_gutter} />
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2}></Grid>
          <Grid item xs={12} lg={8}>
            <Card className={styles.card_wrappers}>
              <CardContent>
                <Typography>1. ADDRESS DETAILS</Typography>
                <Divider />
                <Typography>
                  {!_.isEmpty(customeraddress) && customeraddress.first_name}{" "}
                  {!_.isEmpty(customeraddress) && customeraddress.surname}
                </Typography>
                <Typography>
                  {!_.isEmpty(customeraddress) && customeraddress.address}
                </Typography>
                <Typography>
                  {!_.isEmpty(customeraddress) &&
                    customeraddress.phone_number_1}
                </Typography>
                <Typography>
                  {_.isEmpty(customeraddress) &&
                    "You need to add a delivery address where you want the package to be shipped"}
                </Typography>
              </CardContent>
              <CardActions>
                {!_.isEmpty(customeraddress) && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      handleClickOpen();
                      setAddressDialogType("edit");
                    }}
                  >
                    Edit address
                  </Button>
                )}
                {!_.isEmpty(customeraddress) && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      dispatch(RemoveAddress(customeraddress.address_uuid))
                    }
                  >
                    Remove address
                  </Button>
                )}
                {_.isEmpty(customeraddress) && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      handleClickOpen();
                      setAddressDialogType("add");
                    }}
                  >
                    Add address
                  </Button>
                )}
              </CardActions>
            </Card>
            <Card className={styles.card_wrappers}>
              <CardContent>
                <Typography>2. DELIVERY METHOD</Typography>
                <Divider />
                <Typography>Door Delivery</Typography>
                <Typography>
                  Delivered between{" "}
                  {moment(moment().toDate(), "YYYY-MM-DD")
                    .add(5, "days")
                    .format("LL")}{" "}
                  and{" "}
                  {moment(moment().toDate(), "YYYY-MM-DD")
                    .add(10, "days")
                    .format("LL")}{" "}
                  for{" "}
                  <NumberFormat
                    value={productsData.length * 950}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </Typography>
                <Divider />
                <Typography variant="subtitle2" color="initial">
                  SHIPMENT DETAILS
                </Typography>
                {_.map(productsData, (item, index) => (
                  <Fragment key={index + "key"}>
                    <Typography variant="caption" color="initial">
                      Shipment {index + 1} of {productsData.length}
                    </Typography>
                    <div className={styles.shipment_payment_details_field}>
                      <Typography variant="subtitle1" color="initial">
                        {item.cart_quantity}X {item.product_name}
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        <NumberFormat
                          value={ item.cart_subtotal}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      </Typography>
                    </div>
                  </Fragment>
                ))}
                <Divider />
                <div
                  className={styles.shipment_payment_details_field}
                  style={{ marginTop: 50 }}
                >
                  <Typography variant="subtitle2" color="textSecondary">
                    Subtotal
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    <NumberFormat
                      value={totalSubTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </Typography>
                </div>
                <div className={styles.shipment_payment_details_field}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Delivery fee
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    <NumberFormat
                      value={productsData.length * 950}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </Typography>
                </div>
                <div className={styles.shipment_payment_details_field}>
                  <Typography variant="h6" color="textSecondary">
                    Total
                  </Typography>
                  <Typography variant="h5" className={styles.total_price}>
                    <NumberFormat
                      value={productsData.length * 950 + totalSubTotal}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </Typography>
                </div>
              </CardContent>
            </Card>
            <Card className={styles.card_wrappers}>
              <CardContent>
                <Typography>3. PAYMENT METHOD</Typography>
                <Divider />
                <Typography>Stay Safe, go cashless with Paystack.</Typography>
              </CardContent>
            </Card>

            <Formik
              initialValues={{
                order_id: orderNumber(),
                customer_id: "demo-customer",
                customer_email: "kayodematthew56@gmail.com",
                delivery_address: "",
                deliver_from_date: "",
                deliver_to_date: "",
                deliver_method: "door delivery",
                recipient: {
                  customer: "",
                  phonenumber: "",
                },
                new: true,
                store_ids: [""],
                store_email: [""],
                items: [{}],
                reciept:refNum,
                payment_total:productsData.length * 950 + totalSubTotal,
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  if (typeof customeraddress.address !== "undefined")
                    values.delivery_address = customeraddress.address;

                  values.deliver_from_date = moment(
                    moment().toDate(),
                    "YYYY-MM-DD"
                  )
                    .add(5, "days")
                    .format("LL");
                  values.deliver_to_date = moment(
                    moment().toDate(),
                    "YYYY-MM-DD"
                  )
                    .add(10, "days")
                    .format("LL");
                  if (typeof customeraddress.first_name !== "undefined")
                    values.recipient.customer =
                      customeraddress.first_name +
                      " " +
                      customeraddress.surname;
                  if (typeof customeraddress.phone_number_1 !== "undefined")
                    values.recipient.phonenumber =
                      customeraddress.phone_number_1;
                  values.store_ids = [...storeIds];
                  values.store_email = [...storeEmails];
                  values.items = [...orderData];

                  if (values.delivery_address === "") {
                    dispatch({
                      type: "FEEDBACK",
                      payload: {
                        severity: "error",
                        title: "Delivery address is required! ",
                        body: ``,
                      },
                    });
                  } else {
                    dispatch(NewOrder(values));
                  }
                }, 1000);
              }}
            >
              {({ submitForm, isSubmitting, values, setFieldValue }) => (
                <Form>
                  {isSubmitting && <LinearProgress />}
                  <Card className={styles.card_wrappers}>
                    <CardContent>
                    <PaystackConsumer
                              reference={refNum}
                              email={'muhammedopesusi@gmail.com'}
                              amount={productsData.length * 950 + totalSubTotal}
                              //@ts-ignore
                              publicKey={
                                process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_LEY
                              }
                              text="Ekioja "
                              onSuccess={() => {
                                submitForm();
                              }}
                              onClose={() => {}}
                            >
                              {({ initializePayment }: any) => (
                                 <Button
                                 variant="contained"
                                 style={{
                                   width: "100%",
                                   backgroundColor: "greenyellow",
                                 }}
                                 disabled={isSubmitting}
                                 onClick={() => {
                                  initializePayment();
                                }}
                               >
                                 Confirm order
                               </Button>
                              )}
                            </PaystackConsumer>
                     
                    </CardContent>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12} lg={2}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default MarketPLaceCheckout;
