import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const cancelOrder = (values: any) => {
	const cancelOrderPromise = firebase
		.firestore()
		.collection("OrdersTimeline")
		.doc(values.id)
		.set(
			{
				order_placed: {
					current: false,
				},
				pending_confirmation: {
					current: false,
				},
				waiting_to_be_shipped: {
					current: false,
				},
				out_for_delivery: {
					current: false,
				},
				delivered: {
					current: false,
				},
				canceled: {
					status: true,
					current: true,
					reason: values.reason,
					persona: values.persona,
					persona_id: values.persona_id,
					persona_name: values.persona_name,
					customer_id: values.customer_id,
					date: firebase.firestore.FieldValue.serverTimestamp(),
				},
			},
			{ merge: true }
		);

	//deduct from ekioja

	const ekiojawalletDocRef = firebase
		.firestore()
		.collection("Wallets")
		.doc("4Ip8FlLBP9E7ITWnjOO7");
	//run transaction on Ekioja balance
	const ekiojatransactionpromise = firebase
		.firestore()
		.runTransaction((transaction: any) => {
			return transaction.get(ekiojawalletDocRef).then((transactionDoc: any) => {
				if (!transactionDoc.exists) {
				}

				let newIncome = transactionDoc.data().order - values.amount;

				transaction.update(walletDocRef, {
					order: newIncome,
				});
			});
		});
	//credit customer

	const walletDocRef = firebase
		.firestore()
		.collection("Wallets")
		.doc(values.customer_id);
	//run transaction on Ekioja balance
	const transactionpromise = firebase
		.firestore()
		.runTransaction((transaction: any) => {
			return transaction.get(walletDocRef).then((transactionDoc: any) => {
				if (!transactionDoc.exists) {
				}

				let newIncome = transactionDoc.data().income + values.amount;

				transaction.update(walletDocRef, {
					income: newIncome,
				});
			});
		});

	//record transaction

	const cancelTransactionpromise = firebase
		.firestore()
		.collection("Transactions")
		.doc()
		.set({
			mode: "CANCEL_ORDER",
			payer: "ekioja-super",
			payee: values.customer_id,
			amount: values.amount,
			payer_name: values.recipient.customer,
			metaData: {
				order_timeline_ref: values.id,
				reason: values.reason,
				persona: values.persona,
				persona_id: values.persona_id,
				persona_name: values.persona_name,
			},
			type: ["DEBIT_EKIOJA", "CREDIT_CUSTOMER"],
			class: "CUSTOMER",
			status: "SUCCESS",
			date: firebase.firestore.FieldValue.serverTimestamp(),
		});

	const allPromise = Promise.all([
		cancelOrderPromise,
		transactionpromise,
		cancelTransactionpromise,
		ekiojatransactionpromise,
	]);

	return allPromise;
};

const useCancelOrder = () => {
	return useMutation(cancelOrder);
};

export default useCancelOrder;
