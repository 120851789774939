
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const ApproveStore = (store_id:string, approved_by_id:any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();


        return firebase.firestore().collection('Stores').doc(store_id).set({
           approved:true,
           approved_by:approved_by_id,

        },{merge:true}).then(() => {
            dispatch({ type: 'FEEDBACK', payload: { severity: 'success', title: 'Your have sucessfully approved this store', body: '' } })
                
            }); 

    }