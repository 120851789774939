import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles({
	root: {
		marginTop: 6,
		width: "100%",
		height: 380,
		overflowY: "auto",
		overflowX: "hidden",
	},
});

interface Props {
	data: any;
	status: any;
	value: string;
	onChange: any;
}
const VendorListView = (props: Props) => {
	const styles = useStyles();
	return (
		<div className={styles.root}>
			<FormControl component="fieldset">
				<RadioGroup
					aria-label="brand"
					name="brand"
					value={props.value}
					onChange={props.onChange}
				>
					{props.status.isSuccess &&
						props.data.map(
							(item: any) =>
								item.products_count > 0 && (
									<FormControlLabel
										value={item.store_id}
										control={<Radio />}
										label={item.store_name}
										key={item.store_id}
									/>
								)
						)}
				</RadioGroup>
			</FormControl>
			{props.status.isLoading && (
				<>
					<Skeleton variant="rect" width="100%" height={40} />
					<div style={{ marginBottom: 2 }} />
					<Skeleton variant="rect" width="100%" height={40} />
					<div style={{ marginBottom: 2 }} />
					<Skeleton variant="rect" width="100%" height={40} />
					<div style={{ marginBottom: 2 }} />
					<Skeleton variant="rect" width="100%" height={40} />
					<div style={{ marginBottom: 2 }} />
					<Skeleton variant="rect" width="100%" height={40} />
					<div style={{ marginBottom: 2 }} />
					<Skeleton variant="rect" width="100%" height={40} />
					<div style={{ marginBottom: 2 }} />
					<Skeleton variant="rect" width="100%" height={40} />
					<div style={{ marginBottom: 2 }} />
					<Skeleton variant="rect" width="100%" height={40} />
				</>
			)}
		</div>
	);
};

export default VendorListView;
