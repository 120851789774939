import React, { Fragment, useState, useEffect } from "react";
//components
import Grid from "@material-ui/core/Grid";
import {
	makeStyles,
	Theme,
	createStyles,
	withStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import { green } from "@material-ui/core/colors";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import InputAdornment from "@material-ui/core/InputAdornment";

//icons
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

//formik
import { Field, Form, Formik, ErrorMessage } from "formik";
import { TextField, Select, Checkbox } from "formik-material-ui";

//yup
import * as Yup from "yup";

//wysiy editor
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from 'draftjs-to-html';
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CheckboxProps } from "@material-ui/core/Checkbox";
// import { DateTimePicker } from "formik-material-ui-pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import NumberFormat from "react-number-format";
import { FormHelperText } from "@material-ui/core";

//lodash
import _ from "lodash";

//imgae uploader
import ImageUploader from "../components/ImageUploader";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//react - redux
// import { useSelector } from "react-redux";

//firestoreconnect
// import { useFirestoreConnect } from "react-redux-firebase";

//actions
// import { AddNewProduct } from "../store/actions/store/addNewProduct";

//loading screen
import LoadingScreen from "../components/LoadingScreen";
import GetImageFromFireBase from "../components/GetImageFromFirebase";
//navigation hook
import { useNavigate } from "../hooks/navigate";

//routes
import * as ROUTES from "../constants/routes";

import { enGB } from "date-fns/locale";
//@ts-ignore
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";

import useEditProduct from "../hooks/Product/useEditProduct";
import useGetEkiojaNiches from "../hooks/useGetEkiojaNiches";
import useGetProduct from "../hooks/Product/useGetProduct";

import LoadingButton from "../components/LoadingButton";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//link
import { useParams } from "react-router-dom";

//interface
// import { IProducts } from "../ts/interfaces/product";

const GreenCheckbox = withStyles({
	root: {
		color: green[100],
		"&$checked": {
			color: green[600],
		},
	},
	checked: {},
})((props: CheckboxProps) => (
	<Field component={Checkbox} color="default" {...props} />
));

interface NumberFormatCustomProps {
	inputRef: (instance: NumberFormat | null) => void;
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			prefix="₦"
		/>
	);
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `edit-new-product-tab-${index}`,
		"aria-controls": `edit-new-product-${index}`,
	};
}

const AntTabs = withStyles((theme: Theme) =>
	createStyles({
		root: {
			borderBottom: "1px solid #e8e8e8",
		},
		indicator: {
			backgroundColor: theme.palette.primary.main,
		},
	})
)(Tabs);

const AntTab = withStyles((theme: Theme) =>
	createStyles({
		root: {
			textTransform: "none",
			minWidth: 72,
			fontWeight: theme.typography.fontWeightRegular,
			marginRight: theme.spacing(4),
			fontFamily: [
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
			"&:hover": {
				color: theme.palette.secondary,
				opacity: 1,
			},
			"&$selected": {
				color: theme.palette.primary,
				fontWeight: theme.typography.fontWeightMedium,
			},
			"&:focus": {
				color: "green",
			},
		},
		selected: {},
	})
)((props: StyledTabProps) => <Tab {...props} />);

interface StyledTabsProps {
	value: number;
	onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

interface StyledTabProps {
	label: string;
}

//validation schema
const AddNewProductValidationSchema = Yup.object().shape({
	product_name: Yup.string()
		.min(5, "Too short!")
		.required("The product name is required!"),

	product_short_description: Yup.string()
		.min(100, "Too short! a minimum of 100 characters is required")
		.required("The product summary is required!"),

	product_type: Yup.string()
		.oneOf(["simple", "variation"], "You have to choose either yes or no")
		.required("Please answer this question!"),

	asking_price: Yup.number()
		.min(500, "The minimum value is 500 naira")
		.required("Your asking price price is required"),
});

//styles

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		actionsContainer: {
			marginBottom: theme.spacing(2),
		},
		resetContainer: {
			padding: theme.spacing(3),
		},
		error_msg: {
			color: "red",
		},
		image_uploader_wrapper: {
			display: "flex",
			width: "90%",
			padding: 10,
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		image_uploader: {
			width: "90%",
			height: "auto",
			backgroundColor: "grey",
			borderRadius: 10,
		},
		product_image_uploader_wrapper: {
			width: 350,
			height: 350,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		product_image_uploader: {
			width: 300,
			height: 300,
		},
		product_image_preview: {
			width: 300,
			height: 300,
			position: "relative",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& img": {
				maxWidth: 200,
				height: 200,
			},
		},
		remove_image_action_wrapper: {
			position: "absolute",
			top: 10,
			right: 10,
		},
		promote_option_wrapper: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
	})
);
interface Props {}

const steps = 5;

const calculateEkiojaPrice = (value: string) => {
	const fourPercent = Math.floor(parseInt(value) * 0.04);
	const result = Math.floor(parseInt(value) + fourPercent);

	if (isNaN(result)) return 0;
	return result;
};

const convertProductNameToPermalink = (sentence: string) => {
	const result_transform_to_lowercase = _.lowerCase(sentence)
		.split(" ")
		.join("-");
	return result_transform_to_lowercase;
};

const convertProductNameToPlainName = (sentence: string) => {
	const result_transform_to_lowercase = _.lowerCase(sentence)
		.split(" ")
		.join(" ")
		.replace(/\W/g, "");

	return result_transform_to_lowercase;
};

// const removeImage = async (image_name: string) => {
//     const result = await firebase.storage().ref().child(`images/${image_name}`).delete();
//     return result
// }

const EditProduct = (props: Props) => {
	const styles = useStyles();
	const { id } = useParams<any>();
	const { navigateToLink } = useNavigate();
	const [activeStep, setActiveStep] = React.useState(0);
	const [tabPanelIndex, settabPanelIndex] = useState(0);
	const [shortDecriptionWordsCount, setShortDecriptionWordsCount] = useState(0);
	const [showAttribute2, setShowAttribute2] = useState(false);
	const [showAttribute3, setShowAttribute3] = useState(false);
	const [showAttribute4, setShowAttribute4] = useState(false);
	const [showAttribute5, setShowAttribute5] = useState(false);

	// const profile = useSelector((state: any) => state.firebase.profile);

	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);

	const [EditProductMutate, EditProductInfo] = useEditProduct();
	const ekiojaNichesInfo = useGetEkiojaNiches();
	const productInfo = useGetProduct(id);

	const content = {
		entityMap: {},
		blocks: [
			{
				key: "637gr",
				text: "Product full description.",
				type: "unstyled",
				depth: 0,
				inlineStyleRanges: [],
				entityRanges: [],
				data: {},
			},
		],
	};
	let contentState = convertFromRaw(content);

	const [productDescription, setProductDescription] = useState({
		editorState: EditorState.createWithContent(contentState),
	});

	// useFirestoreConnect([
	//   {
	//     collection: "Stores",
	//     doc: profile.entity,
	//   },
	// ]);

	// const store = useSelector((state: any) => state.firestore.ordered.Stores);

	useEffect(() => {
		if (!productInfo.isLoading) {
			// setEndDate(moment(productInfo.data?.discount_price_to_date).toDate());
			// setStartDate(moment(productInfo.data?.discount_price_from_date).toDate());

			let contentState2 = convertFromRaw(
				JSON.parse(productInfo.data?.product_full_description)
			);

			setProductDescription({
				editorState: EditorState.createWithContent(contentState2),
			});
			// contentState: convertFromRaw(JSON.parse(productFirestore[0].product_full_description))
		}
	}, [productInfo]);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// const handleReset = (action: any) => {
	//   setActiveStep(0);
	//   action();
	//   setProductDescription({
	//     editorState: EditorState.createEmpty(),
	//   });
	// };

	const handleTabPanelIndexChange = (
		event: React.ChangeEvent<{}>,
		newValue: number
	) => {
		settabPanelIndex(newValue);
	};

	if (productInfo.isLoading) return <LoadingScreen />;

	return (
		<Fragment>
			<DashBoardLayout menuIndex={1}>
				<ToastContainer />
				<Grid container>
					<Grid item xs={12} lg={1}></Grid>
					<Grid item xs={12} lg={10}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								paddingTop: 10,
							}}
						>
							<Breadcrumbs
								aria-label="breadcrumb"
								separator={<NavigateNextIcon fontSize="small" />}
							>
								<Button
									style={{ textTransform: "capitalize" }}
									onClick={() => {
										navigateToLink.push(ROUTES.VIEWPRODUCTS, {
											query: ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS,
										});
									}}
								>
									<Typography color="textPrimary"> Back</Typography>
								</Button>

								<Typography color="textPrimary"> Edit Product</Typography>
							</Breadcrumbs>

							<Button
								variant="contained"
								color="primary"
								style={{ color: "#fff" }}
								aria-label="edit-vendor-details"
								size="large"
								onClick={() => {
									navigateToLink.push(ROUTES.VIEW_PRODUCT_DETAILS, {
										id: productInfo.data?.product_id,
									});
								}}
							>
								View
							</Button>
						</div>

						<Divider />
						<br />
						<Formik
							initialValues={{
								entity: productInfo.data?.entity,
								added_by: productInfo.data?.added_by,
								added_by_name: productInfo.data?.added_by_name,
								product_id: productInfo.data?.product_id,
								product_name: productInfo.data?.product_name,
								product_category: productInfo.data?.product_category,
								product_permalink: productInfo.data?.product_permalink,
								plainName: productInfo.data?.plainName,
								product_full_description: "",
								product_short_description:
									productInfo.data?.product_short_description,
								product_type: productInfo.data?.product_type,
								asking_price: productInfo.data?.asking_price,
								regular_price: productInfo.data?.regular_price,
								discount_price: productInfo.data?.discount_price,
								discount_percent: productInfo.data?.discount_percent,
								enable_discount: productInfo.data?.enable_discount,
								enable_discount_schedule:
									productInfo.data?.enable_discount_schedule,
								discount_price_from_date:
									productInfo.data?.discount_price_from_date,
								discount_price_to_date:
									productInfo.data?.discount_price_to_date,
								visible_on_market: productInfo.data?.visible_on_market,
								product_stock_keeping_unit:
									productInfo.data?.product_stock_keeping_unit,
								enable_stock_management:
									productInfo.data?.enable_stock_management,
								stock_status: productInfo.data?.stock_status,
								stock_quantity: productInfo.data?.stock_quantity,
								low_stock_threshold: productInfo.data?.low_stock_threshold,
								dimension_unit: productInfo.data?.dimension_unit,
								dimension_length: productInfo.data?.dimension_length,
								dimension_breadth: productInfo.data?.dimension_breadth,
								dimension_height: productInfo.data?.dimension_height,
								weight_unit: productInfo.data?.weight_unit,
								weight: productInfo.data?.weight,

								product_attribute_1_title:
									productInfo.data?.product_attribute_1_title,
								product_attribute_1_value:
									productInfo.data?.product_attribute_1_value,
								product_attribute_2_title:
									productInfo.data?.product_attribute_2_title,
								product_attribute_2_value:
									productInfo.data?.product_attribute_2_value,
								product_attribute_3_title:
									productInfo.data?.product_attribute_3_title,
								product_attribute_3_value:
									productInfo.data?.product_attribute_3_value,
								product_attribute_4_title:
									productInfo.data?.product_attribute_4_title,
								product_attribute_4_value:
									productInfo.data?.product_attribute_4_value,
								product_attribute_5_title:
									productInfo.data?.product_attribute_5_title,
								product_attribute_5_value:
									productInfo.data?.product_attribute_5_value,

								featured: productInfo.data?.featured,
								promoted: productInfo.data?.promoted,
								image_1: productInfo.data?.image_1,
								image_2: productInfo.data?.image_2,
								image_3: productInfo.data?.image_3,
								image_4: productInfo.data?.image_4,
							}}
							validationSchema={AddNewProductValidationSchema}
							onSubmit={(values, { setSubmitting }) => {
								setTimeout(() => {
									setSubmitting(false);
									values.product_full_description = JSON.stringify(
										convertToRaw(
											productDescription.editorState.getCurrentContent()
										)
									);
									if (productInfo.data?.discount_price_from_date! == null) {
										values.discount_price_from_date = startDate;
									}
									if (productInfo.data?.discount_price_to_date! == null) {
										values.discount_price_to_date = endDate;
									}

									EditProductMutate(values, {
										onSuccess: () => {
											toast.success("Product Edited successfully!", {
												position: "top-right",
												autoClose: 5000,
												hideProgressBar: false,
												closeOnClick: true,
											});
											setTimeout(() => {
												navigateToLink.push(ROUTES.VIEWPRODUCTS, {
													query: ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS,
												});
											}, 1500);
										},
										onError: () => {
											toast.error("Oops! An error occured!  Try agin", {
												position: "top-right",
												autoClose: 5000,
												hideProgressBar: false,
												closeOnClick: true,
											});
										},
									});

									// dispatch(AddNewProduct(values));
								}, 1000);
							}}
						>
							{({
								submitForm,
								isSubmitting,
								errors,
								touched,
								values,
								setFieldValue,
								resetForm,
							}) => (
								<Form>
									<Stepper activeStep={activeStep} orientation="vertical">
										<Step key="step-one">
											<StepLabel>What is the name of the product?</StepLabel>
											<StepContent>
												<Grid container>
													<Grid item xs={12} lg={7}>
														<Field
															component={TextField}
															type="text"
															name="product_name"
															fullWidth
															value={values.product_name}
															onChange={(
																e: React.ChangeEvent<HTMLInputElement>
															) => {
																setFieldValue(
																	"product_name",
																	e.target.value,
																	true
																);
																setFieldValue(
																	"product_permalink",
																	convertProductNameToPermalink(e.target.value),
																	false
																);
																setFieldValue(
																	"plainName",
																	convertProductNameToPlainName(e.target.value),
																	false
																);
															}}
														/>
														{values.product_permalink && (
															<Typography variant="caption">
																ekioja/{values.product_permalink}
															</Typography>
														)}

														<br />
													</Grid>
													<Grid item xs={12} lg={5}></Grid>
												</Grid>
												{/* next navigation */}
												<div className={styles.actionsContainer}>
													<div>
														{!errors.product_name ? (
															<Button
																variant="contained"
																color="primary"
																onClick={handleNext}
																className={styles.button}
															>
																{activeStep === steps - 1 ? "Finish" : "Next"}
															</Button>
														) : null}
													</div>
												</div>
												{/* end next navigation */}
											</StepContent>
										</Step>

										<Step key="step-two">
											<StepLabel>
												Write a short description (Summary) about the product.
											</StepLabel>
											<StepContent>
												<Grid container>
													<Grid item xs={12} lg={7}>
														<Field
															component={TextField}
															name="product_short_description"
															type="text"
															variant="outlined"
															helperText={`${shortDecriptionWordsCount} characters typed | ${
																230 - shortDecriptionWordsCount
															}/230 characters remaining`}
															multiline
															rows={6}
															fullWidth
															value={values.product_short_description}
															onInput={(
																e: React.ChangeEvent<HTMLInputElement>
															) => {
																if (e.target.value.length >= 230) {
																	let value = e.target.value.slice(0, 230);
																	setFieldValue(
																		"product_short_description",
																		value,
																		true
																	);

																	setShortDecriptionWordsCount(value.length);
																}
															}}
															onChange={(
																e: React.ChangeEvent<HTMLInputElement>
															) => {
																if (e.target.value.length >= 230) {
																	let value = e.target.value.slice(0, 230);
																	setFieldValue(
																		"product_short_description",
																		value,
																		true
																	);

																	setShortDecriptionWordsCount(value.length);
																} else if (e.target.value.length < 230) {
																	setFieldValue(
																		"product_short_description",
																		e.target.value,
																		true
																	);

																	setShortDecriptionWordsCount(
																		e.target.value.length
																	);
																}
															}}
															onPaste={(
																e: React.ClipboardEvent<HTMLInputElement>
															) => {
																if (
																	e.clipboardData.getData("Text").length >= 230
																) {
																	let value = e.clipboardData
																		.getData("Text")
																		.slice(0, 230);
																	setFieldValue(
																		"product_short_description",
																		value,
																		true
																	);

																	setShortDecriptionWordsCount(value.length);
																}
															}}
														/>
														<ErrorMessage
															name="product_short_description"
															render={(msg) => (
																<Typography
																	variant="caption"
																	component="span"
																	className={styles.error_msg}
																>
																	{msg}
																</Typography>
															)}
														/>
													</Grid>
													<Grid item xs={12} lg={5}></Grid>
												</Grid>

												{/* next navigation */}
												<div className={styles.actionsContainer}>
													<div>
														<Button
															disabled={activeStep === 0}
															onClick={handleBack}
															className={styles.button}
														>
															Back
														</Button>
														{!errors.product_short_description ? (
															<Button
																variant="contained"
																color="primary"
																onClick={handleNext}
																className={styles.button}
															>
																{activeStep === steps - 1 ? "Finish" : "Next"}
															</Button>
														) : null}
													</div>
												</div>
												{/* end next navigation */}
											</StepContent>
										</Step>

										<Step key="step-three">
											<StepLabel>
												Write a comprehensive description (Detailed Information)
												about the product.
											</StepLabel>
											<StepContent>
												<Grid container>
													<Grid item xs={12} lg={8}>
														<Editor
															editorState={productDescription.editorState}
															editorStyle={{
																height: "300px",
																border:
																	JSON.stringify(
																		convertToRaw(
																			productDescription.editorState.getCurrentContent()
																		)
																	).length <= 1132
																		? "2px red solid"
																		: "2px green solid",
															}}
															onEditorStateChange={(editorState: any) => {
																setProductDescription({
																	editorState,
																});
															}}
														/>
														{JSON.stringify(
															convertToRaw(
																productDescription.editorState.getCurrentContent()
															)
														).length <= 1132 && (
															<Typography
																variant="caption"
																component="span"
																className={styles.error_msg}
															>
																Too short or editor is empty! add more
																information
															</Typography>
														)}
													</Grid>
													<Grid item xs={12} lg={4}></Grid>
												</Grid>

												{/* next navigation */}
												<div className={styles.actionsContainer}>
													<div>
														<Button
															disabled={activeStep === 0}
															onClick={handleBack}
															className={styles.button}
														>
															Back
														</Button>
														{JSON.stringify(
															convertToRaw(
																productDescription.editorState.getCurrentContent()
															)
														).length > 1132 ? (
															<Button
																variant="contained"
																color="primary"
																onClick={handleNext}
																className={styles.button}
															>
																{activeStep === steps - 1 ? "Finish" : "Next"}
															</Button>
														) : null}
													</div>
												</div>
												{/* end next navigation */}
											</StepContent>
										</Step>

										<Step key="step-four">
											<StepLabel>
												Does this product have different variations ?
											</StepLabel>
											<StepContent>
												<Grid container>
													<Grid item xs={12} lg={7}>
														<Field
															component={Select}
															name="product_type"
															inputProps={{
																id: "product_type",
															}}
															style={{ width: "50%" }}
															value={values.product_type}
															onChange={(
																e: React.ChangeEvent<HTMLInputElement>
															) => {
																setFieldValue(
																	"product_type",
																	e.target.value,
																	true
																);
															}}
														>
															<MenuItem value="nill">choose</MenuItem>
															<MenuItem value="simple">No</MenuItem>
															<MenuItem value="variation">Yes</MenuItem>
														</Field>
														<br />
														<ErrorMessage
															name="product_type"
															render={(msg) => (
																<Typography
																	variant="caption"
																	component="span"
																	className={styles.error_msg}
																>
																	{msg}
																</Typography>
															)}
														/>
													</Grid>
													<Grid item xs={12} lg={5}></Grid>
												</Grid>

												{/* next navigation */}
												<div className={styles.actionsContainer}>
													<div>
														<Button
															disabled={activeStep === 0}
															onClick={handleBack}
															className={styles.button}
														>
															Back
														</Button>
														{!errors.product_type ? (
															<Button
																variant="contained"
																color="primary"
																onClick={handleNext}
																className={styles.button}
															>
																{activeStep === steps - 1 ? "Finish" : "Next"}
															</Button>
														) : null}
													</div>
												</div>
												{/* end next navigation */}
											</StepContent>
										</Step>

										<Step key="step-five">
											<StepLabel> Product data</StepLabel>
											<StepContent>
												<Fragment key="simple-product-data">
													<AntTabs
														value={tabPanelIndex}
														onChange={handleTabPanelIndexChange}
														aria-label="add-product-data-tab"
													>
														<AntTab label="General" {...a11yProps(0)} />
														<AntTab label="Inventory" {...a11yProps(1)} />
														<AntTab
															label="Shipping (Required)"
															{...a11yProps(2)}
														/>
														<AntTab label="Attributes" {...a11yProps(3)} />
														<AntTab label="Image" {...a11yProps(4)} />
													</AntTabs>
													<TabPanel value={tabPanelIndex} index={0}>
														<Grid container>
															<Grid item xs={12} lg={7}>
																<Field
																	component={TextField}
																	name="asking_price"
																	fullWidth
																	type="text"
																	label="Your asking Price"
																	helperText="Set your asking price for the product."
																	InputProps={{
																		inputComponent: NumberFormatCustom as any,
																	}}
																	onChange={(
																		e: React.ChangeEvent<HTMLInputElement>
																	) => {
																		setFieldValue(
																			"regular_price",
																			calculateEkiojaPrice(e.target.value),
																			true
																		);
																		setFieldValue(
																			"asking_price",
																			e.target.value,
																			true
																		);
																	}}
																	required
																/>
																<ErrorMessage
																	name="asking_price"
																	render={(msg) => (
																		<Typography
																			variant="caption"
																			component="span"
																			className={styles.error_msg}
																		>
																			{msg}
																		</Typography>
																	)}
																/>
																<br />
																<Field
																	component={TextField}
																	name="regular_price"
																	fullWidth
																	type="text"
																	label="Regulated Price"
																	disabled
																	helperText={
																		values.regular_price !== "" &&
																		`You will get  ₦${
																			values.asking_price
																		} on Sale. ₦${Math.floor(
																			parseInt(values.asking_price) * 0.04
																		)} is  Ekioja's fee (4% of your asking price)`
																	}
																	InputProps={{
																		inputComponent: NumberFormatCustom as any,
																	}}
																/>
																<br />
																{values.enable_discount && (
																	<FormControlLabel
																		control={
																			<GreenCheckbox
																				checked={values.enable_discount}
																				inputProps={{
																					name: "enable_discount",
																				}}
																			/>
																		}
																		label="Enable discount percent?"
																	/>
																)}
																{values.enable_discount && (
																	<Grid container spacing={2}>
																		<Grid item lg={12}>
																			{/* <FormControl fullWidth>
                                        <InputLabel htmlFor="discount_percent">
                                          Discount Price ? if yes,Set the
                                          discount percent for this product.
                                        </InputLabel>
                                        <Field
                                          component={Select}
                                          disabled={
                                            touched.asking_price ? false : true
                                          }
                                          name="discount_percent"
                                          inputProps={{
                                            id: "discount_percent",
                                          }}
                                          value={values.discount_percent}
                                          onChange={(
                                            e: React.ChangeEvent<
                                              HTMLInputElement
                                            >
                                          ) => {
                                            setFieldValue(
                                              "discount_percent",
                                              e.target.value,
                                              true
                                            );
                                            setFieldValue(
                                              "discount_price",
                                              parseInt(values.regular_price) -
                                                (parseInt(e.target.value) /
                                                  100) *
                                                  parseInt(
                                                    values.regular_price
                                                  ),
                                              true
                                            );
                                          }}
                                        >
                                          <MenuItem value="None">None</MenuItem>
                                          {_.map(
                                            [
                                              5,
                                              10,
                                              15,
                                              20,
                                              25,
                                              30,
                                              35,
                                              40,
                                              45,
                                              50,
                                              55,
                                              60,
                                              65,
                                              70,
                                              75,
                                              80,
                                              85,
                                              90,
                                              95,
                                            ],
                                            (item) => (
                                              <MenuItem key={item} value={item}>
                                                {`${item} %`}
                                              </MenuItem>
                                            )
                                          )}
                                        </Field>
                                      </FormControl> */}

																			<Field
																				component={TextField}
																				name="discount_percent"
																				fullWidth
																				type="number"
																				label=" Discount Price ? if yes,Set the
                                          discount percent for this product."
																				helperText="Minimum is 2 % and Maximum is 95 %"
																				InputProps={{
																					endAdornment: (
																						<InputAdornment position="end">
																							%
																						</InputAdornment>
																					),
																				}}
																				inputProps={{
																					min: 2,
																					max: 95,
																				}}
																				value={values.discount_percent}
																				onChange={(
																					e: React.ChangeEvent<HTMLInputElement>
																				) => {
																					setFieldValue(
																						"discount_percent",
																						e.target.value,
																						true
																					);
																					setFieldValue(
																						"discount_price",
																						_.floor(
																							parseInt(values.regular_price) -
																								(parseInt(e.target.value) /
																									100) *
																									parseInt(values.regular_price)
																						),
																						true
																					);
																				}}
																			/>
																		</Grid>
																		<Grid item lg={12}>
																			{values.discount_percent !== null && (
																				<Typography
																					variant="subtitle2"
																					color="inherit"
																				>
																					{" "}
																					{`${values.discount_percent} %`} off{" "}
																					<NumberFormat
																						value={values.regular_price}
																						displayType={"text"}
																						thousandSeparator={true}
																						prefix={"₦"}
																					/>
																					=={" "}
																					<NumberFormat
																						value={_.floor(
																							parseInt(values.regular_price) -
																								(values.discount_percent /
																									100) *
																									parseInt(values.regular_price)
																						)}
																						displayType={"text"}
																						thousandSeparator={true}
																						prefix={"₦"}
																					/>
																				</Typography>
																			)}
																		</Grid>
																	</Grid>
																)}

																<br />

																{values.discount_percent !== null &&
																	values.enable_discount && (
																		<FormControlLabel
																			control={
																				<GreenCheckbox
																					checked={
																						values.enable_discount_schedule
																					}
																					inputProps={{
																						name: "enable_discount_schedule",
																					}}
																				/>
																			}
																			label="Enable discount price schedule?"
																		/>
																	)}

																<br />

																{values.enable_discount_schedule && (
																	<>
																		<Typography
																			variant="subtitle2"
																			color="inherit"
																		>
																			Schedule a Start and End Date for the
																			discount
																		</Typography>
																		<DateRangePicker
																			startDate={startDate}
																			endDate={endDate}
																			onStartDateChange={setStartDate}
																			onEndDateChange={setEndDate}
																			minimumDate={new Date()}
																			minimumLength={1}
																			format="dd MMM yyyy"
																			locale={enGB}
																		>
																			{({
																				startDateInputProps,
																				endDateInputProps,
																				focus,
																			}) => (
																				<div className="date-range">
																					<input
																						className={
																							"input" +
																							(focus === START_DATE
																								? " -focused"
																								: "")
																						}
																						{...startDateInputProps}
																						placeholder="Start date"
																					/>
																					<span className="date-range_arrow" />
																					<input
																						className={
																							"input" +
																							(focus === END_DATE
																								? " -focused"
																								: "")
																						}
																						{...endDateInputProps}
																						placeholder="End date"
																					/>
																				</div>
																			)}
																		</DateRangePicker>
																	</>
																)}

																{/* {values.enable_discount_schedule && (
                                  <Grid
                                    container
                                    spacing={4}
                                    // justifyContent="flex-start"
                                    // alignItems="center"
                                  >
                                    <Grid item>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <Field
                                          component={DateTimePicker}
                                          label="From... date"
                                          format="MM/dd/yyyy"
                                          name="discount_price_from_date"
                                          fullWidth
                                          disablePast
                                          clearable
                                          onChange={(date: any) =>
                                            console.log(
                                              "from date",
                                              moment(date).toISOString()
                                            )
                                          }
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>

                                    <Grid item>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <Field
                                          component={DateTimePicker}
                                          label="To... date"
                                          format="MM/dd/yyyy"
                                          disablePast
                                          name="discount_price_to_date"
                                          fullWidth
                                          clearable
                                          onChange={(date: any) =>
                                            console.log("to date", date)
                                          }
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                  </Grid>
                                )} */}

																<FormControl fullWidth>
																	<InputLabel htmlFor="product_category">
																		What is the product category?
																	</InputLabel>
																	<Field
																		component={Select}
																		name="product_category"
																		inputProps={{
																			id: "product_category",
																		}}
																	>
																		{ekiojaNichesInfo.status === "success" &&
																			ekiojaNichesInfo.data?.values.map(
																				(item: string) => (
																					<MenuItem key={item} value={item}>
																						{item}
																					</MenuItem>
																				)
																			)}
																	</Field>
																</FormControl>
																<br />
																{/* {store[0].ekioja_approved_add_product && (
                                  <FormControlLabel
                                    control={
                                      <GreenCheckbox
                                        checked={values.visible_on_market}
                                        inputProps={{
                                          name: "visible_on_market",
                                        }}
                                      />
                                    }
                                    label={
                                      values.visible_on_market
                                        ? "Should this product be visible on the marketplace right away? Yes"
                                        : "Should this product be visible on the marketplace right away? No"
                                    }
                                  />
                                )} */}
															</Grid>
															<Grid item xs={12} lg={5}></Grid>
														</Grid>
													</TabPanel>
													<TabPanel value={tabPanelIndex} index={1}>
														<Grid container>
															<Grid item xs={12} lg={7}>
																<Field
																	component={TextField}
																	name="product_stock_keeping_unit"
																	style={{ width: "50%" }}
																	type="text"
																	label="SKU"
																	helperText="SKU (stock keeping unit): a random code for the product."
																/>
																<br />
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={values.enable_stock_management}
																			inputProps={{
																				name: "enable_stock_management",
																			}}
																			onChange={(
																				e: React.ChangeEvent<HTMLInputElement>
																			) => {
																				setFieldValue(
																					"stock_quantity",
																					null,
																					true
																				);
																				setFieldValue(
																					"low_stock_threshold",
																					null,
																					true
																				);
																				setFieldValue("stock_status", "", true);
																				setFieldValue(
																					"enable_stock_management",
																					e.target.checked,
																					true
																				);
																			}}
																		/>
																	}
																	label="Enable stock management at product level"
																/>
																<br />
																{values.enable_stock_management && (
																	<Fragment>
																		<Field
																			component={TextField}
																			name="stock_quantity"
																			fullWidth
																			type="number"
																			label="Stock quantity"
																			helperText="Set the quantity of this product in stock."
																		/>
																		<br />
																		<Field
																			component={TextField}
																			name="low_stock_threshold"
																			fullWidth
																			type="number"
																			label="Low stock threshold"
																			helperText="When product stock reaches this amount you will be notified."
																		/>
																	</Fragment>
																)}

																{!values.enable_stock_management && (
																	<FormControl fullWidth>
																		<InputLabel htmlFor="stock-status">
																			Stock status
																		</InputLabel>
																		<Field
																			component={Select}
																			name="stock_status"
																			inputProps={{
																				id: "stock-status",
																			}}
																		>
																			<MenuItem value="In stock">
																				In stock
																			</MenuItem>
																			<MenuItem value="Out of stock">
																				Out of stock
																			</MenuItem>
																		</Field>
																	</FormControl>
																)}

																<br />
															</Grid>
															<Grid item xs={12} lg={5}></Grid>
														</Grid>
													</TabPanel>
													<TabPanel value={tabPanelIndex} index={2}>
														<Grid container>
															<Grid item xs={12} lg={9}>
																<Grid
																	container
																	// direction="row"
																	// justifyContent="center"
																	// alignItems="center"
																	spacing={4}
																>
																	<Grid item xs={12} lg={3}>
																		<FormControl fullWidth>
																			<InputLabel htmlFor="dimension-unit">
																				Dimension Unit
																			</InputLabel>
																			<Field
																				component={Select}
																				name="dimension_unit"
																				inputProps={{
																					id: "dimension-unit",
																				}}
																				variant="outlined"
																				required
																			>
																				<MenuItem value="cm">
																					Centimeter (cm)
																				</MenuItem>
																				<MenuItem value="mm">
																					Millimeter (mm)
																				</MenuItem>
																				<MenuItem value="inches">
																					Inches ('')
																				</MenuItem>
																			</Field>
																			<FormHelperText>
																				Select a unit of measure
																			</FormHelperText>
																		</FormControl>
																	</Grid>
																	<Grid item xs={12} lg={3}>
																		<Field
																			component={TextField}
																			name="dimension_length"
																			fullWidth
																			type="number"
																			label="Length"
																			helperText="the length of the product"
																			variant="outlined"
																			required
																		/>
																	</Grid>
																	<Grid item xs={12} lg={3}>
																		<Field
																			component={TextField}
																			name="dimension_breadth"
																			fullWidth
																			type="number"
																			label="Breadth"
																			helperText="the breadth of the product"
																			variant="outlined"
																			required
																		/>
																	</Grid>
																	<Grid item xs={12} lg={3}>
																		<Field
																			component={TextField}
																			name="dimension_height"
																			fullWidth
																			type="number"
																			label="Height"
																			helperText="the height of the product"
																			variant="outlined"
																			required
																		/>
																	</Grid>
																</Grid>
																<br />
																<Divider />
																<br />
																<Grid
																	container
																	// justify="flex-start"
																	// alignItems="center"
																	spacing={4}
																>
																	<Grid item xs={12} lg={3}>
																		<FormControl fullWidth>
																			<InputLabel htmlFor="weight-unit">
																				Weight Unit
																			</InputLabel>
																			<Field
																				component={Select}
																				name="weight_unit"
																				inputProps={{
																					id: "weight-unit",
																				}}
																				variant="outlined"
																				required
																			>
																				<MenuItem value="kg">
																					Kilogram (cm)
																				</MenuItem>
																				<MenuItem value="g">Gram (g)</MenuItem>
																				<MenuItem value="lb">
																					Pound (lb)
																				</MenuItem>
																				<MenuItem value="oz">
																					Ounce (oz)
																				</MenuItem>
																			</Field>
																			<FormHelperText>
																				Select a unit of measure
																			</FormHelperText>
																		</FormControl>
																	</Grid>
																	<Grid item xs={12} lg={3}>
																		<Field
																			component={TextField}
																			name="weight"
																			fullWidth
																			type="number"
																			label="Weight"
																			helperText="the weight of the product"
																			variant="outlined"
																			required
																		/>
																	</Grid>
																</Grid>
															</Grid>
															<Grid item xs={12} lg={3}></Grid>
														</Grid>
													</TabPanel>
													<TabPanel value={tabPanelIndex} index={3}>
														{/* <ProductAttributeComponent /> */}

														<Fragment key="productAattribute1">
															<Grid
																container
																// justify="flex-start"
																// alignItems="center"
																spacing={2}
															>
																<Grid item xs={12} lg={2}>
																	<Field
																		component={TextField}
																		name="product_attribute_1_title"
																		fullWidth
																		type="text"
																		label="Specification 1 Title"
																		key="pa_1_title"
																	/>
																</Grid>
																<Grid item xs={12} lg={6}>
																	<Field
																		component={TextField}
																		name={`product_attribute_1_value`}
																		fullWidth
																		type="text"
																		label="Specification 1 Value(s)"
																		helperText='Enter some text, or some specifications by a comma "," separating values.'
																		key="pa_1_value"
																	/>
																</Grid>
																<Grid item xs={12} lg={3}></Grid>
																<Grid item xs={12} lg={1}>
																	{!showAttribute2 && (
																		<Tooltip title="Add another attribute">
																			<IconButton
																				style={{
																					color: "green",
																					marginTop: "25%",
																				}}
																				onClick={() => {
																					setShowAttribute2(!showAttribute2);
																					setFieldValue(
																						"product_attribute_2_title",
																						"",
																						false
																					);
																					setFieldValue(
																						"product_attribute_2_value",
																						"",
																						false
																					);
																				}}
																			>
																				<AddCircleIcon />
																			</IconButton>
																		</Tooltip>
																	)}
																</Grid>
															</Grid>
														</Fragment>
														{showAttribute2 === true && (
															<Fragment key="productAattribute2">
																<Grid
																	container
																	// justify="flex-start"
																	// alignItems="center"
																	spacing={2}
																>
																	<Grid item xs={12} lg={2}>
																		<Field
																			component={TextField}
																			name={`product_attribute_2_title`}
																			fullWidth
																			type="text"
																			label={`Specification 2 Title`}
																			key={`pa_2_title`}
																		/>
																	</Grid>
																	<Grid item xs={12} lg={6}>
																		<Field
																			component={TextField}
																			name={`product_attribute_2_value`}
																			fullWidth
																			type="text"
																			label={`Specification 2 Value(s)`}
																			helperText={`Enter some text, or some specifications by a comma "," separating values.`}
																			key={`pa_2_value`}
																		/>
																	</Grid>
																	<Grid item xs={12} lg={3}></Grid>
																	<Grid item xs={12} lg={1}>
																		{!showAttribute3 && (
																			<Tooltip title="Remove this attribute">
																				<IconButton
																					style={{
																						color: "red",
																						marginTop: "25%",
																					}}
																					onClick={() => {
																						setShowAttribute2(!showAttribute2);
																						setFieldValue(
																							"product_attribute_2_title",
																							"",
																							false
																						);
																						setFieldValue(
																							"product_attribute_2_value",
																							"",
																							false
																						);
																					}}
																				>
																					<RemoveCircleIcon />
																				</IconButton>
																			</Tooltip>
																		)}

																		{!showAttribute3 && (
																			<Tooltip title="Add another attribute">
																				<IconButton
																					style={{ color: "green" }}
																					onClick={() => {
																						setShowAttribute3(!showAttribute3);
																						setFieldValue(
																							"product_attribute_3_title",
																							"",
																							false
																						);
																						setFieldValue(
																							"product_attribute_3_value",
																							"",
																							false
																						);
																					}}
																				>
																					<AddCircleIcon />
																				</IconButton>
																			</Tooltip>
																		)}
																	</Grid>
																</Grid>
															</Fragment>
														)}

														{showAttribute3 && (
															<Fragment key="productAattribute3">
																<Grid
																	container
																	// justify="flex-start"
																	// alignItems="center"
																	spacing={2}
																>
																	<Grid item xs={12} lg={2}>
																		<Field
																			component={TextField}
																			name={`product_attribute_3_title`}
																			fullWidth
																			type="text"
																			label={`Specification 3 Title`}
																			key={`pa_3_title`}
																		/>
																	</Grid>
																	<Grid item xs={12} lg={6}>
																		<Field
																			component={TextField}
																			name={`product_attribute_3_value`}
																			fullWidth
																			type="text"
																			label={`Specification 3 Value(s)`}
																			helperText={`Enter some text, or some specifications by a comma "," separating values.`}
																			key={`pa_3_value`}
																		/>
																	</Grid>
																	<Grid item xs={12} lg={3}></Grid>
																	<Grid item xs={12} lg={1}>
																		{showAttribute2 && !showAttribute4 && (
																			<Tooltip title="Remove this attribute">
																				<IconButton
																					style={{
																						color: "red",
																						marginTop: "25%",
																					}}
																					onClick={() => {
																						setShowAttribute3(!showAttribute3);
																						setFieldValue(
																							"product_attribute_3_title",
																							"",
																							false
																						);
																						setFieldValue(
																							"product_attribute_3_value",
																							"",
																							false
																						);
																					}}
																				>
																					<RemoveCircleIcon />
																				</IconButton>
																			</Tooltip>
																		)}

																		{!showAttribute4 && (
																			<Tooltip title="Add another attribute">
																				<IconButton
																					style={{ color: "green" }}
																					onClick={() => {
																						setShowAttribute4(!showAttribute4);
																						setFieldValue(
																							"product_attribute_4_title",
																							"",
																							false
																						);
																						setFieldValue(
																							"product_attribute_4_value",
																							"",
																							false
																						);
																					}}
																				>
																					<AddCircleIcon />
																				</IconButton>
																			</Tooltip>
																		)}
																	</Grid>
																</Grid>
															</Fragment>
														)}

														{showAttribute4 && (
															<Fragment key="productAattribute4">
																<Grid
																	container
																	// justify="flex-start"
																	// alignItems="center"
																	spacing={2}
																>
																	<Grid item xs={12} lg={2}>
																		<Field
																			component={TextField}
																			name={`product_attribute_4_title`}
																			fullWidth
																			type="text"
																			label={`Specification 4 Title`}
																			key={`pa_4_title`}
																		/>
																	</Grid>
																	<Grid item xs={12} lg={6}>
																		<Field
																			component={TextField}
																			name={`product_attribute_4_value`}
																			fullWidth
																			type="text"
																			label={`Specification 4 Value(s)`}
																			helperText={`Enter some text, or some specifications by a comma "," separating values.`}
																			key={`pa_4_value`}
																		/>
																	</Grid>
																	<Grid item xs={12} lg={3}></Grid>
																	<Grid item xs={12} lg={1}>
																		{showAttribute3 && !showAttribute5 && (
																			<Tooltip title="Remove this attribute">
																				<IconButton
																					style={{
																						color: "red",
																						marginTop: "25%",
																					}}
																					onClick={() => {
																						setShowAttribute4(!showAttribute4);
																						setFieldValue(
																							"product_attribute_4_title",
																							"",
																							false
																						);
																						setFieldValue(
																							"product_attribute_4_value",
																							"",
																							false
																						);
																					}}
																				>
																					<RemoveCircleIcon />
																				</IconButton>
																			</Tooltip>
																		)}

																		{!showAttribute5 && (
																			<Tooltip title="Add another attribute">
																				<IconButton
																					style={{ color: "green" }}
																					onClick={() => {
																						setShowAttribute5(!showAttribute5);
																						setFieldValue(
																							"product_attribute_5_title",
																							"",
																							false
																						);
																						setFieldValue(
																							"product_attribute_5_value",
																							"",
																							false
																						);
																					}}
																				>
																					<AddCircleIcon />
																				</IconButton>
																			</Tooltip>
																		)}
																	</Grid>
																</Grid>
															</Fragment>
														)}

														{showAttribute5 && (
															<Fragment key="productAattribute5">
																<Grid
																	container
																	// justify="flex-start"
																	// alignItems="center"
																	spacing={2}
																>
																	<Grid item xs={12} lg={2}>
																		<Field
																			component={TextField}
																			name={`product_attribute_5_title`}
																			fullWidth
																			type="text"
																			label={`Specification 5 Title`}
																			key={`pa_5_title`}
																		/>
																	</Grid>
																	<Grid item xs={12} lg={6}>
																		<Field
																			component={TextField}
																			name={`product_attribute_5_value`}
																			fullWidth
																			type="text"
																			label={`Specification 5 Value(s)`}
																			helperText={`Enter some text, or some specifications by a comma "," separating values.`}
																			key={`pa_5_value`}
																		/>
																	</Grid>
																	<Grid item xs={12} lg={3}>
																		{/* <FormControlLabel style={{ marginTop: '10%' }}
                                                                    control={<GreenCheckbox checked={checkUseAttr5AsVariation} onChange={handleCheckUseAttr5AsVariationChange} name={`use_attribute_5_for_variation`} indeterminate={false} />}
                                                                    label={checkUseAttr5AsVariation ? 'Use for variation? Yes' : 'Use for variation? No'}
                                                                />

                                                                <br /> */}
																	</Grid>
																	<Grid item xs={12} lg={1}>
																		{showAttribute4 && (
																			<Tooltip title="Remove this attribute">
																				<IconButton
																					style={{
																						color: "red",
																						marginTop: "25%",
																					}}
																					onClick={() => {
																						setShowAttribute5(!showAttribute5);
																						setFieldValue(
																							"product_attribute_5_title",
																							"",
																							false
																						);
																						setFieldValue(
																							"product_attribute_5_value",
																							"",
																							false
																						);
																					}}
																				>
																					<RemoveCircleIcon />
																				</IconButton>
																			</Tooltip>
																		)}
																	</Grid>
																</Grid>
															</Fragment>
														)}
													</TabPanel>
													<TabPanel value={tabPanelIndex} index={4}>
														<Grid container spacing={3}>
															<Grid item xs={12} lg={6}>
																<div
																	className={
																		styles.product_image_uploader_wrapper
																	}
																>
																	{values.image_1 === "" && (
																		<div
																			className={styles.product_image_uploader}
																		>
																			<ImageUploader
																				onRequestSave={(id) =>
																					setFieldValue("image_1", id, true)
																				}
																				onRequestClear={() =>
																					setFieldValue("image_1", "", true)
																				}
																				label="upload product image 1"
																				existingImageId={`product_${values.product_id}_image_1`}
																			/>
																		</div>
																	)}
																	{values.image_1 !== "" && (
																		<div
																			className={styles.product_image_preview}
																		>
																			<GetImageFromFireBase
																				source={`product_${values.product_id}_image_1`}
																			/>{" "}
																			<IconButton
																				onClick={() =>
																					setFieldValue("image_1", "", true)
																				}
																			>
																				<RemoveCircleIcon />
																			</IconButton>
																		</div>
																	)}
																</div>
															</Grid>
															<Grid item xs={12} lg={6}>
																<div
																	className={
																		styles.product_image_uploader_wrapper
																	}
																>
																	{values.image_2 === "" &&
																		values.image_1 !== "" && (
																			<div
																				className={
																					styles.product_image_uploader
																				}
																			>
																				<ImageUploader
																					onRequestSave={(id) =>
																						setFieldValue("image_2", id, true)
																					}
																					onRequestClear={() =>
																						setFieldValue("image_2", "", true)
																					}
																					label="upload product image 2"
																					existingImageId={`product_${values.product_id}_image_2`}
																				/>
																			</div>
																		)}
																	{values.image_2 !== "" && (
																		<div
																			className={styles.product_image_preview}
																		>
																			<GetImageFromFireBase
																				source={`product_${values.product_id}_image_2`}
																			/>{" "}
																			<IconButton
																				onClick={() =>
																					setFieldValue("image_2", "", true)
																				}
																			>
																				<RemoveCircleIcon />
																			</IconButton>
																		</div>
																	)}
																</div>
															</Grid>
															<Grid item xs={12} lg={6}>
																<div
																	className={
																		styles.product_image_uploader_wrapper
																	}
																>
																	{values.image_3 === "" &&
																		values.image_2 !== "" && (
																			<div
																				className={
																					styles.product_image_uploader
																				}
																			>
																				<ImageUploader
																					onRequestSave={(id) =>
																						setFieldValue("image_3", id, true)
																					}
																					onRequestClear={() =>
																						setFieldValue("image_3", "", true)
																					}
																					label="upload product image 3"
																					existingImageId={`product_${values.product_id}_image_3`}
																				/>
																			</div>
																		)}
																	{values.image_3 !== "" && (
																		<div
																			className={styles.product_image_preview}
																		>
																			{" "}
																			<GetImageFromFireBase
																				source={`product_${values.product_id}_image_3`}
																			/>{" "}
																			<IconButton
																				onClick={() =>
																					setFieldValue("image_3", "", true)
																				}
																			>
																				<RemoveCircleIcon />
																			</IconButton>
																		</div>
																	)}
																</div>
															</Grid>
															<Grid item xs={12} lg={6}>
																<div
																	className={
																		styles.product_image_uploader_wrapper
																	}
																>
																	{values.image_4 === "" &&
																		values.image_3 !== "" && (
																			<div
																				className={
																					styles.product_image_uploader
																				}
																			>
																				<ImageUploader
																					onRequestSave={(id) =>
																						setFieldValue("image_4", id, true)
																					}
																					onRequestClear={() =>
																						setFieldValue("image_4", "", true)
																					}
																					label="upload product image 4"
																					existingImageId={`product_${values.product_id}_image_4`}
																				/>
																			</div>
																		)}
																	{values.image_4 !== "" && (
																		<div
																			className={styles.product_image_preview}
																		>
																			<GetImageFromFireBase
																				source={`product_${values.product_id}_image_4`}
																			/>{" "}
																			<IconButton
																				onClick={() =>
																					setFieldValue("image_4", "", true)
																				}
																			>
																				<RemoveCircleIcon />
																			</IconButton>
																		</div>
																	)}
																</div>
															</Grid>
														</Grid>
													</TabPanel>
												</Fragment>
												<Fragment key="variation-product-data"></Fragment>
												{/* next navigation */}
												<div className={styles.actionsContainer}>
													<div>
														<Button
															disabled={activeStep === 0}
															onClick={() => {
																settabPanelIndex(0);
																handleBack();
															}}
															className={styles.button}
														>
															Back
														</Button>
														{!errors.asking_price &&
															!errors.product_category &&
															values.image_1 !== "" &&
															!errors.dimension_unit &&
															!errors.dimension_breadth &&
															!errors.dimension_length &&
															!errors.dimension_height &&
															!errors.weight &&
															!errors.weight_unit && (
																<Button
																	variant="contained"
																	color="primary"
																	onClick={handleNext}
																	className={styles.button}
																>
																	{activeStep === steps - 1 ? "Finish" : "Next"}
																</Button>
															)}
													</div>
												</div>
												{/* end next navigation */}
											</StepContent>
										</Step>
									</Stepper>
									{isSubmitting && <LinearProgress />}
									{activeStep === steps && (
										<Paper
											square
											elevation={0}
											className={styles.resetContainer}
										>
											<Button onClick={handleBack} className={styles.button}>
												Back
											</Button>
											<LoadingButton
												variant="contained"
												color="primary"
												pending={EditProductInfo.isLoading}
												disabled={isSubmitting}
												onClick={submitForm}
											>
												Submit
											</LoadingButton>
										</Paper>
									)}
								</Form>
							)}
						</Formik>
					</Grid>
					<Grid item xs={12} lg={1}></Grid>
				</Grid>
			</DashBoardLayout>
		</Fragment>
	);
};

export default EditProduct;
