import { useQuery } from "react-query";
import firebase from "../config/firebaseConfig";

const getStoreData = async (key: any, id: string) => {
	const response = await firebase
		.firestore()
		.collection("Stores")
		.doc(id)
		.get();
	const data = response.data();

	return data;
};

const useGetStoreData = (id: string) => {
	return useQuery(["storeData", id], getStoreData);
};

export default useGetStoreData;
