import React, { Fragment, useState, useEffect } from "react";
//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//material table
import MaterialTable from "material-table";

//material ui components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

//icons
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@material-ui/icons/VisibilityOffTwoTone";
import StarsTwoToneIcon from "@material-ui/icons/StarsTwoTone";
import StarHalfTwoToneIcon from "@material-ui/icons/StarHalfTwoTone";
import StoreMallDirectoryTwoToneIcon from "@material-ui/icons/StoreMallDirectoryTwoTone";
import RateReviewIcon from "@material-ui/icons/RateReview";
import EditIcon from "@material-ui/icons/Edit";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
//number format
import NumberFormat from "react-number-format";

//routes
import * as ROUTES from "../../../constants/routes";

//navigation hook
import { useNavigate } from "../../../hooks/navigate";

import { truncate } from "../../../utils";
//react - redux
import { useSelector, useDispatch } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";
import moment from "moment";

import { HideProductFromMarket } from "../../../store/actions/products/hideProduct";
import { ShowProductOnMarket } from "../../../store/actions/products/showproduct";

import ReviewProductModal from "../../../components/Product/Assessment/ReviewProductModal";
import ViewProductModal from "../../../components/Product/Assessment/ViewProductModal";
import EditProductModal from "../../../components/Product/Assessment/EditProductModal";

interface Props {
	entity: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			borderRadius: 20,
			width: "100%",
		},
		toolbar_wrapper: {
			// backgroundColor: '#303030',
			backgroundColor: theme.palette.background.default,
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		product_image_wrapper: {
			"& img": {
				width: 40,
				height: 40,
				border: "1px white solid",
			},
		},
		add_new_product_button: {
			marginTop: "5%",
		},
		queries_wrapper: {
			width: "100%",
			padding: 10,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
		price: {
			color: "green",
			marginRight: 10,
		},
		discount_price: {
			textDecoration: "line-through",
			color: "rgba(194, 188, 188, 0.7)",
		},
		discount_percent: {
			backgroundColor: "green",
			width: 35,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			color: "#fff",
			marginLeft: 10,
			padding: 4,
		},
		detail_panel_wrapper: {
			padding: 10,
		},
		appBar: {
			position: "relative",
			backgroundColor: "#fff",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ProductsWidget = ({ entity }: Props) => {
	const styles = useStyles();
	// const theme = useTheme();
	const [pageloading, setpageloading] = useState(true);

	const [tableTitle, setTableTitle] = useState("");

	const { navigateToLink } = useNavigate();

	const [productData, setProductData] = useState<any>([]);

	const dispatch = useDispatch();

	const [openModal, setOpenModal] = React.useState(false);

	const handleClickOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const [productModalData, setProductModalData] = useState<any>({});
	const [productModalTitle, setProductModalTitle] = useState("");

	useFirestoreConnect([
		{
			collection: "Products",
			where: [["entity", "==", entity]],
			storeAs: "StoreProducts",
		},
	]);

	const storeProducts = useSelector(
		(state: any) => state.firestore.data["StoreProducts"]
	);

	useEffect(() => {
		if (isLoaded(storeProducts)) {
			setpageloading(false);
			setTableTitle("all products");

			const result_all_products = _.map(storeProducts, _.clone);
			const order_filtered_by_date_added = _.orderBy(
				result_all_products,
				["added_on"],
				["desc"]
			);

			const result = _.map(order_filtered_by_date_added, (item) => {
				return {
					// photo: `product_${item.product_id}_image_1`,
					photo: item.image_1_url,
					productname: truncate(item.product_name, 25),
					category: truncate(item.product_category, 25),
					price: item.regular_price,
					stock: item.enable_stock_management
						? item.stock_quantity
						: item.stock_status,
					status: item.visible_on_market,
					to_be_reviewed: item.to_be_reviewed,
					to_be_resolved: item.to_be_resolved,
					featured: item.featured,
					// promoted: item.promoted,
					promoted:
						item.promoted &&
						_.has(item, "promotion_data") &&
						moment(moment().format("YYYY-MM-DD")).isBefore(
							moment(item.promotion_data.to).format("YYYY-MM-DD")
						)
							? true
							: false,
					// && moment(moment(item.promotion_data.to).format('YYYY-MM-DD')).isAfter(moment().format('YYYY-MM-DD')) ? true : false,
					added: moment(item.added_on.toDate()).fromNow(),
					createdon: moment(item.added_on.toDate()).format("MM/DD/YYYY"),
					// createdon: item.added_on,
					id: item.product_id,
					data: item,
				};
			});

			setProductData([...result]);
			setpageloading(false);
			setTableTitle("All Products");
		}
	}, [storeProducts]);

	const hasNumber = (myString: string) => {
		return /\d/.test(myString);
	};

	if (!isLoaded(storeProducts)) return <div>Loading...</div>;

	return (
		<Fragment>
			<Dialog
				fullScreen
				open={openModal}
				onClose={handleCloseModal}
				TransitionComponent={Transition}
			>
				<AppBar className={styles.appBar} elevation={0}>
					<Toolbar>
						<IconButton
							edge="start"
							color="primary"
							onClick={handleCloseModal}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography
							variant="h6"
							className={styles.title}
							color="textPrimary"
						>
							{productModalTitle === "view-product" && "View Product"}
							{productModalTitle === "review-product" && "Review Product"}
							{productModalTitle === "edit-product" && "Edit Product"}
						</Typography>
					</Toolbar>
				</AppBar>
				{productModalTitle === "edit-product" && (
					<EditProductModal productData={productModalData} />
				)}
				{productModalTitle === "view-product" && (
					<ViewProductModal productData={productModalData} />
				)}

				{productModalTitle === "review-product" && (
					<ReviewProductModal
						data={productModalData}
						handleCloseModal={handleCloseModal}
					/>
				)}
			</Dialog>
			<Box className={styles.root} boxShadow={5}>
				<MaterialTable
					style={{ width: "100%" }}
					isLoading={pageloading}
					title={tableTitle}
					columns={[
						{
							title: "Image",
							field: "photo",
							render: (rowData) => (
								<div className={styles.product_image_wrapper}>
									<img src={rowData.photo} alt={rowData.productname} />
								</div>
							),
							align: "center",
						},
						{
							title: "Product Name",
							field: "productname",
							render: (rowData) => (
								<Typography variant="caption">{rowData.productname}</Typography>
							),
							align: "justify",
						},
						{
							title: "Category",
							field: "category",
							render: (rowData) => (
								<Typography variant="caption">{rowData.category}</Typography>
							),
							align: "justify",
						},
						{
							title: "Price",
							field: "price",
							type: "numeric",
							render: (rowData) => (
								<>
									{rowData.data.enable_discount === false && (
										<Typography variant="h6" className={styles.price}>
											<NumberFormat
												value={rowData.data.regular_price}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"₦"}
											/>
										</Typography>
									)}

									{rowData.data.enable_discount &&
										rowData.data.enable_discount_schedule === true &&
										rowData.data.discount_price_to_date !== null && (
											<>
												<Typography variant="h6" className={styles.price}>
													<NumberFormat
														value={rowData.data.discount_price}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"₦"}
													/>
												</Typography>
												<div
													style={{
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "center",
													}}
												>
													<Typography
														variant="subtitle2"
														className={styles.discount_price}
													>
														<NumberFormat
															value={rowData.data.regular_price}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"₦"}
														/>
													</Typography>
													<div className={styles.discount_percent}>
														{`-${rowData.data.discount_percent}`}
													</div>
												</div>
											</>
										)}

									{rowData.data.enable_discount === true &&
										rowData.data.enable_discount_schedule === false && (
											<>
												<Typography variant="h6" className={styles.price}>
													<NumberFormat
														value={rowData.data.discount_price}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"₦"}
													/>
												</Typography>
												<div
													style={{
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "center",
													}}
												>
													<Typography
														variant="subtitle2"
														className={styles.discount_price}
													>
														<NumberFormat
															value={rowData.data.regular_price}
															displayType={"text"}
															thousandSeparator={true}
															prefix={"₦"}
														/>
													</Typography>
													<div className={styles.discount_percent}>
														{`-${rowData.data.discount_percent}`}
													</div>
												</div>
											</>
										)}
								</>
								// <NumberFormat
								//   value={rowData.price}
								//   displayType={"text"}
								//   thousandSeparator={true}
								//   prefix={"₦"}
								// />
							),
							align: "center",
						},
						{
							title: "Stock",
							field: "stock",
							render: (rowData) =>
								hasNumber(rowData.stock) ? (
									<Typography variant="caption">{rowData.stock}</Typography>
								) : rowData.stock === "In stock" ? (
									<Chip
										icon={<StoreMallDirectoryTwoToneIcon />}
										label={rowData.stock}
										variant="outlined"
										color="secondary"
									/>
								) : (
									<Chip
										icon={<StoreMallDirectoryTwoToneIcon />}
										label={rowData.stock}
										variant="outlined"
										color="secondary"
										style={{ color: "red", border: "1px red solid" }}
									/>
								),
							align: "center",
						},
						{
							title: "Status",
							field: "status",
							type: "boolean",
							render: (rowData) => (
								<Fragment>
									{!rowData.status &&
										!rowData.to_be_reviewed &&
										!rowData.data.under_creatives_process && (
											<Chip
												icon={<VisibilityOffTwoToneIcon />}
												label="In-active"
												variant="outlined"
												color="secondary"
											/>
										)}
									{rowData.status && (
										<Chip
											icon={<VisibilityTwoToneIcon />}
											label="Active"
											variant="outlined"
											color="secondary"
										/>
									)}
									{rowData.to_be_reviewed && (
										<Chip
											icon={<RateReviewIcon style={{ color: "orange" }} />}
											label="Under Review"
											variant="outlined"
											style={{ color: "orange", border: "1px orange solid" }}
										/>
									)}
									{rowData.to_be_resolved && (
										<Chip
											icon={<EditIcon style={{ color: "red" }} />}
											label="To Be Resolved"
											variant="outlined"
											style={{ color: "red", border: "1px red solid" }}
										/>
									)}
									{rowData.data.under_creatives_process && (
										<Chip
											icon={<BubbleChartIcon style={{ color: "orange" }} />}
											label="Rebranding"
											variant="outlined"
											style={{
												color: "orange",
												border: "1px orange solid",
											}}
										/>
									)}
								</Fragment>
							),
							align: "center",
						},

						{
							title: "Promoted",
							field: "promoted",
							type: "boolean",
							render: (rowData) =>
								rowData.promoted ? (
									<Chip
										icon={<StarsTwoToneIcon />}
										label="Yes"
										variant="outlined"
										color="secondary"
									/>
								) : (
									<Chip
										icon={<StarHalfTwoToneIcon />}
										label="No"
										variant="outlined"
										color="secondary"
										style={{ color: "red", border: "1px red solid" }}
									/>
								),
							align: "center",
						},
						{
							title: "Added",
							field: "added",
							render: (rowData) => (
								<Typography variant="caption">
									{rowData.added} <br /> {rowData.createdon}{" "}
								</Typography>
							),
							align: "justify",
						},
						{ title: "Created on", field: "createdon", hidden: true },
						{ title: "Id", field: "id", hidden: true },
						{ title: "data", field: "data", hidden: true },
						{
							title: "to_be_resolved",
							field: "to_be_resolved",
							hidden: true,
						},
						{
							title: "to_be_reviewed",
							field: "to_be_reviewed",
							hidden: true,
						},
					]}
					data={productData}
					options={{
						search: true,
						actionsColumnIndex: -1,
						exportButton: false,
					}}
					detailPanel={[
						{
							icon: "edit",
							tooltip: "Actions on this product",
							render: (rowData) => {
								return (
									<div className={styles.detail_panel_wrapper}>
										<Grid container spacing={2}>
											<Grid item xs={12} lg={1}>
												<Button
													variant="outlined"
													style={{
														color: "#fff",
														backgroundColor: "#030201",
													}}
													aria-label="edit-vendor-details"
													onClick={() => {
														setProductModalData({ ...rowData.data });
														setProductModalTitle("edit-product");
														handleClickOpenModal();
													}}
												>
													Edit
												</Button>
											</Grid>

											<Grid item xs={12} lg={1}>
												<Button
													variant="outlined"
													style={{
														color: "#fff",
														backgroundColor: "#030201",
													}}
													aria-label="view-vendor-details"
													onClick={() => {
														setProductModalData({ ...rowData.data });
														setProductModalTitle("view-product");
														handleClickOpenModal();
													}}
												>
													View
												</Button>
											</Grid>
											{rowData.to_be_reviewed && (
												<Grid item xs={12} lg={1}>
													<Button
														variant="outlined"
														style={{
															color: "#fff",
															backgroundColor: "#030201",
														}}
														aria-label="view-vendor-details"
														onClick={() => {
															setProductModalData({ ...rowData.data });
															setProductModalTitle("review-product");
															handleClickOpenModal();
														}}
													>
														Review
													</Button>
												</Grid>
											)}
											{rowData.to_be_resolved && (
												<Grid item xs={12} lg={1}>
													<Button
														variant="outlined"
														style={{
															color: "#fff",
															backgroundColor: "#030201",
														}}
														aria-label="view-vendor-details"
														onClick={() => {
															navigateToLink.push(ROUTES.RESOLVE_PRODUCT, {
																id: rowData.id,
															});
														}}
													>
														Review
													</Button>
												</Grid>
											)}
											{rowData.status && (
												<Grid item xs={12} lg={2}>
													<Button
														variant="outlined"
														color="secondary"
														aria-label="hide-from-market"
														onClick={() => {
															dispatch(HideProductFromMarket(rowData.id));
														}}
													>
														Hide From Market
													</Button>
												</Grid>
											)}
											{!rowData.to_be_reviewed &&
												!rowData.to_be_resolved &&
												!rowData.status && (
													<Grid item xs={12} lg={3}>
														<Button
															variant="outlined"
															color="secondary"
															aria-label="show-on-market"
															onClick={() => {
																dispatch(ShowProductOnMarket(rowData.id));
															}}
														>
															Show on Market
														</Button>
													</Grid>
												)}
										</Grid>
									</div>
								);
							},
						},
					]}
				/>
			</Box>
		</Fragment>
	);
};

export default ProductsWidget;
