import { useMutation, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

const KYCFlag = (values: any) => {
	return firebase.firestore().collection("Stores").doc(values.id).set(
		{
			pending_vet: true,
		},
		{ merge: true }
	);
};

const useKYCFlag = () => {
	return useMutation(KYCFlag, {
		onSuccess: () => {
			queryCache.invalidateQueries("allStores");
		},
	});
};

export default useKYCFlag;
