import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DashBoardLayout from "../pages/Layouts/DashBoardLayout";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FacebookShop from "../components/facebookShop";
import SlashSaleProductList from "../components/facebookShop/SlashSaleProductList";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`integrations-tabpanel-${index}`}
			aria-labelledby={`integrations-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `integrations-tab-${index}`,
		"aria-controls": `integrations-tabpanel-${index}`,
	};
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			backgroundColor: theme.palette.background.paper,
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const Integrations = () => {
	const styles = useStyles();

	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	return (
		<DashBoardLayout>
			<div className={styles.page_header}>
				<Typography variant="h4" color="primary">
					{" "}
					Integrations
				</Typography>
			</div>
			<br />
			<div className={styles.root}>
				<AppBar position="static">
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="integrations tabs example"
					>
						<Tab label="ONLINE SHOP" {...a11yProps(0)} />
						<Tab label="SLASH SALE PRODUCTS LIST" {...a11yProps(1)} />
					</Tabs>
				</AppBar>
				<TabPanel value={value} index={0}>
					<FacebookShop />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<SlashSaleProductList />
				</TabPanel>
			</div>
		</DashBoardLayout>
	);
};

export default Integrations;
