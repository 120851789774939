import { useMutation, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

const KYCPassVetting = (values: any) => {
	return firebase.firestore().collection("Stores").doc(values.id).set(
		{
			under_vetting_by_legal: false,
			pending_vet: false,
			vetted: true,
			approved: true,
			failed_vet: false,
		},
		{ merge: true }
	);
};

const useKYCPassVetting = () => {
	return useMutation(KYCPassVetting, {
		onSuccess: () => {
			queryCache.invalidateQueries("allStores");
		},
	});
};

export default useKYCPassVetting;
