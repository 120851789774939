import React, { useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import ImagesDropzone from "./ImagesDropzone";
import ImageElement from "./ImageElement";
import shortid from "shortid";
import Button from "@material-ui/core/Button";

export default function ProductImagesUploader({
	existingImageId,
	folderToSave,
	setFieldValue,
	existingImages,
}: any) {
	let id;
	if (existingImageId) {
		id = existingImageId;
	} else {
		id = shortid.generate();
	}

	const [imageList, setImageList] = useState([]);
	const [changeImages, setChangeImages] = useState(false);

	const changeImageField = (index: number, parameter: any, value: any) => {
		const newArray = [...imageList];
		// @ts-ignore
		newArray[index][parameter] = value;
		setImageList(newArray);
	};

	const handleChangeOrderUp = (index: number) => {
		// If first, ignore
		if (index !== 0) {
			const newArray = [...imageList];
			const intermediate = newArray[index - 1];
			newArray[index - 1] = newArray[index];
			newArray[index] = intermediate;
			setImageList(newArray);
		}
	};

	const handleChangeOrderDown = (index: number) => {
		// If last, ignore
		if (index < imageList.length - 1) {
			const newArray = [...imageList];
			const intermediate = newArray[index + 1];
			newArray[index + 1] = newArray[index];
			newArray[index] = intermediate;
			setImageList(newArray);
		}
	};

	const handleDeleteImage = (index: number) => {
		// @ts-ignore
		imageList[index].storageRef
			.delete()
			.then(() => {
				const newArray = [...imageList];
				newArray.splice(index, 1);
				setImageList(newArray);
			})
			.catch((error: any) => {
				console.log("Error deleting file:", error);
			});
	};

	useEffect(() => {
		imageList.forEach((image: any, index: number) => {
			if (image.status === "FINISH" || image.status === "UPLOADING") return;
			changeImageField(index, "status", "UPLOADING");
			const uploadTask = image.storageRef.put(image.file);
			uploadTask.on(
				"state_changed",
				null,
				function error(err: any) {
					console.log("Error Image Upload:", err);
				},
				async function complete() {
					const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
					changeImageField(index, "downloadURL", downloadURL);
					changeImageField(index, "status", "FINISH");
				}
			);
		});
	}, [imageList]);

	useEffect(() => {
		if (imageList.length > 0) {
			imageList.map((item: any, index: number) => {
				const ImageURL = item.downloadURL;
				const fileName = item.fileName;
				setFieldValue(`image_${index + 1}`, fileName, true);
				setFieldValue(`image_${index + 1}_url`, ImageURL, true);
			});
		}
	}, [imageList, setFieldValue]);

	return (
		<Grid container direction="column" alignItems="center" spacing={2}>
			{!changeImages && existingImages && (
				<Grid container>
					<Grid item xs={6} lg={4}>
						<img
							src={existingImages.image_1_url}
							alt={`${id}-product-1`}
							style={{ width: "100%", height: 300 }}
						/>
					</Grid>
					{existingImages.image_2_url && (
						<Grid item xs={6} lg={4}>
							<img
								src={existingImages.image_2_url}
								alt={`${id}-product-2`}
								style={{ width: "100%", height: 300 }}
							/>
						</Grid>
					)}
					{existingImages.image_3_url && (
						<Grid item xs={6} lg={4}>
							<img
								src={existingImages.image_3_url}
								alt={`${id}-product-3`}
								style={{ width: "100%", height: 300 }}
							/>
						</Grid>
					)}
					{existingImages.image_4_url && (
						<Grid item xs={6} lg={4}>
							<img
								src={existingImages.image_4_url}
								alt={`${id}-product-4`}
								style={{ width: "100%", height: 300 }}
							/>
						</Grid>
					)}
				</Grid>
			)}
			<Button
				variant="contained"
				style={{ color: "#fff" }}
				color="primary"
				onClick={() => setChangeImages(!changeImages)}
			>
				{changeImages ? "Cancel change images" : "Change images"}
			</Button>
			{changeImages && (
				<Box
					border={1}
					margin={4}
					padding={3}
					style={{ pointerEvents: imageList.length + 1 <= 4 ? "auto" : "none" }}
				>
					<Grid
						item
						container
						direction="column"
						alignItems="center"
						xs={12}
						spacing={1}
					>
						<Grid item container xs={12} justify="center">
							<ImagesDropzone
								setImageList={setImageList}
								folderToSave={folderToSave}
								id={id}
								// id={`${folderToSave}/${id}`}
							/>
						</Grid>
					</Grid>
				</Box>
			)}

			{changeImages && imageList.length > 0 && (
				<Box bgcolor="primary.light" p={4}>
					{imageList.map((image, index) => {
						return (
							// @ts-ignore
							<Grid item key={image.file.size + index}>
								<ImageElement
									image={image}
									index={index}
									isFirstElement={index === 0}
									isLastElement={index === imageList.length - 1}
									handleChangeOrderUp={handleChangeOrderUp}
									handleChangeOrderDown={handleChangeOrderDown}
									handleDeleteImage={handleDeleteImage}
								/>
							</Grid>
						);
					})}
				</Box>
			)}
		</Grid>
	);
}
