
import {combineReducers} from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import {firestoreReducer} from 'redux-firestore'
import feedBackReducer from './feedBackReducer'

import { loadingBarReducer } from 'react-redux-loading-bar'

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore:firestoreReducer,
  feedBack:feedBackReducer,
  loadingBar: loadingBarReducer,
});

export default rootReducer;