import React from 'react'

//styles
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
//components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

//images
import Logo from '../../assets/images/svg/ekioja-logo.svg';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        wrapper: {
            width: '100%',
            height: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 10,
        }, logo_wrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 'auto',
            marginTop: '10%',
            '& img': {
                width: '30%',
                height: 'auto'
            }
        },
    })
)



const ReviewingAccount = () => {
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <Grid container>
                <Grid item xs={12} lg={2}></Grid>
                <Grid item xs={12} lg={8}>
                    <div className={styles.logo_wrapper}>
                        <img src={Logo} alt="ekioja" />
                    </div>
                    <div className={styles.wrapper}>
                        <Typography variant="h4" >We're reviewing your account.</Typography>
                        <Typography variant="body1"> Your account has been paused while a member of our team reviews it.This usually takes less than 48 hours.</Typography>
                        <Typography variant="caption"> You are getting this mesage either because you are yet to be approved or blocked by your administrator</Typography>
                        <br />

                    </div>
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
            </Grid>
        </div>
    )
}

export default ReviewingAccount
