import React from "react";
import ChatInputController from "./ChatInputController";

const ChatInput = () => {
	return (
		<div>
			<ChatInputController />
		</div>
	);
};

export default ChatInput;
