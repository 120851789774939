import React, { Fragment } from "react";
import ChatActivityView from "./ChatActivityView";
import { Conversation } from "../../../ConservationList/ConservationItem";
import useGenerateInvoice from "../../../../../../hooks/chat/invoice/useGenerateInvoice";

interface Props {
	data: Conversation;
}

const ChatActivityController = (props: Props) => {
	const [mutation, status] = useGenerateInvoice();

	return (
		<Fragment>
			<ChatActivityView
				data={props.data}
				generateInvoiceMutation={mutation}
				generateInvoiceStatus={status}
			/>
		</Fragment>
	);
};

export default ChatActivityController;
