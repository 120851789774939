import { useQuery } from "react-query";
import axios from "axios";

const getBanksData = async (key: any, country: string, currency: string) => {
	// let countryValue = key.queryKey[1];
	// let currencyValue = key.queryKey[2];
	const response = await axios.get("https://api.paystack.co/bank", {
		params: {
			country,
			currency,
		},
		headers: {
			Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
		},
	});

	return response.data.data;
};

const useGetPaystackBanks = (country: string, currency: string) => {
	return useQuery(["psbanksData", country, currency], getBanksData);
};

export default useGetPaystackBanks;
