import React, { Fragment, useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import EkiojaBag from "../assets/images/ekioja-logo.jpg";

//widgets
import LegalWidget from "../components/DashBoardWidgets/LegalWidget";

import LoadingScreen from "../components/LoadingScreen";

//get image
import GetImageFromFirebase from "../components/GetImageFromFirebase";

import _ from "lodash";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//utils
import {
	getVetDocsCounts,
	getUpcomingRevenueTimeFrame,
	getPendingClearanceRevenueTimeFrame,
	getEarnedRevenuePassedTimeFrame,
	getEarnedRevenueFailedTimeFrame,
} from "../utils/index";

import { LEGAL } from "../constants/roles";

// import { AcceptVetting } from "../store/actions/vetting/acceptvet";

import useAcceptStoreToVet from "../hooks/legal/useAcceptStoreToVet";
//number format
import NumberFormat from "react-number-format";

//routes
import * as ROUTES from "../constants/routes";

//navigation hook
import { useNavigate } from "../hooks/navigate";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		queries_wrapper: {
			width: "50%",
			padding: 10,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
		to_be_vetted_wrapper: {
			display: "flex",
			borderRadius: 20,
			width: "90%",
		},
		business_logo_wrapper: {
			width: 70,
			height: 70,
			borderRadius: 70,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& img": {
				width: 60,
				height: 60,
				borderRadius: 60,
			},
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const VetDashboard = () => {
	const styles = useStyles();

	const { navigateToLink } = useNavigate();

	const profile = useSelector((state: any) => state.firebase.profile);

	const authuid = useSelector((state: any) => state.firebase.auth.uid);

	const [storeIdandAmount, setStoreIdandAmount] = useState<{
		id: string;
		amount: number;
	}>({ id: "", amount: 0 });

	const [toBeVettedData, setToBeVettedData] = useState<any>([]);

	const [vetDocsData, setVetDocsData] = useState<any>([]);

	const [balance, setBalance] = useState(0);

	const [toBeVetted, setToBeVetted] = useState<any>([]);
	const [underVetting, setUnderVetting] = useState<any>([]);
	const [passedVetted, setpassedVetted] = useState<any>([]);
	const [failedVetted, setfailedVetted] = useState<any>([]);

	const [AcceptStoreToVetMutate, AcceptStoreToVetInfo] = useAcceptStoreToVet();

	const [
		openConfirmForVettingDialog,
		setOpenConfirmForVettingDialog,
	] = useState(false);

	// const dispatch = useDispatch();

	useFirestoreConnect([
		{
			collection: "Stores",
		},
		{
			collection: "Products",
		},
		{
			collection: "InterestedVendors",
		},
		{
			collection: "VetDocuments",
			where: [["vetted_by", "==", authuid]],
			storeAs: "VetDocuments",
		},
		{
			collection: "Orders",
		},
		{
			collection: "OrdersTimeline",
		},
		{
			collection: "Wallets",
			where: [["owner_id", "==", authuid]],
			storeAs: "Wallets",
		},
	]);

	const stores = useSelector((state: any) => state.firestore.ordered.Stores);

	const VetDocuments = useSelector(
		(state: any) => state.firestore.data["VetDocuments"]
	);

	const wallet = useSelector((state: any) => state.firestore.data["Wallets"]);

	const handleClickConfirmForVettingOpen = () => {
		setOpenConfirmForVettingDialog(true);
	};
	const handleConfirmForVettingClose = () => {
		setOpenConfirmForVettingDialog(false);
	};

	useEffect(() => {
		if (isLoaded(wallet)) {
			let wallet_data: any[] = [];
			if (wallet !== null) {
				for (var walletkey in wallet) {
					wallet_data.push({
						amount: wallet[walletkey].income,
					});
				}
			}

			if (_.has(wallet_data[0], "amount")) {
				setBalance(wallet_data[0].amount);
			}
		}
	}, [wallet]);

	useEffect(() => {
		if (isLoaded(VetDocuments)) {
			let vet_doc_data: any[] = [];
			if (VetDocuments !== null) {
				for (var vetdockey in VetDocuments) {
					vet_doc_data.push({
						...VetDocuments[vetdockey],
						vetdockey_uuid: vetdockey,
					});
				}
			}

			const sorted = _.orderBy(vet_doc_data, ["added_on"], ["desc"]);

			setVetDocsData([...sorted]);

			//get to be vetted
			const to_be_vetted = _.filter(
				sorted,
				(item) =>
					_.has(item, "to_be_vetted") &&
					typeof item.to_be_vetted.status !== "undefined" &&
					item.to_be_vetted.status === true
			);
			setToBeVetted([...to_be_vetted]);

			//get under vettting
			const under_vetting = _.filter(
				sorted,
				(item) =>
					_.has(item, "under_vetting") &&
					typeof item.under_vetting.status !== "undefined" &&
					item.under_vetting.status === true
			);
			setUnderVetting([...under_vetting]);

			//get vetted
			const passed_vetted_data = _.filter(
				sorted,
				(item) =>
					_.has(item, "passed") &&
					typeof item.passed.status !== "undefined" &&
					item.passed.status === true
			);

			setpassedVetted([...passed_vetted_data]);
			const failed_vetted_data = _.filter(
				sorted,
				(item) =>
					_.has(item, "failed") &&
					typeof item.failed.status !== "undefined" &&
					item.passed.failed === true
			);
			setfailedVetted([...failed_vetted_data]);
		}
	}, [VetDocuments]);

	useEffect(() => {
		if (isLoaded(stores)) {
			const all_pending_vet = _.filter(
				stores,
				(item) => item.paid_for_review === true && item.pending_vet === true
			);

			const mapToTableData = _.map(all_pending_vet, (item) => {
				return {
					business_logo_id: item.business_logo_id,
					business_name: item.business_name,
					business_niche: item.business_niche,
					id: item.unique_id,
					amountpaid:
						typeof item.paid_for_review_data !== "undefined"
							? item.paid_for_review_data.amountpaidforsignup
							: 0,
				};
			});

			setToBeVettedData([...mapToTableData]);
		}
	}, [stores]);

	const refreshPage = () => {
		window.location.reload();
	};

	if (!isLoaded(stores) && !isLoaded(VetDocuments) && !isLoaded(wallet))
		return <LoadingScreen />;
	return (
		<DashBoardLayout menuIndex={0}>
			<div className={styles.page_header}>
				<Typography variant="h4" color="primary">
					{" "}
					Legal Dashboard
				</Typography>
			</div>
			<ToastContainer />
			<br />
			<Dialog
				open={openConfirmForVettingDialog}
				onClose={handleConfirmForVettingClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Are you  sure?"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						By confirming it means you will critically vet this store.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleConfirmForVettingClose} color="primary">
						Cancel
					</Button>
					<Button
						onClick={() => {
							// dispatch(
							//   AcceptVetting(
							//     storeIdandAmount.id,
							//     authuid,
							//     true,
							//     storeIdandAmount.amount * 0.15
							//   )
							// );

							AcceptStoreToVetMutate(
								{
									id: storeIdandAmount.id,
									by: authuid,
									status: true,
									amount: storeIdandAmount.amount * 0.15,
								},
								{
									onSuccess: () => {
										toast.success("Store confirmed for vetting successfully!", {
											position: "top-right",
											autoClose: 5000,
											hideProgressBar: false,
											closeOnClick: true,
										});

										refreshPage();
									},
									onError: () => {
										toast.error("Oops! An error occured!  Try agin", {
											position: "top-right",
											autoClose: 5000,
											hideProgressBar: false,
											closeOnClick: true,
										});
									},
								}
							);
							handleConfirmForVettingClose();
							// setTimeout(() => {
							//   refreshPage()
							// }, 1000);
						}}
						color="primary"
						autoFocus
						variant="outlined"
					>
						{AcceptStoreToVetInfo.isLoading ? "Processing..." : "Confirm"}
					</Button>
				</DialogActions>
			</Dialog>

			<div className={styles.root}>
				<Container>
					{profile.role === LEGAL && (
						<Fragment>
							<Grid container>
								<Grid item xs={12} lg={2}></Grid>
								<Grid item xs={12} lg={8}>
									<Box boxShadow={5}>
										<MaterialTable
											style={{ width: "100%" }}
											title="New Stores to be vetted pool"
											columns={[
												{
													title: "Logo",
													field: "business_logo_id",
													render: (rowData) => (
														<>
															{rowData.business_logo_id === "" && (
																<div className={styles.business_logo_wrapper}>
																	<img src={EkiojaBag} alt="ekioja logo" />
																</div>
															)}
															{rowData.business_logo_id !== "" && (
																<div className={styles.business_logo_wrapper}>
																	<GetImageFromFirebase
																		source={rowData.business_logo_id}
																	/>
																</div>
															)}
														</>
													),
												},
												{ title: "Store name", field: "business_name" },
												{ title: "Niche", field: "business_niche" },
												{ title: "id", field: "id", hidden: true },
												{
													title: "amountpaid",
													field: "amountpaid",
													hidden: true,
												},
											]}
											data={toBeVettedData}
											options={{
												search: false,
												actionsColumnIndex: -1,
											}}
											actions={[
												{
													icon: "save",
													tooltip: "Save User",
													onClick: (event, rowData: any) => {
														setStoreIdandAmount({
															id: rowData.id,
															amount: rowData.amountpaid,
														});
														handleClickConfirmForVettingOpen();
													},
												},
											]}
											components={{
												Action: (props) => (
													<Button
														variant="contained"
														color="secondary"
														size="medium"
														onClick={(event) =>
															props.action.onClick(event, props.data)
														}
													>
														ACCEPT
													</Button>
												),
											}}
										/>
									</Box>
								</Grid>
								<Grid item xs={12} lg={2}></Grid>
							</Grid>
							<br />
							<br />
							<Typography variant="h6">Vetting quick links</Typography>
							<Divider />
							<br />
							<Grid container spacing={2}>
								<Grid item xs={12} lg={6}>
									<Grid container spacing={2}>
										<Grid item xs={12} lg={12}>
											<LegalWidget
												count={getVetDocsCounts(
													vetDocsData,
													ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS
												)}
												title="All Vet"
												routeQuery={ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS}
											/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<LegalWidget
												count={getVetDocsCounts(
													vetDocsData,
													ROUTES.VET_DOC_QUERIES.ALL_TO_BE_VETTED
												)}
												title="All To be vetted"
												routeQuery={ROUTES.VET_DOC_QUERIES.ALL_TO_BE_VETTED}
											/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<LegalWidget
												count={getVetDocsCounts(
													vetDocsData,
													ROUTES.VET_DOC_QUERIES.ALL_UNDER_VETTING
												)}
												title="All Undervetting"
												routeQuery={ROUTES.VET_DOC_QUERIES.ALL_UNDER_VETTING}
											/>
										</Grid>
										{/* <Grid item xs={12} lg={6}>
                      <LegalWidget
                        count={getVetDocsCounts(
                          vetDocsData,
                          ROUTES.VET_DOC_QUERIES.ALL_DRAFT
                        )}
                        title="All Draft"
                        routeQuery={ROUTES.VET_DOC_QUERIES.ALL_DRAFT}
                      />
                    </Grid> */}
										<Grid item xs={12} lg={6}>
											<LegalWidget
												count={getVetDocsCounts(
													vetDocsData,
													ROUTES.VET_DOC_QUERIES.ALL_PASSED
												)}
												title="All Passed "
												routeQuery={ROUTES.VET_DOC_QUERIES.ALL_PASSED}
											/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<LegalWidget
												count={getVetDocsCounts(
													vetDocsData,
													ROUTES.VET_DOC_QUERIES.ALL_FAILED
												)}
												title="All Failed "
												routeQuery={ROUTES.VET_DOC_QUERIES.ALL_FAILED}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Box
										boxShadow={6}
										style={{
											minHeight: 105,
											borderRadius: 10,
											width: "90%",
										}}
									>
										<List
											subheader={
												<ListSubheader component="div">
													Today's wallet data
												</ListSubheader>
											}
										>
											<ListItem>
												<ListItemText
													primary="Upcoming earnings"
													secondary={
														<Fragment>
															<NumberFormat
																value={getUpcomingRevenueTimeFrame(
																	toBeVetted,
																	"today"
																)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"₦"}
															/>
														</Fragment>
													}
												/>
											</ListItem>
											<Divider />
											<ListItem>
												<ListItemText
													primary="Pending Clearance"
													secondary={
														<Fragment>
															<NumberFormat
																value={getPendingClearanceRevenueTimeFrame(
																	underVetting,
																	"today"
																)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"₦"}
															/>
														</Fragment>
													}
												/>
											</ListItem>
											<Divider />
											<ListItem>
												<ListItemText
													primary="Earned (Passed vetting)"
													secondary={
														<Fragment>
															<NumberFormat
																value={getEarnedRevenuePassedTimeFrame(
																	passedVetted,
																	"today"
																)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"₦"}
															/>
														</Fragment>
													}
												/>
											</ListItem>
											<Divider />
											<ListItem>
												<ListItemText
													primary="Earned (Failed Vetting)"
													secondary={
														<Fragment>
															<NumberFormat
																value={getEarnedRevenueFailedTimeFrame(
																	failedVetted,
																	"today"
																)}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"₦"}
															/>
														</Fragment>
													}
												/>
											</ListItem>
											<Divider />

											<ListItem>
												<ListItemText
													primary="Available funds"
													secondary={
														<Fragment>
															<NumberFormat
																value={balance}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"₦"}
															/>
														</Fragment>
													}
												/>
												<ListItemSecondaryAction>
													<Button
														onClick={() =>
															navigateToLink.push(ROUTES.VET_WALLET)
														}
													>
														View wallet
													</Button>
												</ListItemSecondaryAction>
											</ListItem>
											<Divider />
										</List>
									</Box>
								</Grid>
							</Grid>
						</Fragment>
					)}
				</Container>
			</div>
		</DashBoardLayout>
	);
};

export default VetDashboard;
