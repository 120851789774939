import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DashBoardLayout from "../pages/Layouts/DashBoardLayout";
import Typography from "@material-ui/core/Typography";
import Chat from "../components/chat";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
		root: {
			padding: 20,
		},
	})
);

const Chats = () => {
	const styles = useStyles();
	return (
		<DashBoardLayout>
			<div className={styles.page_header}>
				<Typography variant="h4" color="primary">
					{" "}
					Chats
				</Typography>
			</div>
			<div className={styles.root}>
				<Chat />
			</div>
			<br />
		</DashBoardLayout>
	);
};

export default Chats;
