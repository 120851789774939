  
import { useMutation } from "react-query";
import firebase from '../../config/firebaseConfig';



const setHotPickProducts = (values:any)=>{

     //empty cart
     const hotPickProductsPromises: any = [];

     for (const item of values) {
       const p = firebase
         .firestore()
         .collection(`Products`)
         .doc(item.id)
         .set({ is_hot_product: true },{merge:true});
 
         hotPickProductsPromises.push(p);
     }

    return Promise.all([...hotPickProductsPromises])
}

 const useSetHotPickProducts = ()=>{
    return useMutation(setHotPickProducts);
}


export default useSetHotPickProducts;