import React from "react";
import { useParams } from "react-router-dom";
import BrandCreativesJobs from "../components/Creatives/Jobs/BrandCreativesJobs";
import FaceBehindTheBrandJobs from "../components/Creatives/Jobs/FaceBehindTheBrandJobs";
import MarketProductJobs from "../components/Creatives/Jobs/MarketProductJobs";
import DashBoardLayout from "./Layouts/DashBoardLayout";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { useNavigate } from "../hooks/navigate";
import * as ROUTES from "../constants/routes";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);
const CreateJobs = () => {
	const { query, type } = useParams<any>();
	const styles = useStyles();
	const { navigateToLink } = useNavigate();

	return (
		<DashBoardLayout menuIndex={2}>
			<div className={styles.page_header}>
				<Typography variant="h4" color="primary">
					{type === "brand-creative" && "Brand Creatives Jobs"}
					{type === "face-behind-the-brand" && "Face Behind The Brand Jobs"}
					{type === "market-product" && "Market Product Images Jobs"}
				</Typography>
			</div>

			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-evenly",
					width: "100%",
					height: 80,
				}}
			>
				<Typography variant="h6" color="inherit">
					Quick links :
				</Typography>
				<Typography
					variant="h6"
					color="inherit"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "all",
							type: "brand-creative",
						})
					}
					style={{ cursor: "pointer" }}
				>
					Brand creatives jobs
				</Typography>
				{" | "}
				<Typography
					variant="h6"
					color="inherit"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "all",
							type: "face-behind-the-brand",
						})
					}
					style={{ cursor: "pointer" }}
				>
					Face behind the brand jobs
				</Typography>
				{" | "}
				<Typography
					variant="h6"
					color="inherit"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "all",
							type: "market-product",
						})
					}
					style={{ cursor: "pointer" }}
				>
					Marketplace product images jobs
				</Typography>
			</div>
			<Divider />

			{type === "brand-creative" && <BrandCreativesJobs query={query} />}
			{type === "face-behind-the-brand" && (
				<FaceBehindTheBrandJobs query={query} />
			)}
			{type === "market-product" && <MarketProductJobs query={query} />}
		</DashBoardLayout>
	);
};

export default CreateJobs;
