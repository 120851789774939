import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//@ts-ignore
import AsyncSelect from "react-select/async";
import firebase from "../../../config/firebaseConfig";
import EkiojaBag from "../../../assets/images/ekioja-logo.jpg";
import { useSelector } from "react-redux";
//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";
//react redux firebase
import { isLoaded } from "react-redux-firebase";
import _ from "lodash";
import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import useSetTopPickProducts from "../../../hooks/Product/useSetTopPickProducts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const loadShopNameOptions = async (inputValue: string) => {
	inputValue = inputValue.toLowerCase().replace(/\W/g, "");
	return new Promise((resolve) => {
		firebase
			.firestore()
			.collection("Stores")
			// .where('type_of_story', '==', 'Shop')
			.orderBy("shop_plainName")
			.startAt(inputValue)
			.endAt(inputValue + "\uf8ff")
			.get()
			.then((docs) => {
				if (!docs.empty) {
					const shopNames: any[] = [];
					docs.forEach(function (doc) {
						const tag = {
							value: {
								id: doc.data().unique_id,
								business_logo_url: doc.data().business_logo_url,
							},
							label: doc.data().business_name,
						};
						shopNames.push(tag);
					});
					return resolve(shopNames);
				} else {
					return resolve([]);
				}
			});
	});
};

const PickTopPickProducts = () => {
	const [vendorData, setVendorData] = useState<any>({});
	const [vendorId, setVendorId] = useState("");
	const [productTableData, setProductTableData] = useState<any>([]);
	const [selectedTopPicks, setSelectedTopPicks] = useState<any>([]);
	const handleOnShopSearchChange = (tag: any) => {
		setVendorData({ ...tag });
		setVendorId(tag.value.id);
	};

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const [
		SetTopPickProductMutate,
		SetTopPickProductInfo,
	] = useSetTopPickProducts();

	useFirestoreConnect([
		{
			collection: "Products",
			where: [
				["visible_on_market", "==", true],
				["entity", "==", vendorId],
			],
			storeAs: "storeProductsForTopPick",
		},
	]);

	const storeProductsForTopPick = useSelector(
		(state: any) => state.firestore.data["storeProductsForTopPick"]
	);

	useEffect(() => {
		if (isLoaded(storeProductsForTopPick)) {
			let storeProductsForTopPick_data: any[] = [];
			if (storeProductsForTopPick !== null) {
				for (let storeProductsForTopPickkey in storeProductsForTopPick) {
					storeProductsForTopPick_data.push({
						...storeProductsForTopPick[storeProductsForTopPickkey],
						storeProductsForTopPick_uuid: storeProductsForTopPickkey,
					});
				}
			}

			let filtered_data = _.filter(
				storeProductsForTopPick_data,
				(item) =>
					item.is_top_product === undefined || item.is_top_product === false
			);

			let data = _.map(filtered_data, (item) => {
				return {
					id: item.storeProductsForTopPick_uuid,
					product_name: item.product_name,
				};
			});

			setProductTableData([...data]);
		}
	}, [storeProductsForTopPick]);

	return (
		<div style={{ paddingTop: 50 }}>
			<ToastContainer />
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="toppickproduct-confirmation-dialog-title"
				aria-describedby="toppickproduct-confirmation-dialog-description"
			>
				<DialogTitle id="toppickproduct-confirmation-dialog-title">
					{"Top picks Confirmation"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="toppickproduct-confirmation-dialog-description">
						Are you sure these selected products should be Top picks?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						onClick={() => {
							SetTopPickProductMutate(selectedTopPicks, {
								onSuccess: () => {
									toast.success("Top-picks added successfully!", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									handleClose();
								},
								onError: () => {
									toast.error("Oops! An error occured!  Try agin", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									handleClose();
								},
							});
						}}
						color="primary"
						autoFocus
					>
						{SetTopPickProductInfo.isLoading
							? "Adding..."
							: SetTopPickProductInfo.isError
							? "Error!"
							: SetTopPickProductInfo.isSuccess
							? "Added!"
							: "Add"}
					</Button>
				</DialogActions>
			</Dialog>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={1}></Grid>
				<Grid item xs={12} lg={5}>
					<AsyncSelect
						loadOptions={loadShopNameOptions}
						onChange={handleOnShopSearchChange}
					/>
					<br />
					{!_.isEmpty(vendorData) && (
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}
						>
							{vendorData.value.business_logo_url === undefined && (
								<img
									src={EkiojaBag}
									alt="ekioja logo"
									style={{
										width: 150,
										height: 150,
										borderRadius: 150,
										border: "1px solid green",
									}}
								/>
							)}

							{vendorData.value.business_logo_url !== undefined && (
								<img
									src={vendorData.value.business_logo_url}
									alt={vendorData.label}
									style={{
										width: 150,
										height: 150,
										borderRadius: 150,
										border: "1px solid green",
									}}
								/>
							)}

							<br />
							<Typography variant="h5" color="primary">
								{vendorData.label}
							</Typography>
						</div>
					)}
				</Grid>
				<Grid item xs={12} lg={5}>
					{!_.isEmpty(vendorData) && (
						<MaterialTable
							title="Products"
							columns={[
								{ title: "Product Name", field: "product_name" },
								{ title: "Id", field: "id", hidden: true },
							]}
							data={productTableData}
							options={{
								selection: true,
								search: true,
							}}
							actions={[
								{
									tooltip: "Make selected products Top picks!",
									icon: "add",
									onClick: (evt, data) => {
										handleClickOpen();
										//@ts-ignore
										setSelectedTopPicks([...data]);
									},
								},
							]}
						/>
					)}
				</Grid>
				<Grid item xs={12} lg={1}></Grid>
			</Grid>
		</div>
	);
};

export default PickTopPickProducts;
