import { useQuery } from "react-query";
import firebase from "../../config/firebaseConfig";

const getProductData = async (key: any, product_slug: any) => {
	const response = await firebase
		.firestore()
		.collection("Products")
		.where("product_permalink", "==", key.queryKey[1])
		.get();
	let data: any = [];

	response.forEach((doc) => {
		data.push({ id: doc.id, ...doc.data() });
	});

	return data;
};

const useGetProductByPermalink = (product_slug: any) => {
	return useQuery(["productData", product_slug], getProductData);
};

export default useGetProductByPermalink;
