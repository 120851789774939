import React from "react";
import Grid from "@material-ui/core/Grid";
import QuickLinkItem from "./QuickLinkItem";
import { getStoresCounts } from "../../../utils/index";
import * as ROUTES from "../../../constants/routes";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import useGetAllStoreData from "../../../hooks/stores/useGetAllStoreData";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorTwoToneIcon from "@material-ui/icons/ErrorTwoTone";

const StoresLinksList = () => {
	const [stores, setStores] = React.useState<any>([]);

	const allStoreQuery = useGetAllStoreData();

	React.useEffect(() => {
		if (allStoreQuery.isSuccess) {
			setStores([...allStoreQuery.data]);
		}
	}, [allStoreQuery]);

	const list = [
		{
			count: getStoresCounts(stores, ROUTES.VIEWALLSTORESQUERIES.ALLSTORES),
			title: "All Stores",
			routeQuery: ROUTES.VIEWALLSTORESQUERIES.ALLSTORES,
		},
		{
			count: getStoresCounts(
				stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES
			),
			title: "All Active Stores",
			routeQuery: ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES,
		},
		{
			count: getStoresCounts(
				stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES
			),
			title: "All In-Active Stores",
			routeQuery: ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES,
		},
		{
			count: getStoresCounts(
				stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES
			),
			title: "All Blocked Stores",
			routeQuery: ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES,
		},
		{
			count: getStoresCounts(
				stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES
			),
			title: "UnPaid Pending Stores",
			routeQuery: ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES,
		},
		{
			count: getStoresCounts(
				stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES
			),
			title: "Approved Stores",
			routeQuery: ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES,
		},
		{
			count: getStoresCounts(
				stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES
			),
			title: "Not Approved Stores",
			routeQuery: ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES,
		},
		{
			count: getStoresCounts(
				stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_UNDER_VETTING_STORES
			),
			title: "Under-Vetting Stores",
			routeQuery: ROUTES.VIEWALLSTORESQUERIES.ALL_UNDER_VETTING_STORES,
		},
		{
			count: getStoresCounts(
				stores,
				ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE
			),
			title: "Vetted Stores",
			routeQuery: ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE,
		},
		{
			count: getStoresCounts(
				stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE
			),
			title: "Failed Vetting Stores",
			routeQuery: ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE,
		},
	];

	return (
		<div style={{ position: "relative" }}>
			{allStoreQuery.isFetching && (
				<div
					style={{
						width: "100%",
						height: 410,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						background: "#fff",
						position: "absolute",
						color: "#000",
						zIndex: 999,
					}}
				>
					<CircularProgress />
					Welcome back, updating store data...
				</div>
			)}
			{allStoreQuery.isError && (
				<div
					style={{
						width: "100%",
						height: 410,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						background: "#fff",
						position: "absolute",
						color: "red",
						zIndex: 888,
					}}
				>
					<ErrorTwoToneIcon
						style={{ color: "red", fontSize: 70, marginBottom: 5 }}
					/>
					Error loading data! Check your internet connection
				</div>
			)}

			{allStoreQuery.isLoading && (
				<>
					<Skeleton variant="rect" width="20%" height={30} animation="wave" />
					<div style={{ marginBottom: 5 }} />
				</>
			)}
			{allStoreQuery.isSuccess && (
				<Typography variant="h6">Stores Quick links</Typography>
			)}

			<Divider />
			<br />
			<Grid container spacing={2}>
				{allStoreQuery.isSuccess &&
					list.map((item) => (
						<Grid key={item.title} item xs={12} lg={3}>
							<QuickLinkItem
								count={item.count}
								title={item.title}
								routeQuery={item.routeQuery}
								query="store"
							/>
						</Grid>
					))}
				{allStoreQuery.isLoading && (
					<Skeleton variant="rect" width="100%" height={300} animation="wave" />
				)}
			</Grid>
		</div>
	);
};

export default StoresLinksList;
