import { useQuery, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

const getAllKYCReports = async () => {
	const response = await firebase.firestore().collection("KYCReports").get();
	let data: any = [];
	response.forEach((doc: any) => {
		data.push({ id: doc.id, ...doc.data() });
	});
	return data;
};

const useGetAllKYCReports = () => {
	return useQuery("allKYCReports", getAllKYCReports, {
		initialData: () => {
			if (queryCache.getQueryData("allKYCReports")) {
				return queryCache.getQueryData("allKYCReports");
			}
		},
		refetchOnWindowFocus: false,
	});
};

export default useGetAllKYCReports;
