import { useQuery, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

const getLogisticsCompany = async (key: any, unique_id: string) => {
	const response = await firebase
		.firestore()
		.collection("LogisticsCompanies")
		.where("unique_id", "==", unique_id)
		.get();
	let data: any = [];
	response.forEach((doc: any) => {
		data.push({ id: doc.id, ...doc.data() });
	});
	return data;
};

const useGetLogisticsCompany = (unique_id: string) => {
	return useQuery(["SingleLogisticsCompany", unique_id], getLogisticsCompany, {
		initialData: () => {
			if (queryCache.getQueryData("SingleLogisticsCompany")) {
				return queryCache.getQueryData("SingleLogisticsCompany");
			}
		},
		refetchOnWindowFocus: false,
	});
};

export default useGetLogisticsCompany;
