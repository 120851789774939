import { useMutation } from "react-query";
import firebase from "../../../config/firebaseConfig";

const requestWithdraw = async (values: any) => {
	const walletDocRef = firebase
		.firestore()
		.collection("Wallets")
		.doc(values.wallet_id);

	const walletToPayoutPomise = firebase
		.firestore()
		.runTransaction((transaction) => {
			return transaction.get(walletDocRef).then((transactionDoc) => {
				if (!transactionDoc.exists) {
				}

				let newIncome = transactionDoc.data()?.income - parseInt(values.amount);
				let newPayout =
					transactionDoc.data()?.pending_payout + parseInt(values.amount);

				if (typeof transactionDoc.data()?.pending_payout === "undefined") {
					transaction.set(
						walletDocRef,
						{
							income: newIncome,
							pending_payout: parseInt(values.amount),
						},
						{ merge: true }
					);
				} else {
					transaction.set(
						walletDocRef,
						{
							income: newIncome,
							pending_payout: newPayout,
						},
						{ merge: true }
					);
				}
			});
		});

	const sendWithdraRequestToAdmin = firebase
		.firestore()
		.collection("RequestedPayouts")
		.doc()
		.set({
			...values,
			amount: parseInt(values.amount),
			date_added: firebase.firestore.FieldValue.serverTimestamp(),
		});

	const allPormises = Promise.all([
		walletToPayoutPomise,
		sendWithdraRequestToAdmin,
	]);

	return allPormises;
};

const useRequestWithdraw = () => {
	return useMutation(requestWithdraw);
};

export default useRequestWithdraw;
