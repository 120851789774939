import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const createLogisticsPartnerAccount = async (values: any) => {
	const response = await firebase
		.auth()
		.createUserWithEmailAndPassword(values.email, values.password);

	//add displayName
	const updateProfilePromise = firebase.auth().currentUser?.updateProfile({
		displayName: values.first_name + " " + values.last_name,
	});

	//create logistics company account if exist

	//create new doc in Admin collection
	const createCollectionPromise = firebase
		.firestore()
		.collection("Admins")
		.doc(response.user?.uid)
		.set({
			first_name: values.first_name,
			last_name: values.last_name,
			username: values.username,
			role: values.role,
			entity: values.entity,
			contact_phone_number: values.contact_phone_number,
			logistics_role: values.logistics_role,
			check_terms_and_condition: values.check_terms_and_condition,
			email: values.email,
			deviceData: values.deviceData,
			approved: true,
			blocked: false,
			isNew: true,
			blocked_history: [],
			removed: false,
			removed_data: [],
			joined_on: firebase.firestore.FieldValue.serverTimestamp(),
		});

	//create wallet

	const createStoreWallet = await firebase
		.firestore()
		.collection("Wallets")
		.doc(values.entity)
		.set({
			income: 0,
			owner_id: values.entity,
		});

	const createLogisticsAccount = await firebase
		.firestore()
		.collection("LogisticsCompanies")
		.doc(values.entity)
		.set({
			logistics_company_name: values.logistics_company_name,
			unique_id: values.entity,
			address: values.address,
			website: values.website,
			approved: false,
			active: false,
			blocked: false,
			isNew: true,
			admins: [response.user?.uid],
			psreciept_code: "",
			psreciept_data: {
				account_name: "",
				account_number: "",
				bank_code: "",
			},
			joined_on: firebase.firestore.FieldValue.serverTimestamp(),
		});

	//send email verification
	const sendEmailPromise = firebase.auth().currentUser?.sendEmailVerification();

	//allpromise
	const allPromise = Promise.all([
		updateProfilePromise,
		createCollectionPromise,
		sendEmailPromise,
		createLogisticsAccount,
		createStoreWallet,
	]);

	return allPromise;
};

const useCreateLogisticsPartnerAccount = () => {
	return useMutation(createLogisticsPartnerAccount);
};

export default useCreateLogisticsPartnerAccount;
