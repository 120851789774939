import { useQuery } from "react-query";
import firebase from "../../config/firebaseConfig";

const getAllProducts = async () => {
	const response = await firebase.firestore().collection("Products").get();
	let data: any = [];
	response.forEach((doc: any) => {
		data.push({ id: doc.id, ...doc.data() });
	});
	return data;
};

const useGetAllProducts = () => {
	return useQuery("allProducts", getAllProducts);
};

export default useGetAllProducts;
