import React, { useState, Fragment } from 'react';
// import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="standard" {...props} />;
}


interface Props {
    title: string;
    body: string;
    action?: () => void;
    hide:()=>void;
    show: boolean;
    severity: 'success' | 'error' | 'warning' | 'info';
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 200,

        },

    }),
);


const MKVAlert = ({ title, body, show, severity  ,hide}: Props) => {
    const style = useStyles();

    const [visibility, setVisibility] = useState(show);

    const handleHide = (event?: React.SyntheticEvent, reason?: string) => {
        hide();
        if (reason === 'clickaway') {
            return;
        }

        setVisibility(!visibility);
    };

    return (
        <Fragment>
            <div className={style.root}>
                <Snackbar open={show} autoHideDuration={20000} onClose={handleHide} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert onClose={handleHide} severity={severity}>
                        <AlertTitle>{title}</AlertTitle>
                        {body}
                    </Alert>
                </Snackbar>
            </div>
        </Fragment>
    )
}

export default MKVAlert
