import { useQuery, queryCache } from "react-query";
import firebase from "../../../config/firebaseConfig";

const getAllFBTBList = async () => {
	const response = await firebase.firestore().collection("FBTB").get();
	let data: any = [];
	response.forEach((doc: any) => {
		data.push({ id: doc.id, ...doc.data() });
	});
	return data;
};

const useGetAllFBTBList = () => {
	return useQuery("allFBTBList", getAllFBTBList, {
		initialData: () => {
			if (queryCache.getQueryData("allFBTBList")) {
				return queryCache.getQueryData("allFBTBList");
			}
		},
		refetchOnWindowFocus: false,
	});
};

export default useGetAllFBTBList;
