import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import NumberFormat from "react-number-format";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { TextField, Select } from "formik-material-ui";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { isLoaded } from "react-redux-firebase";
import _ from "lodash";
import clsx from "clsx";
// import { refreshPage } from "../../../utils";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

import useGetPaystackBanks from "../../hooks/payment/useGetPaystackBanks";
import usePSResolveAccountNumber from "../../hooks/payment/usePSResolveAccountNumber";
import useCreatePSTransferReceipt from "../../hooks/payment/logistics/useCreatePSTransferReceipt";
import useRemoveBankAccountData from "../../hooks/payment/logistics/useRemoveBankAccountData";
import useRequestWithdraw from "../../hooks/payment/logistics/useRequestWithdraw";

import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		height: 100,
		width: "100%",
		borderRadius: 20,
	},
	money_title: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "column",
	},
	atm_icon1: {
		color: "#22b358",
	},
	atm_icon2: {
		color: "#b33022",
	},
	atm_icon3: {
		color: "#22b3a0",
	},
	atm_icon4: {
		color: "#22b358",
	},
	available_wrapper: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	page_header: {
		width: "100%",
		height: 100,
		backgroundColor: "#99ff99",
		paddingTop: 20,
		paddingLeft: 20,
	},
	verfiy_continue_wrapper: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
	},
	card_border1: {
		borderLeft: "5px solid #22b358",
		height: 100,
	},
	card_border2: {
		borderLeft: "5px solid #b33022",
		height: 100,
	},
	card_border3: {
		borderLeft: "5px solid #22b3a0",
		height: 100,
	},
}));

function NumberFormatCustom(props: any) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			prefix="₦"
		/>
	);
}

const LogisticsPaymentWidget = () => {
	const styles = useStyles();

	const [balance, setBalance] = useState(0);
	const [pendingPayout, setPendingPayout] = useState(0);
	const [walletData, setWalletData] = useState<any>({});

	const [widthdrawRequestModal, setWidthdrawRequestModal] = useState(false);
	const [
		changeAccountConfirmationDialog,
		setChangeAccountConfirmationDialog,
	] = useState(false);

	const [accountNumber, setAccountNumber] = useState("");
	const [bankCode, setBankCode] = useState("");

	const profile = useSelector((state: any) => state.firebase.profile);

	const authuid = useSelector((state: any) => state.firebase.auth.uid);

	const GetPaystackBanksQuery = useGetPaystackBanks("nigeria", "NGN");
	const PSResolveAccountNumberQuery = usePSResolveAccountNumber(
		accountNumber,
		bankCode
	);

	const [
		CreatePSTransferReceiptMutate,
		CreatePSTransferReceiptInfo,
	] = useCreatePSTransferReceipt();

	const [
		RemoveBankAccountMutate,
		RemoveBankAccountInfo,
	] = useRemoveBankAccountData();
	const [RequestWithdrawMutate, RequestWithdrawInfo] = useRequestWithdraw();

	const withdraValidationSchema = Yup.object().shape({
		amount: Yup.string()
			.required("Amount is required")
			.test(
				"Enter the amount you want to withdraw",
				"Requested amount should not be more than your balance or less than 1000 NGN",
				(value: any) => {
					return parseInt(value) > balance || parseInt(value) < 1000
						? false
						: true;
				}
			),
	});
	const verifyAccountValidationSchema = Yup.object().shape({
		account_number: Yup.string().required("Amount is required"),
		bank_code: Yup.string().required("Select bank "),
	});

	useFirestoreConnect([
		{
			collection: "Wallets",
			where: [["owner_id", "==", isLoaded(profile) ? profile.entity : "nil"]],
			storeAs: "Wallets",
		},
		{
			collection: "LogisticsCompanies",
			doc: isLoaded(profile) ? profile.entity : "nil",
		},

		// {
		//   collection: "Transactions",
		// },
	]);

	const wallet = useSelector((state: any) => state.firestore.data["Wallets"]);

	const logisticsCompany = useSelector(
		(state: any) => state.firestore.ordered.LogisticsCompanies
	);

	useEffect(() => {
		if (isLoaded(wallet)) {
			let wallet_data = [];
			if (wallet !== null) {
				for (var walletkey in wallet) {
					wallet_data.push({
						amount: wallet[walletkey].income,
						pending_payout: wallet[walletkey].pending_payout,
						wallet_uuid: walletkey,
					});
				}
			}

			setWalletData({ ...wallet_data[0] });

			if (_.has(wallet_data[0], "amount")) {
				setBalance(wallet_data[0].amount);
			}

			if (
				_.has(wallet_data[0], "pending_payout") &&
				typeof wallet_data[0].pending_payout !== "undefined"
			) {
				setPendingPayout(wallet_data[0].pending_payout);
			}
		}
	}, [wallet]);

	return (
		<div>
			<ToastContainer />

			<Dialog
				onClose={() => setChangeAccountConfirmationDialog(false)}
				aria-labelledby="withdraw-dialog"
				open={changeAccountConfirmationDialog}
				maxWidth="xs"
				fullWidth={true}
				id="change-account-confirm-modal"
			>
				<DialogTitle>Are you sure?</DialogTitle>
				<DialogContentText>
					You are about to permanently remove the current bank account. You will
					have to add a new one to withdraw the funds to.
				</DialogContentText>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						style={{ color: "#fff" }}
						onClick={() =>
							RemoveBankAccountMutate(profile.entity, {
								onSuccess: () => {
									RemoveBankAccountInfo.reset();
									setChangeAccountConfirmationDialog(false);
									// setWidthdrawRequestModal(false);
									toast.success(
										"Your bank account have been removed successfully!",
										{
											position: "top-right",
											autoClose: 5000,
											hideProgressBar: false,
											closeOnClick: true,
										}
									);
									// setTimeout(() => {
									// 	refreshPage();
									// }, 1000);
								},
								onError: (err: any) => {
									toast.error(`${err.message}`, {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
								},
							})
						}
					>
						{RemoveBankAccountInfo.isLoading && "Deleting..."}
						{RemoveBankAccountInfo.isIdle && "Confirm"}
						{RemoveBankAccountInfo.isSuccess && "Deleted!"}
					</Button>
					<Button
						variant="text"
						color="secondary"
						onClick={() => setChangeAccountConfirmationDialog(false)}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				onClose={() => setWidthdrawRequestModal(false)}
				aria-labelledby="withdraw-dialog"
				open={widthdrawRequestModal}
				maxWidth="xs"
				fullWidth={true}
				id="withdraw-modal"
			>
				<DialogTitle>
					{typeof logisticsCompany !== "undefined" &&
					_.has(logisticsCompany[0], "psreciept_code") &&
					logisticsCompany[0].psreciept_code === ""
						? "Add a bank account"
						: "Request Withdrawal"}
				</DialogTitle>

				<DialogContent>
					{typeof logisticsCompany !== "undefined" &&
						_.has(logisticsCompany[0], "psreciept_code") &&
						logisticsCompany[0].psreciept_code === "" && (
							<Formik
								initialValues={{
									bank_code: "",
									account_number: "",
								}}
								validationSchema={verifyAccountValidationSchema}
								onSubmit={(values, { setSubmitting }) => {
									setBankCode(values.bank_code);
									setAccountNumber(values.account_number);
									setTimeout(() => {
										setSubmitting(false);

										PSResolveAccountNumberQuery.refetch();
									}, 1000);
								}}
							>
								{({
									submitForm,
									isSubmitting,
									isValid,
									resetForm,
									status,
									setStatus,
								}) => (
									<Form>
										<div>
											{isSubmitting && <LinearProgress />}
											{GetPaystackBanksQuery.isLoading && (
												<p>Please wait... Loading bank list</p>
											)}
											{GetPaystackBanksQuery.isSuccess && (
												<>
													{" "}
													<FormControl fullWidth>
														<InputLabel id="open-psbank-list-label">
															Select your bank from the list
														</InputLabel>
														<Field
															component={Select}
															name="bank_code"
															inputProps={{
																id: "open-psbank-list",
															}}
														>
															{GetPaystackBanksQuery.data.map((item: any) => (
																<MenuItem key={item.code} value={item.code}>
																	{item.name}
																</MenuItem>
															))}
														</Field>
													</FormControl>
													<ErrorMessage
														name="bank_code"
														render={(msg) => (
															<span
																style={{
																	color: "#B33022",
																	fontSize: "0.75rem",
																}}
															>
																{msg}
															</span>
														)}
													/>
													<br />
													<br />
													<Field
														component={TextField}
														name="account_number"
														fullWidth
														placeholder="Enter your account number"
													/>
												</>
											)}

											<br />
											<br />

											{bankCode !== "" &&
												!PSResolveAccountNumberQuery.isLoading &&
												typeof PSResolveAccountNumberQuery.data !==
													"undefined" &&
												PSResolveAccountNumberQuery.isSuccess &&
												status === "" && (
													<Typography variant="subtitle2" color="primary">
														Account Name :
														{PSResolveAccountNumberQuery.data.data.account_name}{" "}
														<br />
														Account Number :
														{
															PSResolveAccountNumberQuery.data.data
																.account_number
														}
													</Typography>
												)}

											<br />
											<br />

											{bankCode !== "" &&
												!PSResolveAccountNumberQuery.isLoading &&
												PSResolveAccountNumberQuery.data === undefined && (
													<Typography
														variant="subtitle2"
														style={{ color: "red" }}
													>
														Account is Invalid or disabled or does to exist with
														the selected bank. Check and try again
													</Typography>
												)}

											<div className={styles.verfiy_continue_wrapper}>
												{isValid && (
													<Button
														variant="contained"
														color="primary"
														style={{ color: "#fff" }}
														disabled={
															isSubmitting ||
															!isValid ||
															(!PSResolveAccountNumberQuery.isLoading &&
																typeof PSResolveAccountNumberQuery.data !==
																	"undefined" &&
																status === "")
														}
														onClick={() => {
															submitForm();
															setStatus("");
														}}
													>
														{bankCode !== "" &&
														PSResolveAccountNumberQuery.isLoading
															? "Verifying"
															: !PSResolveAccountNumberQuery.isLoading &&
															  typeof PSResolveAccountNumberQuery.data !==
																	"undefined" &&
															  status === ""
															? "Verified"
															: "Verify"}
													</Button>
												)}

												{!PSResolveAccountNumberQuery.isLoading &&
													typeof PSResolveAccountNumberQuery.data !==
														"undefined" &&
													status === "" && (
														<Button
															variant="contained"
															style={{
																color: "#fff",
																backgroundColor: "#ff0000",
															}}
															onClick={() => {
																PSResolveAccountNumberQuery.remove();
																resetForm();
																setStatus("reset");
															}}
														>
															Reset
														</Button>
													)}

												{bankCode !== "" &&
													!PSResolveAccountNumberQuery.isLoading &&
													typeof PSResolveAccountNumberQuery.data !==
														"undefined" &&
													PSResolveAccountNumberQuery.isSuccess &&
													status === "" && (
														<Button
															variant="contained"
															color="primary"
															style={{ color: "#fff" }}
															onClick={() =>
																CreatePSTransferReceiptMutate(
																	{
																		account_name:
																			PSResolveAccountNumberQuery.data.data
																				.account_name,
																		account_number:
																			PSResolveAccountNumberQuery.data.data
																				.account_number,
																		bank_code: bankCode,
																		store_id: profile.entity,
																		store_name:
																			logisticsCompany[0]
																				.logistics_company_name,
																	},
																	{
																		onSuccess: () => {
																			// setWidthdrawRequestModal(false);
																			toast.success(
																				"Your bank account have been added successfully!",
																				{
																					position: "top-right",
																					autoClose: 3000,
																					hideProgressBar: false,
																					closeOnClick: true,
																				}
																			);
																		},
																		onError: (err: any) => {
																			toast.error(`${err.message}`, {
																				position: "top-right",
																				autoClose: 5000,
																				hideProgressBar: false,
																				closeOnClick: true,
																			});
																		},
																	}
																)
															}
														>
															{CreatePSTransferReceiptInfo.isLoading
																? "Processing..."
																: CreatePSTransferReceiptInfo.isError
																? "Error!"
																: CreatePSTransferReceiptInfo.isSuccess
																? "Done!"
																: "Continue"}
														</Button>
													)}
											</div>
										</div>
									</Form>
								)}
							</Formik>
						)}
					{typeof logisticsCompany !== "undefined" &&
						_.has(logisticsCompany[0], "psreciept_code") &&
						logisticsCompany[0].psreciept_code !== "" && (
							<Formik
								initialValues={{
									amount: "",
									store_id: profile.entity,
									request_by_id: authuid,
									request_by_name: `${profile.first_name} ${profile.last_name}`,
									wallet_id: walletData?.wallet_uuid,
									store_name: logisticsCompany[0].logistics_company_name,
									psreciept_code: logisticsCompany[0].psreciept_code,
									reason: "LOGISTICS_PAYOUT",
									fulfilled: false,
									persona: "Logistics",
								}}
								validateOnBlur
								validationSchema={withdraValidationSchema}
								onSubmit={(values, { setSubmitting }) => {
									setTimeout(() => {
										setSubmitting(false);
										// console.log(values);
									}, 1000);
								}}
							>
								{({ isSubmitting, errors, touched, values }) => (
									<Form>
										<Grid container>
											<Grid item xs={12} lg={12}>
												{isSubmitting && <LinearProgress />}
												<Field
													component={TextField}
													name="amount"
													fullWidth
													InputProps={{
														inputComponent: NumberFormatCustom,
													}}
													placeholder="Enter the amount you want to withdraw (minimum off ₦1000 )"
												/>

												<br />
												<br />
												<Typography variant="subtitle2" color="primary">
													Account Name :
													{logisticsCompany[0].psreciept_data.account_name}{" "}
													<br />
													Account Number:
													{logisticsCompany[0].psreciept_data.account_number}
													<br />
													Account Bank :
													{GetPaystackBanksQuery.isSuccess &&
														_.result(
															_.find(GetPaystackBanksQuery.data, function (
																obj
															) {
																return (
																	obj.code ===
																	logisticsCompany[0].psreciept_data.bank_code
																);
															}),
															"name"
														)}
												</Typography>
												<br />

												<div className={styles.verfiy_continue_wrapper}>
													<Button
														variant="contained"
														color="primary"
														style={{ color: "#fff" }}
														onClick={() =>
															setChangeAccountConfirmationDialog(true)
														}
													>
														Change Account
													</Button>
													{!errors.amount && touched.amount ? (
														<Button
															variant="contained"
															color="primary"
															style={{ color: "#fff" }}
															disabled={isSubmitting}
															onClick={() =>
																RequestWithdrawMutate(values, {
																	onSuccess: () => {
																		toast.success(
																			"Your request have been processed successfully!",
																			{
																				position: "top-right",
																				autoClose: 5000,
																				hideProgressBar: false,
																				closeOnClick: true,
																			}
																		);
																		setWidthdrawRequestModal(false);
																	},
																	onError: (err: any) => {
																		toast.error(`${err.message}`, {
																			position: "top-right",
																			autoClose: 5000,
																			hideProgressBar: false,
																			closeOnClick: true,
																		});
																	},
																})
															}
														>
															{RequestWithdrawInfo.isLoading
																? "Processing..."
																: RequestWithdrawInfo.isError
																? "Error!"
																: RequestWithdrawInfo.isSuccess
																? "Done!"
																: "Request Withdraw"}
														</Button>
													) : (
														""
													)}
												</div>
											</Grid>
										</Grid>
									</Form>
								)}
							</Formik>
						)}
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						color="secondary"
						onClick={() => setWidthdrawRequestModal(false)}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>

			<br />
			<br />
			<Container>
				<div>
					{!isLoaded(profile) && !isLoaded(wallet) && (
						<Grid container spacing={3}>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Skeleton
									variant="rect"
									width="100%"
									height={120}
									animation="wave"
								/>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Skeleton
									variant="rect"
									width="100%"
									height={120}
									animation="wave"
								/>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<Skeleton
									variant="rect"
									width="100%"
									height={120}
									animation="wave"
								/>
							</Grid>
						</Grid>
					)}
					{isLoaded(profile) && isLoaded(wallet) && (
						<Grid container spacing={3}>
							<Grid item lg={4} md={4} sm={6} xs={12}>
								<Card
									className={clsx(
										"h-96 bg-default flex items-center justify-between p-4",
										styles.card_border1
									)}
								>
									<div>
										<span className="e-green-text uppercase">
											Available funds for withdrawal
										</span>
										<h4 className="font-medium e-green-text m-0 pt-2">
											<NumberFormat
												value={balance}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"₦"}
											/>
										</h4>
									</div>
								</Card>
							</Grid>
							<Grid item lg={4} md={4} sm={6} xs={12}>
								<Card
									className={clsx(
										"h-96 bg-default flex items-center justify-between p-4",
										styles.card_border2
									)}
								>
									<div>
										<span className="e-red-text uppercase">Pending payout</span>
										<h4 className="font-medium e-red-text m-0 pt-2">
											<NumberFormat
												value={pendingPayout}
												displayType={"text"}
												thousandSeparator={true}
												prefix={"₦"}
											/>
										</h4>
									</div>
								</Card>
							</Grid>
							<Grid item lg={4} md={4} sm={6} xs={12}>
								<Card
									className={clsx(
										"h-96 bg-default flex items-center justify-between p-4",
										styles.card_border3
									)}
								>
									<div>
										<span className="e-blue-text uppercase">Withdrawal</span>

										{balance >= 1000 && (
											<Button
												style={{ marginLeft: 40 }}
												onClick={() => setWidthdrawRequestModal(true)}
												className="font-medium e-blue-text m-0 pt-2"
											>
												{typeof logisticsCompany !== "undefined" &&
												_.has(logisticsCompany[0], "psreciept_code") &&
												logisticsCompany[0].psreciept_code === ""
													? "Add bank account data"
													: "Request funds Withdrawal"}
											</Button>
										)}
									</div>
								</Card>
							</Grid>
						</Grid>
					)}
				</div>
			</Container>
		</div>
	);
};

export default LogisticsPaymentWidget;
