import React, { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//material table
import MaterialTable, { MTableToolbar } from "material-table";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//utils
import { getStores } from "../utils/index";

//routes
import * as ROUTES from "../constants/routes";

//get image
import GetImageFromFirebase from "../components/GetImageFromFirebase";

//chip
import Chip from "@material-ui/core/Chip";

import Grid from "@material-ui/core/Grid";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

//utils
import { getStoresCounts } from "../utils/index";

import PaymentIcon from "@material-ui/icons/Payment";
import BeenhereTwoToneIcon from "@material-ui/icons/BeenhereTwoTone";
import BlockTwoToneIcon from "@material-ui/icons/BlockTwoTone";
import AssistantIcon from "@material-ui/icons/Assistant";

import EkiojaBag from "../assets/images/ekioja-logo.jpg";

import ViewStoreDetailModal from "../components/Store/Assessment/ViewStoreDetailModal";
import EditStoreDetailsModal from "../components/Store/Assessment/EditStoreDetailsModal";

import useGetAllStoreData from "../hooks/stores/useGetAllStoreData";
import useDeleteStore from "../hooks/stores/useDeleteStore";
import usePickPackage from "../hooks/stores/usePickPackage";
import usePickPackageMultiple from "../hooks/stores/usePickPackageMultiple";

import useKYCPassVetting from "../hooks/kyc/useKYCPassVetting";
import useKYCFlag from "../hooks/kyc/useKYCFlag";

import { Scrollbars } from "rc-scrollbars";

import VettedStores from "../components/Store/VettedStores";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
// import _ from "lodash";

//formik
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import shortid from "shortid";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import useAddKYCReportEntry from "../hooks/kyc/useAddKYCReportEntry";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			borderRadius: 20,
			width: "100%",
			margin: 20,
		},
		appBar: {
			position: "relative",
			backgroundColor: "#fff",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		vendor_logo: {
			width: 50,
			height: 50,
			borderRadius: 50,
			border: "1px white solid",
		},
		add_new_user_button: {
			marginTop: "5%",
		},
		business_logo_wrapper: {
			width: 100,
			height: 100,
			borderRadius: 100,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& img": {
				width: 80,
				height: 80,
				borderRadius: 80,
			},
		},
		queries_wrapper: {
			width: 1000,
			padding: 2,
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
		detail_panel_wrapper: {
			padding: 10,
		},
		pick_package_item_wrapper: {
			width: "100%",
			backgroundColor: "#161623 ",
			padding: 12,
			overflow: "hidden",
			borderRadius: 10,
		},
		pick_package_item: {
			width: "100%",
			height: 180,
			borderRadius: 10,
			backgroundColor: "rgba(255,255,225,0.2)",
			borderTop: "1px solid rgba(255,255,225,0.6) ",
			borderLeft: "1px solid rgba(255,255,225,0.6) ",
			color: "#fff",
			textAlign: "center",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			cursor: "pointer",
			transition: "0.3s",
			zIndex: 10,
			"&:hover": {
				backgroundColor: "rgba(255,255,255,1)",
				color: "#99ff99",
				transform: "scale(0.9)",
			},
			position: "relative",
			"&:before": {
				content: "''",
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				background: "linear-gradient(#f00, #99ff99)",
				clipPath: "circle(20% at right 70%)",
				// zIndex: -1,
			},
			"&:after": {
				content: "''",
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				background: "linear-gradient(#99ff99, #e91e63)",
				clipPath: "circle(20% at 10% 10%)",
				// zIndex: -1,
			},
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Stores = () => {
	const { query } = useParams<any>();
	const authuid = useSelector((state: any) => state.firebase.auth.uid);
	const [storeData, setStoreData] = useState<any>([]);
	const [tableTitle, setTableTitle] = useState("");
	const styles = useStyles();
	const allStoreQuery = useGetAllStoreData();
	const [DeleteStoreMutate, DeleteStoreInfo] = useDeleteStore();
	const [KYCPassVettingMutate, KYCPassVettingInfo] = useKYCPassVetting();
	const [KYCFlagMutate, KYCFlagInfo] = useKYCFlag();
	const [PickPackageMutate, PickPackageInfo] = usePickPackage();
	const [
		PickPackageMultipleMutate,
		PickPackageMultipleInfo,
	] = usePickPackageMultiple();

	const [
		AddKYCReportEntryMutate,
		AddKYCReportEntryInfo,
	] = useAddKYCReportEntry();

	const [openModal, setOpenModal] = React.useState(false);
	const refTableContainer = useRef<any>(null);

	const handleClickOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const [storeModalData, setStoreModalData] = useState<any>({});
	const [storeModalTitle, setStoreModalTitle] = useState("");

	const [storeToDelete, setStoreToDelete] = useState<any>({});

	const Stores = allStoreQuery.isSuccess ? allStoreQuery.data : [];

	const [
		openStoreDeleteConfirmation,
		setOpenStoreDeleteConfirmation,
	] = useState(false);

	const [openDecisionModal, setOpenDecisionModal] = useState(false);
	const [kycDecideStoreId, setKycDecideStoreId] = useState("");

	const [togglePickPackagePage, setTogglePickPackagePage] = useState(false);
	const [pickPackageStoreId, setPickPackageStoreId] = useState("");

	const [togglePickPackageMultiple, setTogglePickPackageMultiple] = useState(
		false
	);
	const [pickPackageStoreIdArr, setPickPackageStoreIdArr] = useState<any>([]);

	const [addNewUserModalState, setAddNewUserModalState] = useState(false);

	const toggleAddNewUserModal = () => {
		setAddNewUserModalState(!addNewUserModalState);
	};

	useEffect(() => {
		if (allStoreQuery.isSuccess) {
			const result_allstores = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.ALLSTORES
			);
			const result_all_active_stores = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES
			);
			const result_all_in_active_stores = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES
			);
			const result_all_approved_stores = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES
			);
			const result_all_not_approved_stores = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES
			);
			const result_all_blocked_stores = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES
			);
			const result_all_paid_for_review_stores = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_PAID_PENDING_STORES
			);
			const result_all__not_paid_for_review_stores = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES
			);
			const result_all_under_vetting = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_UNDER_VETTING_STORES
			);
			const result_all_vetted = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE
			);
			const result_all_failed_vet = getStores(
				Stores,
				ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE
			);

			switch (query) {
				case ROUTES.VIEWALLSTORESQUERIES.ALLSTORES:
					setStoreData([...result_allstores]);

					setTableTitle("All Stores");
					break;

				case ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES:
					setStoreData([...result_all_active_stores]);

					setTableTitle("All Active Stores");
					break;
				case ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES:
					setStoreData([...result_all_in_active_stores]);

					setTableTitle("All In-active Stores");
					break;
				case ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES:
					setStoreData([...result_all_approved_stores]);

					setTableTitle("All Approved Stores");
					break;
				case ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES:
					setStoreData([...result_all_not_approved_stores]);

					setTableTitle("All Not Approved Stores");
					break;
				case ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES:
					setStoreData([...result_all_blocked_stores]);

					setTableTitle("All Blocked Stores");
					break;
				case ROUTES.VIEWALLSTORESQUERIES.ALL_PAID_PENDING_STORES:
					setStoreData([...result_all_paid_for_review_stores]);

					setTableTitle("All Paid Pending Stores");
					break;
				case ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES:
					setStoreData([...result_all__not_paid_for_review_stores]);

					setTableTitle("All Not Paid Pending Stores");
					break;
				case ROUTES.VIEWALLSTORESQUERIES.ALL_UNDER_VETTING_STORES:
					setStoreData([...result_all_under_vetting]);

					setTableTitle("All Under vetting Stores");
					break;
				case ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE:
					setStoreData([...result_all_vetted]);

					setTableTitle("All Vetted Stores");
					break;
				case ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE:
					setStoreData([...result_all_failed_vet]);

					setTableTitle("All Failed Vet Stores");
					break;

				default:
					break;
			}
		}
	}, [Stores, query, allStoreQuery.isSuccess]);

	return (
		<Fragment>
			<DashBoardLayout menuIndex={1}>
				<Dialog
					onClose={toggleAddNewUserModal}
					aria-labelledby="add-new-user-dialog"
					open={addNewUserModalState}
					maxWidth="sm"
					fullWidth={true}
				>
					<DialogTitle>Invite someone</DialogTitle>
					<DialogContent>
						<Formik
							initialValues={{
								full_name: "",
								email: "",
								phone_number: "",
								business_name: "",
								verdict: true,
								unique_id: shortid.generate(),
								ekioja_bypass: true,
							}}
							// validationSchema={AdminValidationSchema}
							onSubmit={(values, { setSubmitting, resetForm }) => {
								setTimeout(() => {
									setSubmitting(false);

									AddKYCReportEntryMutate(values, {
										onSuccess: () => {
											resetForm();
											AddKYCReportEntryInfo.reset();
											toggleAddNewUserModal();
										},
									});
								}, 1000);
							}}
						>
							{({
								submitForm,
								isSubmitting,
								isValid,
								values,
								setFieldValue,
							}) => (
								<Form>
									<Grid container>
										<Grid item xs={12} lg={2}></Grid>
										<Grid item xs={12} lg={8}>
											<Field
												component={TextField}
												name="full_name"
												fullWidth
												placeholder="Fullname of brand admin"
												type="text"
												required
											/>
											<br />
											<br />
											<Field
												component={PhoneInput}
												inputProps={{
													name: "phone_number",
												}}
												country={"ng"}
												enableSearch={true}
												value={values.phone_number}
												onChange={(phonenumber: string) =>
													setFieldValue("phone_number", phonenumber, true)
												}
												inputStyle={{
													width: "100%",
													border: 0,
													borderBottom: "1px gray solid",
													backgroundColor: "transparent",
													borderRadius: 0,
												}}
											/>

											<br />
											<Field
												component={TextField}
												name="business_name"
												fullWidth
												placeholder="Brand name"
												type="text"
												required
											/>
											<br />
											<br />
											<Field
												component={TextField}
												name="email"
												fullWidth
												placeholder="Email of brand admin"
												type="email"
												required
											/>
											<br />
											<br />
											<Button
												variant="contained"
												color="primary"
												disabled={
													isSubmitting ||
													!isValid ||
													AddKYCReportEntryInfo.isLoading
												}
												onClick={submitForm}
											>
												{AddKYCReportEntryInfo.isLoading
													? "Processing..."
													: AddKYCReportEntryInfo.isError
													? "Error!"
													: AddKYCReportEntryInfo.isSuccess
													? "Done!"
													: "	Send Invite"}
											</Button>
										</Grid>
										<Grid item xs={12} lg={2}></Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</DialogContent>
					<DialogActions>
						<Button
							variant="text"
							color="secondary"
							onClick={() => setAddNewUserModalState(false)}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					fullScreen
					open={openModal}
					onClose={handleCloseModal}
					TransitionComponent={Transition}
				>
					<AppBar className={styles.appBar} elevation={0}>
						<Toolbar>
							<IconButton
								edge="start"
								color="primary"
								onClick={handleCloseModal}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography
								variant="h6"
								className={styles.title}
								color="textPrimary"
							>
								{storeModalTitle === "view-store" && "View Store"}
								{storeModalTitle === "edit-store" && "Edit Store"}
							</Typography>
						</Toolbar>
					</AppBar>
					{storeModalTitle === "view-store" && (
						<ViewStoreDetailModal
							data={storeModalData}
							handleCloseModal={handleCloseModal}
						/>
					)}
					{storeModalTitle === "edit-store" && (
						<EditStoreDetailsModal
							storeData={storeModalData}
							handleCloseModal={handleCloseModal}
						/>
					)}
				</Dialog>

				<Dialog
					open={openStoreDeleteConfirmation}
					onClose={() => setOpenStoreDeleteConfirmation(false)}
					aria-labelledby="delete-store-dialog-title"
					aria-describedby="delete-store-dialog-description"
				>
					<DialogTitle id="delete-store-dialog-title">
						{"Are you sure about this?"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="delete-store-dialog-description">
							By clicking on the confirm button you are deleting all store data
							which includes(Store data, Administrators data, Administrators
							authentication, Store products, Brand stories, Face behind the
							brand data, wallet,)
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setOpenStoreDeleteConfirmation(false)}
							color="primary"
						>
							Close
						</Button>
						<Button
							color="primary"
							autoFocus
							onClick={() => {
								DeleteStoreMutate(storeToDelete, {
									onSuccess: () => {
										setTimeout(() => {
											setOpenStoreDeleteConfirmation(false);
											DeleteStoreInfo.reset();
										}, 1000);
									},
									onError: () => {
										window.alert(
											"An error occured! Trym again or contact the developer"
										);
									},
								});
							}}
						>
							{DeleteStoreInfo.isLoading
								? "Deleting..."
								: DeleteStoreInfo.isError
								? "Error!"
								: DeleteStoreInfo.isSuccess && !DeleteStoreInfo.isIdle
								? "Deleted!"
								: "Confirm"}
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={togglePickPackageMultiple}
					onClose={() => setTogglePickPackageMultiple(false)}
					aria-labelledby="pickpackage-multiple-dialog-title"
					aria-describedby="pickpackage-multiple-dialog-description"
				>
					<DialogTitle id="pickpackage-multiple-dialog-title">
						{PickPackageMultipleInfo.isLoading
							? "Processing..."
							: PickPackageMultipleInfo.isError
							? "Error!"
							: PickPackageMultipleInfo.isSuccess &&
							  !PickPackageMultipleInfo.isIdle
							? "Done!"
							: "Pick a package"}
					</DialogTitle>
					<DialogContent>
						<div className={styles.pick_package_item_wrapper}>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={6}>
									<Box
										boxShadow={5}
										className={styles.pick_package_item}
										onClick={() => {
											if (
												window.confirm(
													"Are you sure you want to pass select the Starter package?"
												)
											) {
												PickPackageMultipleMutate(
													{
														store_id_arr: pickPackageStoreIdArr,
														package: "starter",
														ekioja_admin_picked: true,
														ekioja_admin_id: authuid,
														is_starter_package: true,
														picked_package: true,
													},
													{
														onSuccess: () => {
															setTimeout(() => {
																PickPackageMultipleInfo.reset();
																setTogglePickPackageMultiple(false);
															}, 1000);
														},
														onError: (err) => {
															console.log(err);

															window.alert(
																"An error occured! Trym again or contact the developer"
															);
														},
													}
												);
											}
										}}
									>
										<h4>STARTER</h4>
									</Box>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Box
										boxShadow={5}
										className={styles.pick_package_item}
										onClick={() => {
											if (
												window.confirm(
													"Are you sure you want to pass select the Professsional package?"
												)
											) {
												PickPackageMultipleMutate(
													{
														store_id_arr: pickPackageStoreIdArr,
														package: "professional",
														ekioja_admin_picked: true,
														ekioja_admin_id: authuid,
														is_professional_package: true,
														picked_package: true,
													},
													{
														onSuccess: () => {
															setTimeout(() => {
																PickPackageMultipleInfo.reset();
																setTogglePickPackageMultiple(false);
															}, 1000);
														},
														onError: () => {
															window.alert(
																"An error occured! Trym again or contact the developer"
															);
														},
													}
												);
											}
										}}
									>
										<h4>PROFESSIONAL</h4>
									</Box>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Box
										boxShadow={5}
										className={styles.pick_package_item}
										onClick={() => {
											if (
												window.confirm(
													"Are you sure you want to pass select the Advanced package?"
												)
											) {
												PickPackageMultipleMutate(
													{
														store_id_arr: pickPackageStoreIdArr,
														package: "advanced",
														ekioja_admin_picked: true,
														ekioja_admin_id: authuid,
														is_advanced_package: true,
														picked_package: true,
													},
													{
														onSuccess: () => {
															setTimeout(() => {
																PickPackageMultipleInfo.reset();
																setTogglePickPackageMultiple(false);
															}, 1000);
														},
														onError: () => {
															window.alert(
																"An error occured! Trym again or contact the developer"
															);
														},
													}
												);
											}
										}}
									>
										<h4>ADVANCED</h4>
									</Box>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Box
										boxShadow={5}
										className={styles.pick_package_item}
										onClick={() => {
											if (
												window.confirm(
													"Are you sure you want to pass select the Premium package?"
												)
											) {
												PickPackageMultipleMutate(
													{
														store_id_arr: pickPackageStoreIdArr,
														package: "premium",
														ekioja_admin_picked: true,
														ekioja_admin_id: authuid,
														is_premium_package: true,
														picked_package: true,
													},
													{
														onSuccess: () => {
															setTimeout(() => {
																PickPackageMultipleInfo.reset();
																setTogglePickPackageMultiple(false);
															}, 1000);
														},
														onError: () => {
															window.alert(
																"An error occured! Trym again or contact the developer"
															);
														},
													}
												);
											}
										}}
									>
										<h4>PREMIUM</h4>
									</Box>
								</Grid>
							</Grid>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setTogglePickPackageMultiple(false)}
							color="primary"
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={togglePickPackagePage}
					onClose={() => setTogglePickPackagePage(false)}
					aria-labelledby="pickpackage-dialog-title"
					aria-describedby="pickpackage-dialog-description"
				>
					<DialogTitle id="pickpackage-dialog-title">
						{PickPackageInfo.isLoading
							? "Processing..."
							: PickPackageInfo.isError
							? "Error!"
							: PickPackageInfo.isSuccess && !PickPackageInfo.isIdle
							? "Done!"
							: "Pick a package"}
					</DialogTitle>
					<DialogContent>
						<div className={styles.pick_package_item_wrapper}>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={6}>
									<Box
										boxShadow={5}
										className={styles.pick_package_item}
										onClick={() => {
											if (
												window.confirm(
													"Are you sure you want to pass select the Starter package?"
												)
											) {
												PickPackageMutate(
													{
														store_id: pickPackageStoreId,
														package: "starter",
														ekioja_admin_picked: true,
														ekioja_admin_id: authuid,
														is_starter_package: true,
														picked_package: true,
													},
													{
														onSuccess: () => {
															setTimeout(() => {
																PickPackageInfo.reset();
																setTogglePickPackagePage(false);
															}, 1000);
														},
														onError: (err) => {
															console.log(err);

															window.alert(
																"An error occured! Trym again or contact the developer"
															);
														},
													}
												);
											}
										}}
									>
										<h4>STARTER</h4>
									</Box>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Box
										boxShadow={5}
										className={styles.pick_package_item}
										onClick={() => {
											if (
												window.confirm(
													"Are you sure you want to pass select the Professsional package?"
												)
											) {
												PickPackageMutate(
													{
														store_id: pickPackageStoreId,
														package: "professional",
														ekioja_admin_picked: true,
														ekioja_admin_id: authuid,
														is_professional_package: true,
														picked_package: true,
													},
													{
														onSuccess: () => {
															setTimeout(() => {
																PickPackageInfo.reset();
																setTogglePickPackagePage(false);
															}, 1000);
														},
														onError: () => {
															window.alert(
																"An error occured! Trym again or contact the developer"
															);
														},
													}
												);
											}
										}}
									>
										<h4>PROFESSIONAL</h4>
									</Box>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Box
										boxShadow={5}
										className={styles.pick_package_item}
										onClick={() => {
											if (
												window.confirm(
													"Are you sure you want to pass select the Advanced package?"
												)
											) {
												PickPackageMutate(
													{
														store_id: pickPackageStoreId,
														package: "advanced",
														ekioja_admin_picked: true,
														ekioja_admin_id: authuid,
														is_advanced_package: true,
														picked_package: true,
													},
													{
														onSuccess: () => {
															setTimeout(() => {
																PickPackageInfo.reset();
																setTogglePickPackagePage(false);
															}, 1000);
														},
														onError: () => {
															window.alert(
																"An error occured! Trym again or contact the developer"
															);
														},
													}
												);
											}
										}}
									>
										<h4>ADVANCED</h4>
									</Box>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Box
										boxShadow={5}
										className={styles.pick_package_item}
										onClick={() => {
											if (
												window.confirm(
													"Are you sure you want to pass select the Premium package?"
												)
											) {
												PickPackageMutate(
													{
														store_id: pickPackageStoreId,
														package: "premium",
														ekioja_admin_picked: true,
														ekioja_admin_id: authuid,
														is_premium_package: true,
														picked_package: true,
													},
													{
														onSuccess: () => {
															setTimeout(() => {
																PickPackageInfo.reset();
																setTogglePickPackagePage(false);
															}, 1000);
														},
														onError: () => {
															window.alert(
																"An error occured! Trym again or contact the developer"
															);
														},
													}
												);
											}
										}}
									>
										<h4>PREMIUM</h4>
									</Box>
								</Grid>
							</Grid>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setTogglePickPackagePage(false)}
							color="primary"
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={openDecisionModal}
					onClose={() => setOpenDecisionModal(false)}
					aria-labelledby="decision-store-dialog-title"
					aria-describedby="decision-store-dialog-description"
				>
					<DialogTitle id="decision-store-dialog-title">
						{"What's next for this store?"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="decision-store-dialog-description">
							After the KYC process you need to take a decision on this store.{" "}
							<br />
							If you are satisfied with the KYC process and want to confirm the
							store as vetted, then click on "Confirm as Vetted" button.
							<br />
							If you are not satisfied with the KYC process and want the store
							to be reviewed by the legal, then click on "Flag" button.
							<br />
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setOpenDecisionModal(false)} color="primary">
							Close
						</Button>
						<Button
							color="primary"
							onClick={() => {
								if (
									window.confirm("Are you sure you want to pass this store?")
								) {
									KYCPassVettingMutate(
										{ id: kycDecideStoreId },
										{
											onSuccess: () => {
												setTimeout(() => {
													setOpenDecisionModal(false);
													KYCPassVettingInfo.reset();
												}, 1000);
											},
											onError: () => {
												window.alert(
													"An error occured! Trym again or contact the developer"
												);
											},
										}
									);
								} else {
									setOpenDecisionModal(false);
								}
							}}
						>
							{KYCPassVettingInfo.isLoading
								? "Processing..."
								: KYCPassVettingInfo.isError
								? "Error!"
								: KYCPassVettingInfo.isSuccess && !KYCPassVettingInfo.isIdle
								? "Confirmed!"
								: "Confirm as Vetted"}
						</Button>
						<Button
							color="primary"
							autoFocus
							onClick={() => {
								if (
									window.confirm(
										"Are you sure you want to flag this store for review?"
									)
								) {
									KYCFlagMutate(
										{ id: kycDecideStoreId },
										{
											onSuccess: () => {
												setTimeout(() => {
													setOpenDecisionModal(false);
													KYCFlagInfo.reset();
												}, 1000);
											},
											onError: () => {
												window.alert(
													"An error occured! Trym again or contact the developer"
												);
											},
										}
									);
								} else {
									setOpenDecisionModal(false);
								}
							}}
						>
							{KYCFlagInfo.isLoading
								? "Processing..."
								: KYCFlagInfo.isError
								? "Error!"
								: KYCFlagInfo.isSuccess && !KYCFlagInfo.isIdle
								? "Flagged!"
								: "Flag"}
						</Button>
					</DialogActions>
				</Dialog>
				<div className={styles.page_header}>
					<Typography variant="h4" color="primary">
						{" "}
						Shops Management
					</Typography>
				</div>
				<br />
				<div>
					<Scrollbars
						style={{ width: "100%", height: 50 }}
						autoHide
						autoHideTimeout={1000}
					>
						<div className={styles.queries_wrapper}>
							<Chip
								label={`All Stores ${getStoresCounts(
									Stores,
									ROUTES.VIEWALLSTORESQUERIES.ALLSTORES
								)}`}
								variant="default"
								onClick={() => {
									setStoreData([
										...getStores(Stores, ROUTES.VIEWALLSTORESQUERIES.ALLSTORES),
									]);

									setTableTitle("All Stores");
								}}
							/>
							<Chip
								label={`Active Stores ${getStoresCounts(
									Stores,
									ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES
								)}`}
								variant="default"
								onClick={() => {
									setStoreData([
										...getStores(
											Stores,
											ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES
										),
									]);

									setTableTitle("All Active Stores");
								}}
							/>
							<Chip
								label={`In-Active Stores ${getStoresCounts(
									Stores,
									ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES
								)}`}
								variant="default"
								onClick={() => {
									setStoreData([
										...getStores(
											Stores,
											ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES
										),
									]);

									setTableTitle("All In-active Stores");
								}}
							/>
							<Chip
								label={`Blocked Stores ${getStoresCounts(
									Stores,
									ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES
								)}`}
								variant="default"
								onClick={() => {
									setStoreData([
										...getStores(
											Stores,
											ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES
										),
									]);

									setTableTitle("All Blocked Stores");
								}}
							/>
							<Chip
								label={`Approved Stores ${getStoresCounts(
									Stores,
									ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES
								)}`}
								variant="default"
								onClick={() => {
									setStoreData([
										...getStores(
											Stores,
											ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES
										),
									]);

									setTableTitle("All Approved Stores");
								}}
							/>
							<Chip
								label={`Not Approved Stores ${getStoresCounts(
									Stores,
									ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES
								)}`}
								variant="default"
								onClick={() => {
									setStoreData([
										...getStores(
											Stores,
											ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES
										),
									]);

									setTableTitle("All Not Approved Stores");
								}}
							/>

							<Chip
								label={`UnPaid Pending Stores ${getStoresCounts(
									Stores,
									ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES
								)}`}
								variant="default"
								onClick={() => {
									setStoreData([
										...getStores(
											Stores,
											ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES
										),
									]);

									setTableTitle("All Not Paid Pending Stores");
								}}
							/>
							<Chip
								label={`Under-Vetting Stores ${getStoresCounts(
									Stores,
									ROUTES.VIEWALLSTORESQUERIES.ALL_PAID_PENDING_STORES
								)}`}
								variant="default"
								onClick={() => {
									setStoreData([
										...getStores(
											Stores,
											ROUTES.VIEWALLSTORESQUERIES.ALL_PAID_PENDING_STORES
										),
									]);

									setTableTitle("All Under vetting Stores");
								}}
							/>
							<Chip
								label={`Vetted Stores ${getStoresCounts(
									Stores,
									ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE
								)}`}
								variant="default"
								onClick={() => {
									setStoreData([
										...getStores(
											Stores,
											ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE
										),
									]);

									setTableTitle("All Vetted Stores");
								}}
							/>
							<Chip
								label={`Failed Vet Stores ${getStoresCounts(
									Stores,
									ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE
								)}`}
								variant="default"
								onClick={() => {
									setStoreData([
										...getStores(
											Stores,
											ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE
										),
									]);

									setTableTitle("All Failed Vet Stores");
								}}
							/>
						</div>
					</Scrollbars>
				</div>

				<div className={styles.root} ref={refTableContainer}>
					<MaterialTable
						style={{
							width: refTableContainer.current
								? refTableContainer.current.offsetWidth - 35
								: "100%",
						}}
						isLoading={allStoreQuery.isLoading || allStoreQuery.isFetching}
						title={tableTitle}
						columns={[
							{
								title: "Logo",
								field: "business_logo_id",
								render: (rowData) => (
									<>
										{rowData.business_logo_id === "" && (
											<div className={styles.business_logo_wrapper}>
												<img src={EkiojaBag} alt="ekioja logo" />
											</div>
										)}
										{rowData.business_logo_id !== "" &&
											(rowData.data.business_logo_url === undefined ||
												rowData.data.business_logo_url === "") && (
												<div className={styles.business_logo_wrapper}>
													<GetImageFromFirebase
														source={rowData.business_logo_id}
													/>
												</div>
											)}
										{rowData.business_logo_id !== "" &&
											rowData.data.business_logo_url !== undefined &&
											rowData.data.business_logo_url !== "" && (
												<div className={styles.business_logo_wrapper}>
													<img
														src={rowData.data.business_logo_url}
														alt={rowData.data.business_name}
													/>
												</div>
											)}
									</>
								),
							},
							{
								title: "Store name",
								field: "business_name",
								cellStyle: {
									backgroundColor: "rgba(34, 179, 88, 1)",
									color: "#FFF",
									padding: 10,
								},
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 1)",
									color: "#FFF",
								},
							},
							{
								title: "Address",
								field: "business_location",
								cellStyle: {
									width: 200,
								},

								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
							},
							{
								title: "Niche",
								field: "business_niche",
								cellStyle: {
									padding: 10,
								},
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
							},
							{
								title: "Package",
								field: "plan",
								cellStyle: {
									padding: 10,
								},
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
								render: (rowData) => (
									<Chip
										icon={<AssistantIcon />}
										label={rowData.plan}
										variant="outlined"
										color="secondary"
										style={{
											color: "rgba(34, 179, 88, 1)",
											border: "1px solid rgba(34, 179, 88, 1)",
										}}
									/>
								),
							},
							{
								title: "Paid",
								field: "paid_for_review",
								type: "boolean",
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
								render: (rowData) =>
									rowData.paid_for_review ? (
										<Chip
											icon={<PaymentIcon />}
											label="Yes"
											variant="outlined"
											color="secondary"
										/>
									) : (
										<Chip
											icon={<PaymentIcon />}
											label="No"
											variant="outlined"
											color="secondary"
											style={{ color: "red", border: "1px red solid" }}
										/>
									),
							},
							{
								title: "Approved",
								field: "approved",
								type: "boolean",
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
								render: (rowData) => (
									<>
										{rowData.data?.kyc_vetted &&
										rowData.data?.vetted &&
										rowData.approved ? (
											<Chip
												icon={<BeenhereTwoToneIcon />}
												label="Approved"
												variant="outlined"
												color="secondary"
											/>
										) : rowData.data.hasOwnProperty("kyc_vetted") &&
										  rowData.data?.kyc_vetted ? (
											<Chip
												icon={<BeenhereTwoToneIcon />}
												label="Pre-Approved"
												variant="outlined"
												color="secondary"
											/>
										) : rowData.approved ? (
											<Chip
												icon={<BeenhereTwoToneIcon />}
												label="Approved"
												variant="outlined"
												color="secondary"
											/>
										) : (
											<Chip
												icon={<BlockTwoToneIcon />}
												label="No"
												variant="outlined"
												color="secondary"
												style={{ color: "red", border: "1px red solid" }}
											/>
										)}
									</>
								),
							},
							{
								title: "Blocked",
								field: "blocked",
								type: "boolean",
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
								render: (rowData) =>
									rowData.blocked ? (
										<Chip
											icon={<BeenhereTwoToneIcon />}
											label="Blocked"
											variant="outlined"
											color="secondary"
										/>
									) : (
										<Chip
											icon={<BlockTwoToneIcon />}
											label="No"
											variant="outlined"
											color="secondary"
											style={{ color: "red", border: "1px red solid" }}
										/>
									),
							},
							{
								title: "active",
								field: "active",
								type: "boolean",
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
								render: (rowData) =>
									rowData.active ? (
										<Chip
											icon={<BeenhereTwoToneIcon />}
											label="Active"
											variant="outlined"
											color="secondary"
										/>
									) : (
										<Chip
											icon={<BlockTwoToneIcon />}
											label="No"
											variant="outlined"
											color="secondary"
											style={{ color: "red", border: "1px red solid" }}
										/>
									),
							},
							{
								title: "Joined",
								field: "date_added",
								headerStyle: {
									backgroundColor: "rgba(34, 179, 88, 0.5)",
									color: "#FFF",
								},
							},
							{ title: "id", field: "id", hidden: true },
							{ title: "data", field: "data", hidden: true },
						]}
						data={storeData}
						options={{
							search: true,
							actionsColumnIndex: -1,
							exportAllData: true,
							exportButton: true,
							exportFileName: "vendors_data",
							rowStyle: {
								borderLeft: "6px solid green",
							},
							selection: true,
							selectionProps: (rowData: any) => ({
								disabled: rowData.data?.picked_package === true,
								color: "primary",
							}),
						}}
						actions={[
							{
								tooltip: "Pick a package for selected stores",
								icon: "edit",
								onClick: (evt, data) => {
									setPickPackageStoreIdArr([
										// @ts-ignore
										...data.map((item: any) => item.data.id),
									]);
									setTogglePickPackageMultiple(true);
								},
							},
						]}
						detailPanel={[
							{
								icon: "edit",
								tooltip: "Actions on this product",
								render: (rowData: any) => {
									return (
										<div className={styles.detail_panel_wrapper}>
											<Grid container spacing={2}>
												<Grid item xs={12} lg={1}>
													<Button
														variant="outlined"
														aria-label="edit-vendor-details"
														style={{
															color: "#fff",
															backgroundColor: "#030201",
														}}
														onClick={() => {
															setStoreModalData({ ...rowData.data });
															setStoreModalTitle("edit-store");
															handleClickOpenModal();
														}}
													>
														Edit
													</Button>
												</Grid>

												<Grid item xs={12} lg={1}>
													<Button
														variant="outlined"
														aria-label="view-vendor-details"
														onClick={() => {
															setStoreModalData({ ...rowData.data });
															setStoreModalTitle("view-store");
															handleClickOpenModal();
														}}
														style={{
															color: "#fff",
															backgroundColor: "#030201",
														}}
													>
														View
													</Button>
												</Grid>
												<Grid item xs={12} lg={1}>
													<Button
														variant="outlined"
														aria-label="view-vendor-details"
														onClick={() => {
															setStoreToDelete({ ...rowData.data });

															setOpenStoreDeleteConfirmation(true);
														}}
														style={{
															color: "#fff",
															backgroundColor: "#030201",
														}}
													>
														Delete
													</Button>
												</Grid>
												{rowData.data?.vetted ? null : (
													<Grid item xs={12} lg={1}>
														<Button
															variant="outlined"
															aria-label="view-vendor-details"
															onClick={() => {
																setKycDecideStoreId(rowData.data.unique_id);
																setOpenDecisionModal(true);
															}}
															style={{
																color: "#fff",
																backgroundColor: "#030201",
															}}
														>
															Decide
														</Button>
													</Grid>
												)}
												{rowData.data?.picked_package === true ? null : (
													<Grid item xs={12} lg={2}>
														<Button
															variant="outlined"
															aria-label="view-vendor-details"
															onClick={() => {
																setPickPackageStoreId(rowData.data.unique_id);
																setTogglePickPackagePage(true);
															}}
															style={{
																color: "#fff",
																backgroundColor: "#030201",
															}}
														>
															Pick Package
														</Button>
													</Grid>
												)}
											</Grid>
										</div>
									);
								},
							},
						]}
						components={{
							Action: (props) => (
								<Button
									aria-label="view-vendor-details"
									size="medium"
									onClick={(event) => props.action.onClick(event, props.data)}
								>
									View
								</Button>
							),
							Toolbar: (props) => (
								<div>
									<Grid container>
										<Grid item lg={3}>
											<Tooltip title="Add a new store" aria-label="add-admin">
												<Button
													variant="outlined"
													color="primary"
													className={styles.add_new_user_button}
													startIcon={<Icon>add</Icon>}
													onClick={toggleAddNewUserModal}
												>
													Invite New Store
												</Button>
											</Tooltip>
										</Grid>
										<Grid item lg={9}>
											<MTableToolbar {...props} />
										</Grid>
									</Grid>
								</div>
							),
						}}
					/>
				</div>
				<br />
				<VettedStores />
			</DashBoardLayout>
		</Fragment>
	);
};

export default Stores;
