import React, { lazy, Suspense, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import ChatListQuery from "./components/ChatListQuery";
import Channel from "./components/Channel";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import { ChatContext } from "./ChatController";

const ConservationList = lazy(() => import("./components/ConservationList"));

const ChatView = () => {
	const { setConversationListQuery } = useContext(ChatContext);

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "chat-query-popover" : undefined;

	return (
		<div>
			<Button
				aria-describedby={id}
				variant="contained"
				color="primary"
				onClick={handleClick}
				style={{ marginRight: 20 }}
			>
				Select Brand
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "center",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				style={{ width: 400, padding: 10 }}
			>
				<ChatListQuery />
			</Popover>
			<Button
				variant="contained"
				color="primary"
				onClick={() =>
					setConversationListQuery({ conversation_list_query: "all" })
				}
			>
				All Conversations
			</Button>
			<Grid container spacing={1}>
				<Grid item xs={12} lg={3}>
					<Suspense fallback={<div>Loading...</div>}>
						<ConservationList />
					</Suspense>
				</Grid>
				<Grid item xs={12} lg={9}>
					<Channel />
				</Grid>
			</Grid>
		</div>
	);
};

export default ChatView;
