import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
import { queryCache } from "react-query";

const changeRole = ({ id, role }: { id: string; role: string }) => {
	return firebase.firestore().collection("Admins").doc(id).set(
		{
			role,
		},
		{ merge: true }
	);
};

const useChangeRole = () => {
	return useMutation(changeRole, {
		// // Always refetch after error or success:
		onSettled: () => {
			queryCache.invalidateQueries("allEkiojaAdmins");
		},
	});
};

export default useChangeRole;
