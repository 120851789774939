import { useQuery } from "react-query";
import axios from "axios";

const getAccountDetails = async (
	key: any,
	account_number: string,
	bank_code: string
) => {
	// let account_numberValue = key.queryKey[1];
	// let bank_codeValue = key.queryKey[2];

	if (account_number !== "") {
		const response = await axios.get("https://api.paystack.co/bank/resolve", {
			params: {
				account_number,
				bank_code,
			},
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
			},
		});

		return response.data;
	}
};

const usePSResolveAccountNumber = (
	account_number: string,
	bank_code: string
) => {
	return useQuery(
		["resolveAccountData", account_number, bank_code],
		getAccountDetails,
		{
			refetchOnWindowFocus: false,
		}
	);
};

export default usePSResolveAccountNumber;
