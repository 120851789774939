import React, { Fragment, useEffect, useState } from "react";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MoneyIcon from "@material-ui/icons/Money";
import Divider from "@material-ui/core/Divider";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PaymentIcon from "@material-ui/icons/Payment";
//number format
import NumberFormat from "react-number-format";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";
//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";

import LoadingScreen from "../components/LoadingScreen";

//utils
import {
  getUpcomingRevenueTimeFrame,
  getPendingClearanceRevenueTimeFrame,
  getEarnedRevenuePassedTimeFrame,
  getEarnedRevenueFailedTimeFrame,
} from "../utils/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: 200,
      width: "100%",
      borderRadius: 20,
    },
    money_title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
    },
    available_wrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    page_header: {
      width: "100%",
      height: 100,
      backgroundColor: "#99ff99",
      paddingTop: 20,
      paddingLeft: 20,
    },
  })
);

const VetWallet = () => {
  const styles = useStyles();
  const [balance, setBalance] = useState(0);
  const [toBeVetted, setToBeVetted] = useState<any>([]);
  const [underVetting, setUnderVetting] = useState<any>([]);
  const [passedVetted, setpassedVetted] = useState<any>([]);
  const [failedVetted, setfailedVetted] = useState<any>([]);

  const [upComingRevenue, setUpComingRevenue] = useState(0);
  const [pendingClearanceRevenue, setPendingClearanceRevenue] = useState(0);
  const [passedEarnedRevenue, setPassedEarnedRevenue] = useState(0);
  const [failedEarnedRevenue, setFailedEarnedRevenue] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [timeframeValue, setTimeframeValue] = useState("today");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const authuid = useSelector((state: any) => state.firebase.auth.uid);

  useFirestoreConnect([
    {
      collection: "Wallets",
      where: [["owner_id", "==", authuid]],
      storeAs: "Wallets",
    },
    {
      collection: "VetDocuments",
      where: [["vetted_by", "==", authuid]],
      storeAs: "VetDocuments",
    },
  ]);

  const wallet = useSelector((state: any) => state.firestore.data["Wallets"]);
  const VetDocuments = useSelector(
    (state: any) => state.firestore.data["VetDocuments"]
  );

  useEffect(() => {
    if (isLoaded(wallet)) {
      let wallet_data: any[] = [];
      if (wallet !== null) {
        for (var walletkey in wallet) {
          wallet_data.push({
            amount: wallet[walletkey].income,
          });
        }
      }

      if (_.has(wallet_data[0], "amount")) {
        setBalance(wallet_data[0].amount);
      }
    }
  }, [wallet]);

  useEffect(() => {
    if (isLoaded(VetDocuments)) {
      let vet_doc_data: any[] = [];
      if (VetDocuments !== null) {
        for (var vetdockey in VetDocuments) {
          vet_doc_data.push({
            ...VetDocuments[vetdockey],
            vetdockey_uuid: vetdockey,
          });
        }
      }

      const sorted = _.orderBy(vet_doc_data, ["added_on"], ["desc"]);

      //get to be vetted
      const to_be_vetted = _.filter(
        sorted,
        (item) =>
          _.has(item, "to_be_vetted") &&
          typeof item.to_be_vetted.status !== "undefined" &&
          item.to_be_vetted.status === true
      );
      setToBeVetted([...to_be_vetted]);

      //get under vettting
      const under_vetting = _.filter(
        sorted,
        (item) =>
          _.has(item, "under_vetting") &&
          typeof item.under_vetting.status !== "undefined" &&
          item.under_vetting.status === true
      );
      setUnderVetting([...under_vetting]);

      //get vetted
      const passed_vetted_data = _.filter(
        sorted,
        (item) =>
          _.has(item, "passed") &&
          typeof item.passed.status !== "undefined" &&
          item.passed.status === true
      );

      setpassedVetted([...passed_vetted_data]);
      const failed_vetted_data = _.filter(
        sorted,
        (item) =>
          _.has(item, "failed") &&
          typeof item.failed.status !== "undefined" &&
          item.passed.failed === true
      );
      setfailedVetted([...failed_vetted_data]);
    }
  }, [VetDocuments]);

  if (!isLoaded(wallet)) return <LoadingScreen />;
  if ( !isLoaded(VetDocuments)) return <LoadingScreen />;
  return (
    <Fragment>
      <DashBoardLayout menuIndex={12}>
        <div className={styles.page_header}>
          <Typography variant="h4" color="primary">
            {" "}
            Legal Wallet
          </Typography>
        </div>
        <br />
        <Container>
          <div className={styles.available_wrapper}>
            <Typography variant="subtitle1" color="textSecondary">
              Available funds for withdrawal:{" "}
              <Typography variant="h5" color="primary" display="inline">
                <NumberFormat
                  value={balance}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                />
              </Typography>
            </Typography>
            <Button style={{ marginLeft: 40 }}>Withdraw</Button>
          </div>
          <Divider />
          <br />
          <br />
          <Grid container>
            <Grid item lg={2}>
              <FormControl fullWidth>
                <InputLabel id="open-select-label">Time Frame</InputLabel>
                <Select
                  labelId="open-select-label"
                  id="open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={timeframeValue}
                  onChange={(event: React.ChangeEvent<{ value: any }>) => {
                    setTimeframeValue(event.target.value);
                    setUpComingRevenue(
                      getUpcomingRevenueTimeFrame(
                        toBeVetted,
                        event.target.value
                      )
                    );
                    setPendingClearanceRevenue(
                      getPendingClearanceRevenueTimeFrame(
                        underVetting,
                        event.target.value
                      )
                    );
                    setPassedEarnedRevenue(
                      getEarnedRevenuePassedTimeFrame(
                        passedVetted,
                        event.target.value
                      )
                    );
                    setFailedEarnedRevenue(
                      getEarnedRevenueFailedTimeFrame(
                        failedVetted,
                        event.target.value
                      )
                    );
                  }}
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="within-a-week">Within a week</MenuItem>
                  <MenuItem value="within-a-month">Within a month</MenuItem>
                  <MenuItem value="over-two-weeks">Over two weeks ago</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Box className={styles.wrapper} boxShadow={5}>
                <div className={styles.money_title}>
                  <MoneyIcon style={{ fontSize: 40 }} />
                  <Typography variant="h6" color="textSecondary">
                    Upcoming earnings
                  </Typography>
                </div>
                <Typography variant="h6" color="textPrimary" display="block">
                  <NumberFormat
                    value={upComingRevenue}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </Typography>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  display="block"
                >
                  (To be vetted)
                </Typography>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  display="block"
                >
                  {timeframeValue === "today" && "Within today"}
                  {timeframeValue === "within-a-week" &&
                    "Within the past 7 days"}
                  {timeframeValue === "within-a-month" &&
                    "Within the past 30 days"}
                  {timeframeValue === "over-two-weeks" &&
                    "Over two weeks ago or more"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box className={styles.wrapper} boxShadow={5}>
                <div className={styles.money_title}>
                  <AccountBalanceIcon style={{ fontSize: 40 }} />
                  <Typography variant="h6" color="textSecondary">
                    Pending Clearance
                  </Typography>
                </div>
                <Typography variant="h6" color="textPrimary" display="block">
                  <NumberFormat
                    value={pendingClearanceRevenue}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </Typography>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  display="block"
                >
                  (Under vetting)
                </Typography>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  display="block"
                >
                  {timeframeValue === "today" && "Within today"}
                  {timeframeValue === "within-a-week" &&
                    "Within the past 7 days"}
                  {timeframeValue === "within-a-month" &&
                    "Within the past 30 days"}
                  {timeframeValue === "over-two-weeks" &&
                    "Over two weeks ago or more"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box className={styles.wrapper} boxShadow={5}>
                <div className={styles.money_title}>
                  <LocalAtmIcon style={{ fontSize: 40 }} />
                  <Typography variant="h6" color="textSecondary">
                    Earned
                  </Typography>
                </div>
                <Typography variant="h6" color="textPrimary" display="block">
                  <NumberFormat
                    value={passedEarnedRevenue}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </Typography>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  display="block"
                >
                  (Passed Vetting)
                </Typography>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  display="block"
                >
                  {timeframeValue === "today" && "Within today"}
                  {timeframeValue === "within-a-week" &&
                    "Within the past 7 days"}
                  {timeframeValue === "within-a-month" &&
                    "Within the past 30 days"}
                  {timeframeValue === "over-two-weeks" &&
                    "Over two weeks ago or more"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box className={styles.wrapper} boxShadow={5}>
                <div className={styles.money_title}>
                  <PaymentIcon style={{ fontSize: 40 }} />
                  <Typography variant="h6" color="textSecondary">
                    Earned
                  </Typography>
                </div>
                <Typography variant="h6" color="textPrimary" display="block">
                  <NumberFormat
                    value={failedEarnedRevenue}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                  />
                </Typography>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  display="block"
                >
                  (Failed vetting)
                </Typography>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  display="block"
                >
                  {timeframeValue === "today" && "Within today"}
                  {timeframeValue === "within-a-week" &&
                    "Within the past 7 days"}
                  {timeframeValue === "within-a-month" &&
                    "Within the past 30 days"}
                  {timeframeValue === "over-two-weeks" &&
                    "Over two weeks ago or more"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </DashBoardLayout>
    </Fragment>
  );
};

export default VetWallet;
