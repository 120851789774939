import React, { useState, useEffect, Fragment } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

import LocalMallTwoToneIcon from "@material-ui/icons/LocalMallTwoTone";
import ThumbUpTwoToneIcon from "@material-ui/icons/ThumbUpTwoTone";
import RedeemTwoToneIcon from "@material-ui/icons/RedeemTwoTone";
import LocalShippingTwoToneIcon from "@material-ui/icons/LocalShippingTwoTone";
import PanToolTwoToneIcon from "@material-ui/icons/PanToolTwoTone";
import SentimentVerySatisfiedTwoToneIcon from "@material-ui/icons/SentimentVerySatisfiedTwoTone";
import ReplayTwoToneIcon from "@material-ui/icons/ReplayTwoTone";

import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";

import MarketPlaceLayout from "./Layouts/MarketPlaceLayout";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";

import moment from "moment";

import { IOrder } from "../ts/interfaces/order";

import { truncate } from "../utils/index";

// //navigation hook
import { useNavigate } from "../hooks/navigate";

// //routes
import * as ROUTES from "../constants/routes";

// interface IOrderSumary extends IOrderItems {
//   order_id?: string;
// }
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card_wrapper: {
      marginBottom: 10,
      marginTop: 10,
    },
    product_image: {
      width: "100%",
      minHeight: 120,
      "& img": {
        width: "100%",
        height: 120,
      },
    },
    product_info_wrapper: {
      width: "100%",
      minHeight: 120,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    waiting_state: {
      backgroundColor: "#4287f5",
      padding: 5,
      textTransform: "uppercase",
      color: "white",
      borderRadius: 5,
      fontSize: 12,
    },
    done_state: {
      backgroundColor: "#8bf50a",
      padding: 5,
      textTransform: "uppercase",
      color: "white",
      borderRadius: 5,
      fontSize: 12,
    },
    wrapper: {
      width: "100%",
      height: 300,
    },
    order_list_wrapper: {
      maxWidth: "100%",
      height: 260,
      whiteSpace: "nowrap",
      WebkitOverflowScrolling: "touch",
      msOverflowStyle: "-ms-autohiding-scrollbar",
      overflowY: "hidden",
      overflowX: "auto",
      display: "inline-block",
      verticalAlign: "middle",
      padding: 10,
    },
    order_list_item_wrapper: {
      width: 250,
      height: 250,
      marginRight: 10,
      display: "inline-block",
    },

    order_list_item_info: {
      width: "100%",
      minHeight: 120,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "flex-end",
      flexDirection: "column",
    },
    media: {
      height: 140,
    },
    group_order_wrapper: {
      backgroundColor: "#cbffc2",
      marginTop: 10,
      marginBottom: 10,
    },
  })
);

interface Props {}

const MarketPlaceOrders = (props: Props) => {
  const styles = useStyles();

  const { navigateToLink } = useNavigate();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const [orderData, setOrderData] = useState<IOrder[]>([]);

  useFirestoreConnect([
    {
      collection: "Orders",
      where: [["customer_id", "==", "demo-customer"]],
      storeAs: "Orders",
    },
    {
      collection: "OrdersTimeline",
      where: [["order_customer_id", "==", "demo-customer"]],
      storeAs: "OrdersTimeline",
    },
  ]);

  const orders = useSelector((state: any) => state.firestore.data["Orders"]);

  const ordersTimeline = useSelector(
    (state: any) => state.firestore.data["OrdersTimeline"]
  );

  useEffect(() => {
    if (isLoaded(orders) && isLoaded(ordersTimeline)) {
      let order_data: any[] = [];
      if (orders !== null) {
        for (var orderkey in orders) {
          order_data.push({ ...orders[orderkey], order_uuid: orderkey });
        }
      }

      const mapOrderIdToitems = _.map(order_data, (item) => {
        return {
          ...item,
          items: _.map(item.items, (elems) => {
            return {
              ...elems,
              order_id: item.order_id,
            };
          }),
        };
      });

      let orderTimeline_data: any[] = [];
      if (ordersTimeline !== null) {
        for (var orderTimelinekey in ordersTimeline) {
          orderTimeline_data.push({
            ...ordersTimeline[orderTimelinekey],
            orderTimelinekey_uuid: orderTimelinekey,
          });
        }
      }

      const sorted = _.orderBy(mapOrderIdToitems, ["added_on"], ["desc"]);

      const all_items_in_orders_and_ordertimeline = _.map(sorted, (item) => {
        return {
          ...item,
          items: _.intersectionWith(
            _.cloneDeep(item.items),
            orderTimeline_data,
            function (x: any, y: any) {
              return (
                x.product_id === y.order_product_id &&
                x.order_id === y.the_order_id &&
                _.assign(x, y)
              );
            }
          ),
        };
      });

      setOrderData([...all_items_in_orders_and_ordertimeline]);
    }
  }, [orders, ordersTimeline]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  return (
    <MarketPlaceLayout>
      <div style={{ marginTop: 100 }} />
      <Container>
        <Typography variant="h5" color="textPrimary">
          Orders
        </Typography>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={2}></Grid>
          <Grid item xs={12} lg={8}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label={`Open Orders ${orderData.length}`}
                  {...a11yProps(0)}
                />
                <Tab label={`Closed Orders ${1}`} {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {_.map(orderData, (item, index) => (
                  <Card className={styles.group_order_wrapper} key={index}>
                    <CardContent>
                      <Typography variant="subtitle2" color="textPrimary">
                        Order {item.order_id} | ({item.items.length}) items
                      </Typography>
                      <Divider />
                      <div className={styles.wrapper}>
                        <div className={styles.order_list_wrapper}>
                          {_.map(item.items, (productItems, productindex) => (
                            <Card
                              className={styles.order_list_item_wrapper}
                              raised
                              key={productindex}
                            >
                              <CardMedia
                                className={styles.media}
                                title={productItems.product_name}
                                image={`https://firebasestorage.googleapis.com/v0/b/ekioja-7bb9f.appspot.com/o/images%2F${productItems.product_image}?alt=media`}
                              />
                              <CardContent>
                                <div className={styles.order_list_item_info}>
                                  <Typography
                                    variant="caption"
                                    color="textPrimary"
                                  >
                                    {truncate(productItems.product_name, 33)}
                                  </Typography>
                                  {productItems.order_placed?.current && (
                                    <Fragment>
                                      <Chip
                                        icon={<LocalMallTwoToneIcon />}
                                        label="ORDER PLACED"
                                        variant="outlined"
                                        color="secondary"
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                      >
                                        {moment(
                                          productItems.order_placed.date.toDate()
                                        ).format("YYYY-MM-DD")}
                                      </Typography>
                                    </Fragment>
                                  )}
                                  {productItems.pending_confirmation
                                    ?.current && (
                                    <Fragment>
                                      <Chip
                                        icon={<ThumbUpTwoToneIcon />}
                                        label="PENDING CONFIRMATION"
                                        variant="outlined"
                                        color="secondary"
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                      >
                                        {moment(
                                          productItems.pending_confirmation.date.toDate()
                                        ).format("YYYY-MM-DD")}
                                      </Typography>
                                    </Fragment>
                                  )}
                                  {productItems.waiting_to_be_shipped
                                    ?.current && (
                                    <Fragment>
                                      <Chip
                                        icon={<RedeemTwoToneIcon />}
                                        label="WAITING TO BE SHIPPED"
                                        variant="outlined"
                                        color="secondary"
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                      >
                                        {moment(
                                          productItems.waiting_to_be_shipped.date.toDate()
                                        ).format("YYYY-MM-DD")}
                                      </Typography>
                                    </Fragment>
                                  )}
                                  {productItems.shipped?.current && (
                                    <Fragment>
                                      <Chip
                                        icon={<LocalShippingTwoToneIcon />}
                                        label="SHIPPED"
                                        variant="outlined"
                                        color="secondary"
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                      >
                                        {moment(
                                          productItems.shipped.date.toDate()
                                        ).format("YYYY-MM-DD")}
                                      </Typography>
                                    </Fragment>
                                  )}
                                  {productItems.out_for_delivery?.current && (
                                    <Fragment>
                                      <Chip
                                        icon={<LocalShippingTwoToneIcon />}
                                        label="OUT FOR DELIVERY"
                                        variant="outlined"
                                        color="secondary"
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                      >
                                        {moment(
                                          productItems.out_for_delivery.date.toDate()
                                        ).format("YYYY-MM-DD")}
                                      </Typography>
                                    </Fragment>
                                  )}
                                  {productItems.delivered?.current && (
                                    <Fragment>
                                      <Chip
                                        icon={
                                          <SentimentVerySatisfiedTwoToneIcon />
                                        }
                                        label="DELIVERED"
                                        variant="outlined"
                                        color="secondary"
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                      >
                                        {moment(
                                          productItems.delivered.date.toDate()
                                        ).format("YYYY-MM-DD")}
                                      </Typography>
                                    </Fragment>
                                  )}
                                  {productItems.returned?.current && (
                                    <Fragment>
                                      <Chip
                                        icon={<ReplayTwoToneIcon />}
                                        label="RETURNED"
                                        variant="outlined"
                                        style={{
                                          color: "orange",
                                          border: "1px orange solid",
                                        }}
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                      >
                                        {moment(
                                          productItems.returned.date.toDate()
                                        ).format("YYYY-MM-DD")}
                                      </Typography>
                                    </Fragment>
                                  )}
                                  {productItems.canceled?.current && (
                                    <Fragment>
                                      <Chip
                                        icon={<PanToolTwoToneIcon />}
                                        label="CANCELLED"
                                        variant="outlined"
                                        style={{
                                          color: "red",
                                          border: "1px red solid",
                                        }}
                                      />
                                      <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                      >
                                        {moment(
                                          productItems.canceled.date.toDate()
                                        ).format("YYYY-MM-DD")}
                                      </Typography>
                                    </Fragment>
                                  )}
                                </div>
                              </CardContent>
                            </Card>
                          ))}
                        </div>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          navigateToLink.push(
                            ROUTES.VIEW_MARKETPLACE_ORDERS_DETAILS,
                            {
                              order_uuid: item.order_uuid,
                            }
                          )
                        }
                      >
                        View details
                      </Button>
                    </CardActions>
                  </Card>
                ))}
                {/* {_.map(orderData, (item, index) => (
                  <Card className={styles.card_wrapper} key={index}>
                    <CardActionArea>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={3}>
                            <div className={styles.product_image}>
                              <img
                                src={`https://firebasestorage.googleapis.com/v0/b/ekioja-7bb9f.appspot.com/o/images%2F${item.product_image}?alt=media`}
                                alt={`${item.product_name}`}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={9}>
                            <div className={styles.product_info_wrapper}>
                              <Typography
                                variant="subtitle1"
                                color="textPrimary"
                              >
                                {item.product_name}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                Order {item.order_id}
                              </Typography>
                              {item.order_placed?.status && (
                                <Fragment>
                                  <div className={styles.waiting_state}>
                                    ORDER PLACED
                                  </div>
                                  <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                  >
                                    {moment(
                                      item.order_placed.date.toDate()
                                    ).format("YYYY-MM-DD")}
                                  </Typography>
                                </Fragment>
                              )}
                              {item.pending_confirmation?.status && (
                                <Fragment>
                                  <div className={styles.waiting_state}>
                                    PENDING CONFIRMATION
                                  </div>
                                  <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                  >
                                    {moment(
                                      item.pending_confirmation.date.toDate()
                                    ).format("YYYY-MM-DD")}
                                  </Typography>
                                </Fragment>
                              )}
                              {item.waiting_to_be_shipped?.status && (
                                <Fragment>
                                  <div className={styles.waiting_state}>
                                    WAITING TO BE SHIPPED
                                  </div>
                                  <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                  >
                                    {moment(
                                      item.waiting_to_be_shipped.date.toDate()
                                    ).format("YYYY-MM-DD")}
                                  </Typography>
                                </Fragment>
                              )}
                              {item.shipped?.status && (
                                <Fragment>
                                  <div className={styles.waiting_state}>
                                    SHIPPED
                                  </div>
                                  <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                  >
                                    {moment(item.shipped.date.toDate()).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </Typography>
                                </Fragment>
                              )}
                              {item.out_for_delivery?.status && (
                                <Fragment>
                                  <div className={styles.waiting_state}>
                                    Out for delivery
                                  </div>
                                  <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                  >
                                    {moment(
                                      item.out_for_delivery.date.toDate()
                                    ).format("YYYY-MM-DD")}
                                  </Typography>
                                </Fragment>
                              )}
                              {item.delivered?.status && (
                                <Fragment>
                                  <div className={styles.done_state}>
                                    DELIVERED
                                  </div>
                                  <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                  >
                                    {moment(
                                      item.delivered.date.toDate()
                                    ).format("YYYY-MM-DD")}
                                  </Typography>
                                </Fragment>
                              )}
                              {item.returned?.status && (
                                <Fragment>
                                  <div className={styles.waiting_state}>
                                    RETURNED
                                  </div>
                                  <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                  >
                                    {moment(item.returned.date.toDate()).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </Typography>
                                </Fragment>
                              )}
                              {item.canceled?.status && (
                                <Fragment>
                                  <div className={styles.waiting_state}>
                                    Cancelled
                                  </div>
                                  <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                  >
                                    {moment(item.canceled.date.toDate()).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </Typography>
                                </Fragment>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))} */}
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                Item Two
              </TabPanel>
            </SwipeableViews>
          </Grid>
          <Grid item xs={12} lg={2}></Grid>
        </Grid>
      </Container>
    </MarketPlaceLayout>
  );
};

export default MarketPlaceOrders;
