import React, { useState, useEffect, useContext } from "react";
import ChannelView from "./ChannelView";
import useGetCurrentConversation from "../../../../hooks/chat/useGetCurrentConversation";
import { ChatContext } from "../../ChatController";
import * as queryString from "query-string";

const ChannelController = () => {
	const { chat_id, setCurrentChat } = useContext(ChatContext);

	const [chatID, setChatID] = useState<any>("");

	const { q } = queryString.parse(window.location.search);

	useEffect(() => {
		if (q === undefined || chat_id !== "") {
			setCurrentChat({
				chat_id: chat_id,
			});

			setChatID(chat_id);
		} else if (q !== undefined || chat_id === "") {
			setCurrentChat({
				// @ts-ignore
				chat_id: q,
			});

			setChatID(q);
		}
	}, [q, chat_id]);

	const GetCurrentConversationQuery = useGetCurrentConversation(chatID);

	const data = GetCurrentConversationQuery.isSuccess
		? GetCurrentConversationQuery.data
		: [];

	const currentConversation = data[0] ? data[0] : {};

	return (
		<div>
			<ChannelView
				data={currentConversation}
				isLoading={GetCurrentConversationQuery.isLoading}
			/>
		</div>
	);
};

export default ChannelController;
