  
import { useMutation } from "react-query";
import firebase from '../../../config/firebaseConfig';


const acceptCreativeJob = (values:{id:string,authuid:string,first_name:string,last_name:string})=>{
   return firebase.firestore().collection('CreativeJobs').doc(values.id).set({
    pending_review:false,
    to_be_processed:{
        status:true,
        on:firebase.firestore.FieldValue.serverTimestamp()
    },
    job_processed_by: {
        uid: values.authuid,
        name: `${values.first_name} ${values.last_name}`,
      
      },
   },{merge:true})
}

 const useAcceptCreativeJob = ()=>{
    return useMutation(acceptCreativeJob);
}


export default useAcceptCreativeJob;