export const SUPERVISOR = "SUPERVISOR";
export const EDITOR = "EDITOR";
export const ANALYST = "ANALYST";

export const SUPER_ADMINSTRATOR = "Super Administrator";
export const ADMINSTRATOR = "Administrator";
export const MANAGER = "Manager";
export const LEGAL = "Legal";
export const LOGISTICS = "Logistics";
export const LOGISTICS_MANAGER = "Logistics-Manager";
export const LOGISTICS_DISPATCHER = "Logistics-Dispatcher";
export const FINANCE = "Finance";
export const CREATIVE = "Creative";
export const ACREATIVE = "A-Creative";
export const FCREATIVE = "F-Creative";
export const HUMAN_RESOURCE = "Human Resource";
export const MEDIA = "Media";
