import { INVALID_EMAIL, USER_DISABLED, USER_NOT_FOUND, WRONG_PASSWORD } from '../../../constants/signinerror';
import { SignInValues } from '../../../ts/interfaces/signin';
import { FEEDBACK } from '../../../constants/feedback'

export const SignIn = (credentials: SignInValues) => (dispatch: any, getState: any, { getFirebase }: any) => {
    const firebase = getFirebase();
    firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
        .catch((error: any) => {
            var errorCode = error.code;
            //    var errorMessage = error.message;

            switch (errorCode) {
                case USER_DISABLED:
                    dispatch({ type: FEEDBACK, payload: { severity: 'error', title: 'User  disabled', body: "Oops! This user has been disabled" } })
                    break;
                case USER_NOT_FOUND:
                    dispatch({ type: FEEDBACK, payload: { severity: 'error', title: 'User  not found', body: "Oops! for some reasons this user is not found" } })
                    break;
                case INVALID_EMAIL:
                    dispatch({ type: FEEDBACK, payload: { severity: 'error', title: 'Invalid email', body: "Oops! This email is invalid! Check the format and try again" } })
                    break;
                case WRONG_PASSWORD:
                    dispatch({ type: FEEDBACK, payload: { severity: 'warning', title: 'Wrong password', body: "Oops! Your password is wrong! try again " } })
                    break;
                default:
                    break;
            }
        })

}