import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

//routes
import * as ROUTES from "../../../constants/routes";

//navigation hook
import { useNavigate } from "../../../hooks/navigate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    story_widgets_wrapper: {
      width: "100%",
      minHeight: 105,
      borderRadius: 10,
      position: "relative",
      marginBottom: 20,
    },
    story_widget_inner_wrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 20,
    },

    action_button: {
      position: "absolute",
      top: 60,
      right: 5,
    },
  })
);

interface Props {
  count: number;
  title: string;
  routeQuery: any;
  type: string;
}

const CreativesJobWidget = ({ count, title, routeQuery, type }: Props) => {
  const styles = useStyles();
  const { navigateToLink } = useNavigate();

  return (
    <Box className={styles.story_widgets_wrapper} boxShadow={6}>
      <div className={styles.story_widget_inner_wrapper}>
        <div>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
          <Typography variant="h4" color="inherit">
            {count}
          </Typography>
        </div>

        <Button
          className={styles.action_button}
          onClick={() =>
            navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
              query: routeQuery,
              type,
            })
          }
        >
          View
        </Button>
      </div>
    </Box>
  );
};

export default CreativesJobWidget;
