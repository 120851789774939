import { useMutation, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

const deleteKYCReport = (values: any) => {
	const kycReportsPromises: any = [];

	for (const report of values) {
		const p = firebase
			.firestore()
			.collection(`KYCReports`)
			.doc(report.data.id)
			.delete();
		kycReportsPromises.push(p);
	}

	return Promise.all([...kycReportsPromises]);
};

const useDeleteKYCReport = () => {
	return useMutation(deleteKYCReport, {
		onSuccess: () => {
			queryCache.invalidateQueries("allKYCReports");
		},
	});
};

export default useDeleteKYCReport;
