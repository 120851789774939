import React, { useState } from "react";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded, isEmpty } from "react-redux-firebase";

//material table
import MaterialTable, { MTableToolbar } from "material-table";

const FacebookShop = () => {
	const [tableData, setTableData] = useState<any>([]);

	useFirestoreConnect([
		{
			collection: "Products",
			where: [["visible_on_market", "==", true]],
			storeAs: "AllProducts",
		},
	]);

	const allProductsQuery = useSelector(
		(state: any) => state.firestore.data["AllProducts"]
	);

	React.useEffect(() => {
		if (isLoaded(allProductsQuery) && !isEmpty(allProductsQuery)) {
			const allProductsQuery_data = [];

			for (const key in allProductsQuery) {
				allProductsQuery_data.push({
					...allProductsQuery[key],
					allProductsQuery_uuid: key,
				});
			}

			const data = allProductsQuery_data.map((item: any) => {
				return {
					id: item.allProductsQuery_uuid,
					title: item.product_name,
					description: item.product_short_description,
					availability: "In stock",
					condition: "New",
					price: item.enable_discount
						? item.discount_price
						: item.regular_price,
					link: `https://ekioja.com/q/product/${item.product_permalink}`,
					image_link: item.image_1_url?.substr(
						0,
						item.image_1_url?.indexOf("?")
					),
					additional_image_link: item.image_1_url
						?.substr(0, item.image_1_url?.indexOf("?"))
						.replace("image_1", "image_2"),
					brand: item.store_name,
					google_product_category: item.product_category,
				};
			});

			setTableData([...data]);

			console.log("allProductsQuery_data", allProductsQuery_data);
		}
	}, [allProductsQuery]);
	return (
		<div>
			<MaterialTable
				isLoading={!isLoaded(allProductsQuery)}
				title={"Pixel Products integration"}
				columns={[
					{
						title: "id",
						field: "id",
					},
					{
						title: "brand",
						field: "brand",
						cellStyle: {
							backgroundColor: "rgba(34, 179, 88, 1)",
							color: "#FFF",
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 1)",
							color: "#FFF",
						},
					},
					{
						title: "title",
						field: "title",
						cellStyle: {
							width: 200,
						},

						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "description",
						field: "description",
						cellStyle: {
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "availability",
						field: "availability",
						cellStyle: {
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "condition",
						field: "condition",

						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "price",
						field: "price",

						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "link",
						field: "link",
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "image_link",
						field: "image_link",

						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "additional_image_link",
						field: "additional_image_link",
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "google_product_category",
						field: "google_product_category",
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
				]}
				data={tableData}
				options={{
					search: true,
					actionsColumnIndex: -1,
					exportAllData: true,
					exportButton: true,
					exportFileName: "vendors_data",
					rowStyle: {
						borderLeft: "6px solid green",
					},
					selection: true,
					selectionProps: (rowData: any) => ({
						disabled: rowData.data?.picked_package === true,
						color: "primary",
					}),
				}}
			/>
		</div>
	);
};

export default FacebookShop;
