import React, { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SendIcon from "@material-ui/icons/Send";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import FacebookCircularProgress from "../../../../../facebookStyleSpinner";

const useStyles = makeStyles({
	root: {
		width: "100%",
		height: 100,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	input: {
		width: "100%",
		height: "100%",
		borderRadius: 0,
		border: "none",
		"&:focus": {
			border: "none",
		},
	},
	input_wrapper: {
		backgroundColor: "white",
		border: "1px solid #ccc",
		width: "100%",
		height: 40,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		paddingRight: 20,
	},
	button: {
		height: 40,
		borderRadius: 0,
	},
});

interface Props {
	handleSendMessage: any;
	status: any;
	chatId: string;
}
const ChatInputView = (props: Props) => {
	const styles = useStyles();
	const [inputValue, setInputValue] = useState("");

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [disableSendOnEnter, setDisableSendOnEnter] = useState(false);

	return (
		<div className={styles.root}>
			<div className={styles.input_wrapper}>
				<input
					className={styles.input}
					value={inputValue}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setInputValue(e.target.value);
					}}
					onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
						if (e.key === "Enter" && disableSendOnEnter === false) {
							// console.log({
							// 	content: inputValue,
							// 	chat_id: props.chatId,
							// });
							props.handleSendMessage(
								{
									content: inputValue,
									chat_id: props.chatId,
								},
								{
									onSuccess: () => {
										props.status.reset();
										setInputValue("");
									},
								}
							);
						}
					}}
				/>
				{disableSendOnEnter === false &&
					(props.status.isLoading ? (
						<FacebookCircularProgress />
					) : props.status.isError ? (
						<ErrorRoundedIcon style={{ color: "red" }} />
					) : props.status.isSuccess ? (
						<SendIcon />
					) : (
						<SendIcon />
					))}
			</div>

			{disableSendOnEnter === true && (
				<Button
					className={styles.button}
					variant="outlined"
					color="primary"
					endIcon={
						props.status.isLoading ? (
							<FacebookCircularProgress />
						) : props.status.isError ? (
							<ErrorRoundedIcon style={{ color: "red" }} />
						) : props.status.isSuccess ? (
							<SendIcon />
						) : (
							<SendIcon />
						)
					}
					onClick={() => {
						// console.log(inputValue);
						props.handleSendMessage(
							{
								content: inputValue,
								chat_id: props.chatId,
							},
							{
								onSuccess: () => {
									props.status.reset();
									setInputValue("");
								},
							}
						);
					}}
				>
					send
				</Button>
			)}

			<IconButton
				aria-controls="chat-input-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="chat-input-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem
					onClick={() => {
						setDisableSendOnEnter(!disableSendOnEnter);
						handleClose();
					}}
				>
					{disableSendOnEnter === true
						? "Enable enter to send"
						: "Disable enter to send"}
				</MenuItem>
			</Menu>
		</div>
	);
};

export default ChatInputView;
