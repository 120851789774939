import React, { Fragment } from "react";
import OrderView from "./OrderView";
import {
	Conversation,
	Invoice,
	ShippingOption,
} from "../../../../../ConservationList/ConservationItem";
import _ from "lodash";

interface Props {
	data: Partial<Conversation>;
}
const OrderController = (props: Props) => {
	const data = props.data.orderTimeline ? props.data.orderTimeline : {};
	const invoice: Partial<Invoice[]> = props.data.invoice
		? props.data.invoice
		: [];

	const shippingOption: Partial<ShippingOption> = props.data.shippingOption
		? props.data.shippingOption
		: {};

	// const sumOfProductAmount = _.sumBy(invoice, "product_price");
	const sumOfProductAmount =
		props.data.shippingOption?.shipping_option === "door_delivery"
			? props.data.shippingOption.door_delivery_agreed_cost +
			  _.sumBy(props.data.invoice, (elem: any) => {
					return elem.product_price * elem.product_quantity;
			  })
			: _.sumBy(props.data.invoice, (elem: any) => {
					return elem.product_price * elem.product_quantity;
			  });

	const isHaveCompletedTransaction = _.has(props.data, "transactionComplete");

	const transactionCompleteData = {
		status: isHaveCompletedTransaction,
		brand_id: props.data.brand_id ? props.data.brand_id : "1saa",
		amount: sumOfProductAmount,
	};

	return (
		<Fragment>
			<OrderView
				data={data}
				invoice={invoice}
				chatId={props.data.chat_id}
				transactionCompleteData={transactionCompleteData}
				shippingOption={shippingOption}
			/>
		</Fragment>
	);
};

export default OrderController;
