import React, { useState } from "react";
//components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";

//styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

//formik
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";

//yup
import * as yup from "yup";

//icons
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

//images
import Logo from "../../../assets/images/svg/ekioja-logo.svg";

//login action
import { SignIn } from "../../../store/actions/auth/signin";

//react - redux
import { useDispatch, useSelector } from "react-redux";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//react router dom
import { Link, Redirect } from "react-router-dom";

//routes
import * as ROUTES from "../../../constants/routes";

//signup yup schema
const SignupValidationSchema = yup.object().shape({
	email: yup.string().email().required("Your email is Required"),
	password: yup
		.string()
		.label("Password")
		.required("Your password is Required"),
});

//styles functions
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "99vw",
			backgroundColor: "green",
		},
		form_wrapper: {
			padding: 20,
			borderRadius: 20,
			width: "100%",
			height: "auto",
			backgroundColor: "white",
		},
	})
);

const Login = () => {
	const styles = useStyles();

	const [showPassword, setshowPassword] = useState(false);
	const dispatch = useDispatch();
	const auth = useSelector((state: any) => state.firebase.auth);

	if (isLoaded(auth.uid)) {
		return <Redirect to={ROUTES.REDIRECT_BRIDGE_PAGE} />;
	}
	return (
		<div className={styles.root}>
			<Container>
				<Grid container>
					<Grid item xs={12} lg={4}></Grid>
					<Grid item xs={12} lg={4}>
						<div
							style={{
								height: 200,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<img src={Logo} alt="ekioja" style={{ width: 150 }} />
						</div>

						<Box boxShadow={5} className={styles.form_wrapper}>
							<Formik
								validationSchema={SignupValidationSchema}
								initialValues={{
									email: "",
									password: "",
								}}
								onSubmit={(values, { setSubmitting, resetForm }) => {
									setTimeout(() => {
										setSubmitting(false);
										dispatch(SignIn(values));
									}, 500);
								}}
							>
								{({ submitForm, isSubmitting, resetForm }) => (
									<Form>
										{isSubmitting && <LinearProgress />}

										<br />
										<br />

										<Field
											component={TextField}
											name="email"
											type="email"
											label="Email"
											fullWidth
										/>
										<br />
										<br />
										<Field
											component={TextField}
											type={showPassword ? "text" : "password"}
											label="Password"
											name="password"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<LockIcon />
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment
														position="start"
														onClick={() => setshowPassword(!showPassword)}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</InputAdornment>
												),
											}}
											fullWidth
										/>

										<br />
										<br />
										<Button
											variant="contained"
											style={{ backgroundColor: "#4caf50", color: "white" }}
											disabled={isSubmitting}
											onClick={submitForm}
										>
											Login
										</Button>

										<br />
										<br />
										<Link to="/forgot-password">
											<Button>Forgot password?</Button>
										</Link>
									</Form>
								)}
							</Formik>
						</Box>
					</Grid>
					<Grid item xs={12} lg={4}></Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Login;
