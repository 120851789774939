import { useMutation, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

interface Props {
	chat_id: string;
	content: string;
}

const sendChatMessage = async (values: Props) => {
	const conversationDocRef = firebase
		.firestore()
		.collection("Conversations")
		.doc(values.chat_id);
	//run transaction on conversation
	const conversationtransactionpromise = firebase
		.firestore()
		.runTransaction((transaction: any) => {
			return transaction.get(conversationDocRef).then((transactionDoc: any) => {
				if (!transactionDoc.exists) {
				}

				transaction.update(conversationDocRef, {
					chats: firebase.firestore.FieldValue.arrayUnion({
						content: values.content,
						timestamp: firebase.firestore.Timestamp.now(),
						type: "send",
						sender: "ekioja",
					}),
				});
			});
		});

	return conversationtransactionpromise;

	//   return firebase
	//     .firestore()
	//     .collection('Conversations')
	//     .doc(values.chat_id)
	//     .update({
	//       chats: firebase.firestore.FieldValue.arrayUnion({
	//         content: values.content,
	//         timestamp: firebase.firestore.Timestamp.now(),
	//         type: 'send',
	//         sender: 'buyer',
	//       }),
	//     })
};

const useSendChatMessage = () => {
	return useMutation(sendChatMessage, {
		onSettled: (returnedData, error, sentVaribles: any) => {
			queryCache.invalidateQueries([
				"chatCurrentConversation",
				sentVaribles.chat_id,
			]);
			// queryCache.refetchQueries(
			//   ['chatCurrentConversation', sentVaribles.chat_id],
			//   { active: true, exact: true },
			// )
		},
	});
};

export default useSendChatMessage;
