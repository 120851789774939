import React, { Fragment } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

import LoadingScreen from "../components/LoadingScreen";

// import InterestedVendors from "../components/DashBoardWidgets/InterestedVendors";
import UncheckedInterestedVendors from "../components/DashBoardWidgets/UncheckedInterestedVendors";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//utils
import { getInterestedVendors } from "../utils/index";

//routes
import * as ROUTES from "../constants/routes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // margin: 'auto',
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 300,
      height: 330,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    page_header: {
      width: "100%",
      height: 100,
      backgroundColor: "#99ff99",
      paddingTop: 20,
      paddingLeft: 20,
    },
  })
);
interface Props {}

const InterestedVendorsPage = (props: Props) => {
  const styles = useStyles();
  useFirestoreConnect([
    {
      collection: "InterestedVendors",
    },
  ]);

  const interestedVendors = useSelector(
    (state: any) => state.firestore.ordered.InterestedVendors
  );

  if (!isLoaded(interestedVendors)) return <LoadingScreen />;
  return (
    <Fragment>
      <DashBoardLayout>
        <div className={styles.page_header}>
          <Typography variant="h4" color="primary">
            {" "}
            Interested Vendors Management
          </Typography>
        </div>
        <br />
        <Grid container direction="column" spacing={4}>
        <Grid item xs={12} lg={12}>
            <UncheckedInterestedVendors
              title="Un-checked Interested Vendors!"
              data={getInterestedVendors(
                interestedVendors,
                ROUTES.INTERESTED_VENDORS_QUERIES.UNCHECKED_INTERESTED_VENDORS
              )}
              show_action={true}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
          <UncheckedInterestedVendors
              title="Today's Interested Vendors"
              data={getInterestedVendors(
                interestedVendors,
                ROUTES.INTERESTED_VENDORS_QUERIES.TODAYS_INTERESTED_VENDORS
              )}
            />
            <div style={{ marginTop: 20 }} />
          <UncheckedInterestedVendors
              title="This Month's Interested Vendors"
              data={getInterestedVendors(
                interestedVendors,
                ROUTES.INTERESTED_VENDORS_QUERIES
                  .PREVIOUS_DAYS_INTERESTED_VENDORS
              )}
            /> 
           
          </Grid>
          {/* <div style={{ marginTop: 20 }} />
          <Grid item xs={12} lg={12}>
            <UncheckedInterestedVendors
              title="This Month's Interested Vendors"
              data={getInterestedVendors(
                interestedVendors,
                ROUTES.INTERESTED_VENDORS_QUERIES
                  .PREVIOUS_DAYS_INTERESTED_VENDORS
              )}
            />
          </Grid>
          <div style={{ marginTop: 20 }} />
          <Grid item xs={12} lg={12}>
            <InterestedVendors
              title="Checked Interested Vendors"
              data={getInterestedVendors(
                interestedVendors,
                ROUTES.INTERESTED_VENDORS_QUERIES.CHECKED_INTERESTED_VENDORS
              )}
            />
          </Grid> */}
          <div style={{ marginTop: 20 }} />
         
        </Grid>
      </DashBoardLayout>
    </Fragment>
  );
};

export default InterestedVendorsPage;
