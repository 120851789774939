import React from "react";

import {
	Paper,
	Grid,
	CircularProgress,
	Box,
	IconButton,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import useMediaQuery from "@material-ui/core/useMediaQuery";
export default function ImageElement({
	image,
	index,
	isFirstElement,
	isLastElement,
	handleChangeOrderUp,
	handleChangeOrderDown,
	handleDeleteImage,
}: any) {
	const matches = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
	return (
		<Box my={2} width={matches ? "100%" : 400}>
			<Paper>
				<Grid container direction="row" justify="center" spacing={2}>
					<Grid item container alignItems="center" justify="center" xs={10}>
						{image.downloadURL ? (
							<img
								src={image.downloadURL}
								alt={`Upload Preview ${index + 1}`}
								style={{
									maxHeight: "100%",
									maxWidth: "100%",
								}}
							/>
						) : (
							<Box p={2}>
								<CircularProgress />
							</Box>
						)}
					</Grid>
					<Grid
						container
						direction="column"
						alignItems="center"
						justify="center"
						item
						xs={2}
					>
						<Grid item container alignItems="center" justify="center">
							{!isFirstElement && (
								<IconButton
									aria-label="Image up"
									onClick={() => handleChangeOrderUp(index)}
								>
									<KeyboardArrowUpIcon />
								</IconButton>
							)}
						</Grid>
						<Grid item container alignItems="center" justify="center" xs={4}>
							<IconButton
								aria-label="Delete Image"
								onClick={() => {
									const r = window.confirm("Are you sure?");
									if (r === true) {
										handleDeleteImage(index);
									}
								}}
							>
								<DeleteForeverIcon />
							</IconButton>
						</Grid>
						<Grid item container alignItems="center" justify="center">
							{!isLastElement && (
								<IconButton
									aria-label="Image down"
									onClick={() => handleChangeOrderDown(index)}
								>
									<KeyboardArrowDownIcon />
								</IconButton>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
}
