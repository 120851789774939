import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
const addImageToAuthProfile = async ({ url, uid }: { url: any; uid: any }) => {
	const upDateProfilePhotoURLPromise = await firebase
		.auth()
		.currentUser?.updateProfile({
			photoURL: url,
		});

	const updateInAdminCollectionPromise = firebase
		.firestore()
		.collection("Admins")
		.doc(uid)
		.set(
			{
				photoURL: url,
			},
			{ merge: true }
		);

	const allPromise = Promise.all([
		upDateProfilePhotoURLPromise,
		updateInAdminCollectionPromise,
	]);

	return allPromise;
};

const useAddImageToAuthProfile = () => {
	return useMutation(addImageToAuthProfile);
};

export default useAddImageToAuthProfile;
