import { useQuery, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

const getAllEkiojaAdmins = async () => {
	const response = await firebase
		.firestore()
		.collection("Admins")
		.where("entity", "==", "ekioja-super")
		.get();
	let data: any = [];
	response.forEach((doc: any) => {
		data.push({ id: doc.id, ...doc.data() });
	});
	return data;
};

const useGetAllEkiojaAdmins = () => {
	return useQuery("allEkiojaAdmins", getAllEkiojaAdmins, {
		initialData: () => {
			if (queryCache.getQueryData("allEkiojaAdmins")) {
				return queryCache.getQueryData("allEkiojaAdmins");
			}
		},
		refetchOnWindowFocus: false,
	});
};

export default useGetAllEkiojaAdmins;
