  
import { useQuery } from "react-query";
import firebase from '../../config/firebaseConfig';


const getProductData = async (key:any,id:string) =>{
    const response = await firebase.firestore().collection('Products').doc(id).get();
    const data = response.data();

    return data
}


 const useGetProduct = (id:string)=>{
    return useQuery(['productData',id],getProductData)
}

export default useGetProduct;