import React, { useContext, Suspense, lazy } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import IconButton from "@material-ui/core/IconButton";
import Tab from "@material-ui/core/Tab";
import CloseIcon from "@material-ui/icons/Close";
import ReceiptIcon from "@material-ui/icons/Receipt";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import { ChatContext } from "../../../../ChatController";
import Button from "@material-ui/core/Button";
import { Conversation } from "../../../ConservationList/ConservationItem";
import { has } from "lodash";
// import AddMoreProductFromBrand from "./components/Invoice/components/AddMoreProductFromBrand";
import ShippingOptions from "./components/Invoice/components/ShippingOptions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import SummaryAndPayment from "./components/Invoice/components/SummaryAndPayment";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import Order from "./components/Order";

interface Props {
	data: Conversation;
	generateInvoiceMutation: any;
	generateInvoiceStatus: any;
}

const Invoice = lazy(() => import("./components/Invoice"));

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`chat-activity-tabpanel-${index}`}
			aria-labelledby={`chat-activity-tab-${index}`}
			style={{ padding: 0 }}
			{...other}
		>
			{value === index && <>{children}</>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `chat-activity-tab-${index}`,
		"aria-controls": `chat-activity-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		height: 510,
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down("sm")]: {
			display: "none",
			position: "fixed",
			top: 0,
			left: 0,
			width: "100vw",
			height: "100vh",
			zIndex: 999,
		},
	},
	app_root: {
		flexGrow: 1,
		position: "static",
	},
	closeButton: {
		position: "fixed",
		top: 0,
		left: 0,
		display: "none",
		zIndex: 999,
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
	},
}));
const ChatActivityView = (props: Props) => {
	const styles = useStyles();
	const [value, setValue] = React.useState(0);

	const MatcheSM = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("sm")
	);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	const { openChatActivity, handleOpenChatActivity } = useContext(ChatContext);

	const [openSummary, setOpenSummary] = React.useState(false);

	const handleClickOpenSummary = () => {
		setOpenSummary(true);
	};

	const handleCloseSummary = () => {
		setOpenSummary(false);
	};

	return (
		<div
			className={styles.root}
			style={{ display: openChatActivity ? "block" : "none" }}
		>
			<div className={styles.closeButton}>
				<IconButton
					onClick={() => handleOpenChatActivity({ openChatActivity: false })}
				>
					<CloseIcon />
				</IconButton>
			</div>
			<AppBar position="static" className={styles.app_root}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="chat-activity tabs"
					centered
				>
					<Tab label="Invoice" icon={<ReceiptIcon />} {...a11yProps(0)} />
					<Tab label="Order" icon={<LocalMallIcon />} {...a11yProps(1)} />
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0}>
				<div
					style={{
						width: "100%",
						height: MatcheSM ? "100vh" : 400,
						overflowX: "hidden",
						overflowY: "auto",
					}}
				>
					{!has(props.data, "invoice") && (
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: 30,
							}}
						>
							<Button
								variant="contained"
								color="primary"
								startIcon={<ReceiptIcon />}
								onClick={() => {
									if (window.confirm("Are you sure?")) {
										props.generateInvoiceMutation({
											chat_id: props.data.chat_id,
											product_id: props.data.product_id,
											product_name: props.data.product_name,
											product_price: props.data.product_price.asking_price,
											product_quantity: 1,
											product_image: props.data.product_image,
										});
									}
								}}
							>
								{props.generateInvoiceStatus.isLoading
									? "Processing..."
									: props.generateInvoiceStatus.isError
									? "Error!"
									: props.generateInvoiceStatus.isSuccess
									? "Done!"
									: "Generate Invoice"}
							</Button>
						</div>
					)}

					{has(props.data, "invoice") && (
						<Suspense fallback={<p>Loading...</p>}>
							<Invoice data={props.data} />
						</Suspense>
					)}

					{!_.has(props.data, "payment") === true && (
						<>
							{/* <AddMoreProductFromBrand data={props.data} /> */}
							<ShippingOptions
								data={props.data}
								handleOpenSummary={handleClickOpenSummary}
							/>
						</>
					)}

					{/* <SummaryAndPayment
						open={openSummary}
						handleClose={handleCloseSummary}
						data={props.data}
					/> */}
					{_.has(props.data, "shippingOption") && (
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: 30,
							}}
						>
							{!_.has(props.data, "payment") && (
								<Button
									variant="contained"
									color="primary"
									onClick={handleClickOpenSummary}
									// onClick={() =>
									// 	navigate(`/summary-and-payment/?q=${props.data.chat_id}`)
									// }
									style={{ color: "#fff" }}
								>
									Proceed to Summary
								</Button>
							)}
							{_.has(props.data, "payment") && (
								<Typography variant="body1" color="textPrimary" align="center">
									Customer had paid and initialized the order.
								</Typography>
							)}
						</div>
					)}
				</div>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<div
					style={{
						width: "100%",
						height: MatcheSM ? "100vh" : 400,
						overflowX: "hidden",
						overflowY: "auto",
					}}
				>
					{_.has(props.data, "orderTimeline") && <Order data={props.data} />}
					{!_.has(props.data, "orderTimeline") && (
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: 10,
								height: 300,
							}}
						>
							No order initialised yet
						</div>
					)}
				</div>
			</TabPanel>
		</div>
	);
};

export default ChatActivityView;
