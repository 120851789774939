import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
//routes
import * as ROUTES from "../../../constants/routes";

//navigation hook
import { useNavigate } from "../../../hooks/navigate";

const useStyles = makeStyles((theme) => ({
	pending_vendors_wrapper: {
		// width: '90%',
		minHeight: 105,
		borderRadius: 10,
		position: "relative",
		marginBottom: 5,
		// backgroundColor:'#fff'
		// background: "linear-gradient(45deg,  #ccffcc 90%, #99ff99 50%)",
	},
	pending_vendor_inner_wrapper: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 20,
	},

	action_button: {
		position: "absolute",
		top: 60,
		right: 5,
		color: "black",
	},
}));

const QuickLinkItem = ({
	count,
	title,
	routeQuery,
	query,
}: {
	count: number;
	title: string;
	routeQuery: string;
	query: "store" | "product";
}) => {
	const styles = useStyles();
	const { navigateToLink } = useNavigate();

	return (
		<Box className={styles.pending_vendors_wrapper}>
			<Card
				style={{
					height: 96,
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					padding: 12,
					backgroundColor: "#22b358",
					width: "100%",
				}}
			>
				<div style={{ width: "100%" }}>
					<span
						style={{
							color: "#fff",
							// textTransform: "uppercase",
							fontSize: "0.4em !important",
						}}
					>
						{title}
					</span>
					<h4
						style={{
							color: "#fff",
							margin: 0,
							paddingTop: 2,
							fontWeight: "normal",
							fontSize: 25,
						}}
					>
						{count}
					</h4>
					{query === "store" && (
						<Button
							className={styles.action_button}
							onClick={() =>
								navigateToLink.push(ROUTES.VIEWALLSTORES, { query: routeQuery })
							}
						>
							View
						</Button>
					)}
					{query === "product" && (
						<Button
							className={styles.action_button}
							onClick={() =>
								navigateToLink.push(ROUTES.VIEWPRODUCTS, { query: routeQuery })
							}
						>
							View
						</Button>
					)}
				</div>
				<div className="w-56 h-36">{/* <DummyChart height="40px" /> */}</div>
			</Card>
		</Box>
	);
};

export default QuickLinkItem;
