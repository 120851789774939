
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const UnderVetting = (id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

       

        return firebase.firestore().collection('VetDocuments').doc(id).set({
            
            to_be_vetted:{
                status:false,
            },
            under_vetting:{
                status:true,
                on:firebase.firestore.FieldValue.serverTimestamp()
            },
         },{merge:true}).then(() => {
               
                
            });
           

    }