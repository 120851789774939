import { useQuery } from "react-query";
import firebase from "../../config/firebaseConfig";

const getCurrentConversation = async (key: any, id: string) => {
	const response = await firebase
		.firestore()
		.collection("Conversations")
		// .where('customer_id', '==', id)
		.where("chat_id", "==", id)
		// .orderBy('added_on', 'desc')
		.get();
	let data: any = [];

	response.forEach((doc: any) => {
		data.push({ id: doc.id, ...doc.data() });
	});

	return data;
};

const useGetCurrentConversation = (id: string) => {
	return useQuery(["chatCurrentConversation", id], getCurrentConversation);
};

export default useGetCurrentConversation;
