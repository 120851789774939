import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { default as MyTextField } from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
	Autocomplete,
	AutocompleteRenderInputParams,
} from "formik-material-ui-lab";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
// import Slide from "@material-ui/core/Slide";
import { Formik, Form, Field } from "formik";
import { TextField, Select } from "formik-material-ui";
// import { useGetEkiojaNiches, useUpdateStoreData } from "app/hooks";

// import useGetStoreData from "../../../hooks/useGetStoreData";
import useUpdateStoreData from "../../../hooks/useUpdateStoreData";
// import useUpdateStoreActive from "../../../hooks/useUpdateStoreActive";
// import useDeleteImage from "../../../hooks/useDeleteImage";
import useGetEkiojaNiches from "../../../hooks/useGetEkiojaNiches";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Button from "@material-ui/core/Button";
// import _ from "lodash";
//@ts-ignore
import NaijaStates from "naija-state-local-government";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
		backgroundColor: "#99ff99",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	root: {
		width: "100%",
	},
	form_wrapper: {
		padding: 5,
		borderRadius: 20,
		width: "100%",
		height: "auto",
		marginBottom: 100,
	},
	niches: {
		width: "98%",
		height: 60,
		padding: 10,
		backgroundColor: "white",
		borderRadius: 10,
		marginBottom: 5,
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	form_section: {
		width: "100%",
		height: "auto",
		padding: 10,
		//   backgroundColor: theme.palette.background.paper,
		borderRadius: 5,
	},
	small_avatar: {
		width: 20,
		height: 20,
		borderRadius: 20,
		backgroundColor: "green",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
	},
	form_section_title: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	store_logo_wrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& img": {
			width: 150,
			height: 150,
		},
	},
	store_banner_wrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		"& img": {
			width: 250,
			height: 150,
		},
	},
	store_logo_preview_wrapper: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		height: 300,
	},
	page_header: {
		width: "100%",
		height: 60,
		backgroundColor: "#99ff99",
		paddingTop: 20,
		paddingLeft: 20,
	},
	page_tab: {
		width: "100%",
		height: 40,
		backgroundColor: "#99ff99",
	},
	contact_hours_wrapper: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		width: "100%",
	},
	contact_hours_wrapper_day: {
		width: 200,
	},
	contact_hours_wrapper_open_close_wrapper: {
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		width: "100%",
	},
	contact_hours_wrapper_field_wrapper: {
		width: 150,
		marginLeft: 20,
		marginBottom: 20,
	},
	contact_hours_wrapper_to_wrapper: {
		marginLeft: 10,
	},
	verfiy_continue_wrapper: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
	},
}));

interface StyledTabProps {
	label: string;
}

const AntTabs = withStyles({
	root: {
		borderBottom: "1px solid #99ff99",
	},
	indicator: {
		backgroundColor: "#18591e",
	},
})(Tabs);

const AntTab = withStyles((theme) =>
	createStyles({
		root: {
			textTransform: "none",
			minWidth: 72,
			fontWeight: theme.typography.fontWeightRegular,
			marginRight: theme.spacing(4),
			fontFamily: [
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
			"&:hover": {
				color: "#fff",
				opacity: 1,
			},
			"&$selected": {
				color: "#18591e",
				fontWeight: theme.typography.fontWeightMedium,
			},
			"&:focus": {
				color: "#18591e",
			},
		},
		selected: {},
	})
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: any;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`storeData-settings-scrollable-prevent-tabpanel-${index}`}
			aria-labelledby={`storeData-settings-scrollable-prevent-tab-${index}`}
			{...other}
		>
			{value === index && <>{children}</>}
		</div>
	);
};

const a11yProps = (index: any) => {
	return {
		id: `storeData-settings-scrollable-prevent-tab-${index}`,
		"aria-controls": `storeData-settings-scrollable-prevent-tabpanel-${index}`,
	};
};

interface Props {
	storeData: any;
	handleCloseModal: any;
}

const EditStoreDetailsModal = ({ storeData, handleCloseModal }: Props) => {
	const classes = useStyles();

	const [tabIndexValue, setTabIndexValue] = useState(0);

	const handleTabIndexChange = (
		event: React.ChangeEvent<{}>,
		newValue: number
	) => {
		setTabIndexValue(newValue);
	};

	const [descriptionWords, setDescriptionWords] = useState("");
	const [wordCount, setWordCount] = useState(0);

	const ekiojaNichesQuery = useGetEkiojaNiches();
	const [UpdateStoreDataMutate, UpdateStoreDataInfo] = useUpdateStoreData();

	const openHours = ["8am", "9am", "10am", "11am", "12pm", "1pm"];
	const closeHours = ["1pm", "2pm", "3pm", "4pm", "5pm", "6pm"];
	useEffect(() => {
		if (typeof storeData !== "undefined") {
			setDescriptionWords(storeData.business_description);
		}
	}, [storeData]);

	return (
		<div>
			<ToastContainer />

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<AntTabs
					value={tabIndexValue}
					onChange={handleTabIndexChange}
					aria-label="Store settings tab"
				>
					{/* <AntTab label="Image" {...a11yProps(0)} /> */}
					<AntTab label="Availability" {...a11yProps(0)} />
					<AntTab label="Details" {...a11yProps(1)} />
				</AntTabs>
			</div>

			<div className="p-4">
				<Formik
					initialValues={{
						id: storeData.unique_id,
						business_name: storeData.business_name,
						business_description: storeData.business_description,
						business_logo_id: storeData.business_logo_id,
						business_banner_id: storeData.business_banner_id,
						// business_banner_id: `banner_${storeData.unique_id}`,
						business_slogan: storeData.business_slogan,
						business_email: storeData.business_email,
						business_cac: storeData.business_cac,
						business_website: storeData.business_website,
						business_location: {
							street_address_1: storeData.business_location.street_address_1,
							postal_code_1: storeData.business_location.postal_code_1,
							state: storeData.business_location.hasOwnProperty("state")
								? storeData.business_location.state
								: "",
							lga: storeData.business_location.hasOwnProperty("lga")
								? storeData.business_location.lga
								: "",
						},
						social_media: storeData.social_media,
						business_niche: storeData.business_niche,
						business_contact_hours:
							typeof storeData.business_contact_hours !== "undefined"
								? storeData.business_contact_hours
								: {},
					}}
					onSubmit={(values, { setSubmitting }) => {
						setTimeout(() => {
							setSubmitting(false);

							values.business_description = descriptionWords;

							UpdateStoreDataMutate(values, {
								onSuccess: () => {
									// queryCache.refetchQueries("storeData");
									toast.success(
										"Your storeData have been successfully updated!",
										{
											position: "top-right",
											autoClose: 5000,
											hideProgressBar: false,
											closeOnClick: true,
										}
									);
									handleCloseModal();
								},
								onError: () => {
									toast.error("Oops! An error occured!  Try agin", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
								},
							});
						}, 500);
					}}
				>
					{({ submitForm, isSubmitting, values, handleChange }) => (
						<>
							<Form>
								{/* <TabPanel value={tabIndexValue} index={0}>
									<Grid container>
										<Grid item xs={12} lg={2}></Grid>
										<Grid item xs={12} lg={8}>
											<br />
											<br />
											<div>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={6}>
														{values.business_logo_id === "" && (
															<div
																className={classes.store_logo_preview_wrapper}
															>
																<div style={{ width: "70%" }}>
																	<div>
																		<ImageUploader
																			onRequestSave={(id) =>
																				setFieldValue(
																					"business_logo_id",
																					id,
																					true
																				)
																			}
																			onRequestClear={() => {
																				setFieldValue(
																					"business_logo_id",
																					"",
																					true
																				);
																			}}
																			label="Upload Business Logo"
																			existingImageId={`logo_${values.id}`}
																		/>
																	</div>
																</div>
															</div>
														)}

														{values.business_logo_id !== "" && (
															<div
																className={classes.store_logo_preview_wrapper}
															>
																<div className={classes.store_logo_wrapper}>
																	<br />
																	<Typography variant="caption" color="inherit">
																		Recommendation: 150px by 150px in png format
																	</Typography>
																	<br />
																	<GetImageFromFirebase
																		source={storeData.business_logo_id}
																	/>
																	<br />
																	<br />
																	<Button
																		variant="contained"
																		color="secondary"
																		onClick={() => {
																			setFieldValue(
																				"business_logo_id",
																				"",
																				true
																			);

																			DeleteImageMutate(`logo_${values.id}`);
																		}}
																	>
																		Remove logo
																	</Button>
																</div>
															</div>
														)}
													</Grid>
													<Grid item xs={12} lg={6}>
														{values.business_banner_id === "" && (
															<div
																className={classes.store_logo_preview_wrapper}
															>
																<div style={{ width: "70%" }}>
																	<div>
																		<ImageUploader
																			onRequestSave={(id) =>
																				setFieldValue(
																					"business_banner_id",
																					id,
																					true
																				)
																			}
																			onRequestClear={() => {
																				setFieldValue(
																					"business_banner_id",
																					"",
																					true
																				);
																			}}
																			label="Upload your business banner"
																			existingImageId={`banner_${values.id}`}
																		/>
																	</div>
																</div>
															</div>
														)}

														{values.business_banner_id !== "" && (
															<div
																className={classes.store_logo_preview_wrapper}
															>
																<div className={classes.store_banner_wrapper}>
																	<br />
																	<Typography variant="caption" color="inherit">
																		Recommendation: 1200px by 240px in png
																		format
																	</Typography>
																	<br />
																	<GetImageFromFirebase
																		source={`banner_${values.id}`}
																	/>
																	<br />
																	<br />
																	<Button
																		variant="contained"
																		color="secondary"
																		onClick={() => {
																			setFieldValue(
																				"business_banner_id",
																				"",
																				true
																			);

																			DeleteImageMutate(`banner_${values.id}`);
																		}}
																	>
																		Remove Banner
																	</Button>
																</div>
															</div>
														)}
													</Grid>
												</Grid>
											</div>
										</Grid>
										<Grid item xs={12} lg={2}></Grid>
									</Grid>
								</TabPanel> */}
								<TabPanel value={tabIndexValue} index={0}>
									<Grid container>
										<Grid item xs={12} lg={2}></Grid>
										<Grid item xs={12} lg={8}>
											<br />
											<br />
											<div style={{ width: "100%" }}>
												<Typography
													variant="h6"
													color="inherit"
													align="center"
													gutterBottom
												>
													Business Contact Hours
												</Typography>
												<Card>
													<CardContent
														className={classes.contact_hours_wrapper}
													>
														<div className={classes.contact_hours_wrapper_day}>
															<Typography variant="h6" color="inherit">
																Monday
															</Typography>
														</div>
														<Divider orientation="vertical" flexItem />
														<div
															className={
																classes.contact_hours_wrapper_open_close_wrapper
															}
														>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="monday-open-field">
																	open
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.monday.open"
																	inputProps={{
																		id: "monday-open-field",
																	}}
																>
																	{openHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
															<div
																className={
																	classes.contact_hours_wrapper_to_wrapper
																}
															>
																<Typography variant="h6" color="inherit">
																	{" "}
																	to{" "}
																</Typography>
															</div>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="monday-close-field">
																	close
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.monday.close"
																	inputProps={{
																		id: "monday-close-field",
																	}}
																>
																	{closeHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
														</div>
													</CardContent>
												</Card>
												<br />
												<Card>
													<CardContent
														className={classes.contact_hours_wrapper}
													>
														<div className={classes.contact_hours_wrapper_day}>
															<Typography variant="h6" color="inherit">
																Tuesday
															</Typography>
														</div>
														<Divider orientation="vertical" flexItem />
														<div
															className={
																classes.contact_hours_wrapper_open_close_wrapper
															}
														>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="tuesday-open-field">
																	open
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.tuesday.open"
																	inputProps={{
																		id: "tuesday-open-field",
																	}}
																>
																	{openHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
															<div
																className={
																	classes.contact_hours_wrapper_to_wrapper
																}
															>
																<Typography variant="h6" color="inherit">
																	{" "}
																	to{" "}
																</Typography>
															</div>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="tuesday-close-field">
																	close
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.tuesday.close"
																	inputProps={{
																		id: "tuesday-close-field",
																	}}
																>
																	{closeHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
														</div>
													</CardContent>
												</Card>
												<br />
												<Card>
													<CardContent
														className={classes.contact_hours_wrapper}
													>
														<div className={classes.contact_hours_wrapper_day}>
															<Typography variant="h6" color="inherit">
																Wednesday
															</Typography>
														</div>
														<Divider orientation="vertical" flexItem />
														<div
															className={
																classes.contact_hours_wrapper_open_close_wrapper
															}
														>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="wednesday-open-field">
																	open
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.wednesday.open"
																	inputProps={{
																		id: "wednesday-open-field",
																	}}
																>
																	{openHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
															<div
																className={
																	classes.contact_hours_wrapper_to_wrapper
																}
															>
																<Typography variant="h6" color="inherit">
																	{" "}
																	to{" "}
																</Typography>
															</div>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="wednesday-close-field">
																	close
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.wednesday.close"
																	inputProps={{
																		id: "wednesday-close-field",
																	}}
																>
																	{closeHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
														</div>
													</CardContent>
												</Card>
												<br />
												<Card>
													<CardContent
														className={classes.contact_hours_wrapper}
													>
														<div className={classes.contact_hours_wrapper_day}>
															<Typography variant="h6" color="inherit">
																Thursday
															</Typography>
														</div>
														<Divider orientation="vertical" flexItem />
														<div
															className={
																classes.contact_hours_wrapper_open_close_wrapper
															}
														>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="thursday-open-field">
																	open
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.thursday.open"
																	inputProps={{
																		id: "thursday-open-field",
																	}}
																>
																	{openHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
															<div
																className={
																	classes.contact_hours_wrapper_to_wrapper
																}
															>
																<Typography variant="h6" color="inherit">
																	{" "}
																	to{" "}
																</Typography>
															</div>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="thursday-close-field">
																	close
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.thursday.close"
																	inputProps={{
																		id: "thursday-close-field",
																	}}
																>
																	{closeHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
														</div>
													</CardContent>
												</Card>
												<br />
												<Card>
													<CardContent
														className={classes.contact_hours_wrapper}
													>
														<div className={classes.contact_hours_wrapper_day}>
															<Typography variant="h6" color="inherit">
																Friday
															</Typography>
														</div>
														<Divider orientation="vertical" flexItem />
														<div
															className={
																classes.contact_hours_wrapper_open_close_wrapper
															}
														>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="friday-open-field">
																	open
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.friday.open"
																	inputProps={{
																		id: "friday-open-field",
																	}}
																>
																	{openHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
															<div
																className={
																	classes.contact_hours_wrapper_to_wrapper
																}
															>
																<Typography variant="h6" color="inherit">
																	{" "}
																	to{" "}
																</Typography>
															</div>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="friday-close-field">
																	close
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.friday.close"
																	inputProps={{
																		id: "friday-close-field",
																	}}
																>
																	{closeHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
														</div>
													</CardContent>
												</Card>
												<br />
												<Card>
													<CardContent
														className={classes.contact_hours_wrapper}
													>
														<div className={classes.contact_hours_wrapper_day}>
															<Typography variant="h6" color="inherit">
																Saturday
															</Typography>
														</div>
														<Divider orientation="vertical" flexItem />
														<div
															className={
																classes.contact_hours_wrapper_open_close_wrapper
															}
														>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="saturday-open-field">
																	open
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.saturday.open"
																	inputProps={{
																		id: "saturday-open-field",
																	}}
																>
																	{openHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
															<div
																className={
																	classes.contact_hours_wrapper_to_wrapper
																}
															>
																<Typography variant="h6" color="inherit">
																	{" "}
																	to{" "}
																</Typography>
															</div>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="saturday-close-field">
																	close
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.saturday.close"
																	inputProps={{
																		id: "saturday-close-field",
																	}}
																>
																	{closeHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
														</div>
													</CardContent>
												</Card>
												<br />
												<Card>
													<CardContent
														className={classes.contact_hours_wrapper}
													>
														<div className={classes.contact_hours_wrapper_day}>
															<Typography variant="h6" color="inherit">
																Sunday
															</Typography>
														</div>
														<Divider orientation="vertical" flexItem />
														<div
															className={
																classes.contact_hours_wrapper_open_close_wrapper
															}
														>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="sunday-open-field">
																	open
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.sunday.open"
																	inputProps={{
																		id: "sunday-open-field",
																	}}
																>
																	{openHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
															<div
																className={
																	classes.contact_hours_wrapper_to_wrapper
																}
															>
																<Typography variant="h6" color="inherit">
																	{" "}
																	to{" "}
																</Typography>
															</div>
															<FormControl
																className={
																	classes.contact_hours_wrapper_field_wrapper
																}
															>
																<InputLabel htmlFor="sunday-close-field">
																	close
																</InputLabel>
																<Field
																	component={Select}
																	name="business_contact_hours.sunday.close"
																	inputProps={{
																		id: "sunday-close-field",
																	}}
																>
																	{closeHours.map((item, index) => (
																		<MenuItem
																			key={`${item}+${index}`}
																			value={item}
																		>
																			{item}
																		</MenuItem>
																	))}
																</Field>
															</FormControl>
														</div>
													</CardContent>
												</Card>
											</div>
											<br />
											<br />

											{isSubmitting && <LinearProgress />}

											<br />
											<br />

											<Button
												variant="contained"
												color="primary"
												disabled={isSubmitting}
												onClick={submitForm}
											>
												{UpdateStoreDataInfo.isLoading && "Updating..."}
												{UpdateStoreDataInfo.isIdle && "Update"}
												{UpdateStoreDataInfo.isSuccess && "Updated!"}
											</Button>
											<br />
											<br />
										</Grid>
										<Grid item xs={12} lg={2}></Grid>
									</Grid>
								</TabPanel>
								<TabPanel value={tabIndexValue} index={1}>
									<Grid container>
										<Grid item xs={12} lg={2}></Grid>
										<Grid item xs={12} lg={8}>
											<br />
											<br />
											<br />
											<div>
												<div className={classes.form_section_title}>
													{/*<div className={classes.small_avatar}>*</div>*/}
													<Typography variant="caption">
														Tell us about your business
													</Typography>
												</div>
												<Box className={classes.form_section} boxShadow={5}>
													<Field
														component={TextField}
														name="business_name"
														type="text"
														label="Business name"
														fullWidth
														required
														disabled
													/>
													<br />
													<br />
													<Field
														component={TextField}
														name="business_email"
														type="email"
														label="Business email"
														fullWidth
														required
														disabled
													/>
													<br />
													<br />
													<Field
														component={TextField}
														name="business_cac"
														type="text"
														label="Business CAC"
														fullWidth
														required
														disabled
													/>
													<br />
													<br />
													<Field
														component={TextField}
														name="business_website"
														type="url"
														label="Website(if any)"
														fullWidth
													/>
													<br />
													<br />
													<Field
														component={TextField}
														name="business_slogan"
														type="text"
														label="Business Slogan"
														fullWidth
													/>
												</Box>
											</div>

											{/* <div>
                                        <div className={classes.form_section_title}>
                                            <Avatar className={classes.small_avatar}>1</Avatar>
                                            <Typography variant="caption">Tell us about your business</Typography>
                                        </div>
                                        <Box className={classes.form_section} boxShadow={5}>

                                        </Box>

                                    </div> */}
											<br />
											<br />
											<div>
												<div className={classes.form_section_title}>
													{/*<div className={classes.small_avatar}>*</div>*/}
													<Typography variant="caption">
														Brief description about your business.
													</Typography>
												</div>
												<Box className={classes.form_section} boxShadow={5}>
													<Field
														component={TextField}
														name="business_description"
														type="text"
														label="Business description"
														helperText={`Character count ${
															230 - wordCount
														}/230 remaining`}
														multiline
														rows={6}
														fullWidth
														required
														value={descriptionWords}
														onInput={(
															e: React.ChangeEvent<HTMLInputElement>
														) => {
															if (e.target.value.length >= 230) {
																let value = e.target.value.slice(0, 230);
																setDescriptionWords(value);
																setWordCount(value.length);
															}
														}}
														onChange={(
															e: React.ChangeEvent<HTMLInputElement>
														) => {
															if (e.target.value.length >= 230) {
																let value = e.target.value.slice(0, 230);
																setDescriptionWords(value);
																setWordCount(value.length);
															} else if (e.target.value.length < 230) {
																setDescriptionWords(e.target.value);
																setWordCount(e.target.value.length);
															}
														}}
														onPaste={(
															e: React.ClipboardEvent<HTMLInputElement>
														) => {
															if (
																e.clipboardData.getData("Text").length >= 230
															) {
																let value = e.clipboardData
																	.getData("Text")
																	.slice(0, 230);
																setDescriptionWords(value);
																setWordCount(value.length);
															}
														}}
													/>
												</Box>
											</div>

											<br />
											<br />
											<div>
												<div className={classes.form_section_title}>
													{/*<div className={classes.small_avatar}>*</div>*/}
													<Typography variant="caption">Your niche</Typography>
												</div>
												<Box className={classes.form_section} boxShadow={5}>
													<FormControl fullWidth>
														<Field
															multiple
															name="business_niche"
															disableCloseOnSelect
															component={Autocomplete}
															options={
																ekiojaNichesQuery.status === "success" &&
																ekiojaNichesQuery.data?.values.map(
																	(item: any) => {
																		return item;
																	}
																)
															}
															getOptionLabel={(option: any) => option}
															getOptionSelected={(option: any, value: any) =>
																option === value
															}
															limitTags={8}
															renderOption={(
																option: any,
																{ selected }: any
															) => (
																<React.Fragment>
																	<Checkbox
																		icon={icon}
																		checkedIcon={checkedIcon}
																		style={{ marginRight: 8 }}
																		checked={selected}
																	/>
																	{option}
																</React.Fragment>
															)}
															renderInput={(
																params: AutocompleteRenderInputParams
															) => (
																<MyTextField
																	{...params}
																	style={{ width: "100%" }}
																	type="text"
																/>
															)}
														/>
													</FormControl>
												</Box>
											</div>
											<br />
											<br />

											<div>
												<div className={classes.form_section_title}>
													{/*<div className={classes.small_avatar}>*</div>*/}
													<Typography variant="caption">
														Where can we locate your business?
													</Typography>
												</div>
												<Box className={classes.form_section} boxShadow={5}>
													<Field
														component={TextField}
														name="business_location.street_address_1"
														type="text"
														label="No. Street address"
														fullWidth
														required
													/>
													<br />
													<br />
													<FormControl fullWidth>
														<InputLabel htmlFor="business_location_state">
															State
														</InputLabel>
														<Field
															component={Select}
															name="business_location.state"
															inputProps={{
																id: "business_location_state",
															}}
															value={values.business_location.state}
															onChange={handleChange}
															required
															disabled={
																values.business_location.street_address_1 === ""
																	? true
																	: false
															}
														>
															{NaijaStates.states().map((item: any) => (
																<MenuItem value={item}>{item}</MenuItem>
															))}
														</Field>
													</FormControl>

													<br />
													<br />
													<FormControl fullWidth>
														<InputLabel htmlFor="business_location_lga">
															Local Government Area
														</InputLabel>
														<Field
															component={Select}
															name="business_location.lga"
															inputProps={{
																id: "business_location_lga",
															}}
															required
															disabled={
																values.business_location.state === ""
																	? true
																	: false
															}
														>
															{values.business_location.state !== "" &&
																NaijaStates.lgas(
																	values.business_location.state
																).lgas.map((item: any) => (
																	<MenuItem value={item}>{item}</MenuItem>
																))}
														</Field>
													</FormControl>

													<br />
													<br />
													<Field
														component={TextField}
														name="business_location.postal_code_1"
														type="text"
														label="Zip / Postal code for address"
														fullWidth
														required
													/>
													<br />
													<br />
												</Box>
											</div>

											<br />
											<br />
											<div>
												<div className={classes.form_section_title}>
													{/*<div className={classes.small_avatar}>*</div>*/}
													<Typography variant="caption">
														Social media
													</Typography>
												</div>
												<Box className={classes.form_section} boxShadow={5}>
													<Field
														component={TextField}
														name="social_media.facebook"
														type="text"
														label="Facebook page  link"
														fullWidth
													/>
													<br />
													<br />
													<Field
														component={TextField}
														name="social_media.instagram"
														type="text"
														label="Instagram Account link"
														fullWidth
													/>
													<br />
													<br />

													<Field
														component={TextField}
														name="social_media.whatsappbusiness"
														type="text"
														label="WhatsApp Business Account link"
														fullWidth
													/>
													<br />
													<br />

													<Field
														component={TextField}
														name="social_media.linkedIn"
														type="text"
														label="LinkedIn Account link"
														fullWidth
													/>
													<br />
													<br />

													<Field
														component={TextField}
														name="social_media.twitter"
														type="text"
														label="Twitter Account link"
														fullWidth
													/>
												</Box>
											</div>

											<br />
											<br />

											{isSubmitting && <LinearProgress />}

											<br />
											<br />

											<Button
												variant="contained"
												color="primary"
												disabled={isSubmitting}
												onClick={submitForm}
											>
												{UpdateStoreDataInfo.isLoading && "Updating..."}
												{UpdateStoreDataInfo.isIdle && "Update"}
												{UpdateStoreDataInfo.isSuccess && "Updated!"}
											</Button>
											<br />
											<br />
										</Grid>
										<Grid item xs={12} lg={2}></Grid>
									</Grid>
								</TabPanel>
							</Form>
						</>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default EditStoreDetailsModal;
