export const VENDOR_ADMIN_PROFILE_PHOTOS = 'vendorAdminProfileImages';
export const VENDOR_BANNER_PHOTOS = 'vendorAdminBannerImages';
export const GENERAL_PHOTOS = 'images';
export const STORY_THUMBNAILS = 'storyThumbnails';
export const PRODUCT_STORY_THUMBNAILS = 'productStoryThumbnails';
export const BRAND_STORY_THUMBNAILS = 'brandStoryThumbnails';
export const RAW_PRODUCT_STORY_VIDEOS = 'rawProductStoryVideos';
export const EKIOJA_ADMIN_PROFILE_PHOTOS = 'ekiojaAdminProfileImages';

export const PRODUCT_IMAGES = "productImages";
export const SERVICES_IMAGES = "servicesImages";
export const FBTB_FOUNDER_PHOTO = "fbtbFounderPhoto";
export const MOMENT_FOUNDER_PHOTO = "momentFounderPhoto";
