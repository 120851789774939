import React, { Fragment } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Divider from "@material-ui/core/Divider";

//widgets
// import StoreWidgets from "../components/DashBoardWidgets/StoreWidgets";
// import ProductsWidget from "../components/DashBoardWidgets/ProductsWidget";

import InterestedVendors from "../components/DashBoardWidgets/InterestedVendors";

import LoadingScreen from "../components/LoadingScreen";

//layout
import DashBoardLayout from "../pages/Layouts/DashBoardLayout";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//utils
import {
	// getStoresCounts,
	// getProductCounts,
	getInterestedVendors,
} from "../utils/index";

import { ADMINSTRATOR, MANAGER, SUPER_ADMINSTRATOR } from "../constants/roles";

//routes
import * as ROUTES from "../constants/routes";

import ProductLinksList from "../components/StoreWidgets/superDashboard/ProductLinksList";
import StoresLinksList from "../components/StoreWidgets/superDashboard/StoresLinksList";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		queries_wrapper: {
			width: "50%",
			padding: 10,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
		to_be_vetted_wrapper: {
			display: "flex",
			borderRadius: 20,
			width: "90%",
		},
		business_logo_wrapper: {
			width: 70,
			height: 70,
			borderRadius: 70,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& img": {
				width: 60,
				height: 60,
				borderRadius: 60,
			},
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const DashboardWindow = () => {
	const styles = useStyles();

	const profile = useSelector((state: any) => state.firebase.profile);

	useFirestoreConnect([
		{
			collection: "Stores",
		},
		{
			collection: "Products",
		},
		{
			collection: "InterestedVendors",
		},
	]);

	const stores = useSelector((state: any) => state.firestore.ordered.Stores);
	const products = useSelector(
		(state: any) => state.firestore.ordered.Products
	);
	const interestedVendors = useSelector(
		(state: any) => state.firestore.ordered.InterestedVendors
	);

	if (!isLoaded(stores) && !isLoaded(products) && !isLoaded(interestedVendors))
		return <LoadingScreen />;
	return (
		<DashBoardLayout menuIndex={0}>
			<div className={styles.page_header}>
				<Typography variant="h4" color="primary">
					{" "}
					Ekioja Dashboard
				</Typography>
			</div>
			<br />
			<div className={styles.root}>
				<Container>
					{profile.role === SUPER_ADMINSTRATOR ||
					profile.role === ADMINSTRATOR ||
					profile.role === MANAGER ? (
						<Fragment>
							<br />
							<StoresLinksList />
							<br />
							<ProductLinksList />
							<br />
							<div className={styles.gutter} />
							<Grid container>
								<Grid item xs={12} lg={12}>
									<InterestedVendors
										title="Un-checked Interested Vendors!"
										data={getInterestedVendors(
											interestedVendors,
											ROUTES.INTERESTED_VENDORS_QUERIES
												.UNCHECKED_INTERESTED_VENDORS
										)}
										show_action_link={true}
									/>
								</Grid>
							</Grid>
							<div className={styles.gutter} />
						</Fragment>
					) : (
						<></>
					)}
				</Container>
			</div>
		</DashBoardLayout>
	);
};

export default DashboardWindow;
