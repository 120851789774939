import React, { Fragment } from "react";
//material ui components
import { Avatar, Card, CardContent } from "@material-ui/core";
import AvatarImage from "../assets/images/svg/account_circle-24px.svg";
import UploadImage from "../components/UploadImage/UploadImage";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// import useGetAccountData from "../hooks/profile/useGetAccountData";
import useUpdateAccount from "../hooks/profile/useUpdateAccount";
import useAddImageToAuthProfile from "../hooks/images/useAddImageToAuthProfile";

//react - redux
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import Skeleton from "@material-ui/lab/Skeleton";
import { ToastContainer, toast } from "react-toastify";
//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

import { EKIOJA_ADMIN_PROFILE_PHOTOS } from "../constants/imageFoldersToSave";

import { Field, Form, Formik, ErrorMessage } from "formik";
import { TextField } from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import * as yup from "yup";

const accountValidationSchema = yup.object().shape({
	first_name: yup
		.string()
		.max(35, "its too long")
		.min(2, "its too short")
		.required("Your first name is Required"),
	last_name: yup
		.string()
		.max(35, "its too long")
		.min(2, "its too short")
		.required("Your last name is Required"),
	username: yup
		.string()
		.max(35, "its too long")
		.min(2, "its too short")
		.required("Your user name is Required"),
	contact_phone_number: yup.string().required("Your phone number is Required"),
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
		root: {
			padding: 10,
			position: "relative",
			minHeight: 500,
			"&:hover": {
				boxShadow: theme.shadows[6],
				cursor: "pointer",
			},
		},
		profile_card_wrapper: {
			position: "relative",
			marginTop: 20,
		},
		card: {
			height: 350,
			width: "100%",
			borderRadius: 30,
			position: "absolute",
			top: 50,
		},
		avatar_wrapper: {
			position: "absolute",
			top: 10,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
			zIndex: 200,
		},
		avatar: {
			width: theme.spacing(10),
			height: theme.spacing(10),
		},
		buttonProgress: {
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		upload_button: {
			position: "absolute",
			bottom: -10,
			right: -20,
			width: 50,
			zIndex: 300,
		},
		update_button_wrapper: {
			position: "absolute",
			bottom: 10,
			left: 10,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
		},
	})
);

const Account = () => {
	const styles = useStyles();

	// const currentUser = useSelector((state: any) => state.firebase.profile);
	const profile = useSelector((state: any) => state.firebase.profile);

	const authuid = useSelector((state: any) => state.firebase.auth.uid);
	const authPhotoUrl = useSelector(
		(state: any) => state.firebase.auth.photoURL
	);

	const [UpdateAccountMutate, UpdateAccountInfo] = useUpdateAccount();
	const [AddImageToAuthProfileMutate] = useAddImageToAuthProfile();

	if (!isLoaded(authuid) && !isLoaded(profile)) {
		return <>...Loading</>;
	}

	return (
		<Fragment>
			<DashBoardLayout menuIndex={5}>
				<ToastContainer />
				<div className={styles.page_header}>
					<Typography variant="h4" color="primary">
						{" "}
						Account Settings
					</Typography>
				</div>
				<br />
				<Formik
					validationSchema={accountValidationSchema}
					initialValues={{
						id: authuid,
						first_name: profile.first_name,
						last_name: profile.last_name,
						username: profile.username,
						contact_phone_number: profile.contact_phone_number,
					}}
					onSubmit={(values, { setSubmitting }) => {
						setTimeout(() => {
							UpdateAccountMutate(values, {
								onError: (e: any) => {
									toast.error(`${e.message}`, {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
								},
							});
							setSubmitting(false);
						}, 600);
					}}
				>
					{({ submitForm, isSubmitting, errors, setFieldValue, values }) => (
						<Form>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={4}></Grid>
								<Grid item xs={12} lg={4}>
									<div className={styles.profile_card_wrapper}>
										<div className={styles.avatar_wrapper}>
											{!isLoaded(profile) && (
												<Skeleton
													variant="circle"
													width={80}
													height={80}
													animation="wave"
												/>
											)}
											{isLoaded(profile) && (
												<div style={{ position: "relative" }}>
													<div className={styles.upload_button}>
														<UploadImage
															title="Profile Image"
															existingImageId={authuid}
															folderToSave={EKIOJA_ADMIN_PROFILE_PHOTOS}
															onRequestSaveURL={(url: string) => {
																AddImageToAuthProfileMutate(
																	{ url, uid: authuid },
																	{
																		onSuccess: () => {
																			toast.success(
																				"Your profile image have been successfully updated! Reload page to see changes",
																				{
																					position: "top-right",
																					autoClose: 5000,
																					hideProgressBar: false,
																					closeOnClick: true,
																				}
																			);
																		},
																		onError: (e: any) => {
																			toast.error(`${e.message}`, {
																				position: "top-right",
																				autoClose: 5000,
																				hideProgressBar: false,
																				closeOnClick: true,
																			});
																		},
																	}
																);
															}}
															onRequestSave={(id: string) => {
																// console.log(id);
															}}
															onRequestClear={() => {
																console.log("clear");
															}}
														/>
													</div>

													{isLoaded(authPhotoUrl) && !isEmpty(authPhotoUrl) ? (
														<Avatar
															src={authPhotoUrl}
															className={styles.avatar}
														/>
													) : (
														<Avatar
															src={AvatarImage}
															className={styles.avatar}
														/>
													)}
												</div>
											)}
										</div>

										{!isLoaded(profile) && (
											<Skeleton
												variant="rect"
												width="100%"
												height={400}
												animation="wave"
											/>
										)}
										{isLoaded(profile) && (
											<Card className={styles.card}>
												<CardContent className="pt-10">
													<Field
														component={TextField}
														name="first_name"
														style={{ width: "100%" }}
														label="First name"
														type="text"
														required
													/>
													<br />
													<br />
													<Field
														component={TextField}
														name="last_name"
														style={{ width: "100%" }}
														label="Last name"
														type="text"
														required
													/>
													<br />
													<br />
													<Field
														component={TextField}
														name="username"
														style={{ width: "100%" }}
														label="Username"
														type="text"
														required
													/>
													<br />
													<br />
													<FormControl fullWidth>
														<Field
															component={PhoneInput}
															inputProps={{
																name: "contact_phone_number",
															}}
															country={"ng"}
															enableSearch={true}
															value={values.contact_phone_number}
															onChange={(phone: string) =>
																setFieldValue(
																	"contact_phone_number",
																	phone,
																	true
																)
															}
															inputStyle={{
																width: "100%",
																border: 0,
																borderBottom: `1px solid  ${
																	errors.contact_phone_number ? "red" : "gray"
																}`,
															}}
														/>
													</FormControl>
													<ErrorMessage
														name="contact_phone_number"
														render={(msg) => (
															<span
																style={{ color: "#ff0000", fontSize: "0.7em" }}
															>
																{msg}
															</span>
														)}
													/>

													<div className={styles.update_button_wrapper}>
														<Button
															variant="contained"
															color="primary"
															disabled={
																isSubmitting || UpdateAccountInfo.isLoading
															}
															type="submit"
															onClick={submitForm}
														>
															{UpdateAccountInfo.isLoading
																? "Updating..."
																: UpdateAccountInfo.isError
																? "Error!"
																: UpdateAccountInfo.isSuccess
																? "Updated!"
																: "Update"}
														</Button>
													</div>
												</CardContent>
											</Card>
										)}
									</div>
								</Grid>
								<Grid item xs={12} lg={4}></Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</DashBoardLayout>
		</Fragment>
	);
};

export default Account;
