import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import { Link } from 'react-router-dom';

import * as ROUTES from '../../../constants/routes';

//react - redux
import { useDispatch ,useSelector } from 'react-redux';


import { CheckedInterestedVendor } from '../../../store/actions/interestedvendors/checkedAction';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            borderRadius: 20,
            width: '100%'
        },
        gutter: {
            height: 30,
            width: '100%',
        },
        toolbar_wrapper: {
            // backgroundColor: '#303030',
            backgroundColor: theme.palette.background.default,
        },
        add_new_product_button: {
            marginTop: '10%'
        },

    }),
);

interface Props {
    data: any[],
    title: string;
    show_action_link?: boolean;
    show_action?:boolean;
}

const UncheckedInterestedVendors = ({ data, title, show_action_link ,show_action}: Props) => {
    const styles = useStyles();
    const [pageloading, setpageloading] = useState(true);

    const [openDialog, setOpenDialog] = useState(false);
    const [idToUse, setIdToUse] = useState('');

    const dispatch = useDispatch();

    const authuid = useSelector((state: any) => state.firebase.auth.uid);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setpageloading(false);
        }, 1000);

    }, [])

    return (
        <Box className={styles.root} boxShadow={5}>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        By clicking on confirm it means you have contacted the interested vendor.
                       
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No, Cancel
          </Button>
                    <Button onClick={()=>dispatch(CheckedInterestedVendor(idToUse,authuid))} color="primary" variant='contained' autoFocus>
                       Yes, Confirm
          </Button>
                </DialogActions>
            </Dialog>
            <MaterialTable
                style={{ width: '100%' }}
                isLoading={pageloading}
                title={title}
                columns={[
                    { title: 'Fullname', field: 'fullname' },
                    { title: 'Contact Number', field: 'contact_number' },
                    { title: 'Email', field: 'email' },
                    { title: 'Website', field: 'website' },
                    { title: 'Added on', field: 'added_on', render: rowData => <Typography variant='caption'>{rowData.added_on} <br /> {rowData.createdon} </Typography> },
                    { title: 'createdon', field: 'createdon', hidden: true },


                ]}
                data={data}

                options={{
                    search: false,
                    actionsColumnIndex: -1,
                }}

                actions={[
                    {
                        icon: 'save',
                        tooltip: 'Save User',
                        onClick: (event, rowData: any) => { setIdToUse(rowData.id); handleClickOpen(); }
                    },

                ]}

                components={{

                    Action: props => (
                        <>
                        {
                            show_action && <Button  aria-label="view-vendor-details" size="small" onClick={(event) => props.action.onClick(event, props.data)}>
                            Contacted?
                        </Button>
                        }</>

                    ),

                    Toolbar: props => (
                        <div className={styles.toolbar_wrapper}>
                            <Grid container>
                                <Grid item lg={9}>
                                    <MTableToolbar {...props} />
                                </Grid>
                                <Grid item lg={3}>
                                    {
                                        show_action_link && <Link to={ROUTES.INTERESTED_VENDORS}>
                                            <Button

                                                color="primary"
                                                className={styles.add_new_product_button}

                                            >
                                                VIEW ALL INTERESTED VENDORS
                                </Button>
                                        </Link>
                                    }


                                </Grid>

                            </Grid>

                        </div>
                    )
                }}


            />
        </Box>
    )
}

export default UncheckedInterestedVendors
