import React, { Fragment, useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { green } from "@material-ui/core/colors";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Field, Form, Formik, ErrorMessage } from "formik";
import Radio from "@material-ui/core/Radio";
import { TextField, Select, Checkbox, RadioGroup } from "formik-material-ui";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "formik-material-ui-pickers";
import * as Yup from "yup";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import NumberFormat from "react-number-format";

//lodash
import _ from "lodash";

import "react-nice-dates/build/style.css";

import { ToastContainer, toast } from "react-toastify";

import useGetEkiojaNiches from "../../../hooks/useGetEkiojaNiches";
import useEditProduct from "../../../hooks/Product/useEditProduct";
import useGetProductByPermalink from "../../../hooks/Product/useGetProductByPermalink";
import ProductImagesUploader from "../ProductImagesUploader";
import { PRODUCT_IMAGES } from "../../../constants/imageFoldersToSave";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
		backgroundColor: "transparent",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	root: {
		width: "100%",
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	error_msg: {
		color: "red",
	},
	image_uploader_wrapper: {
		display: "flex",
		width: "90%",
		padding: 10,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	image_uploader: {
		width: "90%",
		height: "auto",
		backgroundColor: "grey",
		borderRadius: 10,
	},
	product_image_uploader_wrapper: {
		width: 350,
		height: 350,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	product_image_uploader: {
		width: 300,
		height: 300,
	},
	product_image_preview: {
		width: "100%",
		height: 200,
		position: "relative",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"& img": {
			width: "100%",
			height: 200,
		},
	},
	remove_image_action_wrapper: {
		position: "absolute",
		top: 10,
		right: 10,
	},
	promote_option_wrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	upload_button: {
		position: "absolute",
		bottom: 10,
		right: 9,
		width: 50,
		zIndex: 500,
	},
}));

const GreenCheckbox = withStyles({
	root: {
		color: green[100],
		"&$checked": {
			color: green[600],
		},
	},
	checked: {},
})((props) => <Field component={Checkbox} color="default" {...props} />);

function NumberFormatCustom(props: any) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			prefix="₦"
		/>
	);
}

//validation schema
const AddNewProductValidationSchema = Yup.object().shape({
	product_name: Yup.string()
		.min(5, "Too short!")
		.required("The product name is required!"),

	product_short_description: Yup.string()
		.min(100, "Too short! a minimum of 100 characters is required")
		.required("The product summary is required!"),

	product_type: Yup.string()
		.oneOf(["simple", "variation"], "You have to choose either yes or no")
		.required("Please answer this question!"),

	asking_price: Yup.number()
		.min(500, "The minimum value is 500 naira")
		.required("Your asking price price is required"),
	discount_percent: Yup.number().test(
		"Enter the discount percent ",
		"Discount percent should not be more than 95% or less than 2%",
		(value: any) => {
			return value > 95 || value < 2 ? false : true;
		}
	),
});

const calculateEkiojaPrice = (value: any) => {
	const fourPercent = Math.floor(parseInt(value) * 0.01);
	const result = Math.floor(parseInt(value) + fourPercent);

	if (isNaN(result)) return 0;
	return result;
};

const convertProductNameToPermalink = (sentence: string) => {
	const result_transform_to_lowercase = _.lowerCase(sentence)
		.split(" ")
		.join("-");
	return result_transform_to_lowercase;
};
const convertProductNameToPlainName = (sentence: string) => {
	const result_transform_to_lowercase = _.lowerCase(sentence)
		.split(" ")
		.join(" ")
		.replace(/\W/g, "");

	return result_transform_to_lowercase;
};

export default function EditProductModal({ productData }: any) {
	const styles = useStyles();

	const [checkPermalink, setCheckPermalink] = useState("qwe");
	const GetProductByPermalinkQuery = useGetProductByPermalink(checkPermalink);
	const [shortDecriptionWordsCount, setShortDecriptionWordsCount] = useState(0);

	const [EditProductMutate, EditProducInfo] = useEditProduct();
	const ekiojaNichesQuery = useGetEkiojaNiches();

	const content = {
		entityMap: {},
		blocks: [
			{
				key: "637gr",
				text: "Product full description.",
				type: "unstyled",
				depth: 0,
				inlineStyleRanges: [],
				entityRanges: [],
				productData: {},
			},
		],
	};
	const contentState = convertFromRaw(content);
	const [productDescription, setProductDescription] = useState({
		editorState: EditorState.createWithContent(contentState),
	});

	useEffect(() => {
		if (productData.product_full_description) {
			const contentState2 = convertFromRaw(
				JSON.parse(productData.product_full_description)
			);

			setProductDescription({
				editorState: EditorState.createWithContent(contentState2),
			});
		}
	}, [productData.product_full_description]);

	return (
		<div>
			<Fragment>
				<ToastContainer />

				<Grid container spacing={2}>
					<Grid item xs={12} lg={2}></Grid>
					<Grid item xs={12} lg={8}>
						{ekiojaNichesQuery.isSuccess && (
							<div className={styles.root}>
								<Formik
									initialValues={{
										added_by: productData.added_by,
										added_by_name: productData.added_by_name,
										product_id: productData.product_id,
										product_name: productData.product_name,
										product_category: productData.product_category,
										product_permalink: productData.product_permalink,
										plainName: productData.plainName,
										product_full_description: "",
										product_short_description:
											productData.product_short_description,
										product_type: productData.product_type,
										asking_price: productData.asking_price,
										regular_price: productData.regular_price,
										discount_price: productData.discount_price,
										discount_percent: productData.discount_percent,
										enable_discount: productData.enable_discount,
										enable_discount_schedule:
											productData.enable_discount_schedule,
										discount_price_from_date:
											productData.discount_price_from_date,
										discount_price_to_date: productData.discount_price_to_date,
										visible_on_market: productData.visible_on_market,
										product_stock_keeping_unit:
											productData.product_stock_keeping_unit,
										enable_stock_management:
											productData.enable_stock_management,
										stock_status: productData.stock_status,
										stock_quantity: productData.stock_quantity,
										low_stock_threshold: productData.low_stock_threshold,
										featured: productData.featured,
										promoted: false,
										promotion_data: {
											from: "",
											to: "",
										},

										image_1: productData.image_1,
										image_1_url: productData.image_1_url,
										image_2: productData.image_2,
										image_2_url: productData.image_2_url,
										image_3: productData.image_3,
										image_3_url: productData.image_3_url,
										image_4: productData.image_4,
										image_4_url: productData.image_4_url,
										negotiable: productData?.negotiable
											? productData?.negotiable
											: false,
										wholesale: productData?.wholesale
											? productData?.wholesale
											: false,
										enable_splash: productData?.enable_splash
											? productData?.enable_splash
											: false,
										enable_splash_duration: productData?.enable_splash_duration
											? productData?.enable_splash_duration
											: false,
										splash_percent: productData?.splash_percent
											? productData?.splash_percent.toString()
											: "30",
										splash_start_date: productData?.splash_start_date
											? moment(productData?.splash_start_date)
											: moment(),
										splash_end_date: productData?.splash_end_date
											? moment(productData?.splash_end_date)
											: moment(),
									}}
									validationSchema={AddNewProductValidationSchema}
									validateOnBlur
									validateOnChange
									onSubmit={(values, { setSubmitting }) => {
										setTimeout(() => {
											setSubmitting(false);
											values.splash_percent = parseInt(values.splash_percent);
											// @ts-ignore
											values.splash_start_date = moment(
												values.splash_start_date
											)
												.format("YYYY-MM-DD")
												.toString();
											// @ts-ignore
											values.splash_end_date = moment(values.splash_end_date)
												.format("YYYY-MM-DD")
												.toString();
											values.product_full_description = JSON.stringify(
												convertToRaw(
													productDescription.editorState.getCurrentContent()
												)
											);

											values.discount_price = _.floor(
												parseInt(values.regular_price) -
													(parseInt(values.discount_percent) / 100) *
														parseInt(values.regular_price)
											);
											EditProductMutate(values, {
												onSuccess: () => {
													toast.success("Product Edited successfully!", {
														position: "top-right",
														autoClose: 5000,
														hideProgressBar: false,
														closeOnClick: true,
													});
												},
												onError: () => {
													toast.error("Oops! An error occured!  Try again", {
														position: "top-right",
														autoClose: 5000,
														hideProgressBar: false,
														closeOnClick: true,
													});
												},
											});
										}, 1000);
									}}
								>
									{({
										submitForm,
										errors,
										touched,
										values,
										setFieldValue,
										isValid,
										isSubmitting,
									}) => (
										<Form>
											<Field
												component={TextField}
												type="text"
												name="product_name"
												fullWidth
												value={values.product_name}
												label="What is the name of the product?"
												onChange={(e: any) => {
													setCheckPermalink(
														convertProductNameToPermalink(e.target.value)
													);
													setFieldValue("product_name", e.target.value, true);
													setFieldValue(
														"product_permalink",
														convertProductNameToPermalink(e.target.value),
														false
													);
													setFieldValue(
														"plainName",
														convertProductNameToPlainName(e.target.value),
														false
													);
												}}
											/>
											{GetProductByPermalinkQuery.isSuccess &&
												GetProductByPermalinkQuery.data.length > 0 && (
													<span style={{ color: "red" }}>
														This product name already exists! Please modify it.
													</span>
												)}
											<br />
											<br />
											<FormControl fullWidth>
												<InputLabel htmlFor="product_category">
													What is the product category?
												</InputLabel>
												<Field
													component={Select}
													name="product_category"
													inputProps={{
														id: "product_category",
													}}
												>
													{ekiojaNichesQuery.isSuccess &&
														// @ts-ignore
														ekiojaNichesQuery?.data.values.map((item: any) => (
															<MenuItem key={item} value={item}>
																{item}
															</MenuItem>
														))}
												</Field>
											</FormControl>
											<ErrorMessage
												name="product_category" // @ts-ignore
												render={(msg) => (
													<Typography
														variant="caption"
														component="span"
														className={styles.error_msg}
													>
														{msg}
													</Typography>
												)}
											/>
											<br />
											<br />
											<Field
												component={TextField}
												name="product_short_description"
												type="text"
												variant="outlined"
												label="Write a summary about the product.(min 30 character)"
												helperText={`${shortDecriptionWordsCount} characters typed | ${
													230 - shortDecriptionWordsCount
												}/230 characters remaining`}
												multiline
												rows={6}
												fullWidth
												value={values.product_short_description}
												onInput={(e: any) => {
													if (e.target.value.length >= 230) {
														let value = e.target.value.slice(0, 230);
														setFieldValue(
															"product_short_description",
															value,
															true
														);

														setShortDecriptionWordsCount(value.length);
													}
												}}
												onChange={(e: any) => {
													if (e.target.value.length >= 230) {
														let value = e.target.value.slice(0, 230);
														setFieldValue(
															"product_short_description",
															value,
															true
														);

														setShortDecriptionWordsCount(value.length);
													} else if (e.target.value.length < 230) {
														setFieldValue(
															"product_short_description",
															e.target.value,
															true
														);

														setShortDecriptionWordsCount(e.target.value.length);
													}
												}}
												onPaste={(e: any) => {
													if (e.clipboardData.getData("Text").length >= 230) {
														let value = e.clipboardData
															.getData("Text")
															.slice(0, 230);
														setFieldValue(
															"product_short_description",
															value,
															true
														);

														setShortDecriptionWordsCount(value.length);
													}
												}}
											/>
											<ErrorMessage
												name="product_short_description"
												render={(msg) => (
													<Typography
														variant="caption"
														component="span"
														className={styles.error_msg}
													>
														{msg}
													</Typography>
												)}
											/>
											<br />
											<br />
											<Typography
												variant="subtitle1"
												style={{ color: "#ccc", fontWeight: "normal" }}
											>
												Write a comprehensive description (Detailed Information)
												about the product.
											</Typography>
											<Editor
												editorState={productDescription.editorState}
												editorStyle={{
													height: "300px",
													border:
														JSON.stringify(
															convertToRaw(
																productDescription.editorState.getCurrentContent()
															)
														).length < 200
															? "2px red solid"
															: "2px green solid",
												}}
												onEditorStateChange={(editorState) => {
													setProductDescription({
														editorState,
													});
												}}
											/>
											{JSON.stringify(
												convertToRaw(
													productDescription.editorState.getCurrentContent()
												)
											).length <= 200 && (
												<Typography
													variant="caption"
													component="span"
													className={styles.error_msg}
												>
													Too short or editor is empty! add more information
												</Typography>
											)}
											<br />
											<br />
											<Field
												component={TextField}
												name="asking_price"
												fullWidth
												type="text"
												label="Your asking Price"
												helperText="Set your asking price for the product."
												InputProps={{
													inputComponent: NumberFormatCustom,
												}}
												onChange={(e: any) => {
													setFieldValue(
														"regular_price",
														calculateEkiojaPrice(e.target.value),
														true
													);
													setFieldValue("asking_price", e.target.value, true);
												}}
												required
											/>
											<ErrorMessage
												name="asking_price"
												render={(msg) => (
													<Typography
														variant="caption"
														component="span"
														className={styles.error_msg}
													>
														{msg}
													</Typography>
												)}
											/>
											<br />
											<Field
												component={TextField}
												name="regular_price"
												fullWidth
												type="text"
												label="Regulated Price"
												disabled
												helperText={
													values.regular_price !== "" &&
													`You will get  ₦${
														values.asking_price
													} on Sale. ₦${Math.floor(
														parseInt(values.asking_price) * 0.01
													)} is  Ekioja's fee (1% of your asking price)`
												}
												InputProps={{
													inputComponent: NumberFormatCustom,
												}}
											/>
											<br />
											<FormControlLabel
												control={
													<GreenCheckbox
														// @ts-ignore
														checked={values.negotiable}
														inputProps={{
															name: "negotiable",
														}}
													/>
												}
												label="is price negotiable?"
											/>
											<br />
											<FormControlLabel
												control={
													<GreenCheckbox
														// @ts-ignore
														checked={values.wholesale}
														inputProps={{
															name: "wholesale",
														}}
													/>
												}
												label="is this product available in wholesale?"
											/>
											<br />
											{touched.asking_price && (
												<FormControlLabel
													control={
														<GreenCheckbox
															// @ts-ignore
															checked={values.enable_discount}
															inputProps={{
																name: "enable_discount",
															}}
														/>
													}
													label="Enable discount percent?"
												/>
											)}
											{values.enable_discount && (
												<>
													<Field
														component={TextField}
														name="discount_percent"
														fullWidth
														type="number"
														label=" Discount Price ? if yes,Set the
                                          discount percent for this product."
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	%
																</InputAdornment>
															),
														}}
														inputProps={{
															min: 2,
															max: 95,
														}}
														value={values.discount_percent}
														onChange={(e: any) => {
															setFieldValue(
																"discount_percent",
																e.target.value,
																true
															);
															setFieldValue(
																"discount_price",
																_.floor(
																	parseInt(values.asking_price) -
																		(parseInt(e.target.value) / 100) *
																			parseInt(values.asking_price)
																),
																true
															);
														}}
													/>
													{!errors.discount_percent &&
														touched.discount_percent &&
														values.discount_percent !== null && (
															<Typography variant="subtitle2" color="inherit">
																<NumberFormat
																	value={values.asking_price}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"₦"}
																/>{" "}
																- {"("}
																{`${values.discount_percent} %`} of{" "}
																<NumberFormat
																	value={values.asking_price}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"₦"}
																/>
																{"  "}
																{")"}
																=={"  "}
																<NumberFormat
																	value={_.floor(
																		parseInt(values.asking_price) -
																			(values.discount_percent / 100) *
																				parseInt(values.asking_price)
																	)}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"₦"}
																/>
															</Typography>
														)}
												</>
											)}
											<br />
											<FormControlLabel
												control={
													<GreenCheckbox
														// @ts-ignore
														checked={values.negotiable}
														inputProps={{
															name: "negotiable",
														}}
													/>
												}
												label="is price negotiable?"
											/>
											<br />
											<FormControlLabel
												control={
													<GreenCheckbox
														// @ts-ignore
														checked={values.wholesale}
														inputProps={{
															name: "wholesale",
														}}
													/>
												}
												label="is this product available in wholesale?"
											/>
											<br />
											<div
											// style={{
											// 	border: "2px solid #000",
											// 	borderRadius: 10,
											// 	padding: 10,
											// }}
											>
												<FormControlLabel
													control={
														<GreenCheckbox
															// @ts-ignore
															checked={values.enable_splash}
															inputProps={{
																name: "enable_splash",
															}}
														/>
													}
													label="Enable Slash Sale for this product?"
												/>
												<br />
												{values.enable_splash === true && (
													<FormControl component="fieldset">
														<FormLabel component="legend">
															Select slash sale percentage
														</FormLabel>
														<Field
															component={RadioGroup}
															name="splash_percent"
															defaultValue={"30"}
															// value={values.splash_percent}
															// onChange={handleChange}
														>
															<FormControlLabel
																value={"30"}
																control={<Radio />}
																label="30%"
																disabled={isSubmitting}
															/>
															<FormControlLabel
																value={"50"}
																control={<Radio />}
																label="50%"
																disabled={isSubmitting}
															/>
															<FormControlLabel
																value={"70"}
																control={<Radio />}
																label="70%"
																disabled={isSubmitting}
															/>
														</Field>
													</FormControl>
												)}
												<br />

												{values.enable_splash === true && (
													<FormControlLabel
														control={
															<GreenCheckbox
																// @ts-ignore
																checked={values.enable_splash_duration}
																inputProps={{
																	name: "enable_splash_duration",
																}}
															/>
														}
														label="Enable slash sale duration for this product?"
													/>
												)}

												{values.enable_splash_duration === true && (
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<Grid container>
															<Grid item xs={6} lg={6}>
																{" "}
																<Field
																	component={DatePicker}
																	label="Start On"
																	name="splash_start_date"
																	variant="inline"
																	disablePast={true}
																/>
															</Grid>
															<Grid item xs={6} lg={6}>
																<Field
																	component={DatePicker}
																	label="End On"
																	name="splash_end_date"
																	variant="inline"
																	disablePast={true}
																/>
															</Grid>
														</Grid>
													</MuiPickersUtilsProvider>
												)}
											</div>

											<br />
											<br />
											<ErrorMessage
												name="image_1"
												render={(msg) => (
													<Typography
														variant="caption"
														component="span"
														className={styles.error_msg}
													>
														{msg}
													</Typography>
												)}
											/>
											<ProductImagesUploader
												existingImageId={`product_${values.product_id}_image`}
												folderToSave={PRODUCT_IMAGES}
												setFieldValue={setFieldValue}
												existingImages={{
													image_1_url: values.image_1_url,
													image_2_url: values.image_2_url,
													image_3_url: values.image_3_url,
													image_4_url: values.image_4_url,
												}}
											/>

											<br />
											<br />
											<div
												style={{
													width: "100%",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<Button
													variant="contained"
													color="primary"
													style={{ color: "#fff" }}
													onClick={submitForm}
													size="large"
													disabled={isSubmitting}
												>
													{EditProducInfo.isLoading
														? "Processing..."
														: EditProducInfo.isError
														? "Error!"
														: EditProducInfo.isSuccess
														? "Done!"
														: "Submit"}
												</Button>
											</div>
										</Form>
									)}
								</Formik>
							</div>
						)}
					</Grid>
					<Grid item xs={12} lg={2}></Grid>
				</Grid>
			</Fragment>
		</div>
	);
}
