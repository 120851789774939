import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CircularProgress, {
	CircularProgressProps,
} from "@material-ui/core/CircularProgress";

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme: Theme) =>
	createStyles({
		top: {
			color: "#1a90ff",
			animationDuration: "550ms",
		},
		circle: {
			strokeLinecap: "round",
		},
	})
);

function FacebookCircularProgress(props: CircularProgressProps) {
	const classes = useStylesFacebook();

	return (
		<CircularProgress
			variant="indeterminate"
			disableShrink
			className={classes.top}
			classes={{
				circle: classes.circle,
			}}
			size={20}
			thickness={4}
			{...props}
		/>
	);
}

export default FacebookCircularProgress;
