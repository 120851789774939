
import { FEEDBACK } from '../../../constants/feedback';

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const DeleteSuggestedCategory = (value: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();
        try {


            firebase.firestore().collection('Ekioja').doc('suggestedNiches').update({
                values: firebase.firestore.FieldValue.arrayRemove(value)
            }).then(() => {
                dispatch({ type: FEEDBACK, payload: { severity: 'success', title: 'Removed!', body: `${value} has been sucessfully removed!` } })
            })


        } catch (error) {
            dispatch({ type: FEEDBACK, payload: { severity: 'error', title: 'Error!!!', body: error.code } })
        }

    }