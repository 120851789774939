import React, { useContext } from "react";
import SearchVendorChatView from "./SearchVendorChatView";
import firebase from "../../../../../../config/firebaseConfig";
import { ChatContext } from "../../../../ChatController";
const db = firebase.firestore();

const loadShopNameOptions = async (inputValue: string) => {
	inputValue = inputValue.toLowerCase().replace(/\W/g, "");
	return new Promise((resolve) => {
		db.collection("Stores")
			.orderBy("shop_plainName")
			.startAt(inputValue)
			.endAt(inputValue + "\uf8ff")
			.get()
			.then((docs) => {
				if (!docs.empty) {
					const shopNames: any[] = [];
					docs.forEach(function (doc) {
						const tag = {
							value: doc.data().unique_id,
							label: doc.data().business_name,
						};
						shopNames.push(tag);
					});
					return resolve(shopNames);
				} else {
					return resolve([]);
				}
			});
	});
};

interface Tag {
	value: string;
	label: string;
}

const SearchVendorChatController = () => {
	const { setCurrentBrandConversation } = useContext(ChatContext);

	const handleOnSearchByBrandChange = (tag: Tag) => {
		setCurrentBrandConversation({ brand_id: tag.value });
	};
	return (
		<div>
			<SearchVendorChatView
				loadOptions={loadShopNameOptions}
				onChange={handleOnSearchByBrandChange}
			/>
		</div>
	);
};

export default SearchVendorChatController;
