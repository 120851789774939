import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
// import { IProducts } from '../../ts/interfaces/product'

const editProduct = (values: any) => {
	return firebase
		.firestore()
		.collection("Products")
		.doc(values.product_id)
		.set(
			{
				...values,
				asking_price: parseInt(values.asking_price),
				regular_price: parseInt(values.regular_price),
			},
			{ merge: true }
		);
};

const useEditProduct = () => {
	return useMutation(editProduct);
};

export default useEditProduct;
