import { useMutation, queryCache } from "react-query";

import firebase from "../../config/firebaseConfig";

const updateAccount = (updatedata: any) => {
	//add displayName
	const updateProfilePromise = firebase.auth().currentUser?.updateProfile({
		displayName: updatedata.first_name,
	});
	//create new doc in Admin collection
	const updateCollectionPromise = firebase
		.firestore()
		.collection("Admins")
		.doc(updatedata.id)
		.set(
			{
				first_name: updatedata.first_name,
				last_name: updatedata.last_name,
				username: updatedata.username,
				// role: updatedata.role,
				contact_phone_number: updatedata.contact_phone_number,
			},
			{ merge: true }
		);

	//allpromise
	const allPromise = Promise.all([
		updateProfilePromise,
		updateCollectionPromise,
	]);

	return allPromise;
};

const useUpdateAccount = () => {
	return useMutation(updateAccount);
	// return useMutation(updateAccount, {
	// 	// When mutate is called:
	// 	onMutate: async (newProfileData) => {
	// 		// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
	// 		await queryCache.cancelQueries("profile");

	// 		// Snapshot the previous value
	// 		const previousProfileData = queryCache.getQueryData("profile");

	// 		// Optimistically update to the new value
	// 		queryCache.setQueryData("profile", (old: any) => [old, newProfileData]);

	// 		// Return a context object with the snapshotted value
	// 		return { previousProfileData };
	// 	},
	// 	// If the mutation fails, use the context returned from onMutate to roll back
	// 	onError: (err, newProfileData, context: any) => {
	// 		queryCache.setQueryData("profile", context?.previousProfileData);
	// 	},
	// 	// Always refetch after error or success:
	// 	onSettled: () => {
	// 		queryCache.invalidateQueries("profile");
	// 	},
	// });
};

export default useUpdateAccount;
