
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const ShowProductOnMarket = (id: string): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();


        return firebase.firestore().collection('Products').doc(id).update({
            visible_on_market:true,

        }).then(() => {
            dispatch({ type: 'FEEDBACK', payload: { severity: 'success', title: 'Your have successfully  made this product visible on the market', body: '' } })
                
            });
           

       

           

    }