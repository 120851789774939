import React, { Fragment, useState } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputLabel from "@material-ui/core/InputLabel";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//params
import { useParams } from "react-router-dom";

//navigation hook
import { useNavigate } from "../hooks/navigate";

//routes
import * as ROUTES from "../constants/routes";
//formik
import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-material-ui";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//number format
// import NumberFormat from "react-number-format";

//react - redux
import { useSelector, useDispatch } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//loading screen
import LoadingScreen from "../components/LoadingScreen";

//get image
import GetImageFromFirebase from "../components/GetImageFromFirebase";

// import moment from "moment";

// import { FEEDBACK } from "../constants/feedback";

import ReactPlayer from "react-player";

import { Assessbrandstory } from "../store/actions/brandstory/assessbrandstory";

import { getAdminForProductStoryCut } from "../utils";

import { CREATIVE, SUPER_ADMINSTRATOR } from "../constants/roles";

import SOUNCLOUDLOGO from "../assets/images/soundcloud.svg";
import STITCHERSLOGO from "../assets/images/stitchers.svg";
import GOOGLEPODCASTLOGO from "../assets/images/google-podcast.svg";
import ANCHORPODCASTLOGO from "../assets/images/anchor_fm_media_social_.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		product_image_preview: {
			width: 500,
			height: 300,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			marginBottom: 20,
			"& img": {
				width: 400,
				height: 250,
			},
		},
		empty_product_image_preview: {
			width: 300,
			height: 300,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "2px gray solid",
			marginBottom: 20,
		},
	})
);

const AssessBrandStory = (props: Props) => {
	const { id } = useParams<any>();

	const { navigateToLink } = useNavigate();
	const styles = useStyles();
	const dispatch = useDispatch();

	const [openStoryTitleNo, setOpenStoryTitleNo] = useState(false);
	const [openStoryDescriptionNo, setOpenStoryDescriptionNo] = useState(false);
	const [openStoryThumbnailNo, setOpenStoryThumbnailNo] = useState(false);
	const [openStoryVideoNo, setOpenStoryVideoNo] = useState(false);

	useFirestoreConnect([
		{
			collection: "BrandStories",
			doc: id,
		},
	]);

	const brandStoriesFirestore = useSelector(
		(state: any) => state.firestore.ordered.BrandStories
	);
	const profile = useSelector((state: any) => state.firebase.profile);
	const authuid = useSelector((state: any) => state.firebase.auth.uid);

	if (!isLoaded(profile)) return <LoadingScreen />;
	if (!isLoaded(authuid)) return <LoadingScreen />;
	if (!isLoaded(brandStoriesFirestore)) return <LoadingScreen />;
	const brandStory = brandStoriesFirestore[0];

	return (
		<Fragment>
			<DashBoardLayout menuIndex={2}>
				<Breadcrumbs aria-label="breadcrumb">
					<ToastContainer />
					{profile.role === SUPER_ADMINSTRATOR && (
						<Button
							onClick={() => {
								navigateToLink.push(ROUTES.BRAND_STORIES, {
									query: ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_REVIEWED,
								});
							}}
						>
							All To Be Assessed Stories
						</Button>
					)}
					{profile.role === CREATIVE && (
						<Button
							onClick={() => {
								navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
									query: ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_REVIEWED,
								});
							}}
						>
							All To Be Assessed Stories
						</Button>
					)}

					<Typography color="textPrimary">{brandStory.title}</Typography>
				</Breadcrumbs>

				<Divider />
				<br />
				<Container>
					<Formik
						initialValues={{
							isBrandStoryNameClear: {
								status: false,
								reason: "",
								touched: false,
							},
							isBrandStoryDescriptionClear: {
								status: false,
								reason: "",
								touched: false,
							},
							isBrandStoryThumbnailClear: {
								status: false,
								reason: "",
								touched: false,
							},
							isBrandStoryVideoClear: {
								status: false,
								reason: "",
								touched: false,
							},

							examined_by_username: profile.username,
							examined_by_id: authuid,
							id: id,
							admincut: getAdminForProductStoryCut(
								brandStory.amountpaidforpromotion
							),
							adminwallet: profile.wallet,
							ekiojawallet: process.env.REACT_APP_EKIOJA_WALLET,

							sound_cloud_podcast_url: "",
							google_podcast_url: "",
							stitcher_podcast_url: "",
							anchor_podcast_url: "",
						}}
						onSubmit={(values, { setSubmitting }) => {
							setTimeout(() => {
								setSubmitting(false);
								if (!values.isBrandStoryNameClear.touched) {
									toast.warning("Brand story title not examined yet!", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									// dispatch({
									//   type: FEEDBACK,
									//   payload: {
									//     severity: "warning",
									//     title: "Brand story title not examined yet!",
									//     body: "You have not assessed the brand story title",
									//   },
									// });
								} else if (
									values.isBrandStoryNameClear.touched &&
									!values.isBrandStoryNameClear.status &&
									values.isBrandStoryNameClear.reason === ""
								) {
									toast.warning("Reason required for brand story title", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									// dispatch({
									//   type: FEEDBACK,
									//   payload: {
									//     severity: "warning",
									//     title: "Reason required for brand story title",
									//     body:
									//       "You have assessed the brand story title but did not choose a reason!",
									//   },
									// });
								} else if (!values.isBrandStoryDescriptionClear.touched) {
									toast.warning("Brand story description not examined yet!", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									// dispatch({
									//   type: FEEDBACK,
									//   payload: {
									//     severity: "warning",
									//     title: "Brand story description not examined yet!",
									//     body: "You have not assessed the brand story description",
									//   },
									// });
								} else if (
									values.isBrandStoryDescriptionClear.touched &&
									!values.isBrandStoryDescriptionClear.status &&
									values.isBrandStoryDescriptionClear.reason === ""
								) {
									toast.warning("Reason required for brand story description", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									// dispatch({
									//   type: FEEDBACK,
									//   payload: {
									//     severity: "warning",
									//     title: "Reason required for brand story description",
									//     body:
									//       "You have assessed the brand story description but did not choose a reason!",
									//   },
									// });
								} else if (!values.isBrandStoryThumbnailClear.touched) {
									toast.warning("Brand story thumbnail not examined yet!", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									// dispatch({
									//   type: FEEDBACK,
									//   payload: {
									//     severity: "warning",
									//     title: "Brand story thumbnail not examined yet!",
									//     body: "You have not assessed the brand story thumbnail",
									//   },
									// });
								} else if (
									values.isBrandStoryThumbnailClear.touched &&
									!values.isBrandStoryThumbnailClear.status &&
									values.isBrandStoryThumbnailClear.reason === ""
								) {
									toast.warning("Reason required for brand story thumbnail", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									// dispatch({
									//   type: FEEDBACK,
									//   payload: {
									//     severity: "warning",
									//     title: "Reason required for brand story thumbnail",
									//     body:
									//       "You have assessed the brand story thumnail but did not choose a reason!",
									//   },
									// });
								} else if (!values.isBrandStoryVideoClear.touched) {
									toast.warning("Brand story video not examined yet!", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
									// dispatch({
									//   type: FEEDBACK,
									//   payload: {
									//     severity: "warning",
									//     title: "Brand story video not examined yet!",
									//     body: "You have not assessed the brand story video",
									//   },
									// });
								} else if (
									values.isBrandStoryVideoClear.touched &&
									!values.isBrandStoryVideoClear.status &&
									values.isBrandStoryVideoClear.reason === ""
								) {
									toast.warning("Reason required for brand story video", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});

									// dispatch({
									//   type: FEEDBACK,
									//   payload: {
									//     severity: "warning",
									//     title: "Reason required for brand story video",
									//     body:
									//       "You have assessed the brand story video but did not choose a reason!",
									//   },
									// });
								} else if (values.sound_cloud_podcast_url === "") {
									toast.warning("Sound Cloud podcast URL is required", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
								} else if (values.google_podcast_url === "") {
									toast.warning("Google podcast URL is required", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
								} else if (values.stitcher_podcast_url === "") {
									toast.warning("Stitcher podcast URL is required", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
								} else if (values.anchor_podcast_url === "") {
									toast.warning("Anchor podcast URL is required", {
										position: "top-right",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
									});
								} else {
									dispatch(Assessbrandstory(values));
								}
							}, 1000);

							setTimeout(() => {
								navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
									query: ROUTES.BRAND_STORIES_QUERIES.ALL_BRAND_STORIES,
								});
							}, 5000);
						}}
					>
						{({ submitForm, isSubmitting, values, setFieldValue, touched }) => (
							<Form>
								<Grid container direction="column" spacing={2}>
									<Grid item xs={12} lg={12}>
										<Card>
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={8}>
														<Typography variant="caption">
															Are you satisfied with the Brand Story Title?{" "}
															<span style={{ color: "red" }}>
																{!values.isBrandStoryNameClear.status &&
																	values.isBrandStoryNameClear.reason !== "" &&
																	"  No," + values.isBrandStoryNameClear.reason}
															</span>{" "}
															<span style={{ color: "green" }}>
																{values.isBrandStoryNameClear.status &&
																	"  Yes , all good!"}
															</span>{" "}
															<span style={{ color: "orange" }}>
																{!values.isBrandStoryNameClear.touched &&
																	"   Waiting for your review"}
															</span>
														</Typography>
														<Typography variant="body2">
															{brandStory.title}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={2}></Grid>
													<Grid item xs={12} lg={2}>
														<Grid
															container
															justify="center"
															alignItems="center"
														>
															<Grid item xs={6} lg={6}>
																<Button
																	variant={
																		values.isBrandStoryNameClear.status
																			? "contained"
																			: "text"
																	}
																	onClick={() => {
																		setOpenStoryTitleNo(false);
																		setFieldValue(
																			"isBrandStoryNameClear.status",
																			true,
																			false
																		);
																		setFieldValue(
																			"isBrandStoryNameClear.reason",
																			"",
																			false
																		);
																		setFieldValue(
																			"isBrandStoryNameClear.touched",
																			true,
																			false
																		);
																	}}
																>
																	Yes
																</Button>
															</Grid>
															<Grid item xs={6} lg={6}>
																<Button
																	onClick={() => {
																		setOpenStoryTitleNo(!openStoryTitleNo);
																		setFieldValue(
																			"isBrandStoryNameClear.status",
																			false,
																			false
																		);
																		setFieldValue(
																			"isBrandStoryNameClear.touched",
																			true,
																			false
																		);
																	}}
																	variant={
																		!values.isBrandStoryNameClear.status &&
																		touched.isBrandStoryNameClear?.reason
																			? "contained"
																			: "text"
																	}
																>
																	No
																</Button>
															</Grid>
														</Grid>
														<Grid container>
															<Grid item xs={12} lg={12}>
																<Collapse
																	in={openStoryTitleNo}
																	timeout="auto"
																	unmountOnExit
																>
																	<FormControl fullWidth>
																		<InputLabel htmlFor="story-name-denied-reason">
																			Reason?
																		</InputLabel>
																		<Field
																			component={Select}
																			name="isBrandStoryNameClear.reason"
																			inputProps={{
																				id: "story-name-denied-reason",
																			}}
																			style={{ width: "100%", paddingLeft: 20 }}
																		>
																			<MenuItem value="Story title too short">
																				Story title too short
																			</MenuItem>
																			<MenuItem value="Story title too long">
																				Story title too long
																			</MenuItem>
																			<MenuItem value="Story title contains inappropriate words">
																				Story title contains inappropriate words
																			</MenuItem>
																			<MenuItem value="Story title is not clear enough">
																				Story title is not clear enough
																			</MenuItem>
																		</Field>
																	</FormControl>
																</Collapse>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
									<Grid item xs={12} lg={12}>
										<Card>
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={8}>
														<Typography variant="caption">
															Are you satisfied with the Brand Story
															Description?{" "}
															<span style={{ color: "red" }}>
																{!values.isBrandStoryDescriptionClear.status &&
																	values.isBrandStoryDescriptionClear.reason !==
																		"" &&
																	"  No," +
																		values.isBrandStoryDescriptionClear.reason}
															</span>{" "}
															<span style={{ color: "green" }}>
																{values.isBrandStoryDescriptionClear.status &&
																	"  Yes , all good!"}
															</span>{" "}
															<span style={{ color: "orange" }}>
																{!values.isBrandStoryDescriptionClear.touched &&
																	"   Waiting for your review"}
															</span>
														</Typography>
														<Typography variant="body2">
															{brandStory.description}
														</Typography>
													</Grid>
													<Grid item xs={12} lg={2}></Grid>
													<Grid item xs={12} lg={2}>
														<Grid
															container
															justify="center"
															alignItems="center"
														>
															<Grid item xs={6} lg={6}>
																<Button
																	variant={
																		values.isBrandStoryDescriptionClear.status
																			? "contained"
																			: "text"
																	}
																	onClick={() => {
																		setOpenStoryDescriptionNo(false);
																		setFieldValue(
																			"isBrandStoryDescriptionClear.status",
																			true,
																			false
																		);
																		setFieldValue(
																			"isBrandStoryDescriptionClear.reason",
																			"",
																			false
																		);
																		setFieldValue(
																			"isBrandStoryDescriptionClear.touched",
																			true,
																			false
																		);
																	}}
																>
																	Yes
																</Button>
															</Grid>
															<Grid item xs={6} lg={6}>
																<Button
																	onClick={() => {
																		setOpenStoryDescriptionNo(
																			!openStoryDescriptionNo
																		);
																		setFieldValue(
																			"isBrandStoryDescriptionClear.status",
																			false,
																			false
																		);
																		setFieldValue(
																			"isBrandStoryDescriptionClear.touched",
																			true,
																			false
																		);
																	}}
																	variant={
																		!values.isBrandStoryDescriptionClear
																			.status &&
																		touched.isBrandStoryDescriptionClear?.reason
																			? "contained"
																			: "text"
																	}
																>
																	No
																</Button>
															</Grid>
														</Grid>
														<Grid container>
															<Grid item xs={12} lg={12}>
																<Collapse
																	in={openStoryDescriptionNo}
																	timeout="auto"
																	unmountOnExit
																>
																	<FormControl fullWidth>
																		<InputLabel htmlFor="story-description-denied-reason">
																			Reason?
																		</InputLabel>
																		<Field
																			component={Select}
																			name="isBrandStoryDescriptionClear.reason"
																			inputProps={{
																				id: "story-description-denied-reason",
																			}}
																			style={{ width: "100%", paddingLeft: 20 }}
																		>
																			<MenuItem value="Story description too short">
																				Story description too short
																			</MenuItem>
																			<MenuItem value="Story description too long">
																				Story description too long
																			</MenuItem>
																			<MenuItem value="Story description contains inappropriate words">
																				Story description contains inappropriate
																				words
																			</MenuItem>
																			<MenuItem value="Story description is not clear enough">
																				Story description is not clear enough
																			</MenuItem>
																		</Field>
																	</FormControl>
																</Collapse>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>

									<Grid item xs={12} lg={12}>
										<Card>
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={8}>
														<Typography variant="caption">
															Are you satisfied with the Brand Story Thumbnail?{" "}
															<span style={{ color: "red" }}>
																{!values.isBrandStoryThumbnailClear.status &&
																	values.isBrandStoryThumbnailClear.reason !==
																		"" &&
																	"  No," +
																		values.isBrandStoryThumbnailClear.reason}
															</span>{" "}
															<span style={{ color: "green" }}>
																{values.isBrandStoryThumbnailClear.status &&
																	"  Yes , all good!"}
															</span>{" "}
															<span style={{ color: "orange" }}>
																{!values.isBrandStoryThumbnailClear.touched &&
																	"   Waiting for your review"}
															</span>
														</Typography>
														<br />
														<div className={styles.product_image_preview}>
															<GetImageFromFirebase
																source={brandStory.thumbnail}
															/>
														</div>
													</Grid>
													<Grid item xs={12} lg={2}></Grid>
													<Grid item xs={12} lg={2}>
														<Grid
															container
															justify="center"
															alignItems="center"
														>
															<Grid item xs={6} lg={6}>
																<Button
																	variant={
																		values.isBrandStoryThumbnailClear.status
																			? "contained"
																			: "text"
																	}
																	onClick={() => {
																		setOpenStoryThumbnailNo(false);
																		setFieldValue(
																			"isBrandStoryThumbnailClear.status",
																			true,
																			false
																		);
																		setFieldValue(
																			"isBrandStoryThumbnailClear.reason",
																			"",
																			false
																		);
																		setFieldValue(
																			"isBrandStoryThumbnailClear.touched",
																			true,
																			false
																		);
																	}}
																>
																	Yes
																</Button>
															</Grid>
															<Grid item xs={6} lg={6}>
																<Button
																	onClick={() => {
																		setOpenStoryThumbnailNo(
																			!openStoryThumbnailNo
																		);
																		setFieldValue(
																			"isBrandStoryThumbnailClear.status",
																			false,
																			false
																		);
																		setFieldValue(
																			"isBrandStoryThumbnailClear.touched",
																			true,
																			false
																		);
																	}}
																	variant={
																		!values.isBrandStoryThumbnailClear.status &&
																		touched.isBrandStoryThumbnailClear?.reason
																			? "contained"
																			: "text"
																	}
																>
																	No
																</Button>
															</Grid>
														</Grid>
														<Grid container>
															<Grid item xs={12} lg={12}>
																<Collapse
																	in={openStoryThumbnailNo}
																	timeout="auto"
																	unmountOnExit
																>
																	<FormControl fullWidth>
																		<InputLabel htmlFor="story-thumbnail-denied-reason">
																			Reason?
																		</InputLabel>
																		<Field
																			component={Select}
																			name="isBrandStoryThumbnailClear.reason"
																			inputProps={{
																				id: "story-description-denied-reason",
																			}}
																			style={{ width: "100%", paddingLeft: 20 }}
																		>
																			<MenuItem value="Story thumbnail is water marked">
																				Story thumbnail is water marked
																			</MenuItem>
																			<MenuItem value="Story thumbnail is blurry">
																				Story thumbnail is blurry
																			</MenuItem>
																			<MenuItem value="Story thumbnail was copied and not original">
																				Story thumbnail was copied and not
																				original
																			</MenuItem>
																			<MenuItem value="Story thumbnail is not appropriate">
																				Story thumbnail is not appropriate
																			</MenuItem>
																		</Field>
																	</FormControl>
																</Collapse>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>

									<Grid item xs={12} lg={12}>
										<Card>
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={8}>
														<Typography variant="caption">
															Are you satisfied with the Brand Story Video?{" "}
															<span style={{ color: "red" }}>
																{!values.isBrandStoryVideoClear.status &&
																	values.isBrandStoryVideoClear.reason !== "" &&
																	"  No," +
																		values.isBrandStoryVideoClear.reason}
															</span>{" "}
															<span style={{ color: "green" }}>
																{values.isBrandStoryVideoClear.status &&
																	"  Yes , all good!"}
															</span>{" "}
															<span style={{ color: "orange" }}>
																{!values.isBrandStoryVideoClear.touched &&
																	"   Waiting for your review"}
															</span>
														</Typography>
														<br />
														<br />
														<div>
															<ReactPlayer
																url={brandStory.video}
																playing={false}
																controls={true}
																width="100%"
																height={400}
																pip={true}
															/>
														</div>
													</Grid>
													<Grid item xs={12} lg={2}></Grid>
													<Grid item xs={12} lg={2}>
														<Grid
															container
															justify="center"
															alignItems="center"
														>
															<Grid item xs={6} lg={6}>
																<Button
																	variant={
																		values.isBrandStoryVideoClear.status
																			? "contained"
																			: "text"
																	}
																	onClick={() => {
																		setOpenStoryVideoNo(false);
																		setFieldValue(
																			"isBrandStoryVideoClear.status",
																			true,
																			false
																		);
																		setFieldValue(
																			"isBrandStoryVideoClear.reason",
																			"",
																			false
																		);
																		setFieldValue(
																			"isBrandStoryVideoClear.touched",
																			true,
																			false
																		);
																	}}
																>
																	Yes
																</Button>
															</Grid>
															<Grid item xs={6} lg={6}>
																<Button
																	onClick={() => {
																		setOpenStoryVideoNo(!openStoryVideoNo);
																		setFieldValue(
																			"isBrandStoryVideoClear.status",
																			false,
																			false
																		);
																		setFieldValue(
																			"isBrandStoryVideoClear.touched",
																			true,
																			false
																		);
																	}}
																	variant={
																		!values.isBrandStoryVideoClear.status &&
																		touched.isBrandStoryVideoClear?.reason
																			? "contained"
																			: "text"
																	}
																>
																	No
																</Button>
															</Grid>
														</Grid>
														<Grid container>
															<Grid item xs={12} lg={12}>
																<Collapse
																	in={openStoryVideoNo}
																	timeout="auto"
																	unmountOnExit
																>
																	<FormControl fullWidth>
																		<InputLabel htmlFor="story-video-denied-reason">
																			Reason?
																		</InputLabel>
																		<Field
																			component={Select}
																			name="isBrandStoryVideoClear.reason"
																			inputProps={{
																				id: "story-video-denied-reason",
																			}}
																			style={{ width: "100%", paddingLeft: 20 }}
																		>
																			<MenuItem value="Story video is water marked">
																				Story video is water marked
																			</MenuItem>
																			<MenuItem value="Story video is blurry">
																				Story video is blurry
																			</MenuItem>
																			<MenuItem value="Story video was copied and not original">
																				Story video was copied and not original
																			</MenuItem>
																			<MenuItem value="Story video is not appropriate">
																				Story video is not appropriate
																			</MenuItem>
																			<MenuItem value="Story video is too short">
																				Story video is too short
																			</MenuItem>
																			<MenuItem value="Story video is too long">
																				Story video is too long
																			</MenuItem>
																		</Field>
																	</FormControl>
																</Collapse>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>

									<Grid item xs={12} lg={12}>
										<Card>
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={2}>
														<img
															src={SOUNCLOUDLOGO}
															alt="sound  cloud"
															style={{ width: 100 }}
														/>
													</Grid>
													<Grid item xs={12} lg={10}>
														<Field
															component={TextField}
															name="sound_cloud_podcast_url"
															fullWidth
															placeholder="Enter Sound Cloud Podcast URL"
															required
														/>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
									<Grid item xs={12} lg={12}>
										<Card>
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={2}>
														<img
															src={GOOGLEPODCASTLOGO}
															alt="google podcast"
															style={{ width: 100 }}
														/>
													</Grid>
													<Grid item xs={12} lg={10}>
														<Field
															component={TextField}
															name="google_podcast_url"
															fullWidth
															placeholder="Enter Google Podcast URL"
															required
														/>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
									<Grid item xs={12} lg={12}>
										<Card>
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={2}>
														<img
															src={STITCHERSLOGO}
															alt="stitchers"
															style={{ width: 100 }}
														/>
													</Grid>
													<Grid item xs={12} lg={10}>
														<Field
															component={TextField}
															name="stitcher_podcast_url"
															fullWidth
															placeholder="Enter Stitcher Podcast URL"
															required
														/>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
									<Grid item xs={12} lg={12}>
										<Card>
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={2}>
														<img
															src={ANCHORPODCASTLOGO}
															alt="Anchor  podcast"
															style={{ width: 100 }}
														/>
													</Grid>
													<Grid item xs={12} lg={10}>
														<Field
															component={TextField}
															name="anchor_podcast_url"
															fullWidth
															placeholder="Enter Anchor Podcast URL"
															required
														/>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>

									<Grid item xs={12} lg={12}>
										{isSubmitting && <LinearProgress />}
										<Card>
											<CardContent>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={12}>
														{/* <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={values.enable_automated_add_product}
                                  inputProps={{
                                    name: "enable_automated_add_product",
                                  }}
                                />
                              }
                              label="Allow products from this store to automatically show on the marketplace.?"
                            /> */}
														<Typography variant="caption">
															{" "}
															Confirm that you have properly assessed this
															story.
														</Typography>
														<Button
															variant="contained"
															color="primary"
															style={{ color: "#fff" }}
															disabled={isSubmitting}
															onClick={submitForm}
														>
															Done
														</Button>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</Container>
			</DashBoardLayout>
		</Fragment>
	);
};

export default AssessBrandStory;
