import React, { Fragment, useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import LogisticsWidget from "../components/DashBoardWidgets/LogisticsWidget";

import LoadingScreen from "../components/LoadingScreen";

import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Chip from "@material-ui/core/Chip";

import _ from "lodash";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//utils
import {
	getBookingCounts,
	getLogisticsUpcomingRevenueTimeFrame,
	getLogisticsPendingClearanceRevenueTimeFrame,
	getLogisticsEarnedRevenueFailedTimeFrame,
} from "../utils/index";

import { LOGISTICS } from "../constants/roles";

//number format
import NumberFormat from "react-number-format";

//routes
import * as ROUTES from "../constants/routes";

//navigation hook
import { useNavigate } from "../hooks/navigate";

import useGetLogisticsComStatus from "../hooks/logistics/useGetLogisticsComStatus";
import useAcceptAssignBooking from "../hooks/logistics/useAcceptAssignBooking";

import AssignDispatcherModal from "../components/Logistics/AssignDispatcherModal";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		queries_wrapper: {
			width: "50%",
			padding: 10,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
		to_be_vetted_wrapper: {
			display: "flex",
			borderRadius: 20,
			width: "90%",
		},
		business_logo_wrapper: {
			width: 70,
			height: 70,
			borderRadius: 70,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& img": {
				width: 60,
				height: 60,
				borderRadius: 60,
			},
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
		appBar: {
			position: "relative",
			backgroundColor: "transparent",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		dispatchers_list_wrapper: {
			width: "100%",
			backgroundColor: "#161623 ",
			padding: 12,
			overflowY: "auto",
			borderRadius: 10,
			height: 600,
		},
		dispatcher_item_wrapper: {
			width: "100%",
			minHeight: 200,
			paddingBottom: 6,
			borderRadius: 10,
			backgroundColor: "rgba(255,255,225,0.2)",
			borderTop: "1px solid rgba(255,255,225,0.6) ",
			borderLeft: "1px solid rgba(255,255,225,0.6) ",
			zIndex: 10,
			marginBottom: 15,
		},
		dispatcher_avatar_wrapper: {
			width: "100%",
			height: 150,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		dispatcher_avatar: {
			width: 100,
			height: 100,
		},
		dispatcher_name: {
			fontWeight: "bolder",
			fontSize: "1em",
			textAlign: "center",
			margin: 0,
		},
		chip_wrapper: {
			"& h5": {
				fontWeight: "bold",
				textAlign: "center",
				// margin: 0,
			},
			"& >div": {
				marginBottom: 14,
			},
			// borderRight: "1px solid rgba(255,255,225,0.6) ",
			padding: 2,
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "space-between",
			flexDirection: "column",
		},
		today_stats_inner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "0px 7px",
			backgroundColor: "rgba(255,255,225,0.2)",
			borderRadius: 10,
			marginRight: 5,
			"& > div": {
				width: "100%",
				display: "flex",
				justifyContent: "flex-start",
				alignItems: "space-between",
				flexDirection: "column",
			},
			"& > div >div": {
				marginBottom: 5,
				marginTop: 5,
			},
		},
		warning_wrapper: {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
			padding: 7,
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const LogisticsDashboard = () => {
	const styles = useStyles();

	const { navigateToLink } = useNavigate();

	const profile = useSelector((state: any) => state.firebase.profile);

	const authuid = useSelector((state: any) => state.firebase.auth.uid);

	const [orderTimelineId, setorderTimelineId] = useState("");

	const [toBeShippedData, setToBeShippedData] = useState<any>([]);

	const [bookingData, setBookingData] = useState<any>([]);

	const [balance, setBalance] = useState(0);

	const [waitingToBeShipped, setWaitingToBeShipped] = useState<any>([]);
	const [outForDelivery, setOutForDelivery] = useState<any>([]);
	const [delivered, setDelivered] = useState<any>([]);

	const [
		AcceptAssignBookingMutate,
		AcceptAssignBookingInfo,
	] = useAcceptAssignBooking();

	const GetLogisticsComStatus = useGetLogisticsComStatus(
		isLoaded(profile) ? profile.entity : "0020"
	);

	let status = GetLogisticsComStatus.isSuccess
		? GetLogisticsComStatus.data
		: "e";

	const [
		openConfirmForBookingDialog,
		setOpenConfirmForBookingDialog,
	] = useState(false);
	const [
		ekiojaLogisticsOpenConfirmForBookingDialog,
		setekiojaLogisticsOpenConfirmForBookingDialog,
	] = useState(false);

	useFirestoreConnect([
		{
			collection: "Stores",
		},
		{
			collection: "Products",
		},
		{
			collection: "Orders",
		},
		{
			collection: "OrdersTimeline",
		},
		{
			collection: "Wallets",
			where: [["owner_id", "==", isLoaded(profile) ? profile.entity : "0020"]],
			storeAs: "Wallets",
		},
	]);

	const stores = useSelector((state: any) => state.firestore.ordered.Stores);
	const products = useSelector(
		(state: any) => state.firestore.ordered.Products
	);

	const orders = useSelector((state: any) => state.firestore.ordered.Orders);
	const ordersTimelime = useSelector(
		(state: any) => state.firestore.ordered.OrdersTimeline
	);

	const wallet = useSelector((state: any) => state.firestore.data["Wallets"]);

	const handleClickConfirmForBookingOpen = () => {
		setOpenConfirmForBookingDialog(true);
	};
	const handleConfirmForBookingClose = () => {
		setOpenConfirmForBookingDialog(false);
	};
	const handleClickConfirmForEkiojaLogisticsBookingOpen = () => {
		setekiojaLogisticsOpenConfirmForBookingDialog(true);
	};
	const handleConfirmForEkiojaLogisticsBookingClose = () => {
		setekiojaLogisticsOpenConfirmForBookingDialog(false);
	};

	useEffect(() => {
		if (isLoaded(wallet)) {
			let wallet_data: any[] = [];
			if (wallet !== null) {
				for (var walletkey in wallet) {
					wallet_data.push({
						amount: wallet[walletkey].income,
					});
				}
			}

			if (_.has(wallet_data[0], "amount")) {
				setBalance(wallet_data[0].amount);
			}
		}
	}, [wallet]);

	useEffect(() => {
		if (isLoaded(orders) && isLoaded(ordersTimelime) && isLoaded(authuid)) {
			//re arrange order data
			let order_data: any[] = [];
			if (orders !== null) {
				for (var orderkey in orders) {
					order_data.push({
						...orders[orderkey],
						order_uuid: orders[orderkey].id,
					});
				}
			}

			const mapOrderIdToitems = _.map(order_data, (item) => {
				return {
					...item,
					items: _.map(item.items, (elems) => {
						return {
							...elems,
							order_id: item.order_id,
							delivery_address: `${item.delivery_address}, LGA:${item.delivery_lga}, WARD:${item.delivery_ward}, ${item.delivery_state} State`,
							delivery_lga: item.delivery_lga,
							delivery_ward: item.delivery_ward,
							delivery_state: item.delivery_state,
						};
					}),
				};
			});

			//re arrange order timeline data
			let orderTimeline_data: any[] = [];
			if (ordersTimelime !== null) {
				for (var orderTimelinekey in ordersTimelime) {
					orderTimeline_data.push({
						...ordersTimelime[orderTimelinekey],
						orderTimelinekey_uuid: ordersTimelime[orderTimelinekey].id,
					});
				}
			}

			//merge order and ordertimeline data
			const all_items_in_orders_and_ordertimeline = _.flattenDeep(
				_.map(mapOrderIdToitems, (item) => {
					return [
						..._.intersectionWith(
							_.cloneDeep(item.items),
							orderTimeline_data,
							function (x: any, y: any) {
								return (
									x.order_id === y.the_order_id &&
									x.product_id === y.order_product_id &&
									_.assign(x, y)
								);
							}
						),
					];
				})
			);

			//get waiting to be shipped data
			// const get_confirmed_items_for_shipping = _.filter(
			//   all_items_in_orders_and_ordertimeline,
			//   (item) =>
			//     _.has(item.waiting_to_be_shipped, "current") &&
			//     item.waiting_to_be_shipped.current === true
			// );

			//get essential data from store
			const storeData = _.map(stores, (item) => {
				return {
					store_name: item.business_name,
					pick_address: `${item.business_location.street_address_1}, LGA:${item.business_location?.lga}, WARD:${item.business_location?.ward}, ${item.business_location?.state} State`,
					store_id: item.id,
				};
			});

			//merge store to items
			// const store_items_data = _.intersectionWith(
			//   _.cloneDeep(storeData),
			//   all_items_in_orders_and_ordertimeline,
			//   function (x: any, y: any) {
			//     // return x.store_id === y.entity && _.assign(x, y);
			//     return x.store_id === y.entity && _.map(_.clone(y)) && _.map(_.clone(x)) ;
			//   }
			// );
			const store_items_data = [];
			for (var storekey in storeData) {
				for (var ookey in all_items_in_orders_and_ordertimeline) {
					if (
						storeData[storekey].store_id ===
						all_items_in_orders_and_ordertimeline[ookey].entity
					) {
						store_items_data.push({
							...storeData[storekey],
							...all_items_in_orders_and_ordertimeline[ookey],
						});
					}
				}
			}

			//get essential data from products
			const productData = _.map(products, (item) => {
				return {
					dimension_unit: item.dimension_unit,
					dimension_length: item.dimension_length,
					dimension_height: item.dimension_height,
					dimension_breadth: item.dimension_breadth,
					weight_unit: item.weight_unit,
					weight: item.weight,
					product_id: item.product_id,
				};
			});

			//merge products to items and store
			// const products_store_items_data = _.intersectionWith(
			//   _.cloneDeep(productData),
			//   store_items_data,
			//   function (x: any, y: any) {
			//     return x.product_id === y.product_id && _.assign(x, y);
			//   }
			// );
			const products_store_items_data = [];
			for (var productkey in productData) {
				for (var stkey in store_items_data) {
					if (
						productData[productkey].product_id ===
						store_items_data[stkey].product_id
					) {
						products_store_items_data.push({
							...productData[productkey],
							...store_items_data[stkey],
						});
					}
				}
			}

			//get logistics bookings
			const logistics_bookings = _.filter(
				products_store_items_data,
				(item) =>
					_.has(item, "logistics") &&
					item.logistics.entity === profile.entity &&
					item.booked === true
			);

			setBookingData([...logistics_bookings]);

			//get items with booking == false
			const noBooking = _.filter(
				products_store_items_data,
				(item) =>
					item.booked === false &&
					_.has(item.waiting_to_be_shipped, "current") &&
					item.waiting_to_be_shipped.current === true
			);

			//get table data
			const newTobeShippedData = _.map(noBooking, (item) => {
				return {
					store_name: item.store_name,
					pick_address: `${item.pick_address}`,
					dimensions: `${item.dimension_length} ${item.dimension_unit} X ${item.dimension_breadth} ${item.dimension_unit}  X ${item.dimension_height} ${item.dimension_unit} `,
					weight: `${item.weight} ${item.weight_unit} `,
					delivery_address: `${item.delivery_address}`,
					quantity: item.quantity,
					orderTimeline_uuid: item.orderTimelinekey_uuid,
					shipping_type: _.has(item, "shipping_type")
						? item.shipping_type
						: "local",
				};
			});

			setToBeShippedData([...newTobeShippedData]);

			//get waiting to be shipped
			const waiting_to_be_shipped = _.filter(
				logistics_bookings,
				(item) =>
					_.has(item, "waiting_to_be_shipped") &&
					typeof item.waiting_to_be_shipped.current !== "undefined" &&
					item.waiting_to_be_shipped.current === true
			);
			setWaitingToBeShipped([...waiting_to_be_shipped]);

			//get out for delivery
			const out_for_delivery = _.filter(
				logistics_bookings,
				(item) =>
					_.has(item, "out_for_delivery") &&
					typeof item.out_for_delivery.current !== "undefined" &&
					item.out_for_delivery.current === true
			);
			setOutForDelivery([...out_for_delivery]);

			//get delivered
			const delivered = _.filter(
				logistics_bookings,
				(item) =>
					_.has(item, "delivered") &&
					typeof item.delivered.current !== "undefined" &&
					item.delivered.current === true
			);
			setDelivered([...delivered]);
		}
	}, [orders, ordersTimelime, stores, products, authuid, profile.entity]);

	if (
		!isLoaded(stores) &&
		!isLoaded(products) &&
		!isLoaded(ordersTimelime) &&
		!isLoaded(wallet)
	)
		return <LoadingScreen />;
	return (
		<DashBoardLayout menuIndex={0}>
			<div className={styles.page_header}>
				<Typography variant="h4" color="primary">
					{" "}
					Logistics Dashboard
				</Typography>
			</div>
			<br />

			<Dialog
				fullScreen
				open={openConfirmForBookingDialog}
				onClose={handleConfirmForBookingClose}
				TransitionComponent={Transition}
				id="assign-booking-dialog"
			>
				<AppBar className={styles.appBar} elevation={0}>
					<Toolbar>
						<IconButton
							edge="start"
							color="primary"
							onClick={handleConfirmForBookingClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={styles.title} color="primary">
							Assign booking to dispatcher
						</Typography>
					</Toolbar>
				</AppBar>
				<AssignDispatcherModal
					entity={profile.entity}
					orderTimelineId={orderTimelineId}
					authuid={authuid}
					close={handleConfirmForBookingClose}
				/>
			</Dialog>

			<Dialog
				open={ekiojaLogisticsOpenConfirmForBookingDialog}
				onClose={handleConfirmForEkiojaLogisticsBookingClose}
				aria-labelledby="confirm-booking-alert-dialog-title"
				aria-describedby="confirm-booking-alert-dialog-description"
			>
				<DialogTitle id="confirm-booking-alert-dialog-title">
					{"Are you  sure?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="confirm-booking-alert-dialog-description">
						By confirming it means you will handle delivery of this item.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							handleConfirmForEkiojaLogisticsBookingClose();
							AcceptAssignBookingInfo.reset();
						}}
						color="primary"
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							if (window.confirm("Are you sure ?")) {
								AcceptAssignBookingMutate(
									{
										logistics_uuid: authuid,
										name: `${profile.first_name} ${profile.last_name}`,
										entity: profile.entity,
										manager_uuid: authuid,
										orderTimelineId: orderTimelineId,
										dispatcher_email: profile.email,
										dispatcher_phonenumber: profile.contact_phone_number,
									},
									{
										onSuccess: () => {
											setTimeout(() => {
												handleConfirmForEkiojaLogisticsBookingClose();
												AcceptAssignBookingInfo.reset();
											}, 2000);
										},
										onError: (err) => {
											console.log(err);
										},
									}
								);
							}
						}}
						color="primary"
						autoFocus
						variant="outlined"
					>
						{AcceptAssignBookingInfo.isLoading
							? "Processing..."
							: AcceptAssignBookingInfo.isError
							? "Error!"
							: AcceptAssignBookingInfo.isSuccess
							? "Done!"
							: "Confirm"}
					</Button>
				</DialogActions>
			</Dialog>
			<div className={styles.root}>
				<Container>
					{status !== "D" && (
						<div
							style={{
								width: "100%",
								height: 400,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								fontSize: 30,
							}}
						>
							Your cannot accept bookings now!. This maybe because your company
							is yet to be approved, have been blocked or not activated.
						</div>
					)}
					{profile.role === LOGISTICS && status === "D" && (
						<Fragment>
							<Grid container>
								<Grid item xs={12} lg={12}>
									<Box boxShadow={5}>
										<MaterialTable
											style={{ width: "100%" }}
											title="Parcels waiting to be shipped"
											columns={[
												{
													title: "Store name",
													field: "store_name",
													align: "center",
												},
												{
													title: "Shipping",
													field: "shipping_type",
													align: "center",
													render: (rowData) => (
														<>
															{rowData.shipping_type === "local" ? (
																<Chip
																	icon={<LocalShippingIcon />}
																	label="Local"
																/>
															) : (
																<Chip
																	icon={<LocalAirportIcon />}
																	label="International"
																/>
															)}
														</>
													),
													cellStyle: {
														width: 100,
													},
												},
												{
													title: "Product Dimension",
													field: "dimensions",
													render: (rowData) => (
														<Typography variant="caption" align="center">
															{rowData.dimensions}
														</Typography>
													),
													cellStyle: {
														width: 300,
													},

													align: "center",
												},
												{
													title: "Product Weight",
													field: "weight",
													align: "center",
													cellStyle: {
														width: 50,
													},
												},
												{
													title: "Quantity",
													field: "quantity",
													type: "numeric",
													cellStyle: {
														width: 50,
													},
												},
												{
													title: "Pick Up address",
													field: "pick_address",
													align: "center",
													render: (rowData) => (
														<Typography variant="caption" align="center">
															{rowData.pick_address}
														</Typography>
													),
													cellStyle: {
														width: 400,
													},
												},
												{
													title: "Delivery address",
													field: "delivery_address",
													align: "center",
													render: (rowData) => (
														<Typography variant="caption" align="center">
															{rowData.delivery_address}
														</Typography>
													),
													cellStyle: {
														width: 400,
													},
												},
												{
													title: "orderTimeline_uuid",
													field: "orderTimeline_uuid",
													hidden: true,
												},
											]}
											data={toBeShippedData}
											options={{
												search: false,
												actionsColumnIndex: -1,
											}}
											actions={[
												{
													icon: "save",
													tooltip: "Save User",
													onClick: (event, rowData: any) => {
														setorderTimelineId(rowData.orderTimeline_uuid);

														if (_.has(profile, "super-entity")) {
															handleClickConfirmForEkiojaLogisticsBookingOpen();
														} else {
															handleClickConfirmForBookingOpen();
														}
													},
												},
											]}
											components={{
												Action: (props) => (
													<Button
														variant="contained"
														color="secondary"
														size="medium"
														onClick={(event) =>
															props.action.onClick(event, props.data)
														}
													>
														ACCEPT
													</Button>
												),
											}}
										/>
									</Box>
								</Grid>
							</Grid>
							<br />
							<br />
							<Typography variant="h6">Bookings quick links</Typography>
							<Divider />
							<br />
							<Grid container spacing={2}>
								<Grid item xs={12} lg={6}>
									<Grid container spacing={2}>
										<Grid item xs={12} lg={12}>
											<LogisticsWidget
												count={getBookingCounts(
													bookingData,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS
												)}
												title="All Bookings"
												routeQuery={
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS
												}
											/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<LogisticsWidget
												count={getBookingCounts(
													bookingData,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES
														.ALL_WAITING_TO_BE_SHIPPED
												)}
												title="All Waiting to be shipped"
												routeQuery={
													ROUTES.LOGISTICS_BOOKINGS_QUERIES
														.ALL_WAITING_TO_BE_SHIPPED
												}
											/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<LogisticsWidget
												count={getBookingCounts(
													bookingData,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_OUT_FOR_DELIVERY
												)}
												title="All Out for delivery"
												routeQuery={
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_OUT_FOR_DELIVERY
												}
											/>
										</Grid>
										{/* <Grid item xs={12} lg={6}>
                      <LegalWidget
                        count={getVetDocsCounts(
                          vetDocsData,
                          ROUTES.VET_DOC_QUERIES.ALL_DRAFT
                        )}
                        title="All Draft"
                        routeQuery={ROUTES.VET_DOC_QUERIES.ALL_DRAFT}
                      />
                    </Grid> */}
										<Grid item xs={12} lg={6}>
											<LogisticsWidget
												count={getBookingCounts(
													bookingData,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_DELIVERED
												)}
												title="All Delivered "
												routeQuery={
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_DELIVERED
												}
											/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<LogisticsWidget
												count={getBookingCounts(
													bookingData,
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_CANCELLED
												)}
												title="All Cancelled "
												routeQuery={
													ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_CANCELLED
												}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} lg={6}>
									{!_.has(profile, "super-entity") && (
										<Box
											boxShadow={6}
											style={{
												minHeight: 105,
												borderRadius: 10,
												width: "90%",
											}}
										>
											<List
												subheader={
													<ListSubheader component="div">
														Today's wallet data
													</ListSubheader>
												}
											>
												<ListItem>
													<ListItemText
														primary="Upcoming earnings"
														secondary={
															<Fragment>
																<NumberFormat
																	value={getLogisticsUpcomingRevenueTimeFrame(
																		waitingToBeShipped,
																		"today"
																	)}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"₦"}
																/>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />
												<ListItem>
													<ListItemText
														primary="Pending Clearance"
														secondary={
															<Fragment>
																<NumberFormat
																	value={getLogisticsPendingClearanceRevenueTimeFrame(
																		outForDelivery,
																		"today"
																	)}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"₦"}
																/>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />
												<ListItem>
													<ListItemText
														primary="Earned"
														secondary={
															<Fragment>
																<NumberFormat
																	value={getLogisticsEarnedRevenueFailedTimeFrame(
																		delivered,
																		"today"
																	)}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"₦"}
																/>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />

												<ListItem>
													<ListItemText
														primary="Available funds"
														secondary={
															<Fragment>
																<NumberFormat
																	value={balance}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"₦"}
																/>
															</Fragment>
														}
													/>
													<ListItemSecondaryAction>
														<Button
															onClick={() =>
																navigateToLink.push(ROUTES.LOGISTICS_WALLET)
															}
														>
															View wallet
														</Button>
													</ListItemSecondaryAction>
												</ListItem>
												<Divider />
											</List>
										</Box>
									)}
								</Grid>
							</Grid>
						</Fragment>
					)}
				</Container>
			</div>
		</DashBoardLayout>
	);
};

export default LogisticsDashboard;
