import React, { useState } from "react";
import AsyncSelect from "react-select/async";

import firebase from "../config/firebaseConfig";

import Grid from "@material-ui/core/Grid";

const db = firebase.firestore();

  const loadOptions = async (inputValue: string) => {
    inputValue = inputValue.toLowerCase().replace(/\W/g, "");
    return new Promise((resolve) => {
      db.collection("Products")
        .orderBy("plainName")
        .startAt(inputValue)
        .endAt(inputValue + "\uf8ff")
        .get()
        .then((docs) => {
          if (!docs.empty) {
            let recommendedProducts: any[] = [];
            docs.forEach(function (doc) {
              const tag = {
                value: doc.id,
                label: doc.data().product_name,
              };
              recommendedProducts.push(tag);
            });
            return resolve(recommendedProducts);
          } else {
            return resolve([]);
          }
        });
    });
  };

const SearchBarTest = () => {
  const [selectedTag, setSelectedTag] = useState<any>([]);


  const handleOnChange = (tag: any) => {
    setSelectedTag((prev: any) => [prev, ...tag]);
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={4}></Grid>
        <Grid item xs={12} lg={4}>
          <AsyncSelect loadOptions={loadOptions} onChange={handleOnChange} />
          {selectedTag.length > 0 && <p>Selected Tag:</p>}

          {selectedTag.map((e: any) => {
            return <li key={e.value}>{e.label}</li>;
          })}
        </Grid>
        <Grid item xs={12} lg={4}></Grid>
      </Grid>
    </div>
  );
};

export default SearchBarTest;
