import { useMutation } from "react-query";
import firebase from "../../../config/firebaseConfig";
import { queryCache } from "react-query";

const approveLogisticsCompany = ({
	id,
	status,
}: {
	id: string;
	status: boolean;
}) => {
	return firebase.firestore().collection("LogisticsCompanies").doc(id).update({
		approved: status,
	});
};

const useApproveLogisticsCompany = () => {
	return useMutation(approveLogisticsCompany, {
		// // Always refetch after error or success:
		onSettled: () => {
			queryCache.invalidateQueries("allLogisticsCompanies");
			queryCache.invalidateQueries("SingleLogisticsCompany");
		},
	});
};

export default useApproveLogisticsCompany;
