import React from "react";
import { Conversation } from "../../../../../ConservationList/ConservationItem";
import OrderController from "./OrderController";

interface Props {
	data: Conversation;
}
const Order = (props: Props) => {
	return <OrderController data={props.data} />;
};

export default Order;
