  
import { useMutation } from "react-query";
import firebase from '../config/firebaseConfig';

// import { ICreateStoreValues } from '../ts/interfaces/createStore';

const updateStoreData = async (values:any)=>{

    return firebase.firestore().collection('Stores').doc(values.id).set({
        ...values
    },{merge:true})
}


 const useUpdateStoreData = ()=>{
    return useMutation(updateStoreData);
}


export default useUpdateStoreData;