import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
import axios from "axios";

const PSInitiateTransfer = async (values: any[]) => {
	//if recipient is === 1
	if (values.length === 1) {
		const singlePaystackTransferResponse = await axios.post(
			"https://api.paystack.co/transfer",
			{
				source: "balance",
				amount: values[0].amount * 100,
				recipient: values[0].data.psreciept_code,
				reason: values[0].data.reason,
			},
			{
				headers: {
					Authorization: `Bearer sk_live_69100aa66e3209d9f08647909543a2c330bbf85f`,
					"Content-Type": `application/json`,
				},
			}
		);

		if (singlePaystackTransferResponse.status === 200) {
			const updateRequestedPayoutPromise = await firebase
				.firestore()
				.collection("RequestedPayouts")
				.doc(values[0].data.requestedPayouts_uuid)
				.set(
					{
						fulfilled: true,
						date_fulfilled: firebase.firestore.FieldValue.serverTimestamp(),
						authorized_by_id: values[0].authorized_by_id,
						authorized_by_name: values[0].authorized_by_name,
						psTransferResponse: { ...singlePaystackTransferResponse.data },
					},
					{ merge: true }
				);

			const walletDocRef = firebase
				.firestore()
				.collection("Wallets")
				.doc(values[0].data.store_id);

			const updatePayoutWalletPromise = await firebase
				.firestore()
				.runTransaction((transaction) => {
					return transaction.get(walletDocRef).then((transactionDoc) => {
						if (!transactionDoc.exists) {
						}

						let newIncome =
							transactionDoc.data()?.pending_payout - values[0].amount;

						transaction.update(walletDocRef, {
							pending_payout: newIncome,
						});
					});
				});

			const allPromise = Promise.all([
				updateRequestedPayoutPromise,
				updatePayoutWalletPromise,
			]);

			return allPromise;
		}
	}

	//if recipient is > 1
	if (values.length > 1) {
		let transferList: any = [];
		for (let item in values) {
			transferList.push({
				amount: values[item].amount * 100,
				recipient: values[item].data.psreciept_code,
				reason: values[item].data.reason,
			});
		}

		const bulkPaystackTransferResponse = await axios.post(
			"https://api.paystack.co/transfer/bulk",
			{
				source: "balance",
				currency: "NGN",
				transfers: [...transferList],
			},
			{
				headers: {
					Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
					//   'Authorization': `Bearer ${process.env.NODE_ENV === 'development'? process.env.REACT_APP_PAYSTACK_TEST_SECRET_KEY :process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
					"Content-Type": `application/json`,
				},
			}
		);

		if (bulkPaystackTransferResponse.status === 200) {
			const transferPromises: any = [];

			for (const item of values) {
				const p = firebase
					.firestore()
					.collection("RequestedPayouts")
					.doc(values[item].data.requestedPayouts_uuid)
					.set(
						{
							fulfilled: true,
							date_fulfilled: firebase.firestore.FieldValue.serverTimestamp(),
							authorized_by_id: values[item].authorized_by_id,
							authorized_by_name: values[item].authorized_by_name,
							psTransferResponse: { ...bulkPaystackTransferResponse.data },
						},
						{ merge: true }
					);

				transferPromises.push(p);
			}

			return Promise.all([...transferPromises]);
		}
	}
};

const usePSInitiateTransfer = () => {
	return useMutation(PSInitiateTransfer);
};

export default usePSInitiateTransfer;
