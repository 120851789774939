import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const inviteNewAdmin = (values: any) => {
	return firebase
		.firestore()
		.collection("InvitedLogisticsPartnerAdmins")
		.doc()
		.set({
			...values,
			on: firebase.firestore.FieldValue.serverTimestamp(),
		});
};

const useInviteNewLogisticsAdmin = () => {
	return useMutation(inviteNewAdmin);
};

export default useInviteNewLogisticsAdmin;
