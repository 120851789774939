import _ from "lodash";
import moment from "moment";
import * as ROUTES from "../constants/routes";
import { CATEGORIES } from "../constants/categories";

//interface
import { ICurrentAdmin } from "../ts/interfaces/currentAdmin";

export const getNotifications = (Allnotifications: object[], id: string) => {
	const notification = Allnotifications.filter((item: any) => {
		return item.to === id;
	});
	const sorted = _.orderBy(notification, ["sent_on"], ["desc"]);
	return sorted;
};

export const getNotificationCount = (
	Allnotifications: object[],
	id: string
) => {
	const notification = Allnotifications.filter((item: any) => {
		return item.to === id && item.unread === true;
	});

	return notification.length;
};

export const mapAllAdminsObjectToArray = (AllAdminsObj: any) => {
	if (!_.isEmpty(AllAdminsObj)) {
		const result_array = [];
		for (const key in AllAdminsObj) {
			let obj: any = {};
			obj = { ...AllAdminsObj[key] };
			obj.id = key;
			result_array.push(obj);
		}

		return result_array;
	}
	return [];
};

export const mapAllAdminArrayToData = (AllAdminsArr: object[]) => {
	if (!_.isEmpty(AllAdminsArr)) {
		const result = _.map(AllAdminsArr, (item: any) => {
			return {
				avatar: item.first_name.charAt(0) + item.last_name.charAt(0),
				username: item.username,
				fullname: item.first_name + " " + item.last_name,
				role: item.role,
				joined_on: moment(item.joined_on.toDate()).fromNow(),
				approved: item.approved,
				blocked: item.blocked,
				data: item,
			};
		});

		return result;
	}

	return [];
};

export const checkIfApproved = (currentAdmin: ICurrentAdmin) => {
	let result: boolean;
	if (currentAdmin.approved === true) {
		result = true;
	} else {
		result = false;
	}

	return result;
};

export const checkIfBlocked = (currentAdmin: ICurrentAdmin) => {
	let result: boolean;
	if (currentAdmin.blocked === true) {
		result = true;
	} else {
		result = false;
	}

	return result;
};

export const checkIfIsNew = (currentAdmin: ICurrentAdmin) => {
	let result: boolean;
	if (currentAdmin.isNew === true) {
		result = true;
	} else {
		result = false;
	}

	return result;
};

//get all  stores data
export const getStoresCounts = (arr: any[], query: string) => {
	let filtered = [];
	switch (query) {
		case ROUTES.VIEWALLSTORESQUERIES.ALLSTORES:
			filtered = _.map(arr, _.clone);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES:
			filtered = _.filter(arr, (item) => item.active === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES:
			filtered = _.filter(arr, (item) => item.active === false);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES:
			filtered = _.filter(arr, (item) => item.approved === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES:
			filtered = _.filter(arr, (item) => item.approved === false);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES:
			filtered = _.filter(arr, (item) => item.blocked === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_PAID_PENDING_STORES:
			filtered = _.filter(
				arr,
				(item) => item.paid_for_review === true && item.pending_vet === true
			);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES:
			filtered = _.filter(arr, (item) => item.paid_for_review === false);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_UNDER_VETTING_STORES:
			filtered = _.filter(arr, (item) => item.under_vetting_by_legal === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE:
			filtered = _.filter(arr, (item) => item.vetted === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE:
			filtered = _.filter(arr, (item) => item.failed_vet === true);
			break;

		default:
			break;
	}

	const result = filtered.length;

	return result;
};
//get all  stores data
export const getStores = (arr: any[], query: string) => {
	let filtered = [];
	switch (query) {
		case ROUTES.VIEWALLSTORESQUERIES.ALLSTORES:
			filtered = _.map(arr, _.clone);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES:
			filtered = _.filter(arr, (item) => item.active === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES:
			filtered = _.filter(arr, (item) => item.active === false);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES:
			filtered = _.filter(arr, (item) => item.approved === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES:
			filtered = _.filter(arr, (item) => item.approved === false);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES:
			filtered = _.filter(arr, (item) => item.blocked === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_PAID_PENDING_STORES:
			filtered = _.filter(
				arr,
				(item) => item.paid_for_review === true && item.pending_vet === true
			);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES:
			filtered = _.filter(arr, (item) => item.paid_for_review === false);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_UNDER_VETTING_STORES:
			filtered = _.filter(arr, (item) => item.under_vetting_by_legal === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE:
			filtered = _.filter(arr, (item) => item.vetted === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE:
			filtered = _.filter(arr, (item) => item.failed_vet === true);
			break;

		default:
			break;
	}

	const sortedFilteredStores = _.orderBy(filtered, ["date_added"], ["desc"]);

	const result = _.map(sortedFilteredStores, (item: any) => {
		return {
			business_logo_id: item.business_logo_id,
			business_name: item.business_name,
			paid_for_review: item.paid_for_review,
			// plan: item.hasOwnProperty("plan") ? item.plan : "Free",
			plan: item?.picked_package
				? item.is_starter_package
					? "Starter"
					: item.is_professional_package
					? "Professional"
					: item.is_advanced_package
					? "Advanced"
					: item.is_premium_package
					? "Premium"
					: "None"
				: "Free",
			business_niche: item.business_niche?.join(" , "),
			business_location: item.business_location?.street_address_1,
			date_added: moment(item.date_added?.toDate()).fromNow(),
			approved: item.approved,
			blocked: item.blocked,
			active: item.active,
			id: item.id,
			data: item,
		};
	});

	return result;
};
export const getStoresReport = (arr: any[], adminArr: any[], query: string) => {
	let filtered = [];
	switch (query) {
		case ROUTES.VIEWALLSTORESQUERIES.ALLSTORES:
			filtered = _.map(arr, _.clone);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES:
			filtered = _.filter(arr, (item) => item.active === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES:
			filtered = _.filter(arr, (item) => item.active === false);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES:
			filtered = _.filter(arr, (item) => item.approved === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES:
			filtered = _.filter(arr, (item) => item.approved === false);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES:
			filtered = _.filter(arr, (item) => item.blocked === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_PAID_PENDING_STORES:
			filtered = _.filter(
				arr,
				(item) => item.paid_for_review === true && item.pending_vet === true
			);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES:
			filtered = _.filter(arr, (item) => item.paid_for_review === false);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_UNDER_VETTING_STORES:
			filtered = _.filter(arr, (item) => item.under_vetting_by_legal === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE:
			filtered = _.filter(arr, (item) => item.vetted === true);
			break;
		case ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE:
			filtered = _.filter(arr, (item) => item.failed_vet === true);
			break;

		default:
			break;
	}

	const all_same_docs_in_stores_and_admins = _.intersectionWith(
		adminArr,
		filtered,
		function (x: any, y: any) {
			return (
				x.entity === y.unique_id && x.role === "Administrator" && _.assign(x, y)
			);
		}
	);

	const sortedFilteredStores = _.orderBy(
		all_same_docs_in_stores_and_admins,
		["date_added"],
		["desc"]
	);

	const result = _.map(sortedFilteredStores, (item: any) => {
		return {
			business_name: item.business_name,
			business_niche: item.business_niche.join(" , "),
			business_location: `${item.business_location?.street_address_1}${item.business_location?.lga}${item.business_location?.ward}`,
			date_added: moment(item.date_added?.toDate()).fromNow(),
			representative: `${item.first_name}  ${item.last_name}`,
			business_phone_number: ` ${item.contact_phone_number}`,
			email: ` ${item.email}`,
		};
	});

	return result;
};

//products data

export const getProductCounts = (arr: any[], query: string) => {
	let filtered = [];
	switch (query) {
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS:
			filtered = _.map(arr, _.clone);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_ACTIVE_PRODUCTS:
			filtered = _.filter(arr, (item) => item.visible_on_market === true);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_IN_ACTIVE_PRODUCTS:
			filtered = _.filter(arr, (item) => item.visible_on_market === false);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_FEATURED_PRODUCTS:
			filtered = _.filter(arr, (item) => item.featured === true);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_ASSESSMENT:
			filtered = _.filter(arr, (item) => item.to_be_reviewed === true);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_TO_BE_RESOLVED:
			filtered = _.filter(arr, (item) => item.to_be_resolved === true);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_PROMOTED_PRODUCTS:
			filtered = _.filter(arr, (item) => item.promoted === true);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_LOW_PRICE_RANGE_PRODUCTS:
			filtered = _.filter(arr, (item) => item.regular_price <= 10000);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_HIGH_PRICE_RANGE_PRODUCTS:
			filtered = _.filter(arr, (item) => item.regular_price >= 10000);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_CREATIVE_PROCESS:
			filtered = _.filter(arr, (item) => item.under_creatives_process === true);
			break;

		default:
			break;
	}

	const result = filtered.length;

	return result;
};

export const truncate = (str: any, n: number) => {
	if (typeof str !== "undefined") {
		return str?.length > n ? str.substr(0, n - 1) + "..." : str;
	}
	return "error!";
};

//get product table data

export const getProducts = (arr: any[], query: string) => {
	let filtered = [];
	switch (query) {
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS:
			filtered = _.map(arr, _.clone);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_ACTIVE_PRODUCTS:
			filtered = _.filter(arr, (item) => item.visible_on_market === true);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_IN_ACTIVE_PRODUCTS:
			filtered = _.filter(arr, (item) => item.visible_on_market === false);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_FEATURED_PRODUCTS:
			filtered = _.filter(arr, (item) => item.featured === true);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_ASSESSMENT:
			filtered = _.filter(arr, (item) => item.to_be_reviewed === true);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_TO_BE_RESOLVED:
			filtered = _.filter(arr, (item) => item.to_be_resolved === true);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_PROMOTED_PRODUCTS:
			filtered = _.filter(arr, (item) => item.promoted === true);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_LOW_PRICE_RANGE_PRODUCTS:
			filtered = _.filter(arr, (item) => item.regular_price <= 10000);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_HIGH_PRICE_RANGE_PRODUCTS:
			filtered = _.filter(arr, (item) => item.regular_price >= 10000);
			break;
		case ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_CREATIVE_PROCESS:
			filtered = _.filter(arr, (item) => item.under_creatives_process === true);
			break;
		default:
			break;
	}

	const sortedFiltered = _.orderBy(filtered, ["added_on"], ["desc"]);

	const result = _.map(sortedFiltered, (item) => {
		if (typeof item !== "undefined") {
			return {
				photo: item.image_1_url,
				productname: truncate(item.product_name, 25),
				category: truncate(item.product_category, 25),
				price: item.regular_price,
				stock: item.enable_stock_management
					? item.stock_quantity
					: item.stock_status,
				status: item.visible_on_market,
				to_be_reviewed: item.to_be_reviewed,
				to_be_resolved: item.to_be_resolved,
				featured: item.featured,
				promoted: item.promoted,
				added: moment(item.added_on?.toDate()).fromNow(),
				createdon: moment(item.added_on?.toDate()).format("MM/DD/YYYY"),
				added_on: item.added_on,
				id: item.product_id,
				permalink: item.product_permalink,
				data: item,
				store_name: item.store_name,
			};
		}
		return {};
	});

	const sorted = _.orderBy(result, ["added_on"], ["desc"]);

	return sorted;
};

export const getInterestedVendors = (arr: any[], query: string) => {
	let filtered = [];
	const now = moment();
	switch (query) {
		case ROUTES.INTERESTED_VENDORS_QUERIES.CHECKED_INTERESTED_VENDORS:
			filtered = _.filter(arr, (item) => item.checked === true);
			break;
		case ROUTES.INTERESTED_VENDORS_QUERIES.UNCHECKED_INTERESTED_VENDORS:
			filtered = _.filter(arr, (item) => item.checked === false);
			break;
		case ROUTES.INTERESTED_VENDORS_QUERIES.TODAYS_INTERESTED_VENDORS:
			filtered = _.filter(arr, (item) =>
				moment(item.date_added.toDate()).isSame(now, "day")
			);
			break;
		case ROUTES.INTERESTED_VENDORS_QUERIES.PREVIOUS_DAYS_INTERESTED_VENDORS:
			filtered = _.filter(arr, (item) =>
				moment(item.date_added.toDate()).isBefore(now, "month")
			);
			break;

		default:
			break;
	}

	const result = _.map(filtered, (item) => {
		return {
			fullname: `${item.first_name} ${item.last_name}`,
			contact_number: item.contact_phone_number,
			email: item.email,
			added_on: moment(item.date_added.toDate()).fromNow(),
			createdon: moment(item.date_added.toDate()).format("MM/DD/YYYY"),
			website: item.website,
			id: item.id,
		};
	});

	return result;
};

export const getProductsByCategory = (arr: any[], query: string) => {
	let filtered = [];

	switch (query) {
		case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_HOME_AND_DECORS:
			filtered = _.filter(
				arr,
				(item) => item.product_category === CATEGORIES.HOME_AND_DECOR
			);
			break;
		case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_ART_AND_CRAFT:
			filtered = _.filter(
				arr,
				(item) => item.product_category === CATEGORIES.ART_AND_CRAFT
			);
			break;
		case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_BEADS_AND_WIREWORKS:
			filtered = _.filter(
				arr,
				(item) => item.product_category === CATEGORIES.BEADS_AND_WIREWORKS
			);
			break;
		case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_BEAUTY_PRODUCTS:
			filtered = _.filter(
				arr,
				(item) => item.product_category === CATEGORIES.BEAUTY_PRODUCTS
			);
			break;
		case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_ClOTH_AND_FASHION:
			filtered = _.filter(
				arr,
				(item) => item.product_category === CATEGORIES.ClOTH_AND_FASHION
			);
			break;

		default:
			break;
	}

	return filtered;
};
export const getProductsByCategoryCount = (arr: any[], query: string) => {
	let filtered = [];

	switch (query) {
		case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_HOME_AND_DECORS:
			filtered = _.filter(
				arr,
				(item) => item.product_category === CATEGORIES.HOME_AND_DECOR
			);
			break;
		case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_ART_AND_CRAFT:
			filtered = _.filter(
				arr,
				(item) => item.product_category === CATEGORIES.ART_AND_CRAFT
			);
			break;
		case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_BEADS_AND_WIREWORKS:
			filtered = _.filter(
				arr,
				(item) => item.product_category === CATEGORIES.BEADS_AND_WIREWORKS
			);
			break;
		case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_BEAUTY_PRODUCTS:
			filtered = _.filter(
				arr,
				(item) => item.product_category === CATEGORIES.BEAUTY_PRODUCTS
			);
			break;
		case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_ClOTH_AND_FASHION:
			filtered = _.filter(
				arr,
				(item) => item.product_category === CATEGORIES.ClOTH_AND_FASHION
			);
			break;

		default:
			break;
	}

	const result = filtered.length;

	return result;
};

//vet counts
export const getVetDocsCounts = (arr: any[], query: string) => {
	let result_vet_count = [];

	switch (query) {
		case ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS:
			result_vet_count = _.map(arr, _.clone);
			break;
		case ROUTES.VET_DOC_QUERIES.ALL_TO_BE_VETTED:
			result_vet_count = _.filter(
				arr,
				(item) =>
					_.has(item, "to_be_vetted") && item.to_be_vetted.status === true
			);
			break;
		case ROUTES.VET_DOC_QUERIES.ALL_UNDER_VETTING:
			result_vet_count = _.filter(
				arr,
				(item) =>
					_.has(item, "under_vetting") && item.under_vetting.status === true
			);
			break;
		case ROUTES.VET_DOC_QUERIES.ALL_DRAFT:
			result_vet_count = _.filter(
				arr,
				(item) => _.has(item, "draft") && item.draft.status === true
			);
			break;
		case ROUTES.VET_DOC_QUERIES.ALL_PASSED:
			result_vet_count = _.filter(
				arr,
				(item) => _.has(item, "passed") && item.passed.status === true
			);
			break;
		case ROUTES.VET_DOC_QUERIES.ALL_FAILED:
			result_vet_count = _.filter(
				arr,
				(item) => _.has(item, "failed") && item.failed.status === true
			);
			break;

		default:
			break;
	}

	const result = result_vet_count.length;

	return result;
};
export const getVetDocs = (arr: any[], query: string) => {
	let result_vet = [];

	switch (query) {
		case ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS:
			result_vet = _.map(arr, _.clone);
			break;
		case ROUTES.VET_DOC_QUERIES.ALL_TO_BE_VETTED:
			result_vet = _.filter(
				arr,
				(item) =>
					_.has(item, "to_be_vetted") && item.to_be_vetted.status === true
			);
			break;
		case ROUTES.VET_DOC_QUERIES.ALL_UNDER_VETTING:
			result_vet = _.filter(
				arr,
				(item) =>
					_.has(item, "under_vetting") && item.under_vetting.status === true
			);
			break;
		case ROUTES.VET_DOC_QUERIES.ALL_DRAFT:
			result_vet = _.filter(
				arr,
				(item) => _.has(item, "draft") && item.draft.status === true
			);
			break;
		case ROUTES.VET_DOC_QUERIES.ALL_PASSED:
			result_vet = _.filter(
				arr,
				(item) => _.has(item, "passed") && item.passed.status === true
			);
			break;
		case ROUTES.VET_DOC_QUERIES.ALL_FAILED:
			result_vet = _.filter(
				arr,
				(item) => _.has(item, "failed") && item.failed.status === true
			);
			break;

		default:
			break;
	}

	const result = _.map(result_vet, (item) => {
		return {
			vid: item.vet_id,
			business_logo_id: item.business_logo_id,
			business_name: item.business_name,
			business_location: `${item.business_location.street_address_1} ,${item.business_location.postal_code_1}`,
			business_niche: item.business_niche,
			data: item,
			status: "",
			vetted_by: "",
		};
	});

	return result;
};

//get bookings counts
export const getBookingCounts = (arr: any[], query: string) => {
	let result_booking_count = [];

	switch (query) {
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS:
			result_booking_count = _.map(arr, _.clone);
			break;
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_WAITING_TO_BE_SHIPPED:
			result_booking_count = _.filter(
				arr,
				(item) =>
					_.has(item, "waiting_to_be_shipped") &&
					item.waiting_to_be_shipped.current === true
			);
			break;
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_OUT_FOR_DELIVERY:
			result_booking_count = _.filter(
				arr,
				(item) =>
					_.has(item, "out_for_delivery") &&
					item.out_for_delivery.current === true
			);
			break;
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_DELIVERED:
			result_booking_count = _.filter(
				arr,
				(item) => _.has(item, "delivered") && item.delivered.current === true
			);
			break;
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_CANCELLED:
			result_booking_count = _.filter(
				arr,
				(item) => _.has(item, "canceled") && item.canceled.current === true
			);
			break;
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_RETURN:
			result_booking_count = _.filter(
				arr,
				(item) => _.has(item, "returned") && item.returned.current === true
			);
			break;

		default:
			break;
	}

	const result = result_booking_count.length;

	return result;
};

//get bookings
export const getBooking = (arr: any[], query: string) => {
	let result_booking = [];

	switch (query) {
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_BOOKINGS:
			result_booking = _.map(arr, _.clone);
			break;
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_WAITING_TO_BE_SHIPPED:
			result_booking = _.filter(
				arr,
				(item) =>
					_.has(item, "waiting_to_be_shipped") &&
					item.waiting_to_be_shipped.current === true
			);
			break;
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_OUT_FOR_DELIVERY:
			result_booking = _.filter(
				arr,
				(item) =>
					_.has(item, "out_for_delivery") &&
					item.out_for_delivery.current === true
			);
			break;
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_DELIVERED:
			result_booking = _.filter(
				arr,
				(item) => _.has(item, "delivered") && item.delivered.current === true
			);
			break;
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_CANCELLED:
			result_booking = _.filter(
				arr,
				(item) => _.has(item, "canceled") && item.canceled.current === true
			);
			break;
		case ROUTES.LOGISTICS_BOOKINGS_QUERIES.ALL_RETURN:
			result_booking = _.filter(
				arr,
				(item) => _.has(item, "returned") && item.returned.current === true
			);
			break;

		default:
			break;
	}

	const order_filtered_by_date_added = _.orderBy(
		result_booking,
		["added_on"],
		["desc"]
	);

	const result = _.map(order_filtered_by_date_added, (item) => {
		return {
			order_id: item.order_id,
			orderTimeline_uuid: item.orderTimeline_uuid,
			store_name: item.store_name,
			pick_address: item.pick_address,
			dimensions: `${item.dimension_length} ${item.dimension_unit} X ${item.dimension_breadth} ${item.dimension_unit}  X ${item.dimension_height} ${item.dimension_unit} `,
			weight: `${item.weight} ${item.weight_unit} `,
			delivery_address: item.delivery_address,
			quantity: item.quantity,
			dispatcher: item.logistics.name,
			data: item,
			logistics_admin: _.has(item, "logistics")
				? `${item.first_name} ${item.last_name} (${item.username})`
				: "",
			shipping_type: _.has(item, "shipping_type")
				? item.shipping_type
				: "local",
			added: moment(item.logistics.on.toDate()).fromNow(),
			createdon: moment(item.logistics.on.toDate()).format("MM/DD/YYYY"),
		};
	});

	return result;
};

//get brand stories counts
export const getStoriesCounts = (arr: any[], query: string) => {
	let result_story_count = [];

	switch (query) {
		case ROUTES.BRAND_STORIES_QUERIES.ALL_BRAND_STORIES:
			result_story_count = _.map(arr, _.clone);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_LIVE_STORIES:
			result_story_count = _.filter(
				arr,
				(item) => _.has(item, "live") && item.live === true
			);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_REVIEWED:
			result_story_count = _.filter(
				arr,
				(item) =>
					_.has(item, "to_be_reviewed") && item.to_be_reviewed.status === true
			);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_UNDER_REVIEW:
			result_story_count = _.filter(
				arr,
				(item) =>
					_.has(item, "under_review") && item.under_review.status === true
			);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_RESOLVED:
			result_story_count = _.filter(
				arr,
				(item) =>
					_.has(item, "to_be_resolved") && item.to_be_resolved.status === true
			);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_PASSED:
			result_story_count = _.filter(
				arr,
				(item) => _.has(item, "pass") && item.pass.status === true
			);
			break;

		default:
			break;
	}

	const result = result_story_count.length;

	return result;
};
//get brand stories
export const getStories = (arr: any[], query: string) => {
	let result_story = [];

	switch (query) {
		case ROUTES.BRAND_STORIES_QUERIES.ALL_BRAND_STORIES:
			result_story = _.map(arr, _.clone);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_LIVE_STORIES:
			result_story = _.filter(
				arr,
				(item) => _.has(item, "live") && item.live === true
			);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_PENDING_REVIEW:
			result_story = _.filter(
				arr,
				(item) => _.has(item, "pending_review") && item.pending_review === true
			);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_REVIEWED:
			result_story = _.filter(
				arr,
				(item) =>
					_.has(item, "to_be_reviewed") && item.to_be_reviewed.status === true
			);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_UNDER_REVIEW:
			result_story = _.filter(
				arr,
				(item) =>
					_.has(item, "under_review") && item.under_review.status === true
			);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_RESOLVED:
			result_story = _.filter(
				arr,
				(item) =>
					_.has(item, "to_be_resolved") && item.to_be_resolved.status === true
			);
			break;
		case ROUTES.BRAND_STORIES_QUERIES.ALL_PASSED:
			result_story = _.filter(
				arr,
				(item) => _.has(item, "pass") && item.pass.status === true
			);
			break;

		default:
			break;
	}

	const result_s = _.map(result_story, (item) => {
		return {
			title: item.title,
			type: item.type_of_story,
			category: _.map(item.catergory, (elem) => elem + " ,"),
			// category: item.catergory,
			shop_name: item.shop_name,
			description: item.description,
			data: item,
			added: moment(item.added_on.toDate()).fromNow(),
			createdon: moment(item.added_on.toDate()).format("MM/DD/YYYY"),
		};
	});

	return result_s;
};

//get orders data

export const getOrders = (arr: any[], query: string) => {
	let orderedFiltered = [];

	switch (query) {
		case ROUTES.VIEWORDERSQUERIES.ALL_ORDERS:
			orderedFiltered = _.map(arr, _.clone);
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_ORDER_PLACED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.order_placed))
					return item.order_placed.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_PENDING_CONFIRMATION:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.pending_confirmation))
					return item.pending_confirmation.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_WAITING_TO_BE_SHIPPED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.waiting_to_be_shipped))
					return item.waiting_to_be_shipped.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_SHIPPED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.shipped)) return item.shipped.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_OUT_FOR_DELIVERY:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.out_for_delivery))
					return item.out_for_delivery.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_DELIVERED:
			orderedFiltered = _.filter(
				arr,
				(item) => _.has(item, "delivered") && item.delivered.current === true
			);
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_RETURNED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.returned))
					return item.returned.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_CANCELLED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.canceled))
					return item.canceled.current === true;
			});
			break;

		default:
			break;
	}

	const order_filtered_by_date_added = _.orderBy(
		orderedFiltered,
		["added_on"],
		["desc"]
	);

	// console.log(order_filtered_by_date_added);
	const result = _.map(order_filtered_by_date_added, (item) => {
		return {
			order_id: item.order_id,
			orderTimeline_uuid: item.orderTimeline_uuid,
			store_name: item.store_name,
			pick_address: item.pick_address,
			dimensions: `${item.dimension_length} ${item.dimension_unit} X ${item.dimension_breadth} ${item.dimension_unit}  X ${item.dimension_height} ${item.dimension_unit} `,
			weight: `${item.weight} ${item.weight_unit} `,
			delivery_address: item.delivery_address,
			quantity: item.quantity,
			dispatcher: _.has(item, "logistics") ? item.logistics.name : "Unbooked",
			data: item,
			logistics_admin: _.has(item, "logistics")
				? `${item.first_name} ${item.last_name} (${item.username})`
				: "",
			shipping_type: _.has(item, "shipping_type")
				? item.shipping_type
				: "local",
			// added: moment(item.logistics.on.toDate()).fromNow(),
			// createdon: moment(item.logistics.on.toDate()).format("MM/DD/YYYY"),
			added: moment(item.added_on.toDate()).fromNow(),
			createdon: moment(item.added_on.toDate()).format("MM/DD/YYYY"),
		};
	});

	return result;
};

// export const getOrders = (arr: any[], query: string) => {
// 	let orderedFiltered = [];

// 	switch (query) {
// 		case ROUTES.VIEWORDERSQUERIES.ALL_ORDERS:
// 			orderedFiltered = _.map(arr, _.clone);
// 			break;
// 		case ROUTES.VIEWORDERSQUERIES.ALL_ORDER_PLACED:
// 			orderedFiltered = _.filter(arr, (item) => {
// 				if (!_.isUndefined(item.order_placed))
// 					return item.order_placed.current === true;
// 			});
// 			break;
// 		case ROUTES.VIEWORDERSQUERIES.ALL_PENDING_CONFIRMATION:
// 			orderedFiltered = _.filter(arr, (item) => {
// 				if (!_.isUndefined(item.pending_confirmation))
// 					return item.pending_confirmation.current === true;
// 			});
// 			break;
// 		case ROUTES.VIEWORDERSQUERIES.ALL_WAITING_TO_BE_SHIPPED:
// 			orderedFiltered = _.filter(arr, (item) => {
// 				if (!_.isUndefined(item.waiting_to_be_shipped))
// 					return item.waiting_to_be_shipped.current === true;
// 			});
// 			break;
// 		case ROUTES.VIEWORDERSQUERIES.ALL_SHIPPED:
// 			orderedFiltered = _.filter(arr, (item) => {
// 				if (!_.isUndefined(item.shipped)) return item.shipped.current === true;
// 			});
// 			break;
// 		case ROUTES.VIEWORDERSQUERIES.ALL_OUT_FOR_DELIVERY:
// 			orderedFiltered = _.filter(arr, (item) => {
// 				if (!_.isUndefined(item.out_for_delivery))
// 					return item.out_for_delivery.current === true;
// 			});
// 			break;
// 		case ROUTES.VIEWORDERSQUERIES.ALL_DELIVERED:
// 			orderedFiltered = _.filter(arr, (item) => {
// 				if (!_.isUndefined(item.delivered))
// 					return item.delivered.current === true;
// 			});
// 			break;
// 		case ROUTES.VIEWORDERSQUERIES.ALL_RETURNED:
// 			orderedFiltered = _.filter(arr, (item) => {
// 				if (!_.isUndefined(item.returned))
// 					return item.returned.current === true;
// 			});
// 			break;
// 		case ROUTES.VIEWORDERSQUERIES.ALL_CANCELLED:
// 			orderedFiltered = _.filter(arr, (item) => {
// 				if (!_.isUndefined(item.canceled))
// 					return item.canceled.current === true;
// 			});
// 			break;

// 		default:
// 			break;
// 	}

// 	const order_filtered_by_date_added = _.orderBy(
// 		orderedFiltered,
// 		["added_on"],
// 		["desc"]
// 	);

// 	// console.log(order_filtered_by_date_added);

// 	const result_filtered_orders = _.map(order_filtered_by_date_added, (item) => {
// 		return {
// 			image: item.product_image,
// 			order_id: item.order_id,
// 			product_name: item.product_name,
// 			quantity: item.quantity,
// 			price: item.subtotal,
// 			logistics_admin: _.has(item, "logistics")
// 				? `${item.first_name} ${item.last_name} (${item.username})`
// 				: "",
// 			store_name: item.store_name,
// 			data: item,
// 			status: "status",
// 			added: moment(item.added_on.toDate()).fromNow(),
// 			createdon: moment(item.added_on.toDate()).format("MM/DD/YYYY"),
// 		};
// 	});
// 	return result_filtered_orders;
// };
export const getOrderCount = (arr: any[], query: string) => {
	let orderedFiltered = [];

	switch (query) {
		case ROUTES.VIEWORDERSQUERIES.ALL_ORDERS:
			orderedFiltered = _.map(arr, _.clone);
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_ORDER_PLACED:
			orderedFiltered = _.filter(
				arr,
				(item) =>
					_.has(item.data.order_placed, "current") &&
					item.data.order_placed.current === true
			);
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_PENDING_CONFIRMATION:
			orderedFiltered = _.filter(
				arr,
				(item) =>
					_.has(item.data.pending_confirmation, "current") &&
					item.data.pending_confirmation.current === true
			);
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_WAITING_TO_BE_SHIPPED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.data.waiting_to_be_shipped))
					return item.data.waiting_to_be_shipped.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_SHIPPED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.data.shipped))
					return item.data.shipped.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_OUT_FOR_DELIVERY:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.data.out_for_delivery))
					return item.data.out_for_delivery.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_DELIVERED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.data.delivered))
					return item.data.delivered.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_RETURNED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.data.returned))
					return item.data.returned.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_CANCELLED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.data.canceled))
					return item.data.canceled.current === true;
			});
			break;

		default:
			break;
	}

	const result = orderedFiltered.length;

	return result;
};
export const getOrderCountDashboard = (arr: any[], query: string) => {
	let orderedFiltered = [];

	switch (query) {
		case ROUTES.VIEWORDERSQUERIES.ALL_ORDERS:
			orderedFiltered = _.map(arr, _.clone);
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_ORDER_PLACED:
			orderedFiltered = _.filter(
				arr,
				(item) =>
					_.has(item.order_placed, "current") &&
					item.order_placed.current === true
			);
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_PENDING_CONFIRMATION:
			orderedFiltered = _.filter(
				arr,
				(item) =>
					_.has(item.pending_confirmation, "current") &&
					item.pending_confirmation.current === true
			);
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_WAITING_TO_BE_SHIPPED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.waiting_to_be_shipped))
					return item.waiting_to_be_shipped.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_SHIPPED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.shipped)) return item.shipped.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_OUT_FOR_DELIVERY:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.out_for_delivery))
					return item.out_for_delivery.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_DELIVERED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.delivered))
					return item.delivered.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_RETURNED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.returned))
					return item.returned.current === true;
			});
			break;
		case ROUTES.VIEWORDERSQUERIES.ALL_CANCELLED:
			orderedFiltered = _.filter(arr, (item) => {
				if (!_.isUndefined(item.canceled))
					return item.canceled.current === true;
			});
			break;

		default:
			break;
	}

	const result = orderedFiltered.length;

	return result;
};

//mail queries
export const MAILQUERIES = {
	INVITE_NEW_ADMIN: "invite-new-admin",
	ASSESSMENT_SUCCESSFUL: "assessment-successful",
	ASSESSMENT_UNSUCCESSFUL: "assessment-unsuccessful",
};

interface IGenerateLink {
	dest: string;
	query: string;
	username?: string;
	first_name?: string;
	last_name?: string;
	role?: string;
	entity?: string;
	invited_by?: string;
	business_name?: string;
	address?: string;
	vendor_name?: string;
	classtype?: string;

	//product assessment
	product_name?: string;
	product_name_reason?: string;
	product_short_description_reason?: string;
	product_full_description_reason?: string;
	product_general_data_reason?: string;
	product_image_data_reason?: string;
	product_attribute_data_reason?: string;
}

export const generateMailLink = (values: IGenerateLink) => {
	const dest = typeof values.dest === "undefined" ? "null" : values.dest;
	const query = typeof values.query === "undefined" ? "null" : values.query;
	const username =
		typeof values.username === "undefined" ? "null" : values.username;
	const first_name =
		typeof values.first_name === "undefined" ? "null" : values.first_name;
	const last_name =
		typeof values.last_name === "undefined" ? "null" : values.last_name;
	const role = typeof values.role === "undefined" ? "null" : values.role;
	const entity = typeof values.entity === "undefined" ? "null" : values.entity;
	const invited_by =
		typeof values.invited_by === "undefined" ? "null" : values.invited_by;
	const business_name =
		typeof values.business_name === "undefined" ? "null" : values.business_name;
	const address =
		typeof values.address === "undefined" ? "null" : values.address;
	const vendor_name =
		typeof values.vendor_name === "undefined" ? "null" : values.vendor_name;
	const classtype =
		typeof values.classtype === "undefined" ? "null" : values.classtype;
	const product_name =
		typeof values.product_name === "undefined" ? "null" : values.product_name;
	const product_name_reason =
		typeof values.product_name_reason === "undefined"
			? "null"
			: values.product_name_reason;
	const product_short_description_reason =
		typeof values.product_short_description_reason === "undefined"
			? "null"
			: values.product_short_description_reason;
	const product_full_description_reason =
		typeof values.product_full_description_reason === "undefined"
			? "null"
			: values.product_full_description_reason;
	const product_general_data_reason =
		typeof values.product_general_data_reason === "undefined"
			? "null"
			: values.product_general_data_reason;
	const product_image_data_reason =
		typeof values.product_image_data_reason === "undefined"
			? "null"
			: values.product_image_data_reason;
	const product_attribute_data_reason =
		typeof values.product_attribute_data_reason === "undefined"
			? "null"
			: values.product_attribute_data_reason;

	return `https://us-central1-ekioja-7bb9f.cloudfunctions.net/ekiojaExpressWidgets/sendMail/${query}/${dest}/${username}/${first_name}/${last_name}/${business_name}/${address}/${vendor_name}/${invited_by}/${role}/${entity}/${classtype}/${product_name}/${product_name_reason}/${product_short_description_reason}/${product_full_description_reason}/${product_general_data_reason}/${product_image_data_reason}/${product_attribute_data_reason}`;
};

export function makeid(length: number) {
	var result = "";
	var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function orderNumber() {
	let now = Date.now().toString(); // '1492341545873'
	// pad with extra random digit
	now += now + Math.floor(Math.random() * 10);
	// format
	return [makeid(2), now.slice(0, 4), now.slice(4, 10), now.slice(10, 14)].join(
		"-"
	);
}

export function vetNumber() {
	let now = Date.now().toString(); // '1492341545873'
	// pad with extra random digit
	now += now + Math.floor(Math.random() * 10);
	// format
	return [makeid(2), now.slice(0, 4), now.slice(4, 10), now.slice(10, 14)].join(
		"-"
	);
}

export function referenceNumber() {
	let now = Date.now().toString(); // '1492341545873'
	// pad with extra random digit
	now += now + Math.floor(Math.random() * 10);
	// format
	return [makeid(2), now.slice(0, 4), now.slice(4, 10), now.slice(10, 14)].join(
		"-"
	);
}
export function CreativeJoBNumber() {
	let now = Date.now().toString(); // '1492341545873'
	// pad with extra random digit
	now += now + Math.floor(Math.random() * 10);
	// format
	return [makeid(2), now.slice(0, 4), now.slice(4, 10), now.slice(10, 14)].join(
		"-"
	);
}

export function getLegalCut(amount: number) {
	return amount * 0.15;
}
export function getAdminForProductStoryCut(amount: number) {
	return amount * 0.1;
}

//moment
var REFERENCE = moment();
var TODAY = REFERENCE.clone().startOf("day");
var YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
var A_WEEK_OLD = REFERENCE.clone().subtract(7, "days").startOf("day");
var A_MONTH_OLD = REFERENCE.clone().subtract(30, "days").startOf("day");

export function isToday(momentDate: any) {
	return momentDate.isSame(TODAY, "d");
}
export function isYesterday(momentDate: any) {
	return momentDate.isSame(YESTERDAY, "d");
}
export function isWithinAWeek(momentDate: any) {
	return momentDate.isAfter(A_WEEK_OLD);
}
export function isWithinAMonth(momentDate: any) {
	return momentDate.isAfter(A_MONTH_OLD);
}
export function isTwoWeeksOrMore(momentDate: any) {
	return !isWithinAWeek(momentDate);
}

export const getUpcomingRevenueTimeFrame = (arr: any[], time: string) => {
	let TBFiltered = [];

	switch (time) {
		case "today":
			TBFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.to_be_vetted.on.toDate())) === true
			);
			break;
		case "within-a-week":
			TBFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.to_be_vetted.on.toDate())) === true
			);
			break;
		case "within-a-month":
			TBFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.to_be_vetted.on.toDate())) === true
			);
			break;
		case "over-two-weeks":
			TBFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.to_be_vetted.on.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_tbfiltered = _.sum(_.map(TBFiltered, (item) => item.commission));

	return result_tbfiltered;
};

export const getPendingClearanceRevenueTimeFrame = (
	arr: any[],
	time: string
) => {
	let UVFiltered = [];

	switch (time) {
		case "today":
			UVFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.under_vetting.on.toDate())) === true
			);
			break;
		case "within-a-week":
			UVFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.under_vetting.on.toDate())) === true
			);
			break;
		case "within-a-month":
			UVFiltered = _.filter(
				arr,
				(item) =>
					isWithinAMonth(moment(item.under_vetting.on.toDate())) === true
			);
			break;
		case "over-two-weeks":
			UVFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.under_vetting.on.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_uvfiltered = _.sum(_.map(UVFiltered, (item) => item.commission));

	return result_uvfiltered;
};

export const getEarnedRevenuePassedTimeFrame = (arr: any[], time: string) => {
	let VFiltered = [];

	switch (time) {
		case "today":
			VFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.passed.on.toDate())) === true
			);
			break;
		case "within-a-week":
			VFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.passed.on.toDate())) === true
			);
			break;
		case "within-a-month":
			VFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.passed.on.toDate())) === true
			);
			break;
		case "over-two-weeks":
			VFiltered = _.filter(
				arr,
				(item) => isTwoWeeksOrMore(moment(item.passed.on.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_vfiltered = _.sum(_.map(VFiltered, (item) => item.commission));

	return result_vfiltered;
};

export const getEarnedRevenueFailedTimeFrame = (arr: any[], time: string) => {
	let VFiltered = [];

	switch (time) {
		case "today":
			VFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.failed.on.toDate())) === true
			);
			break;
		case "within-a-week":
			VFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.failed.on.toDate())) === true
			);
			break;
		case "within-a-month":
			VFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.failed.on.toDate())) === true
			);
			break;
		case "over-two-weeks":
			VFiltered = _.filter(
				arr,
				(item) => isTwoWeeksOrMore(moment(item.failed.on.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_vfiltered = _.sum(_.map(VFiltered, (item) => item.commission));

	return result_vfiltered;
};

//logistics wallet

export const getLogisticsUpcomingRevenueTimeFrame = (
	arr: any[],
	time: string
) => {
	let TBFiltered = [];

	switch (time) {
		case "today":
			TBFiltered = _.filter(
				arr,
				(item) =>
					isToday(moment(item.waiting_to_be_shipped.date.toDate())) === true
			);
			break;
		case "within-a-week":
			TBFiltered = _.filter(
				arr,
				(item) =>
					isWithinAWeek(moment(item.waiting_to_be_shipped.date.toDate())) ===
					true
			);
			break;
		case "within-a-month":
			TBFiltered = _.filter(
				arr,
				(item) =>
					isWithinAMonth(moment(item.waiting_to_be_shipped.date.toDate())) ===
					true
			);
			break;
		case "over-two-weeks":
			TBFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.waiting_to_be_shipped.date.toDate())) ===
					true
			);
			break;

		default:
			break;
	}

	const deliveryFees = TBFiltered.map((item: any) => item.delivery_fee);

	const totalDeliveryFee = deliveryFees.reduce(
		(a: number, b: number) => a + b,
		0
	);

	return totalDeliveryFee;
};

export const getLogisticsPendingClearanceRevenueTimeFrame = (
	arr: any[],
	time: string
) => {
	let UVFiltered = [];

	switch (time) {
		case "today":
			UVFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.out_for_delivery.date.toDate())) === true
			);
			break;
		case "within-a-week":
			UVFiltered = _.filter(
				arr,
				(item) =>
					isWithinAWeek(moment(item.out_for_delivery.date.toDate())) === true
			);
			break;
		case "within-a-month":
			UVFiltered = _.filter(
				arr,
				(item) =>
					isWithinAMonth(moment(item.out_for_delivery.date.toDate())) === true
			);
			break;
		case "over-two-weeks":
			UVFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.out_for_delivery.date.toDate())) === true
			);
			break;

		default:
			break;
	}

	// const result_uvfiltered = UVFiltered.length * 950;

	const deliveryFees = UVFiltered.map((item: any) => item.delivery_fee);

	const totalDeliveryFee = deliveryFees.reduce(
		(a: number, b: number) => a + b,
		0
	);

	return totalDeliveryFee;
};

export const getLogisticsEarnedRevenueFailedTimeFrame = (
	arr: any[],
	time: string
) => {
	let VFiltered = [];

	switch (time) {
		case "today":
			VFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.delivered.date.toDate())) === true
			);
			break;
		case "within-a-week":
			VFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.delivered.date.toDate())) === true
			);
			break;
		case "within-a-month":
			VFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.delivered.date.toDate())) === true
			);
			break;
		case "over-two-weeks":
			VFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.delivered.date.toDate())) === true
			);
			break;

		default:
			break;
	}

	// const result_vfiltered = VFiltered.length * 950;

	const deliveryFees = VFiltered.map((item: any) => item.delivery_fee);

	const totalDeliveryFee = deliveryFees.reduce(
		(a: number, b: number) => a + b,
		0
	);

	return totalDeliveryFee;
};

//Creatives revenue
export const getCreativeUpcomingRevenueTimeFrame = (
	arr: any[],
	time: string
) => {
	let TBFiltered = [];

	switch (time) {
		case "today":
			TBFiltered = _.filter(
				arr,
				(item) =>
					_.has(item, "to_be_reviewed") &&
					typeof isToday(moment(item.to_be_reviewed.on.toDate())) !==
						"undefined" &&
					isToday(moment(item.to_be_reviewed.on.toDate())) === true
			);
			break;
		case "within-a-week":
			TBFiltered = _.filter(
				arr,
				(item) =>
					isWithinAWeek(moment(item.to_be_reviewed.on.toDate())) === true
			);
			break;
		case "within-a-month":
			TBFiltered = _.filter(
				arr,
				(item) =>
					isWithinAMonth(moment(item.to_be_reviewed.on.toDate())) === true
			);
			break;
		case "over-two-weeks":
			TBFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.to_be_reviewed.on.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_tbfiltered = _.sum(_.map(TBFiltered, (item) => item.commission));

	return result_tbfiltered;
};

export const getCreativesPendingClearanceRevenueTimeFrame = (
	arr: any[],
	time: string
) => {
	let UVFiltered = [];

	switch (time) {
		case "today":
			UVFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.under_review.on.toDate())) === true
			);
			break;
		case "within-a-week":
			UVFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.under_review.on.toDate())) === true
			);
			break;
		case "within-a-month":
			UVFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.under_review.on.toDate())) === true
			);
			break;
		case "over-two-weeks":
			UVFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.under_review.on.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_uvfiltered = _.sum(_.map(UVFiltered, (item) => item.commission));

	return result_uvfiltered;
};

export const getCreativesEarnedRevenueTimeFrame = (
	arr: any[],
	time: string
) => {
	let VFiltered = [];

	switch (time) {
		case "today":
			VFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.pass.on.toDate())) === true
			);
			break;
		case "within-a-week":
			VFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.pass.on.toDate())) === true
			);
			break;
		case "within-a-month":
			VFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.pass.on.toDate())) === true
			);
			break;
		case "over-two-weeks":
			VFiltered = _.filter(
				arr,
				(item) => isTwoWeeksOrMore(moment(item.pass.on.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_vfiltered = _.sum(_.map(VFiltered, (item) => item.commission));

	return result_vfiltered;
};

//finance transaction time frame
export const getTransactionsTimeFrame = (arr: any[], time: string) => {
	let TBFiltered = [];

	switch (time) {
		case "today":
			TBFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.date.toDate())) === true
			);
			break;
		case "within-a-week":
			TBFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.date.toDate())) === true
			);
			break;
		case "within-a-month":
			TBFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.date.toDate())) === true
			);
			break;
		case "over-two-weeks":
			TBFiltered = _.filter(
				arr,
				(item) => isTwoWeeksOrMore(moment(item.date.toDate())) === true
			);
			break;

		default:
			break;
	}

	return TBFiltered;
};

export const getEarnedRevenueTimeFrame = (arr: any[], time: string) => {
	let EFiltered = [];

	switch (time) {
		case "today":
			EFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.delivered.date.toDate())) === true
			);
			break;
		case "within-a-week":
			EFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.delivered.date.toDate())) === true
			);
			break;
		case "within-a-month":
			EFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.delivered.date.toDate())) === true
			);
			break;
		case "over-two-weeks":
			EFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.delivered.date.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_efiltered = _.sum(_.map(EFiltered, (item) => item.subtotal));

	return result_efiltered;
};

export const getRefundsTimeFrame = (arr: any[], time: string) => {
	let RFiltered = [];

	switch (time) {
		case "today":
			RFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.refunded.date.toDate())) === true
			);
			break;
		case "within-a-week":
			RFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.refunded.date.toDate())) === true
			);
			break;
		case "within-a-month":
			RFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.refunded.date.toDate())) === true
			);
			break;
		case "over-two-weeks":
			RFiltered = _.filter(
				arr,
				(item) => isTwoWeeksOrMore(moment(item.refunded.date.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_rfiltered = _.sum(_.map(RFiltered, (item) => item.subtotal));

	return result_rfiltered;
};

export const getEkiojaVendorsUpcomingRevenueTimeFrame = (
	arr: any[],
	time: string
) => {
	let URFiltered = [];

	switch (time) {
		case "today":
			URFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.order_placed.date.toDate())) === true
			);
			break;
		case "within-a-week":
			URFiltered = _.filter(
				arr,
				(item) =>
					isWithinAWeek(moment(item.order_placed.date.toDate())) === true
			);
			break;
		case "within-a-month":
			URFiltered = _.filter(
				arr,
				(item) =>
					isWithinAMonth(moment(item.order_placed.date.toDate())) === true
			);
			break;
		case "over-two-weeks":
			URFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.order_placed.date.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_urfiltered = _.sum(_.map(URFiltered, (item) => item.subtotal));

	return result_urfiltered;
};

export const getEkiojaVendorsPendingClearanceRevenueTimeFrame = (
	arr: any[],
	time: string
) => {
	let PCFiltered = [];

	switch (time) {
		case "today":
			PCFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.out_for_delivery.date.toDate())) === true
			);
			break;
		case "within-a-week":
			PCFiltered = _.filter(
				arr,
				(item) =>
					isWithinAWeek(moment(item.out_for_delivery.date.toDate())) === true
			);
			break;
		case "within-a-month":
			PCFiltered = _.filter(
				arr,
				(item) =>
					isWithinAMonth(moment(item.out_for_delivery.date.toDate())) === true
			);
			break;
		case "over-two-weeks":
			PCFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.out_for_delivery.date.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_pcfiltered = _.sum(_.map(PCFiltered, (item) => item.subtotal));

	return result_pcfiltered;
};

export const getEkiojaVendorsEarnedRevenueTimeFrame = (
	arr: any[],
	time: string
) => {
	let EFiltered = [];

	switch (time) {
		case "today":
			EFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.delivered.date.toDate())) === true
			);
			break;
		case "within-a-week":
			EFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.delivered.date.toDate())) === true
			);
			break;
		case "within-a-month":
			EFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.delivered.date.toDate())) === true
			);
			break;
		case "over-two-weeks":
			EFiltered = _.filter(
				arr,
				(item) =>
					isTwoWeeksOrMore(moment(item.delivered.date.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_efiltered = _.sum(_.map(EFiltered, (item) => item.subtotal));

	return result_efiltered;
};

export const getEkiojaVendorsRefundsTimeFrame = (arr: any[], time: string) => {
	let RFiltered = [];

	switch (time) {
		case "today":
			RFiltered = _.filter(
				arr,
				(item) => isToday(moment(item.refunded.date.toDate())) === true
			);
			break;
		case "within-a-week":
			RFiltered = _.filter(
				arr,
				(item) => isWithinAWeek(moment(item.refunded.date.toDate())) === true
			);
			break;
		case "within-a-month":
			RFiltered = _.filter(
				arr,
				(item) => isWithinAMonth(moment(item.refunded.date.toDate())) === true
			);
			break;
		case "over-two-weeks":
			RFiltered = _.filter(
				arr,
				(item) => isTwoWeeksOrMore(moment(item.refunded.date.toDate())) === true
			);
			break;

		default:
			break;
	}

	const result_rfiltered = _.sum(_.map(RFiltered, (item) => item.subtotal));

	return result_rfiltered;
};
