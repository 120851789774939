import React from "react";
import ChannelController from "./ChannelController";

const Channel = () => {
	return (
		<div>
			<ChannelController />
		</div>
	);
};

export default Channel;
