import { useMutation, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

const approveStore = (values: any) => {
	return firebase.firestore().collection("Stores").doc(values.id).set(
		{
			approved: values.status,
			approved_by: values.by,
		},
		{ merge: true }
	);
};

const useApproveStore = () => {
	return useMutation(approveStore, {
		onSuccess: () => {
			queryCache.invalidateQueries("allStores");
		},
	});
};

export default useApproveStore;
