
import { ISignup } from '../../../../ts/interfaces/signup'
import { EMAIL_ALREADY_EXISTS, OPERATION_NOT_ALLOWED, INVALID_EMAIL, WEAK_PASSWORD } from '../../../../constants/signuperrors';
import { FEEDBACK } from '../../../../constants/feedback';

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const CreaNewEkiojaAdminstratorAccount = (signupdata: ISignup): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();
        try {
            //create account
            const response = await firebase.auth().createUserWithEmailAndPassword(signupdata.email, signupdata.password);

            //add displayName
            const updateProfilePromise = firebase.auth().currentUser.updateProfile({
                displayName: signupdata.first_name + ' ' + signupdata.last_name,
            });
            //create new doc in Admin collection
            const createCollectionPromise = firebase.firestore().collection('Admins').doc(response.user.uid).set({
                first_name: signupdata.first_name,
                last_name: signupdata.last_name,
                username: signupdata.username,
                role: signupdata.role,
                entity: signupdata.entity,
                class: signupdata.class,
                email: signupdata.email,
                approved: false,
                blocked: false,
                blocked_history: [],
                removed: false,
                removed_data: [],
                joined_on: firebase.firestore.FieldValue.serverTimestamp(),
            });

            //send email verification
            const sendEmailPromise = firebase.auth().currentUser.sendEmailVerification();

            //add as admistrator
            const addEkiojaAdminstrator = firebase.functions().httpsCallable('addEkiojaAdminstrator');

            const addEkiojaAdminstratorPromise = addEkiojaAdminstrator({ email: signupdata.email })

            //allpromise
            const allPromise = Promise.all([updateProfilePromise, addEkiojaAdminstratorPromise, createCollectionPromise, sendEmailPromise]);

            allPromise.then(() => {

            })


        } catch (error) {
          
            var errorCode = error.code;
            //    var errorMessage = error.message;

            switch (errorCode) {
                case EMAIL_ALREADY_EXISTS:
                    dispatch({ type: FEEDBACK, payload: { severity: 'error',title:'Email already exist', body: "Oops! An existing account is already using this email! Use another email" } })
                    break;
                case OPERATION_NOT_ALLOWED:
                    dispatch({ type: FEEDBACK, payload: { severity: 'error',title:'Operation not allowed', body: "Oops! The developers of this app did not activate this feature!A report would be sent to the owners " } })
                    break;
                case INVALID_EMAIL:
                    dispatch({ type: FEEDBACK, payload: { severity: 'error',title:'Invalid email address', body: "Oops! This email is invalid! Check the format and try again" } })
                    break;
                case WEAK_PASSWORD:
                    dispatch({ type: FEEDBACK, payload: { severity: 'warning',title:'Weak password', body: "Oops! Your password is too weak! try another password! " } })
                    break;
                default:
                    break;
            }
        }

    }