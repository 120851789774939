import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import useGetAllKYCReports from "../../../hooks/kyc/useGetAllKYCReports";
import _ from "lodash";
import moment from "moment";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";

import EligibilityReport from "./EligibilityReport";

import useDeleteKYCReport from "../../../hooks/kyc/useDeleteKYCReport";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			borderRadius: 20,
			width: "100%",
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		toolbar_wrapper: {
			// backgroundColor: '#303030',
			backgroundColor: theme.palette.background.default,
		},
		add_new_product_button: {
			marginTop: "5%",
		},
	})
);

const KYCReport = () => {
	const styles = useStyles();

	const allKYCReportsQuery = useGetAllKYCReports();

	const [DeleteKYCReportMutate, DeleteKYCReportInfo] = useDeleteKYCReport();

	const [eligibiltyReport, setEligibiltyReport] = useState<any>({});
	const [kycReports, setKycReports] = useState<any>([]);

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (allKYCReportsQuery.isSuccess) {
			const order_by_date_added = _.orderBy(
				allKYCReportsQuery.data,
				["added_on"],
				["desc"]
			);

			const data = _.map(order_by_date_added, (item) => {
				return {
					reprensentative_fullname: item.full_name,
					reprensentative_email: item.email,
					reprensentative_phone_number: item.phone_number,
					business_name: item.business_name,
					category_of_products: item.category_of_products?.join(" , "),
					added_on: moment(item.added_on.toDate()).fromNow(),
					createdon: moment(item.added_on.toDate()).format("MM/DD/YYYY"),
					verdict: item.verdict,
					data: item,
				};
			});
			setKycReports([...data]);
		}
	}, [allKYCReportsQuery.isSuccess, allKYCReportsQuery.data]);

	return (
		<Box className={styles.root} boxShadow={5}>
			<EligibilityReport
				data={eligibiltyReport}
				open={open}
				handleClose={handleClose}
			/>
			<MaterialTable
				style={{ width: "100%" }}
				isLoading={
					allKYCReportsQuery.isLoading || DeleteKYCReportInfo.isLoading
				}
				title={
					DeleteKYCReportInfo.isLoading
						? "Deleting..."
						: DeleteKYCReportInfo.isError
						? "Error deleting!"
						: DeleteKYCReportInfo.isSuccess && !DeleteKYCReportInfo.isIdle
						? "Delected!"
						: "KYC Reports"
				}
				columns={[
					{
						title: "Business name",
						field: "business_name",
						cellStyle: {
							backgroundColor: "rgba(34, 179, 88, 1)",
							color: "#FFF",
							padding: 10,
							width: 200,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 1)",
							color: "#FFF",
						},
					},
					{
						title: "Brand Representative",
						field: "reprensentative_fullname",
						cellStyle: {
							width: 200,
						},

						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "Email",
						field: "reprensentative_email",
						cellStyle: {
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "Phone number",
						field: "reprensentative_phone_number",
						cellStyle: {
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},

					{
						title: "Product Category",
						field: "category_of_products",
						cellStyle: {
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "Verdict",
						field: "verdict",
						render: (rowData) => (
							<>
								{rowData.verdict && (
									<CheckCircleTwoToneIcon
										style={{
											fontSize: 40,
											color: "#08c551",
											cursor: "pointer",
										}}
										onClick={() => {
											setEligibiltyReport({ ...rowData.data });
											handleClickOpen();
										}}
									/>
								)}
								{!rowData.verdict && (
									<CancelTwoToneIcon
										style={{ fontSize: 40, color: "red", cursor: "pointer" }}
										onClick={() => {
											setEligibiltyReport({ ...rowData.data });
											handleClickOpen();
										}}
									/>
								)}
							</>
						),
						cellStyle: {
							padding: 10,
							width: 60,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
						align: "center",
					},
					{
						title: "Added on",
						field: "added_on",
						render: (rowData) => (
							<Typography variant="caption">
								{rowData.added_on} <br /> {rowData.createdon}{" "}
							</Typography>
						),
						cellStyle: {
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{ title: "createdon", field: "createdon", hidden: true },
					{ title: "data", field: "data", hidden: true },
				]}
				data={kycReports}
				options={{
					search: true,
					exportAllData: true,
					exportButton: true,
					exportFileName: "kyc_report",

					selection: true,
				}}
				actions={[
					{
						tooltip: "Remove The Selected KYC Reports",
						icon: "delete",
						onClick: (evt, data) => {
							if (
								window.confirm(
									"Are you sure you want to delete the selected KYC Reports"
								)
							) {
								DeleteKYCReportMutate(data, {
									onSuccess: () => {
										setTimeout(() => {
											DeleteKYCReportInfo.reset();
										}, 600);
									},
								});
							}
						},
					},
				]}
			/>
		</Box>
	);
};

export default KYCReport;
