import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import KYCReport from "../components/DashBoardWidgets/KYCReport";
import FilterSortBrands from "../components/Reports/FilterSortBrands";
import DashBoardLayout from "../pages/Layouts/DashBoardLayout";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const KYCReports = () => {
	const styles = useStyles();
	return (
		<DashBoardLayout>
			<div className={styles.page_header}>
				<Typography variant="h4" color="primary">
					{" "}
					Reports
				</Typography>
			</div>
			<br />
			<div style={{ padding: 15 }}>
				<KYCReport />
				<br />
				<br />
				<FilterSortBrands />
			</div>
		</DashBoardLayout>
	);
};

export default KYCReports;
