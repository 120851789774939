import React, { lazy, Suspense, useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import MarketPlaceLayout from "./Layouts/MarketPlaceLayout";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";
//number format
import NumberFormat from "react-number-format";

// //navigation hook
import { useNavigate } from "../hooks/navigate";

// //routes
import * as ROUTES from "../constants/routes";

const CartList = lazy(() => import("../components/MarketPlace/CartList"));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    total_sum_wrapper: {
      width: "100%",
      height: "auto",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "baseline",
      marginBottom: 20,
    },
    total_price: {
      color: "green",
      fontSize: 30,
    },
    navigation_wrapper: {
      padding: 20,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      marginBottom: 20,
      width: "100%",
      height: "auto",
    },
  })
);

const MarketPlaceCart = () => {
  const styles = useStyles();
  const { navigateToLink } = useNavigate();
  const [productsData, setProductsData] = useState<any>([]);
  const [totalSumOfprice, setTotalSumOfprice] = useState(0);
  useFirestoreConnect([
    {
      collection: "Products",
    },
    {
      collection: "Cart",
      where: [["cart_customer_id", "==", "demo-customer"]],
      storeAs: "CustomerCart",
    },
  ]);

  const products = useSelector(
    (state: any) => state.firestore.ordered.Products
  );

  const cart = useSelector(
    (state: any) => state.firestore.data["CustomerCart"]
  );

  useEffect(() => {
    if (isLoaded(cart) && isLoaded(products)) {
      let cart_data: any[] = [];
      if (cart !== null) {
        for (var cartkey in cart) {
          cart_data.push({ ...cart[cartkey], cart_uuid: cartkey });
        }
      }

      var result_products_in_cart = _.intersectionWith(
        _.cloneDeep(products),
        cart_data,
        function (x: any, y: any) {
          return x.product_id === y.cart_product_id && _.assign(x, y);
        }
      );

      const sorted = _.orderBy(
        result_products_in_cart,
        ["cart_added_on"],
        ["desc"]
      );

      const result_total_cart_item_subtotal = _.sumBy(
        cart_data,
        (o: any) => o.cart_subtotal
      );

      setTotalSumOfprice(result_total_cart_item_subtotal);

      setProductsData([...sorted]);
    }
  }, [cart, products]);

  return (
    <MarketPlaceLayout>
      <Container>
        <div style={{ marginTop: 100 }} />
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Typography variant="h6">
              Cart ({productsData.length} items)
            </Typography>
            <Divider />
            <Box boxShadow={0} style={{ padding: 20 }}>
              <Suspense fallback={<div>Loading...</div>}>
                <CartList data={productsData} />
              </Suspense>
            </Box>
            <div className={styles.total_sum_wrapper}>
              <div>
                <div>
                  <Typography variant="caption">Total :</Typography>
                  <Typography variant="caption" className={styles.total_price}>
                    <NumberFormat
                      value={totalSumOfprice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption">
                    Delivery fee not included yet
                  </Typography>
                </div>
              </div>
            </div>

            <Box boxShadow={5} className={styles.navigation_wrapper}>
              <Button variant="contained" color="default">
                contibue shopping
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  navigateToLink.push(ROUTES.VIEW_MARKETPLACE_CHECKOUT)
                }
              >
                proceed to checkout
              </Button>
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Container>
    </MarketPlaceLayout>
  );
};

export default MarketPlaceCart;
