import React from "react";
import VendorListController from "./VendorListController";

const VendorList = () => {
	return (
		<div>
			<VendorListController />
		</div>
	);
};

export default VendorList;
