  
import { useMutation} from "react-query";
import firebase from '../../config/firebaseConfig';


const initiateBrandCreativeJob = (values:any)=>{

    return firebase.firestore().collection('CreativeJobs').doc().set({
        ...values,
        added_on:firebase.firestore.FieldValue.serverTimestamp()
    })
}


 const useInitiateBrandCreativeJob = ()=>{
    return useMutation(initiateBrandCreativeJob);
}


export default useInitiateBrandCreativeJob;