import { useMutation, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

interface Props {
	store_id: string;
	package: "starter" | "professional" | "advanced" | "premium";
	picked_package?: boolean;
	ekioja_admin_picked?: boolean;
	ekioja_admin_id?: string;
	picked_package_by_vendor_name?: string;
	picked_package_by_vendor_id?: string;
	is_starter_package?: boolean;
	is_professional_package?: boolean;
	is_advanced_package?: boolean;
	is_premium_package?: boolean;
}

const pickPackage = (values: Props) => {
	//starter package
	if (values.package === "starter") {
		return firebase.firestore().collection("Stores").doc(values.store_id).set(
			{
				picked_package: values.picked_package,
				ekioja_admin_picked: values.ekioja_admin_picked,
				ekioja_admin_id: values.ekioja_admin_id,
				is_starter_package: values.is_starter_package,
				is_professional_package: false,
				is_advanced_package: false,
				is_premium_package: false,
				picked_package_date: firebase.firestore.FieldValue.serverTimestamp(),
			},
			{ merge: true }
		);
	}
	//professional package
	if (values.package === "professional") {
		return firebase.firestore().collection("Stores").doc(values.store_id).set(
			{
				picked_package: values.picked_package,
				ekioja_admin_picked: values.ekioja_admin_picked,
				ekioja_admin_id: values.ekioja_admin_id,
				is_professional_package: values.is_professional_package,
				is_starter_package: false,
				is_advanced_package: false,
				is_premium_package: false,
				picked_package_date: firebase.firestore.FieldValue.serverTimestamp(),
			},
			{ merge: true }
		);
	}
	//advanced package
	if (values.package === "advanced") {
		return firebase.firestore().collection("Stores").doc(values.store_id).set(
			{
				picked_package: values.picked_package,
				ekioja_admin_picked: values.ekioja_admin_picked,
				ekioja_admin_id: values.ekioja_admin_id,
				is_advanced_package: values.is_advanced_package,
				is_starter_package: false,
				is_professional_package: false,
				is_premium_package: false,
				picked_package_date: firebase.firestore.FieldValue.serverTimestamp(),
			},
			{ merge: true }
		);
	}
	//premium package
	if (values.package === "premium") {
		return firebase.firestore().collection("Stores").doc(values.store_id).set(
			{
				picked_package: values.picked_package,
				ekioja_admin_picked: values.ekioja_admin_picked,
				ekioja_admin_id: values.ekioja_admin_id,
				is_premium_package: values.is_premium_package,
				is_starter_package: false,
				is_professional_package: false,
				is_advanced_package: false,
				picked_package_date: firebase.firestore.FieldValue.serverTimestamp(),
			},
			{ merge: true }
		);
	}

	return firebase.firestore().collection("Stores").doc(values.store_id).set(
		{
			picked_package: values.picked_package,
			ekioja_admin_picked: values.ekioja_admin_picked,
			ekioja_admin_id: values.ekioja_admin_id,
			no_package: true,
			is_starter_package: false,
			is_professional_package: false,
			is_advanced_package: false,
			is_premium_package: false,
			picked_package_date: firebase.firestore.FieldValue.serverTimestamp(),
		},
		{ merge: true }
	);
};

const usePickPackage = () => {
	return useMutation(pickPackage, {
		onSuccess: () => {
			queryCache.invalidateQueries("allStores");
		},
	});
};

export default usePickPackage;
