import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { isLoaded } from "react-redux-firebase";
import _ from "lodash";
import moment from "moment";
//material table
import MaterialTable from "material-table";

const VettedStores = () => {
	const [tableData, setTableData] = useState<any>([]);

	const refTableContainer = useRef<any>(null);

	useFirestoreConnect([
		{
			collection: "Admins",
		},
		{
			collection: "Stores",
			where: [["vetted", "==", true]],
			storeAs: "VettedStores",
		},
	]);

	const vettedStores = useSelector(
		(state: any) => state.firestore.data["VettedStores"]
	);
	const admins = useSelector((state: any) => state.firestore.ordered.Admins);

	useEffect(() => {
		if (isLoaded(vettedStores) && isLoaded(admins)) {
			let vetted_store_data: any[] = [];
			if (vettedStores !== null) {
				for (var vetted_store_key in vettedStores) {
					vetted_store_data.push({
						...vettedStores[vetted_store_key],
						vetted_store_uuid: vetted_store_key,
					});
				}
			}

			const all_same_docs_in_stores_and_admins = _.intersectionWith(
				_.cloneDeep(admins),
				vetted_store_data,
				function (x: any, y: any) {
					return (
						x.entity === y.unique_id &&
						x.role === "Administrator" &&
						_.assign(x, y)
					);
				}
			);

			const sortedFilteredStores = _.orderBy(
				all_same_docs_in_stores_and_admins,
				["date_added"],
				["desc"]
			);

			const result = _.map(sortedFilteredStores, (item: any) => {
				return {
					business_name: item.business_name,
					business_niche: item.business_niche.join(" , "),
					business_location: item.business_location?.street_address_1,
					date_added: moment(item.date_added?.toDate()).fromNow(),
					representative: `${item.first_name}  ${item.last_name} | ${item.contact_phone_number} | ${item.email}`,
				};
			});

			setTableData([...result]);
		}
	}, [vettedStores, admins]);

	return (
		<div>
			<div style={{ margin: 20 }} ref={refTableContainer}>
				<MaterialTable
					style={{
						width: refTableContainer.current
							? refTableContainer.current.offsetWidth - 35
							: "100%",
					}}
					isLoading={isLoaded(vettedStores) && isLoaded(admins) ? false : true}
					title="Vetted Store Data"
					columns={[
						{
							title: "Store name",
							field: "business_name",
							cellStyle: {
								backgroundColor: "rgba(34, 179, 88, 1)",
								color: "#FFF",
								padding: 10,
							},
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 1)",
								color: "#FFF",
							},
						},
						{
							title: "Address",
							field: "business_location",
							cellStyle: {
								width: 200,
							},

							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
						},
						{
							title: "Niche",
							field: "business_niche",
							cellStyle: {
								padding: 10,
							},
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
						},
						{
							title: "Representative",
							field: "representative",
							cellStyle: {
								width: 300,
							},
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
						},

						{
							title: "Joined",
							field: "date_added",
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
						},
					]}
					data={tableData}
					options={{
						search: true,
						// actionsColumnIndex: -1,
						exportAllData: true,
						exportButton: true,
						exportFileName: "vetted_vendors_data",
						rowStyle: {
							borderLeft: "6px solid green",
						},
					}}
				/>
			</div>
		</div>
	);
};

export default VettedStores;
