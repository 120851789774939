import React from "react";

import Button from "@material-ui/core/Button";

import Logo from "../../assets/images/svg/ekioja-logo.svg";

import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

const Home = () => {
	return (
		<div>
			<div
				style={{
					width: "100%",
					height: "100vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "green",
				}}
			>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<img style={{ width: 400 }} src={Logo} alt="Ekioja" />
					<br />
					<p>
						<i>... The home of African products</i>
					</p>
					<div
						style={{
							width: 400,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Link component={Button} to={ROUTES.LOGIN}>
							Login
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
