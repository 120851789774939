import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import "./assets/main.css";
import App from "./App";
//route
import { BrowserRouter as Router } from "react-router-dom";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { store, rrfConfig } from "../src/store";
import firebase from "../src/config/firebaseConfig";
import { Provider } from "react-redux";
import { createFirestoreInstance } from "redux-firestore";

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Provider store={store}>
				<ReactReduxFirebaseProvider
					firebase={firebase}
					config={rrfConfig}
					dispatch={store.dispatch}
					createFirestoreInstance={createFirestoreInstance}
				>
					<App />
				</ReactReduxFirebaseProvider>
			</Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
