import React from "react";
//components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";

//styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

//formik
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";

//yup
import * as yup from "yup";

//images
import Logo from "../../../assets/images/svg/ekioja-logo.svg";

//login action
import { SendPasswordReset } from "../../../store/actions/auth/forgotpassword";

//react - redux
import { useDispatch, useSelector } from "react-redux";

//custom Alert
import MKVAlert from "../../../components/FeedBack/MKVAlert";

//feedback type
import { FEEDBACK } from "../../../constants/feedback";

//firebase
// import firebase from '../../../config/firebaseConfig';

//react router dom
import { Link } from "react-router-dom";

//signup yup schema
const SignupValidationSchema = yup.object().shape({
	email: yup.string().email().required("Your email is Required"),
});

//styles functions
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "99vw",
			backgroundColor: "green",
			paddingTop: 20,
			paddingBottom: 60,
		},
		form_wrapper: {
			padding: 20,
			borderRadius: 20,
			width: "100%",
			height: "auto",
			backgroundColor: "white",
		},
	})
);

const ForgotPassword = () => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const feedback = useSelector((state: any) => state.feedBack.info);
	const clearInfo = () =>
		dispatch({
			type: FEEDBACK,
			payload: { severity: "", title: "", body: "" },
		});

	return (
		<div className={styles.root}>
			{feedback.severity === "error" ||
			feedback.severity === "warning" ||
			feedback.severity === "info" ||
			feedback.severity === "success" ? (
				<MKVAlert
					severity={feedback.severity}
					title={feedback.title}
					body={feedback.body}
					show={true}
					hide={clearInfo}
				/>
			) : null}
			<Container>
				<Grid container>
					<Grid item xs={12} lg={4}></Grid>
					<Grid item xs={12} lg={4}>
						<div
							style={{
								height: 200,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<img src={Logo} alt="ekioja" style={{ width: 150 }} />
						</div>

						<Box boxShadow={5} className={styles.form_wrapper}>
							<Formik
								validationSchema={SignupValidationSchema}
								initialValues={{
									email: "",
								}}
								onSubmit={(values, { setSubmitting, resetForm }) => {
									setTimeout(() => {
										setSubmitting(false);
										dispatch(SendPasswordReset(values));
									}, 500);
								}}
							>
								{({ submitForm, isSubmitting, resetForm }) => (
									<Form>
										{isSubmitting && <LinearProgress />}

										<br />
										<br />

										<Field
											component={TextField}
											name="email"
											type="email"
											label="Email"
											fullWidth
										/>
										<br />
										<br />

										<Button
											variant="contained"
											style={{ backgroundColor: "#4caf50", color: "white" }}
											disabled={isSubmitting}
											onClick={submitForm}
										>
											Send password reset link
										</Button>

										<br />
										<br />
										<Link to="/">
											<Button>Remembered your password?</Button>
										</Link>
									</Form>
								)}
							</Formik>
						</Box>
					</Grid>
					<Grid item xs={12} lg={4}></Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default ForgotPassword;
