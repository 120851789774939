import { IOrder } from "../../../../ts/interfaces/order";
import { SendNotification } from "../../../actions/notification/sendNotificationAction";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import _ from "lodash";
import {CustomerOrderPayment ,AddToWallet} from '../../../actions/transactions'

export const NewOrder = (
  values: IOrder
): ThunkAction<Promise<void>, {}, {}, AnyAction> => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: any,
  { getFirebase }: any
): Promise<void> => {
  const firebase = getFirebase();
  try {
    //empty cart
    const cartPromises: any = [];

    for (const cart of values.items) {
      const p = firebase
        .firestore()
        .collection(`Cart`)
        .doc(cart.cart_uuid)
        .update({ cart_closed: true });

      cartPromises.push(p);
    }

    //add order to db
    const addOrderToDBPromise = firebase
      .firestore()
      .collection("Orders")
      .doc()
      .set({
        ...values,
        added_on: firebase.firestore.FieldValue.serverTimestamp(),
      });

    //add orderTimeline

    const orderTimelinePromises:any = [];
    for(const orderTimelineItem of values.items){
      const orderp = firebase
      .firestore()
      .collection("OrdersTimeline")
      .doc()
      .set({
        the_order_id:values.order_id,
        order_customer_id:values.customer_id,
        order_product_id:orderTimelineItem.product_id,
        order_store_id:orderTimelineItem.entity,
        order_placed:{
            status:true,
            current:true,
            date:firebase.firestore.FieldValue.serverTimestamp()
        },
        item_subtotal:orderTimelineItem.subtotal,
        item_quantity:orderTimelineItem.quantity,
        booked:false,
        added_on: firebase.firestore.FieldValue.serverTimestamp(),
      });

      orderTimelinePromises.push(orderp);
    }
    

    //send notification to vendors
    let noDuplicateStoreIds = _.uniq(values.store_ids);
    let notificationPromises: any = [];
    for (let index = 0; index < noDuplicateStoreIds.length; index++) {
      var store_id = noDuplicateStoreIds[index];

      var p = dispatch(
        SendNotification({
          to: store_id,
          severity: "success",
          subject: "You have a new order!",
          body: `Order number: ${values.order_id} , you need to process it as soon as possible`,
        })
      );

      notificationPromises.push(p);
    }

    const sendNotificationToCustomer = dispatch(
      SendNotification({
        to: values.customer_id,
        severity: "success",
        subject: "Your order will be processed as soon as possible!",
        body: `Order number: ${values.order_id} , you need to process it fast`,
      })
    );


   //record transaction

   const orderTransactionpromise =  dispatch(CustomerOrderPayment(values.customer_id,values.recieptNum,values.payment_total,values.order_id,values.customer_email));

   //run transaction on Ekioja balance
   const transactionpromise = dispatch(AddToWallet(process.env.REACT_APP_EKIOJA_WALLET,values.payment_total));

    //@ts-ignore
    Promise.all([
      ...notificationPromises,
      addOrderToDBPromise,
      sendNotificationToCustomer,
      ...cartPromises,
      ...orderTimelinePromises,
      orderTransactionpromise,
      transactionpromise
    ]).then(() => {
      dispatch({
        type: "FEEDBACK",
        payload: { severity: "success", title: "Order confirmed!", body: "" },
      });
    });
  } catch (error) {
    console.log(error.code);
  }
};
