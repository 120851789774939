import { useMutation, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

const updateBlockStatus = (values: any) => {
	return firebase.firestore().collection("Stores").doc(values.id).set(
		{
			blocked: values.status,
			blocked_status_by: values.by,
		},
		{ merge: true }
	);
};

const useUpdateBlockStatus = () => {
	return useMutation(updateBlockStatus, {
		onSuccess: () => {
			queryCache.invalidateQueries("allStores");
		},
	});
};

export default useUpdateBlockStatus;
