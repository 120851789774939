import React, { Fragment, useState, useEffect, useRef } from "react";
//styles
import {
	createStyles,
	makeStyles,
	Theme,
	withStyles,
} from "@material-ui/core/styles";

//material table
import MaterialTable from "material-table";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

//icons
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@material-ui/icons/VisibilityOffTwoTone";
// import StarTwoToneIcon from "@material-ui/icons/StarTwoTone";
// import StarBorderTwoToneIcon from "@material-ui/icons/StarBorderTwoTone";
import RateReviewIcon from "@material-ui/icons/RateReview";
import EditIcon from "@material-ui/icons/Edit";
import StarsTwoToneIcon from "@material-ui/icons/StarsTwoTone";
import StarHalfTwoToneIcon from "@material-ui/icons/StarHalfTwoTone";
import StoreMallDirectoryTwoToneIcon from "@material-ui/icons/StoreMallDirectoryTwoTone";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//link
import { useParams } from "react-router-dom";

//number format
import NumberFormat from "react-number-format";

//react - redux
import { useDispatch } from "react-redux";

//utils
import { getProductCounts, getProducts } from "../utils/index";

import _ from "lodash";

//routes
import * as ROUTES from "../constants/routes";

//navigation hook
import { useNavigate } from "../hooks/navigate";

import { HideProductFromMarket } from "../store/actions/products/hideProduct";
import { ShowProductOnMarket } from "../store/actions/products/showproduct";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import HotProducts from "../components/Product/HotProducts/HotProducts";
import TopPickProducts from "../components/Product/TopPickProducts/TopPickProducts";

import ReviewProductModal from "../components/Product/Assessment/ReviewProductModal";
import ViewProductModal from "../components/Product/Assessment/ViewProductModal";
import EditProductModal from "../components/Product/Assessment/EditProductModal";

import { Scrollbars } from "rc-scrollbars";

import useGetAllProducts from "../hooks/Product/useGetAllProducts";

const AntTabs = withStyles({
	root: {
		borderBottom: "1px solid #99ff99",
	},
	indicator: {
		backgroundColor: "#99ff99",
	},
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
	createStyles({
		root: {
			textTransform: "none",
			minWidth: 72,
			fontWeight: theme.typography.fontWeightRegular,
			marginRight: theme.spacing(4),
			fontFamily: [
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
			"&:hover": {
				color: "#99ff99",
				opacity: 1,
			},
			"&$selected": {
				color: "#99ff99",
				fontWeight: theme.typography.fontWeightMedium,
			},
			"&:focus": {
				color: "#99ff99",
			},
		},
		selected: {},
	})
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
	label: string;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`product-tabpanel-${index}`}
			aria-labelledby={`product-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

// function a11yProps(index: any) {
// 	return {
// 		id: `product-tab-${index}`,
// 		"aria-controls": `product-tabpanel-${index}`,
// 	};
// }

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			borderRadius: 20,
			width: "100%",
		},
		appBar: {
			position: "relative",
			backgroundColor: "#fff",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		toolbar_wrapper: {
			// backgroundColor: '#303030',
			backgroundColor: theme.palette.background.default,
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		product_image_wrapper: {
			"& img": {
				width: 40,
				height: 40,
				border: "1px white solid",
			},
		},
		add_new_product_button: {
			marginTop: "5%",
		},
		queries_wrapper: {
			width: 1000,
			padding: 2,
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		detail_panel_wrapper: {
			padding: 10,
		},
		list_wrapper: {
			maxWidth: "93.1vw",
			height: 65,
			marginBottom: 5,
			whiteSpace: "nowrap",
			WebkitOverflowScrolling: "touch",
			msOverflowStyle: "-ms-autohiding-scrollbar",
			overflowY: "hidden",
			overflowX: "auto",
			display: "inline-block",
			verticalAlign: "middle",
			padding: 10,
			"&::-webkit-scrollbar": {
				width: "5px",
			},
			"&::-webkit-scrollbar-track": {
				background: "#f1f1f1",
			},
			"&::-webkit-scrollbar-thumb": {
				background: "rgba(18, 175, 10, 0.1)",
				borderRadius: 10,
			},
			"&::-webkit-scrollbar-thumb:hover": {
				background: "rgba(18, 175, 10, 0.3)",
				borderRadius: 10,
			},
		},
		list_item_wrapper: {
			// width: 250,
			// height: 250,
			marginRight: 10,
			display: "inline-block",
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
		price: {
			color: "green",
			marginRight: 10,
		},
		discount_price: {
			textDecoration: "line-through",
			color: "rgba(194, 188, 188, 0.7)",
		},
		discount_percent: {
			backgroundColor: "green",
			width: 35,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			color: "#fff",
			marginLeft: 10,
			padding: 4,
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Products = (props: Props) => {
	const { query } = useParams<any>();

	const [openModal, setOpenModal] = React.useState(false);

	const handleClickOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const [productModalData, setProductModalData] = useState<any>({});
	const [productModalTitle, setProductModalTitle] = useState("");

	const [tabValue, setTabValue] = React.useState(0);

	const handlesetTabValueChange = (
		event: React.ChangeEvent<{}>,
		newValue: number
	) => {
		setTabValue(newValue);
	};

	const styles = useStyles();

	const dispatch = useDispatch();

	const [productData, setProductData] = useState<any>([]);
	const [tableTitle, setTableTitle] = useState("");

	const { navigateToLink } = useNavigate();

	const refTableContainer = useRef<any>(null);

	const allProductsQuery = useGetAllProducts();

	const storeProducts = allProductsQuery.isSuccess ? allProductsQuery.data : [];

	useEffect(() => {
		if (allProductsQuery.isSuccess) {
			const result_all_products = getProducts(
				storeProducts,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS
			);
			const result_all_active_products = getProducts(
				storeProducts,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_ACTIVE_PRODUCTS
			);
			const result_all_in_active_products = getProducts(
				storeProducts,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_IN_ACTIVE_PRODUCTS
			);
			const result_all_featured_products = getProducts(
				storeProducts,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_FEATURED_PRODUCTS
			);
			const result_all_underassessment_products = getProducts(
				storeProducts,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_ASSESSMENT
			);
			const result_all_to_be_resolved_products = getProducts(
				storeProducts,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_TO_BE_RESOLVED
			);
			const result_all_promoted_products = getProducts(
				storeProducts,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_PROMOTED_PRODUCTS
			);
			const result_all_low_range_products = getProducts(
				storeProducts,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_LOW_PRICE_RANGE_PRODUCTS
			);
			const result_all_high_range_products = getProducts(
				storeProducts,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_HIGH_PRICE_RANGE_PRODUCTS
			);

			switch (query) {
				case ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS:
					setProductData([...result_all_products]);

					setTableTitle("All Products");
					break;

				case ROUTES.VIEWPRODUCTSQUERIES.ALL_ACTIVE_PRODUCTS:
					setProductData([...result_all_active_products]);

					setTableTitle("All Active Products");
					break;
				case ROUTES.VIEWPRODUCTSQUERIES.ALL_IN_ACTIVE_PRODUCTS:
					setProductData([...result_all_in_active_products]);

					setTableTitle("All In-Active Products");
					break;
				case ROUTES.VIEWPRODUCTSQUERIES.ALL_FEATURED_PRODUCTS:
					setProductData([...result_all_featured_products]);

					setTableTitle("All Featured Products");
					break;
				case ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_ASSESSMENT:
					setProductData([...result_all_underassessment_products]);

					setTableTitle("All To Be Products");
					break;
				case ROUTES.VIEWPRODUCTSQUERIES.ALL_TO_BE_RESOLVED:
					setProductData([...result_all_to_be_resolved_products]);

					setTableTitle("All To Be Resolved Products");
					break;
				case ROUTES.VIEWPRODUCTSQUERIES.ALL_PROMOTED_PRODUCTS:
					setProductData([...result_all_promoted_products]);

					setTableTitle("All Promoted Products");
					break;
				case ROUTES.VIEWPRODUCTSQUERIES.ALL_LOW_PRICE_RANGE_PRODUCTS:
					setProductData([...result_all_low_range_products]);

					setTableTitle("All  Products less than ₦10,000");
					break;
				case ROUTES.VIEWPRODUCTSQUERIES.ALL_HIGH_PRICE_RANGE_PRODUCTS:
					setProductData([...result_all_high_range_products]);

					setTableTitle("All  Products greater than ₦10,000");
					break;

				default:
					setProductData([...result_all_products]);

					setTableTitle("All Products");
			}
		}
	}, [storeProducts, query, allProductsQuery.isSuccess]);

	const hasNumber = (myString: string) => {
		return /\d/.test(myString);
	};

	return (
		<Fragment>
			<DashBoardLayout menuIndex={8}>
				<Dialog
					fullScreen
					open={openModal}
					onClose={handleCloseModal}
					TransitionComponent={Transition}
				>
					<AppBar className={styles.appBar} elevation={0}>
						<Toolbar>
							<IconButton
								edge="start"
								color="primary"
								onClick={handleCloseModal}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography
								variant="h6"
								className={styles.title}
								color="textPrimary"
							>
								{productModalTitle === "view-product" && "View Product"}
								{productModalTitle === "review-product" && "Review Product"}
								{productModalTitle === "edit-product" && "Edit Product"}
							</Typography>
						</Toolbar>
					</AppBar>
					{productModalTitle === "edit-product" && (
						<EditProductModal productData={productModalData} />
					)}
					{productModalTitle === "view-product" && (
						<ViewProductModal productData={productModalData} />
					)}

					{productModalTitle === "review-product" && (
						<ReviewProductModal
							data={productModalData}
							handleCloseModal={handleCloseModal}
						/>
					)}
				</Dialog>
				<div className={styles.page_header}>
					<Typography variant="h4" color="primary">
						{" "}
						Products Management
					</Typography>
				</div>
				<br />
				<AntTabs
					value={tabValue}
					onChange={handlesetTabValueChange}
					aria-label="ant example"
				>
					<AntTab label="Products" />
					<AntTab label="Hot products" />
					<AntTab label="Top Products" />
				</AntTabs>
				<TabPanel value={tabValue} index={0}>
					<div>
						<Scrollbars
							style={{
								width: "100%",
								height: 50,
								marginTop: 10,
								marginBottom: 10,
							}}
							autoHide
							autoHideTimeout={1000}
						>
							<div className={styles.queries_wrapper}>
								<Chip
									label={`All Products (${getProductCounts(
										storeProducts,
										ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS
									)})`}
									variant="default"
									onClick={() => {
										setProductData([
											...getProducts(
												storeProducts,
												ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS
											),
										]);

										setTableTitle("All Products");
									}}
								/>
								<Chip
									label={`All Active Products (${getProductCounts(
										storeProducts,
										ROUTES.VIEWPRODUCTSQUERIES.ALL_ACTIVE_PRODUCTS
									)})`}
									variant="default"
									onClick={() => {
										setProductData([
											...getProducts(
												storeProducts,
												ROUTES.VIEWPRODUCTSQUERIES.ALL_ACTIVE_PRODUCTS
											),
										]);

										setTableTitle("All Active Products");
									}}
								/>
								<Chip
									label={`All Inactive Products (${getProductCounts(
										storeProducts,
										ROUTES.VIEWPRODUCTSQUERIES.ALL_IN_ACTIVE_PRODUCTS
									)})`}
									variant="default"
									onClick={() => {
										setProductData([
											...getProducts(
												storeProducts,
												ROUTES.VIEWPRODUCTSQUERIES.ALL_IN_ACTIVE_PRODUCTS
											),
										]);

										setTableTitle("All Inactive Products");
									}}
								/>
								<Chip
									label={`All To Be Reviewed (${getProductCounts(
										storeProducts,
										ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_ASSESSMENT
									)})`}
									variant="default"
									onClick={() => {
										setProductData([
											...getProducts(
												storeProducts,
												ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_ASSESSMENT
											),
										]);

										setTableTitle("All To Be Assessed");
									}}
								/>
								<Chip
									label={`All To Be Resolved (${getProductCounts(
										storeProducts,
										ROUTES.VIEWPRODUCTSQUERIES.ALL_TO_BE_RESOLVED
									)})`}
									variant="default"
									onClick={() => {
										setProductData([
											...getProducts(
												storeProducts,
												ROUTES.VIEWPRODUCTSQUERIES.ALL_TO_BE_RESOLVED
											),
										]);

										setTableTitle("All To Be Resolved");
									}}
								/>
								<Chip
									label={`All Promoted Products (${getProductCounts(
										storeProducts,
										ROUTES.VIEWPRODUCTSQUERIES.ALL_PROMOTED_PRODUCTS
									)})`}
									variant="default"
									onClick={() => {
										setProductData([
											...getProducts(
												storeProducts,
												ROUTES.VIEWPRODUCTSQUERIES.ALL_PROMOTED_PRODUCTS
											),
										]);

										setTableTitle("All Promoted Products");
									}}
								/>
								<Chip
									label={`All  Products < ₦10,000 (${getProductCounts(
										storeProducts,
										ROUTES.VIEWPRODUCTSQUERIES.ALL_LOW_PRICE_RANGE_PRODUCTS
									)})`}
									variant="default"
									onClick={() => {
										setProductData([
											...getProducts(
												storeProducts,
												ROUTES.VIEWPRODUCTSQUERIES.ALL_LOW_PRICE_RANGE_PRODUCTS
											),
										]);

										setTableTitle("All Products < ₦10,000");
									}}
								/>
								<Chip
									label={`All  Products > ₦10,000 (${getProductCounts(
										storeProducts,
										ROUTES.VIEWPRODUCTSQUERIES.ALL_HIGH_PRICE_RANGE_PRODUCTS
									)})`}
									variant="default"
									onClick={() => {
										setProductData([
											...getProducts(
												storeProducts,
												ROUTES.VIEWPRODUCTSQUERIES.ALL_HIGH_PRICE_RANGE_PRODUCTS
											),
										]);

										setTableTitle("All Products > ₦10,000");
									}}
								/>
							</div>
						</Scrollbars>
					</div>

					<div className={styles.root} ref={refTableContainer}>
						<MaterialTable
							style={{
								width: refTableContainer.current
									? refTableContainer.current.offsetWidth - 35
									: "100%",
							}}
							isLoading={
								allProductsQuery.isLoading || allProductsQuery.isFetching
							}
							title={tableTitle}
							columns={[
								{
									title: "Image",
									field: "photo",
									render: (rowData) => (
										<div className={styles.product_image_wrapper}>
											<img src={rowData.photo} alt="product logo" />
										</div>
									),
								},
								{
									title: "Vendor",
									field: "store_name",
									cellStyle: {
										backgroundColor: "rgba(34, 179, 88, 1)",
										color: "#FFF",
										padding: 10,
									},
									headerStyle: {
										backgroundColor: "rgba(34, 179, 88, 1)",
										color: "#FFF",
									},
									render: (rowData) => (
										<Typography variant="caption">
											{rowData.store_name}
										</Typography>
									),
									align: "center",
								},
								{
									title: "Product Name",
									field: "productname",
									headerStyle: {
										backgroundColor: "rgba(34, 179, 88, 0.5)",
										color: "#FFF",
									},
									render: (rowData) => (
										<Typography variant="caption">
											{rowData.productname}
										</Typography>
									),
								},
								{
									title: "Category",
									field: "category",
									headerStyle: {
										backgroundColor: "rgba(34, 179, 88, 0.5)",
										color: "#FFF",
									},
									render: (rowData) => (
										<Typography variant="caption">
											{rowData.category}
										</Typography>
									),
								},
								{
									title: "Price",
									field: "price",
									type: "numeric",
									headerStyle: {
										backgroundColor: "rgba(34, 179, 88, 0.5)",
										color: "#FFF",
									},
									render: (rowData) => (
										<>
											{rowData.data.enable_discount === false && (
												<Typography variant="h6" className={styles.price}>
													<NumberFormat
														value={rowData.data.regular_price}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"₦"}
													/>
												</Typography>
											)}

											{rowData.data.enable_discount &&
												rowData.data.enable_discount_schedule === true &&
												rowData.data.discount_price_to_date !== null && (
													<>
														<Typography variant="h6" className={styles.price}>
															<NumberFormat
																value={rowData.data.discount_price}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"₦"}
															/>
														</Typography>
														<div
															style={{
																display: "flex",
																justifyContent: "flex-start",
																alignItems: "center",
															}}
														>
															<Typography
																variant="subtitle2"
																className={styles.discount_price}
															>
																<NumberFormat
																	value={rowData.data.regular_price}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"₦"}
																/>
															</Typography>
															<div className={styles.discount_percent}>
																{`-${rowData.data.discount_percent}`}
															</div>
														</div>
													</>
												)}

											{rowData.data.enable_discount === true &&
												rowData.data.enable_discount_schedule === false && (
													<>
														<Typography variant="h6" className={styles.price}>
															<NumberFormat
																value={rowData.data.discount_price}
																displayType={"text"}
																thousandSeparator={true}
																prefix={"₦"}
															/>
														</Typography>
														<div
															style={{
																display: "flex",
																justifyContent: "flex-start",
																alignItems: "center",
															}}
														>
															<Typography
																variant="subtitle2"
																className={styles.discount_price}
															>
																<NumberFormat
																	value={rowData.data.regular_price}
																	displayType={"text"}
																	thousandSeparator={true}
																	prefix={"₦"}
																/>
															</Typography>
															<div className={styles.discount_percent}>
																{`-${rowData.data.discount_percent}`}
															</div>
														</div>
													</>
												)}
										</>
									),
									align: "center",
								},
								{
									title: "Stock",
									field: "stock",
									headerStyle: {
										backgroundColor: "rgba(34, 179, 88, 0.5)",
										color: "#FFF",
									},
									render: (rowData) =>
										hasNumber(rowData.stock) ? (
											_.has(rowData.data, "out_of_stock") &&
											rowData.data.out_of_stock === true ? (
												<Chip
													icon={<StoreMallDirectoryTwoToneIcon />}
													label="Out of Stock"
													variant="outlined"
													color="secondary"
													style={{
														color: "#B33022",
														border: "1px #B33022 solid",
													}}
												/>
											) : (
												<Typography variant="caption">
													{rowData.stock}
												</Typography>
											)
										) : rowData.stock === "In stock" ? (
											<Chip
												icon={<StoreMallDirectoryTwoToneIcon />}
												label={rowData.stock}
												variant="outlined"
												color="secondary"
											/>
										) : (
											<Chip
												icon={<StoreMallDirectoryTwoToneIcon />}
												label={rowData.stock}
												variant="outlined"
												color="secondary"
												style={{ color: "red", border: "1px red solid" }}
											/>
										),
								},
								{
									title: "Status",
									field: "status",
									type: "boolean",
									headerStyle: {
										backgroundColor: "rgba(34, 179, 88, 0.5)",
										color: "#FFF",
									},
									render: (rowData) => (
										<Fragment>
											{!rowData.status &&
												!rowData.to_be_reviewed &&
												!rowData.data.under_creatives_process && (
													<Chip
														icon={<VisibilityOffTwoToneIcon />}
														label="In-active"
														variant="outlined"
														color="secondary"
													/>
												)}
											{rowData.status && (
												<Chip
													icon={<VisibilityTwoToneIcon />}
													label="Active"
													variant="outlined"
													color="secondary"
												/>
											)}
											{rowData.to_be_reviewed && (
												<Chip
													icon={<RateReviewIcon style={{ color: "orange" }} />}
													label="Under Review"
													variant="outlined"
													style={{
														color: "orange",
														border: "1px orange solid",
													}}
												/>
											)}
											{rowData.to_be_resolved && (
												<Chip
													icon={<EditIcon style={{ color: "red" }} />}
													label="To Be Resolved"
													variant="outlined"
													style={{ color: "red", border: "1px red solid" }}
												/>
											)}

											{rowData.data.under_creatives_process && (
												<Chip
													icon={<BubbleChartIcon style={{ color: "orange" }} />}
													label="Rebranding"
													variant="outlined"
													style={{
														color: "orange",
														border: "1px orange solid",
													}}
												/>
											)}
										</Fragment>
									),
								},
								//   {
								//     title: "Featured",
								//     field: "featured",
								//     type: "boolean",
								//     render: (rowData) =>
								//       rowData.featured ? (
								//         <Chip
								//           icon={<StarTwoToneIcon />}
								//           label="Yes"
								//           variant="outlined"
								//           color="secondary"
								//         />
								//       ) : (
								//         <Chip
								//           icon={<StarBorderTwoToneIcon />}
								//           label="No"
								//           variant="outlined"
								//           color="secondary"
								//           style={{ color: "red", border: "1px red solid" }}
								//         />
								//       ),
								//   },
								// {
								// 	title: "Promoted",
								// 	field: "promoted",
								// 	type: "boolean",
								// 	headerStyle: {
								// 		backgroundColor: "rgba(34, 179, 88, 0.5)",
								// 		color: "#FFF",
								// 	},
								// 	render: (rowData) =>
								// 		rowData.promoted ? (
								// 			<Chip
								// 				icon={<StarsTwoToneIcon />}
								// 				label="Yes"
								// 				variant="outlined"
								// 				color="secondary"
								// 			/>
								// 		) : (
								// 			<Chip
								// 				icon={<StarHalfTwoToneIcon />}
								// 				label="No"
								// 				variant="outlined"
								// 				color="secondary"
								// 				style={{ color: "red", border: "1px red solid" }}
								// 			/>
								// 		),
								// },
								{
									title: "Added",
									field: "added",
									headerStyle: {
										backgroundColor: "rgba(34, 179, 88, 0.5)",
										color: "#FFF",
									},
									render: (rowData) => (
										<Typography variant="caption">
											{rowData.added} <br /> {rowData.createdon}{" "}
										</Typography>
									),
								},
								{ title: "Created on", field: "createdon", hidden: true },
								{ title: "Id", field: "id", hidden: true },
								{ title: "data", field: "data", hidden: true },
								{
									title: "to_be_resolved",
									field: "to_be_resolved",
									hidden: true,
								},
								{
									title: "to_be_reviewed",
									field: "to_be_reviewed",
									hidden: true,
								},
							]}
							data={productData}
							options={{
								search: true,
								actionsColumnIndex: -1,
								rowStyle: {
									borderLeft: "6px solid green",
								},
							}}
							// actions={[
							//     {
							//         icon: 'save',
							//         tooltip: 'Save User',
							//         onClick: (event, rowData: any) => { navigateToLink.push(ROUTES.VIEW_PRODUCT_DETAILS, { id: rowData.id }) }
							//     },

							// ]}

							detailPanel={[
								{
									icon: "edit",
									tooltip: "Actions on this product",
									render: (rowData) => {
										return (
											<div className={styles.detail_panel_wrapper}>
												<Grid container spacing={2}>
													<Grid item xs={12} lg={1}>
														<Button
															variant="outlined"
															color="primary"
															aria-label="edit-vendor-details"
															onClick={() => {
																setProductModalData({ ...rowData.data });
																setProductModalTitle("edit-product");
																handleClickOpenModal();
															}}
														>
															Edit
														</Button>
													</Grid>

													<Grid item xs={12} lg={1}>
														<Button
															variant="outlined"
															color="secondary"
															aria-label="view-vendor-details"
															onClick={() => {
																setProductModalData({ ...rowData.data });
																setProductModalTitle("view-product");
																handleClickOpenModal();
															}}
														>
															View
														</Button>
													</Grid>
													{rowData.to_be_reviewed && (
														<Grid item xs={12} lg={1}>
															<Button
																variant="outlined"
																color="secondary"
																aria-label="view-vendor-details"
																onClick={() => {
																	setProductModalData({ ...rowData.data });
																	setProductModalTitle("review-product");
																	handleClickOpenModal();
																}}
															>
																Review
															</Button>
														</Grid>
													)}
													{rowData.to_be_resolved && (
														<Grid item xs={12} lg={1}>
															<Button
																variant="outlined"
																color="secondary"
																aria-label="view-vendor-details"
																onClick={() => {
																	navigateToLink.push(ROUTES.RESOLVE_PRODUCT, {
																		id: rowData.id,
																	});
																}}
															>
																Review
															</Button>
														</Grid>
													)}
													{rowData.status && (
														<Grid item xs={12} lg={2}>
															<Button
																variant="outlined"
																color="secondary"
																aria-label="hide-from-market"
																onClick={() => {
																	dispatch(HideProductFromMarket(rowData.id));
																}}
															>
																Hide From Market
															</Button>
														</Grid>
													)}
													{!rowData.to_be_reviewed &&
														!rowData.to_be_resolved &&
														!rowData.status && (
															<Grid item xs={12} lg={2}>
																<Button
																	variant="outlined"
																	color="secondary"
																	aria-label="show-on-market"
																	onClick={() => {
																		dispatch(ShowProductOnMarket(rowData.id));
																	}}
																>
																	Show on Market
																</Button>
															</Grid>
														)}
												</Grid>
											</div>
										);
									},
								},
							]}
							components={
								{
									// Action: props => (
									//     <Button aria-label="view-vendor-details" size="medium" onClick={(event) => props.action.onClick(event, props.data)}>
									//         View
									//     </Button>
									// ),
									// Toolbar: props => (
									//     <div className={styles.toolbar_wrapper}>
									//         <Grid container>
									//             <Grid item lg={3}>
									//                 {/* <Link to={ROUTES.ADD_NEW_PRODUCT}>
									//                     <Button
									//                         variant="outlined"
									//                         color="primary"
									//                         className={styles.add_new_product_button}
									//                         startIcon={<Icon>add</Icon>}
									//                     >
									//                         Add New Product
									//             </Button>
									//                 </Link> */}
									//             </Grid>
									//             <Grid item lg={9}>
									//                 <MTableToolbar {...props} />
									//             </Grid>
									//         </Grid>
									//     </div>
									// )
								}
							}
						/>
					</div>
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<HotProducts />
				</TabPanel>
				<TabPanel value={tabValue} index={2}>
					<TopPickProducts />
				</TabPanel>
			</DashBoardLayout>
		</Fragment>
	);
};

export default Products;
