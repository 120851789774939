import { useMutation } from "react-query";
import axios from "axios";

import firebase from "../../../config/firebaseConfig";
// const queryClient = new QueryClient();
const createPSTransferReceipt = async (values: any) => {
	const response = await axios.post(
		"https://api.paystack.co/transferrecipient",
		{
			type: "nuban",
			name: values.account_name,
			account_number: values.account_number,
			bank_code: values.bank_code,
			currency: "NGN",
			metadata: {
				store_id: values.store_id,
				store_name: values.store_name,
				class: "LOGISTICS",
			},
		},
		{
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
				"Content-Type": "application/json",
			},
		}
	);

	// return response.data
	if (response.status === 200 || response.status === 201) {
		return firebase
			.firestore()
			.collection("LogisticsCompanies")
			.doc(values.store_id)
			.set(
				{
					psreciept_code: response.data.data.recipient_code,
					psreciept_data: {
						account_name: values.account_name,
						account_number: values.account_number,
						bank_code: values.bank_code,
					},
				},
				{ merge: true }
			);
	}
	return null;
};

const useCreatePSTransferReceipt = () => {
	return useMutation(createPSTransferReceipt, {
		// onSuccess: () => {
		// 	queryClient.refetchQueries();
		// },
	});
};

export default useCreatePSTransferReceipt;
