import React, { Fragment, useState, useEffect } from "react";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//material table
import MaterialTable from "material-table";

//material ui components
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import Container from "@material-ui/core/Container";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BeenhereTwoToneIcon from "@material-ui/icons/BeenhereTwoTone";

//get image
import GetImageFromFirebase from "../components/GetImageFromFirebase";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//formik
import { Field, Form, Formik } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";

//react - redux
import { useSelector, useDispatch } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";

import LoadingScreen from "../components/LoadingScreen";

import {
	getVetDocsCounts,
	getVetDocs,
	// getLegalCut,
	CreativeJoBNumber,
} from "../utils";

import { useParams } from "react-router-dom";
import * as ROUTES from "../constants/routes";

//navigation hook
import { useNavigate } from "../hooks/navigate";

import { UnderVetting } from "../store/actions/vetting/vet";
import { PassVetting } from "../store/actions/vetting/pass";
import { FailedVetting } from "../store/actions/vetting/failed";

import EkiojaBag from "../assets/images/ekioja-logo.jpg";

import useInitiateBrandCreativeJob from "../hooks/creative/useInitiateBrandCreativeJob";
import useCheckBrandCreatedJob from "../hooks/legal/useCheckBrandCreatedJob";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			borderRadius: 20,
			width: "100%",
		},
		toolbar_wrapper: {
			// backgroundColor: '#303030',
			backgroundColor: theme.palette.background.default,
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		business_logo_wrapper: {
			width: 90,
			height: 90,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& img": {
				width: 80,
				height: 80,
				borderRadius: 80,
			},
		},
		add_new_product_button: {
			marginTop: "5%",
		},
		queries_wrapper: {
			width: "100%",
			padding: 10,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
		appBar: {
			position: "relative",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		td: {
			padding: 10,
			border: "2px black solid",
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Vet = () => {
	const styles = useStyles();

	const { query } = useParams<any>();

	const { navigateToLink } = useNavigate();

	const dispatch = useDispatch();

	const authuid = useSelector((state: any) => state.firebase.auth.uid);
	const profile = useSelector((state: any) => state.firebase.profile);

	const [vetDocsData, setVetDocsData] = useState<any>([]);
	const [vetDocsCountData, setVetDocsCountData] = useState<any>([]);

	const [pageloading, setpageloading] = useState(false);
	const [tableTitle, setTableTitle] = useState("All vet");
	const [openDetailDialog, setOpenDetailDialog] = useState(false);
	const [openVettedDetailDialog, setOpenVettedDetailDialog] = useState(false);
	const [viewvetStoreData, setViewvetStoreData] = useState<any>({});

	const [needCreativesState, setNeedCreativesState] = useState({
		needLogo: false,
		needBanner: false,
	});

	const handleNeedCreateChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setNeedCreativesState({
			...needCreativesState,
			[event.target.name]: event.target.checked,
		});
	};

	const handleToggleConfirmNeedClickOpen = () => {
		setToggleConfirmNeedOpen(true);
	};

	const handleToggleConfirmNeedClose = () => {
		setToggleConfirmNeedOpen(false);
	};

	const { needLogo, needBanner } = needCreativesState;

	const [
		InitiateBrandCreativeJobMutate,
		InitiateBrandCreativeJobInfo,
	] = useInitiateBrandCreativeJob();

	const CheckBrandCreatedJobInfo = useCheckBrandCreatedJob(
		viewvetStoreData.unique_id
	);

	const InitiateBrandCreativeJob = () => {
		let result: any = [];
		if (needLogo) {
			result.push("Logo");
		}
		if (needBanner) {
			result.push("Banner");
		}
		InitiateBrandCreativeJobMutate(
			{
				store_id: viewvetStoreData.unique_id,
				store_name: viewvetStoreData.business_name,
				store_admin_data: {
					first_name: viewvetStoreData.first_name,
					last_name: viewvetStoreData.last_name,
					email: viewvetStoreData.email,
					contact_phone_number: viewvetStoreData.contact_phone_number,
				},
				job_created_by: {
					id: authuid,
					name: `${profile.first_name} ${profile.last_name}`,
					role: profile.role,
				},
				reference: CreativeJoBNumber(),
				job: result,
				commission: 100,
				pending_review: true,
				type: "BRAND_CREATIVE",
			},
			{
				onSuccess: () => {
					toast.success("Creative job created successfully!", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
					});

					handleToggleConfirmNeedClose();
				},
				onError: () => {
					toast.error("Oops! An error occured!  Try agin", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
					});
				},
			}
		);
	};

	const [toggleConfirmNeedCreative, setToggleConfirmNeedOpen] = React.useState(
		false
	);

	useFirestoreConnect([
		{
			collection: "Stores",
		},
		{
			collection: "Admins",
		},
		{
			collection: "VetDocuments",
			where: [["vetted_by", "==", isLoaded(authuid) ? authuid : "rt"]],
			storeAs: "VetDocuments",
		},
	]);

	const stores = useSelector((state: any) => state.firestore.ordered.Stores);

	const admins = useSelector((state: any) => state.firestore.ordered.Admins);

	const VetDocuments = useSelector(
		(state: any) => state.firestore.data["VetDocuments"]
	);

	useEffect(() => {
		if (
			isLoaded(VetDocuments) &&
			isLoaded(stores) &&
			isLoaded(authuid) &&
			isLoaded(admins)
		) {
			let vet_doc_data: any[] = [];
			if (VetDocuments !== null) {
				for (var vetdockey in VetDocuments) {
					vet_doc_data.push({
						...VetDocuments[vetdockey],
						vetdoc_uuid: vetdockey,
					});
				}
			}

			const sorted = _.orderBy(vet_doc_data, ["added_on"], ["desc"]);

			const all_same_docs_in_stores_and_admins = _.intersectionWith(
				_.cloneDeep(admins),
				stores,
				function (x: any, y: any) {
					return (
						x.entity === y.unique_id &&
						x.role === "Administrator" &&
						_.assign(x, y)
					);
				}
			);

			const all_same_docs_in_vet_and_store = _.intersectionWith(
				_.cloneDeep(sorted),
				all_same_docs_in_stores_and_admins,
				function (x: any, y: any) {
					return x.store_id === y.unique_id && _.assign(x, y);
				}
			);

			setVetDocsCountData([...vet_doc_data]);

			const result_all_vet_doc = getVetDocs(
				all_same_docs_in_vet_and_store,
				ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS
			);
			const result_all_to_be_vetted = getVetDocs(
				all_same_docs_in_vet_and_store,
				ROUTES.VET_DOC_QUERIES.ALL_TO_BE_VETTED
			);
			const result_all_under_vetting = getVetDocs(
				all_same_docs_in_vet_and_store,
				ROUTES.VET_DOC_QUERIES.ALL_UNDER_VETTING
			);
			const result_all_draft = getVetDocs(
				all_same_docs_in_vet_and_store,
				ROUTES.VET_DOC_QUERIES.ALL_DRAFT
			);
			const result_all_passed = getVetDocs(
				all_same_docs_in_vet_and_store,
				ROUTES.VET_DOC_QUERIES.ALL_PASSED
			);
			const result_all_failed = getVetDocs(
				all_same_docs_in_vet_and_store,
				ROUTES.VET_DOC_QUERIES.ALL_FAILED
			);

			switch (query) {
				case ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS:
					setpageloading(false);
					setTableTitle("All Vet ");
					setVetDocsData([...result_all_vet_doc]);
					break;
				case ROUTES.VET_DOC_QUERIES.ALL_TO_BE_VETTED:
					setpageloading(false);
					setTableTitle("All To be vetted ");
					setVetDocsData([...result_all_to_be_vetted]);
					break;
				case ROUTES.VET_DOC_QUERIES.ALL_UNDER_VETTING:
					setpageloading(false);
					setTableTitle("All Under vetting ");
					setVetDocsData([...result_all_under_vetting]);

					break;
				case ROUTES.VET_DOC_QUERIES.ALL_DRAFT:
					setpageloading(false);
					setTableTitle("All Under vetting ");
					setVetDocsData([...result_all_draft]);
					break;
				case ROUTES.VET_DOC_QUERIES.ALL_PASSED:
					setpageloading(false);
					setTableTitle("All Passed Vetting ");
					setVetDocsData([...result_all_passed]);
					break;
				case ROUTES.VET_DOC_QUERIES.ALL_FAILED:
					setpageloading(false);
					setTableTitle("All Failed Vetting ");
					setVetDocsData([...result_all_failed]);
					break;

				default:
					break;
			}
		}
	}, [VetDocuments, stores, authuid, admins, query]);

	const handleClickDetailDialogOpen = () => {
		setOpenDetailDialog(true);
	};

	const handlDetailDialogeClose = () => {
		setOpenDetailDialog(false);
	};
	const handleClickVettedDetailDialogOpen = () => {
		setOpenVettedDetailDialog(true);
	};

	const handleVettedDetailDialogeClose = () => {
		setOpenVettedDetailDialog(false);
	};

	// if (!isLoaded(authuid)) return <LoadingScreen />;
	// if () return <LoadingScreen />;
	if (!isLoaded(stores)) return <LoadingScreen />;
	if (!isLoaded(VetDocuments)) return <LoadingScreen />;
	if (!isLoaded(admins)) return <LoadingScreen />;

	return (
		<Fragment>
			<DashBoardLayout menuIndex={11}>
				<div className={styles.page_header}>
					<Typography variant="h4" color="primary">
						{" "}
						Vetting Management
					</Typography>
				</div>
				<br />
				<ToastContainer />
				<Dialog
					open={toggleConfirmNeedCreative}
					onClose={handleToggleConfirmNeedClose}
					aria-labelledby="need-creative-dialog-title"
				>
					<DialogTitle id="need-creative-dialog-title">
						Confirmation needed!
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							By confirming this it means you want to create a new Creative job.
							Confirm to proceed.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleToggleConfirmNeedClose} color="primary">
							Cancel
						</Button>
						<Button
							onClick={() => {
								InitiateBrandCreativeJob();
							}}
							color="primary"
						>
							{InitiateBrandCreativeJobInfo.isLoading
								? "Processing..."
								: "Confirm"}
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullScreen
					open={openDetailDialog}
					onClose={handlDetailDialogeClose}
					TransitionComponent={Transition}
					key="action-vet-dialog"
					id="action-vet-dialog"
				>
					<AppBar className={styles.appBar}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={handlDetailDialogeClose}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography variant="h6" className={styles.title}>
								{viewvetStoreData.vet_id}
							</Typography>
							{CheckBrandCreatedJobInfo.status === "success" &&
								CheckBrandCreatedJobInfo.data.length !== 0 &&
								CheckBrandCreatedJobInfo.data[0]?.pending_review === true && (
									<Typography variant="h6" color="inherit">
										Creative job pending
									</Typography>
								)}
							{CheckBrandCreatedJobInfo.status === "success" &&
								CheckBrandCreatedJobInfo.data.length !== 0 &&
								CheckBrandCreatedJobInfo.data[0]?.processing?.status ===
									true && (
									<Typography variant="h6" color="inherit">
										Creative job under process
									</Typography>
								)}
							{CheckBrandCreatedJobInfo.status === "success" &&
								CheckBrandCreatedJobInfo.data.length !== 0 &&
								CheckBrandCreatedJobInfo.data[0]?.completed?.status ===
									true && (
									<Typography variant="h6" color="inherit">
										Creative job completed
									</Typography>
								)}
							{CheckBrandCreatedJobInfo.status === "success" &&
								CheckBrandCreatedJobInfo.data.length === 0 && (
									<div
										style={{
											backgroundColor: "#fafafa",
											display: "flex",
											justifyContent: "space-evenly",
											alignItems: "center",
											padding: 10,
										}}
									>
										<FormControl component="fieldset">
											<FormLabel component="legend" style={{ color: "#000" }}>
												Does the vendor need a creative?Choose
											</FormLabel>
											<FormGroup row>
												<FormControlLabel
													control={
														<Checkbox
															checked={needLogo}
															onChange={handleNeedCreateChange}
															name="needLogo"
															style={{ color: "#000" }}
														/>
													}
													label="Logo"
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={needBanner}
															onChange={handleNeedCreateChange}
															name="needBanner"
															style={{ color: "#000" }}
														/>
													}
													label="Banner"
												/>
											</FormGroup>
										</FormControl>
										<Button
											variant="contained"
											color="primary"
											onClick={handleToggleConfirmNeedClickOpen}
										>
											{InitiateBrandCreativeJobInfo.isLoading
												? "Processing...wait"
												: "Initiate brand creative"}
										</Button>
									</div>
								)}
						</Toolbar>
					</AppBar>

					<Container>
						<Grid container spacing={2}>
							<Grid item xs={12} lg={4}>
								<br />
								<br />
								{/* <Button variant='contained' color='secondary'>Save as Draft</Button> */}
								<Divider />
								<Formik
									initialValues={{
										video_call: "",
										voice_call: "",
										email: "",
										approval: "",
										comment: "",
										reason: "",
									}}
									onSubmit={(values, { setSubmitting }) => {
										setTimeout(() => {
											setSubmitting(false);

											if (values.approval === "Yes") {
												dispatch(
													PassVetting(
														viewvetStoreData.vetdoc_uuid,
														viewvetStoreData.unique_id,
														values,
														authuid,
														// getLegalCut(
														//   viewvetStoreData.paid_for_review_data
														//     .amountpaidforsignup
														// ),
														0,
														authuid,
														viewvetStoreData.vet_id,
														process.env.REACT_APP_EKIOJA_WALLET
													)
												);
											} else {
												dispatch(
													FailedVetting(
														viewvetStoreData.vetdoc_uuid,
														viewvetStoreData.unique_id,
														values,
														authuid,
														// getLegalCut(
														//   viewvetStoreData.paid_for_review_data
														//     .amountpaidforsignup
														// ),
														0,
														authuid,
														viewvetStoreData.vet_id,
														process.env.REACT_APP_EKIOJA_WALLET
													)
												);
											}
											handlDetailDialogeClose();
										}, 1000);
									}}
								>
									{({ submitForm, isSubmitting, values, setFieldValue }) => (
										<Form>
											{isSubmitting && <LinearProgress />}
											<List>
												<ListItem>
													<ListItemText
														primary="Did you voice call?"
														secondary={
															<Fragment>
																<Field
																	component={RadioGroup}
																	name="voice_call"
																	row
																	value={values.voice_call}
																	onChange={(
																		event: React.ChangeEvent<HTMLInputElement>
																	) => {
																		setFieldValue(
																			"voice_call",
																			(event.target as HTMLInputElement).value,
																			false
																		);
																	}}
																>
																	<FormControlLabel
																		value="Yes"
																		control={<Radio disabled={isSubmitting} />}
																		label="Yes"
																		disabled={isSubmitting}
																	/>
																	<FormControlLabel
																		value="No"
																		control={<Radio disabled={isSubmitting} />}
																		label="No"
																		disabled={isSubmitting}
																	/>
																</Field>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />
												<ListItem>
													<ListItemText
														primary="Did you video call?"
														secondary={
															<Fragment>
																<Field
																	component={RadioGroup}
																	name="video_call"
																	row
																	value={values.video_call}
																	onChange={(
																		event: React.ChangeEvent<HTMLInputElement>
																	) => {
																		setFieldValue(
																			"video_call",
																			(event.target as HTMLInputElement).value,
																			false
																		);
																	}}
																>
																	<FormControlLabel
																		value="Yes"
																		control={<Radio disabled={isSubmitting} />}
																		label="Yes"
																		disabled={isSubmitting}
																	/>
																	<FormControlLabel
																		value="No"
																		control={<Radio disabled={isSubmitting} />}
																		label="No"
																		disabled={isSubmitting}
																	/>
																</Field>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />
												<ListItem>
													<ListItemText
														primary="Did you Email (not compulsory)?"
														secondary={
															<Fragment>
																<Field
																	component={RadioGroup}
																	name="email"
																	row
																	value={values.email}
																	onChange={(
																		event: React.ChangeEvent<HTMLInputElement>
																	) => {
																		setFieldValue(
																			"email",
																			(event.target as HTMLInputElement).value,
																			false
																		);
																	}}
																>
																	<FormControlLabel
																		value="Yes"
																		control={<Radio disabled={isSubmitting} />}
																		label="Yes"
																		disabled={isSubmitting}
																	/>
																	<FormControlLabel
																		value="No"
																		control={<Radio disabled={isSubmitting} />}
																		label="No"
																		disabled={isSubmitting}
																	/>
																</Field>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />
												<ListItem>
													<ListItemText
														primary="Are you satisfied with this store's information and acknowledge that the store should be approved to sell on Ekioja?"
														secondary={
															<Fragment>
																<Field
																	component={RadioGroup}
																	name="approval"
																	row
																	value={values.approval}
																	onChange={(
																		event: React.ChangeEvent<HTMLInputElement>
																	) => {
																		setFieldValue(
																			"approval",
																			(event.target as HTMLInputElement).value,
																			false
																		);
																	}}
																>
																	<FormControlLabel
																		value="Yes"
																		control={<Radio disabled={isSubmitting} />}
																		label="Yes"
																		disabled={isSubmitting}
																	/>
																	<FormControlLabel
																		value="No"
																		control={<Radio disabled={isSubmitting} />}
																		label="No"
																		disabled={isSubmitting}
																	/>
																</Field>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />
											</List>
											{values.approval === "No" && (
												<Fragment>
													<Field
														component={TextField}
														name="reason"
														type="text"
														label="Reason for failing this store?"
														helperText={
															"Write a short reason why this store do not pass vetting"
														}
														multiline
														rows={6}
														fullWidth
													/>
													<Divider />
												</Fragment>
											)}
											<Field
												component={TextField}
												name="comment"
												type="text"
												label="Comment (compulsory)"
												helperText={"Write a short comment (compulsory)"}
												multiline
												rows={6}
												fullWidth
											/>
											<br />
											<Divider />

											<br />
											{values.voice_call !== "" &&
												values.voice_call !== "No" &&
												values.comment.length >= 20 && (
													<Button
														variant="contained"
														color="primary"
														disabled={isSubmitting}
														onClick={submitForm}
													>
														Submit
													</Button>
												)}
										</Form>
									)}
								</Formik>
							</Grid>
							<Grid item xs={12} lg={8}>
								<br />
								<Typography variant="h6" color="secondary">
									Data from the assessed store
								</Typography>
								<Divider />
								<br />
								<br />
								<table style={{ border: "2px black solid" }}>
									<tbody>
										<tr>
											<td className={styles.td}>&nbsp;Business logo</td>
											<td className={styles.td}>
												{viewvetStoreData.business_logo_id === "" && (
													<div className={styles.business_logo_wrapper}>
														<img src={EkiojaBag} alt="ekioja logo" />
													</div>
												)}
												{viewvetStoreData.business_logo_id !== "" && (
													<div className={styles.business_logo_wrapper}>
														<GetImageFromFirebase
															source={viewvetStoreData.business_logo_id}
														/>
													</div>
												)}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Business name</td>
											<td className={styles.td}>
												&nbsp;{viewvetStoreData.business_name}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Business email</td>
											<td className={styles.td}>
												&nbsp;{viewvetStoreData.business_email}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>
												&nbsp;Business website (Optional)
											</td>
											<td className={styles.td}>
												&nbsp;{viewvetStoreData.website}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>
												&nbsp;Corporate Affairs Commission (CAC) number(if
												available)
											</td>
											<td className={styles.td}>
												&nbsp;{viewvetStoreData.business_cac}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Address</td>
											<td className={styles.td}>
												&nbsp;
												{viewvetStoreData.location}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Business description</td>
											<td className={styles.td}>
												&nbsp;{viewvetStoreData.business_description}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Business niche&nbsp;</td>
											<td className={styles.td}>
												{_.map(
													viewvetStoreData.business_niche,
													(item) => item + " ,"
												)}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Representative&nbsp;</td>
											<td className={styles.td}>
												&nbsp;
												{`${viewvetStoreData.first_name}  ${viewvetStoreData.last_name}`}{" "}
												<br />
												&nbsp;{`${viewvetStoreData.email}`} <br />
												&nbsp;{`+${viewvetStoreData.contact_phone_number}`}{" "}
												<br />
											</td>
										</tr>

										<tr>
											<td className={styles.td}>
												&nbsp;Our goal is for Ekioja to be a trusted haven for
												made in Africa products and businesses. To this end,
												each seller confirms that their business and their
												products are genuine, authentic, and are trustworthy.
											</td>
											<td className={styles.td}>
												&nbsp;I confirm that my products are authentic and
												genuine. I confirm I will never upload any product of
												poor quality to the Ekioja Marketplace. I hereby
												covenant that all the products I will promote on the
												Ekioja marketplace are mine, or designed by my business,
												and are genuine and verifiable
											</td>
										</tr>
										<tr>
											<td className={styles.td}>
												&nbsp;Ekioja is a platform to find genuine businesses,
												and authentic African brands and creators. Our sellers
												and actual owners of their brands and creators of their
												products. To maintain the standards that we demand of
												ourselves, we only deal with actual business owners and
												product creators. To this end, you guarantee that you
												are the owner of or person solely in charge of the brand
												under which this business is created.
											</td>
											<td className={styles.td}>
												&nbsp;I confirm and guarantee that I am the owner,
												founder, and direct producer, and manager of my named
												brand and her products. I guarantee that I am not a
												third party, a phony seller, or a phony contractor.
											</td>
										</tr>
										<tr>
											<td className={styles.td}>
												&nbsp;To keep the community and marketplace safe, Ekioja
												maintains some terms and conditions. To protect both the
												buyer and seller in all transactions, Ekioja operates an
												escrow service.
											</td>
											<td className={styles.td}>
												&nbsp;I agree to be bound by the escrow service policy,
												in protecting all transactions.
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;</td>
											<td className={styles.td}>
												&nbsp;I agree to use Ekioja safely and securely, and to
												do my due diligence in preventing fraud or fraudulent
												activities.
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;</td>
											<td className={styles.td}>
												I affirm that the information I have submitted is to the
												best of my knowledge accurate and true representation of
												my business.
											</td>
										</tr>
									</tbody>
								</table>
							</Grid>
						</Grid>
					</Container>
				</Dialog>

				<Dialog
					fullScreen
					open={openVettedDetailDialog}
					onClose={handleVettedDetailDialogeClose}
					TransitionComponent={Transition}
					key="details-vet-dialog"
					id="details-vet-dialog"
				>
					<AppBar className={styles.appBar}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={handleVettedDetailDialogeClose}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography variant="h6" className={styles.title}>
								{viewvetStoreData.vet_id}
							</Typography>
							{/* {viewvetStoreData.passed.status
                ? viewvetStoreData.passed.data.video_call
                : viewvetStoreData.failed.data.video_call} */}
							{/* {viewvetStoreData.passed.data.video_call} */}
						</Toolbar>
					</AppBar>
					<Container>
						<Grid container spacing={2}>
							<Grid item xs={12} lg={4}>
								<br />
								<br />
								{/* <Button variant='contained' color='secondary'>Save as Draft</Button> */}
								<Divider />
								<Formik
									initialValues={{
										video_call:
											(_.has(viewvetStoreData.failed, "data") &&
												viewvetStoreData.failed.data.video_call) ||
											(_.has(viewvetStoreData.passed, "data") &&
												viewvetStoreData.passed.data.video_call),
										voice_call:
											(_.has(viewvetStoreData.failed, "data") &&
												viewvetStoreData.failed.data.voice_call) ||
											(_.has(viewvetStoreData.passed, "data") &&
												viewvetStoreData.passed.data.voice_call),
										email:
											(_.has(viewvetStoreData.failed, "data") &&
												viewvetStoreData.failed.data.email) ||
											(_.has(viewvetStoreData.passed, "data") &&
												viewvetStoreData.passed.data.email),
										approval:
											(_.has(viewvetStoreData.failed, "data") &&
												viewvetStoreData.failed.data.approval) ||
											(_.has(viewvetStoreData.passed, "data") &&
												viewvetStoreData.passed.data.approval),
										comment:
											(_.has(viewvetStoreData.failed, "data") &&
												viewvetStoreData.failed.data.comment) ||
											(_.has(viewvetStoreData.passed, "data") &&
												viewvetStoreData.passed.data.comment),
										reason:
											(_.has(viewvetStoreData.failed, "data") &&
												viewvetStoreData.failed.data.reason) ||
											(_.has(viewvetStoreData.passed, "data") &&
												viewvetStoreData.passed.data.reason),
									}}
									onSubmit={(values, { setSubmitting }) => {
										setTimeout(() => {
											setSubmitting(false);
										}, 1000);
									}}
								>
									{({ submitForm, isSubmitting, values, setFieldValue }) => (
										<Form>
											{isSubmitting && <LinearProgress />}
											<List>
												<ListItem>
													<ListItemText
														primary="Did you voice call?"
														secondary={
															<Fragment>
																<Field
																	component={RadioGroup}
																	name="voice_call"
																	row
																	value={values.voice_call}
																>
																	<FormControlLabel
																		value="Yes"
																		control={<Radio disabled={true} />}
																		label="Yes"
																		disabled={true}
																	/>
																	<FormControlLabel
																		value="No"
																		control={<Radio disabled={isSubmitting} />}
																		label="No"
																		disabled={true}
																	/>
																</Field>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />
												<ListItem>
													<ListItemText
														primary="Did you video call?"
														secondary={
															<Fragment>
																<Field
																	component={RadioGroup}
																	name="video_call"
																	row
																	value={values.video_call}
																>
																	<FormControlLabel
																		value="Yes"
																		control={<Radio disabled={true} />}
																		label="Yes"
																		disabled={true}
																	/>
																	<FormControlLabel
																		value="No"
																		control={<Radio disabled={true} />}
																		label="No"
																		disabled={true}
																	/>
																</Field>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />
												<ListItem>
													<ListItemText
														primary="Did you Email (not compulsory)?"
														secondary={
															<Fragment>
																<Field
																	component={RadioGroup}
																	name="email"
																	row
																	value={values.email}
																>
																	<FormControlLabel
																		value="Yes"
																		control={<Radio disabled={true} />}
																		label="Yes"
																		disabled={true}
																	/>
																	<FormControlLabel
																		value="No"
																		control={<Radio disabled={true} />}
																		label="No"
																		disabled={true}
																	/>
																</Field>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />
												<ListItem>
													<ListItemText
														primary="Are you satisfied with this store's information and acknowledge that the store should be approved to sell on Ekioja?"
														secondary={
															<Fragment>
																<Field
																	component={RadioGroup}
																	name="approval"
																	row
																	value={values.approval}
																>
																	<FormControlLabel
																		value="Yes"
																		control={<Radio disabled={true} />}
																		label="Yes"
																		disabled={true}
																	/>
																	<FormControlLabel
																		value="No"
																		control={<Radio disabled={true} />}
																		label="No"
																		disabled={true}
																	/>
																</Field>
															</Fragment>
														}
													/>
												</ListItem>
												<Divider />
											</List>
											{values.approval === "No" && (
												<Fragment>
													<Field
														component={TextField}
														disabled={true}
														name="reason"
														type="text"
														label="Reason for failing this store?"
														multiline
														rows={6}
														fullWidth
													/>
													<Divider />
												</Fragment>
											)}
											<Field
												component={TextField}
												name="comment"
												type="text"
												label="Comment (optional)"
												disabled={true}
												multiline
												rows={6}
												fullWidth
											/>
											<br />
											<Divider />

											<br />
										</Form>
									)}
								</Formik>
							</Grid>
							<Grid item xs={12} lg={8}>
								<br />
								<Typography variant="h6" color="secondary">
									Data from the assessed store
								</Typography>
								<Divider />
								<br />
								<br />
								<table style={{ border: "2px black solid" }}>
									<tbody>
										<tr>
											<td className={styles.td}>&nbsp;Business logo</td>
											<td className={styles.td}>
												{
													<div className={styles.business_logo_wrapper}>
														<GetImageFromFirebase
															source={viewvetStoreData.business_logo_id}
														/>
													</div>
												}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Business name</td>
											<td className={styles.td}>
												&nbsp;{viewvetStoreData.business_name}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Business email</td>
											<td className={styles.td}>
												&nbsp;{viewvetStoreData.business_email}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>
												&nbsp;Business website (Optional)
											</td>
											<td className={styles.td}>
												&nbsp;{viewvetStoreData.website}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>
												&nbsp;Corporate Affairs Commission (CAC) number(if
												available)
											</td>
											<td className={styles.td}>
												&nbsp;{viewvetStoreData.business_cac}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Address</td>
											<td className={styles.td}>
												&nbsp;
												{viewvetStoreData.location}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Business description</td>
											<td className={styles.td}>
												&nbsp;{viewvetStoreData.business_description}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Business niche&nbsp;</td>
											<td className={styles.td}>
												{_.map(
													viewvetStoreData.business_niche,
													(item) => item + " ,"
												)}
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;Representative&nbsp;</td>
											<td className={styles.td}>
												&nbsp;
												{`${viewvetStoreData.first_name}  ${viewvetStoreData.last_name}`}{" "}
												<br />
												&nbsp;{`${viewvetStoreData.email}`} <br />
												&nbsp;{`+${viewvetStoreData.contact_phone_number}`}{" "}
												<br />
											</td>
										</tr>

										<tr>
											<td className={styles.td}>
												&nbsp;Our goal is for Ekioja to be a trusted haven for
												made in Africa products and businesses. To this end,
												each seller confirms that their business and their
												products are genuine, authentic, and are trustworthy.
											</td>
											<td className={styles.td}>
												&nbsp;I confirm that my products are authentic and
												genuine. I confirm I will never upload any product of
												poor quality to the Ekioja Marketplace. I hereby
												covenant that all the products I will promote on the
												Ekioja marketplace are mine, or designed by my business,
												and are genuine and verifiable
											</td>
										</tr>
										<tr>
											<td className={styles.td}>
												&nbsp;Ekioja is a platform to find genuine businesses,
												and authentic African brands and creators. Our sellers
												and actual owners of their brands and creators of their
												products. To maintain the standards that we demand of
												ourselves, we only deal with actual business owners and
												product creators. To this end, you guarantee that you
												are the owner of or person solely in charge of the brand
												under which this business is created.
											</td>
											<td className={styles.td}>
												&nbsp;I confirm and guarantee that I am the owner,
												founder, and direct producer, and manager of my named
												brand and her products. I guarantee that I am not a
												third party, a phony seller, or a phony contractor.
											</td>
										</tr>
										<tr>
											<td className={styles.td}>
												&nbsp;To keep the community and marketplace safe, Ekioja
												maintains some terms and conditions. To protect both the
												buyer and seller in all transactions, Ekioja operates an
												escrow service.
											</td>
											<td className={styles.td}>
												&nbsp;I agree to be bound by the escrow service policy,
												in protecting all transactions.
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;</td>
											<td className={styles.td}>
												&nbsp;I agree to use Ekioja safely and securely, and to
												do my due diligence in preventing fraud or fraudulent
												activities.
											</td>
										</tr>
										<tr>
											<td className={styles.td}>&nbsp;</td>
											<td className={styles.td}>
												I affirm that the information I have submitted is to the
												best of my knowledge accurate and true representation of
												my business.
											</td>
										</tr>
									</tbody>
								</table>
							</Grid>
						</Grid>
					</Container>
				</Dialog>

				<div className={styles.queries_wrapper}>
					<Chip
						label={`All Vet ${getVetDocsCounts(
							vetDocsCountData,
							ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS
						)}`}
						variant="default"
						onClick={() =>
							navigateToLink.push(ROUTES.VET, {
								query: ROUTES.VET_DOC_QUERIES.ALL_VET_DOCS,
							})
						}
					/>
					<Chip
						label={`All To be vetted ${getVetDocsCounts(
							vetDocsCountData,
							ROUTES.VET_DOC_QUERIES.ALL_TO_BE_VETTED
						)}`}
						variant="default"
						onClick={() =>
							navigateToLink.push(ROUTES.VET, {
								query: ROUTES.VET_DOC_QUERIES.ALL_TO_BE_VETTED,
							})
						}
					/>
					<Chip
						label={`All Undervetting ${getVetDocsCounts(
							vetDocsCountData,
							ROUTES.VET_DOC_QUERIES.ALL_UNDER_VETTING
						)}`}
						variant="default"
						onClick={() =>
							navigateToLink.push(ROUTES.VET, {
								query: ROUTES.VET_DOC_QUERIES.ALL_UNDER_VETTING,
							})
						}
					/>
					{/* <Chip
            label={`All Draft ${getVetDocsCounts(
              vetDocsCountData,
              ROUTES.VET_DOC_QUERIES.ALL_DRAFT
            )}`}
            variant="default"
            onClick={() =>
              navigateToLink.push(ROUTES.VET, {
                query: ROUTES.VET_DOC_QUERIES.ALL_DRAFT,
              })
            }
          /> */}
					<Chip
						label={`All Passed ${getVetDocsCounts(
							vetDocsCountData,
							ROUTES.VET_DOC_QUERIES.ALL_PASSED
						)}`}
						variant="default"
						onClick={() =>
							navigateToLink.push(ROUTES.VET, {
								query: ROUTES.VET_DOC_QUERIES.ALL_PASSED,
							})
						}
					/>
					<Chip
						label={`All Failed  ${getVetDocsCounts(
							vetDocsCountData,
							ROUTES.VET_DOC_QUERIES.ALL_FAILED
						)}`}
						variant="default"
						onClick={() =>
							navigateToLink.push(ROUTES.VET, {
								query: ROUTES.VET_DOC_QUERIES.ALL_FAILED,
							})
						}
					/>
				</div>
				<Box className={styles.root} boxShadow={5}>
					<MaterialTable
						style={{ width: "100%" }}
						isLoading={pageloading}
						title={tableTitle}
						columns={[
							{
								title: "Vet ID",
								field: "vid",
								render: (rowData) => (
									<Typography variant="caption">{rowData.vid}</Typography>
								),
							},
							{
								title: "Logo",
								field: "business_logo_id",
								render: (rowData) => (
									<>
										{rowData.business_logo_id === "" && (
											<div className={styles.business_logo_wrapper}>
												<img src={EkiojaBag} alt="ekioja logo" />
											</div>
										)}
										{rowData.business_logo_id !== "" && (
											<div className={styles.business_logo_wrapper}>
												<GetImageFromFirebase
													source={rowData.business_logo_id}
												/>
											</div>
										)}
									</>
								),
							},
							{
								title: "Store name",
								field: "business_name",
								render: (rowData) => (
									<Typography variant="caption">
										{rowData.business_name}
									</Typography>
								),
							},
							{
								title: "Address",
								field: "business_location",
								render: (rowData) => (
									<Typography variant="caption">
										{rowData.business_location}
									</Typography>
								),
							},
							{
								title: "Niche",
								field: "business_niche",
								render: (rowData) => (
									<Typography variant="caption">
										{rowData.business_niche}
									</Typography>
								),
							},
							{
								title: "business_location",
								field: "business_location",
								hidden: true,
							},
							{
								title: "Status",
								field: "status",
								render: (rowData) => (
									<Fragment>
										{rowData.data.to_be_vetted.status && (
											<Chip
												icon={<BeenhereTwoToneIcon />}
												label="To be Vetted"
												variant="outlined"
												color="secondary"
												style={{ color: "yellow", border: "1px yellow solid" }}
											/>
										)}
										{_.has(rowData.data, "under_vetting") &&
											rowData.data.under_vetting.status === true && (
												<Chip
													icon={<BeenhereTwoToneIcon />}
													label="Under Vetting"
													variant="outlined"
													color="secondary"
													style={{
														color: "yellow",
														border: "1px yellow solid",
													}}
												/>
											)}
										{_.has(rowData.data, "draft") && rowData.data.draft.status && (
											<Chip
												icon={<BeenhereTwoToneIcon />}
												label="Draft"
												variant="outlined"
												color="secondary"
												style={{
													color: "yellow",
													border: "1px yellow solid",
												}}
											/>
										)}
										{_.has(rowData.data, "passed") &&
											rowData.data.passed.status && (
												<Chip
													icon={<BeenhereTwoToneIcon />}
													label="Passed"
													variant="outlined"
													color="secondary"
													style={{ color: "green", border: "1px green solid" }}
												/>
											)}
										{_.has(rowData.data, "failed") &&
											rowData.data.failed.status && (
												<Chip
													icon={<BeenhereTwoToneIcon />}
													label="Failed"
													variant="outlined"
													color="secondary"
													style={{ color: "red", border: "1px red solid" }}
												/>
											)}
									</Fragment>
								),
							},
							{
								title: "data",
								field: "data",
								hidden: true,
							},
							{
								title: "Action",
								field: "action",
								render: (rowData) => (
									<Fragment>
										{rowData.data.to_be_vetted.status && (
											<Button
												onClick={() => {
													dispatch(UnderVetting(rowData.data.vetdoc_uuid));
													handleClickDetailDialogOpen();
													setViewvetStoreData({
														...rowData.data,
														location: rowData.business_location,
													});
												}}
											>
												VET
											</Button>
										)}
										{_.has(rowData.data, "under_vetting") &&
											rowData.data.under_vetting.status === true && (
												<Button
													onClick={() => {
														handleClickDetailDialogOpen();
														setViewvetStoreData({
															...rowData.data,
															location: rowData.business_location,
														});
													}}
												>
													VET
												</Button>
											)}

										{_.has(rowData.data, "passed") &&
											rowData.data.passed.status && (
												<Button
													onClick={() => {
														handleClickVettedDetailDialogOpen();
														setViewvetStoreData({
															...rowData.data,
															location: rowData.business_location,
														});
													}}
												>
													VIEW
												</Button>
											)}
										{_.has(rowData.data, "failed") &&
											rowData.data.failed.status && (
												<Button
													onClick={() => {
														handleClickVettedDetailDialogOpen();
														setViewvetStoreData({
															...rowData.data,
															location: rowData.business_location,
														});
													}}
												>
													VIEW
												</Button>
											)}
									</Fragment>
								),
							},
							//   {
							//     title: "Added",
							//     field: "added",
							//     render: (rowData) => (
							//       <Typography variant="caption">
							//         {rowData.added} <br /> {rowData.createdon}{" "}
							//       </Typography>
							//     ),
							//   },
							//   { title: "Created on", field: "createdon", hidden: true },
						]}
						data={vetDocsData}
						options={{
							search: true,
							actionsColumnIndex: -1,
						}}

						// actions={[
						//     {
						//         icon: 'save',
						//         tooltip: 'Save User',
						//         onClick: (event, rowData: any) => { navigateToLink.push(ROUTES.VIEW_PRODUCT_DETAILS, { id: rowData.id }) }
						//     },

						// ]}
					/>
				</Box>
			</DashBoardLayout>
		</Fragment>
	);
};

export default Vet;
