import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import useGetLogisticsDispatcherList from "../../hooks/logistics/useGetLogisticsDispatcherList";
import useAcceptAssignBooking from "../../hooks/logistics/useAcceptAssignBooking";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dispatchers_list_wrapper: {
			width: "100%",
			backgroundColor: "#161623 ",
			padding: 12,
			overflowY: "auto",
			borderRadius: 10,
			height: 600,
		},
		dispatcher_item_wrapper: {
			width: "100%",
			minHeight: 200,
			paddingBottom: 6,
			borderRadius: 10,
			backgroundColor: "rgba(255,255,225,0.2)",
			borderTop: "1px solid rgba(255,255,225,0.6) ",
			borderLeft: "1px solid rgba(255,255,225,0.6) ",
			zIndex: 10,
			marginBottom: 15,
		},
		dispatcher_avatar_wrapper: {
			width: "100%",
			height: 150,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		dispatcher_avatar: {
			width: 100,
			height: 100,
		},
		dispatcher_name: {
			fontWeight: "bolder",
			fontSize: "1em",
			textAlign: "center",
			margin: 0,
		},
		chip_wrapper: {
			"& h5": {
				fontWeight: "bold",
				textAlign: "center",
				// margin: 0,
			},
			"& >div": {
				marginBottom: 14,
			},
			// borderRight: "1px solid rgba(255,255,225,0.6) ",
			padding: 2,
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "space-between",
			flexDirection: "column",
		},
		today_stats_inner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "0px 7px",
			backgroundColor: "rgba(255,255,225,0.2)",
			borderRadius: 10,
			marginRight: 5,
			"& > div": {
				width: "100%",
				display: "flex",
				justifyContent: "flex-start",
				alignItems: "space-between",
				flexDirection: "column",
			},
			"& > div >div": {
				marginBottom: 5,
				marginTop: 5,
			},
		},
		warning_wrapper: {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
			padding: 7,
		},
	})
);

const AssignDispatcherModal = ({
	entity,
	orderTimelineId,
	authuid,
	close,
}: {
	entity: string;
	orderTimelineId: string;
	authuid: string;
	close: any;
}) => {
	const styles = useStyles();

	const GetLogisticsDispatcherQuery = useGetLogisticsDispatcherList(entity);
	const [
		AcceptAssignBookingMutate,
		AcceptAssignBookingInfo,
	] = useAcceptAssignBooking();

	return (
		<>
			<Grid container>
				<Grid item xs={12} lg={3}></Grid>
				<Grid item xs={12} lg={6}>
					<div className={styles.dispatchers_list_wrapper}>
						{GetLogisticsDispatcherQuery.isLoading && (
							<div
								style={{
									width: "100%",
									height: 400,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<CircularProgress />
							</div>
						)}
						{GetLogisticsDispatcherQuery.isSuccess &&
							GetLogisticsDispatcherQuery.data.map((item: any) => (
								<Box className={styles.dispatcher_item_wrapper} key={item.id}>
									<Grid container spacing={1}>
										<Grid item xs={12} lg={3}>
											<div className={styles.dispatcher_avatar_wrapper}>
												<Avatar className={styles.dispatcher_avatar}>H</Avatar>
											</div>
											<h4 className={styles.dispatcher_name}>
												{`${item.first_name} ${item.last_name}`}
											</h4>
										</Grid>
										<Grid item xs={12} lg={3}>
											<div className={styles.chip_wrapper}>
												<h5>Account status</h5>
												<Chip
													label="Approved"
													icon={item.approved ? <DoneIcon /> : <CloseIcon />}
													component="div"
												/>

												<Chip
													label="Available"
													icon={item.active ? <DoneIcon /> : <CloseIcon />}
													component="div"
												/>

												<Chip
													label="Blocked"
													icon={item.blocked ? <DoneIcon /> : <CloseIcon />}
													component="div"
												/>
											</div>
										</Grid>
										<Grid item xs={12} lg={6}>
											<div className={styles.chip_wrapper}>
												<h5>Today's delivery stats</h5>
												<div className={styles.today_stats_inner}>
													<div>
														<Chip
															avatar={
																<Avatar>
																	{item.waiting_to_be_shipped_count +
																		item.out_for_delivery_count +
																		item.delivered_count +
																		item.canceled_count +
																		item.returned_count}
																</Avatar>
															}
															label="Total assigned"
															component="div"
														/>
														<Chip
															avatar={
																<Avatar>
																	{item.waiting_to_be_shipped_count}
																</Avatar>
															}
															label="To be shipped"
															component="div"
														/>
														<Chip
															avatar={
																<Avatar>{item.out_for_delivery_count}</Avatar>
															}
															label="Out for delivery"
															component="div"
														/>
													</div>
													<div>
														<Chip
															avatar={<Avatar>{item.delivered_count}</Avatar>}
															label="Delivered"
															component="div"
														/>
														<Chip
															avatar={<Avatar>{item.canceled_count}</Avatar>}
															label="Cancelled"
															component="div"
														/>
														<Chip
															avatar={<Avatar>{item.returned_count}</Avatar>}
															label="Returned"
															component="div"
														/>
													</div>
												</div>
											</div>
										</Grid>
										<Grid item xs={12} lg={2}></Grid>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={12} lg={10}>
											{item.approved === false ||
											item.active === false ||
											item.blocked === true ? (
												<div className={styles.warning_wrapper}>
													<WarningIcon />
													<Typography variant="subtitle2" color="textPrimary">
														You can't select this dispatcher if he/she is not
														yet approved,not available or blocked.
													</Typography>
												</div>
											) : null}
										</Grid>
										<Grid item xs={12} lg={2}>
											<Button
												variant="contained"
												color="primary"
												disabled={
													item.approved === false
														? true
														: item.active === false
														? true
														: item.blocked === true
														? true
														: false
												}
												onClick={() => {
													if (
														window.confirm(
															"Are you sure you want to assign this booking to this dispatcher?"
														)
													) {
														AcceptAssignBookingMutate(
															{
																logistics_uuid: item.id,
																name: `${item.first_name} ${item.last_name}`,
																entity: entity,
																manager_uuid: authuid,
																orderTimelineId: orderTimelineId,
																dispatcher_email: item.email,
																dispatcher_phonenumber: item.phone_number,
															},
															{
																onSuccess: () => close(),
															}
														);
													}
												}}
											>
												{AcceptAssignBookingInfo.isLoading
													? "Processing..."
													: AcceptAssignBookingInfo.isError
													? "Error!"
													: AcceptAssignBookingInfo.isSuccess
													? "Done!"
													: "Assign"}
											</Button>
										</Grid>
									</Grid>
								</Box>
							))}
					</div>
				</Grid>
				<Grid item xs={12} lg={3}></Grid>
			</Grid>
		</>
	);
};

export default AssignDispatcherModal;
