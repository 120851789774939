import { useMutation } from "react-query";

import firebase from "../../config/firebaseConfig";

const addImageToStoreProfile = async ({
	url,
	entity,
}: {
	url: string;
	entity: string;
}) => {
	return firebase
		.firestore()
		.collection("Stores")
		.doc(entity)
		.set(
			{
				business_logo_id: `logo_${entity}`,
				business_logo_url: url,
			},
			{ merge: true }
		);
};

const useAddImageToStoreProfile = () => {
	return useMutation(addImageToStoreProfile);
};

export default useAddImageToStoreProfile;
