import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import Divider from "@material-ui/core/Divider";
import CloudUploadTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";

import UploadImage from "../../../UploadImage/UploadImage";
import useDeleteImage from "../../../../hooks/images/useDeleteImage";
import useAddImageDataToVendor from "../../../../hooks/creative/brandcreatives/useAddImageDataToVendor";
import useRemoveImageDataToVendor from "../../../../hooks/creative/brandcreatives/useRemoveImageDataToVendor";
// import useCompleteJob from "../../../../hooks/creative/brandcreatives/useCompleteJob";
import _ from "lodash";
// import EkiojaBag from "../../../../assets/images/ekioja-logo.jpg";
// import EkiojaBG from "../../../../assets/images/categories/african_fashion.png";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";

const CompletedFaceBehindTheBrandJob = ({ data }: { data: any }) => {
	const [DeleteImageMutate] = useDeleteImage();

	const [AddImageDataToVendorMutate] = useAddImageDataToVendor();
	const [
		RemoveImageDataToVendorMutate,
		RemoveImageDataToVendorInfo,
	] = useRemoveImageDataToVendor();

	const [designURL, setDesignURL] = useState(data.fbtb_url);

	const [deleteLogoDialog, setDeleteLogoDialog] = useState(false);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={1}></Grid>
				<Grid item xs={12} lg={4}>
					<List>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<StorefrontIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Vendor" secondary={data.store_name} />
						</ListItem>
						<Divider variant="inset" component="li" />
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<AccountCircleTwoToneIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary="Representative"
								secondary={
									<React.Fragment>
										<h4>{`${data.store_admin_data.first_name} ${data.store_admin_data.last_name}`}</h4>
										<h4>{`${data.store_admin_data.email}`}</h4>
										<h4>{`${data.store_admin_data.contact_phone_number}`}</h4>
									</React.Fragment>
								}
							/>
						</ListItem>
					</List>
				</Grid>
				<Grid item xs={12} lg={7}>
					{designURL !== "" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								minHeight: 200,
								width: "70%",
								border: "1px gray solid",
								borderRadius: 20,
								position: "relative",
								marginBottom: 30,
								paddingTop: 30,
							}}
						>
							<img width={150} height={150} src={designURL} alt="logo" />
							<br />

							<Button
								color="secondary"
								onClick={() => {
									setDeleteLogoDialog(true);
								}}
							>
								Do you want to remove this design?
							</Button>
							<br />
							<Collapse in={deleteLogoDialog} timeout="auto" unmountOnExit>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 40,
									}}
								>
									<div>
										<Button
											variant="contained"
											color="secondary"
											onClick={() => {
												RemoveImageDataToVendorMutate(
													{
														id: data.store_id,
														jobid:
															data.completedFaceBehindTheBrandJobs_data_uuid,
														type: "fbtb",
													},
													{
														onSuccess: () => {
															DeleteImageMutate(
																{
																	folder: "faceBehindTheBrandImages",
																	source: `fbtb_${data.store_id}`,
																},
																{
																	onSuccess: () => {
																		setDesignURL("");
																		setDeleteLogoDialog(false);
																	},
																	onError: (e: any) => {
																		// window.alert(e.message);
																		window.alert("here 1");
																	},
																}
															);
														},
														onError: (e: any) => {
															// window.alert(e.message);
															window.alert("here 2");
														},
													}
												);
											}}
										>
											{RemoveImageDataToVendorInfo.isLoading
												? "removing..."
												: "Yes"}
										</Button>
										<Button
											color="secondary"
											onClick={() => {
												setDeleteLogoDialog(false);
											}}
										>
											No
										</Button>
									</div>
								</div>
							</Collapse>
						</div>
					)}

					{_.includes(data.job, "FACE_BEHIND_THE_BRAND") && designURL === "" && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: 200,
								width: "70%",
								border: "1px gray solid",
								borderRadius: 20,
								position: "relative",
								marginBottom: 30,
							}}
						>
							<CloudUploadTwoToneIcon style={{ fontSize: 40 }} />
							<h3>Upload processed design</h3>
							<div
								style={{
									position: "absolute",
									bottom: -10,
									right: "44%",
									width: 50,
									zIndex: 500,
								}}
							>
								<UploadImage
									title="face behind the brand design"
									existingImageId={`fbtb_${data.store_id}`}
									folderToSave="faceBehindTheBrandImages"
									onRequestSaveURL={(url: any) => {
										AddImageDataToVendorMutate(
											{
												id: data.store_id,
												url,
												jobid: data.completedFaceBehindTheBrandJobs_data_uuid,
												type: "fbtb",
											},
											{
												onSuccess: () => {
													setDesignURL(url);
												},
												onError: (e: any) => {
													window.alert(e.message);
												},
											}
										);
									}}
									onRequestSave={(id: any) => {}}
									onRequestClear={() => {
										DeleteImageMutate(
											{
												folder: "faceBehindTheBrandImages",
												source: `fbtb_${data.store_id}`,
											},
											{
												onSuccess: () => {
													setDesignURL("");
												},
												onError: (e: any) => {
													window.alert(e.message);
												},
											}
										);
									}}
								/>
							</div>
						</div>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default CompletedFaceBehindTheBrandJob;
