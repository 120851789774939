import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
import axios from "axios";

const ekiojaLogisticsPSInitiateTransfer = async (values: any) => {
	const singlePaystackTransferResponse = await axios.post(
		"https://api.paystack.co/transfer",
		{
			source: "balance",
			amount: values.amount * 100,
			recipient: values.psreciept_code,
			reason: values.reason,
		},
		{
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY}`,
				"Content-Type": `application/json`,
			},
		}
	);

	if (singlePaystackTransferResponse.status === 200) {
		firebase
			.firestore()
			.collection("OrdersTimeline")
			.doc(values.orderTimeline_uuid)
			.set(
				{
					ekiojaLogisticsRecievedDeliveryFee: true,
					ekiojaLogisticsRecievedDeliveryFeeDate:
						firebase.firestore.FieldValue.serverTimestamp(),
					psTransferResponse: { ...singlePaystackTransferResponse.data },
				},
				{ merge: true }
			);
		console.log(singlePaystackTransferResponse.data);

		return singlePaystackTransferResponse.data;
	}
	return null;
};

const useEkiojaLogisticsPSInitiateTransfer = () => {
	return useMutation(ekiojaLogisticsPSInitiateTransfer);
};

export default useEkiojaLogisticsPSInitiateTransfer;
