import React, { useEffect, useState, Fragment } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DashBoardLayout from "./Layouts/DashBoardLayout";

import useGetLogisticsAdmin from "../hooks/logistics/useGetLogisticsAdmin";

import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";

import moment from "moment";
import _ from "lodash";

import MaterialTable, { MTableToolbar } from "material-table";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";

//icons
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Icon from "@material-ui/core/Icon";

import useApproveLogisticsAdmin from "../hooks/logistics/useApproveLogisticsAdmin";
import useBlockLogisticsAdmin from "../hooks/logistics/useBlockLogisticsAdmin";
import useUnBlockLogisticsAdmin from "../hooks/logistics/useUnBlockLogisticsAdmin";
import useChangeLogisticsRole from "../hooks/logistics/useChangeLogisticsRole";
import useInviteNewLogisticsAdmin from "../hooks/logistics/useInviteNewLogisticsAdmin";
import useRemoveLogisticsAdmin from "../hooks/logistics/useRemoveLogisticsAdmin";

import { LOGISTICS_MANAGER, LOGISTICS_DISPATCHER } from "../constants/roles";

import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-material-ui";

//yup
import * as yup from "yup";

import useGetLogisticsComStatus from "../hooks/logistics/useGetLogisticsComStatus";
import useGetLogisticsCompany from "../hooks/logistics/useGetLogisticsCompany";

//admin validation schema
const AdminValidationSchema = yup.object().shape({
	potential_user_email: yup.string().email().required("Email is Required"),
	role: yup.string().required("The Role is Required"),
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			padding: 20,
			overflowX: "auto",
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		vendor_logo: {
			width: 50,
			height: 50,
			borderRadius: 50,
			border: "1px white solid",
		},
		add_new_user_button: {
			marginTop: "5%",
			marginLeft: 50,
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const LogisticsAdminMgt = () => {
	const styles = useStyles();

	const [userActionDialogState, setUserActionDialogState] = useState(false);
	// const [approvedState, setApprovedState] = useState(true);
	// const [blockedState, setBlockedState] = useState(false);
	const [deleteState, setDeleteState] = useState(false);
	const [changeRoleState, setChangeRoleState] = useState(false);
	const [addNewUserModalState, setAddNewUserModalState] = useState(false);
	const [viewedAdminData, setViewedAdminData] = useState<any>({});
	const [logisticsAdminsArr, setLogisticsAdminsArr] = useState<any>([]);

	const [
		ApproveLogisticsAdminMutate,
		ApproveLogisticsAdminInfo,
	] = useApproveLogisticsAdmin();
	const [
		BlockLogisticsAdminMutate,
		BlockLogisticsAdminInfo,
	] = useBlockLogisticsAdmin();
	const [
		UnBlockLogisticsAdminMutate,
		UnBlockLogisticsAdminInfo,
	] = useUnBlockLogisticsAdmin();
	const [
		ChangeLogisticsRoleMutate,
		ChangeLogisticsRoleInfo,
	] = useChangeLogisticsRole();
	const [
		InviteNewLogisticsAdminMutate,
		InviteNewLogisticsAdminInfo,
	] = useInviteNewLogisticsAdmin();
	const [
		RemoveLogisticsAdminMutate,
		RemoveLogisticsAdminInfo,
	] = useRemoveLogisticsAdmin();

	const currentUser = useSelector((state: any) => state.firebase.profile);
	const GetLogisticsAdminsQuery = useGetLogisticsAdmin(
		isLoaded(currentUser) ? currentUser.entity : "10"
	);
	const GetLogisticsCompanyQuery = useGetLogisticsCompany(
		isLoaded(currentUser) ? currentUser.entity : "10"
	);
	const LogisticsAdmins = GetLogisticsAdminsQuery.isSuccess
		? GetLogisticsAdminsQuery.data
		: [];
	const LogisticsData = GetLogisticsCompanyQuery.isSuccess
		? GetLogisticsCompanyQuery.data[0]
		: {};

	const GetLogisticsComStatus = useGetLogisticsComStatus(
		isLoaded(currentUser) ? currentUser.entity : "0020"
	);

	let status = GetLogisticsComStatus.isSuccess
		? GetLogisticsComStatus.data
		: "e";

	useEffect(() => {
		if (GetLogisticsAdminsQuery.isSuccess) {
			let logistics_admns_data: any[] = [];

			for (const LogisticsAdminKey in LogisticsAdmins) {
				logistics_admns_data.push({
					...LogisticsAdmins[LogisticsAdminKey],
					uuid: LogisticsAdmins[LogisticsAdminKey].id,
				});
			}

			let mapArrToData = _.map(logistics_admns_data, (item) => {
				return {
					avatar:
						_.upperCase(item.first_name.charAt(0)) +
						_.upperCase(item.last_name.charAt(0)),
					username: item.username,
					fullname:
						_.capitalize(item.first_name) + " " + _.capitalize(item.last_name),
					role: item.logistics_role,
					// joined: item.joined_on,
					joined: moment(item.joined_on.toDate()).fromNow(),
					approved: item.approved,
					blocked: item.blocked,
					email: item.email,
					data: item,
				};
			});

			setLogisticsAdminsArr([...mapArrToData]);
		}
	}, [GetLogisticsAdminsQuery.isSuccess, LogisticsAdmins]);

	const toggleUserActionModal = () => {
		setUserActionDialogState(!userActionDialogState);
	};

	// const toggleApproved = () => {
	//     setApprovedState(!approvedState);
	// }

	// const toggleBlocked = () => {
	//     setBlockedState(!blockedState);
	// }

	const toggleDelete = () => {
		setDeleteState(!deleteState);
	};

	const toggleChangeRole = () => {
		setChangeRoleState(!changeRoleState);
	};

	const toggleAddNewUserModal = () => {
		setAddNewUserModalState(!addNewUserModalState);
	};

	return (
		<DashBoardLayout menuIndex={6}>
			<div className={styles.page_header}>
				<Typography variant="h4" color="primary">
					{" "}
					Logistics Admins Management
				</Typography>
			</div>
			<Dialog
				onClose={toggleAddNewUserModal}
				aria-labelledby="add-new-user-dialog"
				open={addNewUserModalState}
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle>Invite someone</DialogTitle>
				<DialogContent>
					<Formik
						initialValues={{
							potential_user_email: "",
							role: "",
							invited_by: currentUser.first_name,
							entity: currentUser.entity,
							logistics_company_name: LogisticsData.logistics_company_name,
						}}
						validationSchema={AdminValidationSchema}
						onSubmit={(values, { setSubmitting }) => {
							setTimeout(() => {
								setSubmitting(false);

								InviteNewLogisticsAdminMutate(values);
							}, 600);
						}}
					>
						{({ submitForm, isSubmitting, errors, touched }) => (
							<Form>
								<Grid container>
									<Grid item xs={12} lg={2}></Grid>
									<Grid item xs={12} lg={8}>
										{isSubmitting && <LinearProgress />}
										<Field
											component={TextField}
											name="potential_user_email"
											style={{ width: "100%" }}
											placeholder="Email of the person you want to add"
											type="email"
											required
										/>
										<br />
										<br />
										<FormControl>
											<InputLabel htmlFor="potential-user-role-field">
												Choose a role for them
											</InputLabel>
											<Field
												component={Select}
												required
												name="role"
												inputProps={{
													id: "potential-user-role-field",
												}}
												style={{ width: 370, paddingLeft: 20 }}
											>
												<MenuItem value={LOGISTICS_MANAGER}>Manager</MenuItem>
												<MenuItem value={LOGISTICS_DISPATCHER}>
													Dispatcher
												</MenuItem>
											</Field>
										</FormControl>
										<br />
										<br />

										{!errors.potential_user_email &&
										!errors.role &&
										touched.potential_user_email &&
										touched.role ? (
											<Button
												variant="contained"
												color="primary"
												disabled={isSubmitting}
												onClick={submitForm}
											>
												{InviteNewLogisticsAdminInfo.isLoading
													? "Processing..."
													: InviteNewLogisticsAdminInfo.isError
													? "Error!"
													: InviteNewLogisticsAdminInfo.isSuccess
													? "Done!"
													: "	Send Invite"}
											</Button>
										) : (
											""
										)}
									</Grid>
									<Grid item xs={12} lg={2}></Grid>
								</Grid>
							</Form>
						)}
					</Formik>
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						color="secondary"
						onClick={() => {
							setAddNewUserModalState(false);
							InviteNewLogisticsAdminInfo.reset();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				onClose={toggleUserActionModal}
				aria-labelledby="user-action-dialog"
				open={userActionDialogState}
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="simple-dialog-title">Role</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{viewedAdminData.first_name} {viewedAdminData.last_name} (
						{viewedAdminData.username}) : {viewedAdminData.role}
					</DialogContentText>
					<List>
						<ListItem>
							<ListItemText
								id="user-approved-state"
								primary={
									ApproveLogisticsAdminInfo.isLoading
										? "Approving..."
										: ApproveLogisticsAdminInfo.isError
										? "Error!"
										: ApproveLogisticsAdminInfo.isSuccess
										? "Approved!"
										: viewedAdminData.approved
										? "Approved"
										: "Approve"
								}
							/>
							<ListItemSecondaryAction>
								<Switch
									edge="end"
									onChange={() =>
										ApproveLogisticsAdminMutate(viewedAdminData.uuid, {
											onSuccess: () => ApproveLogisticsAdminInfo.reset(),
										})
									}
									checked={viewedAdminData.approved}
									inputProps={{ "aria-labelledby": "user-approved-state" }}
									disabled={viewedAdminData.approved}
								/>
							</ListItemSecondaryAction>
						</ListItem>
						<ListItem>
							<ListItemText
								id="user-blocked-state"
								primary={
									BlockLogisticsAdminInfo.isLoading ||
									UnBlockLogisticsAdminInfo.isLoading
										? "Processing..."
										: BlockLogisticsAdminInfo.isError ||
										  UnBlockLogisticsAdminInfo.isError
										? "Error!"
										: BlockLogisticsAdminInfo.isSuccess ||
										  UnBlockLogisticsAdminInfo.isSuccess
										? "Done!"
										: viewedAdminData.blocked
										? "Unblock"
										: "Block"
								}
							/>
							<ListItemSecondaryAction>
								<Switch
									edge="end"
									onChange={() => {
										if (viewedAdminData.blocked) {
											UnBlockLogisticsAdminMutate(viewedAdminData.uuid, {
												onSuccess: () => {
													UnBlockLogisticsAdminInfo.reset();
												},
											});
										}
										if (viewedAdminData.blocked === false) {
											BlockLogisticsAdminMutate(viewedAdminData.uuid, {
												onSuccess: () => {
													BlockLogisticsAdminInfo.reset();
												},
											});
										}
									}}
									checked={viewedAdminData.blocked}
									inputProps={{ "aria-labelledby": "user-blocked-state" }}
								/>
							</ListItemSecondaryAction>
						</ListItem>
						<ListItem>
							<ListItemText
								id="user-delete-state"
								primary={
									RemoveLogisticsAdminInfo.isLoading
										? "Removing user..."
										: RemoveLogisticsAdminInfo.isError
										? "Error!"
										: RemoveLogisticsAdminInfo.isSuccess
										? "Removed!"
										: "Remove"
								}
							/>
							<ListItemSecondaryAction>
								<IconButton
									edge="end"
									aria-label="user-delete-state"
									onClick={toggleDelete}
								>
									<DeleteIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
						<Collapse in={deleteState}>
							<Alert
								severity="warning"
								action={
									<Fragment>
										<Button
											onClick={() =>
												RemoveLogisticsAdminMutate(viewedAdminData.uuid, {
													onSuccess: () => {
														RemoveLogisticsAdminInfo.reset();
													},
												})
											}
										>
											Confirm
										</Button>
										<Button onClick={toggleDelete}>Cancel</Button>
									</Fragment>
								}
							>
								<AlertTitle>Remove User Confirmation</AlertTitle>
								You are about to remove this admin <strong>
									{" "}
									permanently
								</strong>{" "}
								from the list of roles in your store!
							</Alert>
						</Collapse>
						<ListItem>
							<ListItemText
								id="user-change-role-state"
								primary={
									ChangeLogisticsRoleInfo.isLoading
										? "Changing role..."
										: ChangeLogisticsRoleInfo.isError
										? "Error!"
										: ChangeLogisticsRoleInfo.isSuccess
										? "Done!"
										: "Change role"
								}
							/>
							<ListItemSecondaryAction>
								<IconButton
									edge="end"
									aria-label="user-change-role-state"
									onClick={toggleChangeRole}
								>
									<EditIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
						<Collapse in={changeRoleState}>
							<Formik
								initialValues={{
									role: viewedAdminData.role,
								}}
								onSubmit={(values, { setSubmitting }) => {
									setTimeout(() => {
										setSubmitting(false);
										ChangeLogisticsRoleMutate(
											{ id: viewedAdminData.uuid, role: values.role },
											{
												onSuccess: () => {
													ChangeLogisticsRoleInfo.reset();
												},
											}
										);
									}, 500);
								}}
							>
								{({ submitForm, isSubmitting }) => (
									<Form>
										<Grid container>
											<Grid item xs={12} lg={6}>
												{isSubmitting && <LinearProgress />}
												<FormControl>
													<Field
														component={Select}
														name="role"
														inputProps={{
															id: "change-role-field",
														}}
														variant="outlined"
														style={{ width: "100%" }}
													>
														<MenuItem value={LOGISTICS_MANAGER}>
															Manager
														</MenuItem>
														<MenuItem value={LOGISTICS_DISPATCHER}>
															Dispatcher
														</MenuItem>
													</Field>
												</FormControl>
											</Grid>
											<Grid item xs={12} lg={6}>
												<br />
												<Button
													variant="contained"
													color="primary"
													disabled={isSubmitting}
													onClick={submitForm}
												>
													Change
												</Button>
											</Grid>
										</Grid>
									</Form>
								)}
							</Formik>
						</Collapse>
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={toggleUserActionModal}>Close</Button>
				</DialogActions>
			</Dialog>

			<Box className={styles.root} boxShadow={0}>
				<MaterialTable
					style={{
						width: 1230,
					}}
					isLoading={
						GetLogisticsAdminsQuery.isLoading ||
						GetLogisticsAdminsQuery.isFetching
					}
					title="Admins"
					columns={[
						{
							title: "Avatar",
							field: "avatar",
							render: (rowData) => <Avatar>{rowData.avatar}</Avatar>,
						},
						{
							title: "Username",
							field: "username",
							render: (rowData) => (
								<Typography variant="subtitle1">
									{_.capitalize(rowData.username)}
									{currentUser.email === rowData.email ? "(you)" : ""}
								</Typography>
							),
						},
						{ title: "Full Name", field: "fullname" },
						{ title: "Email", field: "email" },
						{ title: "Role", field: "role" },
						{ title: "Joined", field: "joined" },
						{ title: "Approved", field: "approved", type: "boolean" },
						{ title: "Blocked", field: "blocked", type: "boolean" },
						{ title: "data", field: "data", hidden: true },
					]}
					data={logisticsAdminsArr}
					options={{
						search: true,
						actionsColumnIndex: -1,
					}}
					actions={[
						{
							icon: "save",
							tooltip: "Save User",
							onClick: (event, rowData: any) => {
								setViewedAdminData({ ...rowData.data });
								toggleUserActionModal();
							},
						},
					]}
					components={{
						Action: (props) => (
							<Button
								aria-label="view-vendor-details"
								size="medium"
								onClick={(event) => props.action.onClick(event, props.data)}
							>
								View
							</Button>
						),
						Toolbar: (props) => (
							<div>
								<Grid container>
									<Grid item lg={3}>
										<Tooltip title="Add a new admin" aria-label="add-product">
											<Button
												variant="outlined"
												color="primary"
												className={styles.add_new_user_button}
												startIcon={<Icon>add</Icon>}
												onClick={toggleAddNewUserModal}
												disabled={status !== "D"}
											>
												Add New Admin
											</Button>
										</Tooltip>
									</Grid>
									<Grid item lg={9}>
										<MTableToolbar {...props} />
									</Grid>
								</Grid>
							</div>
						),
					}}
				/>
			</Box>
		</DashBoardLayout>
	);
};

export default LogisticsAdminMgt;
