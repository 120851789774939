import { useMutation, queryCache } from "react-query";
import firebase from "../../../config/firebaseConfig";

interface Props {
	chat_id: string;
	brand_id: string;
	amount: number;
}

const transactionComplete = async (values: Props) => {
	const transactionCompletePromise = await firebase
		.firestore()
		.collection("Conversations")
		.doc(values.chat_id)
		.set(
			{
				transactionComplete: {
					status: true,
					date: firebase.firestore.FieldValue.serverTimestamp(),
				},
			},
			{ merge: true }
		);

	const vendorwalletDocRef = firebase
		.firestore()
		.collection("Wallets")
		.doc(values.brand_id);
	//run transaction on vendor balance
	const vendortransactionpromise = firebase
		.firestore()
		.runTransaction((transaction: any) => {
			return transaction.get(vendorwalletDocRef).then((transactionDoc: any) => {
				if (!transactionDoc.exists) {
				}

				let newIncome = transactionDoc.data().income + values.amount;

				transaction.update(vendorwalletDocRef, {
					income: newIncome,
				});
			});
		});

	const ekiojawalletDocRef = firebase
		.firestore()
		.collection("Wallets")
		.doc("4Ip8FlLBP9E7ITWnjOO7");

	//run transaction on vendor balance
	const deductEkiojatransactionpromise = firebase
		.firestore()
		.runTransaction((transaction: any) => {
			return transaction.get(ekiojawalletDocRef).then((transactionDoc: any) => {
				if (!transactionDoc.exists) {
				}

				let newIncome = transactionDoc.data().order - values.amount;

				transaction.update(ekiojawalletDocRef, {
					order: newIncome,
				});
			});
		});

	return Promise.all([
		transactionCompletePromise,
		vendortransactionpromise,
		deductEkiojatransactionpromise,
	]);
};

const useTransactionComplete = () => {
	return useMutation(transactionComplete, {
		onSettled: (returnedData, error, sentVaribles: any) => {
			queryCache.invalidateQueries([
				"chatCurrentConversation",
				sentVaribles.chat_id,
			]);
			queryCache.refetchQueries(
				["chatCurrentConversation", sentVaribles.chat_id],
				{ active: true, exact: true }
			);
		},
	});
};

export default useTransactionComplete;
