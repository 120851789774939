
import { useMutation} from "react-query";
import firebase from '../../config/firebaseConfig';


const acceptProductStory = (updatedata: any)=>{
return firebase.firestore().collection('BrandStories').doc(updatedata.id).set({
    pending_review:false,
    to_be_reviewed:{
        status:true,
        on:firebase.firestore.FieldValue.serverTimestamp(),
    },
    reviewedById:updatedata.reviewedById,
    reviewedByName:updatedata.reviewedByName,
    commission:updatedata.commission, 
},{merge:true});

}


 const useAcceptProductStory = ()=>{
    return useMutation(acceptProductStory);
}


export default useAcceptProductStory;