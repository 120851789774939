import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
// import { createBrowserHistory } from 'history';
import LoadingScreen from '../LoadingScreen'; // change it to your custom component

//routes
import * as ROUTES from '../../constants/routes';

//roles
import { ADMINSTRATOR , SUPER_ADMINSTRATOR , FINANCE} from '../../constants/roles';

//feedback
// import { FEEDBACK } from '../../constants/feedback';

const locationHelper = locationHelperBuilder({});
// const browserHistory = createBrowserHistory();



export const UserIsAuthenticatedAndIsAdmin = connectedRouterRedirect({
    wrapperDisplayName: 'UserIsAuthenticatedAndIsAdmin',
    AuthenticatingComponent: LoadingScreen,
    allowRedirectBack: true,
    redirectPath: (state, ownProps) =>
        locationHelper.getRedirectQueryParam(ownProps) || ROUTES.HOME,
    authenticatingSelector: ({ firebase: { auth, profile, isInitializing } }: any) =>
        !auth.isLoaded || !profile.isLoaded || isInitializing === true,
    authenticatedSelector: ({ firebase: { profile } }: any) =>
        profile.role === ADMINSTRATOR || profile.role === SUPER_ADMINSTRATOR  || profile.role === FINANCE ? profile.approved  && profile.blocked === false:false,


});
export const UserIsAuthenticated = connectedRouterRedirect({
    wrapperDisplayName: 'UserIsAuthenticated',
    AuthenticatingComponent: LoadingScreen,
    allowRedirectBack: true,
    redirectPath: (state, ownProps) =>
        locationHelper.getRedirectQueryParam(ownProps) || ROUTES.HOME,
    authenticatingSelector: ({ firebase: { auth, profile, isInitializing } }: any) =>
        !auth.isLoaded || !profile.isLoaded || isInitializing === true,
    authenticatedSelector: ({ firebase: { auth, profile } }: any) =>
        auth.isLoaded && !auth.isEmpty && profile.approved  && profile.blocked === false,

});

export const UserIsNotAuthenticated = connectedRouterRedirect({
    wrapperDisplayName: 'UserIsNotAuthenticated',
    AuthenticatingComponent: LoadingScreen,
    allowRedirectBack: false,
    redirectPath: (state, ownProps) =>
        locationHelper.getRedirectQueryParam(ownProps) || ROUTES.DASHBOARD,
    authenticatingSelector: ({ firebase: { auth, isInitializing } }: any) =>
        !auth.isLoaded || isInitializing === true,
    authenticatedSelector: ({ firebase: { auth } }: any) =>
        auth.isLoaded && auth.isEmpty,

});