import React, { useState, useEffect, Fragment } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputLabel from "@material-ui/core/InputLabel";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import LocalMallTwoToneIcon from "@material-ui/icons/LocalMallTwoTone";
import ThumbUpTwoToneIcon from "@material-ui/icons/ThumbUpTwoTone";
import RedeemTwoToneIcon from "@material-ui/icons/RedeemTwoTone";
import LocalShippingTwoToneIcon from "@material-ui/icons/LocalShippingTwoTone";
import PanToolTwoToneIcon from "@material-ui/icons/PanToolTwoTone";
import SentimentVerySatisfiedTwoToneIcon from "@material-ui/icons/SentimentVerySatisfiedTwoTone";
import ReplayTwoToneIcon from "@material-ui/icons/ReplayTwoTone";
import Chip from "@material-ui/core/Chip";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import MarketPlaceLayout from "./Layouts/MarketPlaceLayout";

import { useParams } from "react-router-dom";

//react - redux
import { useSelector, useDispatch } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";

import moment from "moment";

import { IOrder } from "../ts/interfaces/order";

import { truncate } from "../utils/index";

import LoadingScreen from "../components/LoadingScreen";

//number format
import NumberFormat from "react-number-format";

//formik
import { Field, Form, Formik } from "formik";
import { Select } from "formik-material-ui";

import { CancelOrder } from "../store/actions/logistics/cancelOrder";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card_wrapper: {
      marginBottom: 10,
      marginTop: 10,
    },
    product_image: {
      width: "100%",
      minHeight: 120,
      "& img": {
        width: "100%",
        height: 120,
      },
    },
    product_info_wrapper: {
      width: "100%",
      minHeight: 120,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    waiting_state: {
      backgroundColor: "#4287f5",
      padding: 5,
      textTransform: "uppercase",
      color: "white",
      borderRadius: 5,
      fontSize: 12,
    },
    done_state: {
      backgroundColor: "#8bf50a",
      padding: 5,
      textTransform: "uppercase",
      color: "white",
      borderRadius: 5,
      fontSize: 12,
    },
    wrapper: {
      width: "100%",
      height: 300,
    },
    order_list_wrapper: {
      maxWidth: "100%",
      height: 260,
      whiteSpace: "nowrap",
      WebkitOverflowScrolling: "touch",
      msOverflowStyle: "-ms-autohiding-scrollbar",
      overflowY: "hidden",
      overflowX: "auto",
      display: "inline-block",
      verticalAlign: "middle",
      padding: 10,
    },
    order_list_item_wrapper: {
      width: 250,
      height: 250,
      marginRight: 10,
      display: "inline-block",
    },

    order_list_item_info: {
      width: "100%",
      minHeight: 120,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "flex-end",
      flexDirection: "column",
    },
    media: {
      height: 140,
    },
    group_order_wrapper: {
      backgroundColor: "#cbffc2",
      marginTop: 10,
      marginBottom: 10,
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    paper: {
      padding: "6px 16px",
    },
    secondaryTail: {
      backgroundColor: "green",
    },
    spacing: {
      marginBottom: 0,
    },
    connect_default: {
      height: 50,
    },
    connector_success: {
      height: 70,
      backgroundColor: "green",
    },
    connector_error: {
      height: 70,
      backgroundColor: "red",
    },
    dot_success: {
      backgroundColor: "green",
      color: "black",
    },
    dot_error: {
      backgroundColor: "red",
      color: "black",
    },
    action_wrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: 10,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {}

const MarketPlaceOrdersDetail = (props: Props) => {
  const styles = useStyles();

  const dispatch = useDispatch();

  const [orderData, setOrderData] = useState<IOrder[]>([]);

  const [totalSubTotal, setTotalSubTotal] = useState(0);

  const { order_uuid } = useParams<any>();

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [openTrackItemDialog, setOpenTrackItemDialog] = useState(false);

  const [cancelItemData, setCancelItemData] = useState<any>({});

  const [orderTimelineData, setOrderTimelineData] = useState<any>({});

  const handleCancelItemOpen = () => {
    setOpenCancelDialog(true);
  };
  const handleCancelItemClose = () => {
    setOpenCancelDialog(false);
  };

  const handleTrackItemOpen = () => {
    setOpenTrackItemDialog(true);
  };

  const handleTrackItemClose = () => {
    setOpenTrackItemDialog(false);
  };

  useFirestoreConnect([
    {
      collection: "Orders",
      doc: order_uuid,
    },
    {
      collection: "OrdersTimeline",
      where: [["order_customer_id", "==", "demo-customer"]],
      storeAs: "OrdersTimeline",
    },
  ]);

  const orders = useSelector((state: any) => state.firestore.ordered.Orders);

  const ordersTimeline = useSelector(
    (state: any) => state.firestore.data["OrdersTimeline"]
  );

  useEffect(() => {
    if (isLoaded(orders) && isLoaded(ordersTimeline)) {
      let order_data: any[] = [];
      if (orders !== null) {
        for (var orderkey in orders) {
          order_data.push({
            ...orders[orderkey],
            order_uuid: orders[orderkey].id,
          });
        }
      }

      const mapOrderIdToitems = _.map(order_data, (item) => {
        return {
          ...item,
          items: _.map(item.items, (elems) => {
            return {
              ...elems,
              order_id: item.order_id,
              order_uuid: item.order_uuid,
            };
          }),
        };
      });

      let orderTimeline_data: any[] = [];
      if (ordersTimeline !== null) {
        for (var orderTimelinekey in ordersTimeline) {
          orderTimeline_data.push({
            ...ordersTimeline[orderTimelinekey],
            orderTimelinekey_uuid: orderTimelinekey,
          });
        }
      }

      const sorted = _.orderBy(mapOrderIdToitems, ["added_on"], ["desc"]);

      const total_cart_item_subtotal_and_quantity = _.map(sorted, (item) =>
        _.map(item.items, (itemselem) => itemselem.subtotal)
      );

      const result_total_cart_item_subtotal = _.sum(
        _.flattenDeep(total_cart_item_subtotal_and_quantity)
      );

      setTotalSubTotal(result_total_cart_item_subtotal);

      const all_items_in_orders_and_ordertimeline = _.map(sorted, (item) => {
        return {
          ...item,
          items: _.intersectionWith(
            _.cloneDeep(item.items),
            orderTimeline_data,
            function (x: any, y: any) {
              return (
                x.order_id === y.the_order_id &&
                x.product_id === y.order_product_id &&
                _.assign(x, y)
              );
            }
          ),
        };
      });

      setOrderData([...all_items_in_orders_and_ordertimeline]);
    }
  }, [orders, ordersTimeline]);

  if (_.isEmpty(orderData)) return <LoadingScreen />;
  return (
    <MarketPlaceLayout>
      <div style={{ marginTop: 100 }} />
      <Dialog
        fullScreen
        open={openCancelDialog}
        onClose={handleCancelItemClose}
        TransitionComponent={Transition}
        id="cancel-dialog"
      >
        <AppBar className={styles.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCancelItemClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={styles.title}>
              Cancel item shipping
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item xs={12} lg={3}></Grid>
          <Grid item xs={12} lg={6}>
            <Card className={styles.card_wrapper}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={3}>
                    <div className={styles.product_image}>
                      <img
                        src={`https://firebasestorage.googleapis.com/v0/b/ekioja-7bb9f.appspot.com/o/images%2F${cancelItemData.image}?alt=media`}
                        alt={cancelItemData.name}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <div className={styles.product_info_wrapper}>
                      <Typography variant="subtitle1" color="textPrimary">
                        {cancelItemData.name}
                      </Typography>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary">
                          QTY :X{cancelItemData.quantity}
                        </Typography>
                        <Typography variant="subtitle1" color="textPrimary">
                          <NumberFormat
                            //@ts-ignore
                            value={cancelItemData.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₦"}
                          />
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Formik
              initialValues={{
                reason: "",
                persona: "customer",
                persona_id: "demo-customer",
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  dispatch(
                    CancelOrder(cancelItemData.orderTimelinekey_uuid, values)
                  );
                }, 1000);
              }}
            >
              {({ submitForm, isSubmitting, values }) => (
                <Form>
                  {isSubmitting && <LinearProgress />}
                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="cancel-item-reason">
                          What is the reason?
                        </InputLabel>
                        <Field
                          component={Select}
                          required
                          name="reason"
                          inputProps={{
                            id: "cancel-item-reason",
                          }}
                          fullWidth
                        >
                          <MenuItem value="This is a duplicate order">
                            This is a duplicate order
                          </MenuItem>
                          <MenuItem value="Found a better offer in Ekioja">
                            Found a better offer in Ekioja
                          </MenuItem>
                          <MenuItem value="Found a better offer outside Ekioja">
                            Found a better offer outside Ekioja
                          </MenuItem>
                          <MenuItem value="I was testing Ekioja">
                            I was testing Ekioja
                          </MenuItem>
                          <MenuItem value="Not interested in order anymore">
                            Not interested in order anymore
                          </MenuItem>
                          <MenuItem value="Shipping Fee is High">
                            Shipping Fee is High
                          </MenuItem>
                          <MenuItem value=" Change delivery address/method">
                            Change delivery address/method
                          </MenuItem>
                          <MenuItem value=" Change payment method">
                            Change payment method
                          </MenuItem>
                          <MenuItem value="Want to change voucher code">
                            Want to change voucher code
                          </MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br />
                  {values.reason !== "" && (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={() => {
                        submitForm();
                        handleCancelItemClose();
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12} lg={3}></Grid>
        </Grid>
      </Dialog>
      <Dialog
        fullScreen
        open={openTrackItemDialog}
        onClose={handleTrackItemClose}
        TransitionComponent={Transition}
        id="track-item-dialog"
      >
        <AppBar className={styles.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleTrackItemClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={styles.title}>
              Track item shipping
            </Typography>
            <Button autoFocus color="inherit" onClick={handleTrackItemClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item xs={12} lg={3}></Grid>
          <Grid item xs={12} lg={6}>
            <Timeline align="alternate">
              {orderTimelineData.order_placed?.status && (
                <Fragment>
                  <TimelineItem className={styles.spacing}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {moment(
                          orderTimelineData.order_placed.date.toDate()
                        ).format("YYYY-MM-DD")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot className={styles.dot_success}>
                        <LocalMallTwoToneIcon />
                      </TimelineDot>
                      <TimelineConnector className={styles.connector_success} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h6" component="h1">
                          Order Placed
                        </Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                </Fragment>
              )}
              {orderTimelineData.pending_confirmation?.status && (
                <Fragment>
                  <TimelineItem className={styles.spacing}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {moment(
                          orderTimelineData.pending_confirmation.date.toDate()
                        ).format("YYYY-MM-DD")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot className={styles.dot_success}>
                        <ThumbUpTwoToneIcon />
                      </TimelineDot>
                      <TimelineConnector className={styles.connector_success} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h6" component="h1">
                          Pending Confirmation
                        </Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                </Fragment>
              )}
              {orderTimelineData.waiting_to_be_shipped?.status && (
                <Fragment>
                  <TimelineItem className={styles.spacing}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {moment(
                          orderTimelineData.waiting_to_be_shipped.date.toDate()
                        ).format("YYYY-MM-DD")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot className={styles.dot_success}>
                        <RedeemTwoToneIcon />
                      </TimelineDot>
                      <TimelineConnector className={styles.connector_success} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h6" component="h1">
                          Waiting to be shipped
                        </Typography>
                        <Typography variant="caption">
                          Item is processed and vendor is waiting for the
                          delivery man
                        </Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                </Fragment>
              )}
              {orderTimelineData.shipped?.status && (
                <Fragment>
                  <TimelineItem className={styles.spacing}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {moment(orderTimelineData.shipped.date.toDate()).format(
                          "YYYY-MM-DD"
                        )}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot className={styles.dot_success}>
                        <LocalShippingTwoToneIcon />
                      </TimelineDot>
                      <TimelineConnector className={styles.connector_success} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h6" component="h1">
                          Shipped
                        </Typography>
                        <Typography variant="caption">
                          The item is being delivered you
                        </Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                </Fragment>
              )}
              {orderTimelineData.out_for_delivery?.status && (
                <Fragment>
                  <TimelineItem className={styles.spacing}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {moment(
                          orderTimelineData.out_for_delivery.date.toDate()
                        ).format("YYYY-MM-DD")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot className={styles.dot_success}>
                        <LocalShippingTwoToneIcon />
                      </TimelineDot>
                      <TimelineConnector className={styles.connector_success} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h6" component="h1">
                          Out for delivery
                        </Typography>
                        <Typography variant="caption">
                          The item is being delivered you
                        </Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                </Fragment>
              )}
              {orderTimelineData.delivered?.status && (
                <Fragment>
                  <TimelineItem className={styles.spacing}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {moment(
                          orderTimelineData.delivered.date.toDate()
                        ).format("YYYY-MM-DD")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot className={styles.dot_success}>
                        <SentimentVerySatisfiedTwoToneIcon />
                      </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h6" component="h1">
                          Delivered
                        </Typography>
                        <Typography variant="caption">
                          The items were delivered to you successfully
                        </Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                </Fragment>
              )}
              {orderTimelineData.returned?.status && (
                <Fragment>
                  <div className={styles.waiting_state}>RETURNED</div>
                  <Typography variant="subtitle2" color="textPrimary">
                    {moment(orderTimelineData.returned.date.toDate()).format(
                      "YYYY-MM-DD"
                    )}
                  </Typography>
                </Fragment>
              )}
              {orderTimelineData.canceled?.status && (
                <Fragment>
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                  ></Typography>
                  <TimelineItem className={styles.spacing}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {moment(
                          orderTimelineData.canceled.date.toDate()
                        ).format("YYYY-MM-DD")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot className={styles.dot_error}>
                        <PanToolTwoToneIcon />
                      </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h6" component="h1">
                          Order was cancelled
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {orderTimelineData.canceled.persona === "customer" &&
                            "Cancelled by You"}
                          {orderTimelineData.canceled.persona ===
                            "vendor" && "Cancelled by Vendor"}
                          {orderTimelineData.canceled.persona ===
                            "logistics" && "Cancelled by Logistics"}
                        </Typography>
                        <br />
                        <Typography variant="caption" color="textSecondary">
                          Reason :{orderTimelineData.canceled.reason}
                        </Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                </Fragment>
              )}
            </Timeline>
          </Grid>
          <Grid item xs={12} lg={3}></Grid>
        </Grid>
      </Dialog>
      <Container>
        <Typography variant="h5" color="textPrimary">
          Orders details
        </Typography>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={1}></Grid>
          <Grid item xs={12} lg={10}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Card>
                  <CardContent>
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={`Orders ${orderData[0].order_id}`}
                          secondary={
                            <React.Fragment>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                {orderData[0].items.length} Items
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                Placed on{" "}
                                {moment(orderData[0].added_on.toDate()).format(
                                  "YYYY-MM-DD"
                                )}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                Total:{" "}
                                <NumberFormat
                                  value={
                                    totalSubTotal +
                                    orderData[0].items.length * 950
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                />
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>

                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary="Payment Method"
                          secondary={
                            <React.Fragment>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                Pay on Delivery
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary="Payment Details"
                          secondary={
                            <React.Fragment>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                Items total:{" "}
                                <NumberFormat
                                  value={totalSubTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                />
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                Shipping Fees:{" "}
                                <NumberFormat
                                  value={orderData[0].items.length * 950}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                />
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                Total:{" "}
                                <NumberFormat
                                  value={
                                    totalSubTotal +
                                    orderData[0].items.length * 950
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"₦"}
                                />
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Delivery Method"
                          secondary={
                            <React.Fragment>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                {orderData[0].deliver_method}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Shipping Address"
                          secondary={
                            <React.Fragment>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                {orderData[0].recipient.customer}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                {orderData[0].recipient.phonenumber}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textPrimary"
                                display="block"
                              >
                                {orderData[0].delivery_address}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={8}>
                {_.map(orderData[0].items, (item, index) => (
                  <Card className={styles.card_wrapper} key={index}>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={3}>
                          <div className={styles.product_image}>
                            <img
                              src={`https://firebasestorage.googleapis.com/v0/b/ekioja-7bb9f.appspot.com/o/images%2F${item.product_image}?alt=media`}
                              alt={`${item.product_name}`}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                          <div className={styles.product_info_wrapper}>
                            <Typography variant="subtitle1" color="textPrimary">
                              {truncate(item.product_name, 60)}
                            </Typography>
                            <Typography variant="subtitle1" color="textPrimary">
                              X{item.quantity}
                            </Typography>
                            <Typography variant="subtitle1" color="textPrimary">
                              <NumberFormat
                                //@ts-ignore
                                value={item.subtotal}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₦"}
                              />
                            </Typography>

                            {item.order_placed?.current === true && (
                              <Fragment>
                                <Chip
                                  icon={
                                    <LocalMallTwoToneIcon
                                      style={{ color: "green" }}
                                    />
                                  }
                                  label="ORDER PLACED"
                                  variant="outlined"
                                  style={{
                                    color: "green",
                                    border: "1px green solid",
                                  }}
                                />
                                <Typography
                                  variant="subtitle2"
                                  color="textPrimary"
                                >
                                  {moment(
                                    item.order_placed.date.toDate()
                                  ).format("YYYY-MM-DD")}
                                </Typography>
                              </Fragment>
                            )}
                            {item.pending_confirmation?.current === true && (
                              <Fragment>
                                <Chip
                                  icon={
                                    <ThumbUpTwoToneIcon
                                      style={{ color: "green" }}
                                    />
                                  }
                                  label="ITEM CONFIRMED"
                                  variant="outlined"
                                  style={{
                                    color: "green",
                                    border: "1px green solid",
                                  }}
                                />
                                <Typography
                                  variant="subtitle2"
                                  color="textPrimary"
                                >
                                  {moment(
                                    item.pending_confirmation.date.toDate()
                                  ).format("YYYY-MM-DD")}
                                </Typography>
                              </Fragment>
                            )}

                            {item.waiting_to_be_shipped?.current === true && (
                              <Fragment>
                                <Chip
                                  icon={
                                    <RedeemTwoToneIcon
                                      style={{ color: "orange" }}
                                    />
                                  }
                                  label="WAITING TO BE SHIPPED"
                                  variant="outlined"
                                  style={{
                                    color: "orange",
                                    border: "1px orange solid",
                                  }}
                                />
                                <Typography
                                  variant="subtitle2"
                                  color="textPrimary"
                                >
                                  {moment(
                                    item.waiting_to_be_shipped.date.toDate()
                                  ).format("YYYY-MM-DD")}
                                </Typography>
                              </Fragment>
                            )}
                            {item.shipped?.current === true && (
                              <Fragment>
                                <Chip
                                  icon={
                                    <LocalShippingTwoToneIcon
                                      style={{ color: "green" }}
                                    />
                                  }
                                  label="SHIPPED"
                                  variant="outlined"
                                  style={{
                                    color: "green",
                                    border: "1px green solid",
                                  }}
                                />
                                <Typography
                                  variant="subtitle2"
                                  color="textPrimary"
                                >
                                  {moment(item.shipped.date.toDate()).format(
                                    "YYYY-MM-DD"
                                  )}
                                </Typography>
                              </Fragment>
                            )}
                            {item.out_for_delivery?.current === true && (
                              <Fragment>
                                <Chip
                                  icon={
                                    <LocalShippingTwoToneIcon
                                      style={{ color: "green" }}
                                    />
                                  }
                                  label="OUT FOR DELIVERY"
                                  variant="outlined"
                                  style={{
                                    color: "green",
                                    border: "1px green solid",
                                  }}
                                />
                                <Typography
                                  variant="subtitle2"
                                  color="textPrimary"
                                >
                                  {moment(
                                    item.out_for_delivery.date.toDate()
                                  ).format("YYYY-MM-DD")}
                                </Typography>
                              </Fragment>
                            )}
                            {item.delivered?.current === true && (
                              <Fragment>
                                <Chip
                                  icon={
                                    <SentimentVerySatisfiedTwoToneIcon
                                      style={{ color: "green" }}
                                    />
                                  }
                                  label="DELIVERED"
                                  variant="outlined"
                                  color="secondary"
                                />
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    color: "green",
                                    border: "1px green solid",
                                  }}
                                >
                                  {moment(item.delivered.date.toDate()).format(
                                    "YYYY-MM-DD"
                                  )}
                                </Typography>
                              </Fragment>
                            )}
                            {item.returned?.current === true && (
                              <Fragment>
                                <Chip
                                  icon={
                                    <ReplayTwoToneIcon
                                      style={{ color: "orange" }}
                                    />
                                  }
                                  label="RETURNED"
                                  variant="outlined"
                                  style={{
                                    color: "orange",
                                    border: "1px orange solid",
                                  }}
                                />
                                <Typography
                                  variant="subtitle2"
                                  color="textPrimary"
                                >
                                  {moment(item.returned.date.toDate()).format(
                                    "YYYY-MM-DD"
                                  )}
                                </Typography>
                              </Fragment>
                            )}
                            {item.canceled?.current === true && (
                              <Fragment>
                                <Chip
                                  icon={
                                    <PanToolTwoToneIcon
                                      style={{ color: "red" }}
                                    />
                                  }
                                  label="CANCELLED"
                                  variant="outlined"
                                  style={{
                                    color: "red",
                                    border: "1px red solid",
                                  }}
                                />
                                <Typography
                                  variant="subtitle2"
                                  color="textPrimary"
                                >
                                  {moment(item.canceled.date.toDate()).format(
                                    "YYYY-MM-DD"
                                  )}
                                </Typography>
                              </Fragment>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      {item.order_placed?.current === true && (
                        <Button
                          onClick={() => {
                            handleCancelItemOpen();
                            setCancelItemData({
                              image: item.product_image,
                              name: item.product_name,
                              quantity: item.quantity,
                              price: item.subtotal,
                              orderTimelinekey_uuid: item.orderTimelinekey_uuid,
                            });
                          }}
                        >
                          Cancel Order
                        </Button>
                      )}
                      {item.pending_confirmation?.current === true && (
                        <Button
                          onClick={() => {
                            handleCancelItemOpen();
                            setCancelItemData({
                              image: item.product_image,
                              name: item.product_name,
                              quantity: item.quantity,
                              price: item.subtotal,
                            });
                          }}
                        >
                          Cancel Order
                        </Button>
                      )}

                      <Button
                        onClick={() => {
                          handleTrackItemOpen();
                          setOrderTimelineData({
                            order_placed: item.order_placed,
                            pending_confirmation: item.pending_confirmation,
                            waiting_to_be_shipped: item.waiting_to_be_shipped,
                            shipped: item.shipped,
                            out_for_delivery: item.out_for_delivery,
                            delivered: item.delivered,
                            returned: item.returned,
                            canceled: item.canceled,
                          });
                        }}
                      >
                        {item.delivered?.current || item.canceled?.current
                          ? "View History"
                          : "Track this item"}
                      </Button>
                    </CardActions>
                  </Card>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={1}></Grid>
        </Grid>
      </Container>
    </MarketPlaceLayout>
  );
};

export default MarketPlaceOrdersDetail;
