import React, { Fragment, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CategoryIcon from "@material-ui/icons/Category";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";
//formik
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";

//react - redux
import { useSelector, useDispatch } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//actions
import { DeleteSuggestedCategory } from "../store/actions/categories/deleteSuggestedCategory";
import { DeleteNotLiveCategory } from "../store/actions/categories/deleteNotLiveCategory";
import { MoveFromSuggestedToNotLiveCategory } from "../store/actions/categories/moveFromSuggestedToNotLive";
import { MoveFromNotLiveToLiveCategory } from "../store/actions/categories/moveFromNotLiveToLive";
import { AddNewCategoryByAdmin } from "../store/actions/categories/addNewCategoryByAdmin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // margin: 'auto',
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 300,
      height: 330,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    page_header: {
      width: "100%",
      height: 100,
      backgroundColor: "#99ff99",
      paddingTop: 20,
      paddingLeft: 20,
    },
  })
);

const Categories = () => {
  const styles = useStyles();
  const [suggestedNichesData, setSuggestedNichesData] = useState<string[]>([]);
  const [liveNichesData, setLiveNichesData] = useState<string[]>([]);
  const [notLiveNichesData, setNotLiveNichesData] = useState<string[]>([]);

  const dispatch = useDispatch();

  useFirestoreConnect([
    {
      collection: "Ekioja",
    },
  ]);

  const Ekioja = useSelector((state: any) => state.firestore.data.Ekioja);

  useEffect(() => {
    if (isLoaded(Ekioja)) {
      // console.log(Ekioja);

      //get niches
      const getSuggestedNiches = Ekioja.suggestedNiches.values;
      const getLiveNiches = Ekioja.liveNiches.values;
      const getNotLiveNiches = Ekioja.notLiveNiches.values;

      setSuggestedNichesData([...getSuggestedNiches]);
      setLiveNichesData([...getLiveNiches]);
      setNotLiveNichesData([...getNotLiveNiches]);
    }
  }, [Ekioja]);

  const customList = (
    title: React.ReactNode,
    items: string[],
    showOptions?: boolean,
    toDelete?: string
  ) => (
    <Card>
      <CardHeader
        className={styles.cardHeader}
        avatar={showOptions === true ? <CategoryIcon /> : <DoneOutlineIcon />}
        title={title}
      />
      <Divider />
      <List className={styles.list} dense component="div" role="list">
        {items.map((value: string) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button>
              {showOptions === true && toDelete === "suggestedNiche" && (
                <DeleteIcon
                  onClick={() => dispatch(DeleteSuggestedCategory(value))}
                />
              )}
              {showOptions === true && toDelete === "notLiveNiches" && (
                <DeleteIcon
                  onClick={() => dispatch(DeleteNotLiveCategory(value))}
                />
              )}
              <ListItemText id={labelId} primary={`${value}`} />
              {showOptions === true && toDelete === "suggestedNiche" && (
                <Tooltip title="Add to not live">
                  <ListItemIcon
                    onClick={() =>
                      dispatch(MoveFromSuggestedToNotLiveCategory(value))
                    }
                  >
                    <AddCircleOutlineIcon />
                  </ListItemIcon>
                </Tooltip>
              )}
              {showOptions === true && toDelete === "notLiveNiches" && (
                <Tooltip title="Add to live">
                  <ListItemIcon
                    onClick={() =>
                      dispatch(MoveFromNotLiveToLiveCategory(value))
                    }
                  >
                    <AddCircleOutlineIcon />
                  </ListItemIcon>
                </Tooltip>
              )}
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  if (!isLoaded(Ekioja)) return null;
  return (
    <Fragment>
      <DashBoardLayout menuIndex={6}>
        <div className={styles.page_header}>
          <Typography variant="h4" color="primary">
            {" "}
            Categories Management
          </Typography>
        </div>
        <br />
        <Grid container>
          <Grid item xs={12} lg={7}>
            <Formik
              initialValues={{
                category: "",
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  // alert(JSON.stringify(values, null, 2));
                  dispatch(AddNewCategoryByAdmin(values.category));
                  resetForm();
                }, 500);
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                      {isSubmitting && <LinearProgress />}
                      <Field
                        component={TextField}
                        name="category"
                        fullWidth
                        placeholder="new category"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <br />

        <Divider />
        <br />
        <Grid container>
          <Grid item xs={12} lg={8}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
              className={styles.root}
            >
              <Grid item>
                {customList(
                  "Suggested  Categories / Niches",
                  suggestedNichesData,
                  true,
                  "suggestedNiche"
                )}
              </Grid>

              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    variant="outlined"
                    size="small"
                    className={styles.button}
                    // onClick={handleCheckedRight}
                    disabled
                    aria-label="move selected right"
                  >
                    Consider &gt;
                  </Button>
                </Grid>
              </Grid>

              <Grid item>
                {customList(
                  "Not Live Categories / Niches",
                  notLiveNichesData,
                  true,
                  "notLiveNiches"
                )}
                <Grid container direction="column" alignItems="center">
                  <Button
                    variant="outlined"
                    size="small"
                    className={styles.button}
                    disabled
                    aria-label="move selected right"
                  >
                    Go live &gt;
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                {customList("Live Categories / Niches ", liveNichesData)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DashBoardLayout>
    </Fragment>
  );
};

export default Categories;
