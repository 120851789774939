import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
import { LOGISTICS_DISPATCHER } from "../../constants/roles";
const createLogisticsAdminAccount = async (values: any) => {
	const response = await firebase
		.auth()
		.createUserWithEmailAndPassword(values.email, values.password);

	//add displayName
	const updateProfilePromise = await firebase
		.auth()
		.currentUser?.updateProfile({
			displayName: values.first_name + " " + values.last_name,
		});

	//create logistics company account if exist

	//create new doc in Admin collection
	const createCollectionPromise = await firebase
		.firestore()
		.collection("Admins")
		.doc(response.user?.uid)
		.set({
			first_name: values.first_name,
			last_name: values.last_name,
			username: values.username,
			role: values.role,
			entity: values.entity,
			logistics_role: values.logistics_role,
			email: values.email,
			deviceData: values.deviceData,
			phone_number: values.phone_number,
			approved: true,
			active: true,
			blocked: false,
			isNew: true,
			blocked_history: [],
			removed: false,
			removed_data: [],
			joined_on: firebase.firestore.FieldValue.serverTimestamp(),
		});

	//create wallet
	const createStoreWallet = await firebase
		.firestore()
		.collection("Wallets")
		.doc(response.user?.uid)
		.set({
			income: 0,
			owner_id: response.user?.uid,
		});

	const updateLogisticsadmin = await firebase
		.firestore()
		.collection("LogisticsCompanies")
		.doc(values.entity)
		.set(
			{
				admins: firebase.firestore.FieldValue.arrayUnion(response.user?.uid),
			},
			{ merge: true }
		);

	if (values.role === LOGISTICS_DISPATCHER) {
		firebase
			.firestore()
			.collection("LogisticsCompanies")
			.doc(values.entity)
			.set(
				{
					dispatchers: firebase.firestore.FieldValue.arrayUnion(
						response.user?.uid
					),
				},
				{ merge: true }
			);
	}
	//send email verification
	const sendEmailPromise = await firebase
		.auth()
		.currentUser?.sendEmailVerification();

	//allpromise
	const allPromise = Promise.all([
		updateProfilePromise,
		createCollectionPromise,
		sendEmailPromise,
		createStoreWallet,
		updateLogisticsadmin,
	]);

	return allPromise;
};

const useCreateLogisticsAdminAccount = () => {
	return useMutation(createLogisticsAdminAccount);
};

export default useCreateLogisticsAdminAccount;
