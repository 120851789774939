import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// import Box from "@material-ui/core/Box";
// import ListSubheader from "@material-ui/core/ListSubheader";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
// import MaterialTable from "material-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

//firestoreconnect
// import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
// import { isLoaded } from "react-redux-firebase";

//react - redux
import { useSelector } from "react-redux";

//utils
import // getStories,
// getStoriesCounts,
// truncate,
// getAdminForProductStoryCut,
// getCreativeUpcomingRevenueTimeFrame,
// getCreativesPendingClearanceRevenueTimeFrame,
// getCreativesEarnedRevenueTimeFrame,
// CreativeJoBNumber,
"../utils/index";

// import _ from "lodash";

//widgets
// import StoryWidget from "../components/DashBoardWidgets/StoryWidget";

//number format
// import NumberFormat from "react-number-format";

//routes
// import * as ROUTES from "../constants/routes";

// import LoadingScreen from "../components/LoadingScreen";

import useAcceptProductStory from "../hooks/brandstory/useAcceptProductStory";

import useGetBrandStory from "../hooks/creative/useGetBrandStory";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import BrandCreatives from "../components/Creatives/BrandCreatives";
import FaceBehindTheBrand from "../components/Creatives/FaceBehindTheBrand";
import MarketPlaceProduct from "../components/Creatives/MarketPlaceProduct";
import BrandStories from "../components/Creatives/BrandStories";
// import ProductStories from "../components/Creatives/ProductStories";
import BrandCreativesQuickLinks from "../components/Creatives/BrandCreativesQuickLinks";
import FaceBehindTheBrandQuickLinks from "../components/Creatives/FaceBehindTheBrandQuickLinks";
import MarketPlaceProductQuickLink from "../components/Creatives/MarketPlaceProductQuickLink";
// import BrandStoriesQuickLink from "../components/Creatives/BrandStoriesQuickLink";
// import ProductStoriesQuickLinks from "../components/Creatives/ProductStoriesQuickLinks";

// import useInitiateBrandCreativeJob from "../hooks/creative/useInitiateBrandCreativeJob";
//navigation hook
// import { useNavigate } from "../hooks/navigate";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//@ts-ignore
import AsyncSelect from "react-select/async";

import firebase from "../config/firebaseConfig";

//get image
import GetImageFromFirebase from "../components/GetImageFromFirebase";

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`pools-tabpanel-${index}`}
			aria-labelledby={`pools-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `pools-tab-${index}`,
		"aria-controls": `pools-tabpanel-${index}`,
	};
}

const loadShopNameOptions = async (inputValue: string) => {
	inputValue = inputValue.toLowerCase().replace(/\W/g, "");
	return new Promise((resolve) => {
		firebase
			.firestore()
			.collection("Stores")
			// .where('type_of_story', '==', 'Shop')
			.orderBy("shop_plainName")
			.startAt(inputValue)
			.endAt(inputValue + "\uf8ff")
			.get()
			.then((docs) => {
				if (!docs.empty) {
					const shopNames: any[] = [];
					docs.forEach(function (doc) {
						const tag = {
							value: {
								id: doc.data().unique_id,
								business_logo_id: doc.data().business_logo_id,
							},
							label: doc.data().business_name,
						};
						shopNames.push(tag);
					});
					return resolve(shopNames);
				} else {
					return resolve([]);
				}
			});
	});
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
		backButton: {
			marginRight: theme.spacing(1),
		},
		instructions: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		brand_story_thumbnail_wrapper: {
			width: 160,
			height: 160,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& img": {
				width: 140,
				height: 140,
			},
		},
		tabs: {
			borderRight: `1px solid ${theme.palette.divider}`,
		},
	})
);

const CreativeDashboard = () => {
	const styles = useStyles();

	const [
		AcceptProductStoryMutate,
		AcceptProductStoryInfo,
	] = useAcceptProductStory();

	// const [InitiateBrandCreativeJobMutate] = useInitiateBrandCreativeJob();

	// const [pendingReviewData, setPendingReviewData] = useState<any>([]);

	// const [toBeReviewed, setToBeReviewed] = useState<any>([]);
	// const [underReviewed, setUnderReviewed] = useState<any>([]);
	// const [toBeResolved, setToBeResolved] = useState<any>([])
	// const [live, setLive] = useState<any>([])
	// const [passed, setPassed] = useState<any>([]);

	// const [adminSelectedStories, setAdminSelectedStories] = useState<any>([]);

	const [addNewBrandStoryActiveStep, setAddNewBrandStoryActiveStep] = useState(
		0
	);

	const [vendorId, setVendorId] = useState("");

	const branStoryInfo = useGetBrandStory(vendorId);

	const handleOnShopSearchChange = (tag: any) => {
		setVendorId(tag.value.id);
	};

	const handleNewBrandStoryNext = () => {
		setAddNewBrandStoryActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleNewBrandStoryBack = () => {
		setAddNewBrandStoryActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const [
		openConfirmForVettingDialog,
		setOpenConfirmForVettingDialog,
	] = useState(false);

	const [toggleAddBrandStoryModal, setToggleAddBrandStoryModal] = useState(
		false
	);

	// const handleClickConfirmForVettingOpen = () => {
	// 	setOpenConfirmForVettingDialog(true);
	// };
	const handleConfirmForVettingClose = () => {
		setOpenConfirmForVettingDialog(false);
	};
	const [storyIdandAmount, setStoryIdandAmount] = useState<{
		id: string;
		amount: number;
	}>({ id: "", amount: 0 });

	const authuid = useSelector((state: any) => state.firebase.auth.uid);
	const profile = useSelector((state: any) => state.firebase.profile);

	// const [balance, setBalance] = useState(0);

	// const { navigateToLink } = useNavigate();

	const [poolsTabIndex, setPoolsTabIndexValue] = React.useState(0);

	const handlePoolsTabIndexChange = (
		event: React.ChangeEvent<{}>,
		newValue: number
	) => {
		setPoolsTabIndexValue(newValue);
	};

	// useFirestoreConnect([
	// 	{
	// 		collection: "BrandStories",
	// 	},
	// 	{
	// 		collection: "Wallets",
	// 		where: [["owner_id", "==", authuid]],
	// 		storeAs: "Wallets",
	// 	},
	// ]);

	// const brandStories = useSelector(
	// 	(state: any) => state.firestore.ordered.BrandStories
	// );

	// const wallet = useSelector((state: any) => state.firestore.data["Wallets"]);

	// useEffect(() => {
	// 	if (isLoaded(wallet)) {
	// 		let wallet_data: any[] = [];
	// 		if (wallet !== null) {
	// 			for (var walletkey in wallet) {
	// 				wallet_data.push({
	// 					amount: wallet[walletkey].income,
	// 				});
	// 			}
	// 		}

	// 		if (_.has(wallet_data[0], "amount")) {
	// 			setBalance(wallet_data[0].amount);
	// 		}
	// 	}
	// }, [wallet]);

	// useEffect(() => {
	// 	if (isLoaded(brandStories)) {
	// 		let story_data: any[] = [];
	// 		if (brandStories !== null) {
	// 			for (var brandStorieskey in brandStories) {
	// 				story_data.push({
	// 					...brandStories[brandStorieskey],
	// 					brandStorieskey_uuid: brandStorieskey,
	// 				});
	// 			}
	// 		}

	// 		const sorted = _.orderBy(story_data, ["added_on"], ["desc"]);

	// 		const result = getStories(
	// 			sorted,
	// 			ROUTES.BRAND_STORIES_QUERIES.ALL_PENDING_REVIEW
	// 		);

	// 		setPendingReviewData([...result]);

	// 		const getAdminStory = _.filter(
	// 			sorted,
	// 			(item) => _.has(item, "reviewedById") && item.reviewedById === authuid
	// 		);

	// 		setAdminSelectedStories([...getAdminStory]);
	// 		//get to be reviewed
	// 		const to_be_reviewed = _.filter(
	// 			getAdminStory,
	// 			(item) =>
	// 				_.has(item, "to_be_reviewed") &&
	// 				typeof item.to_be_reviewed.status !== "undefined" &&
	// 				item.to_be_reviewed.status === true
	// 		);

	// 		setToBeReviewed([...to_be_reviewed]);
	// 		//get under reviewed
	// 		const under_review = _.filter(
	// 			getAdminStory,
	// 			(item) =>
	// 				_.has(item, "under_review") &&
	// 				typeof item.under_review.status !== "undefined" &&
	// 				item.under_review.status === true
	// 		);
	// 		setUnderReviewed([...under_review]);

	// 		//get to be resolved
	// 		// const to_be_resolved = _.filter(
	// 		//   getAdminStory,
	// 		//   (item) =>
	// 		//     _.has(item, "to_be_resolved") &&
	// 		//     typeof item.to_be_resolved.status !== "undefined" &&
	// 		//     item.to_be_resolved.status === true
	// 		// );
	// 		// setToBeResolved([...to_be_resolved])
	// 		//get live
	// 		// const live_story = _.filter(
	// 		//   getAdminStory,
	// 		//   (item) =>
	// 		//     _.has(item, "live") &&
	// 		//     typeof item.live.status !== "undefined" &&
	// 		//     item.live.status === true
	// 		// );

	// 		// setLive([...live_story])

	// 		//get passed
	// 		const passed_story = _.filter(
	// 			getAdminStory,
	// 			(item) =>
	// 				_.has(item, "pass") &&
	// 				typeof item.pass.status !== "undefined" &&
	// 				item.pass.status === true
	// 		);

	// 		setPassed([...passed_story]);
	// 	}
	// }, [brandStories, authuid]);

	const refreshPage = () => {
		window.location.reload();
	};

	// if (!isLoaded(brandStories)) return <LoadingScreen />;

	return (
		<>
			<DashBoardLayout menuIndex={0}>
				<div className={styles.page_header}>
					<Typography variant="h4" color="primary">
						{" "}
						Creative DashBoard
					</Typography>
				</div>
				<Container>
					<ToastContainer />
					<Dialog
						open={toggleAddBrandStoryModal}
						onClose={() => setToggleAddBrandStoryModal(false)}
						aria-labelledby="add-brand-story-dialog-title"
						aria-describedby="add-brand-story-dialog-description"
						maxWidth="md"
						fullWidth={true}
					>
						<DialogTitle id="add-brand-story-dialog-title">
							{"New brand story"}
						</DialogTitle>
						<DialogContent>
							<Grid container>
								<Grid item xs={12} lg={1}></Grid>
								<Grid item xs={12} lg={10}>
									<Stepper
										activeStep={addNewBrandStoryActiveStep}
										orientation="vertical"
									>
										<Step>
											<StepLabel>Search and pick a store</StepLabel>
											<StepContent>
												<div>
													<Grid container spacing={2}>
														<Grid item xs={12} lg={7}>
															<AsyncSelect
																loadOptions={loadShopNameOptions}
																onChange={handleOnShopSearchChange}
															/>
														</Grid>
														<Grid item xs={12} lg={5}>
															{typeof branStoryInfo.data !== "undefined" &&
																branStoryInfo.data.length !== 0 && (
																	<div>
																		<Typography
																			variant="caption"
																			style={{ color: "red" }}
																		>
																			A vendor can only have one brand story at
																			a time. you must remove this story to
																			proceed!
																		</Typography>
																		<br />
																		<div
																			className={
																				styles.brand_story_thumbnail_wrapper
																			}
																		>
																			<GetImageFromFirebase
																				source={branStoryInfo.data[0].thumbnail}
																			/>
																		</div>
																		<br />
																		<Typography variant="h6" color="inherit">
																			{branStoryInfo.data[0].title}
																		</Typography>
																		<br />
																		<Button variant="contained" color="primary">
																			Remove
																		</Button>
																	</div>
																)}
														</Grid>
													</Grid>
												</div>
												{vendorId !== "" &&
													typeof branStoryInfo.data !== "undefined" &&
													branStoryInfo.data.length === 0 && (
														<div style={{ marginTop: 50 }}>
															<Button
																variant="contained"
																color="primary"
																onClick={handleNewBrandStoryNext}
															>
																{addNewBrandStoryActiveStep === 2 - 1
																	? "Finish"
																	: "Next"}
															</Button>
														</div>
													)}
											</StepContent>
										</Step>
										<Step>
											<StepLabel>Add brand story details</StepLabel>
											<StepContent>
												form 2
												<div>
													{addNewBrandStoryActiveStep === 2 ? (
														<div>
															<Typography className={styles.instructions}>
																All steps completed
															</Typography>
															<Button
																onClick={handleNewBrandStoryBack}
																className={styles.backButton}
															>
																Back
															</Button>
														</div>
													) : (
														<div>
															<Button
																disabled={addNewBrandStoryActiveStep === 0}
																onClick={handleNewBrandStoryBack}
																className={styles.backButton}
															>
																Back
															</Button>
															<Button
																variant="contained"
																color="primary"
																onClick={handleNewBrandStoryNext}
															>
																{addNewBrandStoryActiveStep === 2 - 1
																	? "Finish"
																	: "Next"}
															</Button>
														</div>
													)}
												</div>
											</StepContent>
										</Step>
									</Stepper>
								</Grid>
								<Grid item xs={12} lg={1}></Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => setToggleAddBrandStoryModal(false)}
								color="primary"
							>
								Cancel
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={openConfirmForVettingDialog}
						onClose={handleConfirmForVettingClose}
						aria-labelledby="product-story-dialog-title"
						aria-describedby="product-story-dialog-description"
					>
						<DialogTitle id="product-story-dialog-title">
							{"Are you  sure?"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="product-story-dialog-description">
								By confirming it means you will handle this job.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleConfirmForVettingClose} color="primary">
								Cancel
							</Button>
							<Button
								onClick={() => {
									// dispatch(
									//   AcceptVetting(
									//     storeIdandAmount.id,
									//     authuid,
									//     true,
									//     storeIdandAmount.amount * 0.1
									//   )
									// );
									AcceptProductStoryMutate(
										{
											reviewedById: authuid,
											reviewedByName: profile.first_name,
											commission: storyIdandAmount.amount,
											id: storyIdandAmount.id,
										},
										{
											onSuccess: () => {
												toast.success("Story confirmed successfully!", {
													position: "top-right",
													autoClose: 5000,
													hideProgressBar: false,
													closeOnClick: true,
												});

												refreshPage();
											},
											onError: () => {
												toast.error("Oops! An error occured!  Try agin", {
													position: "top-right",
													autoClose: 5000,
													hideProgressBar: false,
													closeOnClick: true,
												});
											},
										}
									);
									handleConfirmForVettingClose();
								}}
								color="primary"
								autoFocus
								variant="outlined"
							>
								{AcceptProductStoryInfo.isLoading ? "Processing..." : "Confirm"}
							</Button>
						</DialogActions>
					</Dialog>

					<br />
					<Typography variant="h6" color="initial" align="center">
						Creatives Pool
					</Typography>
					<Divider />
					<br />
					<Grid container spacing={2}>
						<Grid item xs={12} lg={2}>
							<Tabs
								orientation="vertical"
								variant="scrollable"
								value={poolsTabIndex}
								onChange={handlePoolsTabIndexChange}
								aria-label="Creative pools tabs"
								className={styles.tabs}
							>
								<Tab label="Logos and Banners" {...a11yProps(0)} />
								<Tab label="Face Behind Brand" {...a11yProps(1)} />
								<Tab label="Marketplace Products" {...a11yProps(2)} />
								{/* <Tab label="Brand Stories" {...a11yProps(3)} />
								<Tab label="Product Stories" {...a11yProps(4)} /> */}
							</Tabs>
							<br />
							<br />
							{/* <Button
								variant="contained"
								color="primary"
								size="large"
								onClick={() =>
									InitiateBrandCreativeJobMutate(
										{
											store_id: "8gXuVObKuj",
											store_name: "test ekioja",
											store_admin_data: {
												first_name: "kayode",
												last_name: "matthew",
												email: "kayodevictormatthew@gmail.com",
												contact_phone_number: "+2348124665812",
											},

											reference: CreativeJoBNumber(),
											job: ["MARKET_PRODUCT"],
											product_data: {
												id: "SuTKc_dN8",
												image_1: "product_SuTKc_dN8_image_1",
												image_1_url:
													"https://firebasestorage.googleapis.com/v0/b/ekioja-7bb9f.appspot.com/o/productImages%2Fproduct_SuTKc_dN8_image_1?alt=media&token=85d9cc79-2feb-464d-958d-436dee653cff",
												image_2: "product_SuTKc_dN8_image_2",
												image_2_url:
													"https://firebasestorage.googleapis.com/v0/b/ekioja-7bb9f.appspot.com/o/productImages%2Fproduct_SuTKc_dN8_image_2?alt=media&token=48cd538a-6d03-4efc-abf1-d0721ea5f20d",
												image_3: "",
												image_3_url: "",
												image_4: "",
												image_4_url: "",
											},
											commission: 100,
											pending_review: true,
											type: "MARKET_PRODUCT",
										},
										{
											onSuccess: () => {
												toast.success("Creative job created successfully!", {
													position: "top-right",
													autoClose: 5000,
													hideProgressBar: false,
													closeOnClick: true,
												});
											},
											onError: () => {
												toast.error("Oops! An error occured!  Try agin", {
													position: "top-right",
													autoClose: 5000,
													hideProgressBar: false,
													closeOnClick: true,
												});
											},
										}
									)
								}
							>
								initiate creative job
							</Button> */}
							{/* <Button
								variant="contained"
								color="primary"
								size="large"
								onClick={() =>
									InitiateBrandCreativeJobMutate(
										{
											store_id: "8gXuVObKuj",
											store_name: "test ekioja",
											store_admin_data: {
												first_name: "kayode",
												last_name: "matthew",
												email: "kayodevictormatthew@gmail.com",
												contact_phone_number: "+2348124665812",
											},

											reference: CreativeJoBNumber(),
											job: ["FACE_BEHIND_THE_BRAND"],
											commission: 100,
											pending_review: true,
											type: "FACE_BEHIND_THE_BRAND",
										},
										{
											onSuccess: () => {
												toast.success("Creative job created successfully!", {
													position: "top-right",
													autoClose: 5000,
													hideProgressBar: false,
													closeOnClick: true,
												});
											},
											onError: () => {
												toast.error("Oops! An error occured!  Try agin", {
													position: "top-right",
													autoClose: 5000,
													hideProgressBar: false,
													closeOnClick: true,
												});
											},
										}
									)
								}
							>
								initiate creative job
							</Button> */}
							{/* <Button
								variant="contained"
								color="primary"
								size="large"
								onClick={() => setToggleAddBrandStoryModal(true)}
							>
								ADD A BRAND STORY
							</Button> */}
						</Grid>
						<Grid item xs={12} lg={8}>
							<TabPanel value={poolsTabIndex} index={0}>
								<BrandCreatives />
							</TabPanel>
							<TabPanel value={poolsTabIndex} index={1}>
								<FaceBehindTheBrand />
							</TabPanel>
							<TabPanel value={poolsTabIndex} index={2}>
								<MarketPlaceProduct />
							</TabPanel>
							<TabPanel value={poolsTabIndex} index={3}>
								<BrandStories />
							</TabPanel>
							<TabPanel value={poolsTabIndex} index={4}>
								{/* <Box boxShadow={5}>
									<MaterialTable
										style={{ width: "100%" }}
										title="New product stories pool"
										columns={[
											{ title: "Title", field: "title" },
											{
												title: "Description",
												field: "description",
												render: (rowData) => (
													<p>{truncate(rowData.description, 40)}</p>
												),
											},
											{ title: "Vendor", field: "shop_name" },
											{ title: "Added on", field: "added" },
											{ title: "data", field: "data", hidden: true },
										]}
										data={pendingReviewData}
										options={{
											search: false,
											actionsColumnIndex: -1,
										}}
										actions={[
											{
												icon: "save",
												tooltip: "Save User",
												onClick: (event, rowData: any) => {
													setStoryIdandAmount({
														id: rowData.data.story_id,
														amount: getAdminForProductStoryCut(
															rowData.data.amountpaidforpromotion
														),
													});
													handleClickConfirmForVettingOpen();
												},
											},
										]}
										components={{
											Action: (props) => (
												<Button
													variant="contained"
													color="secondary"
													size="medium"
													onClick={(event) =>
														props.action.onClick(event, props.data)
													}
												>
													ACCEPT
												</Button>
											),
										}}
									/>
								</Box> */}
							</TabPanel>
						</Grid>
						<Grid item xs={12} lg={2}></Grid>
					</Grid>
					<br />
					<br />
					<br />
					<BrandCreativesQuickLinks />
					<FaceBehindTheBrandQuickLinks />
					<MarketPlaceProductQuickLink />
					{/* <BrandStoriesQuickLink />
					<ProductStoriesQuickLinks /> */}
					{/* <Typography variant="h6">Product story quick links</Typography>
					<Divider />
					<br />
					<Grid container spacing={3}>
						<Grid item xs={12} lg={6}>
							<Grid container spacing={2}>
								<Grid item xs={12} lg={12}>
									<StoryWidget
										count={getStoriesCounts(
											adminSelectedStories,
											ROUTES.BRAND_STORIES_QUERIES.ALL_BRAND_STORIES
										)}
										title="All Stories"
										routeQuery={ROUTES.BRAND_STORIES_QUERIES.ALL_BRAND_STORIES}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<StoryWidget
										count={getStoriesCounts(
											adminSelectedStories,
											ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_REVIEWED
										)}
										title="All To be Reviewed"
										routeQuery={ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_REVIEWED}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<StoryWidget
										count={getStoriesCounts(
											adminSelectedStories,
											ROUTES.BRAND_STORIES_QUERIES.ALL_UNDER_REVIEW
										)}
										title="All Under Review"
										routeQuery={ROUTES.BRAND_STORIES_QUERIES.ALL_UNDER_REVIEW}
									/>
								</Grid>

								<Grid item xs={12} lg={6}>
									<StoryWidget
										count={getStoriesCounts(
											adminSelectedStories,
											ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_RESOLVED
										)}
										title="All To be Resolved "
										routeQuery={ROUTES.BRAND_STORIES_QUERIES.ALL_TO_BE_RESOLVED}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<StoryWidget
										count={getStoriesCounts(
											adminSelectedStories,
											ROUTES.BRAND_STORIES_QUERIES.ALL_LIVE_STORIES
										)}
										title="All Approved "
										routeQuery={ROUTES.BRAND_STORIES_QUERIES.ALL_PASSED}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Box
								boxShadow={6}
								style={{
									minHeight: 105,
									borderRadius: 10,
									width: "90%",
								}}
							>
								<List
									subheader={
										<ListSubheader component="div">
											Today's wallet data
										</ListSubheader>
									}
								>
									<ListItem>
										<ListItemText
											primary="Upcoming earnings"
											secondary={
												<Fragment>
													<NumberFormat
														value={getCreativeUpcomingRevenueTimeFrame(
															toBeReviewed,
															"today"
														)}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"₦"}
													/>
												</Fragment>
											}
										/>
									</ListItem>
									<Divider />
									<ListItem>
										<ListItemText
											primary="Pending Clearance"
											secondary={
												<Fragment>
													<NumberFormat
														value={getCreativesPendingClearanceRevenueTimeFrame(
															underReviewed,
															"today"
														)}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"₦"}
													/>
												</Fragment>
											}
										/>
									</ListItem>
									<Divider />
									<ListItem>
										<ListItemText
											primary="Earned (Passed )"
											secondary={
												<Fragment>
													<NumberFormat
														value={getCreativesEarnedRevenueTimeFrame(
															passed,
															"today"
														)}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"₦"}
													/>
												</Fragment>
											}
										/>
									</ListItem>
									<Divider />

									<ListItem>
										<ListItemText
											primary="Available funds"
											secondary={
												<Fragment>
													<NumberFormat
														value={balance}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"₦"}
													/>
												</Fragment>
											}
										/>
										<ListItemSecondaryAction>
											<Button
												onClick={() => navigateToLink.push(ROUTES.VET_WALLET)}
											>
												View wallet
											</Button>
										</ListItemSecondaryAction>
									</ListItem>
									<Divider />
								</List>
							</Box>
						</Grid>
					</Grid> */}
				</Container>
			</DashBoardLayout>
		</>
	);
};

export default CreativeDashboard;
