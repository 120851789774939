

import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';

export const OrderDelivered = (id:string,values:any): ThunkAction<Promise<void>, {}, {}, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any, { getFirebase }: any): Promise<void> => {
        const firebase = getFirebase();

     
     const deliveredPromise  = firebase.firestore().collection('OrdersTimeline').doc(id).set({
            order_placed:{
                
                current:false,
            },
            pending_confirmation:{
                
                current:false,
            },
            waiting_to_be_shipped:{
               
                current:false,
            },
            out_for_delivery:{
                
                current:false,
            },
            delivered:{
                status:true,
                current:true,
                date: firebase.firestore.FieldValue.serverTimestamp(),
            },

        },{merge:true});

        const vendorwalletDocRef = firebase
        .firestore()
        .collection('Wallets')
        .doc(values.vendor_id)
        //run transaction on vendor balance
    const vendortransactionpromise = firebase
        .firestore()
        .runTransaction((transaction:any) => {
            return transaction.get(vendorwalletDocRef).then((transactionDoc:any) => {
                if (!transactionDoc.exists) {}
    
                let newIncome = transactionDoc.data().income + values.vendor_pay
    
                transaction.update(vendorwalletDocRef, {
                    income: newIncome,
                })
            })
        })



        const logisticswalletDocRef = firebase
        .firestore()
        .collection('Wallets')
        .doc(values.logistics_id)
        //run transaction on vendor balance
    const logisticstransactionpromise = firebase
        .firestore()
        .runTransaction((transaction:any) => {
            return transaction.get(logisticswalletDocRef).then((transactionDoc:any) => {
                if (!transactionDoc.exists) {}
    
                let newIncome = transactionDoc.data().income + values.logistics_pay
    
                transaction.update(logisticswalletDocRef, {
                    income: newIncome,
                })
            })
        })

        const ekiojawalletDocRef = firebase
        .firestore()
        .collection('Wallets')
        .doc(process.env.REACT_APP_EKIOJA_WALLET)
        //run transaction on vendor balance
    const deductEkiojatransactionpromise = firebase
        .firestore()
        .runTransaction((transaction:any) => {
            return transaction.get(ekiojawalletDocRef).then((transactionDoc:any) => {
                if (!transactionDoc.exists) {}
                
                let amount = values.vendor_pay + values.logistics_pay + values.ekioja_pay
                let newIncome = transactionDoc.data().order - amount
    
                transaction.update(ekiojawalletDocRef, {
                    order: newIncome,
                })
            })
        })
    const addEkiojatransactionpromise = firebase
        .firestore()
        .runTransaction((transaction:any) => {
            return transaction.get(ekiojawalletDocRef).then((transactionDoc:any) => {
                if (!transactionDoc.exists) {}
                
                let newIncome = transactionDoc.data().profit + values.ekioja_pay
    
                transaction.update(ekiojawalletDocRef, {
                    profit: newIncome,
                })
            })
        })

    

        Promise.all([deliveredPromise,vendortransactionpromise,logisticstransactionpromise,deductEkiojatransactionpromise,addEkiojatransactionpromise])
      
    }