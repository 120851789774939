import React, { Fragment, useState } from "react";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import Divider from "@material-ui/core/Divider";
// //number format
// import NumberFormat from "react-number-format";
//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

// import _ from "lodash";

// import moment from "moment";

import TransactionWidget from "../components/Finance/TransactionWidget";
import WalletsWidget from "../components/Finance/WalletsWidget";
import PayoutRequestsWidget from "../components/Finance/PayoutRequestsWidget";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page_header: {
      width: "100%",
      height: 100,
      backgroundColor: "#99ff99",
      paddingTop: 20,
      paddingLeft: 20,
    },
  })
);

const PayoutRequests = () => {
  const styles = useStyles();

  const [menuIndex, setMenuIndex] = useState(0);

  return (
    <Fragment>
      <DashBoardLayout menuIndex={7}>
        <div className={styles.page_header}>
          <Typography variant="h4" color="primary">
            {" "}
            Finance Management
          </Typography>
        </div>
        <br />
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={2}>
              <Card>
                <CardContent>
                  <List>
                    <ListItem
                      button
                      selected={menuIndex === 0 ? true : false}
                      onClick={() => setMenuIndex(0)}
                    >
                      <ListItemText primary="Transactions" />
                    </ListItem>
                    <ListItem
                      button
                      selected={menuIndex === 1 ? true : false}
                      onClick={() => setMenuIndex(1)}
                    >
                      <ListItemText primary="Wallets" />
                    </ListItem>
                    <ListItem
                      button
                      selected={menuIndex === 2 ? true : false}
                      onClick={() => setMenuIndex(2)}
                    >
                      <ListItemText primary="Payouts" />
                    </ListItem>
                    <ListItem
                      button
                      selected={menuIndex === 3 ? true : false}
                      onClick={() => setMenuIndex(3)}
                    >
                      <ListItemText primary="Refunds" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={10}>
              <Card>
                <CardContent>
                  {menuIndex === 0 && <TransactionWidget />}
                  {menuIndex === 1 && <WalletsWidget />}
                  {menuIndex === 2 && <PayoutRequestsWidget />}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </DashBoardLayout>
    </Fragment>
  );
};

export default PayoutRequests;
