import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const inviteNewLogisticsPartner = (values: any) => {
	return firebase
		.firestore()
		.collection("InviteNewLogisticsPartner")
		.doc()
		.set({
			...values,
			on: firebase.firestore.FieldValue.serverTimestamp(),
		});
};

const useInviteNewLogisticsPartner = () => {
	return useMutation(inviteNewLogisticsPartner);
};

export default useInviteNewLogisticsPartner;
