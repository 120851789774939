import { useQuery, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

const getAllLogisticsCompanyList = async () => {
	const response = await firebase
		.firestore()
		.collection("LogisticsCompanies")
		.get();
	let data: any = [];
	response.forEach((doc: any) => {
		data.push({ id: doc.id, ...doc.data() });
	});
	return data;
};

const useGetAllLogisticsCompanyList = () => {
	return useQuery("allLogisticsCompanies", getAllLogisticsCompanyList, {
		initialData: () => {
			if (queryCache.getQueryData("allLogisticsCompanies")) {
				return queryCache.getQueryData("allLogisticsCompanies");
			}
		},
		refetchOnWindowFocus: false,
	});
};

export default useGetAllLogisticsCompanyList;
