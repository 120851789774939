import React, { lazy, Suspense, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from '@material-ui/core/CardMedia';
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Divider from "@material-ui/core/Divider";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// import HomeandDecor from '../assets/images/categories/home_and_decor.png';

// //navigation hook
import { useNavigate } from "../hooks/navigate";

// //routes
import * as ROUTES from "../constants/routes";

//constants
import { CATEGORIES } from "../constants/categories";

import _ from "lodash";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//link
import { useParams } from "react-router-dom";

//utils
import { getProductsByCategory } from "../utils/index";

//loading screen
import LoadingScreen from "../components/LoadingScreen";

import MarketPlaceLayout from "../pages/Layouts/MarketPlaceLayout";

const ProductList = lazy(() => import("../components/MarketPlace/ProductList"));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        marginRight: 40,
      },
    },

    logo_wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: 100,
      height: 60,
      marginRight: 10,
      "& img": {
        width: 90,
        height: "auto",
      },
    },
    section_gutter: {
      marginTop: 100,
    },
    card_media: {
      height: 140,
    },
  })
);

const MarketProductCategoryList = () => {
  const styles = useStyles();
  const { query } = useParams<any>();

  const [productsData, setProductsData] = useState<any>([]);
  const [categoryTitle, setCategoryTitle] = useState("default");

  const { navigateToLink } = useNavigate();

  useFirestoreConnect([
    {
      collection: "Products",
      where: [["visible_on_market", "==", true]],
      storeAs: "ActiveProducts",
    },
    {
      collection: "Wishlist",
      where: [["wishlist_customer_id", "==", "demo-customer"]],
      storeAs: "CustomerWishlist",
    },
    {
      collection: "Cart",
      where: [["cart_customer_id", "==", "demo-customer"],["cart_closed", "==", false]],
      storeAs: "CustomerCart",
    },
  ]);



  const Activeproducts = useSelector(
    (state: any) => state.firestore.data["ActiveProducts"]
  );

  const wishList = useSelector(
    (state: any) => state.firestore.data["CustomerWishlist"]
  );

  const cart = useSelector(
    (state: any) => state.firestore.data["CustomerCart"]
  );

  useEffect(() => {
    if (isLoaded(Activeproducts) && isLoaded(wishList) && isLoaded(cart)) {
      let products = [];
      if (Activeproducts !== null) {
        for (var productkey in Activeproducts) {
          products.push({
            ...Activeproducts[productkey],
            product_uuid: productkey,
          });
        }
      }

    

      let wishlist_data = [];
      if (wishList !== null) {
        for (var key in wishList) {
          wishlist_data.push({ ...wishList[key], wishlist_uuid: key });
        }
      }

      let cart_data: any[] = [];
      if (cart !== null) {
        for (var cartkey in cart) {
          cart_data.push({ ...cart[cartkey], cart_uuid: cartkey });
        }
      }

      var result_products_in_cart = _.intersectionWith(
        _.cloneDeep(products),
        cart_data,
        function (x: any, y: any) {
          return x.product_id === y.cart_product_id && _.assign(x, y);
        }
      );

      var result_products_not_in_cart = _.differenceWith(
        _.cloneDeep(products),
        cart_data,
        function (x: any, y: any) {
          return x.product_id === y.cart_product_id && _.assign(x, y);
        }
      );

      
      
      var result_products_in_wishlist = _.intersectionWith(
        _.cloneDeep(products),
        wishlist_data,
        function (x: any, y: any) {
          return x.product_id === y.wishlist_product_id && _.assign(x, y);
        }
      );

      var result_products_not_in_wishlist = _.differenceWith(
        _.cloneDeep(products),
        wishlist_data,
        function (x: any, y: any) {
          return x.product_id === y.wishlist_product_id && _.assign(x, y);
        }
      );

      const all_union = _.unionBy(
        result_products_in_wishlist,
        result_products_in_cart,
        result_products_not_in_wishlist,
        result_products_not_in_cart,
        "product_uuid"
      );

      const result_products = getProductsByCategory(all_union, query);

      setProductsData([...result_products]);

      switch (query) {
        case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_HOME_AND_DECORS:
          setCategoryTitle(CATEGORIES.HOME_AND_DECOR);

          break;
        case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_ART_AND_CRAFT:
          setCategoryTitle(CATEGORIES.ART_AND_CRAFT);

          break;
        case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_BEADS_AND_WIREWORKS:
          setCategoryTitle(CATEGORIES.BEADS_AND_WIREWORKS);

          break;
        case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_BEAUTY_PRODUCTS:
          setCategoryTitle(CATEGORIES.BEAUTY_PRODUCTS);

          break;
        case ROUTES.MARKETPLACE_CATEGORIES_QUERIES.ALL_ClOTH_AND_FASHION:
          setCategoryTitle(CATEGORIES.ClOTH_AND_FASHION);

          break;

        default:
          break;
      }
    }
  }, [Activeproducts, query, wishList, cart]);

  if (!isLoaded(Activeproducts)) return <LoadingScreen />;

  return (
    <MarketPlaceLayout>
      <Container>
        <div className={styles.section_gutter} />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={2}>
            <Card>
              <CardContent>
                <Typography variant="h6">Categories</Typography>
                <List dense={true}>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToLink.push(
                        ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST,
                        {
                          query:
                            ROUTES.MARKETPLACE_CATEGORIES_QUERIES
                              .ALL_HOME_AND_DECORS,
                        }
                      );
                    }}
                  >
                    <ListItemText primary="Home and decor" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToLink.push(
                        ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST,
                        {
                          query:
                            ROUTES.MARKETPLACE_CATEGORIES_QUERIES
                              .ALL_ART_AND_CRAFT,
                        }
                      );
                    }}
                  >
                    <ListItemText primary="Art and craft" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToLink.push(
                        ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST,
                        {
                          query:
                            ROUTES.MARKETPLACE_CATEGORIES_QUERIES
                              .ALL_BEADS_AND_WIREWORKS,
                        }
                      );
                    }}
                  >
                    <ListItemText primary="Beads and Wireworks" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToLink.push(
                        ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST,
                        {
                          query:
                            ROUTES.MARKETPLACE_CATEGORIES_QUERIES
                              .ALL_BEAUTY_PRODUCTS,
                        }
                      );
                    }}
                  >
                    <ListItemText primary="Beauty Products" />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToLink.push(
                        ROUTES.VIEW_MARKETPLACE_CATEGORIES_PRODUCTS_LIST,
                        {
                          query:
                            ROUTES.MARKETPLACE_CATEGORIES_QUERIES
                              .ALL_ClOTH_AND_FASHION,
                        }
                      );
                    }}
                  >
                    <ListItemText primary="Cloth and  Fashion" />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Typography variant="h5">
              Category : {categoryTitle} , showing {productsData.length} results
            </Typography>
            <Divider />
            <Suspense fallback={<div>Loading...</div>}>
              <ProductList data={productsData} />
            </Suspense>
          </Grid>
          <Grid item xs={12} lg={2}></Grid>
        </Grid>
      </Container>
    </MarketPlaceLayout>
  );
};

export default MarketProductCategoryList;
