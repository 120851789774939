import React, { Fragment, useEffect, useState } from "react";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MoneyIcon from "@material-ui/icons/Money";
import Divider from "@material-ui/core/Divider";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

//number format
import NumberFormat from "react-number-format";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";
//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

import _ from "lodash";

import LoadingScreen from "../components/LoadingScreen";

import {
	getLogisticsUpcomingRevenueTimeFrame,
	getLogisticsPendingClearanceRevenueTimeFrame,
	getLogisticsEarnedRevenueFailedTimeFrame,
} from "../utils/index";

import LogisticsPaymentWidget from "../components/Logistics/LogisticsPaymentWidget";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			height: 200,
			width: "100%",
			borderRadius: 20,
		},
		money_title: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			flexDirection: "column",
		},
		available_wrapper: {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		page_header: {
			width: "100%",
			height: 100,
			backgroundColor: "#99ff99",
			paddingTop: 20,
			paddingLeft: 20,
		},
	})
);

const LogisticsWallet = () => {
	const styles = useStyles();
	//   const [bookingData, setBookingData] = useState<any>([]);
	const [waitingToBeShipped, setWaitingToBeShipped] = useState<any>([]);
	const [outForDelivery, setOutForDelivery] = useState<any>([]);
	const [delivered, setDelivered] = useState<any>([]);

	const [upComingRevenue, setUpComingRevenue] = useState(0);
	const [pendingClearanceRevenue, setPendingClearanceRevenue] = useState(0);
	const [earnedRevenue, setEarnedRevenue] = useState(0);
	const [open, setOpen] = React.useState(false);
	const [timeframeValue, setTimeframeValue] = useState("today");

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const profile = useSelector((state: any) => state.firebase.profile);

	const authuid = useSelector((state: any) => state.firebase.auth.uid);

	useFirestoreConnect([
		{
			collection: "OrdersTimeline",
			where: [
				["logistics.entity", "==", isLoaded(profile) ? profile.entity : "nill"],
			],
			storeAs: "OrdersTimeline",
		},
	]);

	const ordersTimelime = useSelector(
		(state: any) => state.firestore.data["OrdersTimeline"]
	);

	useEffect(() => {
		if (isLoaded(ordersTimelime) && isLoaded(authuid)) {
			//re arrange order timeline data
			let orderTimeline_data: any[] = [];
			if (ordersTimelime !== null) {
				for (var orderTimelinekey in ordersTimelime) {
					orderTimeline_data.push({
						...ordersTimelime[orderTimelinekey],
						orderTimelinekey_uuid: ordersTimelime[orderTimelinekey].id,
					});
				}
			}

			//get logistics bookings
			const logistics_bookings = _.filter(
				orderTimeline_data,
				(item) =>
					_.has(item, "logistics") &&
					item.logistics.entity === profile.entity &&
					item.booked === true
			);

			//get waiting to be shipped
			const waiting_to_be_shipped = _.filter(
				logistics_bookings,
				(item) =>
					_.has(item, "waiting_to_be_shipped") &&
					typeof item.waiting_to_be_shipped.current !== "undefined" &&
					item.waiting_to_be_shipped.current === true
			);
			setWaitingToBeShipped([...waiting_to_be_shipped]);

			//get out for delivery
			const out_for_delivery = _.filter(
				logistics_bookings,
				(item) =>
					_.has(item, "out_for_delivery") &&
					typeof item.out_for_delivery.current !== "undefined" &&
					item.out_for_delivery.current === true
			);
			setOutForDelivery([...out_for_delivery]);

			//get delivered
			const delivered = _.filter(
				logistics_bookings,
				(item) =>
					_.has(item, "delivered") &&
					typeof item.delivered.current !== "undefined" &&
					item.delivered.current === true
			);
			setDelivered([...delivered]);

			setTimeframeValue("today");
			setUpComingRevenue(
				getLogisticsUpcomingRevenueTimeFrame(waiting_to_be_shipped, "today")
			);
			setPendingClearanceRevenue(
				getLogisticsPendingClearanceRevenueTimeFrame(out_for_delivery, "today")
			);
			setEarnedRevenue(
				getLogisticsEarnedRevenueFailedTimeFrame(delivered, "today")
			);
		}
	}, [ordersTimelime, authuid]);

	if (!isLoaded(ordersTimelime)) return <LoadingScreen />;
	return (
		<Fragment>
			<DashBoardLayout menuIndex={12}>
				<div className={styles.page_header}>
					<Typography variant="h4" color="primary">
						{" "}
						Logistics Wallet
					</Typography>
				</div>
				<br />

				<Container>
					{!_.has(profile, "super-entity") && <LogisticsPaymentWidget />}

					<br />
					<Divider />
					<br />
					<br />
					<Grid container>
						<Grid item lg={2}>
							<FormControl fullWidth>
								<InputLabel id="open-select-label">Time Frame</InputLabel>
								<Select
									labelId="open-select-label"
									id="open-select"
									open={open}
									onClose={handleClose}
									onOpen={handleOpen}
									value={timeframeValue}
									onChange={(event: React.ChangeEvent<{ value: any }>) => {
										setTimeframeValue(event.target.value);
										setUpComingRevenue(
											getLogisticsUpcomingRevenueTimeFrame(
												waitingToBeShipped,
												event.target.value
											)
										);
										setPendingClearanceRevenue(
											getLogisticsPendingClearanceRevenueTimeFrame(
												outForDelivery,
												event.target.value
											)
										);
										setEarnedRevenue(
											getLogisticsEarnedRevenueFailedTimeFrame(
												delivered,
												event.target.value
											)
										);
									}}
								>
									<MenuItem value="today">Today</MenuItem>
									<MenuItem value="within-a-week">Within a week</MenuItem>
									<MenuItem value="within-a-month">Within a month</MenuItem>
									<MenuItem value="over-two-weeks">Over two weeks ago</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<br />
					<br />
					<Grid container spacing={3}>
						<Grid item xs={12} lg={4}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<MoneyIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Upcoming earnings
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={upComingRevenue}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									(Waiting to be shipped)
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									{timeframeValue === "today" && "Within today"}
									{timeframeValue === "within-a-week" &&
										"Within the past 7 days"}
									{timeframeValue === "within-a-month" &&
										"Within the past 30 days"}
									{timeframeValue === "over-two-weeks" &&
										"Over two weeks ago or more"}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={4}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<AccountBalanceIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Pending Clearance
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={pendingClearanceRevenue}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									(Out for delivery)
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									{timeframeValue === "today" && "Within today"}
									{timeframeValue === "within-a-week" &&
										"Within the past 7 days"}
									{timeframeValue === "within-a-month" &&
										"Within the past 30 days"}
									{timeframeValue === "over-two-weeks" &&
										"Over two weeks ago or more"}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={4}>
							<Box className={styles.wrapper} boxShadow={5}>
								<div className={styles.money_title}>
									<LocalAtmIcon style={{ fontSize: 40 }} />
									<Typography variant="h6" color="textSecondary">
										Earned
									</Typography>
								</div>
								<Typography variant="h6" color="textPrimary" display="block">
									<NumberFormat
										value={earnedRevenue}
										displayType={"text"}
										thousandSeparator={true}
										prefix={"₦"}
									/>
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									(Delivered)
								</Typography>
								<Typography
									variant="caption"
									color="textPrimary"
									display="block"
								>
									{timeframeValue === "today" && "Within today"}
									{timeframeValue === "within-a-week" &&
										"Within the past 7 days"}
									{timeframeValue === "within-a-month" &&
										"Within the past 30 days"}
									{timeframeValue === "over-two-weeks" &&
										"Over two weeks ago or more"}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</DashBoardLayout>
		</Fragment>
	);
};

export default LogisticsWallet;
