import React, { useState, useEffect } from "react";

import firebase from "../../config/firebaseConfig";

import CircularProgress from "@material-ui/core/CircularProgress";

// make a reference to our firebase storage
const storage = firebase.storage().ref();

const GetImageFromFireBase = ({ source }: any) => {
  const [photoUrl, setphotoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // fetch the download URL from firebase
  useEffect(() => {
    if (typeof source !== "undefined") {
      storage
        .child("images/" + source)
        .getDownloadURL()
        .then((url) => {
          setphotoUrl(url);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [source]);

  if (isLoading)
    return (
      <div
        style={{
          width: 50,
          height: 50,
        }}
      >
        <CircularProgress size={40} />
      </div>
    );
  return <img src={photoUrl} alt={photoUrl} />;
};

export default GetImageFromFireBase;
