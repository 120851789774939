import React, { FC } from "react";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

type Props = {
	default: boolean;
	error: boolean;
	warning: boolean;
	info: boolean;
	success: boolean;
	content: string;
};
const Info: FC<Partial<Props>> = (props) => {
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{props.default && (
				<Typography variant="caption" color="textPrimary">
					{props.content}
				</Typography>
			)}
			{props.error && <Alert severity="error">{props.content}</Alert>}
			{props.warning && <Alert severity="warning">{props.content}</Alert>}
			{props.info && <Alert severity="info">{props.content}</Alert>}
			{props.success && <Alert severity="success">{props.content}</Alert>}
		</div>
	);
};

export default Info;
