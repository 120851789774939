import React from "react";
import Grid from "@material-ui/core/Grid";
import QuickLinkItem from "./QuickLinkItem";
import { getProductCounts } from "../../../utils/index";
import * as ROUTES from "../../../constants/routes";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import useGetAllProducts from "../../../hooks/Product/useGetAllProducts";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorTwoToneIcon from "@material-ui/icons/ErrorTwoTone";

const ProductLinksList = () => {
	const [products, setProducts] = React.useState<any>([]);
	const allProductQuery = useGetAllProducts();

	React.useEffect(() => {
		if (allProductQuery.isSuccess) {
			setProducts([...allProductQuery.data]);
		}
	}, [allProductQuery]);

	const list = [
		{
			count: getProductCounts(
				products,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS
			),
			title: "All Products",
			routeQuery: ROUTES.VIEWPRODUCTSQUERIES.ALL_PRODUCTS,
		},
		{
			count: getProductCounts(
				products,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_ACTIVE_PRODUCTS
			),
			title: "All Active Products",
			routeQuery: ROUTES.VIEWPRODUCTSQUERIES.ALL_ACTIVE_PRODUCTS,
		},
		{
			count: getProductCounts(
				products,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_IN_ACTIVE_PRODUCTS
			),
			title: "All In-Active Products",
			routeQuery: ROUTES.VIEWPRODUCTSQUERIES.ALL_IN_ACTIVE_PRODUCTS,
		},
		{
			count: getProductCounts(
				products,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_ASSESSMENT
			),
			title: "All Under Assessment",
			routeQuery: ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_ASSESSMENT,
		},
		{
			count: getProductCounts(
				products,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_TO_BE_RESOLVED
			),
			title: "All To Be Resolved",
			routeQuery: ROUTES.VIEWPRODUCTSQUERIES.ALL_TO_BE_RESOLVED,
		},
		{
			count: getProductCounts(
				products,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_PROMOTED_PRODUCTS
			),
			title: "All Promoted Products",
			routeQuery: ROUTES.VIEWPRODUCTSQUERIES.ALL_PROMOTED_PRODUCTS,
		},
		{
			count: getProductCounts(
				products,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_LOW_PRICE_RANGE_PRODUCTS
			),
			title: "All  Products < ₦10,000",
			routeQuery: ROUTES.VIEWPRODUCTSQUERIES.ALL_LOW_PRICE_RANGE_PRODUCTS,
		},
		{
			count: getProductCounts(
				products,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_HIGH_PRICE_RANGE_PRODUCTS
			),
			title: "All  Products > ₦10,000",
			routeQuery: ROUTES.VIEWPRODUCTSQUERIES.ALL_HIGH_PRICE_RANGE_PRODUCTS,
		},
		{
			count: getProductCounts(
				products,
				ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_CREATIVE_PROCESS
			),
			title: "All  Under Creatives",
			routeQuery: ROUTES.VIEWPRODUCTSQUERIES.ALL_UNDER_CREATIVE_PROCESS,
		},
	];

	return (
		<div style={{ position: "relative" }}>
			{allProductQuery.isFetching && (
				<div
					style={{
						width: "100%",
						height: 410,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						background: "#fff",
						position: "absolute",
						color: "#000",
						zIndex: 999,
					}}
				>
					<CircularProgress />
					Welcome back, updating products data...
				</div>
			)}

			{allProductQuery.isError && (
				<div
					style={{
						width: "100%",
						height: 410,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						background: "#fff",
						position: "absolute",
						color: "red",
						zIndex: 888,
					}}
				>
					<ErrorTwoToneIcon
						style={{ color: "red", fontSize: 70, marginBottom: 5 }}
					/>
					Error loading data! Check your internet connection
				</div>
			)}
			{allProductQuery.isLoading && (
				<>
					<Skeleton variant="rect" width="20%" height={30} animation="wave" />
					<div style={{ marginBottom: 5 }} />
				</>
			)}
			{allProductQuery.isSuccess && (
				<Typography variant="h6">Products Quick links</Typography>
			)}

			<Divider />
			<br />
			<Grid container spacing={2}>
				{allProductQuery.isSuccess &&
					list.map((item) => (
						<Grid key={item.title} item xs={12} lg={3}>
							<QuickLinkItem
								count={item.count}
								title={item.title}
								routeQuery={item.routeQuery}
								query="product"
							/>
						</Grid>
					))}
				{allProductQuery.isLoading && (
					<Skeleton variant="rect" width="100%" height={300} animation="wave" />
				)}
			</Grid>
		</div>
	);
};

export default ProductLinksList;
