import React, { Fragment, useState, useEffect } from "react";
//material ui components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// import InputAdornment from '@material-ui/core/InputAdornment';
import { default as MyTextField } from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import InputLabel from '@material-ui/core/InputLabel';
// import FormLabel from '@material-ui/core/FormLabel';
import FormControl from "@material-ui/core/FormControl";
// import FormGroup from '@material-ui/core/FormGroup';
// import FormHelperText from '@material-ui/core/FormHelperText';
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

// //icons
// import LockIcon from '@material-ui/icons/Lock';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import EmailIcon from '@material-ui/icons/Email';
// import LocationOnIcon from "@material-ui/icons/LocationOn";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";

// //styles
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";

import { useParams } from "react-router-dom";

//formik
import { Formik, Form, Field } from "formik";
import { TextField, Select } from "formik-material-ui";

//layout
import DashBoardLayout from "./Layouts/DashBoardLayout";

//react - redux
// import { useSelector } from "react-redux";

//loading screen
import LoadingScreen from "../components/LoadingScreen";

//store interface
// import { ICreateStoreValues } from '../ts/interfaces/createStore';

//firebase image uploader
import ImageUploader from "../components/ImageUploader";

//actions
// import { IsAvailable } from "../store/actions/storeSettings/isAvailable";
// import { UpdateStore } from "../store/actions/storeSettings/updateStore";

import GetImageFromFirebase from "../components/GetImageFromFirebase";

// import { DeleteImage } from "../store/actions/general/deleteImage";

import useGetStoreData from "../hooks/useGetStoreData";
import useUpdateStoreData from "../hooks/useUpdateStoreData";
import useUpdateStoreActive from "../hooks/useUpdateStoreActive";
import useDeleteImage from "../hooks/useDeleteImage";
import useGetEkiojaNiches from "../hooks/useGetEkiojaNiches";

import LoadingButton from "../components/LoadingButton";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { queryCache } from "react-query";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    form_wrapper: {
      padding: 5,
      borderRadius: 20,
      width: "100%",
      height: "auto",
      marginBottom: 100,
    },
    niches: {
      width: "98%",
      height: 60,
      padding: 10,
      backgroundColor: "white",
      borderRadius: 10,
      marginBottom: 5,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    form_section: {
      width: "100%",
      height: "auto",
      padding: 10,
      //   backgroundColor: theme.palette.background.paper,
      borderRadius: 5,
    },
    small_avatar: {
      width: 20,
      height: 20,
      borderRadius: 20,
      backgroundColor: "green",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
    },
    form_section_title: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    store_logo_wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      "& img": {
        width: 150,
        height: 150,
      },
    },
    store_banner_wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      "& img": {
        width: 250,
        height: 150,
      },
    },
    store_logo_preview_wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: 300,
    },
    page_header: {
      width: "100%",
      height: 60,
      backgroundColor: "#99ff99",
      paddingTop: 20,
      paddingLeft: 20,
    },
    page_tab: {
      width: "100%",
      height: 40,
      backgroundColor: "#99ff99",
    },
    contact_hours_wrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
    contact_hours_wrapper_day: {
      width: 200,
    },
    contact_hours_wrapper_open_close_wrapper: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      width: "100%",
    },
    contact_hours_wrapper_field_wrapper: {
      width: 150,
      marginLeft: 20,
      marginBottom: 20,
    },
    contact_hours_wrapper_to_wrapper: {
      marginLeft: 10,
    },
  })
);

// interface StyledTabsProps {
//   value: number;
//   onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
// }

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #99ff99",
  },
  indicator: {
    backgroundColor: "#18591e",
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#fff",
        opacity: 1,
      },
      "&$selected": {
        color: "#18591e",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#18591e",
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
  label: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`store-settings-scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`store-settings-scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `store-settings-scrollable-prevent-tab-${index}`,
    "aria-controls": `store-settings-scrollable-prevent-tabpanel-${index}`,
  };
};

const EditStore = () => {
  const styles = useStyles();

  const [tabIndexValue, setTabIndexValue] = useState(0);

  const { id } = useParams<any>();

  const handleTabIndexChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setTabIndexValue(newValue);
  };

  const [descriptionWords, setDescriptionWords] = useState<string | undefined>(
    ""
  );
  const [wordCount, setWordCount] = useState(0);

  //   const currentUser = useSelector((state: any) => state.firebase.profile);

  const [DeleteImageMutate] = useDeleteImage();

  const storyDataInfo = useGetStoreData(id);

  const storeData = storyDataInfo.data;

  const [updateStoreDataMutate, { isLoading }] = useUpdateStoreData();
  const [updateStoreActiveMutate, updateStoreActive] = useUpdateStoreActive();

  const ekiojaNichesInfo = useGetEkiojaNiches();

  const openHours = ["8am", "9am", "10am", "11am", "12pm", "1pm"];
  const closeHours = ["1pm", "2pm", "3pm", "4pm", "5pm", "6pm"];
  useEffect(() => {
    if (typeof storeData !== "undefined") {
      setDescriptionWords(storeData?.business_description);
    }
  }, [storeData]);

  if (storyDataInfo.isLoading && ekiojaNichesInfo.isLoading)
    return <LoadingScreen />;

  return (
    <Fragment>
      <DashBoardLayout menuIndex={1}>
        <ToastContainer />
        <div className={styles.page_header}>
          <Typography variant="h4" color="primary">
            {" "}
            Edit Vendor
          </Typography>
        </div>
        <div className={styles.page_tab}>
          <AntTabs
            value={tabIndexValue}
            onChange={handleTabIndexChange}
            aria-label="Store settings tab"
          >
            <AntTab label="Image" {...a11yProps(0)} />
            <AntTab label="Availability" {...a11yProps(1)} />
            <AntTab label="Details" {...a11yProps(2)} />
            {/* <AntTab label="Payment" {...a11yProps(3)} /> */}
          </AntTabs>
        </div>
        <Formik
          initialValues={{
            id: storeData?.unique_id,
            business_name: storeData?.business_name,
            business_description: storeData?.business_description,
            business_logo_id: storeData?.business_logo_id,
            business_banner_id: storeData?.business_banner_id,
            // business_banner_id: `banner_${storeData?.unique_id}`,
            business_slogan: storeData?.business_slogan,
            business_email: storeData?.business_email,
            business_cac: storeData?.business_cac,
            business_website: storeData?.business_website,
            business_location: {
              street_address_1: storeData?.business_location.street_address_1,
              postal_code_1: storeData?.business_location.postal_code_1,
            },
            social_media: storeData?.social_media,
            business_niche: storeData?.business_niche,
            business_contact_hours:
              typeof storeData?.business_contact_hours !== "undefined"
                ? storeData?.business_contact_hours
                : {},
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);

              values.business_description = descriptionWords;

              updateStoreDataMutate(values, {
                onSuccess: () => {
                  queryCache.refetchQueries("storeData");
                  toast.success("Your store have been successfully updated!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                  });
                },
                onError: () => {
                  toast.error("Oops! An error occured!  Try agin", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                  });
                },
              });
            }, 500);
          }}
        >
          {({ submitForm, isSubmitting, values, setFieldValue }) => (
            <>
              <Form>
                <TabPanel value={tabIndexValue} index={0}>
                  <Grid container>
                    <Grid item xs={12} lg={2}></Grid>
                    <Grid item xs={12} lg={8}>
                      <br />
                      <br />
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            {values.business_logo_id === "" && (
                              <div
                                className={styles.store_logo_preview_wrapper}
                              >
                                <div style={{ width: "70%" }}>
                                  <div>
                                    <ImageUploader
                                      onRequestSave={(id) =>
                                        setFieldValue(
                                          "business_logo_id",
                                          id,
                                          true
                                        )
                                      }
                                      onRequestClear={() => {
                                        setFieldValue(
                                          "business_logo_id",
                                          "",
                                          true
                                        );
                                      }}
                                      label="Upload Business Logo"
                                      existingImageId={`logo_${values.id}`}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {values.business_logo_id !== "" && (
                              <div
                                className={styles.store_logo_preview_wrapper}
                              >
                                <div className={styles.store_logo_wrapper}>
                                  <br />
                                  {/* <Typography variant="caption" color="inherit">
                                    Recommendation: 150px by 150px in png format
                                  </Typography> */}
                                  <br />
                                  <GetImageFromFirebase
                                    source={`logo_${values.id}`}
                                  />
                                  <br />
                                  <br />
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      setFieldValue(
                                        "business_logo_id",
                                        "",
                                        true
                                      );

                                      DeleteImageMutate(`logo_${values.id}`);
                                    }}
                                  >
                                    Remove logo
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            {values.business_banner_id === "" && (
                              <div
                                className={styles.store_logo_preview_wrapper}
                              >
                                <div style={{ width: "70%" }}>
                                  <div>
                                    <ImageUploader
                                      onRequestSave={(id) =>
                                        setFieldValue(
                                          "business_banner_id",
                                          id,
                                          true
                                        )
                                      }
                                      onRequestClear={() => {
                                        setFieldValue(
                                          "business_banner_id",
                                          "",
                                          true
                                        );
                                      }}
                                      label="Upload your business banner"
                                      existingImageId={`banner_${values.id}`}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {values.business_banner_id !== "" && (
                              <div
                                className={styles.store_logo_preview_wrapper}
                              >
                                <div className={styles.store_banner_wrapper}>
                                  <br />
                                  <Typography variant="caption" color="inherit">
                                    Recommendation: 1200px by 240px in png
                                    format
                                  </Typography>
                                  <br />
                                  <GetImageFromFirebase
                                    source={`banner_${values.id}`}
                                  />
                                  <br />
                                  <br />
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      setFieldValue(
                                        "business_banner_id",
                                        "",
                                        true
                                      );

                                      DeleteImageMutate(`banner_${values.id}`);
                                    }}
                                  >
                                    Remove Banner
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={2}></Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabIndexValue} index={1}>
                  <Grid container>
                    <Grid item xs={12} lg={2}></Grid>
                    <Grid item xs={12} lg={8}>
                      <br />
                      <br />
                      <Typography variant="caption" align="right">
                        {updateStoreActive?.isLoading
                          ? "Updating..."
                          : "Is your store available for customers?"}{" "}
                      </Typography>

                      <FormControlLabel
                        control={
                          <Switch
                            checked={storeData?.avaliable_on_market}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              updateStoreActiveMutate(
                                {
                                  id: storeData?.unique_id,
                                  status: event.target.checked,
                                },
                                {
                                  onSuccess: () => {
                                    queryCache.refetchQueries("storeData");
                                    toast.success(
                                      "Your store visibility have been successfully updated!",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                      }
                                    );
                                  },
                                  onError: () => {
                                    toast.error(
                                      "Oops! An error occured!  Try agin",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                      }
                                    );
                                  },
                                }
                              )
                            }
                            color={
                              storeData?.avaliable_on_market
                                ? "primary"
                                : "secondary"
                            }
                          />
                        }
                        label={
                          storeData?.avaliable_on_market
                            ? "Available"
                            : "Not Available"
                        }
                      />

                      <br />
                      <br />
                      <div style={{ width: "100%" }}>
                        <Typography
                          variant="h6"
                          color="inherit"
                          align="center"
                          gutterBottom
                        >
                          Business Contact Hours
                        </Typography>
                        <Card>
                          <CardContent className={styles.contact_hours_wrapper}>
                            <div className={styles.contact_hours_wrapper_day}>
                              <Typography variant="h6" color="inherit">
                                Monday
                              </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div
                              className={
                                styles.contact_hours_wrapper_open_close_wrapper
                              }
                            >
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="monday-open-field">
                                  open
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.monday.open"
                                  inputProps={{
                                    id: "monday-open-field",
                                  }}
                                >
                                  {openHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                              <div
                                className={
                                  styles.contact_hours_wrapper_to_wrapper
                                }
                              >
                                <Typography variant="h6" color="inherit">
                                  {" "}
                                  to{" "}
                                </Typography>
                              </div>
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="monday-close-field">
                                  close
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.monday.close"
                                  inputProps={{
                                    id: "monday-close-field",
                                  }}
                                >
                                  {closeHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                            </div>
                          </CardContent>
                        </Card>
                        <br />
                        <Card>
                          <CardContent className={styles.contact_hours_wrapper}>
                            <div className={styles.contact_hours_wrapper_day}>
                              <Typography variant="h6" color="inherit">
                                Tuesday
                              </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div
                              className={
                                styles.contact_hours_wrapper_open_close_wrapper
                              }
                            >
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="tuesday-open-field">
                                  open
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.tuesday.open"
                                  inputProps={{
                                    id: "tuesday-open-field",
                                  }}
                                >
                                  {openHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                              <div
                                className={
                                  styles.contact_hours_wrapper_to_wrapper
                                }
                              >
                                <Typography variant="h6" color="inherit">
                                  {" "}
                                  to{" "}
                                </Typography>
                              </div>
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="tuesday-close-field">
                                  close
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.tuesday.close"
                                  inputProps={{
                                    id: "tuesday-close-field",
                                  }}
                                >
                                  {closeHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                            </div>
                          </CardContent>
                        </Card>
                        <br />
                        <Card>
                          <CardContent className={styles.contact_hours_wrapper}>
                            <div className={styles.contact_hours_wrapper_day}>
                              <Typography variant="h6" color="inherit">
                                Wednesday
                              </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div
                              className={
                                styles.contact_hours_wrapper_open_close_wrapper
                              }
                            >
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="wednesday-open-field">
                                  open
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.wednesday.open"
                                  inputProps={{
                                    id: "wednesday-open-field",
                                  }}
                                >
                                  {openHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                              <div
                                className={
                                  styles.contact_hours_wrapper_to_wrapper
                                }
                              >
                                <Typography variant="h6" color="inherit">
                                  {" "}
                                  to{" "}
                                </Typography>
                              </div>
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="wednesday-close-field">
                                  close
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.wednesday.close"
                                  inputProps={{
                                    id: "wednesday-close-field",
                                  }}
                                >
                                  {closeHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                            </div>
                          </CardContent>
                        </Card>
                        <br />
                        <Card>
                          <CardContent className={styles.contact_hours_wrapper}>
                            <div className={styles.contact_hours_wrapper_day}>
                              <Typography variant="h6" color="inherit">
                                Thursday
                              </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div
                              className={
                                styles.contact_hours_wrapper_open_close_wrapper
                              }
                            >
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="thursday-open-field">
                                  open
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.thursday.open"
                                  inputProps={{
                                    id: "thursday-open-field",
                                  }}
                                >
                                  {openHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                              <div
                                className={
                                  styles.contact_hours_wrapper_to_wrapper
                                }
                              >
                                <Typography variant="h6" color="inherit">
                                  {" "}
                                  to{" "}
                                </Typography>
                              </div>
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="thursday-close-field">
                                  close
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.thursday.close"
                                  inputProps={{
                                    id: "thursday-close-field",
                                  }}
                                >
                                  {closeHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                            </div>
                          </CardContent>
                        </Card>
                        <br />
                        <Card>
                          <CardContent className={styles.contact_hours_wrapper}>
                            <div className={styles.contact_hours_wrapper_day}>
                              <Typography variant="h6" color="inherit">
                                Friday
                              </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div
                              className={
                                styles.contact_hours_wrapper_open_close_wrapper
                              }
                            >
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="friday-open-field">
                                  open
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.friday.open"
                                  inputProps={{
                                    id: "friday-open-field",
                                  }}
                                >
                                  {openHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                              <div
                                className={
                                  styles.contact_hours_wrapper_to_wrapper
                                }
                              >
                                <Typography variant="h6" color="inherit">
                                  {" "}
                                  to{" "}
                                </Typography>
                              </div>
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="friday-close-field">
                                  close
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.friday.close"
                                  inputProps={{
                                    id: "friday-close-field",
                                  }}
                                >
                                  {closeHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                            </div>
                          </CardContent>
                        </Card>
                        <br />
                        <Card>
                          <CardContent className={styles.contact_hours_wrapper}>
                            <div className={styles.contact_hours_wrapper_day}>
                              <Typography variant="h6" color="inherit">
                                Saturday
                              </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div
                              className={
                                styles.contact_hours_wrapper_open_close_wrapper
                              }
                            >
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="saturday-open-field">
                                  open
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.saturday.open"
                                  inputProps={{
                                    id: "saturday-open-field",
                                  }}
                                >
                                  {openHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                              <div
                                className={
                                  styles.contact_hours_wrapper_to_wrapper
                                }
                              >
                                <Typography variant="h6" color="inherit">
                                  {" "}
                                  to{" "}
                                </Typography>
                              </div>
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="saturday-close-field">
                                  close
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.saturday.close"
                                  inputProps={{
                                    id: "saturday-close-field",
                                  }}
                                >
                                  {closeHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                            </div>
                          </CardContent>
                        </Card>
                        <br />
                        <Card>
                          <CardContent className={styles.contact_hours_wrapper}>
                            <div className={styles.contact_hours_wrapper_day}>
                              <Typography variant="h6" color="inherit">
                                Sunday
                              </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div
                              className={
                                styles.contact_hours_wrapper_open_close_wrapper
                              }
                            >
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="sunday-open-field">
                                  open
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.sunday.open"
                                  inputProps={{
                                    id: "sunday-open-field",
                                  }}
                                >
                                  {openHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                              <div
                                className={
                                  styles.contact_hours_wrapper_to_wrapper
                                }
                              >
                                <Typography variant="h6" color="inherit">
                                  {" "}
                                  to{" "}
                                </Typography>
                              </div>
                              <FormControl
                                className={
                                  styles.contact_hours_wrapper_field_wrapper
                                }
                              >
                                <InputLabel htmlFor="sunday-close-field">
                                  close
                                </InputLabel>
                                <Field
                                  component={Select}
                                  name="business_contact_hours.sunday.close"
                                  inputProps={{
                                    id: "sunday-close-field",
                                  }}
                                >
                                  {closeHours.map(
                                    (item: string, index: number) => (
                                      <MenuItem
                                        key={`${item}+${index}`}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    )
                                  )}
                                </Field>
                              </FormControl>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                      <br />
                      <br />

                      {isSubmitting && <LinearProgress />}

                      <br />
                      <br />

                      <LoadingButton
                        variant="contained"
                        color="primary"
                        pending={isLoading}
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Update
                      </LoadingButton>
                      <br />
                      <br />
                    </Grid>
                    <Grid item xs={12} lg={2}></Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabIndexValue} index={2}>
                  <Grid container>
                    <Grid item xs={12} lg={2}></Grid>
                    <Grid item xs={12} lg={8}>
                      <br />
                      <br />
                      <br />
                      <div>
                        <div className={styles.form_section_title}>
                          {/*<div className={styles.small_avatar}>*</div>*/}
                          <Typography variant="caption">
                            Tell us about your business
                          </Typography>
                        </div>
                        <Box className={styles.form_section} boxShadow={5}>
                          <Field
                            component={TextField}
                            name="business_name"
                            type="text"
                            label="Business name"
                            fullWidth
                            required
                            disabled
                          />
                          <br />
                          <br />
                          <Field
                            component={TextField}
                            name="business_email"
                            type="email"
                            label="Business email"
                            fullWidth
                            required
                            disabled
                          />
                          <br />
                          <br />
                          <Field
                            component={TextField}
                            name="business_cac"
                            type="text"
                            label="Business CAC"
                            fullWidth
                            required
                            disabled
                          />
                          <br />
                          <br />
                          <Field
                            component={TextField}
                            name="business_website"
                            type="url"
                            label="Website(if any)"
                            fullWidth
                          />
                          <br />
                          <br />
                          <Field
                            component={TextField}
                            name="business_slogan"
                            type="text"
                            label="Business Slogan"
                            fullWidth
                          />
                        </Box>
                      </div>

                      {/* <div>
                                        <div className={styles.form_section_title}>
                                            <Avatar className={styles.small_avatar}>1</Avatar>
                                            <Typography variant="caption">Tell us about your business</Typography>
                                        </div>
                                        <Box className={styles.form_section} boxShadow={5}>

                                        </Box>

                                    </div> */}
                      <br />
                      <br />
                      <div>
                        <div className={styles.form_section_title}>
                          {/*<div className={styles.small_avatar}>*</div>*/}
                          <Typography variant="caption">
                            Brief description about your business.
                          </Typography>
                        </div>
                        <Box className={styles.form_section} boxShadow={5}>
                          <Field
                            component={TextField}
                            name="business_description"
                            type="text"
                            label="Business description"
                            helperText={`Character count ${
                              230 - wordCount
                            }/230 remaining`}
                            multiline
                            rows={6}
                            fullWidth
                            required
                            value={descriptionWords}
                            onInput={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (e.target.value.length >= 230) {
                                let value = e.target.value.slice(0, 230);
                                setDescriptionWords(value);
                                setWordCount(value.length);
                              }
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (e.target.value.length >= 230) {
                                let value = e.target.value.slice(0, 230);
                                setDescriptionWords(value);
                                setWordCount(value.length);
                              } else if (e.target.value.length < 230) {
                                setDescriptionWords(e.target.value);
                                setWordCount(e.target.value.length);
                              }
                            }}
                            onPaste={(
                              e: React.ClipboardEvent<HTMLInputElement>
                            ) => {
                              if (
                                e.clipboardData.getData("Text").length >= 230
                              ) {
                                let value = e.clipboardData
                                  .getData("Text")
                                  .slice(0, 230);
                                setDescriptionWords(value);
                                setWordCount(value.length);
                              }
                            }}
                          />
                        </Box>
                      </div>

                      <br />
                      <br />
                      <div>
                        <div className={styles.form_section_title}>
                          {/*<div className={styles.small_avatar}>*</div>*/}
                          <Typography variant="caption">Your niche</Typography>
                        </div>
                        <Box className={styles.form_section} boxShadow={5}>
                          <FormControl fullWidth>
                            <Field
                              multiple
                              name="business_niche"
                              disableCloseOnSelect
                              component={Autocomplete}
                              options={
                                ekiojaNichesInfo.status === "success" &&
                                ekiojaNichesInfo.data?.values.map(
                                  (item: any) => {
                                    return item;
                                  }
                                )
                              }
                              getOptionLabel={(option: any) => option}
                              getOptionSelected={(option: any, value: any) =>
                                option === value
                              }
                              limitTags={8}
                              renderOption={(
                                option: any,
                                { selected }: any
                              ) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </React.Fragment>
                              )}
                              renderInput={(
                                params: AutocompleteRenderInputParams
                              ) => (
                                <MyTextField
                                  {...params}
                                  style={{ width: "100%" }}
                                  type="text"
                                />
                              )}
                            />
                          </FormControl>
                        </Box>
                      </div>
                      <br />
                      <br />

                      <div>
                        <div className={styles.form_section_title}>
                          {/*<div className={styles.small_avatar}>*</div>*/}
                          <Typography variant="caption">
                            Where can we locate your business?
                          </Typography>
                        </div>
                        <Box className={styles.form_section} boxShadow={5}>
                          <Field
                            component={TextField}
                            name="business_location.street_address_1"
                            type="text"
                            label="No. Street address"
                            fullWidth
                            required
                          />

                          <br />
                          <br />
                          <Field
                            component={TextField}
                            name="business_location.postal_code_1"
                            type="text"
                            label="Zip / Postal code for address"
                            fullWidth
                            required
                          />
                          <br />
                          <br />
                        </Box>
                      </div>

                      <br />
                      <br />
                      <div>
                        <div className={styles.form_section_title}>
                          {/*<div className={styles.small_avatar}>*</div>*/}
                          <Typography variant="caption">
                            Social media
                          </Typography>
                        </div>
                        <Box className={styles.form_section} boxShadow={5}>
                          <Field
                            component={TextField}
                            name="social_media.facebook"
                            type="text"
                            label="Facebook page  link"
                            fullWidth
                          />
                          <br />
                          <br />
                          <Field
                            component={TextField}
                            name="social_media.instagram"
                            type="text"
                            label="Instagram Account link"
                            fullWidth
                          />
                          <br />
                          <br />

                          <Field
                            component={TextField}
                            name="social_media.whatsappbusiness"
                            type="text"
                            label="WhatsApp Business Account link"
                            fullWidth
                          />
                          <br />
                          <br />

                          <Field
                            component={TextField}
                            name="social_media.linkedIn"
                            type="text"
                            label="LinkedIn Account link"
                            fullWidth
                          />
                          <br />
                          <br />

                          <Field
                            component={TextField}
                            name="social_media.twitter"
                            type="text"
                            label="Twitter Account link"
                            fullWidth
                          />
                        </Box>
                      </div>

                      <br />
                      <br />

                      {isSubmitting && <LinearProgress />}

                      <br />
                      <br />

                      <LoadingButton
                        variant="contained"
                        color="primary"
                        pending={isLoading}
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Update
                      </LoadingButton>
                      <br />
                      <br />
                    </Grid>
                    <Grid item xs={12} lg={2}></Grid>
                  </Grid>
                </TabPanel>
              </Form>
            </>
          )}
        </Formik>
        <TabPanel value={tabIndexValue} index={3}>
          <Grid container>
            <Grid item xs={12} lg={2}></Grid>
            <Grid item xs={12} lg={8}>
              Item 4
            </Grid>
            <Grid item xs={12} lg={2}></Grid>
          </Grid>
        </TabPanel>
      </DashBoardLayout>
    </Fragment>
  );
};

export default EditStore;
