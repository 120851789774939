import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import useGetAllMomentList from "../../../hooks/ekioja-products/moments/useGetAllMomentList";
import _ from "lodash";
import moment from "moment";
import Button from "@material-ui/core/Button";
import MomentDetail from "./MomentDetail";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			borderRadius: 20,
			width: "100%",
		},
		gutter: {
			height: 30,
			width: "100%",
		},
		toolbar_wrapper: {
			// backgroundColor: '#303030',
			backgroundColor: theme.palette.background.default,
		},
		add_new_product_button: {
			marginTop: "5%",
		},
		business_logo_wrapper: {
			width: 100,
			height: 100,
			borderRadius: 100,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& img": {
				width: 80,
				height: 80,
				borderRadius: 80,
			},
		},
	})
);

const MomentList = () => {
	const styles = useStyles();

	const allMomentListQuery = useGetAllMomentList();
	const [momentDetails, setMomentDetails] = useState<any>({});
	const [momentList, setMomentList] = useState<any>([]);

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (allMomentListQuery.isSuccess) {
			const order_by_date_added = _.orderBy(
				allMomentListQuery.data,
				["added_on"],
				["desc"]
			);

			const data = _.map(order_by_date_added, (item) => {
				return {
					reprensentative_fullname: item.full_name,
					reprensentative_email: item.email,
					reprensentative_phone_number: item.phone_number,
					business_name: item.business_name,
					business_logo_url: item.store_logo_url,
					added_on: moment(item.added_on.toDate()).fromNow(),
					createdon: moment(item.added_on.toDate()).format("MM/DD/YYYY"),
					data: item,
				};
			});
			setMomentList([...data]);
		}
	}, [allMomentListQuery.isSuccess, allMomentListQuery.data]);

	return (
		<Box className={styles.root} boxShadow={5}>
			<MomentDetail
				data={momentDetails}
				open={open}
				handleClose={handleClose}
			/>
			<MaterialTable
				style={{ width: "100%" }}
				isLoading={allMomentListQuery.isLoading}
				title="Moment List"
				columns={[
					// {
					// 	title: "Logo",
					// 	field: "business_logo_url",
					// 	render: (rowData) => (
					// 		<>
					// 			<div className={styles.business_logo_wrapper}>
					// 				<img
					// 					src={rowData.business_logo_url}
					// 					alt={rowData.business_name}
					// 				/>

					// 			</div>
					// 		</>
					// 	),
					// 	align: "left",
					// },
					{
						title: "Business name",
						field: "business_name",
						cellStyle: {
							backgroundColor: "rgba(34, 179, 88, 1)",
							color: "#FFF",
							padding: 10,
							width: 200,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 1)",
							color: "#FFF",
						},
					},
					{
						title: "Brand Representative",
						field: "reprensentative_fullname",
						cellStyle: {
							width: 200,
						},

						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "Email",
						field: "reprensentative_email",
						cellStyle: {
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "Phone number",
						field: "reprensentative_phone_number",
						cellStyle: {
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "Added on",
						field: "added_on",
						render: (rowData) => (
							<Typography variant="caption">
								{rowData.added_on} <br /> {rowData.createdon}{" "}
							</Typography>
						),
						cellStyle: {
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{
						title: "Action",
						field: "action",
						render: (rowData) => (
							<Button
								variant="text"
								color="primary"
								onClick={() => {
									setMomentDetails({ ...rowData.data });
									handleClickOpen();
								}}
							>
								View
							</Button>
						),
						cellStyle: {
							padding: 10,
						},
						headerStyle: {
							backgroundColor: "rgba(34, 179, 88, 0.5)",
							color: "#FFF",
						},
					},
					{ title: "createdon", field: "createdon", hidden: true },
					{ title: "data", field: "data", hidden: true },
				]}
				data={momentList}
				options={{
					search: true,
					exportAllData: true,
					exportButton: true,
					exportFileName: "moments_list",
				}}
			/>
		</Box>
	);
};

export default MomentList;
