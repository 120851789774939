
import { useMutation} from "react-query";
import firebase from '../../config/firebaseConfig';

import {vetNumber} from '../../utils';

const acceptStoreToVet = (values: any)=>{
    const updateStoreVetStatus =  firebase.firestore().collection('Stores').doc(values.id).set({
        under_vetting_by_legal: values.status,
        pending_vet:false,
    },{merge:true});

    const newVetDocument = firebase.firestore().collection('VetDocuments').doc().set({
       vet_id:vetNumber(),
       store_id:values.id,
       vetted_by:values.by,
       to_be_vetted:{
           status:true,
           on:firebase.firestore.FieldValue.serverTimestamp()
       },
       commission:values.amount,
       added_on:firebase.firestore.FieldValue.serverTimestamp(),
    });

    const allPromise = Promise.all([updateStoreVetStatus,newVetDocument]);

return allPromise
}


 const useAcceptStoreToVet = ()=>{
    return useMutation(acceptStoreToVet);
}


export default useAcceptStoreToVet;