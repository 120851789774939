import { useMemo } from 'react';
import *  as H from "history";
import { useRouter } from './useRouter';

interface PlainObject {
    [key: string]: any;
}

const replacePathParams = (path: H.Path, pathParams?: PlainObject) => {
    let pathToGo = path;

    if (pathParams) {
        Object.keys(pathParams).forEach(param => {
            pathToGo = pathToGo.replace(`:${param}`, pathParams[param]);
        });
    }

    return pathToGo;
};

export const navigateToLink = (history: H.History) => ({
    replace: (path: H.Path) => {
        history.replace(path);
    },
    push: (path: H.Path, pathParams?: PlainObject) => {
        history.push(replacePathParams(path, pathParams));
    }
});

export const useNavigate = () => {
    const { history } = useRouter();
    const result = useMemo(() => ({ navigateToLink: navigateToLink(history) }), [history]);
    return result;
};