import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
	apiKey: "AIzaSyDLHWMwSYFB9SzyI-WzlIparGUKCOmWNK8",
	authDomain: "ekioja-7bb9f.firebaseapp.com",
	databaseURL: "https://ekioja-7bb9f.firebaseio.com",
	projectId: "ekioja-7bb9f",
	storageBucket: "ekioja-7bb9f.appspot.com",
	messagingSenderId: "751826080767",
	appId: "1:751826080767:web:c32ad697793e9e6aa34dca",
	measurementId: "G-SQW04H87NT",
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.functions();

export default firebase;
