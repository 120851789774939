import { useMutation } from "react-query";

import firebase from "../../config/firebaseConfig";

const addBannerToStoreProfile = async ({
	url,
	entity,
}: {
	url: string;
	entity: string;
}) => {
	return firebase
		.firestore()
		.collection("Stores")
		.doc(entity)
		.set(
			{
				business_banner_id: `banner_${entity}`,
				business_banner_url: url,
			},
			{ merge: true }
		);
};

const useAddBannerToStoreProfile = () => {
	return useMutation(addBannerToStoreProfile);
};

export default useAddBannerToStoreProfile;
