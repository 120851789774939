import { useMutation, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

const activateStore = (values: any) => {
	return firebase.firestore().collection("Stores").doc(values.id).set(
		{
			active: values.status,
			active_status_by: values.by,
		},
		{ merge: true }
	);
};

const useActivateStore = () => {
	return useMutation(activateStore, {
		onSuccess: () => {
			queryCache.invalidateQueries("allStores");
		},
	});
};

export default useActivateStore;
