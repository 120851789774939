import React from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";

//styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		form_wrapper: {
			padding: 5,
			borderRadius: 20,
			width: "100%",
			height: "auto",
			marginBottom: 10,
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		actionsContainer: {
			marginBottom: theme.spacing(2),
		},
		resetContainer: {
			padding: theme.spacing(3),
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		logo_wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
			height: "auto",
			marginTop: 5,
			"& img": {
				width: "30%",
				height: "auto",
			},
		},

		step: {
			backgroundColor: "#F5F9FA",
			padding: 10,
		},

		answer_wrapper: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			height: 80,
			width: "100%",
		},
		niches: {
			width: "100%",
			height: 60,
			padding: 10,
			backgroundColor: "white",
			borderRadius: 5,
			// marginBottom: 5,
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		checkbox: {
			color: "#08c551",
		},
		appBar: {
			position: "relative",
			backgroundColor: "transparent",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		result_line_wrapper: {
			width: "100%",
			height: 50,
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
			border: "1px #08c551 solid",
			borderTopLeftRadius: 20,
			borderBottomLeftRadius: 20,
		},
		wrong_result_line_wrapper: {
			width: "100%",
			height: 50,
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
			border: "1px red solid",
			borderTopLeftRadius: 20,
			borderBottomLeftRadius: 20,
		},
		correct_result_line_wrapper: {
			width: "100%",
			height: 50,
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
			border: "1px #08c551 solid",
			borderTopLeftRadius: 20,
			borderBottomLeftRadius: 20,
		},
		result_line_status_icon_correct: {
			fontSize: 40,
			color: "#08c551",
			marginRight: 20,
		},
		result_line_status_icon_wrong: {
			fontSize: 40,
			color: "red",
			marginRight: 20,
		},
		wrong_result_response_wrapper: {
			width: "100%",
			minHeight: 140,
			display: "flex",
			justifyContent: "flex-start",
			flexDirection: "column",
			alignItems: "center",
			border: "1px red solid",
			borderTopLeftRadius: 20,
			// backgroundColor: "rgb(240, 168, 165)",
			color: "#fff",
			marginTop: 5,
		},
		wrong_result_reason: {
			padding: 5,
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const EligibilityReport = ({
	data,
	open,
	handleClose,
}: {
	data: any;
	open: boolean;
	handleClose: () => void;
}) => {
	const styles = useStyles();

	// const verdict =
	// 	(data.i_am_the_creator || data.locally_produced) &&
	// 	data.i_offer_indigenous_items &&
	// 	!data.i_source_for_my_products_abroad &&
	// 	data.i_am_the_direct_representative &&
	// 	!data.i_am_an_affiliate &&
	// 	data.products_are_authentic &&
	// 	(data.reside_in_africa ||
	// 		data.labeled_as_black_owned ||
	// 		data.i_am_an_african);
	return (
		<div>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar className={styles.appBar} elevation={0}>
					<Toolbar>
						<IconButton
							edge="start"
							color="primary"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography
							variant="h6"
							color="textPrimary"
							className={styles.title}
						>
							Eligibility result
						</Typography>
					</Toolbar>
				</AppBar>

				<Grid container spacing={4}>
					<Grid item xs={12} lg={3}></Grid>
					<Grid item xs={12} lg={6}>
						<Typography
							variant="body1"
							color="textPrimary"
							style={{ marginBottom: 10, marginTop: 10 }}
							align="center"
						>
							<b>Verdict</b>
							<br />
							{data.verdict && (
								<p>This brand is eligible to be on Ekioja Marketplace. </p>
							)}
							{!data.verdict && (
								<p>This brand is not eligible to be on Ekioja Marketplace. </p>
							)}
						</Typography>

						<Divider />
						<Typography variant="h6" color="textPrimary" align="center">
							Eligibility Check-list
						</Typography>
						<br />
						{data.i_am_the_creator === true && (
							<div className={styles.correct_result_line_wrapper}>
								<CheckCircleTwoToneIcon
									className={styles.result_line_status_icon_correct}
								/>
								<div>
									<Typography variant="subtitle2" color="textPrimary">
										My team and I are the actual creator/maker of my
										product/service
									</Typography>
								</div>
							</div>
						)}
						{data.i_am_the_creator === false && (
							<div className={styles.wrong_result_response_wrapper}>
								<div className={styles.wrong_result_line_wrapper}>
									<CancelTwoToneIcon
										className={styles.result_line_status_icon_wrong}
									/>
									<div>
										<Typography variant="subtitle2" color="textPrimary">
											My team and I are the actual creator/maker of my
											product/service
										</Typography>
									</div>
								</div>
								<div className={styles.wrong_result_reason}>
									<Typography variant="body1" color="error" align="center">
										Ekoja is a marketplace exclusively for those who are the
										creators/makers of their products or services.
									</Typography>
								</div>
							</div>
						)}
						<br />
						{data.locally_produced === true && (
							<div className={styles.correct_result_line_wrapper}>
								<CheckCircleTwoToneIcon
									className={styles.result_line_status_icon_correct}
								/>
								<div>
									<Typography variant="subtitle2" color="textPrimary">
										My products are locally produced (in Nigeria/Africa) by my
										team and I
									</Typography>
								</div>
							</div>
						)}
						{data.locally_produced === false && (
							<div className={styles.wrong_result_response_wrapper}>
								<div className={styles.wrong_result_line_wrapper}>
									<CancelTwoToneIcon
										className={styles.result_line_status_icon_wrong}
									/>
									<div>
										<Typography variant="subtitle2" color="textPrimary">
											My products are locally produced (in Nigeria/Africa) by my
											team and I
										</Typography>
									</div>
								</div>
								<div className={styles.wrong_result_reason}>
									<Typography variant="body1" color="error" align="center">
										Ekioja is a marketplace exclusively for products sourced and
										created in Nigeria/Africa. We do not list or promote foreign
										businesses or products/services.
									</Typography>
								</div>
							</div>
						)}
						<br />
						{data.i_offer_indigenous_items === true && (
							<div className={styles.correct_result_line_wrapper}>
								<CheckCircleTwoToneIcon
									className={styles.result_line_status_icon_correct}
								/>
								<div>
									<Typography variant="subtitle2" color="textPrimary">
										I sell/offer indigenous (Nigerian or African)
										products/services
									</Typography>
								</div>
							</div>
						)}
						{data.i_offer_indigenous_items === false && (
							<div className={styles.wrong_result_response_wrapper}>
								<div className={styles.wrong_result_line_wrapper}>
									<CancelTwoToneIcon
										className={styles.result_line_status_icon_wrong}
									/>
									<div>
										<Typography variant="subtitle2" color="textPrimary">
											I sell/offer indigenous (Nigerian or African)
											products/services
										</Typography>
									</div>
								</div>
								<div className={styles.wrong_result_reason}>
									<Typography variant="body1" color="error" align="center">
										Ekioja is a marketplace exclusively for selling/offering
										indigenous (Nigerian/African) products and services.
									</Typography>
								</div>
							</div>
						)}
						<br />
						{data.i_source_for_my_products_abroad === false && (
							<div className={styles.correct_result_line_wrapper}>
								<CheckCircleTwoToneIcon
									className={styles.result_line_status_icon_correct}
								/>
								<div>
									<Typography variant="subtitle2" color="textPrimary">
										I source for my products from China or other foreign
										countries
									</Typography>
								</div>
							</div>
						)}
						{data.i_source_for_my_products_abroad === true && (
							<div className={styles.wrong_result_response_wrapper}>
								<div className={styles.wrong_result_line_wrapper}>
									<CancelTwoToneIcon
										className={styles.result_line_status_icon_wrong}
									/>
									<div>
										<Typography variant="subtitle2" color="textPrimary">
											I source for my products from China or other foreign
											countries
										</Typography>
									</div>
								</div>
								<div className={styles.wrong_result_reason}>
									<Typography variant="body1" color="error" align="center">
										Ekioja is a marketplace exclusively for selling/offering
										indigenous (Nigerian/African) products and services.
									</Typography>
								</div>
							</div>
						)}
						<br />
						{data.i_am_the_direct_representative === true && (
							<div className={styles.correct_result_line_wrapper}>
								<CheckCircleTwoToneIcon
									className={styles.result_line_status_icon_correct}
								/>
								<div>
									<Typography variant="subtitle2" color="textPrimary">
										I am the direct representative/owner of my brand
									</Typography>
								</div>
							</div>
						)}
						{data.i_am_the_direct_representative === false && (
							<div className={styles.wrong_result_response_wrapper}>
								<div className={styles.wrong_result_line_wrapper}>
									<CancelTwoToneIcon
										className={styles.result_line_status_icon_wrong}
									/>
									<div>
										<Typography variant="subtitle2" color="textPrimary">
											I am the direct representative/owner of my brand
										</Typography>
									</div>
								</div>
								<div className={styles.wrong_result_reason}>
									<Typography variant="body1" color="error" align="center">
										Ekioja is a marketplace exclusively for direct owners,
										producers and creators. At this time, we do not have
										accounts or 3rd party listing options.
									</Typography>
								</div>
							</div>
						)}
						<br />
						{data.i_am_an_african === true && (
							<div className={styles.correct_result_line_wrapper}>
								<CheckCircleTwoToneIcon
									className={styles.result_line_status_icon_correct}
								/>
								<div>
									<Typography variant="subtitle2" color="textPrimary">
										I am an African/of African desent
									</Typography>
								</div>
							</div>
						)}
						{data.i_am_an_african === false && (
							<div className={styles.wrong_result_response_wrapper}>
								<div className={styles.wrong_result_line_wrapper}>
									<CancelTwoToneIcon
										className={styles.result_line_status_icon_wrong}
									/>
									<div>
										<Typography variant="subtitle2" color="textPrimary">
											I am an African/of African desent
										</Typography>
									</div>
								</div>
								<div className={styles.wrong_result_reason}>
									<Typography variant="body1" color="error" align="center">
										Ekioja is a marketplace exclusively for Africans, those of
										African desent or those doing business in Africa.
									</Typography>
								</div>
							</div>
						)}
						<br />
						{data.i_am_an_affiliate === false && (
							<div className={styles.correct_result_line_wrapper}>
								<CheckCircleTwoToneIcon
									className={styles.result_line_status_icon_correct}
								/>
								<div>
									<Typography variant="subtitle2" color="textPrimary">
										I am an affiliate or 3rd party to this brand
									</Typography>
								</div>
							</div>
						)}
						{data.i_am_an_affiliate === true && (
							<div className={styles.wrong_result_response_wrapper}>
								<div className={styles.wrong_result_line_wrapper}>
									<CancelTwoToneIcon
										className={styles.result_line_status_icon_wrong}
									/>
									<div>
										<Typography variant="subtitle2" color="textPrimary">
											I am an affiliate or 3rd party to this brand
										</Typography>
									</div>
								</div>
								<div className={styles.wrong_result_reason}>
									<Typography variant="body1" color="error" align="center">
										Ekioja is a marketplace exclusively for direct owners,
										producers and creators. At this time, we do not have
										accounts or 3rd party listing options.
									</Typography>
								</div>
							</div>
						)}
						<br />
						{data.products_are_authentic === true && (
							<div className={styles.correct_result_line_wrapper}>
								<CheckCircleTwoToneIcon
									className={styles.result_line_status_icon_correct}
								/>
								<div>
									<Typography variant="subtitle2" color="textPrimary">
										My products are authentic and genuine
									</Typography>
								</div>
							</div>
						)}
						{data.products_are_authentic === false && (
							<div className={styles.wrong_result_response_wrapper}>
								<div className={styles.wrong_result_line_wrapper}>
									<CancelTwoToneIcon
										className={styles.result_line_status_icon_wrong}
									/>
									<div>
										<Typography variant="subtitle2" color="textPrimary">
											My products are authentic and genuine
										</Typography>
									</div>
								</div>
								<div className={styles.wrong_result_reason}>
									<Typography variant="body1" color="error" align="center">
										Ekioja is a marketplace exclusively for authentic, genuine
										made-in-Africa products and services.
									</Typography>
								</div>
							</div>
						)}
						<br />
						{data.products_are_authentic === true && (
							<div className={styles.correct_result_line_wrapper}>
								<CheckCircleTwoToneIcon
									className={styles.result_line_status_icon_correct}
								/>
								<div>
									<Typography variant="subtitle2" color="textPrimary">
										I reside or am doing business in Nigeria/Africa
									</Typography>
								</div>
							</div>
						)}
						{data.products_are_authentic === false && (
							<div className={styles.wrong_result_response_wrapper}>
								<div className={styles.wrong_result_line_wrapper}>
									<CancelTwoToneIcon
										className={styles.result_line_status_icon_wrong}
									/>
									<div>
										<Typography variant="subtitle2" color="textPrimary">
											I reside or am doing business in Nigeria/Africa
										</Typography>
									</div>
								</div>
								<div className={styles.wrong_result_reason}>
									<Typography variant="body1" color="error" align="center">
										Ekioja is a marketplace exclusively for Africans, those of
										African desent or those doing business in Africa.
									</Typography>
								</div>
							</div>
						)}
						<br />
						{data.labeled_as_black_owned === true && (
							<div className={styles.correct_result_line_wrapper}>
								<CheckCircleTwoToneIcon
									className={styles.result_line_status_icon_correct}
								/>
								<div>
									<Typography variant="subtitle2" color="textPrimary">
										My brand is labeled as black-owned
									</Typography>
								</div>
							</div>
						)}
						{data.labeled_as_black_owned === false && (
							<div className={styles.wrong_result_response_wrapper}>
								<div className={styles.wrong_result_line_wrapper}>
									<CancelTwoToneIcon
										className={styles.result_line_status_icon_wrong}
									/>
									<div>
										<Typography variant="subtitle2" color="textPrimary">
											My brand is labeled as black-owned
										</Typography>
									</div>
								</div>
								<div className={styles.wrong_result_reason}>
									<Typography variant="body1" color="error" align="center">
										Ekioja is a marketplace exclusively for Africans, those of
										African desent or those doing business in Africa.
									</Typography>
								</div>
							</div>
						)}
						<br />
					</Grid>
					<Grid item xs={12} lg={3}></Grid>
				</Grid>
			</Dialog>
		</div>
	);
};

export default EligibilityReport;
