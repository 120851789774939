import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { FormControlLabel, Radio } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { RadioGroup, TextField } from "formik-material-ui";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import NumberFormat from "react-number-format";
import useAddShippingOption from "../../../../../../../../../../hooks/chat/invoice/useAddShippingOption";
import _ from "lodash";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { Conversation } from "../../../../../../../ConservationList/ConservationItem";

interface NumberFormatCustomProps {
	inputRef: (instance: NumberFormat | null) => void;
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const useStyles = makeStyles({
	root: {},
});

const ShippingOptions = (props: {
	data: Conversation;
	handleOpenSummary: () => void;
}) => {
	const styles = useStyles();

	const profile = useSelector((state: any) => state.firebase.profile);

	function NumberFormatCustom(props: NumberFormatCustomProps) {
		const { inputRef, onChange, ...other } = props;

		return (
			<NumberFormat
				{...other}
				getInputRef={inputRef}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				thousandSeparator
				isNumericString
				prefix={"₦"}
			/>
		);
	}

	const [
		AddShippingOptionMutate,
		AddShippingOptionStatus,
	] = useAddShippingOption();
	return (
		<Box
			p={3}
			boxShadow={3}
			marginTop={2}
			className={styles.root}
			borderRadius={3}
		>
			<Formik
				key={`${props.data.chat_id}`}
				initialValues={{
					shipping_option: props.data.shippingOption
						? props.data.shippingOption.shipping_option
						: "",
					door_delivery_agreed_cost: props.data.shippingOption
						? props.data.shippingOption.door_delivery_agreed_cost
						: 0,
					dollarNairaRate: 1,
				}}
				onSubmit={(values, { setSubmitting }) => {
					AddShippingOptionMutate(
						{ chat_id: props.data.chat_id, ...values },
						{
							onSuccess: () => {
								props.handleOpenSummary();
								AddShippingOptionStatus.reset();
							},
						}
					);
					setSubmitting(false);
				}}
			>
				{({ isSubmitting, values, handleChange, submitForm }) => (
					<Form>
						<FormControl component="fieldset">
							<FormLabel component="legend">Shipping options</FormLabel>
							<Field
								component={RadioGroup}
								name="shipping_option"
								value={values.shipping_option}
								onChange={handleChange}
							>
								<FormControlLabel
									value="door_delivery"
									control={<Radio disabled={isSubmitting} />}
									label="Door delivery"
									disabled={isSubmitting}
								/>
								<FormControlLabel
									value="pickup_by_customer"
									control={<Radio disabled={isSubmitting} />}
									label="Pickup by me"
									disabled={isSubmitting}
								/>
							</Field>
						</FormControl>
						<br />
						{/* {values.shipping_option === "door_delivery" && (
							<Field
								id="door_delivery_agreed_cost"
								component={TextField}
								label="Agreed cost"
								name="door_delivery_agreed_cost"
								variant="filled"
								InputProps={{
									inputComponent: NumberFormatCustom as any,
									notched: true,
								}}
								value={values.door_delivery_agreed_cost}
								onChange={(e: any) => {
									setFieldValue(
										"door_delivery_agreed_cost",
										e.target.value,
										false
									);
								}}
								fullWidth
							/>
						)} */}
						{values.shipping_option === "door_delivery" && (
							<Field
								id="door_delivery_agreed_cost"
								component={TextField}
								label="Agreed cost"
								name="door_delivery_agreed_cost"
								variant="filled"
								type="text"
								InputProps={{
									inputComponent: NumberFormatCustom,
								}}
								fullWidth
							/>
						)}
						<br />
						<br />
						{/* <Button
							variant="contained"
							color="primary"
							style={{ color: "#fff" }}
							disabled={
								isSubmitting ||
								values.shipping_option === "" ||
								(values.shipping_option === "door_delivery" &&
									values.door_delivery_agreed_cost === "") ||
								_.has(props.data, "shippingOption")
							}
							onClick={() => {
								if (window.confirm("Are you sure?")) {
									submitForm();
								}
							}}
						>
							{AddShippingOptionStatus.isLoading
								? "Please wait..."
								: AddShippingOptionStatus.isError
								? "Error!"
								: AddShippingOptionStatus.isSuccess
								? "Done!"
								: "Continue"}
						</Button> */}
						<Button
							variant="contained"
							color="primary"
							style={{ color: "#fff" }}
							disabled={isSubmitting}
							onClick={() => {
								if (window.confirm("Are you sure?")) {
									submitForm();
								}
							}}
						>
							{AddShippingOptionStatus.isLoading
								? "Please wait..."
								: AddShippingOptionStatus.isError
								? "Error!"
								: AddShippingOptionStatus.isSuccess
								? "Done!"
								: props.data.shippingOption
								? "Update"
								: "Continue"}
						</Button>
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default ShippingOptions;
