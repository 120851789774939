import { useMutation, queryCache } from "react-query";
import firebase from "../../../config/firebaseConfig";

interface Props {
	chat_id: string;
	product_id: string;
	product_name: string;
	product_image: string;
	product_price: number;
	product_quantity: number;
}

const generateInvoice = async (values: Props) => {
	return firebase
		.firestore()
		.collection("Conversations")
		.doc(values.chat_id)
		.set(
			{
				invoice: [
					{
						product_id: values.product_id,
						product_name: values.product_name,
						product_price: values.product_price,
						product_quantity: values.product_quantity,
						product_image: values.product_image,
					},
				],
			},
			{ merge: true }
		);
};

const useGenerateInvoice = () => {
	return useMutation(generateInvoice, {
		onSettled: (returnedData, error, sentVaribles: any) => {
			queryCache.invalidateQueries([
				"chatCurrentConversation",
				sentVaribles.chat_id,
			]);
			queryCache.refetchQueries(
				["chatCurrentConversation", sentVaribles.chat_id],
				{ active: true, exact: true }
			);
		},
	});
};

export default useGenerateInvoice;
