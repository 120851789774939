//import firebase
import firebase from '../config/firebaseConfig';
//import reducer
import rootReducer from '../store/reducers/rootReducer';
//binding for fireebase
import { applyMiddleware, compose, createStore } from "redux";

import { loadingBarMiddleware } from 'react-redux-loading-bar'
import {
  reduxFirestore,
  getFirestore,
} from "redux-firestore";
import { getFirebase } from "react-redux-firebase";
//middleware
import thunk from "redux-thunk";



export const rrfConfig = {
  userProfile: 'Admins',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

const middlewares = [thunk.withExtraArgument({ getFirebase, getFirestore }),loadingBarMiddleware()];
export const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middlewares), reduxFirestore(firebase))
);
