import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";

const removeHotPickProduct = (id: string) => {
  return firebase.firestore().collection("Products").doc(id).set({is_hot_product:false},{merge:true});
};

const useRemoveHotPickProduct = () => {
  return useMutation(removeHotPickProduct);
};

export default useRemoveHotPickProduct;
