import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { isLoaded } from "react-redux-firebase";
import _ from "lodash";
import moment from "moment";
//material table
import MaterialTable from "material-table";
import { Scrollbars } from "rc-scrollbars";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { getStoresCounts, getStoresReport } from "../../utils/index";
import * as ROUTES from "../../constants/routes";

const useStyles = makeStyles({
	queries_wrapper: {
		width: 1000,
		padding: 2,
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
	},
});

const convertProductNameToPermalink = (sentence: string) => {
	const result_transform_to_lowercase = _.lowerCase(sentence)
		.split(" ")
		.join("-");
	return result_transform_to_lowercase;
};

const FilterSortBrands = () => {
	const styles = useStyles();
	const [tableData, setTableData] = useState<any>([]);
	const [tableTitle, setTableTitle] = useState("");
	const refTableContainer = useRef<any>(null);

	useFirestoreConnect([
		{
			collection: "Admins",
		},
		{
			collection: "Stores",
		},
		{
			collection: "Products",
		},
		// {
		// 	collection: "allStores",
		// 	where: [["vetted", "==", true]],
		// 	storeAs: "VettedStores",
		// },
	]);

	const allStores = useSelector((state: any) => state.firestore.ordered.Stores);
	const admins = useSelector((state: any) => state.firestore.ordered.Admins);
	const allProducts = useSelector(
		(state: any) => state.firestore.ordered.Products
	);

	useEffect(() => {
		if (isLoaded(allStores) && isLoaded(admins) && isLoaded(allProducts)) {
			let all_store_data: any[] = [];
			if (allStores !== null) {
				for (var all_store_key in allStores) {
					all_store_data.push({
						...allStores[all_store_key],
						all_store_uuid: all_store_key,
					});
				}
			}

			const all_same_docs_in_stores_and_admins = _.intersectionWith(
				_.cloneDeep(admins),
				all_store_data,
				function (x: any, y: any) {
					return (
						x.entity === y.unique_id &&
						x.role === "Administrator" &&
						_.assign(x, y)
					);
				}
			);

			const resultExtractEntityIdFromProducts = _.map(
				allProducts,
				(items) => items.entity
			);

			const resultExtractEntityIdFromProductsStoresAdmin = _.map(
				all_same_docs_in_stores_and_admins,
				(item) => {
					let product_entity = _.filter(
						resultExtractEntityIdFromProducts,
						(item2) => item2 === item.unique_id
					);

					return {
						product_store_id: item.unique_id,
						product_count: product_entity.length,
					};
				}
			);

			const all_same_docs_in_stores_and_admins_and_products = _.intersectionWith(
				all_same_docs_in_stores_and_admins,
				resultExtractEntityIdFromProductsStoresAdmin,
				function (x: any, y: any) {
					return x.entity === y.product_store_id && _.assign(x, y);
				}
			);

			const sortedFilteredStores = _.orderBy(
				all_same_docs_in_stores_and_admins_and_products,
				["date_added"],
				["desc"]
			);

			const result = _.map(sortedFilteredStores, (item: any) => {
				return {
					business_name: item.business_name,
					business_niche: item.business_niche.join(" , "),
					business_location: `${item.business_location?.street_address_1}${item.business_location?.lga}${item.business_location?.ward}`,
					date_added: moment(item.date_added?.toDate()).fromNow(),
					representative: `${item.first_name}  ${item.last_name}`,
					business_phone_number: ` ${item.contact_phone_number}`,
					email: ` ${item.email}`,
					product_count: ` ${item.product_count}`,
				};
			});

			setTableData([...result]);
			setTableTitle("All Stores");
		}
	}, [allStores, admins, allProducts]);

	return (
		<div>
			<div>
				<h4>Granular Brands Report</h4>
				<Scrollbars
					style={{ width: "100%", height: 50 }}
					autoHide
					autoHideTimeout={1000}
				>
					<div className={styles.queries_wrapper}>
						<Chip
							label={`All Stores ${getStoresCounts(
								allStores,
								ROUTES.VIEWALLSTORESQUERIES.ALLSTORES
							)}`}
							variant="default"
							onClick={() => {
								setTableData([
									...getStoresReport(
										allStores,
										_.cloneDeep(admins),
										ROUTES.VIEWALLSTORESQUERIES.ALLSTORES
									),
								]);

								setTableTitle("All Stores");
							}}
						/>
						<Chip
							label={`Active Stores ${getStoresCounts(
								allStores,
								ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES
							)}`}
							variant="default"
							onClick={() => {
								setTableData([
									...getStoresReport(
										allStores,
										_.cloneDeep(admins),
										ROUTES.VIEWALLSTORESQUERIES.ALL_ACTIVE_STORES
									),
								]);

								setTableTitle("All Active Stores");
							}}
						/>
						<Chip
							label={`In-Active Stores ${getStoresCounts(
								allStores,
								ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES
							)}`}
							variant="default"
							onClick={() => {
								setTableData([
									...getStoresReport(
										allStores,
										_.cloneDeep(admins),
										ROUTES.VIEWALLSTORESQUERIES.ALL_IN_ACTIVE_STORES
									),
								]);

								setTableTitle("All In-active Stores");
							}}
						/>
						<Chip
							label={`Blocked Stores ${getStoresCounts(
								allStores,
								ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES
							)}`}
							variant="default"
							onClick={() => {
								setTableData([
									...getStoresReport(
										allStores,
										_.cloneDeep(admins),
										ROUTES.VIEWALLSTORESQUERIES.ALL_BLOCKED_STORES
									),
								]);

								setTableTitle("All Blocked Stores");
							}}
						/>
						<Chip
							label={`Approved Stores ${getStoresCounts(
								allStores,
								ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES
							)}`}
							variant="default"
							onClick={() => {
								setTableData([
									...getStoresReport(
										allStores,
										_.cloneDeep(admins),
										ROUTES.VIEWALLSTORESQUERIES.ALL_APPROVED_STORES
									),
								]);

								setTableTitle("All Approved Stores");
							}}
						/>
						<Chip
							label={`Not Approved Stores ${getStoresCounts(
								allStores,
								ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES
							)}`}
							variant="default"
							onClick={() => {
								setTableData([
									...getStoresReport(
										allStores,
										_.cloneDeep(admins),
										ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_APPROVED_STORES
									),
								]);

								setTableTitle("All Not Approved Stores");
							}}
						/>

						<Chip
							label={`UnPaid Pending Stores ${getStoresCounts(
								allStores,
								ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES
							)}`}
							variant="default"
							onClick={() => {
								setTableData([
									...getStoresReport(
										allStores,
										_.cloneDeep(admins),
										ROUTES.VIEWALLSTORESQUERIES.ALL_NOT_PAID_PENDING_STORES
									),
								]);

								setTableTitle("All Not Paid Pending Stores");
							}}
						/>
						<Chip
							label={`Under-Vetting Stores ${getStoresCounts(
								allStores,
								ROUTES.VIEWALLSTORESQUERIES.ALL_PAID_PENDING_STORES
							)}`}
							variant="default"
							onClick={() => {
								setTableData([
									...getStoresReport(
										allStores,
										_.cloneDeep(admins),
										ROUTES.VIEWALLSTORESQUERIES.ALL_PAID_PENDING_STORES
									),
								]);

								setTableTitle("All Under vetting Stores");
							}}
						/>
						<Chip
							label={`Vetted Stores ${getStoresCounts(
								allStores,
								ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE
							)}`}
							variant="default"
							onClick={() => {
								setTableData([
									...getStoresReport(
										allStores,
										_.cloneDeep(admins),
										ROUTES.VIEWALLSTORESQUERIES.All_VETTED_STORE
									),
								]);

								setTableTitle("All Vetted Stores");
							}}
						/>
						<Chip
							label={`Failed Vet Stores ${getStoresCounts(
								allStores,
								ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE
							)}`}
							variant="default"
							onClick={() => {
								setTableData([
									...getStoresReport(
										allStores,
										_.cloneDeep(admins),
										ROUTES.VIEWALLSTORESQUERIES.ALL_FAILED_STORE
									),
								]);

								setTableTitle("All Failed Vet Stores");
							}}
						/>
					</div>
				</Scrollbars>
			</div>
			<div style={{ margin: 20 }} ref={refTableContainer}>
				<MaterialTable
					style={{
						width: "100%",
					}}
					isLoading={isLoaded(allStores) && isLoaded(admins) ? false : true}
					title={tableTitle}
					columns={[
						{
							title: "Store name",
							field: "business_name",
							cellStyle: {
								backgroundColor: "rgba(34, 179, 88, 1)",
								color: "#FFF",
								padding: 10,
							},
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 1)",
								color: "#FFF",
							},
						},
						{
							title: "Name of Rep",
							field: "representative",
							cellStyle: {
								width: 300,
							},
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
						},
						{
							title: "Phone",
							field: "business_phone_number",
							cellStyle: {
								width: 300,
							},
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
						},
						{
							title: "Email",
							field: "email",
							cellStyle: {
								width: 300,
							},
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
						},
						{
							title: "Office Address",
							field: "business_location",
							cellStyle: {
								width: 200,
							},

							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
						},
						{
							title: "Niche(s)",
							field: "business_niche",
							cellStyle: {
								padding: 10,
							},
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
						},
						{
							title: "Number of Product/Service",
							field: "product_count",
							type: "numeric",
							cellStyle: {
								padding: 10,
							},
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
						},

						{
							title: "Joined",
							field: "date_added",
							headerStyle: {
								backgroundColor: "rgba(34, 179, 88, 0.5)",
								color: "#FFF",
							},
							filtering: false,
						},
					]}
					data={tableData}
					options={{
						search: true,
						// actionsColumnIndex: -1,
						exportAllData: true,
						exportButton: true,
						exportFileName: `${convertProductNameToPermalink(tableTitle)}`,
						rowStyle: {
							borderLeft: "6px solid green",
						},
						filtering: true,
					}}
				/>
			</div>
		</div>
	);
};

export default FilterSortBrands;
