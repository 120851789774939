  
import { useQuery } from "react-query";
import firebase from '../../config/firebaseConfig';


const getBrandStory = async (key:any,store_id:string) =>{
    return firebase.firestore().collection('BrandStories').where("store_id","==",store_id).where("type_of_story","==","Product").get().then(function(querySnapshot:any) {
        
       let docArr:any = []
   
        querySnapshot.forEach(function(doc:any) {
           
            docArr.push({uuid:doc.id,...doc.data()})
        });

        return docArr
    })
    .catch(function(error:any) {
        console.log("Error getting documents: ", error);
    });
 
}


 const useGetBrandStory = (id:string)=>{
    return useQuery(['vendorBrandStory',id],getBrandStory)
}


export default useGetBrandStory;