import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
import { ACREATIVE, FCREATIVE, LOGISTICS } from "../../constants/roles";

const createAdminAccount = async (values: any) => {
	const response = await firebase
		.auth()
		.createUserWithEmailAndPassword(values.email, values.password);

	//add displayName
	const updateProfilePromise = firebase.auth().currentUser?.updateProfile({
		displayName: values.first_name + " " + values.last_name,
	});

	//create logistics company account if exist

	//create new doc in Admin collection
	const createCollectionPromise = firebase
		.firestore()
		.collection("Admins")
		.doc(response.user?.uid)
		.set({
			first_name: values.first_name,
			last_name: values.last_name,
			username: values.username,
			role: values.role,
			entity: values.entity,
			logistics_role: values.logistics_role,
			class: values.class,
			email: values.email,
			deviceData: values.deviceData,
			approved: true,
			blocked: false,
			isNew: true,
			blocked_history: [],
			removed: false,
			removed_data: [],
			joined_on: firebase.firestore.FieldValue.serverTimestamp(),
		});

	//create wallet
	let createStoreWallet;
	if (values.role === ACREATIVE || values.role === FCREATIVE) {
		createStoreWallet = await firebase
			.firestore()
			.collection("Wallets")
			.doc(response.user?.uid)
			.set({
				income: 0,
				brand_creatives_balance: 0,
				face_behind_the_brand_balance: 0,
				marketplace_products_balance: 0,
				brand_stories_balance: 0,
				product_stories_balance: 0,
				owner_id: response.user?.uid,
			});
	} else if (values.role === LOGISTICS) {
		createStoreWallet = await firebase
			.firestore()
			.collection("Wallets")
			.doc(values.entity)
			.set({
				income: 0,
				owner_id: values.entity,
			});
	} else {
		createStoreWallet = await firebase
			.firestore()
			.collection("Wallets")
			.doc(response.user?.uid)
			.set({
				income: 0,
				owner_id: response.user?.uid,
			});
	}

	if (values.role === LOGISTICS) {
		firebase
			.firestore()
			.collection("LogisticsCompanies")
			.doc(values.entity)
			.set({
				logistics_company_name: values.logistics_company_name,
				unique_id: values.entity,
				approved: false,
				active: false,
				blocked: false,
				isNew: true,
				admins: [response.user?.uid],
				joined_on: firebase.firestore.FieldValue.serverTimestamp(),
			});
	}

	//send email verification
	const sendEmailPromise = firebase.auth().currentUser?.sendEmailVerification();

	//allpromise
	const allPromise = Promise.all([
		updateProfilePromise,
		createCollectionPromise,
		sendEmailPromise,
		createStoreWallet,
	]);

	return allPromise;
};

const useCreateAdminAccount = () => {
	return useMutation(createAdminAccount);
};

export default useCreateAdminAccount;
