import { useMutation } from "react-query";
import firebase from "../../../config/firebaseConfig";

const removeImageDataToVendor = (values: {
	id: string;
	jobid: string;
	type: string;
	image_num?: number;
}) => {
	let allPromise: any;
	if (values.type === "logo") {
		const removeImageToStore = firebase
			.firestore()
			.collection("Stores")
			.doc(values.id)
			.set(
				{
					business_logo_url: "",
				},
				{ merge: true }
			);
		const removeImageToJob = firebase
			.firestore()
			.collection("CreativeJobs")
			.doc(values.jobid)
			.set(
				{
					business_logo_url: "",
				},
				{ merge: true }
			);

		allPromise = Promise.all([removeImageToStore, removeImageToJob]);

		return allPromise;
	} else if (values.type === "banner") {
		const removeImageToStore = firebase
			.firestore()
			.collection("Stores")
			.doc(values.id)
			.set(
				{
					business_banner_url: "",
				},
				{ merge: true }
			);
		const removeImageToJob = firebase
			.firestore()
			.collection("CreativeJobs")
			.doc(values.jobid)
			.set(
				{
					business_banner_url: "",
				},
				{ merge: true }
			);

		allPromise = Promise.all([removeImageToStore, removeImageToJob]);

		return allPromise;
	} else if (values.type === "fbtb") {
		const addImageToStore = firebase
			.firestore()
			.collection("Stores")
			.doc(values.id)
			.set(
				{
					fbtb_url: "",
				},
				{ merge: true }
			);
		const addImageToJob = firebase
			.firestore()
			.collection("CreativeJobs")
			.doc(values.jobid)
			.set(
				{
					fbtb_url: "",
				},
				{ merge: true }
			);

		allPromise = Promise.all([addImageToStore, addImageToJob]);

		return allPromise;
	} else if (values.type === "marketplaceproduct" && values.image_num === 1) {
		const addImageToProduct = firebase
			.firestore()
			.collection("Products")
			.doc(values.id)
			.set(
				{
					image_1_url: "",
				},
				{ merge: true }
			);
		const addImageToJob = firebase
			.firestore()
			.collection("CreativeJobs")
			.doc(values.jobid)
			.set(
				{
					product_data: {
						image_1_url: "",
					},
				},
				{ merge: true }
			);

		allPromise = Promise.all([addImageToProduct, addImageToJob]);

		return allPromise;
	} else if (values.type === "marketplaceproduct" && values.image_num === 2) {
		const addImageToProduct = firebase
			.firestore()
			.collection("Products")
			.doc(values.id)
			.set(
				{
					image_2_url: "",
				},
				{ merge: true }
			);
		const addImageToJob = firebase
			.firestore()
			.collection("CreativeJobs")
			.doc(values.jobid)
			.set(
				{
					product_data: {
						image_2_url: "",
					},
				},
				{ merge: true }
			);

		allPromise = Promise.all([addImageToProduct, addImageToJob]);

		return allPromise;
	} else if (values.type === "marketplaceproduct" && values.image_num === 3) {
		const addImageToProduct = firebase
			.firestore()
			.collection("Products")
			.doc(values.id)
			.set(
				{
					image_3_url: "",
				},
				{ merge: true }
			);
		const addImageToJob = firebase
			.firestore()
			.collection("CreativeJobs")
			.doc(values.jobid)
			.set(
				{
					product_data: {
						image_2_url: "",
					},
				},
				{ merge: true }
			);

		allPromise = Promise.all([addImageToProduct, addImageToJob]);

		return allPromise;
	} else if (values.type === "marketplaceproduct" && values.image_num === 4) {
		const addImageToProduct = firebase
			.firestore()
			.collection("Products")
			.doc(values.id)
			.set(
				{
					image_4_url: "",
				},
				{ merge: true }
			);
		const addImageToJob = firebase
			.firestore()
			.collection("CreativeJobs")
			.doc(values.jobid)
			.set(
				{
					product_data: {
						image_4_url: "",
					},
				},
				{ merge: true }
			);

		allPromise = Promise.all([addImageToProduct, addImageToJob]);

		return allPromise;
	}
};

const useRemoveImageDataToVendor = () => {
	return useMutation(removeImageDataToVendor);
};

export default useRemoveImageDataToVendor;
