import React, { Fragment, useState, useEffect } from "react";

//styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//material table
import MaterialTable from "material-table";

//material ui components
// import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import EditIcon from "@material-ui/icons/Edit";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LineStyleIcon from "@material-ui/icons/LineStyle";
//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//navigation hook
import { useNavigate } from "../../../hooks/navigate";

//routes
import * as ROUTES from "../../../constants/routes";

import _ from "lodash";

// import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

import ProcessFaceBehindTheBrandJob from "./dialogs/ProcessFaceBehindTheBrandJob";
import CompletedFaceBehindTheBrandJob from "./dialogs/CompletedFaceBehindTheBrandJob";

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			position: "relative",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		root: {
			display: "flex",

			width: "100%",
		},

		queries_wrapper: {
			width: "100%",
			padding: 10,
			display: "flex",
			justifyContent: "space-evenly",
			alignItems: "center",
		},
	})
);

const FaceBehindTheBrandJobs = ({ query }: { query?: string }) => {
	const styles = useStyles();
	const { navigateToLink } = useNavigate();
	const [tableTitle, setTableTitle] = useState("");
	const [pageloading, setpageloading] = useState(true);
	const [tableData, setTableData] = useState<any>([]);
	const [openProcess, setOpenProcess] = React.useState(false);
	const [processJobData, setProcessJobData] = useState<any>({});
	const [typeofJob, setTypeofJob] = useState("");
	const handleClickOpenProcess = () => {
		setOpenProcess(true);
	};

	const handleCloseProcess = () => {
		setOpenProcess(false);
	};

	// const [viewStoriesData, setViewStoriesData] = useState<any>({});
	// const [openViewVideoModal, setOpenViewVideoModal] = useState(false);

	useFirestoreConnect([
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "FACE_BEHIND_THE_BRAND"],
				["to_be_processed.status", "==", true],
			],
			storeAs: "ToBeProcessedFaceBehindTheBrandJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "FACE_BEHIND_THE_BRAND"],
				["processing.status", "==", true],
			],
			storeAs: "ProcessedFaceBehindTheBrandJobs",
		},
		{
			collection: "CreativeJobs",
			where: [
				["type", "==", "FACE_BEHIND_THE_BRAND"],
				["completed.status", "==", true],
			],
			storeAs: "CompletedFaceBehindTheBrandJobs",
		},
	]);

	const toBeProcessedFaceBehindTheBrandJobs = useSelector(
		(state: any) => state.firestore.data["ToBeProcessedFaceBehindTheBrandJobs"]
	);

	const processedFaceBehindTheBrandJobs = useSelector(
		(state: any) => state.firestore.data["ProcessedFaceBehindTheBrandJobs"]
	);
	const completedFaceBehindTheBrandJobs = useSelector(
		(state: any) => state.firestore.data["CompletedFaceBehindTheBrandJobs"]
	);

	const [
		ToBeProcessedFaceBehindTheBrandJobs,
		setToBeProcessedFaceBehindTheBrandJobs,
	] = useState<any>([]);
	const [
		ProcessedFaceBehindTheBrandJobs,
		setProcessedFaceBehindTheBrandJobs,
	] = useState<any>([]);
	const [
		CompletedFaceBehindTheBrandJobs,
		setCompletedFaceBehindTheBrandJobs,
	] = useState<any>([]);

	useEffect(() => {
		if (
			isLoaded(toBeProcessedFaceBehindTheBrandJobs) &&
			typeof toBeProcessedFaceBehindTheBrandJobs !== "undefined" &&
			isLoaded(processedFaceBehindTheBrandJobs) &&
			typeof processedFaceBehindTheBrandJobs !== "undefined"
		) {
			let toBeProcessedFaceBehindTheBrandJobs_data: any[] = [];
			if (toBeProcessedFaceBehindTheBrandJobs !== null) {
				for (var toBeProcessedFaceBehindTheBrandJobs_data_item in toBeProcessedFaceBehindTheBrandJobs) {
					toBeProcessedFaceBehindTheBrandJobs_data.push({
						...toBeProcessedFaceBehindTheBrandJobs[
							toBeProcessedFaceBehindTheBrandJobs_data_item
						],
						toBeProcessedFaceBehindTheBrandJobs_data_uuid: toBeProcessedFaceBehindTheBrandJobs_data_item,
					});
				}
			}

			setToBeProcessedFaceBehindTheBrandJobs([
				...toBeProcessedFaceBehindTheBrandJobs_data,
			]);

			let processedFaceBehindTheBrandJobs_data: any[] = [];
			if (processedFaceBehindTheBrandJobs !== null) {
				for (var processedFaceBehindTheBrandJobs_data_item in processedFaceBehindTheBrandJobs) {
					processedFaceBehindTheBrandJobs_data.push({
						...processedFaceBehindTheBrandJobs[
							processedFaceBehindTheBrandJobs_data_item
						],
						processedFaceBehindTheBrandJobs_data_uuid: processedFaceBehindTheBrandJobs_data_item,
					});
				}
			}

			setProcessedFaceBehindTheBrandJobs([
				...processedFaceBehindTheBrandJobs_data,
			]);

			let completedFaceBehindTheBrandJobs_data: any[] = [];
			if (completedFaceBehindTheBrandJobs !== null) {
				for (var completedFaceBehindTheBrandJobs_data_item in completedFaceBehindTheBrandJobs) {
					completedFaceBehindTheBrandJobs_data.push({
						...completedFaceBehindTheBrandJobs[
							completedFaceBehindTheBrandJobs_data_item
						],
						completedFaceBehindTheBrandJobs_data_uuid: completedFaceBehindTheBrandJobs_data_item,
					});
				}
			}

			setCompletedFaceBehindTheBrandJobs([
				...completedFaceBehindTheBrandJobs_data,
			]);

			let data: any = [];

			if (query === "all") {
				const combineData = [
					...toBeProcessedFaceBehindTheBrandJobs_data,
					...processedFaceBehindTheBrandJobs_data,
					...completedFaceBehindTheBrandJobs_data,
				];
				data = _.map(combineData, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All Face behind the brand");
			} else if (query === "to-be-processed") {
				data = _.map(toBeProcessedFaceBehindTheBrandJobs_data, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All To Be Processed");
			} else if (query === "processing") {
				data = _.map(processedFaceBehindTheBrandJobs_data, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All Under Process");
			} else if (query === "completed") {
				data = _.map(completedFaceBehindTheBrandJobs_data, (item) => {
					return {
						ref: item.reference,
						vendor: item.store_name,
						job: item.job?.join(" & "),
						data: item,
					};
				});
				setTableData([...data]);
				setpageloading(false);
				setTableTitle("All Completed");
			}
		}
	}, [
		toBeProcessedFaceBehindTheBrandJobs,
		processedFaceBehindTheBrandJobs,
		completedFaceBehindTheBrandJobs,
		query,
	]);

	return (
		<Fragment>
			<Dialog
				fullScreen
				open={openProcess}
				onClose={handleCloseProcess}
				TransitionComponent={Transition}
			>
				<AppBar className={styles.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseProcess}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography variant="h6" className={styles.title}>
							{typeofJob === "to-be-processed" &&
								"Process face behind the brand"}
							{typeofJob === "processing" && "Process face behind the brand"}
							{typeofJob === "completed" && "Process face behind the brand"}
						</Typography>
					</Toolbar>
				</AppBar>

				{typeofJob === "to-be-processed" && (
					<ProcessFaceBehindTheBrandJob data={processJobData} />
				)}
				{typeofJob === "processing" && "Process face behind the brand"}
				{typeofJob === "completed" && (
					<CompletedFaceBehindTheBrandJob data={processJobData} />
				)}
			</Dialog>
			<br />
			<br />
			<div className={styles.queries_wrapper}>
				<Chip
					label={`All Face Behind The Brand (${
						ToBeProcessedFaceBehindTheBrandJobs.length +
						ProcessedFaceBehindTheBrandJobs.length +
						CompletedFaceBehindTheBrandJobs.length
					})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "all",
							type: "face-behind-the-brand",
						})
					}
				/>
				<Chip
					label={`All To Be Processed (${ToBeProcessedFaceBehindTheBrandJobs.length})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "to-be-processed",
							type: "face-behind-the-brand",
						})
					}
				/>
				<Chip
					label={`All Under Process (${ProcessedFaceBehindTheBrandJobs.length})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "processing",
							type: "face-behind-the-brand",
						})
					}
				/>
				<Chip
					label={`All Completed (${CompletedFaceBehindTheBrandJobs.length})`}
					variant="default"
					onClick={() =>
						navigateToLink.push(ROUTES.CREATIVES_STORY_JOB, {
							query: "completed",
							type: "face-behind-the-brand",
						})
					}
				/>
			</div>
			<Box className={styles.root} boxShadow={5}>
				<MaterialTable
					style={{ width: "100%" }}
					isLoading={pageloading}
					title={tableTitle}
					columns={[
						{
							title: "Reference",
							field: "ref",
							render: (rowData) => (
								<Typography variant="caption">{rowData.ref}</Typography>
							),
						},
						{
							title: "Vendor ",
							field: "vendor",
							render: (rowData) => (
								<Typography variant="caption">{rowData.vendor}</Typography>
							),
						},
						{
							title: "Task ",
							field: "job",
							render: (rowData) => (
								<Typography variant="caption">{rowData.job}</Typography>
							),
						},
						{
							title: "Status ",
							field: "status",
							render: (rowData) => (
								<Fragment>
									{_.has(rowData.data, "to_be_processed") &&
										rowData.data.to_be_processed.status && (
											<Chip
												icon={<EditIcon />}
												label="To be processed"
												variant="default"
											/>
										)}
									{_.has(rowData.data, "processing") &&
										rowData.data.processing.status && (
											<Chip
												icon={<LineStyleIcon />}
												label="Under Process"
												variant="default"
											/>
										)}
									{_.has(rowData.data, "completed") &&
										rowData.data.completed.status && (
											<Chip
												icon={<AssignmentTurnedInIcon />}
												label="Completed"
												variant="default"
											/>
										)}
								</Fragment>
							),
						},
						{ title: "data", field: "data", hidden: true },
						{
							title: "Action ",
							field: "action",
							render: (rowData) => (
								<Fragment>
									{_.has(rowData.data, "to_be_processed") &&
										rowData.data.to_be_processed.status && (
											<Button
												variant="contained"
												color="secondary"
												style={{ color: "#fff" }}
												onClick={() => {
													handleClickOpenProcess();
													setProcessJobData({ ...rowData.data });
													setTypeofJob("to-be-processed");
												}}
											>
												process
											</Button>
										)}
									{_.has(rowData.data, "processing") &&
										rowData.data.processing.status && (
											<Button
												variant="contained"
												color="secondary"
												style={{ color: "#fff" }}
												onClick={() => {
													setTypeofJob("processing");
												}}
											>
												process
											</Button>
										)}
									{_.has(rowData.data, "completed") &&
										rowData.data.completed.status && (
											<Button
												variant="contained"
												color="secondary"
												style={{ color: "#fff" }}
												onClick={() => {
													handleClickOpenProcess();
													setProcessJobData({ ...rowData.data });
													setTypeofJob("completed");
												}}
											>
												View
											</Button>
										)}
								</Fragment>
							),
						},
					]}
					data={tableData}
					options={{
						search: true,
						actionsColumnIndex: -1,
					}}
				/>
			</Box>
		</Fragment>
	);
};

export default FaceBehindTheBrandJobs;
