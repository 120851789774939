import React, { Fragment, useState, useEffect } from "react";

import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import BeenhereTwoToneIcon from "@material-ui/icons/BeenhereTwoTone";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import UploadImage from "../../../components/UploadImage/UploadImage";
import AssistantIcon from "@material-ui/icons/Assistant";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//images
// import BG from "../assets/images/bg.jpg";
import BG from "../../../assets/images/ekioja-bg.jpeg";

///widgets
import ProductsWidget from "../../../components/StoreWidgets/ProductsWidget";
import OrdersWidget from "../../../components/StoreWidgets/OrdersWidget";

import moment from "moment";
import _ from "lodash";
//get image
import GetImageFromFirebase from "../../../components/GetImageFromFirebase";

import useUpdateBlockStatus from "../../../hooks/stores/useUpdateBlockStatus";
import useApproveStore from "../../../hooks/stores/useApproveStore";
import useActivateStore from "../../../hooks/stores/useActivateStore";
// import useConfirmVetting from "../../../hooks /stores/useConfirmVetting";

import useKYCPassVetting from "../../../hooks/kyc/useKYCPassVetting";
import useKYCFlag from "../../../hooks/kyc/useKYCFlag";

import useAddImageToStoreProfile from "../../../hooks/images/useAddImageToStoreProfile";
import useAddBannerToStoreProfile from "../../../hooks/images/useAddBannerToStoreProfile";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { truncate } from "../../../utils";

import EkiojaBag from "../../../assets/images/ekioja-logo.jpg";

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) => ({
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	logo: {
		width: 100,
		height: 100,
		borderRadius: 100,
		position: "absolute",
		bottom: 10,
		left: 50,
		"& img": {
			width: 100,
			height: 100,
			borderRadius: 100,
			border: `3px ${theme.palette.success.dark} solid`,
			backgroundColor: "white",
		},
	},
	tab_panel_wrapper: {
		// width: 1000,
		// height: 800,
		// marginLeft: 40,
		// border: '1px solid red',
		padding: 10,
	},
	store_image_wrapper: {
		width: "100%",
		height: 300,
		backgroundImage: `url(${BG})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		position: "relative",
	},
	card_title: {
		fontSize: 14,
	},
	product_card_wrapper: {
		padding: 3,
	},

	media: {
		height: 140,
	},
	upload_button: {
		position: "absolute",
		bottom: -5,
		right: -20,
		width: 50,
		zIndex: 300,
	},
}));

interface Props {
	data: any;
	handleCloseModal?: any;
}

const ViewStoreDetailModal = ({ data }: Props) => {
	const styles = useStyles();
	const theme = useTheme();

	const [KYCPassVettingMutate, KYCPassVettingInfo] = useKYCPassVetting();
	const [KYCFlagMutate, KYCFlagInfo] = useKYCFlag();
	const [openDecisionModal, setOpenDecisionModal] = useState(false);
	const [kycDecideStoreId, setKycDecideStoreId] = useState("");

	const [tabIndexValue, setTabIndexValueValue] = useState(0);
	const [store, setStore] = useState<any>({});
	const [
		openConfirmForVettingDialog,
		setOpenConfirmForVettingDialog,
	] = useState(false);

	const [
		UpdateBlockStatusMutate,
		UpdateBlockStatusInfo,
	] = useUpdateBlockStatus();

	const [ApproveStoreMutate, ApproveStoreInfo] = useApproveStore();
	const [ActivateStoreMutate, ActivateStoreInfo] = useActivateStore();
	// const [ConfirmVettingMutate, ConfirmVettingInfo] = useConfirmVetting();

	const [
		AddImageToStoreProfileMutate,
		AddImageToStoreProfileInfo,
	] = useAddImageToStoreProfile();

	const [
		AddBannerToStoreProfileMutate,
		AddBannerToStoreProfileInfo,
	] = useAddBannerToStoreProfile();

	useFirestoreConnect([
		{
			collection: "Admins",
		},
	]);

	const StoreData = [{ ...data }];
	const authuid = useSelector((state: any) => state.firebase.auth.uid);
	const admins = useSelector((state: any) => state.firestore.ordered.Admins);

	useEffect(() => {
		if (isLoaded(admins)) {
			const all_same_docs_in_stores_and_admins = _.intersectionWith(
				_.cloneDeep(admins),
				StoreData,
				function (x: any, y: any) {
					return (
						x.entity === y.unique_id &&
						x.role === "Administrator" &&
						_.assign(x, y)
					);
				}
			);

			setStore({ ...all_same_docs_in_stores_and_admins[0] });
		}
	}, [admins]);

	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabIndexValueValue(newValue);
	};

	const handleTabChangeIndex = (index: number) => {
		setTabIndexValueValue(index);
	};

	//   const handleClickConfirmForVettingOpen = () => {
	//     setOpenConfirmForVettingDialog(true);
	//   };

	const handleConfirmForVettingClose = () => {
		setOpenConfirmForVettingDialog(false);
	};

	if (isLoaded(authuid) && isLoaded(admins) && _.isEmpty(store))
		return <div>Loading...</div>;

	return (
		<Fragment>
			<ToastContainer />
			<Dialog
				open={openConfirmForVettingDialog}
				onClose={handleConfirmForVettingClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Are you  sure?"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						By confirming this store for vetting it means you have verfied that
						they had paid.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleConfirmForVettingClose} color="primary">
						Close
					</Button>
					<Button
						onClick={() => {
							// ConfirmVettingMutate({
							// 	id: store.unique_id,
							// 	by: authuid,
							// 	status: true,
							// });
							handleConfirmForVettingClose();
						}}
						color="primary"
						autoFocus
						variant="outlined"
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openDecisionModal}
				onClose={() => setOpenDecisionModal(false)}
				aria-labelledby="decision-store-dialog-title"
				aria-describedby="decision-store-dialog-description"
			>
				<DialogTitle id="decision-store-dialog-title">
					{"What's next for this store?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="decision-store-dialog-description">
						After the KYC process you need to take a decision on this store.{" "}
						<br />
						If you are satisfied with the KYC process and want to confirm the
						store as vetted, then click on "Confirm as Vetted" button.
						<br />
						If you are not satisfied with the KYC process and want the store to
						be reviewed by the legal, then click on "Flag" button.
						<br />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDecisionModal(false)} color="primary">
						Close
					</Button>
					<Button
						color="primary"
						onClick={() => {
							if (window.confirm("Are you sure you want to pass this store?")) {
								KYCPassVettingMutate(
									{ id: kycDecideStoreId },
									{
										onSuccess: () => {
											setTimeout(() => {
												setOpenDecisionModal(false);
												KYCPassVettingInfo.reset();
											}, 1000);
										},
										onError: () => {
											window.alert(
												"An error occured! Trym again or contact the developer"
											);
										},
									}
								);
							} else {
								setOpenDecisionModal(false);
							}
						}}
					>
						{KYCPassVettingInfo.isLoading
							? "Processing..."
							: KYCPassVettingInfo.isError
							? "Error!"
							: KYCPassVettingInfo.isSuccess && !KYCPassVettingInfo.isIdle
							? "Confirmed!"
							: "Confirm as Vetted"}
					</Button>
					<Button
						color="primary"
						autoFocus
						onClick={() => {
							if (
								window.confirm(
									"Are you sure you want to flag this store for review?"
								)
							) {
								KYCFlagMutate(
									{ id: kycDecideStoreId },
									{
										onSuccess: () => {
											setTimeout(() => {
												setOpenDecisionModal(false);
												KYCFlagInfo.reset();
											}, 1000);
										},
										onError: () => {
											window.alert(
												"An error occured! Trym again or contact the developer"
											);
										},
									}
								);
							} else {
								setOpenDecisionModal(false);
							}
						}}
					>
						{KYCFlagInfo.isLoading
							? "Processing..."
							: KYCFlagInfo.isError
							? "Error!"
							: KYCFlagInfo.isSuccess && !KYCFlagInfo.isIdle
							? "Flagged!"
							: "Flag"}
					</Button>
				</DialogActions>
			</Dialog>
			{_.isEmpty(store) && (
				<Skeleton variant="rect" width="100%" height={400} animation="wave" />
			)}

			{!_.isEmpty(store) && (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={8}>
							<div
								style={{
									display: "flex",
									justifyContent: "space-evenly",
									alignItems: "flex-start",
									width: "100%",
								}}
							>
								<FormGroup row>
									{_.has(store, "vetted") && store.vetted === true && (
										<FormControlLabel
											control={
												<Switch
													checked={store.approved}
													disabled={store.approved}
													onChange={(
														event: React.ChangeEvent<HTMLInputElement>
													) =>
														ApproveStoreMutate({
															id: store.unique_id,
															by: authuid,
															status: event.target.checked,
														})
													}
													color={store.approved ? "primary" : "secondary"}
												/>
											}
											label={
												ApproveStoreInfo.isLoading
													? "updating..."
													: ApproveStoreInfo.isError
													? "Error!"
													: ApproveStoreInfo.isSuccess
													? store.approved
														? "Approved!"
														: "Approve!"
													: store.approved
													? "Approved"
													: "Approve"
											}
										/>
									)}

									{_.has(store, "vetted") && store.vetted === true && (
										<FormControlLabel
											control={
												<Switch
													checked={store.blocked}
													onChange={(
														event: React.ChangeEvent<HTMLInputElement>
													) =>
														UpdateBlockStatusMutate({
															id: store.unique_id,
															by: authuid,
															status: event.target.checked,
														})
													}
													color={store.blocked ? "primary" : "secondary"}
												/>
											}
											label={
												UpdateBlockStatusInfo.isLoading
													? "updating..."
													: UpdateBlockStatusInfo.isError
													? "Error!"
													: UpdateBlockStatusInfo.isSuccess
													? store.blocked
														? "UnBlocked!"
														: "Blocked!"
													: store.blocked
													? "UnBlock"
													: "Block"
											}
										/>
									)}
									{_.has(store, "vetted") && store.vetted === true && (
										<FormControlLabel
											control={
												<Switch
													checked={store.active}
													onChange={(
														event: React.ChangeEvent<HTMLInputElement>
													) =>
														ActivateStoreMutate({
															id: store.unique_id,
															by: authuid,
															status: event.target.checked,
														})
													}
													color={store.active ? "primary" : "secondary"}
												/>
											}
											label={
												ActivateStoreInfo.isLoading
													? "updating..."
													: ActivateStoreInfo.isError
													? "Error!"
													: ActivateStoreInfo.isSuccess
													? store.active
														? "Hidden from market!"
														: "Activated Store!"
													: store.active
													? "Hide from market"
													: "Activate Store"
											}
										/>
									)}

									{/* {!store.paid_for_review && (
										<FormControlLabel
											control={
												<Switch
													checked={store.paid_for_review}
													onChange={(
														event: React.ChangeEvent<HTMLInputElement>
													) =>
														setOpenConfirmForVettingDialog(event.target.checked)
													}
													color={
														store.paid_for_review ? "primary" : "secondary"
													}
												/>
											}
											label={
												ConfirmVettingInfo.isLoading
													? "updating..."
													: ConfirmVettingInfo.isError
													? "Error!"
													: ConfirmVettingInfo.isSuccess
													? store.paid_for_review
														? "Paid for vetting"
														: "Confirmed for Vetting by you!"
													: store.paid_for_review
													? "Paid for vetting"
													: "Confirm for Review (Legal)"
											}
										/>
									)} */}
								</FormGroup>
								{!store?.vetted && (
									<Button
										variant="outlined"
										aria-label="view-vendor-details"
										onClick={() => {
											setKycDecideStoreId(store.unique_id);
											setOpenDecisionModal(true);
										}}
										style={{
											color: "#fff",
											backgroundColor: "#030201",
										}}
									>
										Decide
									</Button>
								)}

								<Chip
									icon={<AssistantIcon />}
									label={
										store?.picked_package
											? store.is_starter_package
												? "Starter"
												: store.is_professional_package
												? "Professional"
												: store.is_advanced_package
												? "Advanced"
												: store.is_premium_package
												? "Premium"
												: "None"
											: "Free"
									}
									variant="outlined"
									color="secondary"
									style={{
										color: "rgba(34, 179, 88, 1)",
										border: "1px solid rgba(34, 179, 88, 1)",
									}}
								/>
								{store.pending_vet && (
									<Chip
										icon={<BeenhereTwoToneIcon />}
										label="Pending Vet"
										variant="outlined"
										color="secondary"
										style={{
											color: "yellow",
											border: "1px yellow solid",
										}}
									/>
								)}
								{store.under_vetting_by_legal && (
									<Chip
										icon={<BeenhereTwoToneIcon />}
										label="Under Vetting"
										variant="outlined"
										color="secondary"
										style={{
											color: "orange",
											border: "1px orange solid",
										}}
									/>
								)}
								{store.vetted && (
									<Chip
										icon={<BeenhereTwoToneIcon />}
										label="Vetted"
										variant="outlined"
										color="secondary"
									/>
								)}

								{store.failed_vet && (
									<Chip
										icon={<BeenhereTwoToneIcon />}
										label=" Failed Vetting"
										variant="outlined"
										color="secondary"
										style={{ color: "red", border: "1px red solid" }}
									/>
								)}
							</div>
						</Grid>
					</Grid>
					<br />
					<Divider />
					<br />

					<Grid container direction="row" spacing={2}>
						<Grid item xs={12} lg={2}>
							<Tabs
								value={tabIndexValue}
								onChange={handleTabChange}
								indicatorColor="primary"
								textColor="primary"
								aria-label="store details"
								orientation="vertical"
								variant="scrollable"
								className={styles.tabs}
							>
								<Tab label="General" {...a11yProps(0)} />
								<Tab label="Products" {...a11yProps(1)} />
								<Tab label="Orders" {...a11yProps(2)} />
								<Tab label="Statistics" {...a11yProps(3)} />
								<Tab label="Wallet" {...a11yProps(3)} />
								<Tab label="Reviews" {...a11yProps(4)} />
								<Tab label="Reports" {...a11yProps(5)} />
							</Tabs>
							<br />
						</Grid>
						<Grid item xs={12} lg={10}>
							<SwipeableViews
								axis={theme.direction === "rtl" ? "x-reverse" : "x"}
								index={tabIndexValue}
								onChangeIndex={handleTabChangeIndex}
							>
								<TabPanel value={tabIndexValue} index={0} dir={theme.direction}>
									<div className={styles.tab_panel_wrapper}>
										{AddImageToStoreProfileInfo.isLoading
											? "uploading logo..."
											: ""}
										{AddBannerToStoreProfileInfo.isLoading
											? "uploading banner..."
											: ""}
										<div
											className={styles.store_image_wrapper}
											style={{
												backgroundImage:
													store.business_banner_id !== ""
														? store.business_banner_url !== undefined
															? `url(${store.business_banner_url})`
															: `url(https://firebasestorage.googleapis.com/v0/b/ekioja-7bb9f.appspot.com/o/images%2Fbanner_${store.unique_id}?alt=media)`
														: `url(${BG})`,
											}}
										>
											<div className={styles.upload_button}>
												<UploadImage
													title="Store Banner Image"
													existingImageId={`banner_${store.unique_id}`}
													folderToSave={"vendorAdminBannerImages"}
													onRequestSaveURL={(url: string) => {
														AddBannerToStoreProfileMutate(
															{ url, entity: store.unique_id },
															{
																onSuccess: () => {
																	toast.success(
																		"Store banner have been successfully updated! Reload page to see changes",
																		{
																			position: "top-right",
																			autoClose: 3000,
																			hideProgressBar: false,
																			closeOnClick: true,
																		}
																	);
																},
																onError: (e) => {
																	toast.error(
																		"Oops! An error occured!  Try agin",
																		{
																			position: "top-right",
																			autoClose: 3000,
																			hideProgressBar: false,
																			closeOnClick: true,
																		}
																	);
																},
															}
														);
													}}
													onRequestSave={(id: string) => {
														// console.log(id);
													}}
													onRequestClear={() => {
														console.log("clear");
													}}
												/>
											</div>
											{store.business_logo_id === "" && (
												<div className={styles.logo}>
													<img src={EkiojaBag} alt="ekioja logo" />
													<div style={{ position: "relative" }}>
														<div className={styles.upload_button}>
															<UploadImage
																title="Store Logo Image"
																existingImageId={`logo_${store.unique_id}`}
																folderToSave={"images"}
																onRequestSaveURL={(url: string) => {
																	AddImageToStoreProfileMutate(
																		{ url, entity: store.unique_id },
																		{
																			onSuccess: () => {
																				toast.success(
																					"Store logo have been successfully updated! Reload page to see changes",
																					{
																						position: "top-right",
																						autoClose: 3000,
																						hideProgressBar: false,
																						closeOnClick: true,
																					}
																				);
																			},
																			onError: (e) => {
																				toast.error(
																					"Oops! An error occured!  Try agin",
																					{
																						position: "top-right",
																						autoClose: 3000,
																						hideProgressBar: false,
																						closeOnClick: true,
																					}
																				);
																			},
																		}
																	);
																}}
																onRequestSave={(id: string) => {
																	// console.log(id);
																}}
																onRequestClear={() => {
																	console.log("clear");
																}}
															/>
														</div>
													</div>
												</div>
											)}
											{store.business_logo_id !== "" &&
												(store.business_logo_url === undefined ||
													store.business_logo_url === "") && (
													<div className={styles.logo}>
														<GetImageFromFirebase
															source={store.business_logo_id}
														/>
														<div style={{ position: "relative" }}>
															<div className={styles.upload_button}>
																<UploadImage
																	title="Store Logo Image"
																	existingImageId={`logo_${store.unique_id}`}
																	folderToSave={"images"}
																	onRequestSaveURL={(url: string) => {
																		AddImageToStoreProfileMutate(
																			{ url, entity: store.unique_id },
																			{
																				onSuccess: () => {
																					toast.success(
																						"Store logo have been successfully updated! Reload page to see changes",
																						{
																							position: "top-right",
																							autoClose: 3000,
																							hideProgressBar: false,
																							closeOnClick: true,
																						}
																					);
																				},
																				onError: (e) => {
																					toast.error(
																						"Oops! An error occured!  Try agin",
																						{
																							position: "top-right",
																							autoClose: 3000,
																							hideProgressBar: false,
																							closeOnClick: true,
																						}
																					);
																				},
																			}
																		);
																	}}
																	onRequestSave={(id: string) => {
																		// console.log(id);
																	}}
																	onRequestClear={() => {
																		console.log("clear");
																	}}
																/>
															</div>
														</div>
													</div>
												)}
											{store.business_logo_id !== "" &&
												store.business_logo_url !== undefined &&
												store.business_logo_url !== "" && (
													<div className={styles.logo}>
														<img
															src={store.business_logo_url}
															alt={store.business_name}
														/>
														<div style={{ position: "relative" }}>
															<div className={styles.upload_button}>
																<UploadImage
																	title="Store Logo Image"
																	existingImageId={`logo_${store.unique_id}`}
																	folderToSave={"images"}
																	onRequestSaveURL={(url: string) => {
																		AddImageToStoreProfileMutate(
																			{ url, entity: store.unique_id },
																			{
																				onSuccess: () => {
																					toast.success(
																						"Store logo have been successfully updated! Reload page to see changes",
																						{
																							position: "top-right",
																							autoClose: 3000,
																							hideProgressBar: false,
																							closeOnClick: true,
																						}
																					);
																				},
																				onError: (e) => {
																					toast.error(
																						"Oops! An error occured!  Try agin",
																						{
																							position: "top-right",
																							autoClose: 3000,
																							hideProgressBar: false,
																							closeOnClick: true,
																						}
																					);
																				},
																			}
																		);
																	}}
																	onRequestSave={(id: string) => {
																		// console.log(id);
																	}}
																	onRequestClear={() => {
																		console.log("clear");
																	}}
																/>
															</div>
														</div>
													</div>
												)}
										</div>

										<Typography variant="h5"> {store.business_name}</Typography>
										<Grid container spacing={2}>
											<Grid item xs={12} lg={4}>
												<List dense={false}>
													<ListItem>
														<ListItemText
															primary="Business email"
															secondary={store.business_email}
														/>
													</ListItem>
													<ListItem>
														<ListItemText
															primary="Business Website"
															secondary={
																store.business_website !== ""
																	? store.business_website
																	: "Nil"
															}
														/>
													</ListItem>
													<ListItem>
														<ListItemText
															primary="Representative"
															secondary={`${store.first_name} ${store.last_name} | ${store.contact_phone_number}`}
														/>
													</ListItem>
												</List>
											</Grid>
											<Grid item xs={12} lg={4}>
												<List dense={false}>
													<ListItem>
														<ListItemText
															primary="Business Slogan"
															secondary={
																store.business_slogan !== ""
																	? store.business_slogan
																	: "Nil"
															}
														/>
													</ListItem>
													<ListItem>
														<ListItemText
															primary="Niches"
															secondary={store.business_niche}
														/>
													</ListItem>
													<ListItem>
														<ListItemText
															primary="Joined"
															secondary={
																typeof store.date_added !== "undefined" &&
																moment(store.date_added.toDate())
																	.fromNow()
																	.toString()
															}
														/>
													</ListItem>
												</List>
											</Grid>
											<Grid item xs={12} lg={4}>
												<List dense={false}>
													<ListItem>
														<ListItemText
															primary="Location"
															secondary={`${store.business_location?.street_address_1} | ${store.business_location?.postal_code_1}`}
														/>
													</ListItem>
													<ListItem>
														<ListItemText
															primary="Business description"
															secondary={store.business_description}
														/>
													</ListItem>
												</List>
											</Grid>
										</Grid>
									</div>
								</TabPanel>
								<TabPanel value={tabIndexValue} index={1} dir={theme.direction}>
									<div className={styles.tab_panel_wrapper}>
										<ProductsWidget entity={store.unique_id} />
									</div>
								</TabPanel>
								<TabPanel value={tabIndexValue} index={2} dir={theme.direction}>
									<div className={styles.tab_panel_wrapper}>
										<OrdersWidget entity={store.unique_id} />
									</div>
								</TabPanel>
								<TabPanel value={tabIndexValue} index={3} dir={theme.direction}>
									<div className={styles.tab_panel_wrapper}>
										<h5>Coming soon...</h5>
									</div>
								</TabPanel>
								<TabPanel value={tabIndexValue} index={4} dir={theme.direction}>
									<div className={styles.tab_panel_wrapper}>
										<h5>Coming soon...</h5>
									</div>
								</TabPanel>
								<TabPanel value={tabIndexValue} index={5} dir={theme.direction}>
									<div className={styles.tab_panel_wrapper}>
										<h5>Coming soon...</h5>
									</div>
								</TabPanel>
								<TabPanel value={tabIndexValue} index={6} dir={theme.direction}>
									<div className={styles.tab_panel_wrapper}>
										<h5>Coming soon...</h5>
									</div>
								</TabPanel>
							</SwipeableViews>
						</Grid>
					</Grid>
				</>
			)}
		</Fragment>
	);
};

export default ViewStoreDetailModal;
