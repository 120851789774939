  
import { useQuery } from "react-query";
import firebase from '../config/firebaseConfig';


const getEkiojaBiches = async () =>{
    const response = await firebase.firestore().collection('Ekioja').doc('liveNiches').get();
    const data = response.data();

    return data
}


 const useGetEkiojaNiches = ()=>{
    return useQuery('ekiojaNiches',getEkiojaBiches)
}


export default useGetEkiojaNiches;