import { useQuery } from "react-query";
import firebase from "../../config/firebaseConfig";
import _ from "lodash";

const getEkiojaBrandsWithProducts = async () => {
	const response = await firebase
		.firestore()
		.collection("Stores")
		.where("blocked", "==", false)
		.where("active", "==", true)
		.get();
	const productResponse = await firebase
		.firestore()
		.collection("Products")
		.where("visible_on_market", "==", true)
		.get();

	let store_data: any = [];
	response.forEach((doc: any) => {
		store_data.push({
			store_id: doc.data().unique_id,
			store_name: doc.data().business_name,
		});
	});
	let product_data: any = [];
	productResponse.forEach((doc: any) => {
		product_data.push({
			product_id: doc.data().unique_id,
			...doc.data(),
		});
	});

	const store_and_product_data = store_data.map((doc: any) => {
		const products = _.filter(
			product_data,
			(item) => item.entity === doc.store_id
		);
		return { ...doc, products_count: products.length };
	});

	return store_and_product_data;
};

const useGetEkiojaBrandsWithProducts = () => {
	return useQuery("ekiojaStoreWithProducts", getEkiojaBrandsWithProducts);
};

export default useGetEkiojaBrandsWithProducts;
