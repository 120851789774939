import { useMutation, queryCache } from "react-query";
import firebase from "../../../config/firebaseConfig";
import _ from "lodash";
interface Props {
	chat_id: string;
	shipping_option: string;
	door_delivery_agreed_cost: any;
	dollarNairaRate: number;
}

const addShippingOption = async (values: Props) => {
	return firebase
		.firestore()
		.collection("Conversations")
		.doc(values.chat_id)
		.set(
			{
				shippingOption: {
					shipping_option: values.shipping_option,
					door_delivery_agreed_cost:
						typeof values.door_delivery_agreed_cost === "string"
							? values.dollarNairaRate === 1
								? parseInt(values.door_delivery_agreed_cost)
								: _.floor(
										parseInt(values.door_delivery_agreed_cost) /
											values.dollarNairaRate,
										2
								  )
							: values.dollarNairaRate === 1
							? values.door_delivery_agreed_cost
							: _.floor(
									values.door_delivery_agreed_cost / values.dollarNairaRate,
									2
							  ),
				},
			},
			{ merge: true }
		);
};

const useAddShippingOption = () => {
	return useMutation(addShippingOption, {
		onSettled: (returnedData, error, sentVaribles: any) => {
			queryCache.invalidateQueries([
				"chatCurrentConversation",
				sentVaribles.chat_id,
			]);
			queryCache.refetchQueries(
				["chatCurrentConversation", sentVaribles.chat_id],
				{ active: true, exact: true }
			);
		},
	});
};

export default useAddShippingOption;
