import React from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
//styles
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		form_wrapper: {
			padding: 5,
			borderRadius: 20,
			width: "100%",
			height: "auto",
			marginBottom: 10,
		},
		button: {
			marginTop: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		step: {
			backgroundColor: "#F5F9FA",
			padding: 10,
		},

		checkbox: {
			color: "#08c551",
		},
		appBar: {
			position: "relative",
			backgroundColor: "transparent",
		},
		title: {
			marginLeft: theme.spacing(2),
			flex: 1,
		},
		photo_wrapper: {
			width: "100%",
			height: 300,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			"& > img": {
				width: 250,
				height: 250,
			},
		},
		logo_wrapper: {
			width: "100%",
			height: 300,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderRadius: 100,
			"& > img": {
				width: 100,
				height: 100,
				borderRadius: 100,
			},
		},
	})
);

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const BrandStoryDetails = ({
	data,
	open,
	handleClose,
}: {
	data: any;
	open: boolean;
	handleClose: () => void;
}) => {
	const styles = useStyles();

	return (
		<div>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar className={styles.appBar} elevation={0}>
					<Toolbar>
						<IconButton
							edge="start"
							color="primary"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography
							variant="h6"
							color="textPrimary"
							className={styles.title}
						>
							{data.business_name} - Brand Story
						</Typography>
					</Toolbar>
				</AppBar>

				<Grid container spacing={4}>
					<Grid item xs={12} lg={3}></Grid>
					<Grid item xs={12} lg={6}>
						<Typography
							variant="body1"
							style={{ color: "#000", marginBottom: 10, marginTop: 10 }}
							align="center"
						>
							<b>Details</b>
						</Typography>
						<br />
						<Grid container spacing={2}>
							<Grid item xs={12} lg={6}>
								<div className={styles.logo_wrapper}>
									<img src={data.store_logo_url} alt={data.business_name} />
								</div>
							</Grid>
							<Grid item xs={12} lg={6}>
								<div className={styles.photo_wrapper}>
									<img src={data.photo_of_founder_url} alt={data.full_name} />
								</div>
							</Grid>
						</Grid>
						<br />
						<List>
							<ListItem>
								<ListItemText primary="Full Name" secondary={data.full_name} />
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Business Phone Number"
									secondary={data.business_phone_number}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Brand/Business Name"
									secondary={data.business_name}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Business email"
									secondary={data.business_email}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Business website"
									secondary={data.website}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Brand Motto/Slogan"
									secondary={data.business_slogan}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Summary about brand"
									secondary={data.business_summary}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What inspired you to start this brand?"
									secondary={data.inspiration_article}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What problem(s) the brand  solving"
									secondary={data.problems_solutions}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What market(s) this brand is serving?"
									secondary={data.market}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Where is the brand now? (In terms of business operations, growth, problems etc.)"
									secondary={data.current_status_article}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What is one major thing the brand  wants the World to know about them?"
									secondary={data.one_thing_article}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="Brief about the Founder(s)"
									secondary={data.brief_about_founders_article}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText
									primary="What are the key products offerings?"
									secondary={
										<div>
											<Typography variant="subtitle2" color="textPrimary">
												Product 1<br />
												{data.key_products?.product_1}
											</Typography>
											<Divider />
											<Typography variant="subtitle2" color="textPrimary">
												Product 2<br />
												{data.key_products?.product_2}
											</Typography>
											<Divider />
											<Typography variant="subtitle2" color="textPrimary">
												Product 3<br />
												{data.key_products?.product_3}
											</Typography>
											<Divider />
											<Typography variant="subtitle2" color="textPrimary">
												Product 4<br />
												{data.key_products?.product_4}
											</Typography>
											<Typography variant="subtitle2" color="textPrimary">
												Product 5<br />
												{data.key_products?.product_5}
											</Typography>
											<Divider />
										</div>
									}
								/>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText primary="HashTags" secondary={data.hash_tags} />
							</ListItem>
							<Divider />
							{data.social_media?.instagram !== "" && (
								<ListItem>
									<ListItemIcon>
										<InstagramIcon />
									</ListItemIcon>
									<ListItemText
										primary="Instagram"
										secondary={data.social_media?.instagram}
									/>
								</ListItem>
							)}
							{data.social_media?.twitter !== "" && (
								<ListItem>
									<ListItemIcon>
										<TwitterIcon />
									</ListItemIcon>
									<ListItemText
										primary="Twitter"
										secondary={data.social_media?.twitter}
									/>
								</ListItem>
							)}
							{data.social_media?.facebook !== "" && (
								<ListItem>
									<ListItemIcon>
										<FacebookIcon />
									</ListItemIcon>
									<ListItemText
										primary="Facebook"
										secondary={data.social_media?.facebook}
									/>
								</ListItem>
							)}
							{data.social_media?.linkedin !== "" && (
								<ListItem>
									<ListItemIcon>
										<LinkedInIcon />
									</ListItemIcon>
									<ListItemText
										primary="Facebook"
										secondary={data.social_media?.linkedin}
									/>
								</ListItem>
							)}
						</List>
						<Divider />
					</Grid>
					<Grid item xs={12} lg={3}></Grid>
				</Grid>
			</Dialog>
		</div>
	);
};

export default BrandStoryDetails;
