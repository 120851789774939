import React, { Fragment } from "react";
import ChatBody from "./components/chatBody/ChatBody";
import ChatHead from "./components/chatHead/ChatHead";
import ChatInput from "./components/chatInput/ChatInput";
import { Conversation } from "../ConservationList/ConservationItem";
import { isEmpty } from "lodash";
import { useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import ChatActivity from "./components/chatActivity/ChatActivity";

interface Props {
	data: Conversation;
	isLoading: boolean;
}
const ChannelView = (props: Props) => {
	const theme = useTheme();
	return (
		<div>
			{!isEmpty(props.data) && (
				<Fragment>
					<Grid container spacing={1}>
						<Grid item xs={12} lg={7}>
							<ChatHead data={props.data} />
							<ChatBody data={props.data} />
							<ChatInput />
						</Grid>
						<Grid item xs={12} lg={5}>
							<ChatActivity data={props.data} />
						</Grid>
					</Grid>
				</Fragment>
			)}
			{props.isLoading && <Skeleton variant="rect" width="100%" height={400} />}
			{isEmpty(props.data) && !props.isLoading && (
				<div
					style={{
						width: "100%",
						height: 350,
						backgroundColor: theme.palette.background.paper,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						color: theme.palette.text.primary,
						fontSize: "2em",
						textAlign: "center",
					}}
				>
					Select a conversation to start chatting
				</div>
			)}
		</div>
	);
};

export default ChannelView;
