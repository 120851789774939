import React from "react";
import SearchVendorChatController from "./SearchVendorChatController";

const SearchVendorChat = () => {
	return (
		<div>
			<SearchVendorChatController />
		</div>
	);
};

export default SearchVendorChat;
