import { useMutation, queryCache } from "react-query";
import firebase from "../../config/firebaseConfig";

var deleteUserAuthAccount = firebase
	.functions()
	.httpsCallable("deleteUserAuthAccount");

const deleteStore = async (values: any) => {
	//delete store data

	const storeDeleteDataPromise = await firebase
		.firestore()
		.collection(`Stores`)
		.doc(values.unique_id)
		.delete();

	//get all store admins and delete
	const storeAdminDocsPromises: any = [];

	for (const adminId of values.admins) {
		const p = firebase.firestore().collection(`Admins`).doc(adminId).delete();

		storeAdminDocsPromises.push(p);
	}

	//get all store admins user auth
	const storeAdminAuthPromises: any = [];

	for (const adminAuthyId of values.admins) {
		const p = deleteUserAuthAccount({ uid: adminAuthyId });

		storeAdminAuthPromises.push(p);
	}
	//get all store products
	const storeProductsQuertSnapshotResponse = await firebase
		.firestore()
		.collection("Products")
		.where("store_id", "==", values.unique_id)
		.get();
	let storeProductsData: any = [];
	storeProductsQuertSnapshotResponse.forEach((doc: any) => {
		storeProductsData.push(doc.id);
	});

	const storeProductDocsPromises: any = [];

	for (const product of storeProductsData) {
		const p = firebase.firestore().collection(`Products`).doc(product).delete();
		storeProductDocsPromises.push(p);
	}

	//get all store wallet
	const storeWalletQuertSnapshotResponse = await firebase
		.firestore()
		.collection("Wallets")
		.where("owner_id", "==", values.unique_id)
		.get();
	let storeWalletData: any = [];
	storeWalletQuertSnapshotResponse.forEach((doc: any) => {
		storeWalletData.push(doc.id);
	});

	const storeWalletDocsPromises: any = [];

	for (const wallet of storeWalletData) {
		const p = firebase.firestore().collection(`Wallets`).doc(wallet).delete();
		storeWalletDocsPromises.push(p);
	}
	//delete all
	const allPromises = Promise.all([
		...storeAdminDocsPromises,
		...storeAdminAuthPromises,
		...storeProductDocsPromises,
		...storeWalletDocsPromises,
		storeDeleteDataPromise,
	]);
	return allPromises;
};

const useDeleteStore = () => {
	return useMutation(deleteStore, {
		onSuccess: () => {
			queryCache.invalidateQueries("allStores");
			queryCache.invalidateQueries("allProducts");
		},
	});
};

export default useDeleteStore;
