import { useMutation } from "react-query";
import firebase from "../../config/firebaseConfig";
import { queryCache } from "react-query";

const inviteNewAdmin = (values: any) => {
	return firebase
		.firestore()
		.collection("InvitedEkiojaAdmins")
		.doc()
		.set({
			...values,
			on: firebase.firestore.FieldValue.serverTimestamp(),
		});
};

const useInviteNewAdmin = () => {
	return useMutation(inviteNewAdmin, {
		// // Always refetch after error or success:
		onSettled: () => {
			queryCache.invalidateQueries("allEkiojaAdmins");
		},
	});
};

export default useInviteNewAdmin;
