import React, { useState } from "react";
//components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import * as yup from "yup";
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Logo from "../../../assets/images/svg/ekioja-logo.svg";

import useCreateLogisticsPartnerAdminAccount from "../../../hooks/auth/useCreateLogisticsPartnerAdminAccount";

import { useSelector } from "react-redux";

import * as ROUTES from "../../../constants/routes";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

//react router dom
import { useParams } from "react-router-dom";

import { getBrowserProps } from "../../../utils/getBrowerProps";

import { Redirect } from "react-router-dom";

import { LOGISTICS } from "../../../constants/roles";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Typography from "@material-ui/core/Typography";

//signup yup schema
const SignupValidationSchema = yup.object().shape({
	first_name: yup
		.string()
		.max(35, "its too long")
		.min(2, "its too short")
		.required("Your first name is Required"),
	last_name: yup
		.string()
		.max(35, "its too long")
		.min(2, "its too short")
		.required("Your last name is Required"),
	username: yup
		.string()
		.max(35, "its too long")
		.min(2, "its too short")
		.required("Your user name is Required"),
	email: yup.string().email().required("Your email is Required"),
	contact_phone_number: yup.string().required("Your phone number is Required"),
	password: yup
		.string()
		.label("Password")
		.required("Your password is Required")
		.min(8, "Your password must not be less than 8 characters"),
	confirmpassword: yup
		.string()
		.required()
		.test("passwords-match", "Oops! Passwords do not match!", function (value) {
			return this.parent.password === value;
		}),
	check_terms_and_condition: yup
		.boolean()
		.required("Required to proceed")
		.test("check-terms-and-condition", "Required to proceed", (val) => {
			return val === true;
		}),
});

//styles functions
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100vw",
			height: "100vh",
			backgroundColor: "green",
			paddingTop: 20,
			paddingBottom: 60,
			overflowX: "hidden",
		},
		form_wrapper: {
			padding: 20,
			borderRadius: 20,
			width: "100%",
			height: "auto",
			backgroundColor: "white",
		},
		form_header: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			marginBottom: 20,
		},
		checkbox: {
			color: "#08c551",
		},
	})
);

const LogisticsPartnerAdminNewAccount = () => {
	const styles = useStyles();

	const [errMsg, setErrMsg] = useState("");

	const [showPassword, setshowPassword] = useState(false);
	const [
		CreateAdminAccountMutate,
		CreateAdminAccountInfo,
	] = useCreateLogisticsPartnerAdminAccount();

	const { role, email, logistics_company_name, entity } = useParams<any>();

	const [clientDevicedata, setclientDevicedata] = useState({});

	React.useEffect(() => {
		getBrowserProps().then((data: any) => {
			setclientDevicedata({ ...data });
		});
	}, []);

	const auth = useSelector((state: any) => state.firebase.auth);

	if (isLoaded(auth.uid)) {
		return <Redirect to={ROUTES.REDIRECT_BRIDGE_PAGE} />;
	}

	return (
		<div className={styles.root}>
			<Container>
				<Grid container>
					<Grid item xs={12} lg={3}></Grid>
					<Grid item xs={12} lg={6}>
						<div className={styles.form_header}>
							<img src={Logo} alt="ekioja" />
							<Typography variant="h5" style={{ color: "#fff" }} align="center">
								Logistics Partner({logistics_company_name}) <br />
								{role} Create Account Form
							</Typography>
						</div>

						<Box boxShadow={5} className={styles.form_wrapper}>
							<Formik
								validationSchema={SignupValidationSchema}
								initialValues={{
									first_name: "",
									last_name: "",
									username: "",
									email: email,
									contact_phone_number: "",
									password: "",
									confirmpassword: "",
									role: LOGISTICS,
									entity: entity,
									logistics_role: role,
									logistics_company_name: logistics_company_name,
									check_terms_and_condition: false,
									deviceData: [],
								}}
								onSubmit={(values, { setSubmitting }) => {
									// @ts-ignore
									values.deviceData.push({ ...clientDevicedata });
									setTimeout(() => {
										setSubmitting(false);
										CreateAdminAccountMutate(values, {
											onSuccess: () => CreateAdminAccountInfo.reset(),
											onError: (err: any) => setErrMsg(err?.message),
										});
									}, 500);
								}}
							>
								{({
									submitForm,
									isSubmitting,
									setFieldValue,
									values,
									isValid,
									errors,
								}) => (
									<Form>
										{isSubmitting && <LinearProgress />}
										<Field
											component={TextField}
											name="first_name"
											type="text"
											label="First Name"
											fullWidth
										/>
										<br />

										<Field
											component={TextField}
											name="last_name"
											type="text"
											label="Last Name"
											fullWidth
										/>
										<br />

										<Field
											component={TextField}
											name="username"
											type="text"
											label="Username"
											fullWidth
										/>
										<br />

										<Field
											component={TextField}
											name="email"
											type="email"
											label="Email"
											fullWidth
											disabled
										/>
										<br />
										<Field
											component={TextField}
											name="logistics_company_name"
											type="text"
											label="Company Name"
											fullWidth
											disabled
										/>
										<br />
										<br />

										<FormControl fullWidth>
											<Field
												component={PhoneInput}
												inputProps={{
													name: "contact_phone_number",
												}}
												country={"ng"}
												enableSearch={true}
												value={values.contact_phone_number}
												onChange={(phone: string) =>
													setFieldValue("contact_phone_number", phone, true)
												}
												inputStyle={{
													width: "100%",
													border: 0,
													marginBottom: 10,
													borderBottom: `1px solid  ${
														errors.contact_phone_number ? "red" : "gray"
													}`,
												}}
											/>
										</FormControl>
										<ErrorMessage
											name="contact_phone_number"
											render={(msg) => (
												<span style={{ color: "red", fontSize: "13px" }}>
													{msg}
												</span>
											)}
										/>
										<br />
										<br />

										<Field
											component={TextField}
											type={showPassword ? "text" : "password"}
											label="Password (for your dashboard access)"
											name="password"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<LockIcon />
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment
														position="start"
														onClick={() => setshowPassword(!showPassword)}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</InputAdornment>
												),
											}}
											fullWidth
										/>
										<br />

										<Field
											component={TextField}
											type={showPassword ? "text" : "password"}
											label="Confirm Password"
											name="confirmpassword"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<LockIcon />
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment
														position="start"
														onClick={() => setshowPassword(!showPassword)}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</InputAdornment>
												),
											}}
											fullWidth
										/>
										<br />
										<Field
											component={CheckboxWithLabel}
											type="checkbox"
											name="check_terms_and_condition"
											Label={{
												label:
													"I agree to the terms and conditions on Ekioja, and to do my due diligence in preventing fraud or fraudulent activities.",
											}}
											checked={values.check_terms_and_condition}
											onChange={(event: any) =>
												setFieldValue(
													"check_terms_and_condition",
													event.target.checked,
													true
												)
											}
											className={styles.checkbox}
											color="primary"
										/>

										<span style={{ color: "red" }}>{errMsg}</span>
										<br />

										<Button
											variant="contained"
											style={{
												backgroundColor: "#4caf50",
												color: "white",
												marginTop: 40,
											}}
											disabled={isSubmitting || !isValid}
											onClick={submitForm}
										>
											{CreateAdminAccountInfo.isLoading
												? "Processing..."
												: CreateAdminAccountInfo.isError
												? "Error!"
												: CreateAdminAccountInfo.isSuccess
												? "Done!"
												: "Create Account"}
										</Button>
									</Form>
								)}
							</Formik>
						</Box>
					</Grid>
					<Grid item xs={12} lg={3}></Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default LogisticsPartnerAdminNewAccount;
