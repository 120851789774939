import React, { ReactNode, useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//images
import Logo from "../../assets/images/svg/ekioja-logo.svg";

import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";

import LoadingScreen from "../../components/LoadingScreen";

//react - redux
import { useSelector } from "react-redux";

//firestoreconnect
import { useFirestoreConnect } from "react-redux-firebase";

//react redux firebase
import { isLoaded } from "react-redux-firebase";

// //navigation hook
import { useNavigate } from "../../hooks/navigate";

// //routes
import * as ROUTES from "../../constants/routes";

import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        marginRight: 40,
      },
    },

    logo_wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: 100,
      height: 60,
      marginRight: 10,
      "& img": {
        width: 90,
        height: "auto",
      },
    },
    section_gutter: {
      marginTop: 100,
    },
    card_media: {
      height: 140,
    },
  })
);

interface Props {
  children: ReactNode;
}

const MarketPlaceLayout = ({ children }: Props) => {
  const styles = useStyles();

  const { navigateToLink } = useNavigate();

  const [wishlistCount, setWishlistCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);

  useFirestoreConnect([
    {
      collection: "Wishlist",
      where: [["wishlist_customer_id", "==", "demo-customer"]],
      storeAs: "CustomerWishlist",
    },
    {
      collection: "Cart",
      where: [["cart_customer_id", "==", "demo-customer"],["cart_closed", "==", false]],
      storeAs: "CustomerCart",
    },
  ]);

  const wishList = useSelector(
    (state: any) => state.firestore.data["CustomerWishlist"]
  );

  const cart = useSelector(
    (state: any) => state.firestore.data["CustomerCart"]
  );

  useEffect(() => {
    if (isLoaded(wishList) && isLoaded(cart)) {
      let wishlist_data = [];
      if (wishList !== null) {
        for (var key in wishList) {
          wishlist_data.push({ ...wishList[key], wishlist_uuid: key });
        }
      }
      setWishlistCount(wishlist_data.length);

      let cart_data: any[] = [];
      if (cart !== null) {
        for (var cartkey in cart) {
          cart_data.push({ ...cart[cartkey], cart_uuid: cartkey });
        }
      }

      const result_total_cart_item_quantity = _.sumBy(
        cart_data,
        (o: any) => o.cart_quantity
      );

      setCartCount(result_total_cart_item_quantity);
    }
  }, [wishList, cart]);

  if (!isLoaded(wishList)) return <LoadingScreen />;
  return (
    <div className={styles.root}>
      <AppBar position="fixed" style={{ backgroundColor: "#00e676" }}>
        <Toolbar>
          <div className={styles.logo_wrapper}>
            <img src={Logo} alt="ekioja" />
          </div>

          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={styles.title}
          >
            Categories
          </Typography>

          <div className={styles.grow} />
          <div>
            <Tooltip title="show items to be compared">
              <IconButton
                aria-label="show items to be compared"
                color="inherit"
              >
                <Badge badgeContent={0} color="secondary">
                  <CompareArrowsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="show items on wish list">
              <IconButton
                aria-label="show items on wish list"
                color="inherit"
                onClick={() =>
                  navigateToLink.push(ROUTES.VIEW_MARKETPLACE_WISHLIST)
                }
              >
                <Badge badgeContent={wishlistCount} color="secondary">
                  <FavoriteIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="show items on cart list">
              <IconButton
                aria-label="show items on cart list"
                color="inherit"
                onClick={() =>
                  navigateToLink.push(ROUTES.VIEW_MARKETPLACE_CART)
                }
              >
                <Badge badgeContent={cartCount} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      {children}
    </div>
  );
};

export default MarketPlaceLayout;
