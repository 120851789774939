import { useMutation } from "react-query";
import firebase from '../../config/firebaseConfig';
const deleteImage = async({ folder, source }:{ folder:any, source:any }) => {

    return firebase.storage().ref().child(`${folder}/${source}`).delete()
}


const useDeleteImage = () => {
    return useMutation(deleteImage);
}


export default useDeleteImage;